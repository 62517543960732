import { useFormikContext } from 'formik'
import React from 'react'
import { UploadFileComponent } from 'src/component'
import styles from './index.module.scss'

const EmployeeDocument: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.header}>7. เอกสาร</div>
      <div className={styles.card}>
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.form}>
              <p className={styles.title}>แนบไฟล์บัตรประชาชน</p>
              <UploadFileComponent
                name={'formInput.personalIdAttachment'}
                flodername={'DocumentPersonal'}
                valueShow={values.formInput.personalIdAttachment}
                id={values.formInput.id}
                label="อัปโหลดเอกสารสำเนาบัตรประชาชน (JPEG,PNG,PDF)"
              />
            </div>
            <div className={styles.form}>
              <p className={styles.title}>แนบไฟล์สำเนาทะเบียนบ้าน</p>
              <UploadFileComponent
                name={'formInput.personalHouseAttachment'}
                flodername={'DocumentPersonal'}
                valueShow={values.formInput.personalHouseAttachment}
                id={values.formInput.id}
                label="อัปโหลดเอกสารสำเนาทะเบียนบ้าน (JPEG,PNG,PDF)"
              />
            </div>
            <div className={styles.form}>
              <p className={styles.title}>แนบไฟล์สำเนาวุฒิการศึกษา</p>
              <UploadFileComponent
                name={'formInput.personalEducationalAttachment'}
                flodername={'DocumentPersonal'}
                valueShow={values.formInput.personalEducationalAttachment}
                id={values.formInput.id}
                label="อัปโหลดเอกสารสำเนาวุฒิการศึกษา (JPEG,PNG,PDF)"
              />
            </div>
            <div className={styles.form}>
              <p className={styles.title}>{`แนบไฟล์เอกสารอบรม (ถ้ามี)`}</p>
              <UploadFileComponent
                name={'formInput.personalTrainningAttachment'}
                flodername={'DocumentPersonal'}
                valueShow={values.formInput.personalTrainningAttachment}
                id={values.formInput.id}
                label="อัปโหลดเอกสารการอบรม (JPEG,PNG,PDF)"
              />
            </div>
            <div className={styles.form}>
              <p className={styles.title}>แนบไฟล์เอกสารอื่น ๆ</p>
              <UploadFileComponent
                name={'formInput.personalOptionalAttachment'}
                flodername={'DocumentPersonal'}
                valueShow={values.formInput.personalOptionalAttachment}
                id={values.formInput.id}
                label="อัปโหลดเอกสารอื่น  ๆ (JPEG,PNG,PDF)"
              />
            </div>
            <div className={styles.form}>
              <p className={styles.title}>{`แนบไฟล์สำเนาทะเบียนสมรส (ถ้ามี)`}</p>
              <UploadFileComponent
                name={'formInput.personalMarriageAttachment'}
                flodername={'DocumentPersonal'}
                valueShow={values.formInput.personalMarriageAttachment}
                id={values.formInput.id}
                label="อัปโหลดเอกสารสำเนาทะเบียนสมรส  (JPEG,PNG,PDF)"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default EmployeeDocument
