import { Backdrop, Divider, Fade, Modal, Popper, TextField, Tooltip } from '@mui/material'
import * as React from 'react'
import styles from './index.module.scss'
import Profile from './image/profileCard.svg'
import CloseIcon from '../../image/closeGray.svg'
import { DateStyleShort, toTime } from 'src/utils/common'
import { ELeave, ELeavForm } from '../AttendanceReport/EType'
import { ButtonComponent } from 'src/component'
import GlassIcon from './image/hourglass.svg'
import {
  InputDateComponentCustom,
  InputSelectComponentCustom,
  InputTextComponentCustom,
  InputTimeComponentCustom,
} from 'src/component/InputsFormik'
import { Formik } from 'formik'
import DeleteIcon from '../../image/deleteRedIcon.svg'
import swal from 'sweetalert'
import { DeleteLeaveDocument, GetLeaveDocument, UpdateLeaveDocument } from 'src/adapter/xhr'
import CheckStatusIcon from './image/checkstatus.svg'

export const ModalView: React.FC<any> = (props: any): JSX.Element => {
  const GetQuota = () => {
    if (props.LeaveDocument.length) {
      const LeaveData = props.LeaveDocument[0].employee.listLeaveQuota
      const LeaveType = props.LeaveDocument[0].leaveType
      const balanceQuota = LeaveData.items.find((ele: any) => ele.leaveConfig.id === LeaveType)?.balance
      return balanceQuota | 0
    }
  }
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalBox}>
        <div className={styles.card}>
          <section className={styles.GroupArea}>
            <nav className={styles.HeadText}>
              <p className={styles.Text}>รายละเอียด</p>
              <img src={CloseIcon} alt="" className={styles.Icon} onClick={() => props.setOpen(false)} />
            </nav>

            <section className={styles.GroupProfile}>
              <div className={styles.ProfileIcon}>
                {props.LeaveDocument[0]?.employee?.profileImage !== null ? (
                  <img
                    src={props.LeaveDocument[0]?.employee?.profileImage}
                    alt="profile"
                    style={{ borderRadius: '50%', width: '127px', height: '128px' }}
                  />
                ) : (
                  <img src={Profile} alt="profileFixed" />
                )}
                <div className={styles.border}>
                  <img src={GlassIcon} alt="" />
                  <p>{GetQuota()}</p>
                </div>
              </div>

              <div className={styles.GroupDetail}>
                {props.LeaveDocument?.map((item: any, index: number) => (
                  <>
                    <section className={styles.TextProfile} key={index}>
                      <p>
                        {item.employee.fullName} {item.employee.lastName}
                      </p>
                      <p className={styles.textSub}>{item.employee.id}</p>
                      <span className={styles.textHead}>
                        วันที่สร้างเอกสาร <span className={styles.text}>{DateStyleShort(item.createdAt)} </span>
                      </span>
                    </section>

                    <section className={styles.Detail}>
                      <aside className={styles.leftSide}>
                        <div className={styles.Group}>
                          <span className={styles.textHead}>รหัสเอกสาร </span>
                          <Tooltip title={<p>{item.id}</p>} placement="top" arrow>
                            <div className={styles.TextOverflow}>
                              <p>{item.id}</p>
                            </div>
                          </Tooltip>
                        </div>

                        <span className={styles.textHead}>
                          ประเภทการลา <span className={styles.textSub}>{ELeave[`${item.leaveType}`]}</span>
                        </span>
                        <span className={styles.textHead}>
                          วันที่เริ่ม <span className={styles.textSub}>{DateStyleShort(item.startedAt)}</span>
                        </span>
                        <span className={styles.textHead}>
                          หมายเหตุ <span className={styles.textSub}></span>
                        </span>
                      </aside>
                      <aside className={styles.leftSide}>
                        <div className={styles.Status}>
                          <span className={styles.textHead}>สถานะ</span>
                          <div
                            className={
                              item.status === 'APPROVE'
                                ? styles.pill
                                : item.status === 'REJECT'
                                ? styles.pillReject
                                : styles.pillPending
                            }
                          >
                            {item.status}
                          </div>
                        </div>

                        <span className={styles.textHead}>
                          รูปแบบการลา <span className={styles.textSub}>{ELeavForm[`${item.leaveForm}`]}</span>
                        </span>
                        <span className={styles.textHead}>
                          วันสิ้นสุด <span className={styles.textSub}>{DateStyleShort(item.endedAt)}</span>
                        </span>
                      </aside>
                    </section>
                  </>
                ))}
              </div>
            </section>
          </section>

          <div className={styles.FooterButton}>
            <ButtonComponent
              _colorBG="blue"
              _text="Close"
              _colorText="white"
              _type={'button'}
              _variant={'text'}
              _functionOnClick={() => props.setOpen(false)}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
export const ModalEdite: React.FC<any> = (props: any): JSX.Element => {
  const formikRef: any = React.useRef(null)
  const otherProps = props
  const [Leavelist, setLeavelist] = React.useState<any>(null)

  React.useEffect(() => {
    if (!props.open) return
    GetLeaveDocument({
      employeeId: props.employeeID,
      getLeaveId: props.LeaveID,
    }).then((res: any) => {
      const data = res.data.data.getLeave
      setLeavelist(data)
      console.log(res, 'resresres')
      if (data.date) {
        formikRef.current.setFieldValue('date', data.date)
      }
      if (data.startedAt) {
        formikRef.current.setFieldValue('startedAt', toTime(data.startedAt))
      }
      if (data.endedAt) {
        formikRef.current.setFieldValue('endedAt', toTime(data.endedAt))
      }
      if (data.leaveType) {
        formikRef.current.setFieldValue('leaveType', data.leaveType)
      }
    })
  }, [props.employeeID, props.LeaveID, props.open])

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Formik
        initialValues={{
          date: '',
          startedAt: '',
          endedAt: '',
          leaveType: '',
        }}
        innerRef={formikRef}
        onSubmit={(values, actions) => {
          console.log(values)
          const LeaveVariables = {
            input: {
              id: props.LeaveID,
              employeeID: props.employeeID,
              date: values.date,
              startedAt: values.startedAt,
              endedAt: values.endedAt,
              leaveType: values.leaveType,
            },
          }
          swal('', {
            title: 'บันทึกการแก้ไขรายการเอกสารการลา',
            text: '',
            buttons: ['ยกเลิก', 'บันทึกข้อมูล'],
            icon: 'warning',
          }).then((submit) => {
            if (submit) {
              UpdateLeaveDocument(LeaveVariables).then((res: any) => {
                console.log(res, 'Update OK')
              })
              swal('', {
                title: 'บันทึกการแก้ไขเรียบร้อยแล้ว',
                icon: 'success',
              })
              props.setOpen(false)
              setTimeout(() => {
                props.QueryListDocument()
              }, 1000)
            }
          })
        }}
      >
        {(props: any) => (
          <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
            <div className={styles.modalBox}>
              <div className={styles.card}>
                <section className={styles.GroupArea}>
                  <nav className={styles.HeadText}>
                    <p className={styles.Text}>แก้ไขรายละเอียด</p>
                    <img src={CloseIcon} alt="" className={styles.Icon} onClick={() => otherProps.setOpen(false)} />
                  </nav>

                  <section className={styles.GroupProfile}>
                    <div className={styles.ProfileIcon}>
                      {Leavelist && (
                        <>
                          {Leavelist.employee?.profileImage !== null ? (
                            <img
                              src={Leavelist.employee?.profileImage}
                              alt="profile"
                              style={{ borderRadius: '50%', width: '127px', height: '128px' }}
                            />
                          ) : (
                            <img src={Profile} alt="profile" />
                          )}
                        </>
                      )}

                      <div className={styles.border}>
                        <img src={GlassIcon} alt="" />
                        <p>32</p>
                      </div>
                    </div>

                    <div className={styles.GroupDetail}>
                      {Leavelist ? (
                        <section className={styles.TextProfile}>
                          <p>
                            {Leavelist.employee?.fullName} {Leavelist.employee?.lastName}
                          </p>
                          <p className={styles.textSub}>{Leavelist.employee?.id}</p>
                          <span className={styles.textHead}>
                            วันที่สร้างเอกสาร <span>{DateStyleShort(Leavelist?.createdAt)}</span>
                          </span>
                          <div className={styles.Status}>
                            <div
                              className={
                                Leavelist.status === 'APPROVE'
                                  ? styles.pill
                                  : Leavelist.status === 'REJECT'
                                  ? styles.pillReject
                                  : styles.pillPending
                              }
                            >
                              {Leavelist.status}
                            </div>
                          </div>
                        </section>
                      ) : (
                        ''
                      )}

                      <section className={styles.GroupInput}>
                        <section className={styles.Box}>
                          <p className={styles.textHead}>วันที่</p>
                          <InputDateComponentCustom _name="date" _label="วันที่" _value={props.values.date} />
                        </section>

                        <section className={styles.Box}>
                          <p className={styles.textHead}>ช่วงเวลา</p>
                          <div className={styles.BoxFlex}>
                            <InputTimeComponentCustom
                              _name="startedAt"
                              _label="เวลาเริ่ม"
                              _value={props.values.startedAt}
                            />
                            <InputTimeComponentCustom
                              _name="endedAt"
                              _label="เวลาสิ้นสุด"
                              _value={props.values.endedAt}
                            />
                          </div>
                        </section>

                        <section className={styles.BoxGrid}>
                          <p className={styles.textHead}>ประเภทการลา</p>
                          <>
                            <InputSelectComponentCustom
                              _name="leaveType"
                              _label={'ประเภทการลา'}
                              _menuList={[
                                { label: 'ลาป่วย', value: 'REASON_SICK' },
                                { label: 'ลาพักร้อน', value: 'REASON_ANNUAL_LEAVE' },
                                { label: 'ลากิจ', value: 'REASON_PERSONAL_LEAVE' },
                                {
                                  label: 'ขาด',
                                  value: 'ABSENT',
                                },
                              ]}
                              _value={props.values.leaveType}
                            />
                          </>
                        </section>

                        <section className={styles.Box}>
                          <p className={styles.textHead}>จำนวนชั่วโมง</p>
                          <>
                            <InputTextComponentCustom
                              _name="minutes"
                              _disabled={true}
                              // _value={DateToMinutes(
                              //   `${props.values.date}T${props.values.startedAt}+007`,
                              //   `${props.values.date}T${props.values.endedAt}+007`,
                              // )}
                            />
                          </>
                        </section>
                      </section>
                    </div>
                  </section>
                </section>

                <div className={styles.FooterButton}>
                  <img
                    src={DeleteIcon}
                    alt="del"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      swal('', {
                        icon: 'warning',
                        title: 'ลบเอกสารการลา',
                        text: 'ไม่สามารถเปลี่ยนแปลงการแก้ไขได้',
                        buttons: ['ยกเลิก', 'ลบข้อมูล'],
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          DeleteLeaveDocument({
                            input: { id: otherProps.employeeID, employeeID: otherProps.LeaveID },
                          }).then((res: any) => {
                            console.log(res, 'Delete OK')
                          })
                          otherProps.setOpen(false)
                          swal('', {
                            icon: 'success',
                            title: 'ลบรายการเอกสารเรียบร้อยแล้ว',
                          })
                          setTimeout(() => {
                            otherProps.QueryListDocument()
                          }, 1000)
                        }
                      })
                    }}
                  />
                  <ButtonComponent
                    _colorBG="blue"
                    _text="บันทึก"
                    _colorText="white"
                    _type={'submit'}
                    _variant={'text'}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
export const PopperFilter: React.FC<any> = (props: any): JSX.Element => {
  const [showSelect, setShowSelect] = React.useState(false)
  const [countFilter, setCounter] = React.useState<any>([])

  const otherProps = props

  const handleClickType = (value: any) => {
    if (otherProps.fillLeavtype !== value) {
      otherProps.setfillLeavtype(value)
    } else {
      otherProps.setfillLeavtype('')
    }
  }

  const handleClickForm = (value: any) => {
    if (otherProps.fillLeavForm !== value) {
      otherProps.setfillLeavForm(value)
    } else {
      otherProps.setfillLeavForm('')
    }
  }

  const handleClickStatus = (value: any) => {
    if (otherProps.setfillStatus !== value) {
      otherProps.setfillStatus(value)
    } else {
      otherProps.setfillStatus('')
    }
  }
  return (
    <Popper transition placement="bottom" anchorEl={props.anchorEl} open={props.openpopper}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div className={styles.Popper}>
            {/* <Formik
              initialValues={{
                startdate: '',
                enddate: '',
              }}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  props.setOpenPopper(false)
                  props.QueryListDocument()
                }, 1000)
              }}
            >
              {(props: any) => ( */}
            <div className={styles.Popper}>
              <div className={styles.pointer}></div>
              <div className={styles.modal}>
                <div className={styles.modalGroup}>
                  <section className={styles.Header}>
                    <p>ค้นหาแบบละเอียด</p>
                    <img
                      src={CloseIcon}
                      alt=""
                      onClick={() => {
                        otherProps.setOpenPopper(false)
                      }}
                    />
                  </section>

                  <div className={styles.GroupDetail}>
                    <section className={styles.BoxFilter}>
                      <p style={{ fontSize: '16px' }}>ค้นหาตามประเภท</p>
                      <div className={styles.GroupCard}>
                        {LeaveType.map((item: any, index: number) => (
                          <div
                            className={item.value === otherProps.fillLeavtype ? styles.CardBlue : styles.Card}
                            key={index}
                            onClick={() => {
                              handleClickType(item.value)
                            }}
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </section>
                    <Divider />

                    <section className={styles.BoxFilter}>
                      <p style={{ fontSize: '16px' }}>ค้นหาตามรูปแบบการลา</p>
                      <div className={styles.GroupCard}>
                        {LeaveForm.map((item: any, index: number) => (
                          <div
                            className={item.value === otherProps.fillLeavForm ? styles.CardBlue : styles.Card}
                            key={index}
                            onClick={() => {
                              handleClickForm(item.value)
                            }}
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </section>

                    <Divider />

                    <section className={styles.BoxFilter}>
                      <p style={{ fontSize: '16px' }}>ช่วงวันที่</p>
                      <div className={styles.GroupCardDate}>
                        <div className={styles.InputBox}>
                          <TextField
                            InputProps={{
                              style: {
                                fontFamily: 'KanitRegular',
                                color: 'gray',
                                borderRadius: 4,
                              },
                            }}
                            fullWidth
                            size="small"
                            type="date"
                            label="วันที่เริ่ม"
                            name="startdate"
                            value={otherProps.fillStartdate}
                            onChange={(e) => {
                              otherProps.setfillStartdate(e.target.value)
                              console.log(e.target.value, 'e')
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: 14, fontFamily: 'KanitRegular' },
                            }}
                          />
                        </div>
                        <p>-</p>
                        <div className={styles.InputBox}>
                          <TextField
                            InputProps={{
                              style: {
                                fontFamily: 'KanitRegular',
                                color: 'gray',
                                borderRadius: 4,
                              },
                            }}
                            fullWidth
                            size="small"
                            type="date"
                            label="วันที่สิ้นสุด"
                            name="enddate"
                            value={otherProps.fillEnddate}
                            onChange={(e) => {
                              otherProps.setfillEnddate(e.target.value)
                              console.log(e.target.value, 'e')
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: 14, fontFamily: 'KanitRegular' },
                            }}
                          />
                        </div>
                      </div>
                    </section>

                    <Divider />

                    <section className={styles.BoxFilter}>
                      <p style={{ fontSize: '16px' }}>สถานะ</p>
                      <div className={styles.GroupPill}>
                        {Status.map((item: any, index: number) => (
                          <div className={styles.Status} key={index}>
                            <div
                              className={
                                item.status === 'APPROVE'
                                  ? styles.pill
                                  : item.status === 'REJECT'
                                  ? styles.pillReject
                                  : styles.pillPending
                              }
                              onClick={() => {
                                handleClickStatus(item.status)
                              }}
                            >
                              {item.status}
                              {item.status === otherProps.fillStatus ? (
                                <img src={CheckStatusIcon} alt="checkstatus" className={styles.checkIcon} />
                              ) : null}
                            </div>
                          </div>
                        ))}
                      </div>
                    </section>
                  </div>

                  <section className={styles.FooterButton}>
                    <ButtonComponent
                      _colorBG="white"
                      _text="ล้าง"
                      _type="button"
                      _variant="outlined"
                      _colorText="red"
                      _functionOnClick={() => {
                        otherProps.setfillLeavtype('')
                        otherProps.setfillLeavForm('')
                        otherProps.setfillStartdate('')
                        otherProps.setfillEnddate('')
                        otherProps.setfillStatus('')
                      }}
                    />
                    <ButtonComponent
                      _colorBG="blue"
                      _text="ตกลง"
                      _type="button"
                      _variant="contained"
                      _colorText="white"
                      _functionOnClick={() => {
                        setTimeout(() => {
                          otherProps.setOpenPopper(false)
                          otherProps.QueryListDocument()
                        }, 1000)
                      }}
                    />
                  </section>
                </div>
              </div>
            </div>
            {/* )}
            </Formik> */}
          </div>
        </Fade>
      )}
    </Popper>
  )
}

const LeaveType = [
  {
    label: 'ลาป่วย',
    value: 'REASON_SICK',
  },
  {
    label: 'ลากิจ',
    value: 'REASON_PERSONAL_LEAVE',
  },
  {
    label: 'ลากิจไม่หักเงิน',
    value: 'REASON_PERSONAL_LEAVE_WITHOUT_DEDUCT_MONEY',
  },
  {
    label: 'ลาพักร้อน',
    value: 'REASON_ANNUAL_LEAVE',
  },
]

const LeaveForm = [
  { label: 'ลาเต็มวัน', value: 'FULLDAY_LEAVE' },
  { label: 'ลาครึ่งวันเช้า', value: 'HALFDAY_MORNING_LEAVE' },
  { label: 'ลาครึ่งวันบ่าย', value: 'HALFDAY_AFTERNOON_LEAVE' },
  { label: 'ลาแบบกำหนดเอง', value: 'CUSTOM_TIME' },
]

const Status = [
  {
    status: 'APPROVE',
  },
  {
    status: 'PENDING',
  },
  {
    status: 'REJECT',
  },
]
