import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IInitialStatePackage {
  itemsList: []
  itemsListOption: any[]
  refetch: () => void
}
const initialState: IInitialStatePackage = {
  itemsList: [],
  itemsListOption: [],
  refetch: () => null,
}

export const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    setPackage: (state, action: PayloadAction<any>) => {
      state.itemsList = action.payload.items
      state.itemsListOption = action.payload.items?.map((data: any) => ({
        value: data.packageGroupID,
        label: data.name,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setPackage } = packageSlice.actions

export default packageSlice.reducer
