import { SocialSecurityConfig, PutSocialSecurityConfigInput } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationPutSocialSecurityConfig } from '../xhr/mutation'
import { queryGetSocialSecurityConfig } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function getSocialSecurityConfig() {
  return gqlResponseType<SocialSecurityConfig>(
    post('/', {
      query: queryGetSocialSecurityConfig,
    }),
    'getSocialSecurityConfig',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------

export function putSocialSecurityConfig(input: PutSocialSecurityConfigInput) {
  return gqlResponseType<SocialSecurityConfig>(
    post('/', {
      query: mutationPutSocialSecurityConfig,
      variables: { input },
    }),
    'putSocialSecurityConfig',
  )
}
