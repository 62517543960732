import {
  CreateOtherIncomeOrExpenseConfigInput,
  OtherIncomeOrExpenseConfig,
  UpdateOtherIncomeOrExpenseConfigInput,
} from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationCreateOtherIncomeExpenseConfig, mutationUpdateOtherIncomeExpenseConfig } from '../xhr/mutation'
import { queryListOtherIncomeOrExpenseConfig } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function getListOtherIncomeOrExpenseConfig() {
  return gqlResponseType<OtherIncomeOrExpenseConfig>(
    post('/', {
      query: queryListOtherIncomeOrExpenseConfig,
    }),
    'listOtherIncomeOrExpenseConfig',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------

export function createOtherIncomeOrExpenseConfig(input: CreateOtherIncomeOrExpenseConfigInput) {
  return gqlResponseType<OtherIncomeOrExpenseConfig>(
    post('/', {
      query: mutationCreateOtherIncomeExpenseConfig,
      variables: { input },
    }),
    'createOtherIncomeOrExpenseConfig',
  )
}

export function updateOtherIncomeOrExpenseConfig(input: UpdateOtherIncomeOrExpenseConfigInput) {
  return gqlResponseType<OtherIncomeOrExpenseConfig>(
    post('/', {
      query: mutationUpdateOtherIncomeExpenseConfig,
      variables: { input },
    }),
    'updateOtherIncomeOrExpenseConfig',
  )
}
