import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { ButtonComponent, PopperContainer, InputSelectComponent } from '../../component'
import CustomizedSwitches from '../../component/AntSwitch'
import { Box, Button, Grid, Paper, Stack } from '@mui/material'
import { FieldArray, Form, FormikProvider, useFormik, useFormikContext } from 'formik'
import {
  EEmployeeType,
  ERoundType,
  ESex,
  WithdrawalConfig,
  UpdateWithdrawalConfig,
  WithdrawalQuota,
  EWelfareQuotaUnit,
} from '../../utils/generated'
import {
  createWithdrawalConfig,
  deleteWithdrawalConfig,
  getListWithdrawalConfig,
  updateWithdrawalConfig,
} from '../../adapter/api'
import { classes, deepClone, remove__typename } from '../../utils/common'
import {
  FastFieldProvider,
  InputCheckboxComponentCustom,
  InputNumberComponent,
  InputRadioComponent,
  InputTextComponent,
} from '../../component/InputsFormik'
import styled from '@emotion/styled'
import AddIcon from '../../image/add_circle.svg'
import QuestionIcon from '../../image/question.svg'
import DeleteIcon from '../../image/deleteRedIcon.svg'
import MinusIcon from '../../image/minus_circle.svg'

import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useFormikAlertError } from 'src/hooks/useFormikAlertError'
import ReactDOM from 'react-dom'

type IValue = {
  withdrawalConfigList: WithdrawalConfig[]
  selectConfigId: string | null
  currentConfig?: WithdrawalConfig
  isShowDetail: boolean
}

const initialWithdrawalQuota: WithdrawalQuota = {
  workMonthExperience: 0,
  quota: 0,
  quotaUOM: EWelfareQuotaUnit.BahtPerMonth,
}

const isEmpty = (value: any) => value === null || value === undefined || value === ''

const filterEmptyQuota = (quotaList?: WithdrawalQuota[]) => {
  return quotaList?.filter((q) => !isEmpty(q.workMonthExperience) && !isEmpty(q.quota) && !isEmpty(q.quotaUOM))
}

const WithdrawalPolicyPage = () => {
  const onSubmit = async (values: IValue) => {
    const input = deepClone(values.currentConfig!) as UpdateWithdrawalConfig

    // if (!input.withdrawalQuotaList) input.withdrawalQuotaList = []

    input.withdrawalQuotaList = filterEmptyQuota(input.withdrawalQuotaList as WithdrawalQuota[])

    remove__typename(input)

    try {
      if (input.withdrawalID) {
        await updateWithdrawalConfig(input)
        await fetchWithdrawalConfig()
      } else {
        const res = await createWithdrawalConfig(input)

        await fetchWithdrawalConfig()

        ReactDOM.flushSync(() => {
          formik.setFieldValue('selectConfigId', res.withdrawalID)
          formik.setFieldValue('currentConfig.withdrawalID', res.withdrawalID)
        })
      }

      Swal.fire({ title: 'Saved', icon: 'success' })
    } catch (error: any) {
      Swal.fire({ title: 'Error', icon: 'error', text: JSON.stringify(error.message) })
      console.log('error', error)
    }
  }

  const initialValues: IValue = {
    withdrawalConfigList: [],
    selectConfigId: null,
    isShowDetail: false,
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const fetchWithdrawalConfig = async () => {
    const res = await getListWithdrawalConfig()
    formik.setFieldValue('withdrawalConfigList', res.items)
  }

  React.useEffect(() => {
    fetchWithdrawalConfig()
  }, [])

  const handleDelete = async (id: string) => {
    const willDelete = await Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    })

    if (!willDelete.isConfirmed) return

    try {
      await deleteWithdrawalConfig({ withdrawalID: id })
      await fetchWithdrawalConfig()

      Swal.fire({ title: 'Deleted', icon: 'success' })
      formik.setFieldValue('isShowDetail', false)
      formik.setFieldValue('selectConfigId', null)
    } catch (error: any) {
      Swal.fire({ title: 'Error', icon: 'error' })
    }
  }

  return (
    <div>
      <div className="main">
        <FormikProvider value={formik}>
          <>
            <div className={styles.container}>
              <section className={styles.topicPageWrapper}>
                <p className={styles.topicPage}>การตั้งค่าการเบิก</p>
              </section>
              <div className={styles.contentContainer}>
                <ConfigTabList />
                <ConfigForm onDelete={handleDelete} />
              </div>
            </div>
          </>
        </FormikProvider>
      </div>
    </div>
  )
}
const ConfigTabList = () => {
  const formik = useFormikContext<IValue>()

  const handleCreateConfig = () => {
    const { setFieldValue } = formik

    setFieldValue('currentConfig', {
      welfareConfigList: [deepClone(initialWithdrawalQuota)],
    })
    setFieldValue('isShowDetail', true)
    setFieldValue('selectConfigId', null)
  }

  const isActive = React.useCallback(
    (id: string) => {
      return id === formik.values.selectConfigId
    },
    [formik.values.selectConfigId],
  )

  const handleSelectConfig = (id: string) => {
    const { values, setFieldValue } = formik

    setFieldValue('selectConfigId', id)

    const currentConfig = values.withdrawalConfigList.find((e) => e.withdrawalID === id)!

    if (!currentConfig?.withdrawalQuotaList?.length) currentConfig.withdrawalQuotaList = [initialWithdrawalQuota]

    setFieldValue('currentConfig', deepClone(currentConfig))
    setFieldValue('isShowDetail', true)
  }

  const handleToggle = async (id: string, status: boolean) => {
    const idx = formik.values.withdrawalConfigList.findIndex((e) => e.withdrawalID === id)
    const leave = formik.values.withdrawalConfigList[idx]
    leave.isActive = status
    formik.setFieldValue(`withdrawalConfigList[${idx}]`, leave)

    try {
      const input = deepClone(leave) as UpdateWithdrawalConfig
      remove__typename(input)
      formik.setFieldValue('currentConfig.isActive', status)
      await updateWithdrawalConfig(input)
      Swal.fire({ title: 'Saved', icon: 'success' })
    } catch (error: any) {
      Swal.fire({ title: 'Error', icon: 'error', text: JSON.stringify(error) })
    }
  }

  return (
    <section className={styles.TabList}>
      <div className={styles.AreaList}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <p className={styles.title}>ค่าใช้จ่ายที่เพิ่มแล้ว</p>
          <ButtonComponent
            _colorBG="blue"
            _colorText="white"
            _text="เพิ่มค่าใช้จ่าย"
            _type="button"
            _variant="text"
            _isIcon="add"
            _sx={{ width: 'fit-content', fontSize: '18px', px: 1.5 }}
            _functionOnClick={handleCreateConfig}
          />
        </Stack>

        <div className={styles.GroupList}>
          <p className={styles.headText}>ประเภทค่าใช้จ่าย</p>
          <div className={styles.CardList}>
            {formik.values.withdrawalConfigList?.map((item, index) => (
              <TabMenuItem
                onToggle={handleToggle}
                key={index}
                item={item}
                isActive={isActive(item.withdrawalID!)}
                onSelectConfig={handleSelectConfig}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

interface TabMenuItemProps {
  item: WithdrawalConfig
  isActive: boolean
  onSelectConfig: (id: string) => any
  onToggle: (id: string, status: boolean) => void
}

const TabMenuItem: React.FC<TabMenuItemProps> = ({ item, onSelectConfig, isActive, onToggle }) => {
  return (
    <div
      className={classes(styles.card, isActive && styles.cardActive)}
      onClick={onSelectConfig.bind(null, item.withdrawalID!)}
    >
      <p>{item.name}</p>

      <CustomizedSwitches
        _name=""
        _checked={!!item.isActive}
        onChange={(checked) => onToggle(item.withdrawalID!, checked)}
      />
    </div>
  )
}

interface ConfigFormProps {
  onDelete: (id: string) => any
}
const ConfigForm: React.FC<ConfigFormProps> = (props) => {
  const [prevConfig, setPrevConfig] = React.useState<WithdrawalConfig>()

  const formikContext = useFormikContext<IValue>()

  const initialValues: Partial<WithdrawalConfig> = {
    roundType: ERoundType.Round,
  }
  const onSubmit = () => {
    formikContext.setFieldValue('currentConfig', formik.values)
    formikContext.submitForm()
  }

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      name: yup.string().required(),
    }),
    onSubmit,
  })

  useFormikAlertError(formik)

  useEffect(() => {
    if (!formikContext.values.selectConfigId) {
      formik.setValues({ ...initialValues, withdrawalQuotaList: [initialWithdrawalQuota] })
      return
    }
    if (!formikContext.values.currentConfig?.withdrawalQuotaList?.length) {
      formikContext.values.currentConfig!.withdrawalQuotaList = [initialWithdrawalQuota]
    }

    formik.setValues(formikContext.values.currentConfig!)
    setPrevConfig(deepClone(formikContext.values.currentConfig!))
  }, [formikContext.values.selectConfigId, formikContext.values.currentConfig?.isActive])

  const handleToggleList = <T,>(list: T[], item: T, findCb?: (item: T) => boolean): T[] => {
    const _list = deepClone<T[]>(list)
    const idx = _list.findIndex((e) => {
      if (findCb) return findCb(e)
      return e === item
    })
    if (idx === -1) _list.push(item)
    else _list.splice(idx, 1)
    return _list
  }

  const handleSelectToggleAllEmployeeType = () => {
    if (formik.values.employeeAllowTypeList?.length === EMPLOYEE_TYPES.length) {
      return formik.setFieldValue('employeeAllowTypeList', [])
    }
    formik.setFieldValue(
      'employeeAllowTypeList',
      EMPLOYEE_TYPES.map((e) => e.value),
    )
  }

  const handleReset = () => {
    formik.setValues(deepClone(prevConfig!))
  }

  return (
    <FormikProvider value={formik}>
      <FastFieldProvider>
        <section className={styles.DataShow}>
          {formikContext.values.isShowDetail && (
            <Form>
              <Grid container columnSpacing={2} columns={2} alignItems="center">
                <Grid item xs={1}>
                  <Stack direction="row" alignItems="center" columnGap="1rem">
                    <Box sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>ชื่อประเภท</Box>
                    <InputTextComponent _label="" _name="name" />
                  </Stack>
                </Grid>
              </Grid>
              <Stack direction="row" mt="2rem">
                <Stack>
                  <Box sx={{ fontWeight: 'bold' }}>ตั้งค่าการปัดเศษ</Box>
                  <InputRadioComponent
                    _row
                    _name="roundType"
                    _menuList={[
                      { value: ERoundType.None, label: 'ไม่ปัดเศษ' },
                      { value: ERoundType.Round, label: 'ปัดเศษ' },
                      { value: ERoundType.Floor, label: 'ปัดเศษทิ้ง' },
                    ]}
                  />
                </Stack>
              </Stack>
              <Divider />
              <Grid container columns={10}>
                <Grid item xs={4}>
                  <Stack rowGap={2}>
                    <Box sx={{ fontWeight: 'bold' }}>เพศที่พนักงาน</Box>
                    <Stack>
                      {EMPLOYEE_SEX.map((employeeSex) => (
                        <InputCheckboxComponentCustom
                          key={employeeSex.value}
                          _name="employeeAllowSexList"
                          _label={employeeSex.label}
                          _value={formik.values.employeeAllowSexList?.includes(employeeSex.value)}
                          _onChange={() => {
                            const list = handleToggleList(formik.values.employeeAllowSexList || [], employeeSex.value)
                            formik.setFieldValue('employeeAllowSexList', list)
                          }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack rowGap={2}>
                    <Stack direction="row" justifyContent="space-between">
                      <Box sx={{ fontWeight: 'bold' }}>ประเภทพนักงาน</Box>
                      <Box
                        sx={{ color: '#3a77da', textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={handleSelectToggleAllEmployeeType}
                      >
                        {formik.values.employeeAllowTypeList?.length === EMPLOYEE_TYPES.length && 'เลิก'}
                        เลือกทั้งหมด
                      </Box>
                    </Stack>
                    <Grid container columnGap={4} display="grid" gridTemplateColumns={'1fr 1fr'}>
                      {EMPLOYEE_TYPES.map((employeeType) => (
                        <InputCheckboxComponentCustom
                          key={employeeType.value}
                          _name="employeeAllowTypeList"
                          _label={employeeType.label}
                          _value={formik.values.employeeAllowTypeList?.includes(employeeType.value)}
                          _onChange={() => {
                            const list = handleToggleList(formik.values.employeeAllowTypeList || [], employeeType.value)
                            formik.setFieldValue('employeeAllowTypeList', list)
                          }}
                        />
                      ))}
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
              <Divider />
              <>
                <Box sx={{ fontWeight: 'bold' }} mb={2}>
                  เงื่อนไข
                </Box>
                <Grid container columns={{ xs: 7 }} columnSpacing={4}>
                  <Grid item xs={2} mb={1}>
                    <p>ระยะเวลา (เดือน)</p>
                  </Grid>
                  <Grid item xs={2} mb={1}>
                    <p>งบประมาน</p>
                  </Grid>
                  <Grid item xs={2} mb={1}>
                    <p>หน่วย</p>
                  </Grid>
                  <FieldArray
                    name="withdrawalQuotaList"
                    render={(arrHelper) => (
                      <>
                        {(formik.values.withdrawalQuotaList || []).map((quota, index) => (
                          <React.Fragment key={index}>
                            <Grid item xs={2} mb={1}>
                              <InputNumberComponent
                                _name={`withdrawalQuotaList[${index}].workMonthExperience`}
                                _label=""
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <InputNumberComponent _name={`withdrawalQuotaList[${index}].quota`} _label="" />
                            </Grid>
                            <Grid item xs={3}>
                              <Stack direction="row" columnGap={2} alignItems="center">
                                <InputSelectComponent
                                  _label=""
                                  _name={`withdrawalQuotaList[${index}].quotaUOM`}
                                  _menuList={[
                                    // {
                                    //   label: 'บาท/วัน',
                                    //   value: EWelfareQuotaUnit.BahtPerDay,
                                    // },
                                    {
                                      label: 'บาท/เดือน',
                                      value: EWelfareQuotaUnit.BahtPerMonth,
                                    },
                                    {
                                      label: 'บาท/ปี',
                                      value: EWelfareQuotaUnit.BahtPerYear,
                                    },
                                  ]}
                                />
                                {index === (formik.values.withdrawalQuotaList?.length || 0) - 1 ? (
                                  <img
                                    className={styles.addIcon}
                                    src={AddIcon}
                                    onClick={() => arrHelper.push(initialWithdrawalQuota)}
                                  />
                                ) : (
                                  <img
                                    className={styles.addIcon}
                                    src={MinusIcon}
                                    onClick={() => arrHelper.remove(index)}
                                  />
                                )}
                              </Stack>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  />
                </Grid>
              </>
              <Divider />
              <Stack rowGap={2}>
                <Grid container columns={2} columnSpacing={8} alignItems="center" minHeight="2.75rem">
                  <SwitchItem
                    name="isCountProbation"
                    label="นับอายุช่วงทดลองงาน"
                    popperText="กำหนดให้อายุงานเริ่มนับตั้งแต่ช่วงทดลองงาน สำหรับคำนวณสิทธิสวัสดิการ"
                  />
                </Grid>
                <Grid container columns={2} columnSpacing={8} alignItems="center" minHeight="2.75rem">
                  <SwitchItem name="isShare" label="แชร์ร่วมกันได้" popperText="แชร์ร่วมกันได้" />
                </Grid>
              </Stack>
              <Divider />
              <Stack alignItems="center" justifyContent="space-between" direction="row">
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <ButtonComponent
                    _colorBG="gray"
                    _colorText="white"
                    _text="รีเซต"
                    _type="button"
                    _variant="text"
                    _sx={{ width: 'fit-content', fontSize: '1rem' }}
                    _functionOnClick={handleReset}
                  />
                  <ButtonComponent
                    _colorBG="blue"
                    _colorText="white"
                    _text="บันทึก"
                    _type="submit"
                    _variant="text"
                    _sx={{ width: 'fit-content', fontSize: '1rem' }}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" columnGap={1}>
                  {formik.values.withdrawalID && (
                    <Button
                      sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        columnGap: 1,
                        color: '#d32f2f',
                        ':hover': {
                          backgroundColor: '#FFF5F8',
                        },
                      }}
                      onClick={props.onDelete.bind(null, formik.values.withdrawalID!)}
                      type="button"
                    >
                      <img className={styles.DeleteIcon} src={DeleteIcon} />
                      <p>ลบประเภทค่าใช้จ่าย</p>
                    </Button>
                  )}
                  <Stack direction="row" alignItems="center" columnGap={1}>
                    เปิดใช้งาน
                    <CustomizedSwitches.Formik _name="isActive" />
                  </Stack>
                </Stack>
              </Stack>
            </Form>
          )}
        </section>
      </FastFieldProvider>
    </FormikProvider>
  )
}

interface SwitchItemProps {
  name: string
  label: string
  popperText: string
}
const SwitchItem: React.FC<SwitchItemProps> = ({ name, label, popperText }) => {
  return (
    <Grid item xs={1}>
      <Stack alignItems="center" flexGrow={1} justifyContent="space-between" direction="row">
        <Stack direction="row" alignItems="center" columnGap={1}>
          <p>{label}</p>
          <SwitchPopper text={popperText} />
        </Stack>
        <CustomizedSwitches.Formik _name={name} />
      </Stack>
    </Grid>
  )
}
interface SwitchPopperProps {
  text: string
}

const SwitchPopper: React.FC<SwitchPopperProps> = ({ text }) => {
  return (
    <PopperContainer button={<img className={styles.questionIcon} src={QuestionIcon} />} placement="right">
      <Paper sx={{ width: '12rem', ml: '0.5rem', p: '0.5rem' }}>{text}</Paper>
    </PopperContainer>
  )
}

const EMPLOYEE_TYPES = [
  {
    value: EEmployeeType.FulltimeMonth,
    label: 'พนักงานรายเดือน',
  },
  {
    value: EEmployeeType.FulltimeDay,
    label: 'พนักงานรายวัน',
  },

  {
    value: EEmployeeType.Parttime,
    label: 'พนักงานพาร์ทไทม์',
  },
  {
    value: EEmployeeType.Outsource,
    label: 'พนักงานรายจ้างเหมา',
  },
]

const EMPLOYEE_SEX = [
  {
    value: ESex.Male,
    label: 'พนักงานเพศชาย',
  },
  {
    value: ESex.Female,
    label: 'พนักงานเพศหญิง',
  },
]

const Divider = styled.hr`
  margin: 2rem 0;
  border: 0;
  border-top: 1px solid #e9e9e9;
`

export default WithdrawalPolicyPage
