import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { withFormik, useFormikContext, Form } from 'formik'
import Banner01 from '../../image/bannerLoginPage01.png'
import Eye02 from '../../image/eyeLoginPage02.png'
import EyeClose03 from '../../image/eyeCloseLoginPage03.png'
import BannerMobile04 from '../../image/bannerLoginPage04.png'
import ModalForgot from './modalForgot'
import { connect, useSelector } from 'react-redux'
import { ICombineReducers } from 'src/state/reducers'
import { bindActionCreators, Dispatch } from 'redux'
import { logInUser } from 'src/state/reducers/login'
// import { Login } from '../../adapter/xhr'
import swal from 'sweetalert'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useCompanyID from 'src/hooks/useCompanyID'

const LoginAdminPage: React.FC = (): JSX.Element => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  // const { companyID } = useCompanyID()

  const [isWaitResponse, setIsWaitResponse] = useState(false)
  const { isLoggingIn, isLoggedIn, decodedAccessToken } = useSelector((state: ICombineReducers) => state.loginReducer)

  useEffect(() => {
    if (!isWaitResponse && isLoggingIn) {
      setIsWaitResponse(true)
    }
    if (isWaitResponse && !isLoggingIn) {
      // Time to Success or Fail
      if (isLoggedIn) {
        swal('เข้าสู่ระบบ', ``, 'success', {
          // buttons: {
          //   default: false,
          // },
          timer: 700,
        }).then(() => {
          // THIS NEEDS TO CHECK WHETHER IT"S ADMIN OR EMPLOYEE
          // Edit: This should be in the latest module state, but just default to hr first!
          if (decodedAccessToken.roleType.toUpperCase() === 'ADMIN') navigate('/admin/hr')
          else navigate('/employee')
        })
      } else {
        swal({
          icon: 'error',
          title: 'ข้อมูลการเข้าสู่ระบบไม่ถูกต้อง',
          text: 'กรุณาลองอีกครั้ง',
          dangerMode: true,
        })
      }
      setIsWaitResponse(false)
    }
  }, [isWaitResponse, isLoggingIn])

  return (
    <>
      <Form className={styles.container}>
        <img className={styles.bannerDesktop} src={Banner01} alt="bannerDesktop" />
        <img className={styles.bannerMobile} src={BannerMobile04} alt="bannerDesktop" />

        <div className={styles.wrapperForm}>
          <h1>เข้าสู่ระบบ {isLoggingIn && <CircularProgress />}</h1>

          <StepLoginTwo />
        </div>
      </Form>
      <ModalForgot open={open} setOpen={setOpen} />
    </>
  )
}

const EnhancedLoginAdminPage = withFormik({
  mapPropsToValues: () => ({
    // domain: '',
    username: '',
    password: '',

    // stepID: 2,
  }),
  validate: (values: any) => {
    const errors: any = {}

    // if (values.stepID === 1) {
    //   if (!values.domain) {
    //     errors.domain = 'กรุณากรอกโดเมน'
    //   }
    // } else if (values.stepID === 2) {
    if (!values.username) {
      errors.username = 'กรุณากรอกชื่อผู้ใช้งาน'
    }
    if (!values.password) {
      errors.password = 'กรุณากรอกรหัสผ่าน'
    }
    // }

    return errors
  },
  handleSubmit: async (values: any, { props }: any) => {
    const { logInUser } = props
    const companyID = useCompanyID()
    logInUser(values.username, values.password, companyID)
  },
})(LoginAdminPage)

const mapStateToProps = (state: ICombineReducers) => {
  return {
    // companyID: state.companyIDReducer.companyID
  }
}
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logInUser,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedLoginAdminPage)

// const StepLoginOne = ({ handleControlStep }: any) => {
//   const { values, touched, errors, setFieldValue }: any = useFormikContext()

//   return (
//     <>
//       <p className={styles.labelInput}>
//         ชื่อโดเมน <span className={styles.errMsg}>{touched.domain && errors.domain && errors.domain}</span>
//       </p>
//       <div className={styles.wrapperDomainInput}>
//         <label className={styles.httpsBox}>https://</label>
//         <input
//           type={'text'}
//           className={styles.domainInput}
//           placeholder="example.com"
//           value={values.domain}
//           onChange={(e) => setFieldValue('domain', e.target.value, true)}
//         />
//       </div>

//       <button type="button" className={styles.btn} onClick={() => handleControlStep('NEXT')}>
//         ตกลง
//       </button>
//     </>
//   )
// }

interface IContext {
  username: string
  password: string
}

const StepLoginTwo = () => {
  const { values, touched, errors, setFieldValue } = useFormikContext<IContext>()

  const [showPass, setShowPass] = useState(false)
  return (
    <>
      <p className={styles.labelInput}>
        Username<span className={styles.errMsg}>{touched.username && errors.username && errors.username}</span>
      </p>
      <input
        type={'text'}
        className={styles.templatInput}
        placeholder="Username"
        value={values.username}
        onChange={(e) => setFieldValue('username', e.target.value.trim(), true)}
      />
      <p className={styles.labelInput}>
        รหัสผ่าน<span className={styles.errMsg}>{touched.password && errors.password && errors.password}</span>
      </p>
      <div className={styles.inputPassword}>
        <input
          type={!showPass ? 'password' : 'text'}
          className={styles.templatInput}
          placeholder="รหัสผ่าน"
          value={values.password}
          onChange={(e) => setFieldValue('password', e.target.value, true)}
        />
        <img
          className={styles.eyeIcon}
          src={!showPass ? EyeClose03 : Eye02}
          onClick={() => setShowPass(!showPass)}
          alt="Eye02"
        />
      </div>
      <div className={styles.wrapperCheckBox}>
        <input type="checkbox" className={styles.checkbox} />
        <span>จดจำการเข้าสู่ระบบของฉัน</span>
      </div>

      <button type="submit" className={styles.btn}>
        เข้าสู่ระบบ
      </button>

      <p className={styles.resetPassword}>รีเซ็ตรหัสผ่าน</p>
    </>
  )
}
