import React, { useRef, useState } from 'react'
import styles from './index.module.scss'
// import { Formik } from 'formik'
import blueCalendar from '../../image/blueCalendar.svg'
// import * as yup from 'yup'
import { createTimeAttendanceBatch, getTimeAttendanceStreamCount } from '../../adapter/xhr'
import { ButtonComponent } from '../../component'
import { packCSVFile } from '../../utils/common'
// import TextField from '@mui/material/TextField'
// import CircularProgress from '@mui/material/CircularProgress'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
// import moment from 'moment'
import { PaginationBar } from '../../component'
import { ModelSortDirection, TimeAttendance } from '../../utils/generated'
import { TableAttend } from './Table'
import { gql } from '@apollo/client'
import usePaginationQuery from 'src/hooks/usePaginationQuery'

const AttendanceLogPage: React.FC = (): JSX.Element => {
  // const { setFieldValue } = useFormikContext<any>()
  const [logProcessed, setLogProcessed] = useState<number>(0)
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false)
  const [logTotal, setLogTotal] = useState<number>(0)
  const [isDateRangeOpen, setIsDateRangeOpen] = useState<boolean>(false)

  const inputFile = useRef<any>()

  // Pagination State
  const {
    currentTableData,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    windowSize,
    maxHasNextToken,
    currentMaxPageIndex,
    loading,
    clearCacheRefetch,
  } = usePaginationQuery<TimeAttendance>({
    query: GET_ATTEND_LOG,
    convertRefetch: (s) => s.listTimeAttendance,
    additionalVariables: { sortDirection: ModelSortDirection.Desc },
  })

  // Date Range for Selection in CSV Import
  const myCurrentDate = new Date()
  const myPastDate = new Date(myCurrentDate)
  myPastDate.setDate(myPastDate.getDate() - 7)

  const [dateRangeStartEnd, setDateRangeStartEnd] = React.useState<DateRange<Date>>([myPastDate, myCurrentDate])

  //! onClick
  const csvFileReader = (csvFile: File) => {
    if (csvFile) {
      const reader = new FileReader()
      reader.onload = async function (x) {
        if (x.target) {
          const timeAttendanceGenerate = packCSVFile(x.target.result)
          // console.log(timeAttendanceGenerate, 'generate file items')
          if (timeAttendanceGenerate.items && timeAttendanceGenerate.items.length > 0) {
            const CHUNK_SIZE = 250
            setLogProcessed(timeAttendanceGenerate.items.length)
            setIsLoadingFile(true)
            for (let i = 0, j = timeAttendanceGenerate.items.length; i < j; i += CHUNK_SIZE) {
              const variableCreateTimeAttendanceBatch = {
                input: { items: timeAttendanceGenerate.items.slice(i, i + CHUNK_SIZE) },
                startDate: dateRangeStartEnd[0]?.toLocaleDateString('sv-SE'),
                endDate: dateRangeStartEnd[1]?.toLocaleDateString('sv-SE'),
              }
              // console.log(variableCreateTimeAttendanceBatch, 'packCSVFile(x.target.result)')
              const successString = await (
                await createTimeAttendanceBatch(variableCreateTimeAttendanceBatch)
              ).data.data.createTimeAttendanceBatch
              if (successString === 'Success') {
                if (i + CHUNK_SIZE <= timeAttendanceGenerate.items.length) {
                  setLogTotal(i + CHUNK_SIZE)
                } else {
                  setIsLoadingFile(false)
                  setLogTotal(0)
                  setLogProcessed(0)
                }
                // console.log('timeAttendanceGenerate.items', timeAttendanceGenerate.items)
              }
            }
          }
          const intervalStreamCount = setInterval(() => {
            getTimeAttendanceStreamCount({})
              .then((res: any) => {
                console.log(res, 'res StreamCount')
                const responseLogTotal = res.data.data.getTimeAttendanceReportStreamCount.logTotal
                const responseLogProcessed = res.data.data.getTimeAttendanceReportStreamCount.logProcessed
                setLogTotal(responseLogTotal)
                setLogProcessed(responseLogProcessed)
                // console.log(!res.data.data.getTimeAttendanceReportStreamCount)
                if (!res.data.data.getTimeAttendanceReportStreamCount || responseLogTotal === responseLogProcessed) {
                  setIsLoadingFile(false)
                  clearInterval(intervalStreamCount)
                  clearCacheRefetch()
                } else {
                  setIsLoadingFile(true)
                }
              })
              .catch(() => {
                setIsLoadingFile(false)
                clearInterval(intervalStreamCount)
                clearCacheRefetch()
              })
          }, 1000)
        }
      }
      reader.readAsText(csvFile)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.navtop}>
        <h1 className={styles.title}>Attendance Log</h1>
      </div>

      <div className={styles.contain}>
        <section className={styles.header}>
          <article className={styles.headerText}>
            <h1>Upload CSV</h1>
            {isLoadingFile && (
              <section className={styles.progressbarBox}>
                <Box sx={{ minWidth: '200px', width: '100%' }}>
                  <LinearProgress
                    sx={{ backgroundColor: '#D9D9D9', borderRadius: '10px' }}
                    variant="determinate"
                    value={(logTotal / logProcessed) * 100}
                  />
                </Box>
                <span>{`${((logTotal / logProcessed) * 100).toFixed()}%`}</span>
              </section>
            )}
          </article>
          <div className={styles.buttonBox}>
            <div className={styles.dateToImportContainer}>
              <p className={styles.dateToImportTopic}>เลือกวันที่นำเข้าข้อมูล</p>
              <div className={styles.dateRangeContainer}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    // startText="Check-in"
                    // endText="Check-out"
                    open={isDateRangeOpen}
                    onOpen={() => {
                      setIsDateRangeOpen(true)
                    }}
                    onClose={() => {
                      setIsDateRangeOpen(false)
                    }}
                    value={dateRangeStartEnd}
                    onChange={(newValue: any) => {
                      setDateRangeStartEnd(newValue)
                    }}
                    renderInput={() => <></>}
                    // renderInput={(startProps: any, endProps: any) => (
                    //   <React.Fragment>
                    //     <TextField inputRef={inputDateRank} {...startProps} />
                    //     <TextField {...endProps} />
                    //   </React.Fragment>
                    // )}
                  />
                </LocalizationProvider>
              </div>
              <img
                className={styles.blueCalendar}
                src={blueCalendar}
                onClick={() => {
                  setIsDateRangeOpen(true)
                }}
                alt=""
              />
              <section className={styles.dateRankStartEnd}>
                {`${dateRangeStartEnd[0]?.toLocaleDateString('en-GB')} - ${dateRangeStartEnd[1]?.toLocaleDateString(
                  'en-GB',
                )}`}
              </section>
            </div>
            <input
              type="file"
              onChange={(e) => {
                if (e.target.files) {
                  csvFileReader(e.target.files[0])
                }
              }}
              className={styles.inputFile}
              accept=".csv"
              ref={inputFile}
            />
            <div className={styles.buttonImport}>
              <ButtonComponent
                _type="button"
                _colorBG="blue"
                _colorText="white"
                _variant="outlined"
                _isIcon="add"
                _text="Import"
                _functionOnClick={() => {
                  inputFile.current.click()
                }}
              />
            </div>
          </div>
        </section>

        {loading && <LinearProgress />}
        <div className={styles.TableAttend}>
          <TableAttend log={currentTableData ? currentTableData : []} />
        </div>

        <PaginationBar
          // Convert from PageIndex to PageNumber
          currentTotalPageCount={currentMaxPageIndex + 1}
          // currentTotalPageCount={6}
          currentPage={currentPage}
          hasNextToken={maxHasNextToken}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          windowSize={windowSize}
        />
      </div>
    </div>
  )
}

export default AttendanceLogPage

const GET_ATTEND_LOG = gql`
  query Query($nextToken: String, $sortDirection: ModelSortDirection, $limit: Int) {
    listTimeAttendance(nextToken: $nextToken, sortDirection: $sortDirection, limit: $limit) {
      items {
        employeeID
        timeID
        scanAt
        scanReason
        scanType
        locationType
        lat
        long
        imageUrl
        employee {
          fullName
          lastName
        }
      }
      nextToken
    }
  }
`
