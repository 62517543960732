import styled from '@emotion/styled'
import { Box, Button, Grid, Stack, useTheme } from '@mui/material'
import { FieldArray, Formik, FormikProps } from 'formik'
import React, { useEffect } from 'react'
import { EBANK_PROVIDER_THAILAND } from '../../utils/enums'
import {
  NavTopBarComponent,
  NavSideBarComponent,
  ImageUpload,
  InputTextComponent,
  InputTextAreaComponent,
  ButtonComponent,
  InputNumberComponent,
  InputSelectComponent,
} from '../../component'
import { Company, EBankProviderThailand, BankAccountInformation } from '../../utils/generated'
import { createCompanyDetail, getCompanyDetail } from '../../adapter/api'
import { deepClone, handleUploadFile } from '../../utils/common'
import Swal from 'sweetalert'

type IValue = Partial<Company> & {
  companyProfileImage?: string | File
}

const companyBankInitialValue: BankAccountInformation = {
  bankPayment: undefined,
  bankReferenceCompanyName: '',
  bankAccountId: undefined,
}

const CompanyDetailPolicyPage: React.FC = () => {
  const theme = useTheme()
  const formikRef = React.useRef<FormikProps<IValue> | null>(null)

  const [prevComapanyDetail, setPrevCompanyDetail] = React.useState<Company>()

  const initialValues: IValue = {
    bankAccountInformation: [companyBankInitialValue],
  }

  const onSubmit = async (values: IValue) => {
    if ((values.companyProfileImage as any) instanceof File) {
      const [res] = await Promise.all(handleUploadFile([values.companyProfileImage], 'companyProfileImages'))
      values.companyProfileImage = res
    }

    const _values = deepClone<any>(values)
    delete _values.companyID
    try {
      await createCompanyDetail(_values)
      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
    }
  }

  const fetchCompanyDetail = async () => {
    const companyDetail = await getCompanyDetail()
    setPrevCompanyDetail(deepClone(companyDetail))
    formikRef.current!.setValues(companyDetail as IValue)
  }

  const handleReset = () => {
    formikRef.current!.setValues(prevComapanyDetail as IValue)
  }

  useEffect(() => {
    fetchCompanyDetail()
  }, [])

  const bankProviders = Object.entries(EBANK_PROVIDER_THAILAND).map(([value, label]) => ({ value, label }))

  return (
    <>
      <div className="layout-main">
        <div className="main">
          <Box component="section" sx={{ backgroundColor: theme.colors.light2, p: '2rem', minHeight: '100vh' }}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              innerRef={formikRef}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Stack
                    alignItems="center"
                    rowGap={6}
                    sx={{ backgroundColor: 'white', borderRadius: '14px', p: '2rem' }}
                  >
                    <ImageUpload
                      image={values.companyProfileImage}
                      onChange={(file) => file && setFieldValue('companyProfileImage', file)}
                      placeholder=""
                      sx={{ width: '16rem', height: '16rem', borderRadius: '7px', overflow: 'hidden' }}
                    />
                    <Stack sx={{ width: '50%', m: 'auto' }} alignItems="center" rowGap={2}>
                      <h3>ข้อมูลบริษัท</h3>
                      <FormLabel>
                        <label>ชื่อบริษัท:</label>
                        <InputTextComponent _name="name" />
                      </FormLabel>
                      <FormLabel>
                        <label>เลขประจำตัวผู้เสียภาษี:</label>
                        <InputTextComponent _name="taxID" />
                      </FormLabel>
                      <FormLabel>
                        <label>ที่อยู่:</label>
                        <Stack rowGap={2}>
                          <InputTextComponent _name="companyAddress.addressNumber" _label="เลขที่" />
                          <InputTextComponent _name="companyAddress.group" _label="หมู่" />
                          <InputTextComponent _name="companyAddress.optional" _label="ข้อมูลเพิ่มเติมอื่นๆ ไม่บังคับ" />
                          <InputTextComponent _name="companyAddress.province" _label="จังหวัด" />
                          <Grid columns={2} container spacing={2}>
                            <Grid item xs={1}>
                              <InputTextComponent _name="companyAddress.district" _label="เขต / อำเภอ" />
                            </Grid>
                            <Grid item xs={1}>
                              <InputTextComponent _name="companyAddress.subDistrict" _label="แขวง / ตำบล" />
                            </Grid>
                            <Grid item xs={1}>
                              <InputNumberComponent _name="companyAddress.zipCode" _label="รหัสไปรษณีย์" />
                            </Grid>
                          </Grid>
                        </Stack>
                      </FormLabel>

                      <Divider />

                      <h3>ข้อมูลธนาคาร</h3>
                      <FieldArray
                        name="bankAccountInformation"
                        render={(arrayHelper) => (
                          <>
                            {values.bankAccountInformation?.map((bank, index, list) => (
                              <React.Fragment key={index}>
                                <FormLabel>
                                  <label>ธนาคาร: {values.bankAccountInformation?.[index]?.bankPayment}</label>
                                  <InputSelectComponent
                                    _name={`bankAccountInformation[${index}].bankPayment`}
                                    _label=""
                                    _menuList={bankProviders}
                                  />
                                </FormLabel>
                                <FormLabel>
                                  <label>เลขที่บัญชี:</label>
                                  <InputTextComponent _name={`bankAccountInformation[${index}].bankAccountId`} />
                                </FormLabel>
                                <FormLabel>
                                  <label>ชื่อบริษัทอ้างอิงธนาคาร:</label>
                                  <span>สำหรับการนำส่งข้อมูลการนำจ่ายเงินเดือน</span>
                                  <InputTextAreaComponent
                                    _name={`bankAccountInformation[${index}].bankReferenceCompanyName`}
                                  />
                                </FormLabel>
                                {index === list.length - 1 ? (
                                  <ButtonComponent
                                    _colorBG="transparent"
                                    _colorText="blue"
                                    _text="เพิ่มข้อมูลบัญชีธนาคาร"
                                    _isIcon="add"
                                    _type="button"
                                    _variant="outlined"
                                    _sx={{ width: 'fit-content', mt: 4, fontSize: '1rem' }}
                                    _functionOnClick={() => arrayHelper.push(companyBankInitialValue)}
                                  />
                                ) : (
                                  <Button
                                    sx={{ color: theme.colors.colorRed }}
                                    color="error"
                                    onClick={() => arrayHelper.remove(index)}
                                  >
                                    <DeleteIcon />
                                    <Box mx="0.5rem">ลบ</Box>
                                  </Button>
                                )}
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      />

                      <Divider />

                      <h3>ข้อมูลติดต่อ</h3>
                      <FormLabel>
                        <label>เบอร์โทรศัพท์:</label>
                        <InputTextComponent _name="companyContactInformation.tel" />
                      </FormLabel>
                    </Stack>

                    <Stack direction="row" columnGap="1rem">
                      <ButtonComponent
                        _colorBG="blue"
                        _colorText="white"
                        _text="บันทึก"
                        _type="submit"
                        _variant="text"
                      />
                      <ButtonComponent
                        _colorBG="gray"
                        _colorText="white"
                        _text="รีเซต"
                        _type="button"
                        _variant="text"
                        _functionOnClick={handleReset}
                      />
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
        </div>
      </div>
    </>
  )
}

const FormLabel = styled(Stack)({
  rowGap: '0.5rem',
  grow: 1,
  width: '100%',
  label: {
    fontWeight: 'bold',
  },
  span: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
})

const Divider = styled.hr`
  margin: 2rem 0;
  border: 0;
  border-top: 1px solid #e9e9e9;
  width: 100%;
`

const DeleteIcon = () => {
  const theme = useTheme()
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.77295 6H5.77295H21.7729"
        stroke={theme.colors.colorRed}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.77295 6V4C8.77295 3.46957 8.98366 2.96086 9.35874 2.58579C9.73381 2.21071 10.2425 2 10.7729 2H14.7729C15.3034 2 15.8121 2.21071 16.1872 2.58579C16.5622 2.96086 16.7729 3.46957 16.7729 4V6M19.7729 6V20C19.7729 20.5304 19.5622 21.0391 19.1872 21.4142C18.8121 21.7893 18.3034 22 17.7729 22H7.77295C7.24252 22 6.73381 21.7893 6.35874 21.4142C5.98366 21.0391 5.77295 20.5304 5.77295 20V6H19.7729Z"
        stroke={theme.colors.colorRed}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7729 11V17"
        stroke={theme.colors.colorRed}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7729 11V17"
        stroke={theme.colors.colorRed}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CompanyDetailPolicyPage
