import modalAction from './modal'
import modalConfirmAction from './modalConfirm'
import shiftAction from './shift'

const allAction = {
  modalAction,
  modalConfirmAction,
  shiftAction,
}

export default allAction
