// Lib
import { useState } from 'react'
import { useFormikContext } from 'formik'

const useFormInput = () => {
  const { setFieldValue } = useFormikContext<any>()

  const [loading, setLoading] = useState(true)

  const handleSetFieldValue = (data: any) => {
    if (data) {
      setFieldValue('formInput', data)
    }
  }

  return { loading, handleSetFieldValue }
}

export default useFormInput
