import {
  CreateWithdrawalConfig,
  DeleteWithdrawalConfig,
  UpdateWithdrawalConfig,
  WithdrawalConfig,
} from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import {
  mutationCreateWithdrawalConfig,
  mutationDeleteWithdrawalConfig,
  mutationUpdateWithdrawalConfig,
} from '../xhr/mutation'
import { queryListWithdrawalConfig } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function getListWithdrawalConfig() {
  return gqlResponseType<{ items: WithdrawalConfig[] }>(
    post('/', {
      query: queryListWithdrawalConfig,
    }),
    'listWithdrawalConfig',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------

export function createWithdrawalConfig(input: CreateWithdrawalConfig) {
  return gqlResponseType<WithdrawalConfig>(
    post('/', {
      query: mutationCreateWithdrawalConfig,
      variables: { input },
    }),
    'createWithdrawalConfig',
  )
}

export function updateWithdrawalConfig(input: UpdateWithdrawalConfig) {
  return gqlResponseType<WithdrawalConfig>(
    post('/', {
      query: mutationUpdateWithdrawalConfig,
      variables: { input },
    }),
    'updateWithdrawalConfig',
  )
}

export function deleteWithdrawalConfig(input: DeleteWithdrawalConfig) {
  return gqlResponseType<WithdrawalConfig>(
    post('/', {
      query: mutationDeleteWithdrawalConfig,
      variables: { input },
    }),
    'deleteWithdrawalConfig',
  )
}
