//lib
import React, { useEffect, useRef, useState } from 'react'
import { Form, useFormikContext } from 'formik'

//Include Project
import { eqTime, minuteToHourHH, timeToMinute } from 'src/utils/common'
import {
  ButtonComponent,
  InputNumberComponentCustom,
  InputRadioComponent,
  InputSelectComponentCustom,
  InputTimeComponentCustom,
} from 'src/component'
import styles from './index.module.scss'

//Images
import AddIcon from '../../image/plusIcon.svg'
import { EStatus } from 'src/pages/AttendanceReport/EType'
import editSVG from '../../image/edit.svg'
import closeSVG from '../../image/closeGray.svg'
import Swal from 'sweetalert2'
import { gql, useMutation } from '@apollo/client'
import { mutationCreateOvertimeDocument } from 'src/adapter/xhr/mutation'
import { DelRequestOvertime, GetOvertimeDocument } from 'src/adapter/xhr'
import { ModalOverTime } from 'src/pages/AttendanceReport/ModalAttendance/ModalOT'
import { Backdrop, Modal } from '@mui/material'

interface Props {
  open: boolean
  setOpen: any
  overtimeID: string
}
const ModalMultiplierOT: React.FC<Props> = (props: Props): JSX.Element => {
  const { values } = useFormikContext<any>()

  const formikRef: any = useRef()
  // useEffect(() => {
  //   if (props.open) return
  //   GetOvertimeDocument({})
  //   console.log('OPEN', values)
  //   console.log(props.overtimeID, 'overtimeRequestID')
  // }, [props.open, props.overtimeID])
  // useEffect(() => {
  //   if (props.open === false) return
  //   GetOvertimeDocument({ getOvertimeId: props.overtimeList?.overtimeRequestID, employeeId: props.employeeID }).then(
  //     (res: any) => {
  //       const data = res.data.data.getOvertime
  //       if (data?.overtimeType) {
  //         formikRef.current.setFieldValue('overtimeType', data.overtimeType)
  //       }
  //       if (data?.overtimeMultiply) {
  //         formikRef.current.setFieldValue('overtimeMultiply', data.overtimeMultiply)
  //       }
  //     },
  //   )
  // }, [props.open])

  // const GetOvertimeConfig = (overtimeType: string) => {
  //   const employeeType = props.attendance.employee.employeeType
  //   const dayType = props.attendance.dayType
  //   const overtimeConfig = props.overtimConfig
  //   if (employeeType === 'FULLTIME_MONTH') {
  //     return overtimeConfig.overtimeMonth[dayType][overtimeType]
  //   } else {
  //     return overtimeConfig.overtimeDay[dayType][overtimeType]
  //   }
  //   return 0
  // }
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalMulti}>
        <style lang="scss">{`
        .swal {
        z-index: 3000;
        }
        `}</style>
        <div className={styles.card}>
          {/* <Formik
            innerRef={formikRef}
            initialValues={{
              overtimeType: 'WORK',
              overtimeMultiply: 0,
            }}
            onSubmit={(values) => {
              const OvertimeVariable: any = {
                input: {
                  id: props.overtimeList.overtimeRequestID,
                  overtimeType: values.overtimeType,
                  overtimeMultiply: values.overtimeMultiply,
                  employeeID: props.employeeID,
                },
              }
              Swal.fire({
                title: `บันทึกตัวคูณ OT`,
                text: `จาก ${GetOvertimeConfig(values.overtimeType)} เป็น ${values.overtimeMultiply} เท่า`,
                cancelButtonText: 'ยกเลิก',
                confirmButtonColor: '#3080f8',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                reverseButtons: true,
                customClass: {
                  container: 'swal',
                },
              }).then((submit) => {
                if (submit.isConfirmed) {
                  UpdateOvertimeDocument(OvertimeVariable).then((res: any) => {
                    console.log(res, 'res')
                    const _overtimeList = []
                    _overtimeList.push({
                      ...props.overtimeList,
                      overtimeRequestID: res.data.data.updateOvertime2.id,
                      overtimeMultiply: values.overtimeMultiply,
                      isCustomOvertimeMultiply: true,
                      overtimeType: values.overtimeType,
                    })
                    props.values.setFieldValue(`overtimeList[${props.overtimeIndex}]`, _overtimeList[0])
                    Swal.fire({
                      icon: 'success',
                      title: `บันทึกตัวคูณ OT เรียบร้อยแล้ว`,
                      timer: 1500,
                      showConfirmButton: false,
                      customClass: {
                        container: 'swal',
                      },
                    })
                    props.setOpen(false)
                  })
                }
              })
            }}
          >
            {(formikProps: any) => ( */}
          <Form className={styles.wrapperForm}>
            <header className={styles.headText}>กำหนดตัวคูณ OT</header>
            <article className={styles.GroupArea}>
              <section className={styles.Group}>
                <p className={styles.TextHead}>ประเภท OT</p>
                <InputRadioComponent
                  _name="overtimeType"
                  _menuList={[
                    { label: 'OT-Work', value: 'WORK' },
                    { label: 'OT-OT', value: 'OVERTIME' },
                  ]}
                  _row
                />
              </section>
              <section className={styles.Group}>
                <p className={styles.TextHead}>ตัวคูณ OT </p>
                {/* <p>
                  เดิม x {GetOvertimeConfig(formikProps.values.overtimeType)}
                  เท่า
                </p> */}
                {/* <InputNumberComponentCustom _name="overtimeMultiply" _value={formikProps.values.overtimeMultiply} /> */}
                <p className={styles.textSub}>คิดจากเงินเดือนรายชั่วโมง</p>
              </section>
            </article>

            <section className={styles.ButtonForm}>
              <ButtonComponent
                _colorBG="transparent"
                _colorText="red"
                _text="ยกเลิก"
                _type="button"
                _variant={'outlined'}
                _functionOnClick={() => props.setOpen(false)}
              />
              <ButtonComponent _colorBG="blue" _colorText="white" _text="บันทึก" _type="submit" _variant={'text'} />
            </section>
          </Form>
          {/* )}
          </Formik> */}
        </div>
      </div>
    </Modal>
  )
}
export default ModalMultiplierOT
