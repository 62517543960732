import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IInitialStatePosition {
  itemsList: []
  itemsListOption: any[]
  refetch: () => void
}
const initialState: IInitialStatePosition = {
  itemsList: [],
  itemsListOption: [],
  refetch: () => null,
}

export const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {
    setPosition: (state, action: PayloadAction<any>) => {
      state.itemsList = action.payload.items
      state.itemsListOption = action.payload.items?.map((data: any) => ({
        value: data.id,
        label: data.positionName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setPosition } = positionSlice.actions

export default positionSlice.reducer
