import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { mutationExcel, mutationPDF } from 'src/adapter/xhr/mutation'
import { ReportFilter } from 'src/component'
import { dateFmtShort } from 'src/utils/common'
import { EDocumentType } from 'src/utils/generated'
import Swal from 'sweetalert2'
import styles from '../../pages/index.module.scss'

const EmployeeReportPage: React.FC = (): JSX.Element => {
  const [showfile, setShowfile] = useState<boolean>(false)
  const [fileData, setFileData] = useState()
  const [typefile, setTypeFile] = useState<string>()
  const [mutationData, { data, loading, error }] = useMutation(mutationPDF)
  const [mutationDataExcel, { data: dataExcel, loading: loadingExcel, error: errorExcel }] = useMutation(mutationExcel)

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: error.name,
        text: error.message,
      })
    }
  }, [error])

  return (
    <div className={styles.container}>
      <section className={styles.cardFilter}>
        <div className={styles.wrapperCard}>
          <Formik
            initialValues={{
              departmentID: [],
              employeeID: [],
              documentType: 'PDF',
              startedAt: dateFmtShort(new Date()),
              checkDepartment: true,
              employeeall: true,
              endedAt: dateFmtShort(new Date()),
            }}
            onSubmit={(values) => {
              console.log(values)
            }}
          >
            {(props: any) => (
              <form onSubmit={props.handleSubmit} className={styles.filterCard}>
                <ReportFilter
                  headerLabel="รายงานทะเบียนลูกจ้าง"
                  value={props}
                  documentType={EDocumentType.EmployeeAllReportDoc}
                  mutationData={props.values.documentType === 'PDF' ? mutationData : mutationDataExcel}
                  loading={props.values.documentType === 'PDF' ? loading : loadingExcel}
                  setShowfile={setShowfile}
                  defaultData={props.values.documentType === 'PDF' ? data : dataExcel}
                  setFileData={setFileData}
                  setTypeFile={setTypeFile}
                />
              </form>
            )}
          </Formik>
        </div>
      </section>
      <div className={styles.line} />
      <section className={styles.cardReport}>
        <div className={styles.wrapperCard}>
          {showfile === true ? (
            <iframe
              src={typefile === 'PDF' ? fileData : `https://view.officeapps.live.com/op/embed.aspx?src=${fileData}`}
              className={styles.viewFram}
              frameBorder="0"
            ></iframe>
          ) : (
            <p className={styles.text}>หน้าแสดงข้อมูล</p>
          )}
        </div>
      </section>
    </div>
  )
}

export default EmployeeReportPage
