import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IInitialStateHoliday {
  holidayList: []
  holidayListOption: []
  refetch: () => void
}
const initialState: IInitialStateHoliday = {
  holidayList: [],
  holidayListOption: [],
  refetch: () => null,
}

export const holidaySlice = createSlice({
  name: 'holiday',
  initialState,
  reducers: {
    setHoliday: (state, action: PayloadAction<any>) => {
      state.holidayList = action.payload.items
      state.holidayListOption = action.payload.items?.map((data: any) => ({
        value: data.holidayID,
        label: data.holidayName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setHoliday } = holidaySlice.actions

export default holidaySlice.reducer
