//lib
import React from 'react'
import { useFormikContext } from 'formik'

//Include Project
import { InputRadioComponent, InputSelectComponentCustom, InputTextComponentCustom } from 'src/component'
import { EPaymentMethod } from 'src/utils/generated'
import styles from './index.module.scss'

const EmployeePaymentTab: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.header}>5. ข้อมูลการจ่ายเงิน</div>
      <div className={styles.card}>
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.flexBox}>
              <div className={styles.gap}>
                <p className={styles.title}>ช่องทางการชำระเงิน</p>
                <InputRadioComponent _row _name="formInput.paymentMethod" _menuList={PaymentList} />
              </div>
            </div>

            {values.formInput.paymentMethod === EPaymentMethod.Bank ? (
              <>
                <div className={styles.form}>
                  <p className={styles.title}>จ่ายเงินผ่านธนาคาร</p>
                  <div className={styles.flexBoxTitle}>
                    <p className={styles.title}>ธนาคาร</p>
                    <div className={styles.wrapperInput}>
                      <InputSelectComponentCustom
                        _name="formInput.bankPayment"
                        _label="ธนาคาร"
                        _menuList={EBankProviderThailand}
                        _value={values.formInput.bankPayment}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.form}>
                  <p className={styles.title}>บัญชีพนักงาน</p>
                  <div className={styles.flexBoxTitle}>
                    <p className={styles.title}>ธนาคาร</p>
                    <div className={styles.wrapperInput}>
                      <InputSelectComponentCustom
                        _name="formInput.bankAccountName"
                        _label="ธนาคาร"
                        _menuList={EBankProviderThailand}
                        _value={values.formInput.bankAccountName}
                      />
                    </div>
                  </div>
                  <div className={styles.flexBoxTitle}>
                    <p className={styles.title}>เลขที่บัญชี</p>
                    <div className={styles.wrapperInput}>
                      <InputTextComponentCustom
                        _name="formInput.bankAccountId"
                        _label="เลขที่บัญชี"
                        _value={values.formInput.bankAccountId}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </section>
      </div>
    </div>
  )
}

export default EmployeePaymentTab

const PaymentList = [
  { label: 'ธนาคาร', value: EPaymentMethod.Bank },
  { label: 'เงินสด', value: EPaymentMethod.Cash },
]

const EBankProviderThailand = [
  { label: 'ธนาคารกรุงเทพ ', value: 'KRUNGTHEP' },
  { label: 'ธนาคารกรุงไทย', value: 'KRUNGTHAI' },
  { label: 'ธนาคารกรุงศรีอยุธยา', value: 'KRUNGSRI' },
  { label: 'ธนาคารกสิกรไทย', value: 'KASIKORN' },
  { label: 'ธนาคารทหารไทยธนชาต', value: 'THANACHAT' },
  { label: 'ธนาคารไทยพาณิชย์', value: 'THAI_PANICH' },
  { label: 'ธนาคารซีไอเอ็มบี ไทย ', value: 'CIMB' },
  { label: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) ', value: 'STANDARD_CHADTER' },
  { label: 'ธนาคารยูโอบี', value: 'UOB' },
  { label: 'ธนาคารทิสโก้', value: 'TISCO' },
  { label: 'ธนาคารเกียรตินาคินภัทร', value: 'KIET_NAKIN' },
  { label: 'ธนาคารแลนด์ แอนด์ เฮ้าส์', value: 'LAND_AND_HOUSE' },
  { label: 'ธนาคารไอซีบีซี (ไทย)', value: 'ICBC' },
  { label: 'ธนาคารไทยเครดิต เพื่อรายย่อย', value: 'THAI_CREDIT' },
  { label: 'ธนาคารเมกะ สากลพาณิชย์', value: 'MEGE_PANICH' },
  { label: 'ธนาคารแห่งประเทศจีน (ไทย) ', value: 'CHINESE' },
  { label: 'ธนาคารเอเอ็นแซด (ไทย)', value: 'ANZ' },
  { label: 'ธนาคารซูมิโตโม มิตซุย ทรัสต์ (ไทย)', value: 'SUMITOMO_MITSUI_TRUST' },
]
