import React, { useState } from 'react'
import styles from './index.module.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { InputNumberComponentCustom, PaginationBar } from '../../../component'
import { useFormikContext } from 'formik'
import { paginationFunc } from '../../../utils/common'

export interface ITableHeader {
  text: string
  key: string
  type: 'text' | 'input'
  inputType?: 'number'
}

interface ITableProps {
  topic: 'รายรับ' | 'รายจ่าย'
  tableHeaderList: ITableHeader[]
  tableBodyList: any[]
  prefixValues?: string
  searchVal?: string
}

const ExpenseIncomeTable: React.FC<ITableProps> = (props: ITableProps): JSX.Element => {
  const [pageSite, setPageSite] = useState<number>(20)
  const [listItemIndex, setListItemIndex] = useState<number>(0)
  const { values } = useFormikContext<any>()
  // console.log(props.searchVal, 'searchVal1')
  return (
    <div className={styles.tableContainer}>
      <section className={styles.tableTopic}>{props.topic}</section>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              borderRight: '1px solid rgba(0, 0, 0, 0.2)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
              borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
            }}
          >
            {props?.tableHeaderList?.map((headerString: ITableHeader, headerIndex: number) => {
              return (
                <TableCell sx={{ border: 'none' }} align="center" key={headerIndex}>
                  <p>{headerString.text}</p>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableBodyList.length
            ? props.tableBodyList
                .filter((item: any) => {
                  if (props.searchVal === '' || props.searchVal === undefined) {
                    return item
                  } else if (
                    item.employeeName.toLocaleLowerCase().includes(props.searchVal.toLocaleLowerCase()) ||
                    item.departmentName.toLocaleLowerCase().includes(props.searchVal.toLocaleLowerCase()) ||
                    item.employeeID.toLocaleLowerCase().includes(props.searchVal.toLocaleLowerCase())
                  ) {
                    return item
                  }
                })
                .map((tableBodyObj: any, tableBodyIndex: number) => {
                  return (
                    <TableRow
                      sx={{
                        borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                        borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                      }}
                      key={tableBodyIndex}
                    >
                      {props?.tableHeaderList?.map((headerString: ITableHeader, headerIndex: number) => {
                        return (
                          <>
                            {headerString.key === 'No.' ? (
                              <TableCell sx={{ border: 'none' }} align="center" key={headerIndex}>
                                <p>{pageSite * listItemIndex + tableBodyIndex + 1}</p>
                              </TableCell>
                            ) : headerString.type === 'input' ? (
                              <TableCell sx={{ border: 'none' }} align="center" key={headerIndex}>
                                <InputNumberComponentCustom
                                  _name={`${props?.prefixValues}[${tableBodyIndex}].${headerString.key}`}
                                  _value={values[`${props?.prefixValues}`][tableBodyIndex][headerString.key]}
                                />
                              </TableCell>
                            ) : (
                              <TableCell sx={{ border: 'none' }} align="center" key={headerIndex}>
                                <p>{tableBodyObj[headerString.key]}</p>
                              </TableCell>
                            )}
                          </>
                        )
                      })}
                    </TableRow>
                  )
                })
            : null}
        </TableBody>
      </Table>
      <section className={styles.PaginationBar}>
        {/* <PaginationBar
          _dropDownValue={pageSite}
          _onDropdownChange={(e: any) => {
            setPageSite(e.target.value)
            setListItemIndex(0)
          }}
          _selectedNumber={listItemIndex + 1}
          _numberList={paginationFunc(props?.tableBodyList, pageSite).map((e: any, index: number) => index + 1)}
          _setSelectedNumber={(event: any, number: number, index: number) => {
            // console.log(number, index, 'index')
            if (listItemIndex !== index) {
              setListItemIndex(index)
              // if (index + 2 === paginationFunc(listAttendanceLog, pageSite).length && nextToken) {
              //   ListTimeAttenLog({ nextToken: nextToken }).then((res: any) => {
              //     if (res.data.data.listTimeAttendance.items && res.data.data.listTimeAttendance.items.length > 0) {
              //       setAttendanceLog([...listAttendanceLog, ...res.data.data.listTimeAttendance.items])
              //     }
              //     setNextToken(res.data.data.listTimeAttendance.nextToken)
              //   })
              // }
            }
          }}
        /> */}
      </section>
    </div>
  )
}

export default ExpenseIncomeTable
