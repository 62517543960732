import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop, Popper, Fade, Divider, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { Formik } from 'formik'
import { ButtonComponent, InputTextComponentCustom } from '../../component'
import { EApprove } from './DetailPage'
import { ApproveDocument, ApproveDocumentBatch } from '../../adapter/xhr'
import allAction from '../../state/actions'

export const ModalApprove: React.FC<any> = (props: any): JSX.Element => {
  const otherProps = props
  const dispatch = useDispatch()
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalApprove}>
        <div className={styles.card}>
          <Formik
            initialValues={{
              remark: '',
            }}
            onSubmit={(values, actions) => {
              const statusNew = (type: any) => {
                if (otherProps.type === 'อนุมัติ') {
                  return 'APPROVE'
                } else {
                  return 'REJECT'
                }
              }

              const inputApprove = {
                input: {
                  id: otherProps.detailData.id,
                  employeeID: otherProps.detailData.employeeID,
                  approveBy: '',
                  status: statusNew(otherProps.type),
                  remarkApprove: values.remark,
                },
              }
              console.log(inputApprove, 'Variables')
              ApproveDocument(inputApprove).then((res) => {
                console.log(res, 'APPROVE')
              })
              otherProps.setOpen(false)
              setTimeout(() => {
                props.QueryApprove()
              }, 100)
              {
                otherProps.type === 'อนุมัติ'
                  ? dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        `อนุมัติรายการ ${EApprove[`${otherProps.detailData.Type}`]}`,
                        `ของ ${otherProps.detailData.employee.fullName} ${otherProps.detailData.employee.lastName}`,
                        'success',
                      ),
                    )
                  : dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        `ปฏิเสธรายการ ${EApprove[`${otherProps.detailData.Type}`]}`,
                        `ของ ${otherProps.detailData.employee.fullName} ${otherProps.detailData.employee.lastName}`,
                        'error',
                      ),
                    )
              }
            }}
          >
            {(props: any) => (
              <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                <div className={styles.GroupArea}>
                  <section className={styles.Topmodal}>
                    {otherProps.type === 'อนุมัติ' ? <p>อนุมัติรายการ</p> : <p>ปฏิเสธรายการ</p>}
                  </section>
                  <section className={styles.TextApprove}>
                    <span className={styles.text}>
                      {otherProps.type === 'อนุมัติ' ? <span>อนุมัติรายการ</span> : <span>ปฏิเสธรายการ</span>}
                      {EApprove[`${otherProps.detailData.Type}`]}
                    </span>
                    <p className={styles.text}>
                      ของ {otherProps.detailData.employee.fullName} {otherProps.detailData.employee.lastName}
                    </p>
                  </section>
                  <section className={styles.InputGroup}>
                    <InputTextComponentCustom _name="remark" _label="หมายเหตุ" />
                  </section>
                </div>

                <section className={styles.bottom}>
                  <div className={styles.buttonForm}>
                    <div className={styles.button}>
                      <ButtonComponent
                        _type="button"
                        _colorBG="red"
                        _colorText="white"
                        _variant="text"
                        _text="ยกเลิก"
                        _functionOnClick={() => {
                          otherProps.setOpen(false)
                        }}
                      />
                    </div>
                    <div className={styles.button}>
                      <ButtonComponent
                        _type="submit"
                        _colorBG="blue"
                        _colorText="white"
                        _variant="text"
                        _text={otherProps.type === 'อนุมัติ' ? 'อนุมัติ' : 'ปฏิเสธ'}
                      />
                    </div>
                  </div>
                </section>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export const ModalApproveArr: React.FC<any> = (props: any): JSX.Element => {
  const otherProps = props
  const dispatch = useDispatch()
  // console.log(props.selectList, 'select')

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.setOpenModal(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalApprove}>
        <div className={styles.card}>
          <Formik
            initialValues={{
              remark: '',
            }}
            // validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              console.log(values, 'values')

              const statusNew = (type: any) => {
                if (otherProps.type === 'อนุมัติที่เลือกทั้งหมด') {
                  return 'APPROVE'
                } else {
                  return 'REJECT'
                }
              }

              const NewInput: any[] = []
              const Input = otherProps.selectList.forEach((item: any) => {
                delete item.employee
                return NewInput.push({
                  ...item,
                  id: item.id,
                  employeeID: item.employeeID,
                  approveBy: '',
                  status: statusNew(otherProps.type),
                  remarkApprove: values.remark,
                })
              })
              const inputApprove = {
                input: NewInput,
              }

              console.log(inputApprove, 'inputApprove')

              ApproveDocumentBatch(inputApprove).then((res) => {
                console.log(res, 'APPROVE')
              })
              otherProps.setOpenModal(false)
              {
                otherProps.type === 'อนุมัติที่เลือกทั้งหมด'
                  ? dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        `อนุมัติรายการ ${EApprove[`${otherProps.detailData.Type}`]}`,
                        `จำนวนที่อนุมัติ ${otherProps.selectList.length} รายการ`,
                        'success',
                      ),
                    )
                  : dispatch(
                      allAction.modalAction.setOpenModalAlertStatus(
                        `ปฏิเสธรายการ ${EApprove[`${otherProps.detailData.Type}`]}`,
                        `จำนวนที่ปฏิเสธ ${otherProps.selectList.length} `,
                        'error',
                      ),
                    )
              }
            }}
          >
            {(props: any) => (
              <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
                <div className={styles.GroupArea}>
                  <section className={styles.Topmodal}>
                    {otherProps.type === 'อนุมัติที่เลือกทั้งหมด' ? <p>อนุมัติรายการ</p> : <p>ปฏิเสธรายการ</p>}
                  </section>
                  <section className={styles.TextApprove}>
                    <span className={styles.text}>
                      {otherProps.type === 'อนุมัติที่เลือกทั้งหมด' ? (
                        <span>อนุมัติรายการที่เลือกทั้งหมด</span>
                      ) : (
                        <span>ปฏิเสธรายการที่เลือกทั้งหมด</span>
                      )}
                    </span>
                    <p className={styles.text}>รายการที่เลือก {otherProps.selectList.length} รายการ</p>
                  </section>
                  <section className={styles.InputGroup}>
                    <InputTextComponentCustom _name="remark" _label="หมายเหตุ" />
                  </section>
                </div>

                <section className={styles.bottom}>
                  <div className={styles.buttonForm}>
                    <div className={styles.button}>
                      <ButtonComponent
                        _type="button"
                        _colorBG="red"
                        _colorText="white"
                        _variant="text"
                        _text="ยกเลิก"
                        _functionOnClick={() => {
                          otherProps.setOpenModal(false)
                        }}
                      />
                    </div>
                    <div className={styles.button}>
                      <ButtonComponent
                        _type="submit"
                        _colorBG="blue"
                        _colorText="white"
                        _variant="text"
                        _text="ok"
                        _functionOnClick={() => console.log('click')}
                        // _text={otherProps.type === 'อนุมัติที่เลือกทั้งหมด' ? 'อนุมัติ' : 'ปฏิเสธ'}
                      />
                    </div>
                  </div>
                </section>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export const PopperFilter: React.FC<any> = (props: any): JSX.Element => {
  const [showSelect, setShowSelect] = React.useState(false)
  const [countFilter, setCounter] = React.useState<any>([])

  const otherProps = props

  // const handleClickType = (value: any) => {
  //   if (otherProps.fillLeavtype !== value) {
  //     otherProps.setfillLeavtype(value)
  //   } else {
  //     otherProps.setfillLeavtype('')
  //   }
  // }

  // const handleClickForm = (value: any) => {
  //   if (otherProps.fillLeavForm !== value) {
  //     otherProps.setfillLeavForm(value)
  //   } else {
  //     otherProps.setfillLeavForm('')
  //   }
  // }

  // const handleClickStatus = (value: any) => {
  //   if (otherProps.setfillStatus !== value) {
  //     otherProps.setfillStatus(value)
  //   } else {
  //     otherProps.setfillStatus('')
  //   }
  // }
  return (
    <Popper transition placement="bottom" anchorEl={props.anchorEl} open={props.openpopper}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div className={styles.Popper}>
            {/* <Formik
              initialValues={{
                startdate: '',
                enddate: '',
              }}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  props.setOpenPopper(false)
                  props.QueryListDocument()
                }, 1000)
              }}
            >
              {(props: any) => ( */}
            <div className={styles.Popper}>
              <div className={styles.pointer}></div>
              <div className={styles.modal}>
                <div className={styles.modalGroup}>
                  {/* <section className={styles.Header}>
                    <p>ค้นหาแบบละเอียด</p>
                    <img
                      src={CloseIcon}
                      alt=""
                      onClick={() => {
                        otherProps.setOpenPopper(false)
                      }}
                    />
                  </section> */}

                  <div className={styles.GroupDetail}>
                    <section className={styles.BoxFilter}>
                      <p style={{ fontSize: '16px' }}>ค้นหาตามประเภท</p>
                      <div className={styles.GroupCard}>
                        {TypeDocument.map((item: any, index: number) => (
                          <div
                            className={styles.Card}
                            // className={item.value === otherProps.fillLeavtype ? styles.CardBlue : styles.Card}

                            key={index}
                            onClick={() => {
                              // handleClickType(item.value)
                            }}
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </section>
                    <Divider />

                    <section className={styles.BoxFilter}>
                      {/* <p style={{ fontSize: '16px' }}>ค้นหาตามรูปแบบการลา</p> */}
                      <div className={styles.GroupCard}>
                        {/* {LeaveForm.map((item: any, index: number) => (
                          <div
                            className={item.value === otherProps.fillLeavForm ? styles.CardBlue : styles.Card}
                            key={index}
                            onClick={() => {
                              handleClickForm(item.value)
                            }}
                          >
                            {item.label}
                          </div>
                        ))} */}
                      </div>
                    </section>

                    <Divider />

                    <section className={styles.BoxFilter}>
                      <p style={{ fontSize: '16px' }}>ช่วงวันที่เอกสาร</p>
                      <div className={styles.GroupCardDate}>
                        <div className={styles.InputBox}>
                          <TextField
                            InputProps={{
                              style: {
                                fontFamily: 'KanitRegular',
                                color: 'gray',
                                borderRadius: 4,
                              },
                            }}
                            fullWidth
                            size="small"
                            type="date"
                            label="วันที่เริ่ม"
                            name="startdate"
                            value={otherProps.fillStartdate}
                            onChange={(e) => {
                              otherProps.setfillStartdate(e.target.value)
                              console.log(e.target.value, 'e')
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: 14, fontFamily: 'KanitRegular' },
                            }}
                          />
                        </div>
                        <p>-</p>
                        <div className={styles.InputBox}>
                          <TextField
                            InputProps={{
                              style: {
                                fontFamily: 'KanitRegular',
                                color: 'gray',
                                borderRadius: 4,
                              },
                            }}
                            fullWidth
                            size="small"
                            type="date"
                            label="วันที่สิ้นสุด"
                            name="enddate"
                            value={otherProps.fillEnddate}
                            onChange={(e) => {
                              otherProps.setfillEnddate(e.target.value)
                              console.log(e.target.value, 'e')
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: 14, fontFamily: 'KanitRegular' },
                            }}
                          />
                        </div>
                      </div>
                    </section>

                    <Divider />
                  </div>

                  <section className={styles.FooterButton}>
                    <ButtonComponent
                      _colorBG="white"
                      _text="ล้าง"
                      _type="button"
                      _variant="outlined"
                      _colorText="red"
                      // _functionOnClick={() => {
                      //   otherProps.setfillLeavtype('')
                      //   otherProps.setfillLeavForm('')
                      //   otherProps.setfillStartdate('')
                      //   otherProps.setfillEnddate('')
                      //   otherProps.setfillStatus('')
                      // }}
                    />
                    <ButtonComponent
                      _colorBG="blue"
                      _text="ตกลง"
                      _type="button"
                      _variant="contained"
                      _colorText="white"
                      // _functionOnClick={() => {
                      //   setTimeout(() => {
                      //     otherProps.setOpenPopper(false)
                      //     otherProps.QueryListDocument()
                      //   }, 1000)
                      // }}
                    />
                  </section>
                </div>
              </div>
            </div>
            {/* )}
            </Formik> */}
          </div>
        </Fade>
      )}
    </Popper>
  )
}

const TypeDocument = [
  {
    label: 'การลา',
    value: 'OVERTIME',
    submenu: [
      {
        label: 'ลากิจ',
        value: '',
      },
      {
        label: 'ลาป่วย',
        value: '',
      },
      {
        label: 'ลาพักร้อน',
        value: '',
      },
      {
        label: 'ลากิจ',
        value: '',
      },
    ],
  },
  {
    label: 'การทำงานล่วงเวลา',
    value: 'OVERTIME',
    submenu: [
      {
        label: 'ทำงานล่วงเวลา',
        value: '',
      },
      {
        label: 'ทำงานวันหยุด',
        value: '',
      },
    ],
  },
]
