import { LinearProgress, Slide, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ICombineReducers } from 'src/state/reducers'
import { setCloseNotification } from 'src/state/slice/notification'
import styles from './index.module.scss'
import loadingSVG from '../../image/loader.svg'

const Notification: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const notificationReducer = useSelector((state: ICombineReducers) => state.notificationReducer)

  return (
    <Snackbar
      open={notificationReducer.isOpen}
      onClose={() => dispatch(setCloseNotification())}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      TransitionComponent={Slide}
      autoHideDuration={notificationReducer.typeNotification === 'loading' ? null : 5000}
    >
      <div className={styles.main}>
        <div className={`${styles.head}`}>
          <div className={`${styles.titleBox}`}>
            <img src={loadingSVG} alt="" />
            <h5>{notificationReducer.title}</h5>
          </div>
        </div>
        {notificationReducer.typeNotification === 'loading' && notificationReducer.isLoading === true && (
          <LinearProgress sx={{ borderRadius: 10, background: 'none' }} color="primary" />
        )}

        <div className={notificationReducer.typeNotification === 'loading' ? styles.text : styles.padding}>
          <p>{notificationReducer.description}</p>
        </div>
      </div>
    </Snackbar>
  )
}

export default Notification
