import React from 'react'
import styles from './index.module.scss'
import { useFormikContext } from 'formik'

interface ITable {
  Header: string[]
  values?: any
}
export const TableLeaveQuota: React.FC<ITable> = (props: ITable): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <>
      {values.formInput.listLeaveQuota.length ? (
        <table className={styles.tableRounded}>
          <tr>
            {props.Header.map((item: any, index: number) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
          {values.formInput.listLeaveQuota?.items?.map((ele: any, index: number) => (
            <tr key={index}>
              <td>
                {ele.leaveConfig.name}
                <span className={styles.textsub}> ({ele.prev < 0 ? 'ลาแบบหักเงิน' : 'ลาแบบไม่หักเงิน'})</span>
              </td>
              <td>{ele.prev < 0 ? 'ไม่จำกัด' : ele.begin}</td>
              <td>{ele.used}</td>
              <td>{ele.approved}</td>
              <td>{ele.prev < 0 ? 'ไม่จำกัด' : ele.begin - ele.used}</td>
            </tr>
          ))}
        </table>
      ) : (
        <p>ยังไม่มีข้อมูลโควต้าการลา</p>
      )}
    </>
  )
}
