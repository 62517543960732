import { AutocompleteComponent } from 'src/component'
import React, { useMemo, useRef } from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import { Formik, FormikProps, FormikProvider, useFormik, useFormikContext } from 'formik'
import PopperContainer from 'src/component/PopperContainer'
import AddIcon from '../../image/addblue.svg'
import DownIcon from '../../image/dropBlue.svg'
import MinusIcon from '../../image/minus.svg'

interface ApprovalPosition {
  id: string
  position: string
}

interface Approval {
  type: string
  approvalPositionList?: ApprovalPosition[]
  approvalPositionOrders?: ApprovalPosition[]
}

interface HeadingTopic {
  name: string
  key: string
}

interface IValue {
  headingTopics?: HeadingTopic[]
  menuList?: HeadingTopic[]

  approval: Approval[]
  approvalPositionList?: ApprovalPosition[]
  approvalPositionOrders?: ApprovalPosition[]
  selectedTopic: HeadingTopic
  selectedMenu: HeadingTopic

  MAX_ORDER_COUNT: number

  functions: {
    handleChangeApproval: (position: ApprovalPosition, type: 'add' | 'remove' | 'edit', index?: number | null) => void
    filteredPositionList: (positionList: ApprovalPosition[], selectedList: ApprovalPosition[]) => ApprovalPosition[]
  }
}

const ApprovalPolicyPage: React.FC = () => {
  const theme = useTheme()

  // const formilRef = useRef<FormikProps<IValue> | null>(null)

  const filteredPositionList = (positionList: ApprovalPosition[], selectedList: ApprovalPosition[]) => {
    return positionList.filter((l) => !selectedList.find((s) => s.id === l.id))
  }

  const handleChangeApproval = (position: ApprovalPosition, type: 'add' | 'remove' | 'edit', index?: number | null) => {
    const { setFieldValue, values } = formik
    const { approvalPositionOrders = [] } = values
    switch (type) {
      case 'add': {
        const arr = approvalPositionOrders
        arr.push(position)
        setFieldValue('approvalPositionOrders', arr)
        break
      }
      case 'remove': {
        const idx = approvalPositionOrders?.findIndex((e) => e.id === position.id) ?? -1
        const totalItemLength = approvalPositionOrders?.length
        const removeCount = idx === totalItemLength - 1 ? 1 : totalItemLength - idx
        console.log(idx, totalItemLength)
        approvalPositionOrders.splice(idx, removeCount)
        setFieldValue('approvalPositionOrders', approvalPositionOrders)
        break
      }
      case 'edit': {
        // const idx = approvalPositionOrders?.findIndex((e) => e.id === position.id) ?? -1
        // approvalPositionOrders[idx] = position
        // console.log(idx)

        approvalPositionOrders[index ?? -1] = position
        setFieldValue('approvalPositionOrders', approvalPositionOrders)
        break
      }
    }
  }

  const initialValues: IValue = {
    approval: [
      {
        type: 'ALL',
        approvalPositionList: [
          { id: '1', position: 'หัวหน้า' },
          { id: '2', position: 'ผู้จัดการ' },
          { id: '3', position: 'บุคคล' },
        ],
        approvalPositionOrders: [
          { id: '1', position: 'หัวหน้า' },
          // { id: '2', position: 'ผู้จัดการ' },
        ],
      },
    ],
    approvalPositionList: [
      { id: '1', position: 'หัวหน้า' },
      { id: '2', position: 'ผู้จัดการ' },
      { id: '3', position: 'บุคคล' },
    ],
    approvalPositionOrders: [
      { id: '1', position: 'หัวหน้า' },
      // { id: '2', position: 'ผู้จัดการ' },
    ],
    headingTopics: [
      {
        name: 'การลา',
        key: 'LEAVE',
      },
      {
        name: 'การทำงานล่วงเวลา',
        key: 'OVERTIME',
      },

      {
        name: 'ขอสวัสดิการ',
        key: 'COMPENSATION',
      },

      {
        name: 'ค่าใช้จ่าย',
        key: 'OUTCOME',
      },
    ],
    menuList: [
      {
        name: 'ทั้งหมด',
        key: 'ALL',
      },
      {
        name: 'ลาพักร้อน',
        key: 'LEAVE1',
      },
    ],
    selectedTopic: {
      name: 'การลา',
      key: 'LEAVE',
    },
    selectedMenu: {
      name: 'ทั้งหมด',
      key: 'ALL',
    },
    MAX_ORDER_COUNT: 2,
    functions: {
      filteredPositionList,
      handleChangeApproval,
    },
  }

  const onSubmit = () => {
    console.log(onSubmit)
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const isActive = (key: string, type: 'topic' | 'menu', values: IValue) => {
    const { selectedTopic, selectedMenu } = values

    switch (type) {
      case 'topic':
        return key === selectedTopic?.key
      case 'menu':
        return key === selectedMenu?.key
    }
  }

  const handleSelectMenu = (value: HeadingTopic, type: 'topic' | 'menu') => {
    const { setFieldValue } = formik
    switch (type) {
      case 'topic':
        return setFieldValue('selectedTopic', value)
      case 'menu':
        return setFieldValue('selectedMenu', value)
    }
  }

  return (
    <>
      <div className="layout-main">
        {/* <div className="header">
          <NavTopBarComponent />
        </div>
        <aside className="sidebar">
          <NavSideBarComponent />
        </aside> */}
        <div className="main">
          <Box sx={{ backgroundColor: theme.colors.light2, minHeight: '100vh', p: '2rem' }}>
            <FormikProvider value={formik}>
              <>
                <Stack rowGap={4}>
                  <Stack direction="row" flexWrap="wrap" gap={4}>
                    {formik.values.headingTopics?.map((topic) => (
                      <Box
                        key={topic.key}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '14px',
                          width: '12rem',
                          height: '2.75rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          fontSize: '18px',

                          // border: isActive(topic.key, 'topic', values) ? `1px solid ${theme.colors.primary}` : '',
                        }}
                        onClick={handleSelectMenu.bind(null, topic, 'topic')}
                      >
                        <b>{topic.name}</b>
                      </Box>
                    ))}
                  </Stack>
                  <Stack direction="row" columnGap={6} rowGap={4}>
                    {/* {values.menuList?.map((menu) => (
                      <Box
                        key={menu.key}
                        sx={{
                          cursor: 'pointer',
                          fontSize: '16px',

                          borderBottom: `4px solid ${
                            isActive(menu.key, 'menu', values) ? theme.colors.primary : 'transparent'
                          }`,
                        }}
                        onClick={handleSelectMenu.bind(null, menu, 'menu')}
                      >
                        <b>{menu.name}</b>
                      </Box>
                    ))} */}
                  </Stack>
                  <Box
                    component="p"
                    sx={{
                      fontSize: '22px',
                    }}
                  >
                    กำหนดสิทธิ์ลำดับขั้นอนุมัติ
                  </Box>

                  <Stack direction="row" alignItems="center">
                    <Stack
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '7px',
                        border: '1px solid #e9e9e9',

                        width: '15rem',
                        height: '6rem',
                        position: 'relative',
                      }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <p>พนักงานทั้งหมด</p>

                      {/* {!values.approvalPositionOrders?.length && <PopperApproval type="add" />} */}
                    </Stack>
                    {/* {values.approvalPositionOrders?.map((position, index) => (
                      <>
                        <Arrow />
                        <ApprovalCard index={index} />
                      </>
                    ))} */}
                  </Stack>
                </Stack>
              </>
            </FormikProvider>
          </Box>
        </div>
      </div>
    </>
  )
}

function Arrow() {
  const theme = useTheme()
  return (
    <Stack direction="row" alignItems="center" sx={{ position: 'relative' }}>
      {/* Circle */}
      <Box
        sx={{
          width: '0.5rem',
          height: '0.5rem',
          border: `1.5px solid ${theme.colors.primary}`,
          borderRadius: '50%',
          position: 'absolute',
          left: 0,
          transform: 'translateX(-50%)',
          backgroundColor: 'white',
        }}
      />
      {/* Line */}
      <Box sx={{ width: '8rem', height: '1px', backgroundColor: theme.colors.primary }} />
      {/* Arrow Head */}
      <Box
        sx={{
          border: `solid ${theme.colors.primary}`,
          borderWidth: '0 1px 1px 0',
          display: 'inline-block',
          padding: '3px',
          transform: 'rotate(-45deg)',
          position: 'absolute',
          right: '0',
        }}
      />
    </Stack>
  )
}

interface CardProps {
  index: number
}

function ApprovalCard({ index }: CardProps) {
  const theme = useTheme()

  const { values } = useFormikContext<IValue>()

  const position = useMemo<ApprovalPosition>(() => {
    if (!values.approvalPositionOrders) return {} as ApprovalPosition
    return values.approvalPositionOrders[index]
  }, [index, values.approvalPositionOrders?.[index]])

  const { MAX_ORDER_COUNT, approvalPositionOrders } = values
  const { handleChangeApproval } = values.functions

  return (
    <>
      <Stack
        sx={{
          backgroundColor: 'white',
          borderRadius: '7px',
          border: '1px solid #e9e9e9',
          width: '15rem',
          height: '6rem',
          position: 'relative',
        }}
        justifyContent="center"
      >
        <Stack direction="row" height="100%" alignItems="center" justifyContent="space-between" px="1rem">
          <Box component="p" sx={{ color: theme.colors.gray10 }}>
            ขั้นที่ {index + 1}
          </Box>
          <Box
            component="img"
            src={MinusIcon}
            sx={{ cursor: 'pointer' }}
            onClick={handleChangeApproval.bind(null, position, 'remove', null)}
          />
        </Stack>
        <Box sx={{ with: '100%', height: '1px', borderTop: `1px dashed ${theme.colors.primary}` }} />
        <Stack direction="row" height="100%" alignItems="center" justifyContent="space-between" px="1rem">
          {position.id === '3' ? <AutocompleteComponent _name="" _label="" _option={[]} /> : <p>{position.position}</p>}

          <PopperApproval type="edit" index={index} />
        </Stack>
        {index === approvalPositionOrders!.length - 1 && approvalPositionOrders!.length < MAX_ORDER_COUNT && (
          <>
            <PopperApproval type="add" />
          </>
        )}
      </Stack>
    </>
  )
}

interface PopperApprovalProps {
  type: 'add' | 'edit'
  index?: number
}
function PopperApproval(props: PopperApprovalProps) {
  const theme = useTheme()

  const { values } = useFormikContext<IValue>()
  const { handleChangeApproval, filteredPositionList } = values.functions

  const buttonType = {
    add: (
      <Box
        component="img"
        src={AddIcon}
        alt="add"
        sx={{
          width: '2rem',
          cursor: 'pointer',
          position: 'absolute',
          top: '50%',
          right: '0',
          transform: 'translate(50%, -50%)',
          zIndex: 99,
        }}
      />
    ),
    edit: <Box component="img" sx={{ cursor: 'pointer' }} src={DownIcon} />,
  }

  return (
    <PopperContainer placement="right-end" button={buttonType[props.type]}>
      <Box
        sx={{
          ml: '-1rem',
          mb: '-1.5rem',
          backgroundColor: 'white',
          borderRadius: '7px',
          border: '1px solid #e9e9e9',
          px: '1rem',
          py: '1rem',
          position: 'absolute',
          width: '10rem',
        }}
      >
        <Stack rowGap="1rem">
          {filteredPositionList(values.approvalPositionList || [], values.approvalPositionOrders || []).map(
            (position) => (
              <Box
                component="p"
                key={position.id}
                sx={{
                  '&:hover': { color: theme.colors.primary },
                  color: theme.colors.gray6,
                  cursor: 'pointer',
                }}
                onClick={handleChangeApproval.bind(null, position, props.type, props.index)}
              >
                {position.position}
              </Box>
            ),
          )}
        </Stack>
      </Box>
    </PopperContainer>
  )
}

export default ApprovalPolicyPage
