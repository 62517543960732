import { useFormikContext } from 'formik'
import React from 'react'

//Images
import styles from './index.module.scss'
import { handleDecimalsOnValue } from 'src/utils/common'
import { InputCurrencyFormat } from 'src/component/InputCurrency'

const EmployeeTaxDonation: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>ค่าบริจาคทางการศึกษา :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.educationDonate" />

              {/* <InputNumberUnitComponent
                      _name="employeeTax.educationDonate"
                      _value={values.employeeTax.educationDonate}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.educationDonate')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 100,000 บาท</p>
        </div>

        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>ค่าบริจาคอื่น ๆ :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.educationDonate" />

              {/* <InputNumberUnitComponent
                      _name="employeeTax.educationDonate"
                      _value={values.employeeTax.educationDonate}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.educationDonate')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 100,000 บาท</p>
        </div>

        <div className={styles.bottomGroup}>
          <p className={styles.text}>รวมค่าลดหย่อนบริจาค: </p>
          <div className={styles.wrapperText}>
            <p className={styles.textResult}>{handleDecimalsOnValue(values.employeeTax.educationDonate)}</p>
            <p className={styles.text}>บาท/ปี</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeTaxDonation
