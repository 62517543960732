import React from 'react'

//images
import timeLogSVG from '../../image/TimeLog.svg'

//Include Project
import styles from './index.module.scss'
import { TooltipArrow } from 'src/component'
import { toTime } from 'src/utils/common'

interface Props {
  inDiffMinutes: any
  scanIn: any
  timeAttendanceLog: any
}
const TooltipTimeLog = () => {
  return (
    <div className={styles.IconTime}>
      <img src={timeLogSVG} alt="timeLogSVG" />
    </div>
  )
}
const ChipInDiffMinutes: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      {props.scanIn && (
        <div className={styles.textScan}>
          <div className={styles.groupText}>
            <p>{toTime(props.scanIn)}</p>
            <TooltipArrow
              label={
                props.timeAttendanceLog?.scanIn.length > 0
                  ? `เวลาจริงจากเครื่องสแกนนิ้ว ${toTime(props.timeAttendanceLog?.scanIn)} น.`
                  : 'ไม่มีข้อมูลการสแกนนิ้ว'
              }
              child={TooltipTimeLog()}
            />
          </div>

          {props.inDiffMinutes !== 0 && (
            <div className={styles.groupPill}>
              <section className={props.inDiffMinutes < 0 ? styles.pillDiffEarly : styles.pillLate}>
                {props.inDiffMinutes > 0 ? <p>มาสาย</p> : <p>มาก่อน</p>}
              </section>
              <p className={props.inDiffMinutes > 0 ? styles.textLate : styles.textIn}>{props.inDiffMinutes}</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default ChipInDiffMinutes
