import { gql } from '@apollo/client'
import { EStatus } from 'src/utils/generated'

export const queryGetTimeAttendanceStreamCount = `query GetTimeAttendanceReportStreamCount {
  getTimeAttendanceReportStreamCount {
    logTotal
    logProcessed
  }
}`

export const listTimeAttendanceReport = `
query Query($filter: ModelTimeAttendanceReportFilterInput, $sortDirection: ModelSortDirection, $limit: Int, $nextToken: String) {
  listTimeAttendanceReportTypeAttendance(filter: $filter, sortDirection: $sortDirection, limit: $limit, nextToken: $nextToken) {
    items {
      employeeID
      employee {
        id
        fullName
        lastName
        nickName
      }
      date
      scanIn
      scanOut
      lateMinute
    }
    nextToken
  }
}`

export const listTimeAttendanceReportTypeLeave = `
query Query($filter: ModelTimeAttendanceReportFilterInput, $sortDirection: ModelSortDirection, $limit: Int, $nextToken: String) {
  listTimeAttendanceReportTypeLeave(filter: $filter, sortDirection: $sortDirection, limit: $limit, nextToken: $nextToken) {
  items {
      employeeID
      employee {
        id
        fullName
        lastName
        nickName
      }
      date
      scanIn
      scanOut
      leaveForm
      leaveType
      remark
      leaveMinute
    }
    nextToken 
  }
}`

export const listTimeAttendanceReportTypeOT = `
query Query($filter: ModelTimeAttendanceReportFilterInput, $sortDirection: ModelSortDirection, $limit: Int, $nextToken: String) {
  listTimeAttendanceReportTypeOT(filter: $filter, sortDirection: $sortDirection, limit: $limit, nextToken: $nextToken) {
    items {
      employeeID
       employee {
         id
         fullName
         lastName
         nickName
       }
      date
      scanIn
      scanOut
      overtimeType
      dayType
      remark
      overtimeMinute
    }
    nextToken
  }
}`

//Department
export const listDepartment = `
query ListDepartment {
  listDepartment {
    items {
      id
      departmentName
      listEmployee {
        items {
          id
          id
          status
          fullName
          lastName
          sex
          employeeType
        }
      }
    }
  }
}
`
//! recheck
export const listDepartmentTable = ` 
query ListDepartment {
  listDepartment {
    items {
        id
        departmentName
        listEmployee {
          items {
            id
            fullName
            lastName
            status
            }
        }
    }
  }
}
`
export const getDepartmant = `
query Query($getDepartmentId: ID!) {
  getDepartment(id: $getDepartmentId) {
    id
    departmentName
    regulationWorkAttendance
    regulationLeave
    regulationCalendar
    regulationOverTime
    departmentDetail
  }
}
`

//Employee team
export const listTeamEmployee = `
query ListTeam {
  listTeam {
    items {  

      id
      teamName
      listMemberID

    }
  }
}
`
export const listTeamEmployeeTable = `
query Query {
  listTeam {
    items {
      teamName
      id
      teamLeader {
        id
        fullName
        lastName
        status
        position {
          positionName
          positionLevel
        }
      }
      listMember {
        items {
          getEmployee {
            id
            fullName
            lastName
            status
            position {
              positionName
              positionLevel
            }
          }
        }
      }
    }
  }
}
`
export const getTeam = `
query Query($getTeamId: ID!) {
  getTeam(id: $getTeamId) {
    id
    teamName
    teamLeader {
      id
      fullName
      lastName
      position {
        positionName
        positionLevel
      }
      profileImage
    }
    listMember {
      items {
        getEmployee {
          id
          fullName
          lastName
          profileImage
          position {
            positionName
            positionLevel
          }
        }
      }
    }
  }
}
`
// Position
export const listPosition = `
query ListTeam {
  listPosition {
    items {
      id
      positionName
      positionLevel
      remark
      listEmployee {
        items {
          id
        }
      }
    }
  }
}
`
export const getPosition = `
query Query($getPositionId: ID!) {
  getPosition(id: $getPositionId) {
    id
    positionName
    positionLevel
    remark
  }
}
`
//* Approve
export const listApprove = `
query Approve_list($filter: ModelApproveFilterInput) {
  listApprove(filter: $filter) {
    items {
      employeeID
      employee {
        id
        fullName
        lastName
        department {
          departmentName
        }
        profileImage
      }   
      Type
      status
      id

      timestamp
      statusTimeStamp

      approveBy

      createdAt
      updatedAt



      
      ... on ApproveLeave {
        leaveType
        leaveForm
        leaveFormHalfDay
        startedAt
        endedAt
        date
        leaveMinute
        leaveFormHalfDay
        isLeaveDeductMoney
        leaveDocumentAttachment
        remark
        leaveConfig {
          id
          name
        }
      }

      ... on ApproveOvertime
       {
        overtimeType
        date
        startedAt
        overtimeMinute
        endedAt
      }


      
    }
  }
}
`

//! ApproveAttendance
export const listApproveAttendance = `
query Items($filter: ModelApproveAttendanceFilterInput) {
  listApproveAttendance(filter: $filter) {
    items {
      id
      date
      createdAt
      startedAt
      endedAt
      status
      listLeave {
        startedAt
        endedAt
        remark
        status
        leaveType
        leaveFormHour
      }
      listAttendanceReport {
        lateMinute
        date
        scanIn
        scanOut
        Type
      }
      listOvertime {
        overtimeHour
        startedAt
        endedAt
      }
      listCompensation {
        compensationUsed
        compensationPaid
        compensationType
        compensationName
        status
      }
      employee {
        id
        fullName
        lastName
        position {
          positionName
        }
        employeeType
      }
    }
  }
}
`

//*Payment
export const listPayment = `
query Query {
  listPayment {
    items {
      id
      date
      createdAt
      paymentName
      status
      employee {
        id
        fullName
        lastName
        bankAccountNumber
        bankPaymentAmount
      }
    }
  }
}
`

export const listShift = `
query ListShift {
  listShift {
    getDefaultShift
    items {
      shiftID
      workDayPolicy {
        dayList
        timeList {
          startTime
          endTime
          isScanEnd
          isScanEnd
        }
      }
      breakPolicy {
        dayList
        timeList {
          startTime
          endTime
          isScanEnd
          isScanEnd
        }
      }
      employeeType
      shiftName
    }
  }
}`

export const listShiftColor = `
query ListShiftColor {
  listShiftColor {
    hexColorList
  }
}`

export const getShift = `
query GetShift($shiftId: ID!) {
  getShift(shiftID: $shiftId) {
    shiftID
    shiftName
    employeeType
    workDayPolicy {
      dayList
      totalHours
      timeList {
        isScanStart
        isScanEnd
        startTime
        endTime
      }
    }
    breakPolicy {
      dayList
      totalHours
      timeList {
        isScanStart
        isScanEnd
        startTime
        endTime
      }
    }
    workType
    shiftColorIndex
    shiftColor
  }
}`
//Holiday Calendar
export const queryListCalendar = `
query Query {
  listHoliday {
    getDefaultHoliday
    items {
      holidayID
      holidayName
      holidayYear
    }
    nextToken
    startedAt
    getDefaultHoliday
  }
}
`

//GetHoliday
export const queryGetHoliday = `
query Holiday_get($holidayId: ID!, $year: Int!) {
  getHoliday(holidayID: $holidayId, year: $year) {
    holidayID
    holidayName
    holidayYear
  }
}
`

//attendance
// export const querylistAttendanceLog = `
// query Query($nextToken: String, $sortDirection: ModelSortDirection) {
//   listTimeAttendance(nextToken: $nextToken, sortDirection: $sortDirection) {
//     items {
//       employeeID
//       timeID
//       scanAt
//       scanReason
//       scanType
//       locationType
//       lat
//       long
//       imageUrl
//       employee {
//         fullName
//         lastName
//       }
//     }
//     nextToken
//   }
// }
// `

export const LIST_ATTENDANCE_REPORT_EMP = gql`
  query ListTimeAttendanceReportByEmployeeID($employeeId: ID!, $startDate: AWSDate!, $endDate: AWSDate!, $limit: Int) {
    listTimeAttendanceReportByEmployeeID(
      employeeID: $employeeId
      startDate: $startDate
      endDate: $endDate
      limit: $limit
    ) {
      nextToken
      items {
        employeeID
        dayType
        status

        shiftName
        shiftStartTime
        shiftEndTime
        shiftVersion
        shiftMinute

        Type
        date

        scanIn
        inDiffMinute

        scanOut
        outDiffMinute

        inDiffMinute
        breakDiffMinute
        outDiffMinute

        workingMinute
        workingTimeList {
          startedAt
          endedAt
          workingMinute
        }

        overtimeMinute
        overtimeList {
          startedAt
          endedAt
          overtimeMinute
          overtimeType
          locationType
          status
          isCustomOvertimeMultiply
          overtimeMultiply
        }

        leaveMinute
        leaveList {
          startedAt
          endedAt
          leaveMinute
          leaveType
          leaveForm
          leaveDocumentAttachment
          status
          leaveConfig {
            id
            name
            isActive
            leaveAmountType
            leaveConfigType
            employeeAllowSexList
            employeeAllowTypeList
            isLeaveBeforehand
            leaveBeforehandDay
            isLeaveDiligent
            isLeaveCompound
            leaveCompoundType
            maxLeaveCompound
            isLookupNotWorkday
            isLeaveAfterhand
            isCountProbation
            isExceedQuota
            isLeaveOnce
          }
        }
        overtimeApproveList {
          startedAt
          endedAt
          overtimeMinute
          overtimeType
          locationType
          status
          overtimeRequestID
          isCustomOvertimeMultiply
          overtimeMultiply
        }
        overtimeApproveMinute
        breakTimeList {
          startTime
          endTime
          startedAt
          endedAt
        }

        leaveDeductMinute
        leaveDeductList {
          startedAt
          endedAt
          leaveMinute
          leaveType
          leaveForm
          leaveDocumentAttachment
          status
          leaveConfig {
            id
            name
            isActive
            leaveAmountType
            leaveConfigType
            employeeAllowSexList
            employeeAllowTypeList
            isLeaveBeforehand
            leaveBeforehandDay
            isLeaveDiligent
            isLeaveCompound
            leaveCompoundType
            maxLeaveCompound
            isLookupNotWorkday
            isLeaveAfterhand
            isCountProbation
            isExceedQuota
            isLeaveOnce
          }
        }
        timeAttendanceLog {
          scanIn
          scanOut
        }

        isLink
        remark
        status
      }
    }
  }
`

// export const queryListAttendanceReport = `
// query REPORT_IMPORTANT($startDate: AWSDate!, $endDate: AWSDate!, $filter: ModelTimeAttendanceReportFilterInput, $nextToken: String) {
//   listTimeAttendanceReportGroupByEmployee(startDate: $startDate, endDate: $endDate, filter: $filter, nextToken: $nextToken) {

//     items {
//       employeeID

//       employee {
//         id
//         fullName
//         lastName
//         nickName
//         hireDate
//         retireDate
//         department {
//           departmentName
//         }
//       }

//       items {

//         employeeID
//         dayType

//         shiftName
//         shiftStartTime
//         shiftEndTime
//         shiftVersion
//         shiftMinute

//         Type
//         date

//         scanIn
//         inDiffMinute

//         scanOut
//         outDiffMinute

//         inDiffMinute
//         breakDiffMinute
//         outDiffMinute

//         workingMinute
//         workingTimeList {
//           startedAt
//           endedAt
//           workingMinute
//         }

//         overtimeMinute
//         overtimeList {
//           startedAt
//           endedAt
//           overtimeMinute
//           overtimeType
//           locationType
//           status
//         }

//       leaveMinute
//       leaveList {
//         startedAt
//         endedAt
//         leaveMinute
//         leaveType
//         leaveForm
//         leaveDocumentAttachment
//         status
//       }
//       overtimeApproveList {
//         startedAt
//         endedAt
//         overtimeMinute
//         overtimeType
//         locationType
//         status
//         overtimeApproveMinute
//         overtimeRequestID
//       }
//       overtimeApproveMinute
//       breakTimeList {
//         startTime
//         endTime
//         startedAt
//         endedAt
//       }

//       leaveDeductMinute
//       leaveDeductList {
//         startedAt
//         endedAt
//         leaveMinute
//         leaveType
//         leaveForm
//         leaveDocumentAttachment
//         status
//       }

//         isLink
//         remark

//       }

//     }
//     nextToken
//   }
// }
// `

//ListPayroll
export const queryListPayroll = `
query Query($yearMonth: String!, $period: String, $nextToken: String) {
  listPayroll(yearMonth: $yearMonth, period: $period, nextToken: $nextToken) {
    yearMonth
    period
    employeeCount
    employeeTypeCount {
      FULLTIME_MONTH
      FULLTIME_DAY
      PARTTIME
      OUTSOURCE
    }
    employeeHireCount
    employeeRetireCount
    status
    createdAt
    updatedAt
    paySlipEmpPeriod
    periodList {
      period
      status
      paySlipEmpPeriod
      closeDate
    }
    totalPayroll
    closeDate
    nextToken
    employeePayrollList {
      employeeID
      
      leaveMinute
      paySlipURL
      employeePeriod
      paymentID
      daysInPeriod
      employee {
        id
        positionID
        departmentID
        nameTitle
        fullName
        lastName
        department {
          departmentName
        }
      }
      netIncome
      netNegativeIncome
      outEarlyExpense
      breakLateExpense
      inLateExpense
      otherExpenseList {
        id
        name
        incomeOrExpense
        incomeExpenseType
        variableOrFixed
        income40Type
        amount
      }
      netPositiveIncome
      outLateIncome
      breakEarlyIncome
      inEarlyIncome
      workDiligentIncome
      welfareList {
        id
        name
        income40Type
        amount
      }
      leaveDeductMinute
      otTypeWorkMinute
      otTypeOTMinute
      income
      otTypeOTIncome
      otherIncomeList {
        id
        name
        incomeOrExpense
        incomeExpenseType
        variableOrFixed
        income40Type
        amount
      }
      otTypeWorkIncome
      workingMinute
    }
    timeAttendanceReportList {
      employeeID
      employee {
        id
        fullName
        lastName
        department {
          id
          departmentName
        }
      }
      items {
        companyID
        date
        employeeID
        createdAt
        dayType
        Type
        updatedAt
        isLink
        remark
        shiftName
        shiftStartTime
        shiftEndTime
        shiftVersion
        shiftMinute
        scanIn
        scanOut
        inDiffMinute
        outDiffMinute
        breakDiffMinute
        workingTimeList {
          startedAt
          endedAt
          workingMinute
        }
        workingMinute
        overtimeList {
          startedAt
          endedAt
          overtimeMinute
          overtimeType
          locationType
          status
          overtimeRequestID
        }
        overtimeMinute
        overtimeApproveList {
          startedAt
          endedAt
          overtimeMinute
          overtimeType
          locationType
          status
          overtimeRequestID
        }
        overtimeApproveMinute
        leaveList {
          startedAt
          endedAt
          leaveMinute
          leaveType
          leaveForm
          leaveDocumentAttachment
          leaveRequestID
          status
        }
        leaveMinute
        leaveDeductList {
          startedAt
          endedAt
          leaveMinute
          leaveType
          leaveForm
          leaveDocumentAttachment
          leaveRequestID
          status
        }
        leaveDeductMinute
      }
    }
  }
}
`

//GetAttendanceReport
export const queryGetAttendance = `
query TimeReport_get($employeeId: ID!, $date: AWSDate!, $year: String!) {
  getTimeAttendanceReport(employeeID: $employeeId, date: $date) {
    employeeID
    dayType
    Type
    date
    shiftName
    shiftStartTime
    shiftEndTime
    shiftVersion
    shiftMinute
    scanIn
    scanOut
    workingTimeList {
      startedAt
      endedAt
      workingMinute
    }
    inDiffMinute
    breakDiffMinute
    outDiffMinute
    overtimeList {
      overtimeType
      startedAt
      endedAt
      isCustomOvertimeMultiply
      overtimeMultiply
      status   
    }
    leaveList {
      startedAt
      endedAt
      leaveMinute
      leaveType
      leaveForm
      leaveDocumentAttachment
      leaveRequestID
      status
      leaveConfig {
        id
        name
        isActive
        leaveAmountType
        leaveConfigType
        employeeAllowSexList
        employeeAllowTypeList
        isLeaveBeforehand
        leaveBeforehandDay
        isLeaveDiligent
        isLeaveCompound
        leaveCompoundType
        maxLeaveCompound
        isLookupNotWorkday
        isLeaveAfterhand
        isCountProbation
        isExceedQuota
        isLeaveOnce
      }
    }
    leaveDeductList {
      startedAt
      endedAt
      leaveMinute
      leaveType
      leaveForm
      leaveDocumentAttachment
      leaveRequestID
      status
      leaveConfig {
        id
        name
        isActive
        leaveAmountType
        leaveConfigType
        employeeAllowSexList
        employeeAllowTypeList
        isLeaveBeforehand
        leaveBeforehandDay
        isLeaveDiligent
        isLeaveCompound
        leaveCompoundType
        maxLeaveCompound
        isLookupNotWorkday
        isLeaveAfterhand
        isCountProbation
        isExceedQuota
        isLeaveOnce
      }
    }
    breakTimeList {
      startTime
      endTime
      startedAt
      endedAt
    }

    overtimeApproveList {
      startedAt
      endedAt
      overtimeMinute
      overtimeType
      locationType
      status
      isCustomOvertimeMultiply
      overtimeMultiply
      overtimeRequestID
    }
    overtimeApproveMinute

    isLink
    workingMinute
    overtimeMinute
    leaveMinute
    remark
    leaveDeductMinute
    employee {
      id
      fullName
      lastName
      sex
      nickName
      employeeType
      shiftWorkingID
      employeeType
      getPackageGroup {
        packageGroupID
        name
      }
      listLeaveQuota(year: $year) {
        items {
          companyID
          employeeID
          period
          quotaType
          packageID
          prev
          begin
          used
          approved
          balance
          leaveConfig {
            id
            name
            isActive
            leaveAmountType
            leaveConfigType
            employeeAllowSexList
            isExceedQuota
          }
        }
      }
      hireDate
      retireDate
    }
    timeAttendanceLog {
      scanIn
      scanOut
    }
    status
  }
}
`
export const queryPayrollConfig = `
query GetPayrollConfig {
  getPayrollConfig {
    periodStart
    periodCount
    period1Day
    period2Day
    period3Day
    workingDayCount
    workingDayCountNewEmployee
    resetLeaveDay
    workingHourDivisor
    roundType
  }
}
`
// ShareCalendar
export const getShareCalendar = `
query GetShareCalendar($input: GetShareCalendarInput!) {
  getShareCalendar(input: $input) {
    year
    items {
      date
      isHoliday
      holidayDescription
      events {
        Type
        event
        employeeID
        employee {
          id
          fullName
          profileImage
          lastName
        }
      }
    }
  }
}
`
// Employee
export const GET_ACTIVE_EMPLOYEE = gql`
  query ListEmployee($filter: ModelEmployeeFilterInput, $limit: Int) {
    listEmployee(filter: $filter, limit: $limit) {
      items {
        id
        fullName
        lastName
        department {
          id
          departmentName
        }
        hireDate
        retireDate
        profileImage
        nickName
      }
    }
  }
`

export const GET_LISTPAYROLL = gql`
  query Query($yearMonth: String!, $period: String, $nextToken: String) {
    listPayroll(yearMonth: $yearMonth, period: $period, nextToken: $nextToken) {
      yearMonth
      period
      employeeCount
      employeeTypeCount {
        FULLTIME_MONTH
        FULLTIME_DAY
        PARTTIME
        OUTSOURCE
      }
      employeeHireCount
      employeeRetireCount
      status
      createdAt
      updatedAt
      paySlipEmpPeriod
      periodList {
        period
        status
        paySlipEmpPeriod
        closeDate
      }
      totalPayroll
      closeDate
      nextToken
      employeePayrollList {
        employeeID
        leaveMinute
        paySlipURL
        employeePeriod
        paymentID
        daysInPeriod
        employee {
          id
          positionID
          departmentID
          employeeType
          nameTitle
          fullName
          lastName
          department {
            departmentName
          }
        }
        netIncome
        netNegativeIncome
        outEarlyExpense
        breakLateExpense
        inLateExpense
        otherExpenseList {
          id
          name
          incomeOrExpense
          incomeExpenseType
          variableOrFixed
          income40Type
          amount
        }
        netPositiveIncome
        outLateIncome
        breakEarlyIncome
        inEarlyIncome
        workDiligentIncome
        welfareList {
          id
          name
          income40Type
          amount
        }
        leaveDeductMinute
        otTypeWorkMinute
        otTypeOTMinute
        income
        otTypeOTIncome
        otherIncomeList {
          id
          name
          incomeOrExpense
          incomeExpenseType
          variableOrFixed
          income40Type
          amount
        }
        otTypeWorkIncome
        workingMinute
      }
      timeAttendanceReportList {
        employeeID
        employee {
          id
          fullName
          lastName
          department {
            id
            departmentName
          }
        }
        items {
          companyID
          date
          employeeID
          createdAt
          dayType
          Type
          updatedAt
          isLink
          remark
          shiftName
          shiftStartTime
          shiftEndTime
          shiftVersion
          shiftMinute
          scanIn
          scanOut
          inDiffMinute
          outDiffMinute
          breakDiffMinute
          workingTimeList {
            startedAt
            endedAt
            workingMinute
          }
          workingMinute
          overtimeList {
            startedAt
            endedAt
            overtimeMinute
            overtimeType
            locationType
            status
            overtimeRequestID
          }
          overtimeMinute
          overtimeApproveList {
            startedAt
            endedAt
            overtimeMinute
            overtimeType
            locationType
            status
            overtimeRequestID
          }
          overtimeApproveMinute
          leaveList {
            startedAt
            endedAt
            leaveMinute
            leaveType
            leaveForm
            leaveDocumentAttachment
            leaveRequestID
            status
          }
          leaveMinute

          leaveDeductMinute
        }
      }
    }
  }
`
/** Filter Active Employees that has hire/retire dates intersection with startDate/endDate
 * This is achieved by endDate > hireDate && startDate < retireDate
 * @param {string} startDate the startDate to filter
 * @param {string} endDate the end date to filter
 * @return {OperationVariables} Filter equation for the listEmployee query
 */
export function activeEmployeeFilter(
  startDate = new Date('1950-01-01').toLocaleDateString('sv-SE'),
  endDate: string = new Date().toLocaleDateString('sv-SE'),
): { filter: Record<string, any> } {
  return {
    filter: {
      status: {
        eq: EStatus.Active,
      },
      hireDate: {
        le: endDate,
      },
      retireDate: {
        ge: startDate,
      },
    },
  }
}

export const ListEmployee = `
query ListEmployee($year: String!, $filter: ModelEmployeeFilterInput) {
  listEmployee(filter: $filter) {
    items {
      id
      positionID
      departmentID
      status
      nickName
      fullName
      lastName
      middleName
      personalID
      sex
      hireDate
      retireDate
      department {
        id
        departmentName
      }
      profileImage
      getCurrentShift {
        shiftID
        shiftName
      }
      listLeaveQuota(year: $year) {
        items {
          companyID
          employeeID
          period
          quotaType
          packageID
          prev
          begin
          used
          approved
          balance
          getPackageGroup {
            packageGroupID
            name
          }
          leaveConfig {
            id
            name
            isExceedQuota
          }
        }
      }
    }
  }
}
`

//GetEmployee
export const querygetEmployee = `
query GetEmployee($year: String!, $listWelfareQuotaYear2: String!, $getEmployeeId: ID) {
  getEmployee(id: $getEmployeeId) {
   nameTitle
    sex
    fullName
    middleName
    lastName
    fullNameEnglish
    middleNameEnglish
    lastNameEnglish
    birthDate
    nickName
    ethnicity
    nationality
    religion
    bloodType
    weight
    height
    militaryStatus
    remark
    historyFamily {
      relation
      fullName
      birthDate
      job
      familyStatus
    }
    personalRelation
    detailsFamily {
      childrens
      fullName
      lastName
      job
      positionName
      companyName
      tel
      siblings
      myNumber
      maleNumber
      femaleNumber
    }
    tel
    email
    lineId
    emergencyContact {
      fullName
      lastName
      relation
      tel
      address
    }
    addressPersonalID {
      addressNumber
      group
      optional
      province
      subDistrict
      district
      zipCode
    }
    addressCurrent {
      addressNumber
      group
      optional
      province
      subDistrict
      district
      zipCode
    }
    detailsFamily {
      childrens
      fullName
      lastName
      job
      positionName
      companyName
      tel
      siblings
      myNumber
      maleNumber
      femaleNumber
    }

    historyEducation {
      educationLevel
      educationName
      majorName
      startedYear
      endedYear
      grade
    }
      historyWork {
      workName
      startedAt
      endedAt
      positionName
      descPosition
      salary
      referral
      tel
      leaveReason
    }
    historyTraining {
      trainingName
      courseName
      date
      period
    }
    language {
      language
      speak
      write
      read
    }
  
    historySick {
      sickName
      isSick
      remark
    }
    fingerPrintId
    hireDate
    retireDate
    employeeType
    departmentID
    teamList
    positionID
    leaderTeam
    employeeWorkStatus
    employeeRetireDate
    employeeRetireRemark
    isTimeAttendance
    setShiftWorking
    shiftWorkingID
    jobDescAttachment
    workPlace
    packageGroupID
    holidayID
    holidayLink
    getHolidayID
    salaryOptional
    salary
    incomeExpense {
      incomeOrExpenseID
      incomeExpenseType
      name
      total
      unit
    }
    taxCalculate
    taxFixedPerMonth
    taxStartedMonth
    socialSecurityCalculate
    hospitalService
    diligentAllowance
    employeeFund {
      incomeOrExpenseID
      name
      fundNumber
      contractDate
      deductMethod
      fundRate
      contributionMethod
      contributionCompany
      contributionTotal
      beneficiary
      remark
    }
    paymentMethod
    bankPayment
    bankAccountName
    bankAccountId
    profileImage
    username
    password
    personalIdAttachment
    personalHouseAttachment
    personalEducationalAttachment
    personalTrainningAttachment
    personalOptionalAttachment
    personalMarriageAttachment
    nationalityType
    personalID
    personalIDAt
    personalPassportID
    personalWorkID
    personalIDIssue
    personalIDExpire
    socialSecurity
    taxDeductOne {
      year
      month
      total
    }
    lineUserId
    taxDeductTwo {
      name
      rateDeduct
      totalDeduct
    }
    position {
      id
      positionName
      positionLevel
      remark
    }
    department {
      id
      departmentName
      regulationWorkAttendance
      regulationLeave
      regulationCalendar
      regulationOverTime
      departmentDetail
    }
    listLeaveQuota(year: $year) {
      items {
        companyID
        employeeID
        quotaType
        packageID
        prev
        begin
        used
        approved
        balance
        leaveConfig {
          id
          name
          isActive
          leaveAmountType
          leaveConfigType
          employeeAllowSexList
          employeeAllowTypeList
          isExceedQuota
        }
      }
    }
    getPackageGroup {
      packageGroupID
      name
    }
    listWelfareQuota(year: $listWelfareQuotaYear2) {
      items {
        companyID
        employeeID
        period
        quotaType
        packageID
        prev
        begin
        used
        approved
        balance
        leaveConfig {
          id
          name
        }
      }
    }
    id
  }
}
`

//QueryLeaveDocument
export const queryLeaveDocument = `
query LeaveListGroupByEmployee($nextToken: String, $filter: ModelLeaveFilterInput, $year: String!) {
  listLeaveGroupByEmployee(filter: $filter, nextToken: $nextToken) {
    items {
      employeeID
      employee {
        fullName
        lastName
        profileImage
      }
      items {
        leaveType
        leaveForm
        createdAt
        startedAt
        endedAt
        status
        id
        employee {
          id
          positionID
          departmentID
          status
          fullName
          lastName
          profileImage
            listLeaveQuota(year: $year) {
          items {
            companyID
            employeeID
            period
            quotaType
            packageID
            prev
            begin
            used
            approved
            balance
            leaveConfig {
              id
              name
              isActive
              leaveAmountType
              leaveConfigType
              employeeAllowSexList
              employeeAllowTypeList
              isLeaveBeforehand
              leaveBeforehandDay
              isLeaveDiligent
              isLeaveCompound
              leaveCompoundType
              maxLeaveCompound
              isLookupNotWorkday
              isLeaveAfterhand
              isCountProbation
              isExceedQuota
              isLeaveOnce
            }
            getPackageGroup {
              packageGroupID
              name
            }
          }
        }
        }
        leaveConfig {
          id
          name
          isActive
          leaveAmountType
          leaveConfigType
          employeeAllowSexList
          employeeAllowTypeList
          isLeaveBeforehand
          leaveBeforehandDay
          isLeaveDiligent
          isLeaveCompound
          leaveCompoundType
          maxLeaveCompound
          isLookupNotWorkday
          isLeaveAfterhand
          isCountProbation
          isExceedQuota
          isLeaveOnce
        }
      }

    }
    nextToken
  }
}
`

//Document Overtime
export const queryOvertimeDocument = `
query ListOvertimeGroupByEmployee($nextToken: String, $filter: ModelOvertimeFilterInput) {
  listOvertimeGroupByEmployee(nextToken: $nextToken, filter: $filter) {
    items {
      employeeID
      employee {
        fullName
        lastName
        profileImage
      }
      items {
        startedAt
        endedAt
        overtimeType
        overtimeMinute
        status
        id
        remark
        date
        employee {
          id
          departmentID
          fullName
          lastName
          profileImage
        }
      }
    }
    nextToken
  }
}
`

//GetOvertimeDocument
export const querygetovertimeDocument = `
query GetOvertime($employeeId: ID!, $getOvertimeId: ID!) {
  getOvertime(employeeID: $employeeId, id: $getOvertimeId) {
    id
    employeeID
    createdAt
    date
    startedAt
    endedAt
    overtimeType
    overtimeMultiply
    isCustomOvertimeMultiply
    status
    employee {
      id
      status
      fullName
      middleName
      lastName
    }
    
  }
}
`

//GetLeaveDocument
export const queryGetLeaveDocument = `
query GetLeave($getLeaveId: ID!, $employeeId: ID!) {
  getLeave(id: $getLeaveId, employeeID: $employeeId) {
    id
    employeeID
    createdAt
    date
    startedAt
    endedAt
    leaveType
    employee {
      id
      fullName
      middleName
      lastName
      departmentID
      positionID
      profileImage
    }
  }
}
`

export const queryotherIncome = ` 
query ListOtherIncomeOrExpenseConfig($filter: ModelOtherIncomeOrExpenseConfigFilterInput) {
  listOtherIncomeOrExpenseConfig(filter: $filter) {
    otherIncomeConfigList {
      id
      name
      incomeOrExpense
      status
      incomeExpenseType
      variableOrFixed
      roundType
      income40Type
      calculateWithList
    }
    otherExpenseConfigList {
      id
      name
      incomeOrExpense
      status
      incomeExpenseType
      variableOrFixed
      roundType
      income40Type
      calculateWithList
    }
  }
}
`

export const queryListLeaveConfig = `
query LeaveConfig_list {

  listLeaveConfig {
    items {
      id
      name
      isActive
      leaveAmountType
      leaveConfigType
      employeeAllowSexList
      employeeAllowTypeList
      leaveQuotaList {
        workMonthExperience
        quotaLeaveDay
        maxLeavePaidDay
      }
      isLeaveBeforehand
      leaveBeforehandDay
      isLeaveDiligent
      isLeaveCompound
      leaveCompoundType
      maxLeaveCompound
      isLookupNotWorkday
      isLeaveAfterhand
      isCountProbation
      isExceedQuota
      isLeaveOnce

    }
  }

}
`

export const queryGetOvertimeConfig = `
query GetOvertimeConfig {
  getOvertimeConfig {
    overtimeMonthStatus
    overtimeMonth
    overtimeDayStatus
    overtimeDay
    overtimeBeforeMinute
    overtimeAfterMinute
    overtimeBreakBeforeMinute
    overtimeBreakAfterMinute
    overtimeMultiplyType
    overtimeRoundType
    overtimeRoundHour
    overtimeRoundFloor
  }
}
`

export const queryGetCompanyDetail = `
query GetCompanyDetail {
  getCompanyDetail {
    companyProfileImage
    companyID
    name
    taxID
    status
    companyAddress {
      addressNumber
      group
      optional
      province
      subDistrict
      district
      zipCode
    }
    bankAccountInformation {
      bankPayment
      bankAccountId
      bankReferenceCompanyName
    }
    companyContactInformation {
      tel
    }
  }
}
`

export const queryGetDiligent = `
query GetDiligent {
  getDiligent {
    diligentPerMonth
    diligentType
    incrementPerRound
    maxIncrementRound
    extraMoney
    monthStart
    monthEnd
    monthPayment
    status
  }
}
`

export const queryListOtherIncomeOrExpenseConfig = `
query ListOtherIncomeOrExpenseConfig {
  listOtherIncomeOrExpenseConfig {
    otherIncomeConfigList {
      id
      name
      incomeOrExpense
      status
      incomeExpenseType
      variableOrFixed
      roundType
      income40Type
      calculateWithList
    }
    otherExpenseConfigList {
      id
      name
      incomeOrExpense
      status
      incomeExpenseType
      variableOrFixed
      roundType
      income40Type
      calculateWithList
    }
  }
}
`

export const queryGetTaxConfig = `
query GetTaxConfig {
  getTaxConfig {
    isBaseSalary
    income
    expense
    welfare
    fund
    auto
    overtime
  }
}
`

export const queryListWelfareConfig = `
query listWelfareConfig {
  listWelfareConfig {
    items {
      welfareID
      name
      isActive
      income40Type
      roundType
      employeeAllowSexList
      employeeAllowTypeList
      welfareQuotaList {
        workMonthExperience
        quota
        quotaUOM
      }
      isCountProbation
      isCompound
      welfareCompoundType
      maxWelfareCompound
      isExceedQuota
      isShare
    }
  }
}
`

export const queryGetProvidentFundConfig = `
query getProvFund {
  getProvidentFundConfig {
    isBaseSalary
    qualifyFund
    income
    expense
    welfare
    fund
    auto
    overtime
  }
}
`

export const queryGetSocialSecurityConfig = `
query GetSocialSecurityConfig {
  getSocialSecurityConfig {
    status
    employeePercent
    employerPercent
    isBaseSalary
    income
    expense
    welfare
    fund
    auto
    overtime
    dependencyList
  }
}
`

export const queryListWithdrawalConfig = `
query ListWithdrawalConfig {
  listWithdrawalConfig {
    items {
    withdrawalID
    name
    isActive
    roundType
    employeeAllowSexList
    employeeAllowTypeList
    withdrawalQuotaList {
      workMonthExperience
      quota
      quotaUOM
    }
    isCountProbation
    isShare
    }
  }
}`

export const queryGetWithdrawalConfig = `
query Query($withdrawalId: ID!) {
  getWithdrawalConfig(withdrawalID: $withdrawalId) {
    withdrawalID
    name
    isActive
    roundType
    employeeAllowSexList
    employeeAllowTypeList
    withdrawalQuotaList {
      workMonthExperience
      quota
      quotaUOM
    }
    isCountProbation
    isShare
  }
}`

export const queryGetPayrollConfig = `
query GetPayrollConfig {
  getPayrollConfig {
    periodStart
    periodCount
    period1Day
    period2Day
    period3Day
    workingDayCount
    workingDayCountNewEmployee
    resetLeaveDay
    workingHourDivisor
    roundType
    fulltimeDayPaidHoliday
  }
}`

export const queryGetTimeAttendanceConfig = `
query GetTimeAttendanceConfig {
  getTimeAttendanceConfig {
    dayShift
    nightShift
    countType
    noneFingerPrint
    roundType
    timeBreak
    timeBreakFirst
    isWorkInEarly
    maxWorkInEarlyMinute
    isWorkInEarlyPaid
    workInEarlyPaidRate
    workInEarlyPaidType
    isWorkInEarlyDiligent
    isWorkInLate
    maxWorkInLateMinute
    isWorkInLateDiligent
    maxWorkInLateType
    isWorkInLateDeduct
    workInLateDeductRate
    workInLateDeductType
    isBreakOvertime
    maxBreakOvertimeMinute
    isBreakOvertimeDiligent
    breakOvertimeType
    isBreakOvertimeDeduct
    breakOvertimeDeductRate
    breakOvertimeDedcutType
    isBreakEarly
    maxBreakEarlyMinute
    isBreakEarlyDiligent
    isBreakEarlyPaid
    breakEarlyPaidRate
    breakEarlyPaidType
    isWorkOutEarly
    isWorkOutEarlyDiligent
    workOutEarlyType
    maxWorkOutEarlyMinute
    isWorkOutEarlyDeduct
    workOutEarlyDeductRate
    workOutEarlyDeductType
    isWorkOutLate
    isWorkOutLateDiligent
    maxWorkOutLateMinute
    workOutLateQuotaType
    isWorkOutLatePaid
    isWorkOutLatePaidRate
    isWorkOutLatePaidType
  }
}
`

export const LIST_PACKAGE_GROUP = gql`
  query ListPackageGroup {
    listPackageGroup {
      items {
        packageGroupID
        name
        leavePackageList {
          leaveConfigID
          leaveConfig {
            id
            name
            leaveQuotaList {
              workMonthExperience
              quotaLeaveDay
              maxLeavePaidDay
            }
          }
          customQuotaList {
            workMonthExperience
            isCustomQuota
            customQuota
          }
        }
        welfarePackageList {
          welfareConfigID
          welfareConfig {
            welfareID
            name
            welfareQuotaList {
              workMonthExperience
              quota
              quotaUOM
            }
          }
          customQuotaList {
            workMonthExperience
            isCustomQuota
            customQuota
            customQuotaUOM
          }
        }
        employeeCount
        employeeList {
          items {
            id
            getEmployee {
              id
              profileImage
            }
          }
        }
      }
    }
  }
`

export const GET_PACKAGE_GROUP = gql`
  query GetPackageGroup($packageGroupId: ID!) {
    getPackageGroup(packageGroupID: $packageGroupId) {
      packageGroupID
      name
      leavePackageList {
        leaveConfigID
        leaveConfig {
          id
          name
          leaveQuotaList {
            workMonthExperience
            quotaLeaveDay
            maxLeavePaidDay
          }
        }
        customQuotaList {
          workMonthExperience
          isCustomQuota
          customQuota
        }
      }
      welfarePackageList {
        welfareConfigID
        welfareConfig {
          welfareID
          name
          welfareQuotaList {
            workMonthExperience
            quota
            quotaUOM
          }
        }
        customQuotaList {
          workMonthExperience
          isCustomQuota
          customQuota
          customQuotaUOM
        }
      }
      employeeCount
      employeeList {
        items {
          id
          getEmployee {
            fullName
            id
            profileImage
          }
        }
      }
    }
  }
`
