import { Divider, Fade, Popper } from '@mui/material'
import * as React from 'react'
import ButtonComponent from '../Button'
import styles from './index.module.scss'

interface IPopper {
  open: boolean
  anchorEI: any
  typFilter?: any
}

export const FilterPopperComponent: React.FC<IPopper> = (props: IPopper): JSX.Element => {
  const [showSelect, setShowSelect] = React.useState(false)
  const [countFilter, setCounter] = React.useState<any>([])

  return (
    <Popper transition placement="right" anchorEl={props.anchorEI} open={props.open}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div className={styles.Popper}>
            {/* <div className={styles.pointer}></div> */}
            <div className={styles.modal}>
              <div className={styles.modalGroup}>
                <section className={styles.Header}>
                  <p>ค้นหาแบบละเอียด</p>
                </section>
                {props.typFilter.map((item: any, index: number) => (
                  <section className={styles.BoxFilter} key={index}>
                    <p style={{ fontSize: '16px' }}>{item.name}</p>
                    <div className={styles.GroupCard}>
                      {item.submenu.map((ele: any, index: number) => (
                        <div
                          className={styles.card}
                          key={index}
                          onClick={() => {
                            console.log(ele.value)
                          }}
                        >
                          {ele.label}
                        </div>
                      ))}
                    </div>
                  </section>
                ))}
                <Divider />
                <section className={styles.FooterButton}>
                  <ButtonComponent _colorBG="white" _text="ล้าง" _type="button" _variant="outlined" _colorText="red" />
                  <ButtonComponent
                    _colorBG="blue"
                    _text="ตกลง"
                    _type="button"
                    _variant="contained"
                    _colorText="white"
                  />
                </section>
              </div>
            </div>
          </div>
        </Fade>
      )}
    </Popper>
  )
}
