//lib
import React from 'react'
import { useFormikContext } from 'formik'
import { gql, useQuery } from '@apollo/client'

//include Project
import styles from './index.module.scss'

//Image
import ProfileBg from '../../component/ProfileUpload/images/profile-BG.svg'

interface Props {
  tabItem: any[]
  tab: string
  setTab: any
  executeScroll?: any
}

const EmployeeProfileNavbar: React.FC<Props> = (props: Props): JSX.Element => {
  const { values } = useFormikContext<any>()

  const { data: getPosition } = useQuery(QUERY_POSITION, {
    variables: { getPositionId: values.formInput.positionID },
  })

  const { data: getDepartment } = useQuery(QUERY_DEPARTMENT, {
    variables: { getDepartmentId: values.formInput.departmentID },
  })

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        {values.formInput.profileImage ? (
          <img src={values.formInput.profileImage} alt="" className={styles.profile} />
        ) : (
          <img src={ProfileBg} alt="" className={styles.profileMock} />
        )}
        <section className={styles.groupText}>
          <div className={styles.wrapperText}>
            <div className={styles.oneline}>
              <p className={styles.textHead}>ชื่อ</p>
              <p className={styles.text}>{values.formInput.fullName}</p>
            </div>
            <div className={styles.oneline}>
              <p className={styles.textHead}></p>
              <p className={styles.text}>{values.formInput.fullNameEnglish}</p>
            </div>
          </div>

          <div className={styles.wrapperText}>
            <div className={styles.oneline}>
              <p className={styles.textHead}>นามสกุล</p>
              <p className={styles.text}>{values.formInput.lastName}</p>
            </div>
            <div className={styles.oneline}>
              <p className={styles.textHead}></p>
              <p className={styles.text}>{values.formInput.lastNameEnglish}</p>
            </div>
          </div>
          <div className={styles.wrapperText}>
            <div className={styles.oneline}>
              <p className={styles.textHead}>ชื่อเล่น</p>
              <p className={styles.text}>{values.formInput.nickName}</p>
            </div>
          </div>
          <div className={styles.wrapperText}>
            <div className={styles.oneline}>
              <p className={styles.textHead}>ตำแหน่ง</p>
              <p className={styles.text}> {getPosition?.getPosition?.positionName}</p>
            </div>
            <div className={styles.oneline}>
              <p className={styles.textHead}>แผนก</p>
              <p className={styles.text}>{getDepartment?.getDepartment?.departmentName}</p>
            </div>
          </div>
        </section>
      </section>

      <section className={styles.buttonGroup}>
        {props.tabItem.map((item: any, index: number) => (
          <button
            type="button"
            onClick={() => {
              props.setTab(item.name)
              props.executeScroll(item.ref)
            }}
            className={props.tab === item.name ? styles.buttonHover : styles.button}
            key={index}
          >
            {index + 1}.{item.title}
          </button>
        ))}
      </section>
    </div>
  )
}

export default EmployeeProfileNavbar

const QUERY_POSITION = gql`
  query Query($getPositionId: ID!) {
    getPosition(id: $getPositionId) {
      id
      positionName
    }
  }
`

const QUERY_DEPARTMENT = gql`
  query Query($getDepartmentId: ID!) {
    getDepartment(id: $getDepartmentId) {
      departmentDetail
      departmentName
      id
    }
  }
`
