import React from 'react'
import styles from './index.module.scss'
import { Modal, Backdrop } from '@mui/material'
import colseSVG from '../../image/closeGray.svg'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'src/state/reducers'
import { useGetEmployee } from 'src/adapter/api/employee'
import { Employee } from 'src/utils/generated'

interface IModal {
  open: boolean
  setOpen: any
}
export const ModalQuota: React.FC<IModal> = (props: IModal): JSX.Element => {
  const header = ['การลา', 'โควต้า(วัน)', 'ใช้ไป(วัน)', 'อนุมัติ (วัน)', 'คงเหลือ (วัน)']
  const { decodedAccessToken } = useSelector((state: ICombineReducers) => state.loginReducer)
  const userId = decodedAccessToken.userID
  const years = new Date().getFullYear()
  const { data } = useGetEmployee({ getEmployeeId: userId, year: `${years}`, listWelfareQuotaYear2: `${years}` })
  const _data = data as Employee

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={styles.modal}>
          <div className={styles.card}>
            <div className={styles.header}>
              <p>โควต้าการลาคงเหลือ</p>
              <img src={colseSVG} style={{ cursor: 'pointer' }} alt="colseSVG" onClick={() => props.setOpen(false)} />
            </div>

            <div>
              <table className={styles.tableRounded}>
                <tr>
                  {header.map((item: any, index: number) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
                {_data?.listLeaveQuota?.items?.length &&
                  _data?.listLeaveQuota?.items?.map((ele, index: number) => (
                    <tr key={index}>
                      <td>
                        {ele.leaveConfig?.name}
                        <span className={styles.textsub}> ({ele.prev! < 0 ? 'ลาแบบหักเงิน' : 'ลาแบบไม่หักเงิน'})</span>
                      </td>
                      <td>{ele.prev! < 0 ? 'ไม่จำกัด' : ele.begin}</td>
                      <td>{ele.used}</td>
                      <td>{ele.approved}</td>
                      <td>{ele.prev! < 0 ? 'ไม่จำกัด' : ele.begin! - ele.used!}</td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
