import { Box, Stack } from '@mui/material'
import { FormikProvider, useFormik, Form } from 'formik'
import React from 'react'
import {
  getListOtherIncomeOrExpenseConfig,
  getListWelfareConfig,
  getSocialSecurityConfig,
  putSocialSecurityConfig,
} from '../../adapter/api'
import {
  InputNumberComponent,
  AutocompleteComponent,
  ContentContainer,
  ButtonComponent,
  InputCheckboxComponentToggle,
} from '../../component'
import {
  EIncomeExpenseType,
  OtherIncomeOrExpenseConfig,
  OtherIncomeOrExpenseConfigItem,
  SocialSecurityConfig,
  WelfareConfig,
  EStatus,
  PutSocialSecurityConfigInput,
  EOverTimeType,
} from '../../utils/generated'

type IValue = {
  socialSecurityConfig?: SocialSecurityConfig
  incomeOrExpenseConfig?: OtherIncomeOrExpenseConfig
  incomeList?: OtherIncomeOrExpenseConfigItem[]
  expenseList?: OtherIncomeOrExpenseConfigItem[]
  welfareList?: WelfareConfig[]
}

import Swal from 'sweetalert'
import { deepClone } from '../../utils/common'

const SocialSecurityPolicyPage: React.FC = () => {
  const initialValues: IValue = {}

  const [prevConfig, setPrevConfig] = React.useState<SocialSecurityConfig>()

  const onSubmit = async (values: IValue) => {
    try {
      const input = values.socialSecurityConfig as PutSocialSecurityConfigInput

      if (!input.status) input.status = EStatus.Active
      await putSocialSecurityConfig(input)
      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
    }
  }

  const formik = useFormik({ initialValues, onSubmit })

  const fethcSocialSecurityConfig = async () => {
    const res = await getSocialSecurityConfig()
    formik.setFieldValue('socialSecurityConfig', res)
    setPrevConfig(deepClone(res))
  }

  const fetchIncomeExpense = async () => {
    const res = await getListOtherIncomeOrExpenseConfig()
    formik.setFieldValue('incomeOrExpenseConfig', res)
    formik.setFieldValue('incomeList', res.otherIncomeConfigList)
    formik.setFieldValue('expenseList', res.otherExpenseConfigList)
  }

  const fetchWelfareConfig = async () => {
    const res = await getListWelfareConfig()
    formik.setFieldValue('welfareList', res.items)
  }

  React.useEffect(() => {
    fethcSocialSecurityConfig()
    fetchIncomeExpense()
    fetchWelfareConfig()
  }, [])

  const incomeSelectList = React.useMemo(() => {
    const list = formik.values.incomeList || []

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.incomeList])

  const expenseSelectList = React.useMemo(() => {
    const list = formik.values.expenseList || []

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.expenseList])

  const welfareSelectList = React.useMemo(() => {
    const list = formik.values.welfareList || []

    return list.map((e) => ({ title: e.name as string, value: e.welfareID }))
  }, [formik.values.welfareList])

  const fundSelectList = React.useMemo(() => {
    if (!formik.values.incomeOrExpenseConfig) return []

    const { otherExpenseConfigList, otherIncomeConfigList } = formik.values.incomeOrExpenseConfig

    const fundFilter = (item: OtherIncomeOrExpenseConfigItem) => item.incomeExpenseType === EIncomeExpenseType.Fund

    const list = [otherIncomeConfigList.filter(fundFilter), otherExpenseConfigList.filter(fundFilter)].flat()

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.incomeOrExpenseConfig])

  const autoSelectList = React.useMemo(() => {
    if (!formik.values.incomeOrExpenseConfig) return []

    const { otherExpenseConfigList, otherIncomeConfigList } = formik.values.incomeOrExpenseConfig

    const autoFilter = (item: OtherIncomeOrExpenseConfigItem) => item.incomeExpenseType === EIncomeExpenseType.Auto

    const list = [otherIncomeConfigList.filter(autoFilter), otherExpenseConfigList.filter(autoFilter)].flat()

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.incomeOrExpenseConfig])

  const overtimeSelectList = React.useMemo(() => {
    // Overtime UI ไม่มี?: Dropdown EOverTimeType OT Work (วันทำงานในวันหยุด), OT OT (วันทำงานล่วงเวลา)
    return [
      {
        title: 'วันทำงานในวันหยุด',
        value: EOverTimeType.Work,
      },
      {
        title: 'วันทำงานล่วงเวลา',
        value: EOverTimeType.Overtime,
      },
    ]
  }, [])

  const handleReset = () => {
    formik.setFieldValue('socialSecurityConfig', deepClone(prevConfig))
  }

  return (
    <ContentContainer>
      <FormikProvider value={formik}>
        <Form>
          <Stack sx={{ backgroundColor: 'white', p: '2rem', borderRadius: '14px' }}>
            <Stack sx={{ width: '50%', mx: 'auto' }} rowGap={2}>
              <Stack>
                <Box sx={{ fontWeight: 'bold' }}>อัตราประกันสังคมของพนักงาน(%)</Box>
                <InputNumberComponent _name="socialSecurityConfig.employeePercent" />
              </Stack>
              <Stack>
                <Box sx={{ fontWeight: 'bold' }}>อัตราประกันสังคมของนายจ้าง(%)</Box>
                <InputNumberComponent _name="socialSecurityConfig.employerPercent" />
              </Stack>
              <Stack>
                <Stack direction="row" columnGap={6}>
                  <Box sx={{ fontWeight: 'bold' }}>เงื่อนไขที่นำมาคำนวณ</Box>
                </Stack>

                <InputCheckboxComponentToggle _name="socialSecurityConfig.isBaseSalary" _label="ฐานเงินเดือน" />
              </Stack>
              <AutocompleteComponent _name="socialSecurityConfig.income" _option={incomeSelectList} _label="รายรับ" />
              <AutocompleteComponent _name="socialSecurityConfig.expense" _option={expenseSelectList} _label="รายหัก" />
              <AutocompleteComponent
                _name="socialSecurityConfig.welfare"
                _option={welfareSelectList}
                _label="สวัสดิการ"
              />
              <AutocompleteComponent _name="socialSecurityConfig.fund" _option={fundSelectList} _label="กองทุน" />
              <AutocompleteComponent _name="socialSecurityConfig.auto" _option={autoSelectList} _label="Auto" />
              <AutocompleteComponent
                _name="socialSecurityConfig.overtime"
                _option={overtimeSelectList}
                _label="การทำงานล่วงเวลา"
              />
              <Stack direction="row" columnGap="1rem">
                <ButtonComponent
                  _colorBG="white"
                  _colorText="red"
                  _text="ยกเลิก"
                  _type="button"
                  _variant="contained"
                  _sx={{ width: 'fit-content' }}
                  _functionOnClick={handleReset}
                />
                <ButtonComponent
                  _colorBG="blue"
                  _colorText="white"
                  _text="บันทึก"
                  _type="submit"
                  _variant="contained"
                  _sx={{ width: 'fit-content' }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </ContentContainer>
  )
}

export default SocialSecurityPolicyPage
