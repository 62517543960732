//lib
import React from 'react'

//Include Project
import styles from './index.module.scss'

interface Props {
  section: string
}

const SumCard: React.FC<Props> = ({ section }: Props): JSX.Element => {
  return (
    <div className={styles.navtop}>
      <h1 className={styles.title}>{section}</h1>
    </div>
  )
}

export default SumCard
