import { gql, useQuery } from '@apollo/client'
import { queryGetTimeAttendanceConfig } from 'src/adapter/xhr/query'

function useTimeAttendanceConfig() {
  const { data, refetch } = useQuery(gql(queryGetTimeAttendanceConfig), { fetchPolicy: 'no-cache' })

  return { data: data?.getTimeAttendanceConfig, refetch }
}

export default useTimeAttendanceConfig
