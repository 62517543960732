import React, { useEffect, useState } from 'react'
import { ButtonComponent, ButtonSelect, PaginationBar } from '../../component'
import styles from './index.module.scss'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { useNavigate } from 'react-router-dom'
import Grid from '../../image/btGrid.svg'
import List from '../../image/btList.svg'
import Profile from '../../image/profile.svg'
import search from '../../image/search.svg'
import CollapsibleTable from './table'
import usePaginationQuery from 'src/hooks/usePaginationQuery'
import { Employee } from 'src/utils/generated'
import { LinearProgress } from '@mui/material'
import { activeEmployeeFilter, GET_ACTIVE_EMPLOYEE } from 'src/adapter/xhr/query'

const MenuEmployeeMain: React.FC = (): JSX.Element => {
  // 2 Different ways of viewing the page: LIST/GRID
  const [pageView, setPageView] = useState<PageState>(PageState.LIST)

  const [searchbar, setSearchbar] = useState(false)
  // const navigate = useNavigate()

  const [employeeName, setEmployeeName] = useState('')

  // Pagination State
  const {
    currentTableData,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    windowSize,
    maxHasNextToken,
    currentMaxPageIndex,
    loading,
  } = usePaginationQuery<Employee>({
    query: GET_ACTIVE_EMPLOYEE,
    convertRefetch: (s) => s.listEmployee,
    additionalVariables: activeEmployeeFilter(),
  })

  return (
    <div className={styles.container}>
      <div className={styles.navtop}>
        <h1 className={styles.title}>พนักงาน</h1>
        <div className={styles.head}>
          <div className={styles.iconBar}>
            {!searchbar && (
              <div className={styles.ellipse}>
                <img src={search} alt="" className={styles.search} onClick={() => setSearchbar(!searchbar)} />
              </div>
            )}

            {searchbar && (
              <div className={styles.searchbar}>
                <TextField
                  label={<p>{'Search Employee'}</p>}
                  id="outlined-start-adornment"
                  sx={{ m: 1, width: '25ch', borderRadius: '10px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={search} alt="" onClick={() => setSearchbar(!searchbar)} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e: any) => setEmployeeName(e.target.value)}
                />
              </div>
            )}

            <img src={List} alt="" className={styles.icon} onClick={() => setPageView(PageState.LIST)} />
            <img src={Grid} alt="" className={styles.icon} onClick={() => setPageView(PageState.GRID)} />
          </div>
        </div>
      </div>

      {loading && <LinearProgress />}

      {/* PageView: LIST State */}
      {pageView === PageState.LIST && (
        <ListView employeeName={employeeName} data={currentTableData ? currentTableData : []} />
      )}
      {pageView === PageState.GRID && (
        <GridView employeeName={employeeName} data={currentTableData ? currentTableData : []} />
      )}

      {/* Currently pagination is out of table.. is there a way to move it in? */}
      <PaginationBar
        // Convert from PageIndex to PageNumber
        currentTotalPageCount={currentMaxPageIndex + 1}
        // currentTotalPageCount={6}
        currentPage={currentPage}
        hasNextToken={maxHasNextToken}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        windowSize={windowSize}
      />
    </div>
  )
}

export default MenuEmployeeMain

const ListView: React.FC<{ employeeName: string; data: Employee[] }> = ({ employeeName, data }) => {
  const exportData = [{ menu: 'pdf' }, { menu: 'csv' }]
  const navigate = useNavigate()
  return (
    <div className={styles.cardTable}>
      <div className={styles.head}>
        <h1 className={styles.title}>รายชื่อพนักงาน</h1>
        <div className={styles.buttonBox}>
          <div className={styles.btImport}>
            <ButtonComponent
              _variant="contained"
              _text="Import"
              _colorText="white"
              _colorBG="secondary"
              _type="button"
              _functionOnClick={() => navigate('ot/create')}
            />
          </div>

          <div className={styles.btExport}>
            <ButtonSelect
              _text="Export"
              _colorBG="blue"
              _colorText="white"
              _variant="outlined"
              _selectData={exportData}
            />
          </div>

          <div className={styles.btAdd}>
            <ButtonComponent
              _isIcon="add"
              _variant="contained"
              _text="เพิ่มพนักงาน"
              _colorText="white"
              _colorBG="blue"
              _type="button"
              _functionOnClick={() => navigate('../employee/add')}
            />
          </div>
        </div>
      </div>
      <div className={styles.Areatable}>
        <div className={styles.table}>
          <CollapsibleTable employeeList={data} employeename={employeeName} />
        </div>

        {/* This was once pagination, remove? */}
        <div className={styles.bottomtable}></div>
      </div>
    </div>
  )
}

const GridView: React.FC<{ employeeName: string; data: Employee[] }> = ({ employeeName, data }) => {
  const exportData = [{ menu: 'pdf' }, { menu: 'csv' }]
  const navigate = useNavigate()
  return (
    <div className={styles.cardView}>
      <div className={styles.head}>
        <div className={styles.btImport}>
          <ButtonComponent
            _variant="contained"
            _text="Import"
            _colorText="white"
            _colorBG="secondary"
            _type="button"
            _functionOnClick={() => navigate('ot/create')}
          />
        </div>
        <div className={styles.btExport}>
          <ButtonSelect
            _text="Export"
            _colorBG="secondary"
            _colorText="white"
            _variant="outlined"
            _selectData={exportData}
          />
        </div>
        <div className={styles.btAdd}>
          <ButtonComponent
            _isIcon="add"
            _variant="contained"
            _text="เพิ่มพนักงาน"
            _colorText="white"
            _colorBG="blue"
            _type="button"
            _functionOnClick={() => navigate('../employee/employee/add')}
          />
        </div>
      </div>
      <div className={styles.content}>
        {data
          .filter((item) => {
            if (employeeName === '') {
              return item
            } else if (
              item.fullName?.toLocaleLowerCase().includes(employeeName.toLocaleLowerCase()) ||
              item.lastName?.toLocaleLowerCase().includes(employeeName.toLocaleLowerCase())
              // item.id?.toLocaleLowerCase().includes(employeeName.toLocaleLowerCase())
              // item.id === employeeName
            ) {
              return item
            }
          })
          .map((employee, empIndex: number) => (
            <div className={styles.card} key={empIndex} onClick={() => navigate(`edit/${employee.id}`)}>
              <div className={styles.profile}>
                {employee.profileImage == null ? (
                  <img src={Profile} alt="profile" />
                ) : (
                  <img
                    src={employee.profileImage}
                    alt="profile"
                    style={{ borderRadius: '50%', width: '60px', height: '60px', verticalAlign: 'middle' }}
                  />
                )}
              </div>
              <p className={styles.nametext}>
                {employee.fullName} {employee.lastName}
              </p>
              <p className={styles.postext}>{employee.department?.departmentName}</p>
            </div>
          ))}
      </div>
    </div>
  )
}

enum PageState {
  LIST,
  GRID,
}
