import React from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'

//Include Project
import styles from './index.module.scss'
import { handleDecimalsOnValue } from 'src/utils/common'
import { InputCurrencyFormat } from 'src/component/InputCurrency'

interface Props {
  totalIncomePerYear: any
}

const EmployeeTaxIncome: React.FC<Props> = (props: Props): JSX.Element => {
  const { errors } = useFormikContext<any>()
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <p className={styles.title}>รายได้</p>
        <div className={styles.line} />
        <div className={styles.flexBoxTitle}>
          <p className={styles.text}>เงินได้ทั้งหมด :</p>
          <div className={styles.wrapperInput}>
            <InputCurrencyFormat name="employeeTax.salary" helperText={`${_.get(errors, 'employeeTax.salary')}`} />
            {/* <InputNumberUnitComponent _name="employeeTax.salary" _value={values.salary} _unit="บาท/เดือน" /> */}
          </div>
        </div>
        <div className={styles.flexBoxTitle}>
          <p className={styles.text}>โบนัส :</p>
          <div className={styles.wrapperInput}>
            <InputCurrencyFormat name="employeeTax.bonus" />
            {/* <InputNumberUnitComponent _name="employeeTax.salary" _value={values.salary} _unit="บาท/เดือน" /> */}
          </div>
        </div>

        <div className={styles.flexBoxTitle}>
          <p className={styles.text}>รายได้อื่น ๆ ทั้งปี : </p>
          <div className={styles.wrapperInput}>
            <InputCurrencyFormat name="employeeTax.otherIncomePerYear" />
            {/* 
                  <InputNumberUnitComponent
                    _name="employeeTax.otherIncomePerYear"
                    _value={values.employeeTax.otherIncomePeYear}
                    _unit="บาท/ปี"
                  /> */}
          </div>
        </div>
        <div className={styles.bottomGroup}>
          <p className={styles.text}>รวมรายได้ทั้งหมดต่อปี : </p>
          <div className={styles.wrapperText}>
            <p className={styles.textResult}>{handleDecimalsOnValue(props.totalIncomePerYear)}</p>
            <p className={styles.text}>บาท /ปี</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeTaxIncome
