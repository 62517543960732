//lib
import React, { useEffect, useState } from 'react'
import { Modal, Backdrop, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { gql, useMutation } from '@apollo/client'
import Swal from 'sweetalert2'
import { Form, Formik, useFormikContext } from 'formik'
import * as yup from 'yup'

//Includes Project
import { dateFmtShort, isAfterShift, isBeforeShift, toTime } from '../../utils/common'
import LeaveSelect from 'src/component/LeaveSelect'
import { useOvertimeDocumnet } from 'src/hooks'
import Switch from '../../component/Switch'
import { EDocumentStatus, Employee, EOverTimeType } from 'src/utils/generated'
import {
  InputDateComponentCustome,
  InputSelectComponentCustom,
  InputTimeComponentCustom,
} from '../../component/InputsFormik'
import { ButtonComponent, ButtonSelectCustom, UploadFileComponent } from '../../component'
import { SaveType } from './EType'
import styles from './index.module.scss'

interface Props {
  open: boolean
  setOpen: any
  date: string
  employeeID: Employee
  onSentOvertime: any
  overtimeData?: any
}

export const ModalOverTimePopper: React.FC<Props> = (props: Props): JSX.Element => {
  const [status, setStatus] = React.useState('APPROVE')
  const { values, setFieldValue, handleSubmit } = useFormikContext<any>()
  const { createOvertime } = useOvertimeDocumnet()

  useEffect(() => {
    setFieldValue('documentOvertime.startedAt', toTime(props.overtimeData?.startedAt))
    setFieldValue('documentOvertime.endedAt', toTime(props.overtimeData?.endedAt))
    setFieldValue('documentOvertime.overtimeType', props.overtimeData?.overtimeType)
  }, [props.overtimeData])

  const handleUpdate = () => {
    const packData = {
      input: {
        date: props.date,
        employeeID: props.employeeID,
        startedAt: values.documentOvertime.startedAt,
        endedAt: values.documentOvertime.endedAt,
        status: status,
        overtimeType: values.documentOvertime.overtimeType,
      },
    }
    Swal.fire({
      title: `บันทึกเอกสารโอที`,
      html: `วันที่ ${props.date} <br> เวลา ${values.documentOvertime.startedAt} - ${values.documentOvertime.endedAt}`,
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#3080f8',
      confirmButtonText: 'ตกลง',
      reverseButtons: true,
      customClass: {
        container: 'swal',
      },
    }).then((create) => {
      if (create.isConfirmed) {
        createOvertime({ variables: packData }).then((res) => {
          console.log(res, 'res')
          props.onSentOvertime?.call(null, res, status)
        })
        props.setOpen(false)
      }
    })
  }

  const statusType = (e: any) => {
    setStatus(e)
  }

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalLeaveEdaite}>
        <style lang="scss">{`
          .swal {
          z-index: 4000;
                }
          `}</style>
        <p>สร้างเอกสารโอที</p>
        <div className={styles.card}>
          <Form className={styles.wrapperForm}>
            <div className={styles.header}>
              <div className={styles.bt}>
                <ButtonComponent
                  _type={'button'}
                  _colorBG={'white'}
                  _colorText={'red'}
                  _variant={'text'}
                  _text={'ยกเลิก'}
                  _sx={{ fontSize: '16px' }}
                  _functionOnClick={() => {
                    props.setOpen(false)
                  }}
                />
              </div>
              <p className={styles.textHead}>สร้างเอกสารโอที</p>
              <div className={styles.btSelect}>
                <ButtonSelectCustom
                  _selectData={[
                    {
                      menu: 'Save',
                      value: EDocumentStatus.Pending,
                    },
                    { menu: 'Save and Approve', value: EDocumentStatus.Approve },
                  ]}
                  _onchange={(e: any) => {
                    statusType(e)
                  }}
                  _functionOnclick={() => {
                    handleSubmit()
                    handleUpdate()
                  }}
                />
              </div>
            </div>
            <div className={styles.FormGroup}>
              <div className={styles.filedInputFlex}>
                <p>ประเภทโอที</p>
                <div className={styles.inputBox}>
                  <InputSelectComponentCustom
                    _name="overtimeType"
                    _label={'ประเภทโอที'}
                    _menuList={[
                      { label: 'วันทำงาน', value: EOverTimeType.Work },
                      { label: 'ทำงานล่วงเวลา', value: EOverTimeType.Overtime },
                    ]}
                    _disabled
                    _value={values.documentOvertime.overtimeType}
                  />
                </div>
              </div>

              <div className={styles.filedInputTime}>
                <p>ช่วงเวลา</p>

                <section className={styles.GroupBox}>
                  <div className={styles.InputTime}>
                    <InputTimeComponentCustom
                      _name="startedAt"
                      _label={'เริ่มต้น'}
                      _value={values.documentOvertime.startedAt}
                      _disabled
                    />
                  </div>
                  <div className={styles.InputTime}>
                    <InputTimeComponentCustom
                      _name="endedAt"
                      _label={'สิ้นสุด'}
                      _value={values.documentOvertime.endedAt}
                      _disabled
                    />
                  </div>
                </section>
              </div>
              {/* <p className={styles.sumHour}>
                    รวมเวลา {getHour(date, props.values.startedAt, props.values.endedAt)} ชั่วโมง
                  </p> */}

              <div className={styles.filedInputFlex}>
                <p>วันที่</p>
                <div className={styles.inutBox}>
                  <InputDateComponentCustome _name={'date'} _value={props.date} _disabled={true} _label={'วันที่'} />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  )
}

interface IModal {
  employeelist: Employee
  attendance?: any
  open: boolean
  setOpen: any
  values: any
  dataList?: any
  setFieldValue?: any
}
export const ModalLeavePopper: React.FC<IModal> = (props: IModal): JSX.Element => {
  const [mutationLeave, { loading, error }] = useMutation(mutationCreateLeaveDocument)

  const [type, setType] = React.useState('leave')
  const [status, setStatus] = React.useState('APPROVE')

  const statusType = (e: any) => {
    setStatus(e)
  }
  const LeavFormList = [
    {
      label: 'กำหนดเอง',
      value: 'CUSTOM_TIME',
    },
    {
      label: 'ลาเต็มวัน',
      value: 'FULLDAY_LEAVE',
    },
    {
      label: 'ลาครึ่งวันเช้า',
      value: 'HALFDAY_MORNING_LEAVE',
    },
    {
      label: 'ลาครึ่งวันบ่าย',
      value: 'HALFDAY_AFTERNOON_LEAVE',
    },
  ]

  // const date = props.date
  const validationSchema = yup.object({
    leaveType: yup.string().required('เลือกประเภทการลา'),
    startedAt: yup
      .string()
      .required('กรอกเวลาเริ่มต้น')
      .test(
        'start_time_test',
        `ไม่อยู่ในช่วงเวลา Shift (${props.attendance.shiftStartTime} - ${props.attendance.shiftEndTime}) `,
        function (value) {
          return isBeforeShift(value, props.attendance.shiftStartTime)
        },
      ),
    endedAt: yup
      .string()
      .required('กรอกเวลาสิ้นสุด')
      .test(
        'start_time_test',
        `ไม่อยู่ในช่วงเวลา Shift (${props.attendance.shiftStartTime} - ${props.attendance.shiftEndTime}) `,
        function (value) {
          return isAfterShift(value, props.attendance.shiftEndTime)
        },
      ),
  })

  const otherProps = props

  const [leaveForm, setLeaveForm] = useState('')
  const [value, setValues] = useState<any>('')
  const SetIntervalsLeave = (leaveForm: any, formik: any) => {
    if (leaveForm === 'FULLDAY_LEAVE') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', props.attendance.date)
      formik.setFieldValue('endedAt', props.attendance.date)
    } else if (leaveForm == 'HALFDAY_MORNING_LEAVE') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', props.attendance.shiftStartTime)
      formik.setFieldValue('endedAt', props.attendance.breakTimeList[0].startTime)
    } else if (leaveForm == 'HALFDAY_AFTERNOON_LEAVE') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', props.attendance.breakTimeList[0].endTime)
      formik.setFieldValue('endedAt', props.attendance.shiftEndTime)
    } else if (leaveForm == 'CUSTOM_TIME') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', '')
      formik.setFieldValue('endedAt', '')
    }
    return leaveForm
  }
  useEffect(() => {
    SetIntervalsLeave(leaveForm, value)
  }, [leaveForm, value])

  const UnpackDate = (type: any, value: any) => {
    if (type === 'FULLDAY_LEAVE') {
      return dateFmtShort(value)
    }
    return value
  }

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: error.name,
        html: `มีเอกสารการลาช่วงเวลานี้อยู่ในระบบแล้ว </br> กรุณาเลือกช่วงเวลาอื่น </br> ${error.message} `,
        customClass: {
          container: 'swal',
        },
      })
    }
  }, [error])

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalLeaveEdaite}>
        <style lang="scss">{`
        .swal {
          z-index: 30000;
        }
      `}</style>
        <div className={styles.card}>
          <Formik
            initialValues={{
              leaveType: '',
              startedAt: '',
              endedAt: '',
              leaveDocumentAttachment: '',
              isLeaveDeductMoney: false,
              status: '',
              leaveForm: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // const startedAtCheck = (type: any, startedAt: any, topic: string) => {
              //   if (type === 'FULLDAY_LEAVE' && topic === 'start') {
              //     return new Date(`${startedAt}:${props.attendance.shiftStartTime}`).toISOString()
              //   } else if (type === 'FULLDAY_LEAVE' && topic === 'end') {
              //     return new Date(`${startedAt}:${props.attendance.shiftEndTime}+0700`).toISOString()
              //   } else {
              //     return startedAt
              //   }
              // }
              const DocumentLeaveVariables = {
                input: {
                  date: props.attendance.date,
                  employeeID: props.employeelist.id,
                  status: status,
                  leaveType: values.leaveType,
                  startedAt: values.startedAt,
                  endedAt: values.endedAt,
                  isLeaveDeductMoney: values.isLeaveDeductMoney,
                  leaveForm: leaveForm,
                  leaveDocumentAttachment: values.leaveDocumentAttachment,
                },
              }
              Swal.fire({
                title: `บันทึกเอกสารการลา`,
                html: `${props.employeelist.fullName} ${props.employeelist.lastName} </br> วันที่ ${props.attendance.date}`,
                showCancelButton: true,
                cancelButtonText: 'ยกเลิก',
                confirmButtonColor: '#3080f8',
                confirmButtonText: 'ตกลง',
                reverseButtons: true,
                customClass: {
                  container: 'swal',
                },
              }).then((create) => {
                if (create.isConfirmed) {
                  mutationLeave({ variables: DocumentLeaveVariables }).then((res) => {
                    console.log(res.data, 'res')
                    props.dataList.push({
                      leaveType: values.leaveType,
                      startedAt: UnpackDate(values.leaveForm, values.startedAt),
                      endedAt: UnpackDate(values.leaveForm, values.endedAt),
                      leaveDocumentAttachment: values.leaveDocumentAttachment,
                      status: status,
                      leaveForm: leaveForm,
                      type: type,
                      leaveRequestID: res.data.createLeaveRequest.id,
                      leaveConfig: res.data.createLeaveRequest.leaveConfig,
                    })
                    props.setFieldValue(`leaveList`, props.dataList)
                    Swal.fire({
                      title: `บันทึกเอกสารเรียบร้อยแล้ว`,
                      icon: 'success',
                      timer: 1500,
                      showConfirmButton: false,
                      customClass: {
                        container: 'swal',
                      },
                    })
                    props.setOpen(false)
                  })
                }
              })
            }}
          >
            {(formikProps: any) => (
              <form className={styles.wrapperForm} onSubmit={formikProps.handleSubmit}>
                <div className={styles.header}>
                  <div className={styles.bt}>
                    <ButtonComponent
                      _type={'button'}
                      _colorBG={'white'}
                      _colorText={'red'}
                      _variant={'text'}
                      _text={'ยกเลิก'}
                      _sx={{ fontSize: '16px' }}
                      _functionOnClick={() => otherProps.setOpen(false)}
                    />
                  </div>
                  <p className={styles.textHead}>สร้างใหม่</p>
                  <div className={styles.btSelect}>
                    <ButtonSelectCustom
                      _text={'Save'}
                      _selectData={SaveType}
                      _onchange={(e: any) => {
                        statusType(e)
                      }}
                    />
                  </div>
                </div>
                <div className={styles.FormGroup}>
                  <div className={styles.filedInputFlex}>
                    <p>ประเภทการลา</p>
                    <div className={styles.inputBox}>
                      <LeaveSelect name={'leaveType'} employee={props.employeelist} />
                    </div>
                  </div>
                  <div>
                    <div className={styles.toggle}>
                      <Switch
                        options={[
                          { label: 'ลาไม่หักเงิน', value: 'leave' },
                          { label: 'ลาหักเงิน', value: 'leaveDeduct' },
                        ]}
                        onChange={(value: any) => {
                          setType(value)
                          if (value === 'leaveDeduct') {
                            formikProps.setFieldValue('isLeaveDeductMoney', true)
                          } else if (value === 'leave') {
                            formikProps.setFieldValue('isLeaveDeductMoney', false)
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.filedInputFlex}>
                    <p>รูปแบบการลา</p>
                    <div className={styles.inputBox}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          shrink
                          sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white', padding: '0px 5px' }}
                        >
                          {'รูปแบบการลา'}
                        </InputLabel>
                        <Select
                          id="leaveForm"
                          defaultValue={formikProps.values.leaveForm}
                          sx={{ borderRadius: 3, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
                          value={formikProps.values.leaveForm}
                          label={'รูปแบบการลา'}
                          onChange={(e: any) => {
                            setLeaveForm(e.target.value)
                            setValues(formikProps)
                          }}
                          name="leaveForm"
                        >
                          {LeavFormList.map((data: any, index: number) => (
                            <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
                              {data.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <>
                    {formikProps.values.leaveForm === 'FULLDAY_LEAVE' ? (
                      <div className={styles.filedInputTime}>
                        <p>วันที่</p>
                        <div className={styles.GroupBox}>
                          <div className={styles.InputTime}>
                            <InputDateComponentCustome
                              _name="startedAt"
                              _label={'วันที่เริ่มต้น'}
                              _helperText={formikProps.errors.startedAt}
                              _value={formikProps.values.startedAt}
                            />
                          </div>
                          <div className={styles.InputTime}>
                            <InputDateComponentCustome
                              _name="endedAt"
                              _label={'วันที่สิ้นสุด'}
                              _helperText={formikProps.errors.endedAt}
                              _value={formikProps.values.endedAt}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>

                  <>
                    {formikProps.values.leaveForm === 'FULLDAY_LEAVE' ? (
                      ''
                    ) : (
                      <div className={styles.filedInputTime}>
                        <p>ช่วงเวลา</p>
                        <div className={styles.GroupBox}>
                          <div className={styles.InputTime}>
                            {/* <Datepicker view='' /> */}
                            <InputTimeComponentCustom
                              _name="startedAt"
                              _label={'เริ่มต้น'}
                              _helperText={formikProps.errors.startedAt}
                              _value={formikProps.values.startedAt}
                            />
                          </div>
                          <div className={styles.InputTime}>
                            <InputTimeComponentCustom
                              _name="endedAt"
                              _label={'สิ้นสุด'}
                              _helperText={formikProps.errors.endedAt}
                              _value={formikProps.values.endedAt}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>

                  <div className={styles.filedInputFlex}>
                    <p>แนบไฟล์</p>
                    <UploadFileComponent
                      name={'leaveDocumentAttachment'}
                      flodername={'LeaveDocument'}
                      valueShow={formikProps.values.leaveDocumentAttachment}
                      id={props.employeelist.id}
                      label="แนบไฟล์เอกสารการลา (JPEG,PNG,PDF)"
                    />
                  </div>
                  <div className={styles.filedInputFlex}>
                    <p>วันที่</p>
                    <div className={styles.inputBox}>
                      <InputDateComponentCustome
                        _name={'date'}
                        _value={props.attendance.date}
                        _disabled={true}
                        _label={'วันที่'}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

const mutataionOvertimeDocument = gql`
  mutation RequestOT_create($input: CreateOvertimeInput!) {
    createOvertimeRequest(input: $input) {
      id
      date
      employeeID
      startedAt
      endedAt
      status
      overtimeType
    }
  }
`

const mutationCreateLeaveDocument = gql`
  mutation CreateLeaveRequest($input: CreateLeaveInput!) {
    createLeaveRequest(input: $input) {
      id
      date
      employeeID
      leaveType
      isLeaveDeductMoney
      leaveForm
      startedAt
      endedAt
      leaveDocumentAttachment
      status
      leaveConfig {
        id
        name
        isActive
        leaveAmountType
        leaveConfigType
        employeeAllowSexList
        employeeAllowTypeList
        isLeaveBeforehand
        leaveBeforehandDay
        isLeaveDiligent
        isLeaveCompound
        leaveCompoundType
        maxLeaveCompound
        isLookupNotWorkday
        isLeaveAfterhand
        isCountProbation
        isExceedQuota
        isLeaveOnce
      }
    }
  }
`
