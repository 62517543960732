import { combineReducers } from 'redux'
import modalReducer, { IInitialState } from './modal'
import modalComfirmReducer, { IInitialStateModalConfirm } from './modalConfirm'
// import companyIDReducer, { IInitialStateCompanyID } from './companyID'
import loginReducer, { IInitialStateLogin } from './login'
import departmentReducer, { IInitialStateDepartment } from '../slice/department'
import shiftReducer, { IInitialStateShift } from '../slice/shift'
import holidayReducer, { IInitialStateHoliday } from '../slice/holiday'
import packageReducer, { IInitialStatePackage } from '../slice/package'
import positionReducer, { IInitialStatePosition } from '../slice/position'
import employeeReducer, { IInitialStateEmployee } from '../slice/employee'
import notificationReducer, { NotificationState } from '../slice/notification'
import timeAttendanceReducer, { IInitialStateTimeAttendanceConfig } from '../slice/timeAttendanceConfig'

export interface ICombineReducers {
  modalReducer: IInitialState
  modalComfirmReducer: IInitialStateModalConfirm
  // companyIDReducer: IInitialStateCompanyID
  loginReducer: IInitialStateLogin
  departmentReducer: IInitialStateDepartment
  shiftReducer: IInitialStateShift
  holidayReducer: IInitialStateHoliday
  packageReducer: IInitialStatePackage
  positionReducer: IInitialStatePosition
  employeeReducer: IInitialStateEmployee
  notificationReducer: NotificationState
  timeAttendanceReducer: IInitialStateTimeAttendanceConfig
}

const rootReducer = combineReducers<ICombineReducers>({
  modalReducer,
  modalComfirmReducer,
  // companyIDReducer,
  loginReducer,
  departmentReducer,
  shiftReducer,
  holidayReducer,
  packageReducer,
  positionReducer,
  employeeReducer,
  notificationReducer,
  timeAttendanceReducer,
})
export default rootReducer
