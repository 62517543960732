//lib
import React from 'react'
import { useFormikContext } from 'formik'
import Swal from 'sweetalert2'

//Images
import linkSVG from '../../image/link.svg'
import unLinkSVG from '../../image/Unlink.svg'

//Include Project
import styles from './index.module.scss'

const CardLinkAttendanceReport: React.FC = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.FlexGroup}>
        {values.report.isLink === true ? (
          <div className={styles.LinkText}>
            <img
              src={linkSVG}
              alt=""
              onClick={() => {
                setFieldValue('report.isLink', false)
                Swal.fire({
                  icon: 'warning',
                  title: 'แก้ไขข้อมูลการทำงาน',
                  text: 'โดยไม่อ้างอิงเวลาสแกนเข้า - ออกงานจากระบบ',
                })
              }}
              className={styles.IconLink}
            />
            <div className={styles.grouptText}>
              <p className={styles.headerText}>ลิงค์เวลาให้สอดคล้องกัน</p>{' '}
              <p className={styles.textSub}>
                ลิงค์เวลา สามารถแก้ไขเวลาจากเครื่องสแกนนิ้วกับสร้างเอกสาร การลาได้เท่านั้น
                โดยผลรวมของข้อมูลจะสอดคล้องกัน
              </p>
            </div>
          </div>
        ) : (
          <div className={styles.LinkText}>
            <img
              src={unLinkSVG}
              alt="unLinkSVG"
              onClick={() => {
                setFieldValue('report.isLink', true)
              }}
              className={styles.IconLink}
            />
            <div className={styles.grouptText}>
              <p className={styles.headerText}>ไม่ลิงค์เวลาให้สอดคล้องกัน</p>{' '}
              <p className={styles.textSub}>ไม่ลิงค์เวลา สามารถแก้ไขเวลาได้ทั้งหมดโดยผลรวมของข้อมูล จะไม่สอดคล้องกัน</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CardLinkAttendanceReport
