const setOpenModalAlertStatus = (
  text: string,
  des: string,
  status: 'success' | 'error' | 'info' | '',
): { type: string; textModalAlertStatus: string; desModalAlertStatus: string; status: string } => {
  return {
    type: 'SET_OPEN_MODAL_ALERT_STATUS',
    textModalAlertStatus: text,
    desModalAlertStatus: des,
    status: status,
  }
}

const setCloseModalAlertStatus = (): { type: string } => {
  return {
    type: 'SET_CLOSE_MODAL_ALERT_STATUS',
  }
}

export default {
  setOpenModalAlertStatus,
  setCloseModalAlertStatus,
}
