import { CreateDiligentInput, Diligent } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationCreateDiligent } from '../xhr/mutation'
import { queryGetDiligent } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function getDiligent() {
  return gqlResponseType<Diligent>(
    post('/', {
      query: queryGetDiligent,
    }),
    'getDiligent',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------

export function createDiligent(input: CreateDiligentInput) {
  return gqlResponseType<Diligent>(
    post('/', {
      query: mutationCreateDiligent,
      variables: { input },
    }),
    'createDiligent',
  )
}
