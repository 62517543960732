import { Box, ClickAwayListener, Popper, PopperProps } from '@mui/material'
import React, { useState } from 'react'

interface Props extends Omit<PopperProps, 'open'> {
  children: React.ReactNode
  button: JSX.Element
}

function PopperContainer({ children, button, ...popperProps }: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
  }

  const Button = React.cloneElement(button, {
    onClick: handleClick,
  })

  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box height="fit-content">
        {Button}
        <Popper {...popperProps} anchorEl={anchorEl} open={open} disablePortal={false}>
          {children}
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export default PopperContainer
