import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
// Components
import { ButtonComponent, ProgessBarComponent } from '../../component'
import ListCard from './ListCard'
import ListSelectCard from './ListSelectCard'
import { queryListApprove } from '../../adapter/xhr'
import { EOverTime } from '../AttendanceReport/EType'
import DetailPage from './DetailPage'
import { ModalApproveArr } from './modalApprove'

const ApprovalSystemPage: React.FC = (): JSX.Element => {
  const [zeroData, setZeroData] = useState<any>({})
  const [detailData, setDetailData] = useState<any>({})
  const [listApprove, setListApprove] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const QueryApprove = () => {
    const filterStatus: any = {
      filter: {
        status: {
          eq: 'PENDING',
        },
      },
    }
    queryListApprove(filterStatus).then((res: any) => {
      console.log(res, 'res')
      setListApprove(res.data.data.listApprove.items)
      setZeroData(res.data.data.listApprove.items[0])
      setLoading(false)
    })
  }

  const packValue = (valueList: any) => {
    const packVal = valueList.map((element: any) => {
      const value: any = {
        ...element,
        employeeName:
          (element?.employee.fullName ? element?.employee.fullName : null) +
          ' ' +
          (element?.employee.lastName ? element?.employee.lastName : null),
      }
      return value
    })
    return packVal
  }

  useEffect(() => {
    setDetailData(zeroData)
  }, [zeroData])

  useEffect(() => {
    QueryApprove()
  }, [])

  const [btType, setBtType] = useState<string>('')
  const [isCheckAll, setIsCheckAll] = useState<any>(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [listData2, setListData] = useState<any>([])

  useEffect(() => {
    setListData(listApprove)
  }, [listApprove])

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(listData2.map((li: any) => li))
    if (isCheckAll) {
      setIsCheck([])
    }
  }
  const handleClick = (e: any, id: any) => {
    const { value, checked } = e.target
    setIsCheck([...isCheck, listData2.find((item: any) => item.id === id)])
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item.id !== id))
    }
  }

  //ListSelectCard
  const [list, setList] = useState('รายการทั้งหมด')

  //DetailCard

  return (
    <>
      {/* <ModalConfirm open={openModalConfirm} close={handleClose} data={modalData} isCall={isCall} /> */}
      <div className={styles.container}>
        <section className={styles.topicPageWrapper}>
          <p className={styles.topicPage}>การอนุมัติรายการ </p>
          {isCheck.length > 0 ? (
            <div className={styles.btnTopicWrapper}>
              <div className={styles.btnTopic}>
                <ButtonComponent
                  _type="button"
                  _colorBG="blue"
                  _colorText="white"
                  _variant="outlined"
                  _text="อนุมัติที่เลือกทั้งหมด"
                  _functionOnClick={(e: any) => {
                    setOpenModal(true)
                    setBtType(e.target.textContent)
                  }}
                />
              </div>
              <div className={styles.btnTopic}>
                <ButtonComponent
                  _type="button"
                  _colorBG="red"
                  _colorText="white"
                  _variant="outlined"
                  _text="ปฎิเสธที่เลือกทั้งหมด"
                  _functionOnClick={(e: any) => {
                    setOpenModal(true)
                    setBtType(e.target.textContent)
                  }}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
        {loading ? (
          <ProgessBarComponent />
        ) : (
          <div className={styles.contentContainer}>
            <div className={styles.listCardWrapper}>
              <ListSelectCard
                list={list}
                setList={setList}
                data={listApprove}
                _checked={isCheckAll}
                _onChange={handleSelectAll}
              />

              <div className={styles.CardArea}>
                {packValue(listApprove).map((listData: any, listDataIndex: number) => {
                  // console.log(listData.leaveConfig, 'listData')
                  return (
                    <div key={listDataIndex} className={styles.listCard}>
                      <ListCard
                        _key={listData.id}
                        topic={listData.Type}
                        subtopic={
                          listData.leaveType
                            ? `${listData.leaveConfig?.name}`
                            : listData.overtimeType
                            ? `${EOverTime[`${listData.overtimeType}`]}`
                            : ''
                        }
                        description={listData.Type}
                        departmentName={listData.employee?.department?.departmentName}
                        name={listData.employee?.fullName}
                        lastName={listData.employee?.lastName}
                        position={listData?.department}
                        date={listData.startedAt}
                        minuteHour={listData?.overtimeMinute ? (listData?.overtimeMinute / 60).toFixed(2) : ''}
                        _onChange={(e: any) => handleClick(e, listData.id)}
                        _checked={isCheck.map((item: any) => item.id).includes(listData.id)}
                        functionOnClick={() => {
                          setDetailData(listData)
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={styles.detailCard}>
              <DetailPage
                detailData={detailData}
                QueryApprove={QueryApprove}
                listApprove={listApprove}
                zeroData={zeroData}
              />
            </div>
          </div>
        )}
      </div>
      <ModalApproveArr openModal={openModal} setOpenModal={setOpenModal} selectList={isCheck} type={btType} />
    </>
  )
}
export default ApprovalSystemPage
