import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { useDispatch } from 'react-redux'
import { CardQuotaComponent, InputDateComponent, InputRadioComponent, UploadFileComponent } from '../../component'
import {
  InputCheckboxComponentCustom,
  InputDateComponentCustome,
  InputNumberComponentCustom,
  InputNumberUnitComponent,
  InputSelectComponentCustom,
  InputTextComponentCustom,
} from '../../component/InputsFormik'
import CustomizedSwitches from '../../component/AntSwitch'
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import deleteIcon from '../../image/deleteRedIcon.svg'
import questionIcon from '../../image/question.svg'
import PlusIcon from '../../image/plus.svg'
import { useNavigate } from 'react-router-dom'
import { queryListDepartment, queryListPosition, queryListShift, UpdateEmployeeProfile } from '../../adapter/xhr'
import ModalTable from './modal'
import moment from 'moment'
import { handleUploadFile } from '../../utils/common'
import ProfileBg from '../../image/profile-BG.svg'
import IconCamera from '../../image/camera.svg'
import {
  EEmployeeType,
  EEmployeeWorkStatus,
  EHolidayLink,
  ELocationType,
  ENameTitle,
  EPaymentMethod,
  EPersonalRelation,
  ESex,
  ESocialSecurity,
  ETaxCalculate,
  EWelfareQuotaUnit,
} from 'src/utils/generated'
import { TableFunde, TableIncomeExpense, TableLeaveQuota, TableTaxDeduct, TableTaxDeductII } from './table'
import Swal from 'sweetalert2'
import FilterSVG from '../../image/filterIcons.svg'
import { FilterPopperComponent } from 'src/component/FilterPopper'

export const PersonalTab: React.FC<any> = (props: any): JSX.Element => {
  const [address, setAddress] = useState(false)

  return (
    <>
      <div className={styles.AreaData}>
        <div className={styles.box}>
          <section className={styles.GroupInput}>
            <p className={styles.title}>คำนำหน้า</p>
            <InputRadioComponent _row={true} _name="nameTitle" _menuList={NameTitle} />
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>ภาษาไทย</p>
            <div className={styles.GroupFlex}>
              <InputTextComponentCustom
                _name="fullName"
                _label="ชื่อ"
                _value={props.values.values.fullName}
                _helperText={props.values.errors.fullName}
              />
              <InputTextComponentCustom _name="middleName" _label="ชื่อกลาง" _value={props.values.values.middleName} />
              <InputTextComponentCustom
                _name="lastName"
                _label="นามสกุล"
                _value={props.values.values.lastName}
                _helperText={props.values.errors.lastName}
              />
            </div>
            <div className={styles.GroupFlex}>
              <InputTextComponentCustom
                _name="personalID"
                _label="เลขบัตรประชาชน/ID พาสปอร์ต"
                _value={props.values.values.personalID}
              />
              <InputTextComponentCustom _name="nationality" _label="สัญชาติ" _value={props.values.values.nationality} />
              <InputTextComponentCustom _name="ethnicity" _label="เชื้อชาติ" _value={props.values.values.ethnicity} />
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>ภาษาอังกฤษ</p>
            <div className={styles.GroupFlex}>
              <InputTextComponentCustom
                _name="fullNameEnglish"
                _label="fullName"
                _helperText={props.values.errors.fullNameEnglish}
                _value={props.values.values.fullNameEnglish}
              />
              <InputTextComponentCustom
                _name="middleNameEnglish"
                _label="Mid name"
                _value={props.values.values.middleNameEnglish}
                // _value={props.values.values.middleNameEnglish}
              />
              <InputTextComponentCustom
                _name="lastNameEnglish"
                _label="Last name"
                _value={props.values.values.lastNameEnglish}
              />
            </div>
          </section>
          <section className={styles.GroupInput}>
            <div className={styles.GroupFlex}>
              <InputDateComponent _name="birthDate" _label="วันเกิด" _helperText={props.values.errors.birthDate} />
              <InputTextComponentCustom _name="nickName" _label="ชื่อเล่น" _value={props.values.values.nickName} />
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>ข้อมูลการติดต่อ</p>
            <div className={styles.GroupFlex}>
              <InputTextComponentCustom _name="tel" _label="เบอร์โทรศัพท์" _value={props.values.values.tel} />
              <InputTextComponentCustom
                _name="email"
                _label="email"
                _value={props.values.values.email}
                _helperText={props.values.errors.email}
              />
            </div>
            <InputTextComponentCustom _name="lineId" _label="Line ID" _value={props.values.values.lineId} />
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>เพศ</p>
            <InputRadioComponent _name="sex" _row={true} _menuList={EGenger} />
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>สถานภาพ</p>
            <InputRadioComponent _name="personalRelation" _row={true} _menuList={PersonalRelation} />
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>ที่อยู่ตามบัตรประชาชน</p>
            <div className={styles.GroupColumn}>
              <InputTextComponentCustom
                _name={`addressPersonalID.addressNumber`}
                _label="บ้านเลขที่"
                _value={props.values.values.addressPersonalID.addressNumber}
              />
              <InputTextComponentCustom
                _name={`addressPersonalID.group`}
                _label="หมู่"
                _value={props.values.values.addressPersonalID.group}
              />
              <InputTextComponentCustom
                _name={`addressPersonalID.optional`}
                _label="ข้อมูลเพิ่มเติมอื่น ๆ"
                _value={props.values.values.addressPersonalID.optional}
              />
              <InputTextComponentCustom
                _name={`addressPersonalID.province`}
                _label="จังหวัด"
                _value={props.values.values.addressPersonalID.province}
              />

              <div className={styles.GroupFlex}>
                <InputTextComponentCustom
                  _name={`addressPersonalID.district`}
                  _label="เขต/อำเภอ"
                  _value={props.values.values.addressPersonalID.district}
                />
                <InputTextComponentCustom
                  _name={`addressPersonalID.subDistrict`}
                  _label="แขวง/ตำบล"
                  _value={props.values.values.addressPersonalID.subDistrict}
                />
              </div>

              <InputNumberComponentCustom
                _name={`addressPersonalID.zipCode`}
                _label="รหัสไปรษณีย์"
                _value={props.values.values.addressPersonalID.zipCode}
              />
            </div>
          </section>

          <section className={styles.GroupInput}>
            <div className={styles.GroupHeadText}>
              <p className={styles.title}>ที่อยู่ปัจจุบัน</p>
              <section
                className={styles.textLink}
                onClick={() => {
                  setAddress(!address)
                  if (!address) {
                    console.log('SETADDRESS')
                    props.values.setFieldValue(
                      'addressCurrent.addressNumber',
                      props.values.values.addressPersonalID.addressNumber,
                    )
                    props.values.setFieldValue('addressCurrent.group', props.values.values.addressPersonalID.group)
                    props.values.setFieldValue(
                      'addressCurrent.optional',
                      props.values.values.addressPersonalID.optional,
                    )
                    props.values.setFieldValue(
                      'addressCurrent.province',
                      props.values.values.addressPersonalID.province,
                    )
                    props.values.setFieldValue(
                      'addressCurrent.subDistrict',
                      props.values.values.addressPersonalID.subDistrict,
                    )
                    props.values.setFieldValue(
                      'addressCurrent.district',
                      props.values.values.addressPersonalID.district,
                    )
                    props.values.setFieldValue('addressCurrent.zipCode', props.values.values.addressPersonalID.zipCode)
                  } else {
                    props.values.setFieldValue('addressCurrent.addressNumber', '')
                    props.values.setFieldValue('addressCurrent.group', '')
                    props.values.setFieldValue('addressCurrent.optional', '')
                    props.values.setFieldValue('addressCurrent.province', '')
                    props.values.setFieldValue('addressCurrent.subDistrict', '')
                    props.values.setFieldValue('addressCurrent.district', '')
                    props.values.setFieldValue('addressCurrent.zipCode', '')
                  }
                }}
              >
                {!address ? (
                  <p>
                    <p>ใช้ที่อยู่ตามบัตรประชาชน</p>
                  </p>
                ) : (
                  <p>ใช้ที่อยู่ปัจจุบัน</p>
                )}
              </section>
            </div>
            <div className={styles.GroupColumn}>
              <InputTextComponentCustom
                _name={`addressCurrent.addressNumber`}
                _label="บ้านเลขที่"
                _value={props.values.values.addressCurrent.addressNumber}
              />
              <InputTextComponentCustom
                _name={`addressCurrent.group`}
                _label="หมู่"
                _value={props.values.values.addressCurrent.group}
              />
              <InputTextComponentCustom
                _name={`addressCurrent.optional`}
                _label="ข้อมูลเพิ่มเติมอื่น ๆ"
                _value={props.values.values.addressCurrent.optional}
              />
              <InputTextComponentCustom
                _name={`addressCurrent.province`}
                _label="จังหวัด"
                _value={props.values.values.addressCurrent.province}
              />

              <div className={styles.GroupFlex}>
                <InputTextComponentCustom
                  _name={`addressCurrent.district`}
                  _label="เขต/อำเภอ"
                  _value={props.values.values.addressCurrent.district}
                />
                <InputTextComponentCustom
                  _name={`addressCurrent.subDistrict`}
                  _label="แขวง/ตำบล"
                  _value={props.values.values.addressCurrent.subDistrict}
                />
              </div>

              <InputNumberComponentCustom
                _name={`addressCurrent.zipCode`}
                _label="รหัสไปรษณีย์"
                _value={props.values.values.addressCurrent.zipCode}
              />
            </div>
          </section>
        </div>
        {/* )} */}
      </div>
    </>
  )
}

//Tab2

const GetRetireDate = (EmployeeType: any, birthDate: string, hireDate: string) => {
  const probation: any = 90
  const retireAge: any = 60
  const hireDateNew = new Date(hireDate)
  const birthDateNew = new Date(birthDate)
  if (EmployeeType === 'PROBATION') {
    const retireDate = moment(new Date(new Date().setDate(hireDateNew.getDate() + probation))).format('YYYY-MM-DD')
    console.log(moment(retireDate).isValid(), 'F')
    if (moment(retireDate).isValid() === false) return
    console.log('retireDate', retireDate)
    return retireDate
  } else {
    console.log(
      moment(new Date(new Date().setFullYear(birthDateNew.getFullYear() + retireAge))).format('YYYY-MM-DD'),
      'Retire Employee',
    )
    return moment(new Date(new Date().setFullYear(birthDateNew.getFullYear() + retireAge))).format('YYYY-MM-DD')
  }
}

export const Employment: React.FC<any> = (props: any): JSX.Element => {
  const [showInput, setShowInput] = useState(false)
  const [listShift, setListShift] = useState<any>([])
  const [visible, setVisible] = useState<number>(8)

  const ListShift: any = []

  useEffect(() => {
    queryListShift().then((res: any) => {
      console.log(res, 'ListShift')
      setListShift(res.data.data.listShift)
    })
  }, [])
  const Shift = listShift.items?.map((item: any) => {
    ListShift.push({ label: item.shiftName, value: item.shiftID })
  })

  const ListDepartment: any = []
  const [department, setDepartment] = useState<any>([])
  useEffect(() => {
    queryListDepartment().then((res: any) => {
      setDepartment(res.data.data.listDepartment)
      console.log(res, 'department')
    })
  }, [])

  const ListPosition: any = []
  const [positions, setPositions] = useState<any>([])
  useEffect(() => {
    queryListPosition().then((res: any) => {
      setPositions(res.data.data.listPosition)
    })
  }, [])

  const Position = positions.items?.map((item: any) => {
    ListPosition.push({ label: item.positionName, value: item.id })
  })

  const Department = department.items?.map((item: any) => {
    ListDepartment.push({ label: item.departmentName, value: item.id })
  })

  const HeaderTable = ['การลา', 'โควต้า(วัน)', 'ใช้ไป(วัน)', 'อนุมัติ(วัน)', 'คงเหลือ(วัน)']

  return (
    <>
      <div className={styles.AreaData}>
        <div className={styles.box}>
          {/* {console.log(props.values.values.getPackageGroup, 'getPackageGroup')} */}
          <section className={styles.GroupInput}>
            <p className={styles.title}>สถานะพนักงาน</p>
            <InputRadioComponent _row={true} _name="employeeWorkStatus" _menuList={employeeWorkStataus} />
          </section>
          <section className={styles.GroupInput}>
            <div className={styles.GroupFlex}>
              <InputTextComponentCustom _name="id" _label="รหัสพนักงาน" _value={props.values.values.id} />
              <InputTextComponentCustom
                _name="finger"
                _label="รหัสลายนิ้วมือ"
                _value={props.values.values.fingerPrintId}
              />
            </div>
          </section>

          <section className={styles.GroupInput}>
            <div className={styles.GroupFlex}>
              <TextField
                InputProps={{
                  style: {
                    fontFamily: 'KanitRegular',
                    color: 'gray',
                    borderRadius: 4,
                  },
                }}
                size="small"
                type="date"
                fullWidth
                value={props.values.values.hireDate}
                id="outlined-basic"
                label="วันที่เริ่มงาน"
                name="hireDate"
                onChange={(e) => {
                  props.values.setFieldValue('hireDate', e.target.value)
                  props.values.setFieldValue(
                    'retireDate',
                    GetRetireDate(
                      props.values.values.employeeWorkStatus,
                      props.values.values.birthDate,
                      e.target.value,
                    ),
                  )
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: 16, fontFamily: 'KanitRegular' },
                }}
              />

              <InputDateComponentCustome
                _name="retireDate"
                _label={
                  props.values.values.employeeWorkStatus === 'PROBATION'
                    ? 'วันที่สิ้นสุดการทดลองงาน'
                    : 'วันสิ้นสุดการทำงาน'
                }
                _value={props.values.values.retireDate}
              />
            </div>
          </section>

          <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>ประเภทพนักงาน</p>
              <InputSelectComponentCustom
                _name="employeeType"
                _label="ประเภทพนักงาน"
                _menuList={employeeType}
                _value={props.values.values.employeeType}
                _helperText={props.values.errors.employeeType}
              />
            </div>

            <div className={styles.GridInput}>
              <p>แผนก</p>
              <InputSelectComponentCustom
                _name="departmentID"
                _label="แผนก"
                _menuList={ListDepartment}
                _value={props.values.values.departmentID}
                _helperText={props.values.errors.departmentID}
              />
            </div>
          </section>

          <section className={styles.GroupSelect}>
            {/* <div className={styles.GridInput}>
              <p>ทีมงาน</p>
              <InputSelectComponentCustom _name="employeeType" _label="ประเภทพนักงาน" _menuList={mockUpSelect} />
            </div> */}

            <div className={styles.GridInput}>
              <p>ตำแหน่ง</p>
              <InputSelectComponentCustom
                _name="positionID"
                _label="ตำแหน่ง"
                _menuList={ListPosition}
                _value={props.values.values.positionID}
                _helperText={props.values.errors.positionID}
              />
            </div>
          </section>

          {/* <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>หัวหน้าพนักงาน</p>
              <InputSelectComponentCustom _name="employeeType" _label="ประเภทพนักงาน" _menuList={mockUpSelect} />
            </div>
          </section> */}

          <section className={styles.GroupInput}>
            <p className={styles.title}>ตั้งค่าการทำงาน</p>
            <div className={styles.GroupFlex}>
              <p>บันทึกเวลาเข้า - ออกงาน</p>
              <CustomizedSwitches
                _name="isTimeAttendance"
                _onClick={(e: any) => props.values.setFieldValue('isTimeAttendance', e.target.checked)}
                _checked={props.values.values.isTimeAttendance}
              />
            </div>

            <div className={styles.GroupFlex}>
              <p>กะการทำงาน</p>
              <InputRadioComponent
                _row={true}
                _name="setShiftWorking"
                _menuList={[
                  { label: 'เปิดใช้งาน', value: 'ENABLE' },
                  { label: 'ปิดใช้งาน', value: 'DISABLE' },
                ]}
              />

              {props.values.values.setShiftWorking === 'ENABLE' ? (
                <div className={styles.Box}>
                  <InputSelectComponentCustom
                    _name="shiftWorkingID"
                    _label="กะการทำงาน"
                    _menuList={ListShift}
                    _value={props.values.values.shiftWorkingID}
                  />
                </div>
              ) : (
                ''
              )}

              {showInput ? (
                <div className={styles.Box}>
                  <InputSelectComponentCustom _name="shift" _label="กะการทำงาน" />
                </div>
              ) : (
                ''
              )}
            </div>
          </section>

          <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>สถานที่ทำงาน</p>
              <InputSelectComponentCustom
                _name="workPlace"
                _label="สถานที่ทำงาน"
                _menuList={EplaceType}
                _value={props.values.values.workPlace}
              />
            </div>
          </section>

          {/* <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>แพ็คเกจ Group</p>
              <InputSelectComponentCustom _name="employeeType" _label="แพ็คเกจ Group" _menuList={mockUpSelect} />
            </div>
          </section> */}

          <section className={styles.GroupSelect}>
            <div className={styles.GridInput}>
              <p>วันหยุดประจำปี</p>
              <InputSelectComponentCustom
                _name="holidayLink"
                _label="วันหยุดประจำปี"
                _menuList={EholidayType}
                _value={props.values.values.holidayLink}
              />
            </div>
          </section>
          {props.values.values.listLeaveQuota.length ? (
            <div className={styles.AreaCard}>
              <p className={styles.title}>การลา</p>
              <div className={styles.textHead}>
                <div className={styles.wrapperText}>
                  <p className={styles.title}>
                    {props.values.values.getPackageGroup ? props.values.values.getPackageGroup.name : ''}
                  </p>
                </div>
              </div>
              <div>
                <TableLeaveQuota Header={HeaderTable} values={props.values.values.listLeaveQuota} />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

//Tab3
export const Compensation: React.FC<any> = (props: any): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [listPosition, setListPosition] = useState<any>([])
  const [visible, setVisible] = useState<number>(8)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openpopper, setOpenPopper] = useState(false)
  const handleClickPopper = (event: any) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper((previousOpen) => !previousOpen)
  }

  useEffect(() => {
    queryListPosition().then((res: any) => {
      console.log(res, 'res')
      setListPosition(res.data.data.listPosition.items)
    })
  }, [])
  return (
    <>
      <div className={styles.AreaData}>
        <div className={styles.box}>
          <div className={styles.TextGroup}>
            <p className={styles.title}>ค่าจ้าง</p>
            <p className={styles.subtext}>ประเภทพนักงาน : {EemployeeType[`${props.values.values.employeeType}`]}</p>
            <p className={styles.subtext}>ตำแหน่ง : {props.values.values.positionID}</p>

            <section className={styles.GroupGrid}>
              <div>
                <InputRadioComponent
                  _name="salaryOptional"
                  _menuList={[
                    { label: 'ค่าคงที่', value: 'STABLE' },
                    { label: 'ระบบคำนวณเงินเดือน JD', value: 'JD_CALCULATE' },
                  ]}
                />
                <p style={{ cursor: 'pointer', color: '#3080f8', textDecoration: 'underline' }}>
                  ตั้งค่าระบบคำนวณเงินเดือน JD
                </p>
              </div>
              <div className={styles.BoxSalary}>
                {props.values.values.salaryOptional === 'STABLE' ? (
                  <InputNumberUnitComponent _name="salary" _label="ค่าจ้าง" _unit="บาท" />
                ) : (
                  ''
                )}
              </div>
            </section>
          </div>

          <section className={styles.GroupInput}>
            <p className={styles.title}>ภาษี</p>
            <div className={styles.groupInput}>
              <InputSelectComponentCustom
                _label="กำหนดคำนวณภาษี"
                _name="taxCalculate"
                _menuList={TaxMenuList}
                _value={props.values.values.taxCalculate}
              />
              <InputNumberUnitComponent
                _label="จำนวนภาษีคงที่ต่อเดือน"
                _name="taxFixedPerMonth"
                _value={props.values.values.taxFixedPerMonth}
                _unit="บาท"
              />
              <InputDateComponentCustome
                _label="เดือนที่เริ่มคำนวณ"
                _name="taxStartedMonth"
                _type="month"
                _value={props.values.values.taxStartedMonth}
              />
            </div>
          </section>

          <section className={styles.GroupInput}>
            <p className={styles.title}>รายได้รายหักคงที่</p>
            <div className={styles.Table}>
              <TableIncomeExpense Header={HeadIncome} values={props.values.values.incomeExpense} />
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>รายได้รายหักอัตโนมัติ</p>
            <div className={styles.AreaCard}>
              <p className={styles.title}>1. ประกันสังคม</p>
              <div className={styles.Group}>
                <div>
                  <p>ประเภทบัตรประจำตัว</p>
                  <InputRadioComponent _row={true} _name="socialSecurity" _menuList={SocialSecurity} />
                </div>

                {props.values.values.socialSecurity === 'PERSONAL_IDCARD' ? (
                  <section className={styles.GroupSelect}>
                    <div className={styles.GridInput}>
                      <p>รหัสประจำตัว</p>
                      <InputTextComponentCustom
                        _name="personalIDNumber"
                        _label="รหัสประจำตัว"
                        _value={props.values.values.personalID}
                      />
                    </div>
                  </section>
                ) : (
                  <section className={styles.GroupSelect}>
                    <div className={styles.GridInput}>
                      <p>รหัสประจำตัว</p>
                      <InputTextComponentCustom
                        _name="personalIDNumber"
                        _label="รหัสประจำตัว"
                        _value={props.values.values.personalIDNumber}
                      />
                    </div>
                  </section>
                )}

                <section className={styles.GroupSelect}>
                  <div className={styles.GridInput}>
                    <p>โรงพยาบาล</p>
                    <InputTextComponentCustom
                      _name="hospitalService"
                      _label="โรงพยาบาล"
                      _value={props.values.values.hospitalService}
                    />
                  </div>
                </section>

                <div>
                  <p>การคำนวณประกันสังคม</p>
                  <div className={styles.CalBox}>
                    <InputRadioComponent
                      _row={true}
                      _name="socialSecurityCalculate"
                      _menuList={[
                        { label: 'คิดตามฐานเงินเดือนจริง', value: 'BASE_ON_SALARY' },
                        { label: 'ไม่คิดประกันสังคม', value: 'NOT_BASE_ON' },
                      ]}
                    />

                    <div className={styles.Formtext}>
                      <p className={styles.link}>ติดต่อทีมงาน</p>
                      <p className={styles.text}>หากต้องการเพิ่มการคิดประกันสังคม</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.AreaCard}>
              <div className={styles.flexCard}>
                <p className={styles.title}>2. เบี้ยขยัน</p>
                <CustomizedSwitches
                  _name="diligentAllowance"
                  _onClick={(e: any) => props.values.setFieldValue('diligentAllowance', e.target.checked)}
                  _checked={props.values.values.diligentAllowance}
                />
              </div>
            </div>
            <div className={styles.AreaCard}>
              <p className={styles.title}>สวัสดิการ</p>
              <div className={styles.textHead}>
                <div className={styles.wrapperText}>
                  <p className={styles.title}>
                    {/* {console.log(props.values.values.listWelfareQuota, 'list')} */}
                    {props.values.values.getPackageGroup ? props.values.values.getPackageGroup.name : ''}
                  </p>
                  {/* <img src={FilterSVG} alt="FilterSVG" onClick={(e) => handleClickPopper(e)} /> */}
                </div>
                {props.values.values.listWelfareQuota.length > visible && (
                  <p
                    className={styles.textLink}
                    onClick={() => {
                      setVisible(props.values.values.listWelfareQuota.length)
                    }}
                  >
                    ดูทั้งหมด
                  </p>
                )}
                {visible > 8 && (
                  <p
                    className={styles.textLink}
                    onClick={() => {
                      setVisible(8)
                    }}
                  >
                    น้อยลง
                  </p>
                )}
              </div>
              <div className={styles.wrapperCard}>
                {props.values.values.listWelfareQuota.slice(0, visible).map((ele: any, index: number) => (
                  <CardQuotaComponent
                    balance={ele.balance}
                    key={index}
                    topic={ele.welfareConfig.name}
                    amount={ele.begin}
                    approve={ele.approved}
                    spent={ele.used}
                    unit={ele.welfareConfig.welfareQuotaList[0].quotaUOM}
                  />
                ))}
              </div>
            </div>
          </section>
          <section className={styles.GroupInput}>
            <div className={styles.GroupHeadText}>
              <p className={styles.title}>กองทุน</p>
              <p className={styles.textLink} onClick={() => setOpen(true)}>
                ขยาย
              </p>
            </div>

            <div>
              <TableFunde Header={Headfund} values={props.values.values.employeeFund} />
            </div>
          </section>
          <section className={styles.GroupInput}>
            <p className={styles.title}>ลดหย่อนภาษี</p>
            <TableTaxDeduct Header={Headvat} values={props.values.values.taxDeductOne} />
          </section>
          <InputCheckboxComponentCustom
            _name="employeeHandicappedAndAgeOut"
            _value={props.values.employeeHandicappedAndAgeOut}
            _label="พนักงานมีอายุมากกว่า 65 ขึ้นไป / ผู้พิการ"
          />
          <TableTaxDeductII Header={Headdeduct} values={props.values.values.taxDeductTwo} />
        </div>
      </div>
      <ModalTable open={open} setOpen={setOpen} values={props.values} />
      <FilterPopperComponent open={openpopper} anchorEI={anchorEl} typFilter={typeFilter} />
    </>
  )
}

const typeFilter: any = [
  {
    name: 'ค้นหาตามประเภท',
    submenu: [
      {
        label: 'บาท/วัน',
        value: EWelfareQuotaUnit.BahtPerMonth,
      },
      {
        label: 'บาท/เดือน',
        value: EWelfareQuotaUnit.BahtPerMonth,
      },
      {
        label: 'บาท/ปี',
        value: EWelfareQuotaUnit.BahtPerYear,
      },
    ],
  },
]

//Tab4

export const Payment: React.FC<any> = (props: any): JSX.Element => {
  return (
    <>
      <div className={styles.AreaData}>
        <div className={styles.box}>
          <section className={styles.GroupInput}>
            <p className={styles.title}>ช่องทางการชำระเงิน</p>
            <div className={styles.GroupFlex}>
              <InputRadioComponent _row={true} _name="paymentMethod" _menuList={PaymentList} />
              <div className={styles.Formtext}>
                <p className={styles.link}>ติดต่อทีมงาน</p>
                <p className={styles.text}>หากต้องการเพิ่มช่องทางชำระเงิน</p>
              </div>
            </div>
          </section>

          {props.values.values.paymentMethod === 'BANK' ? (
            <div>
              <section className={styles.GroupInput}>
                <p className={styles.title}>จ่ายเงินผ่านธนาคาร</p>
                <div className={styles.GridInput}>
                  <p>ธนาคาร</p>
                  <InputSelectComponentCustom
                    _name="bankPayment"
                    _label="ธนาคาร"
                    _menuList={EBankProviderThailand}
                    _value={props.values.values.bankPayment}
                  />
                </div>
              </section>
              <section className={styles.GroupInput}>
                <p className={styles.title}>บัญชีพนักงาน</p>
                <div className={styles.GridInput}>
                  <p>ธนาคาร</p>
                  <InputSelectComponentCustom
                    _name="bankAccountName"
                    _label="ธนาคาร"
                    _menuList={EBankProviderThailand}
                    _value={props.values.values.bankAccountName}
                  />
                </div>
                <div className={styles.GridInput}>
                  <p>เลขที่บัญชี</p>
                  <InputTextComponentCustom
                    _name="bankAccountId"
                    _label="เลขที่บัญชี"
                    _value={props.values.values.bankAccountId}
                  />
                </div>
              </section>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

//Tab5
export const Settings: React.FC<any> = (props: any): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const onDelete = (values: any) => {
    return Swal.fire({
      title: 'ลบบัญชีพนักงาน',
      html: `${values.fullName} ${values.lastName} <br /> ${values.id}`,
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ลบบัญชี',
      confirmButtonColor: '#d32f2f',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        UpdateEmployeeProfile({ input: { id: values.id, status: 'INACTIVE' } }).then((res: any) => {
          console.log('Del Employee', res)
        })
        navigate(-1)
      }
    })
  }
  return (
    <>
      <div className={styles.AreaData}>
        <section className={styles.CenterBox}>
          <label className={styles.container}>
            <div className={styles.imageContainer}>
              {props.values.values.profileImage === '' ? (
                <img src={ProfileBg} alt="" />
              ) : (
                <img src={props.values.values.profileImage} alt="profileBG" />
              )}
            </div>
            <div className={styles.dotCamera}>
              <img src={IconCamera} alt="camera" />
            </div>
            <input
              type="file"
              id="profileImage"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => {
                setLoading(true)
                Promise.all(handleUploadFile(e.target.files, `Profile/${props.values.values.id}/images/`)).then(
                  (profileImage: any) => {
                    props.values.setFieldValue('profileImage', profileImage[0])
                    setLoading(false)
                  },
                )
              }}
            />
          </label>
          <div className={styles.InputField}>
            <InputTextComponentCustom
              _name="username"
              _label="Username"
              _value={props.values.values.username}
              _helperText={props.values.errors.username}
            />
          </div>
          <div className={styles.InputField}>
            <InputTextComponentCustom
              _name="password"
              _label="Password"
              _value={props.values.values.password}
              _helperText={props.values.errors.password}
            />
            <p className={styles.txetLink} onClick={() => navigate('../forgotpassword')}>
              เปลี่ยนรหัสผ่าน
            </p>
          </div>

          {props.mode === 'update' ? (
            <div className={styles.bottom}>
              <p className={styles.tetxLink}>ลืมรหัสผ่านใช่ไหม ?</p>
              <div
                className={styles.GroupText}
                onClick={() => {
                  onDelete(props.values.values)
                }}
              >
                <img src={deleteIcon} alt="" />
                <p>ลบ Account พนักงาน</p>
                <Tooltip
                  arrow
                  title={
                    <p>
                      ลบข้อมูลของพนักงานคนนี้ทั้งหมดในฐานข้อมูล จะไม่สามารถค้นหา หรือตั้งค่าพนักงานคนนี้ได้อีก
                      การดำเนินการนี้จะเริ่มต้นเมื่อกดปุ่มยืนยัน และการดำเนินการนี้จะไม่กระทบกับพนักงานคนอื่น
                    </p>
                  }
                >
                  <img src={questionIcon} alt="" />
                </Tooltip>
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
      </div>
    </>
  )
}

//Tab6
export const Background: React.FC<any> = (props: any): JSX.Element => {
  const [editFamily, setEditFamily] = useState(false)
  const [editEducate, setEducate] = useState(false)
  const [inputTalent, setinputTalent] = useState(false)
  const [editWork, setEditeWork] = useState(false)
  const [talent, setTalent] = useState<any>([
    'พิมพ์เร็ว',
    'พูดภาษาอังกฤษได้คล่อง',
    'Microsoft office',
    'คิดบวก',
    'มีความเป็นผู้นำ',
    'มีความอดทน',
    'เข้าสังคม',
  ])

  const handleClick = (e: any, index: any) => {
    const ItemFind = props.listTalent.find((item: any) => item === e)
    if (ItemFind === e) {
      props.setListTalent(props.listTalent.filter((item: any) => item !== e))
    } else {
      props.setListTalent([...props.listTalent, talent.find((item: any) => item === e)])
    }
    console.log(props.listTalent, 'listTalent')
  }

  const OnkeyDown = (e: any) => {
    if (e.key === 'Enter') {
      console.log('ENTER')
      setTalent([...talent, e.target.value])
      console.log(talent, 'Talent')
    }
  }
  return (
    <>
      <div className={styles.AreaData}>
        <section className={styles.GroupInput}>
          <p className={styles.title}>ประวัติครอบครัว</p>
          <div className={styles.TableCard}>
            {!editFamily ? (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEditFamily(!editFamily)
                }}
              >
                แก้ไข
              </p>
            ) : (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEditFamily(!editFamily)
                }}
              >
                เสร็จสิ้น
              </p>
            )}
            <table className={styles.tableRounded}>
              <tr>
                {HeaderFamily.map((item: any, index: number) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
              {props.values.values.historyFamily.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <InputTextComponentCustom _name={`historyFamily[${index}].fullName`} _value={item.fullName} />
                  </td>
                  <td>
                    <InputTextComponentCustom _name={`historyFamily[${index}].age`} _value={item.age} />
                  </td>
                  <td>
                    <InputTextComponentCustom _name={`historyFamily[${index}].job`} _value={item.job} />
                  </td>
                  <td>
                    <InputTextComponentCustom _name={`historyFamily[${index}].relation`} _value={item.relation} />
                  </td>
                  <td>
                    {editFamily ? (
                      <img
                        src={deleteIcon}
                        alt=""
                        className={styles.Icon}
                        onClick={() => {
                          props.values.values.historyFamily.splice(index, 1)
                          console.log(index)
                          props.values.setFieldValue('historyFamily', props.values.values.historyFamily)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              ))}
            </table>

            <p
              className={styles.LinkText}
              onClick={() => {
                props.values.values.historyFamily.push({
                  fullName: '',
                  age: '',
                  job: '',
                  relation: '',
                })
                props.values.setFieldValue('historyFamily', props.values.values.historyFamily)
              }}
            >
              เพิ่มแถวตาราง
            </p>
          </div>
        </section>

        <section className={styles.GroupInput}>
          <p className={styles.title}>ประวัติการศึกษา</p>
          <div className={styles.TableCard}>
            {!editEducate ? (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEducate(!editEducate)
                }}
              >
                แก้ไข
              </p>
            ) : (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEducate(!editEducate)
                }}
              >
                เสร็จสิ้น
              </p>
            )}
            <table className={styles.tableRounded}>
              <tr>
                {HeadEducation.map((item: any, index: number) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
              {props.values.values.historyEducation.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <InputTextComponentCustom
                      _name={`historyEducation[${index}].educationLevel`}
                      _value={item.educationLevel}
                    />
                  </td>
                  <td>
                    <InputTextComponentCustom
                      _name={`historyEducation[${index}].educationName`}
                      _value={item.educationName}
                    />
                  </td>
                  <td>
                    <InputTextComponentCustom _name={`historyEducation[${index}].majorName`} _value={item.majorName} />
                  </td>
                  <td>
                    <InputTextComponentCustom
                      _name={`historyEducation[${index}].startedYear`}
                      _value={item.startedYear}
                    />
                  </td>
                  <td>
                    <InputTextComponentCustom _name={`historyEducation[${index}].endedYear`} _value={item.endedYear} />
                  </td>
                  <td>
                    {editEducate ? (
                      <img
                        src={deleteIcon}
                        alt=""
                        className={styles.Icon}
                        onClick={() => {
                          props.values.values.historyEducation.splice(index, 1)
                          props.values.setFieldValue('historyEducation', props.values.values.historyEducation)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              ))}
            </table>

            <p
              className={styles.LinkText}
              onClick={() => {
                props.values.values.historyEducation.push({
                  educationLevel: '',
                  educationName: '',
                  majorName: '',
                  startedYear: '',
                  endedYear: '',
                })
                props.values.setFieldValue('historyEducation', props.values.values.historyEducation)
              }}
            >
              เพิ่มแถวตาราง
            </p>
          </div>
        </section>

        <section className={styles.GroupInput}>
          <p className={styles.title}>ประวัติการทำงาน</p>
          <div className={styles.TableCard}>
            {!editWork ? (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEditeWork(!editWork)
                }}
              >
                แก้ไข
              </p>
            ) : (
              <p
                className={styles.LinkText}
                onClick={() => {
                  setEditeWork(!editWork)
                }}
              >
                เสร็จสิ้น
              </p>
            )}

            <table className={styles.tableRounded}>
              <tr>
                {HeadExperian.map((item: any, index: number) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
              {props.values.values.historyWork.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <InputTextComponentCustom _name={`historyWork[${index}].workName`} _value={item.workName} />
                  </td>
                  <td>
                    <InputDateComponent _name={`historyWork[${index}].startedAt`} _label={'วันเริ่มงาน'} />
                  </td>
                  <td>
                    <InputDateComponent _name={`historyWork[${index}].endedAt`} _label={'วันสิ้นสุดการทำงาน'} />
                  </td>
                  <td>
                    <InputTextComponentCustom _name={`historyWork[${index}].positionName`} _value={item.positionName} />
                  </td>
                  <td>
                    <InputTextComponentCustom _name={`historyWork[${index}].descPosition`} _value={item.descPosition} />
                  </td>
                  <td>
                    <InputTextComponentCustom _name={`historyWork[${index}].salary`} _value={item.salary} />
                  </td>
                  <td>
                    <InputTextComponentCustom _name={`historyWork[${index}].leaveReason`} _value={item.leaveReason} />
                  </td>
                  <td>
                    {editWork ? (
                      <img
                        src={deleteIcon}
                        alt=""
                        className={styles.Icon}
                        onClick={() => {
                          props.values.values.historyWork.splice(index, 1)
                          console.log(index)
                          props.values.setFieldValue('historyWork', props.values.values.historyWork)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              ))}
            </table>
            <p
              className={styles.LinkText}
              onClick={() => {
                props.values.values.historyWork.push({
                  workName: '',
                  startedAt: '',
                  endedAt: '',
                  positionName: '',
                  descPosition: '',
                  salary: '',
                  leaveReason: '',
                })
                props.values.setFieldValue('historyWork', props.values.values.historyWork)
              }}
            >
              เพิ่มแถวตาราง
            </p>
          </div>
        </section>

        <section className={styles.GroupInput}>
          <p className={styles.title}>ความสามารถพิเศษ</p>
          <div className={styles.GroupPill}>
            {talent.map((item: any, Index: number) => (
              <div
                className={
                  props.listTalent.map((item: any) => item).includes(item) === true ? styles.pillSelect : styles.pill
                }
                key={Index}
                onClick={(e: any) => {
                  handleClick(item, Index)
                }}
              >
                {item}
              </div>
            ))}
            <div className={styles.pill}>
              อื่น ๆ
              <img src={PlusIcon} alt="" className={styles.Icon} onClick={() => setinputTalent(!inputTalent)} />
              {inputTalent ? (
                <input
                  className={styles.inputBox}
                  name="talent"
                  placeholder="กรอกความสามารถพิเศษ"
                  onKeyDown={(e: any) => OnkeyDown(e)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

//Tab7

export const Document: React.FC<any> = (props: any): JSX.Element => {
  return (
    <>
      <div className={styles.AreaData}>
        <div className={styles.box}>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์บัตรประชาชน</p>
            <UploadFileComponent
              name={'personalIdAttachment'}
              flodername={'DocumentPersonal'}
              valueShow={props.values.values.personalIdAttachment}
              id={props.values.values.id}
              label="อัปโหลดเอกสารสำเนาบัตรประชาชน (JPEG,PNG,PDF)"
            />
          </section>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์สำเนาทะเบียนบ้าน</p>
            <UploadFileComponent
              name={'personalHouseAttachment'}
              flodername={'DocumentPersonal'}
              valueShow={props.values.values.personalHouseAttachment}
              id={props.values.values.id}
              label="อัปโหลดเอกสารสำเนาทะเบียนบ้าน (JPEG,PNG,PDF)"
            />
          </section>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์สำเนาวุฒิการศึกษา</p>
            <UploadFileComponent
              name={'personalEducationalAttachment'}
              flodername={'DocumentPersonal'}
              valueShow={props.values.values.personalEducationalAttachment}
              id={props.values.values.id}
              label="อัปโหลดเอกสารสำเนาวุฒิการศึกษา (JPEG,PNG,PDF)"
            />
          </section>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์เอกสารอบรม (ถ้ามี)</p>
            <UploadFileComponent
              name={'personalTrainningAttachment'}
              flodername={'DocumentPersonal'}
              valueShow={props.values.values.personalTrainningAttachment}
              id={props.values.values.id}
              label="อัปโหลดเอกสารการอบรม (JPEG,PNG,PDF)"
            />
          </section>
          <section className={styles.GroupInput}>
            <p>แนบไฟล์เอกสารอื่น ๆ</p>
            <UploadFileComponent
              name={'personalOptionalAttachment'}
              flodername={'DocumentPersonal'}
              valueShow={props.values.values.personalOptionalAttachment}
              id={props.values.values.id}
              label="อัปโหลดเอกสารอื่น  ๆ (JPEG,PNG,PDF)"
            />
          </section>
        </div>
      </div>
    </>
  )
}

const PackageMockup = [
  {
    id: 'EX001',
    name: 'ค่าอาหาร',
    amount: 1000,
    unit: 'BATH_PER_MONTH',
    approve: 500,
    spent: 500,
    unitTest: 'BATH',
  },
  {
    id: 'EX002',
    name: 'ค่าขนม',
    amount: 1000,
    unit: 'BATH_PER_MONTH',
    approve: 200,
    spent: 300,
    unitTest: 'BATH',
  },
  {
    id: 'EX003',
    name: 'ค่าเดินทาง',
    amount: 400,
    unit: 'BATH_PER_MONTH',
    approve: 400,
    spent: 400,
    unitTest: 'BATH',
  },
  {
    id: 'EX004',
    name: 'ค่าตัดแว่น',
    amount: 2000,
    unit: 'BATH_PER_MONTH',
    approve: 1500,
    spent: 1500,
    unitTest: 'BATH',
  },
]
const PackageLeaveMockup = [
  {
    id: 'EX001',
    name: 'ลาป่วย',
    amount: 30,
    unit: 'DAY_PERYEAR',
    approve: 30,
    spent: 30,
    unitTest: 'DAY',
  },
  {
    id: 'EX002',
    name: 'ลาพักร้อน',
    amount: 6,
    unit: 'DAY_PERYEAR',
    approve: 0,
    spent: 0,
    unitTest: 'DAY',
  },
  {
    id: 'EX003',
    name: 'ลากิจ',
    amount: 30,
    unit: 'DAY_PERMONTH',
    approve: 30,
    spent: 30,
    unitTest: 'DAY',
  },
]
const Headvat = ['ปี', 'จำนวนเดือน', 'มูลค่า']
const Headdeduct = ['รายการ', 'เรทจำนวนเงินลดหย่อน', 'จำนวนเงินหักลดหย่อน']
const HeadIncome = ['ประเภท', 'รายการ', 'มูลค่า', 'หน่วย']
const Headfund = [
  'ชื่อกองทุน',
  'เลขที่กองทุน',
  'วันที่สัญญากองทุน',
  'วิธีการหักเงิน',
  'เรทกองทุน',
  'วิธีการสมทบ',
  'บริษัทสมทบ',
  'ยอดสมทบ',
  'ผู้ได้รับผลประโยชน์',
  'หมายเหตุ',
]
const NameTitle = [
  { label: 'นาย', value: ENameTitle.Mr },
  { label: 'นางสาว', value: ENameTitle.Miss },
  { label: 'นาง', value: ENameTitle.Mrs },
]

const PaymentList = [
  { label: 'ธนาคาร', value: EPaymentMethod.Bank },
  { label: 'เงินสด', value: EPaymentMethod.Cash },
]

const EGenger = [
  { label: 'ชาย', value: ESex.Male },
  { label: 'หญิง', value: ESex.Female },
]

const PersonalRelation = [
  { label: 'โสด', value: EPersonalRelation.Single },
  { label: 'สมรส', value: EPersonalRelation.Married },
  { label: 'หย่าร้าง', value: EPersonalRelation.Divorce },
]

const EIncomeType: any = {
  INCOME: 'รายได้',
  EXPENSE: 'รายหัก',
}
const EemployeeType: any = {
  FULLTIME_MONTH: 'รายเดือน',
  FULLTIME_DAY: 'รายวัน',
  PARTTIME: 'รายชั่วโมง',
  OUTSOURCE: 'จ้างภายนอก',
}

const SocialSecurity = [
  { label: 'บัตรประชาชน', value: ESocialSecurity.PersonalIdcard },
  { label: 'ใบอนุญาติ', value: ESocialSecurity.License },
  { label: 'ใบต่างด้าว', value: ESocialSecurity.ForeignIdcard },
]

//Tab 3
const employeeType = [
  {
    label: 'พนักงานรายเดือน',
    value: EEmployeeType.FulltimeMonth,
  },
  {
    label: 'พนักงานรายวัน',
    value: EEmployeeType.FulltimeDay,
  },
  {
    label: 'พนักงานรายชั่วโมง',
    value: EEmployeeType.Parttime,
  },
  {
    label: 'บุคลากรภายนอก (จ้างงานชั่วคราว)',
    value: EEmployeeType.Outsource,
  },
]

const employeeWorkStataus = [
  {
    label: 'ทดลองงาน',
    value: EEmployeeWorkStatus.Probation,
  },
  {
    label: 'บรรจุพนักงานแล้ว',
    value: EEmployeeWorkStatus.Employee,
  },
  {
    label: 'พ้นสภาพ',
    value: EEmployeeWorkStatus.Retired,
  },
]

const EplaceType = [
  {
    label: 'ออฟฟิศ',
    value: ELocationType.Office,
  },
  {
    label: 'นอกออฟฟิศ',
    value: ELocationType.Offsite,
  },
]

const EholidayType = [
  {
    label: 'ระเบียบปฏิทินแผนก',
    value: EHolidayLink.Department,
  },
  {
    label: 'ระเบียบปฏิทินกำหนดเอง',
    value: EHolidayLink.Custom,
  },
  {
    label: 'ระเบียบปฏิทินบริษัท',
    value: EHolidayLink.Default,
  },
]

const TaxMenuList = [
  {
    label: 'ไม่คิดภาษี',
    value: '01',
  },
  {
    label: 'คิดภาษี ภงด.1 คงที่ทุกเดือน',
    value: ETaxCalculate.PorgnordorOneStableMonth,
  },
  {
    label: 'คิดภาษี ภงด.3 รวมเงินเดือน',
    value: ETaxCalculate.PorgnordorThreeAllSalary,
  },
  {
    label: 'คิดภาษี ภงด.3 ไม่รวมเงินเดือน',
    value: ETaxCalculate.PorgnordorThreeNotSalary,
  },
]

//Payment
const EBankProviderThailand = [
  { label: 'ธนาคารกรุงเทพ ', value: 'KRUNGTHEP' },
  { label: 'ธนาคารกรุงไทย', value: 'KRUNGTHAI' },
  { label: 'ธนาคารกรุงศรีอยุธยา', value: 'KRUNGSRI' },
  { label: 'ธนาคารกสิกรไทย', value: 'KASIKORN' },
  { label: 'ธนาคารทหารไทยธนชาต', value: 'THANACHAT' },
  { label: 'ธนาคารไทยพาณิชย์', value: 'THAI_PANICH' },
  { label: 'ธนาคารซีไอเอ็มบี ไทย ', value: 'CIMB' },
  { label: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) ', value: 'STANDARD_CHADTER' },
  { label: 'ธนาคารยูโอบี', value: 'UOB' },
  { label: 'ธนาคารทิสโก้', value: 'TISCO' },
  { label: 'ธนาคารเกียรตินาคินภัทร', value: 'KIET_NAKIN' },
  { label: 'ธนาคารแลนด์ แอนด์ เฮ้าส์', value: 'LAND_AND_HOUSE' },
  { label: 'ธนาคารไอซีบีซี (ไทย)', value: 'ICBC' },
  { label: 'ธนาคารไทยเครดิต เพื่อรายย่อย', value: 'THAI_CREDIT' },
  { label: 'ธนาคารเมกะ สากลพาณิชย์', value: 'MEGE_PANICH' },
  { label: 'ธนาคารแห่งประเทศจีน (ไทย) ', value: 'CHINESE' },
  { label: 'ธนาคารเอเอ็นแซด (ไทย)', value: 'ANZ' },
  { label: 'ธนาคารซูมิโตโม มิตซุย ทรัสต์ (ไทย)', value: 'SUMITOMO_MITSUI_TRUST' },
]

const HeaderFamily = ['ความสัมพันธ์', 'ชื่อ', 'วันเดือนปีเกิด', 'อาชีพ', '']
const HeadEducation = ['ระดับการศึกษา', 'สถาบันการศึกษา', 'สาขาวิชา', 'ปีที่เริ่มการศึกษา', 'ปีที่จบการศึกษา', '']
const HeadExperian = [
  'สถานที่ทำงาน',
  'วันเริ่มงาน',
  'วันออกงาน',
  'ตำแหน่งงาน',
  'ลักษณะงาน',
  'ค่าจ้าง',
  'เหตุผลที่ออก',
  '',
]
