import React from 'react'
import styles from './index.module.scss'
//Image
import ProfileBg from './images/profile-BG.svg'
import IconCamera from './images/camera.svg'
import { handleUploadFile } from 'src/utils/common'
import { useFormikContext } from 'formik'
interface Props {
  valueImages: any
  Id: any
  setImages: any
}
const ProfileUpload: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()

  return (
    <label className={styles.container}>
      <div className={styles.imageContainer}>
        {!values.profileImage ? <img src={ProfileBg} alt="" /> : <img src={values.profileImage} alt="profileBG" />}
      </div>
      <div className={styles.dotCamera}>
        <img src={IconCamera} alt="camera" />
      </div>
      <input
        type="file"
        id="profileImage"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => {
          Promise.all(handleUploadFile(e.target.files, `Profile/${props.Id}/images/`)).then((profileImage: any) => {
            setFieldValue('profileImage', profileImage[0])
          })
        }}
      />
    </label>
  )
}
export default ProfileUpload
