import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './index.module.scss'
// import AttendanceReportSVG from './images/attendanceReport.svg'
// import ApprovalSVG from './images/approval.svg'
// import MasterDataSVG from './images/masterData.svg'
// import IntragationSVG from './images/intregation.svg'
// import PackageSVG from './images/package.svg'
import ArrowSVG from './images/arrow.svg'
// import DownArrowSVG from './images/downArrow.svg'
// import SubmenuSVG from './images/submenu.svg'
// import BlueCircleSVG from './blueImages/blueCircle.svg'
import { IMenuItem, menuList, menuListEmployee, menuReportList, menuReportListEmployee } from './menu'
// import HamburgurIcon from 'src/component/NavTopBar/images/hamburgur.svg'
import LogoSVG from 'src/component/NavTopBar/images/logo.svg'
import useMyLocation from 'src/hooks/useMyLocation'
import cn from 'classnames'

const NavSideBarComponent: React.FC<NavSideBarProps> = ({
  isSideNavOpen,
  setIsSideNavOpen,
}: NavSideBarProps): JSX.Element => {
  const navigate = useNavigate()
  const { adminOrEmployee, topic, subtopic } = useMyLocation()
  const [currentTopic, setCurrentTopic] = useState<string>('')
  const location = useLocation()

  // Scroll to Top of the asideTopBar
  const myRef = useRef<HTMLDivElement>(null)
  const executeScroll = () => myRef.current && myRef.current.scrollIntoView()

  // Everytime Location Changes
  // 1. Close the side Nav!
  useEffect(() => {
    setIsSideNavOpen(false)
  }, [location])

  // Everytime Nav is Open, scroll to Top asideTopBar
  useEffect(() => {
    if (isSideNavOpen) executeScroll()
  }, [isSideNavOpen])

  useEffect(() => {
    setCurrentTopic(topic || '')
  }, [topic, isSideNavOpen])

  const isLinkActive = (link: string, prefix = '/admin/hr') => {
    // can not use location form useLocation cause rerender stage that show old state value
    // const { pathname } = window.location // THIS DOESN"T WORK!!!! click doesn't rerender.

    if (!link) return false

    // const _path = pathname.replace(new RegExp(`^${prefix}`), '')
    const _link = link.replace(/^./, '')

    // return _path === _link
    return `/${topic}/${subtopic}` === _link
  }

  const renderMenuList = React.useMemo(() => {
    const _props = { isLinkActive, setCurrentTopic }

    if (currentTopic === 'masterdata') return <SettingMenuList {..._props} />
    if (currentTopic === 'report') return <ReportMenuList {..._props} />
    return <IndexMenuList {..._props} />
  }, [currentTopic, location])

  return (
    <div className={styles.wrapper}>
      {/* Top Bar Save as the NavTop! */}
      <section className={styles.asideTopBar} ref={myRef}>
        {/* Hamburger Button */}
        <button
          className={styles.sideNavToggle}
          aria-controls="side-navigation"
          aria-expanded={isSideNavOpen}
          onClick={() => setIsSideNavOpen(false)}
        >
          <span className="sr-only">Menu</span>
        </button>
        {/* <img alt="ExpandSVG" src={HamburgurIcon} width={24} height={24} onClick={() => console.log('clickk')} /> */}
        <Link to={`/${adminOrEmployee.toLowerCase()}`}>
          <img className={styles.logo} alt="LogoSVG" src={LogoSVG} width={109} height={24} />
        </Link>
      </section>

      {/* Menu list */}
      <>{renderMenuList}</>

      {/* Footer */}
      <section className={styles.asideFooter}>
        <div className={styles.GroupLink}>
          <p>เกี่ยวกับ</p>
          <p>ติดต่อเรา</p>
          <p>คุกกี้</p>
          <p>บทความ</p>
          <p>นโยบายความเป็นส่วนตัว</p>
        </div>

        <p className={styles.textsub}>© 2022 Datability.info</p>
      </section>
    </div>
  )
}

interface MenuListProps {
  isLinkActive: (link: string, prefix?: string) => boolean
  setCurrentTopic: (state: string) => void
}

const IndexMenuList: React.FC<MenuListProps> = ({ isLinkActive, setCurrentTopic }) => {
  const { adminOrEmployee } = useMyLocation()
  const menuItemList = adminOrEmployee === 'EMPLOYEE' ? menuListEmployee : adminOrEmployee === 'ADMIN' ? menuList : []
  const [menuState, setMenuState] = useState<IMenuItem[]>(menuItemList)

  const selectSubMenu = (menu: IMenuItem, menuIndex: number) => {
    const menuStateCopy = [...menuState]
    const menuItemCopy = { ...menu }
    menuItemCopy.expanded = !menuItemCopy.expanded
    menuStateCopy[menuIndex] = menuItemCopy
    // Collapse all other menus
    menuStateCopy.forEach((menu, index) => {
      if (index !== menuIndex) menu.expanded = false
    })
    setMenuState(menuStateCopy)
  }

  return (
    <section className={styles.asideMenuList}>
      {menuState.map((menu, index) => (
        <div className={styles.CardButton} key={index}>
          <div className={styles.groupNavSettingItems}>
            {/* Has Submenu */}
            {menu.subMenu?.length ? (
              <>
                {/* Menu */}
                <div className={styles.subMenu}>
                  <button
                    type="button"
                    className={cn(styles.menuButton, {
                      [styles.active]: isLinkActive(menu.link, '/admin/hr'),
                    })}
                    onClick={() => selectSubMenu(menu, index)}
                    aria-controls="subMenu-content"
                    aria-expanded={menu.expanded}
                  >
                    <div className={styles.groupText}>
                      <img src={isLinkActive(menu.link, '/admin/hr') ? menu.imgHover : menu.img} />
                      {menu.name}
                    </div>
                    <img className={styles.toggleArrow} src={ArrowSVG} />
                  </button>
                </div>
                {/* SubNavigation Content */}
                <div id="subMenu-content" className={styles.subMenuContent} data-visible={menu.expanded}>
                  {menu.subMenu.map((submenu, subMenuIndex) => (
                    <Link to={submenu.link} key={subMenuIndex}>
                      <button
                        type="button"
                        className={cn(styles.subMenuBtn, {
                          [styles.active]: isLinkActive(submenu.link, '/admin/hr'),
                        })}
                      >
                        {submenu.name}
                      </button>
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <>
                {menu.name === 'Setting' ? (
                  <button
                    type="button"
                    className={cn(styles.menuButton, {
                      [styles.active]: isLinkActive(menu.link, '/admin/hr'),
                    })}
                    onClick={() => setCurrentTopic('masterdata')}
                  >
                    <div className={styles.groupText}>
                      <img src={isLinkActive(menu.link, '/admin/hr') ? menu.imgHover : menu.img} />
                      {menu.name}
                    </div>
                  </button>
                ) : menu.name === 'Report' ? (
                  <button
                    type="button"
                    className={cn(styles.menuButton, {
                      [styles.active]: isLinkActive(menu.link, '/admin/hr'),
                    })}
                    onClick={() => setCurrentTopic('report')}
                  >
                    <div className={styles.groupText}>
                      <img src={isLinkActive(menu.link, '/admin/hr') ? menu.imgHover : menu.img} />
                      {menu.name}
                    </div>
                  </button>
                ) : (
                  <Link to={menu.link}>
                    <button
                      type="button"
                      className={cn(styles.menuButton, {
                        [styles.active]: isLinkActive(menu.link, '/admin/hr'),
                      })}
                    >
                      <div className={styles.groupText}>
                        <img src={isLinkActive(menu.link, '/admin/hr') ? menu.imgHover : menu.img} />
                        {menu.name}
                      </div>
                    </button>
                  </Link>
                )}
              </>
            )}
            {menu.addDivider && <div className={styles.divider} />}
          </div>
        </div>
      ))}
    </section>
  )
}

const SettingMenuList: React.FC<MenuListProps> = ({ isLinkActive, setCurrentTopic }) => {
  const settingMenuList = [menuSetting, menuSettingfinance, menuSettingCompany, menuSettingIntegration]
  const navigate = useNavigate()
  return (
    <div className={styles.aside}>
      <div className={styles.headCard}>
        <img src={ArrowSVG} alt="" className={styles.icon} onClick={() => setCurrentTopic('')} />
        <p>Setting</p>
      </div>
      {settingMenuList.map((menuList, index) => (
        <div className={styles.CardButton} key={index}>
          {menuList.map((menu, menuIndex) => {
            const isActive = isLinkActive(menu.link, '/admin/hr')
            return (
              <div className={styles.groupNavSettingItems} key={menuIndex}>
                <button
                  type="button"
                  className={cn(
                    styles.buttonW,
                    menuIndex === 0 && styles.buttonFirst,
                    menuIndex === menuList.length - 1 && styles.buttonLast,
                    isActive && styles.buttonActive,
                  )}
                  onClick={() => {
                    navigate(menu.link || '/404')
                  }}
                >
                  <p>{menu.name}</p>
                  <img src={ArrowSVG} alt="" />
                </button>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

const ReportMenuList: React.FC<MenuListProps> = ({ isLinkActive, setCurrentTopic }) => {
  const { adminOrEmployee } = useMyLocation()
  const menuItemList =
    adminOrEmployee === 'EMPLOYEE' ? menuReportListEmployee : adminOrEmployee === 'ADMIN' ? menuReportList : []
  const [menuState, setMenuState] = useState<IMenuItem[]>(menuItemList)

  const selectSubMenu = (menu: IMenuItem, menuIndex: number) => {
    const menuStateCopy = [...menuState]
    const menuItemCopy = { ...menu }
    menuItemCopy.expanded = !menuItemCopy.expanded
    menuStateCopy[menuIndex] = menuItemCopy
    // Collapse all other menus
    menuStateCopy.forEach((menu, index) => {
      if (index !== menuIndex) menu.expanded = false
    })
    setMenuState(menuStateCopy)
  }

  return (
    <section className={styles.asideMenu}>
      <div className={styles.headCard}>
        <img src={ArrowSVG} alt="" className={styles.icon} onClick={() => setCurrentTopic('')} />
        <p>Report</p>
      </div>
      {menuState.map((menu, index) => (
        <div className={styles.CardButton} key={index}>
          <div className={styles.groupNavSettingItems}>
            {/* Has Submenu */}
            {menu.subMenu?.length ? (
              <>
                {/* Menu */}
                <div className={styles.subMenu}>
                  <button
                    type="button"
                    className={cn(styles.menuButton, {
                      [styles.active]: isLinkActive(menu.link, '/admin/hr'),
                    })}
                    onClick={() => selectSubMenu(menu, index)}
                    aria-controls="subMenu-content"
                    aria-expanded={menu.expanded}
                  >
                    <div className={styles.groupText}>
                      <img src={isLinkActive(menu.link, '/admin/hr') ? menu.imgHover : menu.img} />
                      {menu.name}
                    </div>
                    <img className={styles.toggleArrow} src={ArrowSVG} />
                  </button>
                </div>
                {/* SubNavigation Content */}
                <div id="subMenu-content" className={styles.subMenuContent} data-visible={menu.expanded}>
                  {menu.subMenu.map((submenu, subMenuIndex) => (
                    <Link to={submenu.link} key={subMenuIndex}>
                      <button
                        type="button"
                        className={cn(styles.subMenuBtn, {
                          [styles.active]: isLinkActive(submenu.link, '/admin/hr'),
                        })}
                      >
                        {submenu.name}
                      </button>
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
            {menu.addDivider && <div className={styles.divider} />}
          </div>
        </div>
      ))}
    </section>
  )
}
export default NavSideBarComponent

interface NavSideBarProps {
  isSideNavOpen: boolean
  setIsSideNavOpen: React.Dispatch<React.SetStateAction<boolean>>
}

//ListMenuSetting
const menuSetting = [
  {
    name: 'Holiday Policy',
    link: './masterdata/holidayPolicy/',
  },
  {
    name: 'OT Policy',
    link: './masterdata/overtimepolicy',
  },
  {
    name: 'Leave Policy',
    link: './masterdata/leavepolicy',
  },
  {
    name: 'Shift Policy',
    link: './masterdata/attendance',
  },
]

const menuSettingfinance = [
  {
    name: 'Welfare Policy',
    link: './masterdata/welfarePolicy',
  },
  {
    name: 'Calculation Policy',
    link: './masterdata/calculationPolicy',
  },
  {
    name: 'Income Expense Policy',
    link: './masterdata/incomeExpensePolicy',
  },
  {
    name: 'Social Security Policy',
    link: './masterdata/socialSecurityPolicy',
  },
  {
    name: 'Diligent Policy',
    link: './masterdata/diligentPolicy',
  },
  {
    name: 'Provident Fund Policy',
    link: './masterdata/providentFundPolicy',
  },
  {
    name: 'Tax Policy',
    link: './masterdata/taxPolicy',
  },
  {
    name: 'Withdrawal Policy',
    link: './masterdata/withdrawalPolicy',
  },
]

const menuSettingCompany = [
  {
    name: 'Approval Policy',
    link: './masterdata/approvalPolicy',
  },
  {
    name: 'Retirement Policy',
    link: '',
  },
  {
    name: 'Attendance Policy',
    link: './masterdata/attendancePolicy',
  },
  // {
  //   name: 'Social Security Policy',
  //   link: '',
  // },
  {
    name: 'Company Detail',
    link: './masterdata/companyDetailPolicy',
  },
  {
    name: 'Organization Chart',
    link: '',
  },
  {
    name: 'Location',
    link: '',
  },
  {
    name: 'Role Management',
    link: '',
  },
]

const menuSettingIntegration = [
  {
    name: 'Integration',
    link: '',
  },
]
