import React from 'react'
import styles from './index.module.scss'
import { FormControl, MenuItem, Select } from '@mui/material'
import usePagination, { DOTS } from 'src/hooks/usePagination'
import cn from 'classnames'

// Pagination States:
// 1.  1 2 3 4 5          Total page count is less than the page pills we want to show.
// 2.  1 2 3 4 5 ...        has nextToken,
// 3.  1 ... 13 14 15 ...   Total page count is greater than the page pills, has next Token
// 4.  1 ... 14 15 16  17   Total page count is greater than page pills, no next Token
// https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/

const PaginationBar: React.FC<IPropsPagination> = ({
  currentTotalPageCount,
  currentPage,
  hasNextToken,
  pageSize,
  setPageSize,
  setCurrentPage,
  windowSize = 3,
}: IPropsPagination): JSX.Element => {
  const paginationRange = usePagination({
    currentTotalPageCount,
    currentPage,
    hasNextToken,
    pageSize,
    windowSize,
  })

  // For Showing
  const i = (currentPage - 1) % windowSize
  const startIndex = i * pageSize
  const endIndex = (i + 1) * pageSize

  // console.log('PAGINATIONBAR>>currentTotalPageCount', currentTotalPageCount)
  // console.log('PAGINATIONBAR>>currentPage', currentPage)
  // console.log('PAGINATIONBAR>>paginationRange', paginationRange.length)

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentTotalPageCount <= 0 || currentPage <= 0 || paginationRange.length < 2) {
    return <React.Fragment></React.Fragment>
  }
  const onNext = () => {
    setCurrentPage(currentPage + 1)
    // if (props._selectedNumber !== props._numberList[props._numberList.length - 1]) {
    //   props._setSelectedNumber(event, props._selectedNumber + 1, props._selectedNumber)
    // }
  }

  const onPrevious = () => {
    setCurrentPage(currentPage - 1)
    // if (props._selectedNumber !== props._numberList[0]) {
    //   props._setSelectedNumber(event, props._selectedNumber - 3, props._selectedNumber - 2)
    // }
  }

  return (
    <div className={styles.barContainer}>
      <span className={styles.leftSideContent}>
        <p className={styles.paginationText}>View</p>
        <FormControl fullWidth size="small">
          <Select
            sx={{ borderRadius: 3, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
            value={pageSize}
            onChange={(e) => {
              setPageSize(e.target.value as number)
              setCurrentPage(1)
            }}
          >
            {pageSizeList.map((data, index) => (
              <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
          {/* <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p> */}
        </FormControl>
        <p className={styles.paginationText}>row per page</p>
      </span>
      <span className={styles.rightSideContent}>
        <p className={styles.paginationText}>
          {/* Showing {props._dropDownValue * (props._selectedNumber - 1) + 1} to{' '}
          {props._dropDownValue * props._selectedNumber} of entries {props._dropDownValue * props._numberList.length} */}
          Showing {startIndex + 1} - {endIndex}
        </p>
        {/* Actual Pagination List */}
        <ul className={cn(styles.numberContainer)}>
          {/* Left navigation arrow */}
          <li className={cn(styles.paginationItem, { [styles.disabled]: currentPage === 1 })} onClick={onPrevious}>
            <div className={cn(styles.arrow, styles.left)} />
          </li>
          {paginationRange.map((pageNumber, pageNumberIndex) => {
            // (
            //   props._selectedNumber + 2 < props?._numberList.length
            //     ? number >= props._selectedNumber && number < props._selectedNumber + 3
            //     : number > props?._numberList.length - 3
            // ) ? (
            //   <button
            //     type="button"
            //     key={index}
            //     className={number === props._selectedNumber ? styles.paginationButtonBold : styles.paginationButton}
            //     onClick={(event: any) => {
            //       props._setSelectedNumber(event, number, index)
            //     }}
            //     disabled={number === props._selectedNumber}
            //   >
            //     {number}
            //   </button>
            // ) : null,
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) {
              return (
                <li className={cn(styles.paginationItem, styles.dots)} key={pageNumberIndex}>
                  &#8230;
                </li>
              )
            }
            // Render our Page Pills
            return (
              <li
                className={cn(styles.paginationItem, {
                  [styles.selected]: pageNumber === currentPage,
                })}
                key={pageNumberIndex}
                onClick={() => setCurrentPage(pageNumber)}
              >
                {pageNumber}
              </li>
            )
          })}
          {/*  Right Navigation arrow */}
          <li
            className={cn(styles.paginationItem, {
              [styles.disabled]: currentPage === (currentTotalPageCount && !hasNextToken),
            })}
            onClick={onNext}
          >
            <div className={cn(styles.arrow, styles.right)} />
          </li>
        </ul>
      </span>
    </div>
  )
}
export default PaginationBar

const pageSizeList: IPageSize[] = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
]

interface IPropsPagination {
  currentTotalPageCount: number
  currentPage: number
  hasNextToken: boolean
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  windowSize?: number

  // _onDropdownChange: any
  // _dropDownValue: number
  // //   _menuList: IMenu[]
  // _numberList: number[]
  // _selectedNumber: number
  // _setSelectedNumber: any
}

interface IPageSize {
  value: number
  label: string
}
