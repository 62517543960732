import { useMutation, gql } from '@apollo/client'
import { useMemo } from 'react'
import { mutationCreateOvertimeDocument } from 'src/adapter/xhr/mutation'
import useHandleResponseAPI from '../useHandleResponseAPI'

function useOvertimeDocumnet() {
  const [createOvertime, optionCreate] = useMutation(gql(mutationCreateOvertimeDocument))

  const isLoading = useMemo(() => {
    return optionCreate.loading
  }, [optionCreate.loading])

  const errMsg = useMemo(() => {
    if (optionCreate?.error) return JSON.stringify(optionCreate?.error)
    else return ''
  }, [optionCreate.error])

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg,
  })

  return {
    createOvertime,
  }
}

export default useOvertimeDocumnet
