import * as React from 'react'
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { FastField, Field, getIn, useField, useFormikContext } from 'formik'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))
interface PropsSwitch {
  _name: any
  _checked?: boolean
  _onClick?: any
  onChange?: (checked: boolean) => void
  activeValue?: any
  inactiveValue?: any
  fastField?: boolean
}
function CustomizedSwitches(props: PropsSwitch) {
  const handleChange = (_: any, checked: boolean) => {
    props.onChange && props.onChange(getValue(checked))
  }

  const getValue = (checked: boolean): any => {
    const activeValue = props.activeValue === undefined ? true : props.activeValue
    const inactiveValue = props.inactiveValue === undefined ? false : props.inactiveValue

    const checkValue: any = {
      true: activeValue,
      false: inactiveValue,
    }

    return checkValue[String(checked)]
  }

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        {/* <Typography>Off</Typography> */}
        <AntSwitch
          inputProps={{ 'aria-label': 'ant design' }}
          checked={props._checked}
          name={props._name}
          onChange={handleChange}
          onClick={props._onClick}
        />
        {/* <Typography>On</Typography> */}
      </Stack>
    </FormGroup>
  )
}

function CustomizedSwitchesFormik(props: PropsSwitch) {
  const [{ value }, , { setValue }] = useField(props._name)

  function handleClick(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    setValue(getValue(checked))
    props._onClick?.(event, getValue(checked))
  }

  const getValue = (checked: boolean): any => {
    const activeValue = props.activeValue === undefined ? true : props.activeValue
    const inactiveValue = props.inactiveValue === undefined ? false : props.inactiveValue

    const checkValue: any = {
      true: activeValue,
      false: inactiveValue,
    }

    return checkValue[String(checked)]
  }

  const isChecked = React.useMemo(() => {
    const activeValue = props.activeValue === undefined ? true : props.activeValue
    return activeValue === value
  }, [props.activeValue, value])

  const FieldWrapper = props.fastField ? FastField : Field

  return (
    <FieldWrapper name={props._name}>
      {() => (
        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            {/* <Typography>Off</Typography> */}
            <AntSwitch
              inputProps={{ 'aria-label': 'ant design' }}
              checked={isChecked}
              name={props._name}
              onChange={handleClick}
            />
            {/* <Typography>On</Typography> */}
          </Stack>
        </FormGroup>
      )}
    </FieldWrapper>
  )
}

export default Object.assign(CustomizedSwitches, {
  Formik: CustomizedSwitchesFormik,
})
