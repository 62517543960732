//lib
import React, { useEffect, useState } from 'react'
import { Modal, Backdrop } from '@mui/material'
import { Form, useFormikContext } from 'formik'
// import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import swal from 'sweetalert'

//Include Project
import styles from './index.module.scss'
import { combineList, dateFmtShort, dateRange, deepClone, remove__typename, toTime } from '../../../utils/common'
import {
  EApproveStatus,
  Employee,
  EScanReasonType,
  Leave,
  LeaveReport,
  Overtime,
  OvertimeConfig,
  Shift,
  TimeAttendance,
  TimeAttendanceReport,
} from '../../../utils/generated'
// import { ModalLeavePopper } from '../ModalPopper'
import { HolidayYearList, resetTimeAttendanceReport } from 'src/utils/resetTimeAttendanceReport'
import { getOvertimeConfig } from 'src/adapter/api'
import {
  CalendarEventContainer,
  CardLinkAttendanceReport,
  HeadModalAttendanceReport,
  HeadModalAttendanceReportView,
  LeaveAttendaceReport,
  LeaveAttendaceReportView,
  OvertimeAttendanceReport,
  OvertimeAttendanceReportView,
  ScanCardAttendanceReport,
  ScanCardAttendanceReportView,
  SumCard,
  WorkingTimeAttendanceReport,
  WorkingTimeAttendanceReportView,
} from 'src/container'
import { useTimeAttendanceReport } from 'src/hooks'
import { ICombineReducers } from 'src/state/reducers'
import { IAttendanceReport } from 'src/utils/formikInterfce'
import {
  ETranformationDataToClient,
  ETranformationDataToServer,
  transformationDataToClient,
  transformationDataToServer,
} from 'src/utils/tranformData'

interface IProps {
  employeeList: Employee
  startDate: string
  endDate: string
  open: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  Date: string
  refechData?: any
}

export const ModalAttendanceEdit: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<IAttendanceReport>()

  const { getTimeReport, updateAttendanceReport } = useTimeAttendanceReport()

  const getTimeAttendanceConfig = useSelector((state: ICombineReducers) => state.timeAttendanceReducer)

  useEffect(() => {
    if (props.open) {
      getTimeReport({
        variables: {
          date: values.report.date,
          employeeId: props.employeeList.id,
          year: `${new Date(props.Date).getFullYear()}`,
        },
      }).then((res) => {
        const data = res.data.getTimeAttendanceReport as unknown as TimeAttendanceReport
        setFieldValue('attendance', data)
        setFieldValue('report.scanIn', toTime(data.scanIn))
        setFieldValue('report.scanOut', toTime(data.scanOut))

        //--------------------- Leave List ---------------------//
        const dataLeavelist = transformationDataToClient({
          type: ETranformationDataToClient.LeaveDocumentList,
          values: { leave: data.leaveList, leaveDeduct: data.leaveDeductList },
        })
        setFieldValue('report.leaveList', dataLeavelist)
        //--------------------- Leave List ---------------------//

        //--------------------- Overtime List ---------------------//
        const dataOvertimelist = transformationDataToClient({
          type: ETranformationDataToClient.OvertimeDocumentList,
          values: { overtime: data.overtimeList, overtimeApproveList: data.overtimeApproveList },
        })
        setFieldValue('report.overtimeList', dataOvertimelist)
        //--------------------- Overtime List ---------------------//

        //--------------------- WorkTime List ---------------------//
        const dataWorkTime = transformationDataToClient({
          type: ETranformationDataToClient.WorkTimelist,
          values: data.workingTimeList,
        })
        setFieldValue('report.workingTimeList', dataWorkTime)
        //--------------------- WorkTime List ---------------------//

        if (data.breakDiffMinute) {
          setFieldValue('report.breakDiffMinute', data.breakDiffMinute)
        }
        if (data.outDiffMinute) {
          setFieldValue('report.outDiffMinute', data.outDiffMinute)
        }
        if (data.inDiffMinute) {
          setFieldValue('report.inDiffMinute', data.inDiffMinute)
        }
        if (data.isLink) {
          setFieldValue('report.isLink', data.isLink)
        }
        setFieldValue('report.workingMinute', data.workingMinute)
      })
    }
  }, [props.open])

  const [overtimeconfig, setOvertimeConfig] = useState<OvertimeConfig>()
  const fetchOvertimeConfig = async () => {
    const res = await getOvertimeConfig()
    setOvertimeConfig(res)
  }
  useEffect(() => {
    fetchOvertimeConfig()
  }, [])

  //RESET
  const onSubmitWithReset = () => {
    if (!values.attendance.employeeID && !values.attendance.employee) return

    //Overtime List//
    const newOvertimeList: any[] = []
    const newOvertimeApprove: any[] = []
    deepClone(values.report.overtimeList || []).map((overtimeObject: any) => {
      overtimeObject.startedAt = new Date(`${props.Date}:${overtimeObject.startedAt}`).toISOString()
      overtimeObject.endedAt = new Date(`${props.Date}:${overtimeObject.endedAt}`).toISOString()
      if (overtimeObject.type === 'overtime') {
        delete overtimeObject.type
        return newOvertimeList.push(overtimeObject)
      } else {
        delete overtimeObject.type
        return newOvertimeApprove.push(overtimeObject)
      }
    })

    // console.log();

    //Leave List//
    const newLeaveDeductList: any[] = []
    const newLeaveList: any[] = []
    deepClone(values.report.leaveList || []).map((leaveObject: any) => {
      if (leaveObject.leaveForm === 'FULLDAY_LEAVE') {
        if (leaveObject.leaveRequestID === null) {
        } else {
          leaveObject.startedAt = new Date(`${leaveObject.startedAt}:08:00`).toISOString()
          leaveObject.endedAt = new Date(`${leaveObject.endedAt}:17:00`).toISOString()
        }
      } else {
        leaveObject.startedAt = new Date(`${props.Date}:${leaveObject.startedAt}`).toISOString()
        leaveObject.endedAt = new Date(`${props.Date}:${leaveObject.endedAt}`).toISOString()
      }
      if (leaveObject.type === 'leave') {
        delete leaveObject.type
        return newLeaveList.push(leaveObject)
      } else {
        delete leaveObject.type
        return newLeaveDeductList.push(leaveObject)
      }
    })

    //Employee DATA//
    const employees = [
      {
        id: values.attendance.employeeID,
        hireDate: values.attendance.employee?.hireDate,
        retireDate: values.attendance.employee?.retireDate,
      },
    ] as Employee[]

    //Log
    const attendanceLogs = [
      {
        scanAt: `${props.Date}T${values.report.scanIn}+07:00`,
        scanReason: EScanReasonType.ScanIn,
        employeeID: values.attendance.employeeID,
      },
      {
        scanAt: `${props.Date}T${values.report.scanOut}+07:00`,
        scanReason: EScanReasonType.ScanOut,
        employeeID: values.attendance.employeeID,
      },
    ] as TimeAttendance[]

    const holidayList = [] as HolidayYearList[]
    const overtimeApproveList = newOvertimeApprove.map((e) => ({
      ...e,
      employeeID: values.attendance.employeeID,
      id: e.overtimeRequestID,
    })) as Overtime[]

    const leaveList = combineList(
      newLeaveDeductList
        .filter((e) => e.leaveRequestID)
        .map((e) => ({
          ...e,
          employeeID: values.attendance.employeeID,
          id: e.leaveRequestID,
          isLeaveDeductMoney: true,
        })),
      newLeaveList
        .filter((e) => e.leaveRequestID)
        .map((e) => ({
          ...e,
          employeeID: values.attendance.employeeID,
          id: e.leaveRequestID,
        })),
    ) as (Leave & LeaveReport)[]
    const overtimeConfig = overtimeconfig
    const attendanceConfig = getTimeAttendanceConfig.timeAttendanceConfig
    const startDate = new Date(props.Date)
    const endDate = new Date(props.Date)
    const [report] = resetTimeAttendanceReport({
      attendanceLogs,
      employees,
      defaultShift,
      attendanceConfig,
      startDate,
      endDate,
      leaveList,
      holidayList,
      overtimeApproveList,
      overtimeConfig,
    })

    // console.log(report, 'TEST')

    // console.log(
    //   attendanceLogs,
    //   employees,
    //   defaultShift,
    //   attendanceConfig,
    //   startDate,
    //   endDate,
    //   leaveList,
    //   holidayList,
    //   overtimeApproveList,
    //   overtimeConfig,
    //   'report',
    // )
    // console.log('THIS IS REPORT ', report)

    // console.log(report.overtimeList, 'TEST')
    // if (report.overtimeList && report.overtimeList?.length) {
    //   report.overtimeMinute = report.overtimeList.reduce((acc: any, curr: any) => acc + (curr.overtimeMinute || 0), 0)
    // }
    if (report.overtimeList && report.overtimeApproveList?.length) {
      // report.overtimeList = []
      // report.overtimeMinute = 0
      report.overtimeApproveMinute = report.overtimeApproveList.reduce(
        (acc, curr) => acc + (curr.overtimeMinute || 0),
        0,
      )
    }
    return report
  }

  const handleUpdate = () => {
    const report = onSubmitWithReset()

    const variables: any = transformationDataToServer({
      type: ETranformationDataToServer.UpdateAttendance,
      values: { report: report, attendance: values.attendance },
    })

    // query defaultShift from ListShift
    if (values.report.isLink === false) {
      const workingToMinute: number = (values.report.workingMinute || 0) * 60
      const variablesunLink: any = {
        input: {
          employeeID: props.employeeList.id,
          date: props.Date,
          scanIn: `${new Date(`${props.Date}T${values.report.scanIn}+07:00`).toISOString()}`,
          scanOut: `${new Date(`${props.Date}T${values.report.scanOut}+07:00`).toISOString()}`,
          inDiffMinute: values.report.inDiffMinute,
          breakDiffMinute: values.report.breakDiffMinute,
          outDiffMinute: values.report.outDiffMinute,
          isLink: values.report.isLink,
          workingMinute: workingToMinute,
        },
      }
      swal('', {
        title: 'บันทึกข้อมูล',
        text: `วันที่ ${props.Date}`,
        buttons: ['ยกเลิก', 'บันทึก'],
      }).then((onConfirm) => {
        if (onConfirm) {
          updateAttendanceReport({ variables: variablesunLink }).then(() => {
            props.setOpenModal(false)
            swal('', {
              icon: 'success',
              title: 'บันทึกข้อมูลสำเร็จ',
              timer: 1000,
              buttons: { cancel: false, confirm: false },
              className: `${styles.swal}`,
            })
            props.refechData()
          })
        }
      })
    } else if (values.report.isLink === true) {
      remove__typename(variables)
      const leaveList: any[] = combineList(variables.input.leaveList, variables.input.leaveDeductList)
      Swal.fire({
        title: `บันทึกข้อมูลการทำงาน`,
        html: `${props.employeeList.fullName} ${props.employeeList.lastName} <br> วันที่ ${props.Date} `,
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#3080f8',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        reverseButtons: true,
        customClass: {
          container: 'swal',
        },
      }).then((onConfirm) => {
        if (onConfirm.isConfirmed) {
          if (leaveList.find((ele: any) => ele.leaveForm === 'FULLDAY_LEAVE')) {
            const startDate = leaveList.find((ele: any) => ele.leaveForm === 'FULLDAY_LEAVE').startedAt
            const EndDate = leaveList.find((ele: any) => ele.leaveForm === 'FULLDAY_LEAVE').endedAt
            const dates = dateRange(dateFmtShort(startDate), dateFmtShort(EndDate))
            for (let i = 0; i < dates.length; i++) {
              const params: any = deepClone(variables)
              params.input.date = dates[i]
              updateAttendanceReport({ variables: params }).then(() => {
                props.refechData()
              })
            }
          } else {
            updateAttendanceReport({ variables: variables }).then(() => {
              props.refechData()
            })
          }

          props.setOpenModal(false)
          Swal.fire({
            icon: 'success',
            title: `บันทึกข้อมูลการทำงานเรียบร้อย`,
            html: `${props.employeeList.fullName} ${props.employeeList.lastName} <br> วันที่ ${props.Date} `,
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              container: 'swal',
            },
          })
        }
      })
    }
  }

  const handleClose = () => {
    props.setOpenModal(false)
    setFieldValue('attendance', '')
    setFieldValue('report', intitialReport)
  }

  return (
    <Modal
      open={props.open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalCalendarEvent}>
        <style lang="scss">{`
        .swal {
        z-index: 3000;
        }
        `}</style>
        <div className={styles.card}>
          <div className={styles.InputGroup}>
            <Form className={styles.wrapperForm}>
              <div>
                <div className={styles.Event}>
                  <div className={styles.calendarDay}>
                    <CalendarEventContainer date={props.Date} />
                  </div>
                  <div className={styles.GroupInput}>
                    <div className={styles.header}>
                      {values.attendance.status === EApproveStatus.Approve ? (
                        <HeadModalAttendanceReportView date={props.Date} handleClose={handleClose} />
                      ) : (
                        <HeadModalAttendanceReport
                          date={props.Date}
                          functionOnClick={handleUpdate}
                          handleClose={handleClose}
                        />
                      )}
                    </div>

                    <div className={styles.wrapperContainer}>
                      {values.attendance.status === EApproveStatus.Approve ? (
                        <ScanCardAttendanceReportView />
                      ) : (
                        <ScanCardAttendanceReport />
                      )}

                      {values.attendance.status === EApproveStatus.Approve ? (
                        <WorkingTimeAttendanceReportView />
                      ) : (
                        <WorkingTimeAttendanceReport />
                      )}

                      {values.attendance.status === EApproveStatus.Approve ? (
                        <OvertimeAttendanceReportView />
                      ) : (
                        <OvertimeAttendanceReport />
                      )}

                      {values.attendance.status === EApproveStatus.Approve ? (
                        <LeaveAttendaceReportView />
                      ) : (
                        <LeaveAttendaceReport />
                      )}
                    </div>

                    <div className={styles.LinkArea}>
                      <CardLinkAttendanceReport />
                      <SumCard report={onSubmitWithReset()} />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  )
}

//MockUp Shift Data ==> onSubmitwithReset
const defaultShift = {
  breakPolicy: [
    {
      dayList: [1, 2, 3, 4, 5, 6],
      timeList: [
        {
          endTime: '13:00',
          isScanEnd: true,
          isScanStart: false,
          startTime: '12:00',
        },
      ],
    },
  ],
  shiftID: '667cd6e9-432a-4202-baba-664e0a73e5ee',
  shiftName: 'Shift 1',
  Type: 'SHIFT',
  workDayPolicy: [
    {
      dayList: [1, 2, 3, 4, 5],
      timeList: [
        {
          endTime: '17:00',
          isScanEnd: true,
          isScanStart: true,
          startTime: '08:00',
        },
      ],
    },
    {
      dayList: [6],
      timeList: [
        {
          endTime: '15:00',
          isScanEnd: true,
          isScanStart: true,
          startTime: '08:00',
        },
      ],
    },
  ],
} as Shift

const intitialReport = {
  date: '',
  scanIn: '',
  scanOut: '',
  overtimeApproveList: [],
  overtimeList: [],
  leaveDeductList: [],
  leaveList: [],
  inDiffMinute: 0,
  breakDiffMinute: 0,
  outDiffMinute: 0,
  isLink: true,
  workingTimeList: [],
  remark: '',
  workingMinute: 0,
  overtimeMinute: 0,
  leaveMinute: 0,
  leaveDeductMinute: 0,
}
