import { useFormikContext } from 'formik'
import React from 'react'

//Images
import styles from './index.module.scss'
import { handleDecimalsOnValue } from 'src/utils/common'
import { InputCurrencyFormat } from 'src/component/InputCurrency'
import { InputNumberUnitComponent, InputSelectComponentCustom } from 'src/component'
import { EPersonalTaxRelation } from 'src/utils/generated'

interface Props {
  totalDiscountFamily: any
}

const EmployeeTaxExpenses: React.FC<Props> = (props: Props): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <p className={styles.title}>ค่าใช้จ่ายและค่าลดหย่อน</p>
        <div className={styles.line} />
        {/*Personal Discount*/}
        <div className={styles.flexBoxTitle}>
          <p className={styles.text}>ค่าลดหย่อนส่วนบุคคล : </p>
          <div className={styles.wrapperInput}>
            {/* <InputNumberUnitComponent
                    _name="employeeTax.personalDiscount"
                    _value={values.employeeTax.personalDiscount}
                    _unit="บาท"
                    _disabled
                  /> */}
            <InputCurrencyFormat name="employeeTax.personalDiscount" />
          </div>
        </div>
        <div className={styles.boxCard}>
          <section className={styles.flexBoxTitle}>
            <p className={styles.text}>{`หักค่าใช้จ่าย  : `}</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.deductExpense" />

              {/* <InputNumberUnitComponent
                      _name="employeeTax.deductExpense"
                      _value={values.employeeTax.deductExpense}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.deductExpense')}`}
                    /> */}
            </div>
          </section>
          <p className={styles.textSubtitle}>50% ของเงินได้ ไม่เกิน 100,000 บาท</p>
        </div>

        <div className={styles.flexBoxTitle}>
          <p className={styles.text}>สถานะภาพ : </p>
          <div className={styles.wrapperInput}>
            <InputSelectComponentCustom
              _name="employeeTax.personalRelation"
              _label="สถานภาพ"
              _menuList={PersonalRelation}
              _value={values.employeeTax.personalRelation}
            />
          </div>
        </div>
        {values.employeeTax.personalRelation === 'SPOUSE_NOT_INCOME' && (
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>ลดหย่อนคู่สมรสไม่มีรายได้ : </p>
            <div className={styles.wrapperInput}>
              <InputNumberUnitComponent
                _name="employeeTax.spouseDiscount"
                _value={values.employeeTax.spouseDiscount}
                _disabled
                _unit="บาท"
              />
            </div>
          </div>
        )}
        {values.employeeTax.personalRelation !== 'SINGLE' && (
          <>
            <div className={styles.flexBoxTitle}>
              <p className={styles.text}>ค่าลดหย่อนบุตร : </p>
              <div className={styles.wrapperInput}>
                <InputCurrencyFormat name="employeeTax.childDiscount" />
                {/* <InputNumberUnitComponent
                        _name="employeeTax.childDiscount"
                        _value={values.employeeTax.childDiscount}
                        _unit="บาท"
                      /> */}
              </div>
            </div>

            <p className={styles.textSubtitle}>
              {`ลดหย่อนคนละ 30,000 บาท (ตั้งแต่คนที่ 2 ที่เกิดตั้งแต่ปี พ.ศ.2561 คนละ 60,000 บาท)`}
            </p>
          </>
        )}
        <div className={styles.bottomGroup}>
          <p className={styles.text}>รวมค่าลดหย่อนตนเองและครอบครัว : </p>
          <div className={styles.wrapperText}>
            <p className={styles.textResult}>{handleDecimalsOnValue(props.totalDiscountFamily)}</p>
            <p className={styles.text}>บาท</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeTaxExpenses

const PersonalRelation = [
  { label: 'โสด', value: EPersonalTaxRelation.Single },
  { label: 'หย่า', value: '' },
  { label: 'คู่สมรสมมีเงินได้ (รวมยื่น)', value: EPersonalTaxRelation.SpouseHasIncomeIncludedTax },
  { label: 'คู่สมรสมีเงินได้ (แยกยื่น)', value: EPersonalTaxRelation.SpouseHasIncomeSeperateTax },
  { label: 'คู่สมรสไม่มีเงินได้', value: EPersonalTaxRelation.SpouseNotIncome },
]
