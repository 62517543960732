//lib
import React from 'react'
import { useFormikContext } from 'formik'

//Include Project
import { getThaiDate } from 'src/utils/common'
import styles from './index.module.scss'
import { IAttendanceReport } from 'src/utils/formikInterfce'

//Images
import closeSVG from '../../image/closeGray.svg'
import approveSVG from '../../image/AttendanceReport/approve.svg'

interface Props {
  date: string
  handleClose: () => void
}

const HeadModalAttendaneReportView: React.FC<Props> = ({ date, handleClose }: Props): JSX.Element => {
  const { values } = useFormikContext<IAttendanceReport>()

  return (
    <div className={styles.headDate}>
      <div className={styles.headButton}>
        <div className={styles.buttonForm}>
          <img src={closeSVG} alt="closeSVG" onClick={() => handleClose()} />
        </div>
      </div>
      <div className={styles.wrapperCard}>
        <div>
          <p className={styles.dateText}>{getThaiDate(new Date(date)).format('day date month year')}</p>
          {values.attendance?.shiftName && (
            <div className={styles.subText}>
              <p>{values.attendance.shiftName}</p>:
              <span>
                {values.attendance.shiftStartTime} - {values.attendance.shiftEndTime}
              </span>
              {values.attendance.shiftVersion && (
                <div>
                  <section className={styles.vershift}>
                    <p>{values.attendance.shiftVersion}</p>
                  </section>
                </div>
              )}
            </div>
          )}
        </div>

        <img src={approveSVG} width={60} height={60} alt="approveSVG" />
      </div>
    </div>
  )
}

export default HeadModalAttendaneReportView
