//lib
import { useQuery, gql } from '@apollo/client'
import { useFormikContext } from 'formik'
import React from 'react'

//include Project
import {
  InputDateComponentCustome,
  InputNumberUnitComponent,
  InputRadioComponent,
  InputSelectComponentCustom,
  InputTextComponentCustom,
} from 'src/component'
import { ESocialSecurityCalculate, ETaxCalculate } from 'src/utils/generated'
import styles from './index.module.scss'
import { TableFunde, TableIncomeExpense } from './table'

const EmployeeCompensationTab: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()

  const { data: getPosition } = useQuery(QUERY_POSITION, {
    variables: { getPositionId: values.formInput.positionID },
  })

  return (
    <div className={styles.container}>
      <div className={styles.header}>4. ค่าตอบแทน</div>
      <div className={styles.card}>
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.form}>
              <p className={styles.title}>ค่าจ้าง</p>
              <div>
                <p>ประเภทพนักงาน : {EemployeeType[`${values.formInput.employeeType}`]}</p>
                <p>ตำแหน่ง : {getPosition?.getPosition?.positionName}</p>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.width20}>
                <InputRadioComponent
                  _name="formInput.salaryOptional"
                  _menuList={[{ label: 'ค่าคงที่', value: 'STABLE' }]}
                />
              </div>
              <div className={styles.width80}>
                <InputNumberUnitComponent _name="formInput.salary" _value={values.formInput.salary} _unit="บาท" />
              </div>
            </div>
            {/*table Income */}
            <div className={styles.form}>
              <p className={styles.title}>รายได้รายหักคงที่</p>
              <TableIncomeExpense />
            </div>
            <div className={styles.form}>
              <p className={styles.title}>ภาษี</p>
              <div className={styles.flexBox}>
                <InputSelectComponentCustom
                  _label="กำหนดคำนวณภาษี"
                  _name="formInput.taxCalculate"
                  _menuList={TaxMenuList}
                  _value={values.formInput.taxCalculate}
                />
                <InputNumberUnitComponent
                  _label="จำนวนภาษีคงที่ต่อเดือน"
                  _name="formInput.taxFixedPerMonth"
                  _value={values.formInput.taxFixedPerMonth}
                  _unit="บาท"
                />
                <InputDateComponentCustome
                  _label="เดือนที่เริ่มคำนวณ"
                  _name="formInput.taxStartedMonth"
                  _type="month"
                  _value={values.formInput.taxStartedMonth}
                />
              </div>
            </div>

            <div className={styles.form}>
              <p className={styles.title}>รายได้รายหักอัตโนมัติ</p>
              <div className={styles.wrapperTab}>
                <p className={styles.textTab}>1.ประกันสังคม</p>
                <div className={styles.inputTab}>
                  <InputSelectComponentCustom
                    _label="การคำนวณประกันสังตม"
                    _name="formInput.socialSecurityCalculate"
                    _menuList={[
                      {
                        label: 'ไม่คิดประกันสังคม',
                        value: ESocialSecurityCalculate.NotBaseOn,
                      },
                      {
                        label: 'คิดประกันสังคม',
                        value: ESocialSecurityCalculate.BaseOnSalary,
                      },
                    ]}
                    _value={values.formInput.socialSecurityCalculate}
                  />
                  <InputTextComponentCustom
                    _name="formInput.hospitalService"
                    _label="โรงพยาบาล"
                    _value={values.formInput.hospitalService}
                  />
                </div>
              </div>
              <div className={styles.wrapperTab}>
                <p className={styles.textTab}>2.เบี้ยขยัน</p>
                <div className={styles.inputTab}>
                  <InputRadioComponent
                    _name="formInput.diligentAllowance"
                    _row
                    _menuList={[
                      { label: 'ได้รับเบี้ยขยัน', value: true },
                      { label: 'ไม่ได้รับเบี้ยขยัน', value: false },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className={styles.form}>
              <p className={styles.title}>กองทุน</p>
              <TableFunde />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default EmployeeCompensationTab

const HeaderIncome = ['ประเภท', 'รายการ', 'มูลค่า', 'หน่วย']
const shift = [
  {
    label: 'เปิดใช้งาน',
    value: true,
  },
  {
    label: 'ปิดใช้งาน',
    value: false,
  },
]

const TaxMenuList = [
  {
    label: 'ไม่คิดภาษี',
    value: '01',
  },
  {
    label: 'คิดภาษี ภงด.1 คงที่ทุกเดือน',
    value: ETaxCalculate.PorgnordorOneStableMonth,
  },
  {
    label: 'คิดภาษี ภงด.3 รวมเงินเดือน',
    value: ETaxCalculate.PorgnordorThreeAllSalary,
  },
  {
    label: 'คิดภาษี ภงด.3 ไม่รวมเงินเดือน',
    value: ETaxCalculate.PorgnordorThreeNotSalary,
  },
]
const EemployeeType: any = {
  FULLTIME_MONTH: 'รายเดือน',
  FULLTIME_DAY: 'รายวัน',
  PARTTIME: 'รายชั่วโมง',
  OUTSOURCE: 'จ้างภายนอก',
}

const QUERY_POSITION = gql`
  query Query($getPositionId: ID!) {
    getPosition(id: $getPositionId) {
      id
      positionName
    }
  }
`
