import { CreateTaxConfigInput, TaxConfig } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationCreateTaxConfig } from '../xhr/mutation'
import { queryGetTaxConfig } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function getTaxConfig() {
  return gqlResponseType<TaxConfig>(
    post('/', {
      query: queryGetTaxConfig,
    }),
    'getTaxConfig',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------

export function createTaxConfig(input: CreateTaxConfigInput) {
  return gqlResponseType<TaxConfig>(
    post('/', {
      query: mutationCreateTaxConfig,
      variables: { input },
    }),
    'createTaxConfig',
  )
}
