import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { mutationPDF } from 'src/adapter/xhr/mutation'
import { ReportFilter } from 'src/component'
import { dateFmtShort } from 'src/utils/common'
import { EDocumentType } from 'src/utils/generated'
import Swal from 'sweetalert2'
import styles from '../../pages/index.module.scss'

const AttendanceReportDocumentPage: React.FC = (): JSX.Element => {
  const [showfile, setShowfile] = useState<boolean>(false)
  const [mutationData, { data, loading, error }] = useMutation(mutationPDF)

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: error.name,
        text: error.message,
      })
    }
  }, [error])

  return (
    <div className={styles.container}>
      <section className={styles.cardFilter}>
        <div className={styles.wrapperCard}>
          <Formik
            initialValues={{
              departmentID: [],
              employeeID: [],
              documentType: 'PDF',
              startedAt: dateFmtShort(new Date()),
              checkDepartment: true,
              employeeall: true,
              endedAt: dateFmtShort(new Date()),
              employeeType: [],
            }}
            onSubmit={(values) => {
              console.log(values)
            }}
          >
            {(props: any) => (
              <form onSubmit={props.handleSubmit} className={styles.filterCard}>
                <ReportFilter
                  headerLabel="รายการข้อมูลการทำงานพนักงาน"
                  value={props}
                  documentType={EDocumentType.TimeAttendanceReportDoc}
                  mutationData={mutationData}
                  loading={loading}
                  setShowfile={setShowfile}
                  defaultData={data}
                />
              </form>
            )}
          </Formik>
        </div>
      </section>
      <div className={styles.line} />
      <section className={styles.cardReport}>
        <div className={styles.wrapperCard}>
          {showfile === true ? (
            <iframe src={data?.downloadPDFDocument} className={styles.viewFram} allowFullScreen={true} />
          ) : (
            <p className={styles.text}>หน้าแสดงข้อมูล</p>
          )}
        </div>
      </section>
    </div>
  )
}

export default AttendanceReportDocumentPage
