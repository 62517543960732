import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { ButtonComponent } from '../../component'
import search from '../../image/search.svg'
import styles from './index.module.scss'
import { TabTeam } from './tab'

const UserManageTeam: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const validationSchema = yup.object({})
  return (
    <div className={styles.container}>
      {/* <div className={styles.navtop}>
            <h1 className={styles.title}>User Management</h1>
          </div> */}

      <div className={styles.cardSearch}>
        <Formik
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2))
              actions.setSubmitting(false)
            }, 1000)
          }}
        >
          {(props: any) => (
            <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
              <div className={styles.InputArea}>
                <div className={styles.inputBox}>
                  <TextField
                    label="ค้นหาทีม"
                    id="outlined-start-adornment"
                    sx={{ m: 1, width: '35ch' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={search} alt="" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className={styles.btSearch}>
                <ButtonComponent
                  _variant="contained"
                  _text="ค้นหา"
                  _colorText="white"
                  _colorBG="blue"
                  _type="button"
                  _functionOnClick={() => navigate('../menuemployee')}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className={styles.cardTable}>
        <div className={styles.head}>
          <h1 className={styles.title}>รายชื่อทีม</h1>
          <div className={styles.buttonBox}>
            <div className={styles.btImport}>
              {' '}
              <ButtonComponent
                _variant="contained"
                _text="Import"
                _colorText="white"
                _colorBG="secondary"
                _type="button"
                _functionOnClick={() => navigate('ot/create')}
              />
            </div>

            <div className={styles.btAdd}>
              {' '}
              <ButtonComponent
                _isIcon="add"
                _variant="contained"
                _text="เพิ่ม Team"
                _colorText="white"
                _colorBG="blue"
                _type="button"
                _functionOnClick={() => navigate('../usermanage/team/addteam')}
              />
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <TabTeam />
        </div>
      </div>
    </div>
  )
}

export default UserManageTeam
