import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IInitialStateTimeAttendanceConfig {
  timeAttendanceConfig: any
  refetch: () => void
}
const initialState: IInitialStateTimeAttendanceConfig = {
  timeAttendanceConfig: '',
  refetch: () => null,
}

export const timeAttendanceConfigSlice = createSlice({
  name: 'timeAttendanceConfig',
  initialState,
  reducers: {
    setTimeAttendanceConfig: (state, action: PayloadAction<any>) => {
      state.timeAttendanceConfig = action.payload.data
      state.refetch = action.payload.refetch
    },
  },
})

export const { setTimeAttendanceConfig } = timeAttendanceConfigSlice.actions

export default timeAttendanceConfigSlice.reducer
