//lib
import React from 'react'
import styles from './index.module.scss'

//Include Project
import { TableLeaveQuota } from './table'

const EmployeeLeaveQuotaTab: React.FC = (): JSX.Element => {
  const HeaderTable = ['การลา', 'โควต้า(วัน)', 'ใช้ไป(วัน)', 'อนุมัติ(วัน)', 'คงเหลือ(วัน)']

  return (
    <div className={styles.container}>
      <div className={styles.header}>8. การลา</div>
      <div className={styles.card}>
        <section className={styles.group}>
          <TableLeaveQuota Header={HeaderTable} />
        </section>
      </div>
    </div>
  )
}

export default EmployeeLeaveQuotaTab
