import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Tooltip } from '@mui/material'
import { ButtonComponent } from '../../../component'
import TimeIcon from '../../../image/clock.svg'
import Profile from '../../../image/profile2.svg'
import { ELeave, ELeavForm, EStatus } from '../../AttendanceReport/EType'
import { toTime } from '../../../utils/common'
import FileIcon from '../../../image/fileblue.svg'
import CalendarIcon from '../../../image/calendarIcon.svg'
import { ModalApprove } from '../modalApprove'
import { QueryGetAttendance } from '../../../adapter/xhr'
import Swal from 'sweetalert2'
import { Formik } from 'formik'

export const EApprove: any = {
  APPROVE_LEAVE: 'การลา',
  APPROVE_OVERTIME: 'ทำงานล่วงเวลา',
  APPROVE_COMPENSATION: 'สวัสดิการ',
  APPROVE_PAYROLL: 'เงินเดือน',
}
export const DateLocal = (date: any) => {
  return new Date(date).toLocaleDateString('th-TH', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
  })
}

const DetailPage: React.FC<any> = (props: any): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [reportList, setReportList] = useState<any>({})
  const [buttonType, setButtonType] = useState<string>('')
  // if (Object.keys(props.detailData).length === o)

  const dataMap = () => {
    const dataVal: any = props.detailData
    if (Object.keys(props.detailData).length === 0) {
      dataVal === props.zeroData
    } else {
      dataVal === props.detailData
    }

    return dataVal
  }

  const data = props.detailData
  useEffect(() => {
    if (data?.date && data?.employeeID) {
      QueryGetAttendance({ date: data.date, employeeId: data.employeeID }).then((res: any) => {
        setReportList(res.data.data.getTimeAttendanceReport)
      })
    }
  }, [data?.date, data?.employeeID])

  const OnApprove = () => {
    return Swal.fire({
      title: `อนุมัติเอกสาร`,
      text: ``,
      html: (
        <Formik
          initialValues={{ remark: '' }}
          onSubmit={(values) => {
            console.log(values, 'Values')
          }}
        ></Formik>
      ),
    })
  }

  // use
  return (
    <>
      <div className={styles.container}>
        <section className={styles.Header}>
          <section className={styles.GroupButton}>
            <div className={styles.btApprove}>
              <ButtonComponent
                _type={'button'}
                _colorBG={'blue'}
                _colorText={'white'}
                _variant={'text'}
                _value="approve"
                _text={'อนุมัติ'}
                _sx={{ fontSize: '15px' }}
                _functionOnClick={OnApprove}
              />
            </div>
            <div className={styles.btApprove}>
              <ButtonComponent
                _type={'button'}
                _colorBG={'white'}
                _colorText={'red'}
                _variant={'text'}
                _value="cancle"
                _text={'ปฏิเสธ'}
                _sx={{ fontSize: '15px' }}
                _functionOnClick={(e: any) => {
                  setButtonType(e.target.textContent)
                  Swal.fire({
                    title: `${buttonType}`,
                  })
                  // setButtonType(e.target.textContent)
                  // setOpen(true)
                }}
              />
            </div>
          </section>
        </section>

        {data ? (
          <section className={styles.detailArea}>
            <div className={styles.profile}>
              <section className={styles.imageProfile}>
                {data?.employee.profileImage ? (
                  <img src={data?.employee.profileImage} alt="profile" className={styles.profile} />
                ) : (
                  <img src={Profile} alt="" />
                )}
              </section>
              <section className={styles.GroupText}>
                <p className={styles.textSub}>
                  {data.employee?.fullName} {data.employee?.lastName}
                </p>
                <p className={styles.text}>{data?.employee?.department?.departmentName}</p>{' '}
              </section>

              <section className={styles.status}>
                <div className={styles.pill}>
                  <p className={styles.textPending}>{EStatus[`${data.status}`]}</p>
                </div>
              </section>
            </div>

            {data.Type === 'APPROVE_LEAVE' ? (
              <div className={styles.detail}>
                {data.id ? (
                  <section className={styles.Header}>
                    รหัสเอกสาร
                    <Tooltip title={<p>{data.id}</p>} placement="top" arrow>
                      <p className={styles.textTitle}> {data.id}</p>
                    </Tooltip>
                  </section>
                ) : (
                  ''
                )}
                <section className={styles.GroupForm}>
                  <p className={styles.title}>สร้าง</p>
                  <p>{EApprove[`${data.Type}`]}</p>
                </section>
                <section className={styles.GroupForm}>
                  <p className={styles.title}>ประเภทการลา</p>
                  <p>{ELeave[`${data.leaveType}`]}</p>
                </section>
                <section className={styles.GroupForm}>
                  <p className={styles.title}>รูปแบบการลา</p>
                  <p>{ELeavForm[`${data.leaveForm}`]}</p>
                </section>
                {data.isLeaveDeductMoney === false ? (
                  <p className={styles.leaveText}>ลาไม่หักเงิน *</p>
                ) : (
                  <p className={styles.leaveText}>ลาหักเงิน *</p>
                )}
                <section className={styles.GroupForm}>
                  <p className={styles.title}>ช่วงเวลา</p>
                  <section className={styles.GroupInput}>
                    <div className={styles.InputLeft}>
                      <p> {toTime(data.startedAt)} น.</p>
                    </div>
                    <div className={styles.InputRigth}>
                      <p>{toTime(data.endedAt)} น.</p>
                      <img src={TimeIcon} alt="" />
                    </div>
                  </section>
                </section>
                <p className={styles.textHour}>รวม {(data.leaveMinute / 60).toFixed(2)} ชั่วโมง</p>

                <section className={styles.GroupForm}>
                  <p className={styles.title}>ช่วงวันที่</p>
                  <div className={styles.dateArea}>
                    <p>{DateLocal(data.date)}</p>
                    <img src={CalendarIcon} alt="" />
                  </div>
                </section>

                {data.leaveDocumentAttachment ? (
                  <section className={styles.GroupForm}>
                    <p className={styles.title}>เอกสารแนบ</p>
                    <div className={styles.fileArea}>
                      <p>{data.leaveDocumentAttachment}</p>
                      <img src={FileIcon} alt="" />
                    </div>
                  </section>
                ) : (
                  ''
                )}
              </div>
            ) : data.Type === 'APPROVE_OVERTIME' ? (
              <div className={styles.detail}>
                {data.id ? (
                  <section className={styles.Header}>
                    รหัสเอกสาร
                    <Tooltip title={<p>{data.id}</p>} placement="top" arrow>
                      <p className={styles.textTitle}> {data.id}</p>
                    </Tooltip>
                  </section>
                ) : (
                  ''
                )}

                <section className={styles.GroupForm}>
                  <p className={styles.title}>สร้าง</p>
                  <p>{EApprove[`${data.Type}`]}</p>
                </section>

                <section className={styles.GroupForm}>
                  <p className={styles.title}>ช่วงเวลา</p>
                  <section className={styles.GroupInput}>
                    <div className={styles.InputLeft}>
                      <p> {toTime(data.startedAt)} น.</p>
                    </div>
                    <div className={styles.InputRigth}>
                      <p>{toTime(data.endedAt)} น.</p>
                      <img src={TimeIcon} alt="" />
                    </div>
                  </section>
                </section>
                <p className={styles.textHour}>รวม {(data.overtimeMinute / 60).toFixed(2)} ชั่วโมง</p>

                <section className={styles.GroupForm}>
                  <p className={styles.title}>ช่วงวันที่</p>
                  <div className={styles.dateArea}>
                    <p>{DateLocal(data.date)}</p>
                    <img src={CalendarIcon} alt="" />
                  </div>
                </section>
                <div className={styles.line}></div>
                <section className={styles.bottomContent}>
                  {reportList?.overtimeList?.length ? <p className={styles.Texthead}>ข้อมูลวันทำงานจริง</p> : ''}

                  {reportList?.overtimeList?.map((itemReport: any, reportIndex: number) => (
                    <div key={reportIndex} className={styles.dataMap}>
                      <section className={styles.GroupForm}>
                        <div className={styles.dateArea}>
                          <img src={CalendarIcon} alt="" />
                          <p>{DateLocal(reportList.date)}</p>
                        </div>
                      </section>
                      <section className={styles.GroupForm}>
                        <p className={styles.title}>ช่วงเวลา</p>
                        <section className={styles.GroupInput}>
                          <div className={styles.InputLeft}>
                            <p> {toTime(itemReport.startedAt)} น.</p>
                          </div>
                          <div className={styles.InputRigth}>
                            <p>{toTime(itemReport.endedAt)} น.</p>
                            <img src={TimeIcon} alt="" />
                          </div>
                        </section>
                      </section>
                      <p className={styles.textHour}>รวม {(reportList.overtimeMinute / 60).toFixed(2)} ชั่วโมง</p>
                    </div>
                  ))}
                </section>
              </div>
            ) : (
              ''
            )}
          </section>
        ) : (
          ''
        )}
        <ModalApprove
          open={open}
          setOpen={setOpen}
          detailData={props.detailData}
          type={buttonType}
          QueryApprove={props.QueryApprove}
        />
      </div>
    </>
  )
}

export default DetailPage
