import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import {
  CreateEmployeeProfile,
  GetEmployeeProfile,
  ListOtherIncomeOrExpense,
  UpdateEmployeeProfile,
} from '../../adapter/xhr'
import { ButtonComponent, ProgessBarComponent } from '../../component'
import styles from './index.module.scss'
import { Background, Compensation, Document, Employment, Payment, PersonalTab, Settings } from './InputFiled'
import backWardIcon from '../../image/backward.svg'
import Swal from 'sweetalert2'
import swal from 'sweetalert'
import { gql, useLazyQuery } from '@apollo/client'
import { queryotherIncome } from 'src/adapter/xhr/query'
import { combineList } from 'src/utils/common'
const MenuEmployeeEdit: React.FC = (): JSX.Element => {
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [listTalent, setListTalent] = useState<any>([])
  const [tab, setTab] = useState<string>('personal')
  const formikRef: any = useRef()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>()

  const ConversDate = (month: any) => {
    const year = new Date().getFullYear()
    return `${year}-0${month}`
  }

  const [loadIncomeExpense, { data: dataIncome }] = useLazyQuery(gql(queryotherIncome))
  const FilterIncom = {
    filter: {
      variableOrFixed: {
        eq: 'FIXED',
      },
      status: {
        eq: 'ACTIVE',
      },
    },
  }

  useEffect(() => {
    if (id) {
      const FiledData = async () => {
        const response: any = await GetEmployeeProfile({
          getEmployeeId: id,
          year: '2022',
          listWelfareQuotaYear2: '2022',
        }).then((res: any) => {
          setData(res.data.data.getEmployee)
          const data = res.data.data.getEmployee
          console.log(data.getPackageGroup, 'datadata')
          const setFieldRef = formikRef.current.setFieldValue
          //Tab1
          if (data.nameTitle) {
            setFieldRef('nameTitle', data.nameTitle)
          }
          setFieldRef('fullName', data.fullName)
          setFieldRef('middleName', data.middleName)
          setFieldRef('lastName', data.lastName)
          setFieldRef('personalID', data.personalID)
          setFieldRef('ethnicity', data.ethnicity)
          setFieldRef('nationality', data.nationality)
          setFieldRef('fullNameEnglish', data.fullNameEnglish)
          setFieldRef('middleNameEnglish', data.middleNameEnglish)
          setFieldRef('lastNameEnglish', res.data.data.getEmployee.lastNameEnglish)
          setFieldRef('sex', data.sex)
          if (data.personalRelation) {
            setFieldRef('personalRelation', data.personalRelation)
          }
          setFieldRef('birthDate', data?.birthDate)
          setFieldRef('nickName', data?.nickName)
          setFieldRef('tel', data?.tel)
          setFieldRef('email', data?.email)
          setFieldRef('lineId', data?.lineId)
          setFieldRef(`addressPersonalID.addressNumber`, data.addressPersonalID?.addressNumber)
          setFieldRef(`addressPersonalID.group`, data.addressPersonalID?.group)
          setFieldRef(`addressPersonalID.optional`, data.addressPersonalID?.optional)
          setFieldRef(`addressPersonalID.province`, data.addressPersonalID?.province)
          setFieldRef(`addressPersonalID.subDistrict`, data.addressPersonalID?.subDistrict)
          setFieldRef(`addressPersonalID.district`, data.addressPersonalID?.district)
          setFieldRef(`addressPersonalID.zipCode`, data.addressPersonalID?.zipCode)
          setFieldRef(`addressCurrent.addressNumber`, data.addressCurrent?.addressNumber)
          setFieldRef(`addressCurrent.group`, data.addressCurrent?.group)
          setFieldRef(`addressCurrent.optional`, data.addressCurrent?.optional)
          setFieldRef(`addressCurrent.province`, data.addressCurrent?.province)
          setFieldRef(`addressCurrent.subDistrict`, data.addressCurrent?.subDistrict)
          setFieldRef(`addressCurrent.district`, data.addressCurrent?.district)
          setFieldRef(`addressCurrent.zipCode`, data.addressCurrent?.zipCode)

          //TAX
          setFieldRef(`taxCalculate`, data.taxCalculate)
          setFieldRef(`taxFixedPerMonth`, data.taxFixedPerMonth)
          setFieldRef(`taxStartedMonth`, ConversDate(data.taxStartedMonth))

          //Tab2
          setFieldRef(`id`, data?.id)
          setFieldRef(`fingerPrintId`, data?.fingerPrintId)
          setFieldRef(`hireDate`, data?.hireDate)
          setFieldRef(`retireDate`, data?.retireDate)
          setFieldRef(`departmentID`, data?.departmentID)
          setFieldRef(`positionID`, data?.positionID)
          setFieldRef('listLeaveQuota', data?.listLeaveQuota.items)
          setFieldRef('getPackageGroup', data?.getPackageGroup)

          if (data.employeeWorkStatus) {
            setFieldRef(`employeeWorkStatus`, data.employeeWorkStatus)
          }
          if (data.employeeType) {
            setFieldRef(`employeeType`, data.employeeType)
          }
          setFieldRef(`employeeWorkTypeShift`, data?.employeeWorkTypeShift)

          if (data.setShiftWorking) {
            setFieldRef(`setShiftWorking`, data.setShiftWorking)
          }
          setFieldRef(`shiftWorkingID`, data?.shiftWorkingID)

          if (data.workPlace) {
            setFieldRef(`workPlace`, data.workPlace)
          }

          if (data.holidayLink) {
            setFieldRef(`holidayLink`, data.holidayLink)
          }
          setFieldRef('isTimeAttendance', data.isTimeAttendance)

          //Tab3
          if (data.salaryOptional) {
            setFieldRef(`salaryOptional`, data.salaryOptional)
          }
          if (data.socialSecurityCalculate) {
            setFieldRef(`socialSecurityCalculate`, data.socialSecurityCalculate)
          }
          if (data.socialSecurity) {
            setFieldRef(`socialSecurity`, data.socialSecurity)
          }
          setFieldRef(`hospitalService`, data.hospitalService)
          if (data.personalIDNumber) {
            setFieldRef(`personalIDNumber`, data.personalIDNumber)
          }
          setFieldRef('listWelfareQuota', data.listWelfareQuota.items)
          setFieldRef(`salary`, data.salary)
          loadIncomeExpense({
            variables: FilterIncom,
          }).then((res) => {
            console.log(res, 'res data')
            const _dataIncome = res.data.listOtherIncomeOrExpenseConfig.otherIncomeConfigList
              ?.filter((ele: any) => ele.incomeExpenseType === 'INCOME')
              .map((ele: any) => ({
                incomeExpenseType: ele.incomeExpenseType,
                incomeOrExpenseID: ele.id,
                name: ele.name,
                total: 0,
                unit: null,
              }))

            const _dataExpense = res.data.listOtherIncomeOrExpenseConfig.otherExpenseConfigList
              ?.filter((ele: any) => ele.incomeExpenseType === 'EXPENSE')
              .map((ele: any) => ({
                incomeExpenseType: ele.incomeExpenseType,
                incomeOrExpenseID: ele.id,
                name: ele.name,
                total: 0,
                unit: null,
              }))
            console.log(_dataExpense, '_dataExpense')
            const _dataFund = res.data.listOtherIncomeOrExpenseConfig.otherExpenseConfigList
              ?.filter((ele: any) => ele.incomeExpenseType === 'FUND')
              .map((ele: any) => ({
                incomeOrExpenseID: ele.id,
                fundNumber: 0,
                name: ele.name,
                contractDate: null,
                fundRate: 0,
                contributionMethod: null,
                contributionCompany: 0,
                contributionTotal: 0,
                beneficiary: null,
                remark: '',
              }))
            const _IncomeExpenseCreate: any[] = combineList(_dataIncome, _dataExpense)
            if (data.incomeExpense) {
              const _IncomeExpense: any[] = combineList(data.incomeExpense, _dataIncome, _dataExpense)
              const _dataIncomeExpense: any[] = _IncomeExpense.filter(
                (ele: any, index: number) =>
                  index === _IncomeExpense.findIndex((item: any) => item.incomeOrExpenseID === ele.incomeOrExpenseID),
              )
              setFieldRef(`incomeExpense`, _dataIncomeExpense)
            } else {
              setFieldRef(`incomeExpense`, _IncomeExpenseCreate)
            }

            if (data.employeeFund) {
              const _Fund: any[] = combineList(data.employeeFund, _dataFund)
              const _FundFilter: any[] = _Fund.filter(
                (ele: any, index: number) =>
                  index === _Fund.findIndex((item: any) => item.incomeOrExpenseID === ele.incomeOrExpenseID),
              )
              setFieldRef(`employeeFund`, _FundFilter)
              console.log(_FundFilter, '_FundFilter')
            } else {
              setFieldRef(`employeeFund`, _dataFund)
            }
          })
          if (data.taxDeductOne) {
            setFieldRef(`taxDeductOne`, data.taxDeductOne)
          }
          if (data.taxDeductTwo) {
            setFieldRef(`taxDeductTwo`, data.taxDeductTwo)
          }
          setFieldRef(`diligentAllowance`, data.diligentAllowance)
          //TaxConfig
          //Tab4
          if (data.paymentMethod) {
            setFieldRef(`paymentMethod`, data.paymentMethod)
          }
          setFieldRef(`bankPayment`, data?.bankPayment)
          setFieldRef(`bankAccountName`, data?.bankAccountName)
          setFieldRef(`bankAccountId`, data?.bankAccountId)

          //Tab5
          setFieldRef(`username`, data?.username)
          setFieldRef(`password`, data?.password)

          //Tab6
          if (data.historyFamily) {
            setFieldRef(`historyFamily`, data.historyFamily)
          }
          if (data.historyWork) {
            setFieldRef(`historyWork`, data.historyWork)
          }
          if (data.historyEducation) {
            setFieldRef(`historyEducation`, data.historyEducation)
          }
          if (data.personalTalent) {
            setListTalent(data.personalTalent)
          }
          if (data.personalIdAttachment) {
            setFieldRef(`personalIdAttachment`, data.personalIdAttachment)
          }
          if (data.personalHouseAttachment) {
            setFieldRef(`personalHouseAttachment`, data.personalHouseAttachment)
          }
          if (data.personalEducationalAttachment) {
            setFieldRef(`personalEducationalAttachment`, data.personalEducationalAttachment)
          }
          if (data.personalTrainningAttachment) {
            setFieldRef(`personalTrainningAttachment`, data.personalTrainningAttachment)
          }
          if (data.personalOptionalAttachment) {
            setFieldRef(`personalOptionalAttachment`, data.personalOptionalAttachment)
          }
          if (data.profileImage) {
            setFieldRef(`profileImage`, data.profileImage)
          }
        })
        setLoading(false)
      }
      FiledData()
      setMode('update')
    } else {
      setLoading(false)
      loadIncomeExpense({
        variables: FilterIncom,
      }).then((res) => {
        const _dataIncome = res.data.listOtherIncomeOrExpenseConfig.otherIncomeConfigList.map((ele: any) => ({
          incomeExpenseType: ele.incomeExpenseType,
          incomeOrExpenseID: ele.id,
          name: ele.name,
          total: 0,
          unit: null,
        }))
        const _dataFund = res.data.listOtherIncomeOrExpenseConfig.otherExpenseConfigList
          ?.filter((ele: any) => ele.incomeExpenseType === 'FUND')
          .map((ele: any) => ({
            incomeOrExpenseID: ele.id,
            fundNumber: 0,
            name: ele.name,
            contractDate: null,
            fundRate: 0,
            contributionMethod: null,
            contributionCompany: 0,
            contributionTotal: 0,
            beneficiary: null,
            remark: '',
          }))

        formikRef.current.setFieldValue(`incomeExpense`, _dataIncome)
        formikRef.current.setFieldValue(`employeeFund`, _dataFund)
      })
      setMode('create')
    }
  }, [])

  const navigate = useNavigate()
  const validationSchema = yup.object({
    //Tab1
    fullName: yup.string().required('กรอกชื่อ'),
    lastName: yup.string().required('กรอกนามสกุล'),
    birthDate: yup.date().required('กรอกวันเกิด'),
    //Tab2
    hireDate: yup.date().required('กรอกวันเริ่มงาน'),
    employeeType: yup.string().required('เลือกประเภทพนักงาน'),
    departmentID: yup.string().required('เลือกแผนกงาน'),
    positionID: yup.string().required('เลือกตำแหน่งงาน'),
    //Tab5
    username: yup.string().required('กรอกชื่อผู้ใช้'),
    password: yup.string().required('กรอกรหัสผ่าน'),
    // email: yup.string().email().notRequired(),
  })

  const onClickSubmit = (errors: any) => {
    if (Object.keys(errors).length !== 0) {
      return Swal.fire({
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
        text: `${Object.values(errors)}`,
        icon: 'error',
        confirmButtonColor: '#d32f2f',
      })
    }
  }

  const onSubmit = (mode: string, varibles: any, employee: any, values: any) => {
    if (!values.fullName) {
      return swal('กรอกข้อมูลให้ครบถ้วน')
    } else {
      return Swal.fire({
        title: `${mode === 'create' ? 'เพิ่มข้อมูลพนักงาน' : 'บันทึกข้อมูลพนักงาน'} `,
        html: `${employee.name} <br /> รหัส ${employee.id}`,
        showCancelButton: true,
        confirmButtonText: 'บันทึก',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
        confirmButtonColor: '#3080f8',
      }).then((result) => {
        if (result.isConfirmed) {
          if (mode === 'create') {
            CreateEmployeeProfile(varibles).then((res: any) => {
              console.log(res, 'Create')
            })
          } else {
            UpdateEmployeeProfile(varibles).then((res: any) => {
              console.log(res, 'Update')
            })
          }
          navigate(-1)
          Swal.fire({
            title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.navtop}>
        <img src={backWardIcon} alt="backWardIcon" style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
        {mode === 'create' && <h1 className={styles.title}>เพิ่มพนักงาน</h1>}
        {mode === 'update' && <h1 className={styles.title}>แก้ไขข้อมูลพนักงาน</h1>}
      </div>
      <div className={styles.card_one}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const EmployeeVariables: any = {
              input: {
                //Tab1
                id: values.id,
                nameTitle: values.nameTitle,
                fullName: values.fullName,
                middleName: values.middleName,
                lastName: values.lastName,
                personalID: values.personalID,
                tel: values.tel,
                email: values.email,
                lineId: values.lineId,
                nationality: values.nationality,
                ethnicity: values.ethnicity,
                fullNameEnglish: values.fullNameEnglish,
                middleNameEnglish: values.middleNameEnglish,
                lastNameEnglish: values.lastNameEnglish,
                sex: values.sex,
                personalRelation: values.personalRelation,
                birthDate: values.birthDate,
                nickName: values.nickName,
                addressPersonalID: values.addressPersonalID,
                addressCurrent: values.addressCurrent,
                //Tab2
                // id: values.id,
                hireDate: values.hireDate,
                retireDate: values.retireDate,
                departmentID: values.departmentID,
                positionID: values.positionID,
                employeeWorkStatus: values.employeeWorkStatus,
                employeeType: values.employeeType,
                setShiftWorking: values.setShiftWorking,
                workPlace: values.workPlace,
                shiftWorkingID: values.shiftWorkingID,
                holidayID: '',
                holidayLink: values.holidayLink,
                isTimeAttendance: values.isTimeAttendance,
                //Tab3
                salaryOptional: values.salaryOptional,
                socialSecurityCalculate: values.socialSecurityCalculate,
                socialSecurity: values.socialSecurity,
                salary: values.salary,
                hospitalService: values.hospitalService,
                personalIDNumber: values.socialSecurityCalculate,
                incomeExpense: values.incomeExpense,
                employeeFund: values.employeeFund,
                taxDeductOne: values.taxDeductOne,
                employeeHandicappedAndAgeOut: values.employeeHandicappedAndAgeOut,
                taxDeductTwo: values.taxDeductTwo,
                diligentAllowance: values.diligentAllowance,
                taxCalculate: values.taxCalculate,
                taxFixedPerMonth: values.taxFixedPerMonth,
                taxStartedMonth: parseInt(values.taxStartedMonth.split('-')[1]),
                //Tab4
                paymentMethod: values.paymentMethod,
                bankPayment: values.bankPayment,
                bankAccountName: values.bankAccountName,
                bankAccountId: values.bankAccountId,
                //Tab5
                profileImage: values.profileImage,
                username: values.username,
                password: values.password,
                //Tab6
                historyFamily: values.historyFamily,
                historyEducation: values.historyEducation,
                historyWork: values.historyWork,
                personalTalent: listTalent,
                salaryUOM: 'MONTH',
                employeePeriod: 'FULL',
                status: 'ACTIVE',
                //Tab7
                personalIdAttachment: values.personalIdAttachment,
                personalHouseAttachment: values.personalHouseAttachment,
                personalEducationalAttachment: values.personalEducationalAttachment,
                personalTrainningAttachment: values.personalTrainningAttachment,
                personalOptionalAttachment: values.personalOptionalAttachment,
              },
            }
            onSubmit(
              mode,
              EmployeeVariables,
              { name: `${values.fullName + ' ' + values.lastName}`, id: values.id },
              values,
            )
            // console.log(EmployeeVariables, 'VARIABLES')
          }}
        >
          {(props: any) => (
            <form onSubmit={props.handleSubmit} className={styles.GroupArea}>
              <section className={styles.SideTab}>
                <div className={styles.GroupTitle}>
                  {TabData.map((item: any, index: number) => (
                    <div
                      className={tab !== item.name ? styles.Title : styles.TitleSelect}
                      key={index}
                      onClick={() => setTab(item.name)}
                    >
                      <div className={styles.GroupText}>
                        <p>
                          {index + 1}.{item.title}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className={styles.line}></div>
              </section>
              <section className={styles.DataArea}>
                <div className={styles.GroupFiled}>
                  {loading ? (
                    <ProgessBarComponent />
                  ) : (
                    <>
                      {tab === 'personal' ? (
                        <PersonalTab values={props} mode={mode} />
                      ) : tab === 'employment' ? (
                        <Employment values={props} />
                      ) : tab === 'compensation' ? (
                        <Compensation values={props} />
                      ) : tab === 'payment' ? (
                        <Payment values={props} />
                      ) : tab === 'settings' ? (
                        <Settings values={props} mode={mode} />
                      ) : tab === 'background' ? (
                        <Background values={props} listTalent={listTalent} setListTalent={setListTalent} />
                      ) : tab === 'document' ? (
                        <Document values={props} />
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
                <div className={styles.ButtonFooter}>
                  <div className={styles.ButtonForm}>
                    <div className={styles.button}>
                      <ButtonComponent
                        _colorBG="white"
                        _colorText="red"
                        _text="ยกเลิก"
                        _type="button"
                        _variant="contained"
                        _functionOnClick={() => navigate(-1)}
                      />
                    </div>
                    <div className={styles.button}>
                      <ButtonComponent
                        _colorBG="blue"
                        _colorText="white"
                        _text="บันทึก"
                        _type="submit"
                        _variant="contained"
                        _functionOnClick={() => {
                          onClickSubmit(props.errors)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}
export default MenuEmployeeEdit

const initialValues = {
  //tab1
  nameTitle: 'MR',
  fullName: '',
  middleName: '',
  lastName: '',
  personalID: '',
  nationality: '',
  ethnicity: '',
  fullNameEnglish: '',
  middleNameEnglish: '',
  lastNameEnglish: '',
  sex: 'MALE',
  personalRelation: 'SINGLE',
  birthDate: null,
  nickName: '',
  tel: '',
  email: null,
  lineId: '',
  addressPersonalID: {
    addressNumber: '',
    group: '',
    optional: '',
    province: '',
    subDistrict: '',
    district: '',
    zipCode: '',
  },
  addressCurrent: {
    addressNumber: '',
    group: '',
    optional: '',
    province: '',
    subDistrict: '',
    district: '',
    zipCode: '',
  },
  //tab2
  id: '',
  fingerPrintId: '',
  hireDate: '',
  retireDate: '',
  departmentID: '',
  positionID: '',
  teamList: '',
  getPackageGroup: '',
  leaderTeam: '',
  employeeWorkStatus: 'PROBATION',
  listLeaveQuota: [],
  employeeType: '',
  employeeWorkTypeShift: '',
  setShiftWorking: 'ENABLE',
  shiftWorkingID: '',
  workPlace: 'OFFICE',
  packageGroupID: '',
  holidayID: '',
  holidayLink: 'DEFAULT',
  //Tab3
  salaryOptional: 'STABLE',
  socialSecurityCalculate: 'BASE_ON_SALARY',
  socialSecurity: 'PERSONAL_IDCARD',
  listWelfareQuota: [],
  hospitalService: '',
  personalIDNumber: '',
  diligentAllowance: false,
  salary: 0,
  incomeExpense: [],
  isTimeAttendance: true,
  taxCalculate: null,
  taxFixedPerMonth: 0,
  taxStartedMonth: '',
  employeeFund: [],
  taxDeductOne: [{ year: '', month: '', total: 0 }],
  employeeHandicappedAndAgeOut: '',
  taxDeductTwo: [{ name: '', rateDeduct: 0, totalDeduct: 0 }],
  //Tab4
  paymentMethod: 'BANK',
  bankPayment: null,
  bankAccountName: null,
  bankAccountId: '',
  //Tab5
  profileImage: '',
  username: '',
  password: '',
  // tab6
  historyFamily: [{ fullName: '', age: 0, job: '', relation: '' }],
  historyEducation: [
    { educationLevel: '', educationName: '', majorName: '', startedYear: '', endedYear: '' },
    //Tab7
  ],
  historyWork: [
    {
      workName: '',
      startedAt: null,
      endedAt: null,
      positionName: '',
      descPosition: '',
      salary: 0,
      leaveReason: '',
    },
  ],
  personalTalent: [],
  personalIdAttachment: '',
  personalHouseAttachment: '',
  personalEducationalAttachment: '',
  personalTrainningAttachment: '',
  personalOptionalAttachment: '',
}
const TabData = [
  { name: 'personal', title: 'ข้อมูลพื้นฐาน' },
  { name: 'employment', title: 'ข้อมูลจ้างงาน' },
  { name: 'compensation', title: 'ข้อมูลค่าตอบแทน' },
  // { name: 'tax_deduction', title: 'ลดหย่อนภาษี' },
  { name: 'payment', title: 'ข้อมูลการจ่ายเงิน' },
  { name: 'settings', title: 'การตั้งค่าผู้ใช้งาน' },
  { name: 'background', title: 'ประวัติส่วนตัว' },
  { name: 'document', title: 'เอกสาร' },
]
