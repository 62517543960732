import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { ButtonComponent, PopperContainer } from '../../component'
import CustomizedSwitches from '../../component/AntSwitch'
import { Box, Button, Grid, Paper, Stack } from '@mui/material'
import { FieldArray, Form, FormikProvider, useFormik, useFormikContext } from 'formik'
import {
  ECompoundType,
  EEmployeeType,
  ELeaveAmountType,
  ELeaveConfigType,
  ESex,
  LeaveConfig,
  LeaveQuota,
  UpdateLeaveConfig,
} from '../../utils/generated'
import { createLeaveConfig, deleteLeaveConfig, updateLeaveConfig, useQueryListLeaveList } from '../../adapter/api'
import { classes, deepClone, remove__typename } from '../../utils/common'
import {
  FastFieldProvider,
  InputCheckboxComponentCustom,
  InputNumberComponent,
  InputNumberUnitComponent,
  InputRadioComponent,
  InputSelectComponent,
  InputTextComponent,
} from '../../component/InputsFormik'
import styled from '@emotion/styled'
import AddIcon from '../../image/add_circle.svg'
import QuestionIcon from '../../image/question.svg'
import DeleteIcon from '../../image/deleteRedIcon.svg'
import MinusIcon from '../../image/minus_circle.svg'

import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useFormikAlertError } from '../../hooks/useFormikAlertError'
import ReactDOM from 'react-dom'

interface IValue {
  leaveConfigList: LeaveConfig[]
  selectConfigId: string | null
  currentConfig?: LeaveConfig
  isShowDetail: boolean
}

const initialValueLeaveQuota: LeaveQuota = {
  maxLeavePaidDay: 0,
  quotaLeaveDay: 0,
  workMonthExperience: 0,
}

const isEmpty = (value: any) => value === null || value === undefined || value === ''

const filterEmptyQuota = (quotaList?: LeaveQuota[]) => {
  return quotaList?.filter(
    (q) => !isEmpty(q.maxLeavePaidDay) && !isEmpty(q.quotaLeaveDay) && !isEmpty(q.workMonthExperience),
  )
}

const LeavePolicyPage: React.FC = (): JSX.Element => {
  const { data, refetch } = useQueryListLeaveList({})

  useEffect(() => {
    if (data) formik.setFieldValue('leaveConfigList', data)
  }, [data])

  const onSubmit = async (values: IValue) => {
    const input = deepClone(values.currentConfig!) as UpdateLeaveConfig
    remove__typename(input)

    input.leaveQuotaList = filterEmptyQuota(input.leaveQuotaList as LeaveQuota[])

    try {
      if (input.id) {
        await updateLeaveConfig(input)
        refetch({})
      } else {
        const res = await createLeaveConfig(input)
        await refetch({})
        ReactDOM.flushSync(() => {
          formik.setFieldValue('selectConfigId', res.id)
          formik.setFieldValue('currentConfig.id', res.id)
        })
      }

      Swal.fire({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal.fire({ title: 'Error', icon: 'error', text: JSON.stringify(error) })
    }
  }

  const initialValues: IValue = {
    leaveConfigList: data || [],
    selectConfigId: null,
    isShowDetail: false,
  }

  const validateSchema = yup.object().shape({
    currentConfig: yup.object().shape({
      name: yup.string().required(),
      leaveAmountType: yup.string().required(),
      leaveConfigType: yup.string().required(),
    }),
  })

  const formik = useFormik<IValue>({
    initialValues,
    onSubmit,
    validationSchema: validateSchema,
  })

  const handleDelete = async (id: string) => {
    const willDelete = await Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    })

    if (!willDelete.isConfirmed) return
    try {
      await deleteLeaveConfig({ id })
      await refetch({})

      Swal.fire({ title: 'Deleted', icon: 'success' })
      formik.setFieldValue('isShowDetail', false)
      formik.setFieldValue('selectConfigId', null)
    } catch (error) {
      Swal.fire({ title: 'Error', icon: 'error', text: JSON.stringify(error) })
    }
  }

  return (
    <>
      <div>
        <div className="main">
          <FormikProvider value={formik}>
            <>
              <div className={styles.container}>
                <section className={styles.topicPageWrapper}>
                  <p className={styles.topicPage}>ระเบียบการลา</p>
                </section>
                <div className={styles.contentContainer}>
                  <LeaveConfigTabList />
                  <LeaveConfigForm onDelete={handleDelete} />
                </div>
              </div>
            </>
          </FormikProvider>
        </div>
      </div>
    </>
  )
}

const LeaveConfigTabList = () => {
  const formik = useFormikContext<IValue>()

  const handleCreateLeaveConfig = () => {
    const { setFieldValue } = formik

    setFieldValue('currentConfig', {
      leaveQuotaList: [deepClone(initialValueLeaveQuota)],
    })
    setFieldValue('isShowDetail', true)
    setFieldValue('selectConfigId', null)
  }

  const filterLeaveConfigType = React.useCallback(
    (type: ELeaveConfigType) => {
      const { values } = formik
      return values.leaveConfigList.filter((e) => e.leaveConfigType === type)
    },
    [formik.values.leaveConfigList],
  )

  const isActive = React.useCallback(
    (id: string) => {
      return id === formik.values.selectConfigId
    },
    [formik.values.selectConfigId],
  )

  const handleSelectLeaveConfig = (id: string) => {
    const { values, setFieldValue } = formik

    setFieldValue('selectConfigId', id)

    const currentConfig = values.leaveConfigList.find((e) => e.id === id)!

    if (!currentConfig?.leaveQuotaList?.length) currentConfig.leaveQuotaList = [initialValueLeaveQuota]

    setFieldValue('currentConfig', deepClone(currentConfig))
    setFieldValue('isShowDetail', true)
  }

  const handleToggle = async (id: string, status: boolean) => {
    const idx = formik.values.leaveConfigList.findIndex((e) => e.id === id)
    const leave = formik.values.leaveConfigList[idx]
    leave.isActive = status
    formik.setFieldValue(`leaveConfigList[${idx}]`, leave)

    try {
      const input = deepClone(leave) as UpdateLeaveConfig
      remove__typename(input)
      formik.setFieldValue('currentConfig.isActive', status)
      await updateLeaveConfig(input)
      Swal.fire({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal.fire({ title: 'Error', icon: 'error', text: JSON.stringify(error) })
    }
  }

  return (
    <section className={styles.TabList}>
      <div className={styles.AreaList}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <p className={styles.title}>วันลาที่เพิ่มแล้ว</p>
          <ButtonComponent
            _colorBG="blue"
            _colorText="white"
            _text="เพิ่มวันลา"
            _type="button"
            _variant="text"
            _isIcon="add"
            _sx={{ width: 'fit-content', fontSize: '18px', px: 1.5 }}
            _functionOnClick={handleCreateLeaveConfig}
          />
        </Stack>

        <div className={styles.GroupList}>
          <p className={styles.headText}>ประเภทวันลาตามกฎหมายแรงงาน</p>
          <div className={styles.CardList}>
            {filterLeaveConfigType(ELeaveConfigType.ByLaw).map((item, index) => (
              <TabMenuItem
                onToggle={handleToggle}
                key={index}
                item={item}
                isActive={isActive(item.id!)}
                onSelectLeaveConfig={handleSelectLeaveConfig}
              />
            ))}
          </div>
        </div>
        <Stack sx={{ mt: 2 }}>
          <p className={styles.headText}>ประเภทวันลาตามนโยบายบริษัท</p>
          <div className={styles.CardList}>
            {filterLeaveConfigType(ELeaveConfigType.ByCompany).map((item, index) => (
              <TabMenuItem
                onToggle={handleToggle}
                key={index}
                item={item}
                isActive={isActive(item.id!)}
                onSelectLeaveConfig={handleSelectLeaveConfig}
              />
            ))}
          </div>
        </Stack>
      </div>
    </section>
  )
}

interface TabMenuItemProps {
  item: LeaveConfig
  isActive: boolean
  onSelectLeaveConfig: (id: string) => any
  onToggle: (id: string, status: boolean) => void
}

const TabMenuItem: React.FC<TabMenuItemProps> = ({ item, onSelectLeaveConfig, isActive, onToggle }) => {
  return (
    <div
      className={classes(styles.card, isActive && styles.cardActive)}
      onClick={onSelectLeaveConfig.bind(null, item.id!)}
    >
      <p>{item.name}</p>

      <CustomizedSwitches _name="" _checked={!!item.isActive} onChange={(checked) => onToggle(item.id!, checked)} />
    </div>
  )
}

interface LeaveConfigFormProps {
  onDelete: (id: string) => any
}
const LeaveConfigForm: React.FC<LeaveConfigFormProps> = (props) => {
  const [prevConfig, setPrevConfig] = React.useState<LeaveConfig>()

  const formikContext = useFormikContext<IValue>()

  const initialValues: LeaveConfig = {
    leaveConfigType: ELeaveConfigType.ByCompany,
    leaveAmountType: ELeaveAmountType.PerYear,
  }
  const onSubmit = () => {
    formikContext.setFieldValue('currentConfig', formik.values)
    formikContext.submitForm()
  }

  const validateSchema = yup.object().shape({
    name: yup.string().required(),
    leaveAmountType: yup.string().required(),
    leaveConfigType: yup.string().required(),
  })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validateSchema,
  })

  useEffect(() => {
    formik.setValues(initialValues)
  }, [])

  useFormikAlertError(formik)

  useEffect(() => {
    if (!formikContext.values.selectConfigId) {
      formik.setValues({ ...initialValues, leaveQuotaList: [initialValueLeaveQuota] })
      return
    }

    if (!formikContext.values.currentConfig?.leaveQuotaList?.length) {
      formikContext.values.currentConfig!.leaveQuotaList = [initialValueLeaveQuota]
    }

    formik.setValues(formikContext.values.currentConfig!)
    setPrevConfig(deepClone(formikContext.values.currentConfig!))
  }, [formikContext.values.selectConfigId, formikContext.values.currentConfig?.isActive])

  const handleToggleList = <T,>(list: T[], item: T, findCb?: (item: T) => boolean): T[] => {
    const _list = deepClone<T[]>(list)
    const idx = _list.findIndex((e) => {
      if (findCb) return findCb(e)
      return e === item
    })
    if (idx === -1) _list.push(item)
    else _list.splice(idx, 1)
    return _list
  }

  const handleSelectToggleAllEmployeeType = () => {
    if (formik.values.employeeAllowTypeList?.length === EMPLOYEE_TYPES.length) {
      return formik.setFieldValue('employeeAllowTypeList', [])
    }
    formik.setFieldValue(
      'employeeAllowTypeList',
      EMPLOYEE_TYPES.map((e) => e.value),
    )
  }

  const handleReset = () => {
    formik.setValues(deepClone(prevConfig!))
  }

  return (
    <FormikProvider value={formik}>
      <FastFieldProvider>
        <section className={styles.DataShow}>
          {formikContext.values.isShowDetail && (
            <Form>
              <Grid container columnSpacing={2} columns={5} alignItems="center">
                <Grid item xs={2}>
                  <Grid container columns={3} rowGap={4} alignItems="center">
                    <Grid item xs={1}>
                      <h2>ชื่อการลา</h2>
                    </Grid>
                    <Grid item xs={2}>
                      <InputTextComponent _label="" _name="name" />
                    </Grid>
                    <Grid item xs={1}>
                      <b>หน่วยวันที่ลา</b>
                    </Grid>
                    <Grid item xs={2}>
                      <InputSelectComponent
                        _name="leaveAmountType"
                        _label=""
                        _menuList={[
                          { label: 'ปี', value: ELeaveAmountType.PerYear },
                          { label: 'ตลอดอายุการทำงาน', value: ELeaveAmountType.WorkLife },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Stack>
                    <b>ประเภทวันลา</b>
                    <small>แบ่งประเภทวันลาให้เป็นตามกฎหมายแรงงาน หรือตามนโยบายบริษัท</small>
                  </Stack>
                  <InputRadioComponent
                    _name="leaveConfigType"
                    _menuList={[
                      { label: 'ประเภทวันลาตามกฎหมายแรงงาน', value: ELeaveConfigType.ByLaw },
                      { label: 'ประเภทวันลาตามนโยบายบริษัท', value: ELeaveConfigType.ByCompany },
                    ]}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container columns={10}>
                <Grid item xs={4}>
                  <Stack rowGap={2}>
                    <b>เพศที่พนักงานลาได้</b>
                    <Stack>
                      {EMPLOYEE_SEX.map((employeeSex) => (
                        <InputCheckboxComponentCustom
                          key={employeeSex.value}
                          _name="employeeAllowSexList"
                          _label={employeeSex.label}
                          _value={formik.values.employeeAllowSexList?.includes(employeeSex.value)}
                          _onChange={() => {
                            const list = handleToggleList(formik.values.employeeAllowSexList || [], employeeSex.value)
                            formik.setFieldValue('employeeAllowSexList', list)
                          }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack rowGap={2}>
                    <Stack direction="row" justifyContent="space-between">
                      <b>ประเภทพนักงานที่ลาได้</b>
                      <Box
                        sx={{ color: '#3a77da', textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={handleSelectToggleAllEmployeeType}
                      >
                        {formik.values.employeeAllowTypeList?.length === EMPLOYEE_TYPES.length && 'เลิก'}
                        เลือกทั้งหมด
                      </Box>
                    </Stack>
                    <Grid container columnGap={4} display="grid" gridTemplateColumns={'1fr 1fr'}>
                      {EMPLOYEE_TYPES.map((employeeType) => (
                        <InputCheckboxComponentCustom
                          key={employeeType.value}
                          _name="employeeAllowTypeList"
                          _label={employeeType.label}
                          _value={formik.values.employeeAllowTypeList?.includes(employeeType.value)}
                          _onChange={() => {
                            const list = handleToggleList(formik.values.employeeAllowTypeList || [], employeeType.value)
                            formik.setFieldValue('employeeAllowTypeList', list)
                          }}
                        />
                      ))}
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
              <Divider />
              <>
                <Box component="b" mb={2}>
                  เงื่อนไข
                </Box>
                <Grid container columns={{ xs: 7 }} columnSpacing={4}>
                  <Grid item xs={2} mb={1}>
                    <p>อายุงาน (เดือน)</p>
                  </Grid>
                  <Grid item xs={2} mb={1}>
                    <p>โควต้า (วัน)</p>
                  </Grid>
                  <Grid item xs={2} mb={1}>
                    <p>จำนวนวันลาสูงสุดที่ได้รับ (วัน)</p>
                  </Grid>
                  <FieldArray
                    name="leaveQuotaList"
                    render={(arrHelper) => (
                      <>
                        {(formik.values.leaveQuotaList || []).map((quota, index) => (
                          <React.Fragment key={index}>
                            <Grid item xs={2} mb={1}>
                              <InputNumberComponent _name={`leaveQuotaList[${index}].workMonthExperience`} _label="" />
                            </Grid>
                            <Grid item xs={2}>
                              <InputNumberComponent _name={`leaveQuotaList[${index}].quotaLeaveDay`} _label="" />
                            </Grid>
                            <Grid item xs={3}>
                              <Stack direction="row" columnGap={2} alignItems="center">
                                <InputNumberComponent _name={`leaveQuotaList[${index}].maxLeavePaidDay`} _label="" />
                                {index === (formik.values.leaveQuotaList?.length || 0) - 1 ? (
                                  <img
                                    className={styles.addIcon}
                                    src={AddIcon}
                                    onClick={() => arrHelper.push(initialValueLeaveQuota)}
                                  />
                                ) : (
                                  <img
                                    className={styles.addIcon}
                                    src={MinusIcon}
                                    onClick={() => arrHelper.remove(index)}
                                  />
                                )}
                              </Stack>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  />
                </Grid>
              </>
              <Divider />
              <Stack rowGap={2}>
                <Grid container columns={2} columnSpacing={8} alignItems="center" minHeight="2.75rem">
                  <SwitchItem
                    name="isLeaveBeforehand"
                    label="ลาล่วงหน้า"
                    popperText="ลาล่วงหน้า คือ พนักงานต้องสร้างเอกสารการลาก่อนถึงวันเท่านั้น"
                  />
                  <Grid item xs={1}>
                    {formik.values.isLeaveBeforehand && (
                      <Stack direction="row" alignItems="center" columnGap={1}>
                        <Box whiteSpace="nowrap">
                          <p>ลาล่วงหน้าอย่างน้อย</p>
                        </Box>
                        <InputNumberUnitComponent _name="leaveBeforehandDay" _label="" _unit="วัน" />
                      </Stack>
                    )}
                  </Grid>
                </Grid>
                <Grid container columns={2} columnSpacing={8} alignItems="center" minHeight="2.75rem">
                  <SwitchItem
                    name="isLeaveDiligent"
                    label="เบี้ยขยัน"
                    popperText="อนุญาตให้พนักงานลาได้ โดยได้รับเบี้ยขยันในเดือนนั้นๆ"
                  />
                </Grid>
                <Grid container columns={2} columnSpacing={8} alignItems="start" minHeight="2.75rem">
                  <SwitchItem
                    name="isLeaveCompound"
                    label="สะสมข้ามปี"
                    popperText="อนุญาตให้วันลาที่เหลือสะสมในปีถัดไป"
                  />
                  <Grid item xs={1}>
                    {formik.values.isLeaveCompound && (
                      <Stack>
                        <InputRadioComponent
                          _name="leaveCompoundType"
                          _row
                          _menuList={[
                            {
                              label: 'โอนวันลาครึ่งจำนวน',
                              value: ECompoundType.Half,
                            },
                            {
                              label: 'โอนวันลาเต็มจำนวน',
                              value: ECompoundType.Full,
                            },
                          ]}
                        />
                        <Stack direction="row" alignItems="center" columnGap={1}>
                          <Box whiteSpace="nowrap">
                            <p>สะสมสูงสุดต่อปีไม่เกิน</p>
                          </Box>
                          <InputNumberUnitComponent _name="maxLeaveCompound" _label="" _unit="วัน" />
                        </Stack>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
                <Grid container columns={2} columnSpacing={8} alignItems="center" minHeight="2.75rem">
                  <SwitchItem
                    name="isLookupNotWorkday"
                    label="คำนวณวันหยุด"
                    popperText="กำหนดให้นับรวมวันหยุดเป็นวันลาด้วย"
                  />
                </Grid>
                <Grid container columns={2} columnSpacing={8} alignItems="center" minHeight="2.75rem">
                  <SwitchItem
                    name="isLeaveAfterhand"
                    label="ลาย้อนหลัง"
                    popperText="ลาย้อนหลัง คือ เมื่อผ่านวันลาไปแล้ว พนักงานสามารถสร้างเอกสารการลาได้"
                  />
                </Grid>
                <Grid container columns={2} columnSpacing={8} alignItems="center" minHeight="2.75rem">
                  <SwitchItem
                    name="isCountProbation"
                    label="นับอายุช่วงทดลองงาน"
                    popperText="กำหนดให้อายุงานเริ่มนับตั้งแต่ช่วงทดลองงาน สำหรับคำนวณสิทธิการลา"
                  />
                </Grid>
                <Grid container columns={2} columnSpacing={8} alignItems="center" minHeight="2.75rem">
                  <SwitchItem
                    name="isExceedQuota"
                    label="เกินโควต้า"
                    popperText="เกินโควต้า คือ พนักงานสามารถลา เกินโควต้าที่กำหนดได้"
                  />
                </Grid>
                <Grid container columns={2} columnSpacing={8} alignItems="center" minHeight="2.75rem">
                  <SwitchItem
                    name="isLeaveOnce"
                    label="ลาครั้งเดียว"
                    popperText="ลาครั้งเดียว คือ พนักงานสามารถทำการลา ได้เพียงครั้งเดียวเท่านั้น"
                  />
                </Grid>
              </Stack>
              <Divider />
              <Stack alignItems="center" justifyContent="space-between" direction="row">
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <ButtonComponent
                    _colorBG="gray"
                    _colorText="white"
                    _text="รีเซต"
                    _type="button"
                    _variant="text"
                    _sx={{ width: 'fit-content', fontSize: '1rem' }}
                    _functionOnClick={handleReset}
                  />
                  <ButtonComponent
                    _colorBG="blue"
                    _colorText="white"
                    _text="บันทึก"
                    _type="submit"
                    _variant="text"
                    _sx={{ width: 'fit-content', fontSize: '1rem' }}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" columnGap={1}>
                  {formik.values.id && (
                    <Button
                      sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        columnGap: 1,
                        color: '#d32f2f',
                        ':hover': {
                          backgroundColor: '#FFF5F8',
                        },
                      }}
                      onClick={props.onDelete.bind(null, formik.values.id!)}
                      type="button"
                    >
                      <img className={styles.DeleteIcon} src={DeleteIcon} />
                      <p>ลบการลานี้</p>
                    </Button>
                  )}
                  <Stack direction="row" alignItems="center" columnGap={1}>
                    เปิดใช้งาน
                    <CustomizedSwitches.Formik _name="isActive" />
                  </Stack>
                </Stack>
              </Stack>
            </Form>
          )}
        </section>
      </FastFieldProvider>
    </FormikProvider>
  )
}

interface SwitchItemProps {
  name: string
  label: string
  popperText: string
}
const SwitchItem: React.FC<SwitchItemProps> = ({ name, label, popperText }) => {
  return (
    <Grid item xs={1}>
      <Stack alignItems="center" flexGrow={1} justifyContent="space-between" direction="row">
        <Stack direction="row" alignItems="center" columnGap={1}>
          <p>{label}</p>
          <SwitchPopper text={popperText} />
        </Stack>
        <CustomizedSwitches.Formik _name={name} />
      </Stack>
    </Grid>
  )
}

export default LeavePolicyPage

const EMPLOYEE_TYPES = [
  {
    value: EEmployeeType.FulltimeMonth,
    label: 'พนักงานรายเดือน',
  },
  {
    value: EEmployeeType.FulltimeDay,
    label: 'พนักงานรายวัน',
  },

  {
    value: EEmployeeType.Parttime,
    label: 'พนักงานพาร์ทไทม์',
  },
  {
    value: EEmployeeType.Outsource,
    label: 'พนักงานรายจ้างเหมา',
  },
]

const EMPLOYEE_SEX = [
  {
    value: ESex.Male,
    label: 'พนักงานเพศชาย',
  },
  {
    value: ESex.Female,
    label: 'พนักงานเพศหญิง',
  },
]

const Divider = styled.hr`
  margin: 2rem 0;
  border: 0;
  border-top: 1px solid #e9e9e9;
`

interface SwitchPopperProps {
  text: string
}

const SwitchPopper: React.FC<SwitchPopperProps> = ({ text }) => {
  return (
    <PopperContainer button={<img className={styles.questionIcon} src={QuestionIcon} />} placement="right">
      <Paper sx={{ width: '12rem', ml: '0.5rem', p: '0.5rem' }}>{text}</Paper>
    </PopperContainer>
  )
}
