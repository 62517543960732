//lib
import React from 'react'
import { useFormikContext } from 'formik'

//Include Project
import styles from './index.module.scss'

const WorkingTimeAttendanceReportView: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.wrapperInput}>
        <div className={styles.gropHead}>
          <p className={styles.TextHead}>ช่วงเวลาทำงาน</p>
        </div>

        <div className={styles.wrapperTime}>
          {values?.report.workingTimeList?.map((itemWork: any, IndexWorktime: number) => (
            <section className={styles.inputBox} key={IndexWorktime}>
              <p className={styles.descriptionText}>ช่วงเวลา</p>
              <div className={styles.groupBox}>
                <p>
                  {itemWork.startedAt} - {itemWork.endedAt}
                </p>
              </div>
            </section>
          ))}
        </div>
        {values.report.workingTimeList.length > 0 && (
          <section className={styles.textGroup}>
            <p>
              {values.report.inDiffMinute < 0 ? 'เข้าก่อน' : 'เข้าสาย'} : {values.report.inDiffMinute} นาที
            </p>
            <p>
              {values.report.breakDiffMinute < 0 ? 'พักเร็ว' : 'พักเกิน'} : {values.report.breakDiffMinute} นาที
            </p>
            <p>
              {values.report.outDiffMinute < 0 ? 'ออกก่อน' : 'ออกหลัง'} : {values.report.outDiffMinute} นาที
            </p>
          </section>
        )}
      </div>
    </div>
  )
}

export default WorkingTimeAttendanceReportView
