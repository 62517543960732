import { Box, Divider, Grid, Modal, Stack, styled, useTheme } from '@mui/material'
import { useFormikContext, useField, useFormik, FormikProvider } from 'formik'
import React, { useEffect } from 'react'
import {
  EIncomeExpenseType,
  E40Type,
  ERoundType,
  EIncomeOrExpense,
  EVariableOrFixed,
  OtherIncomeOrExpenseConfigItem,
} from '../../../utils/generated'
import { ButtonComponent, InputRadioComponent, InputTextComponent, ToggleGroup, ToggleItem } from '../../../component'
import { IValue } from '../index'

interface Props {
  open: boolean
  onClose?: (status: boolean) => void
  onSubmit?: (config: OtherIncomeOrExpenseConfigItem) => void
}

export const IncomeExpenseModal: React.FC<Props> = (props) => {
  const theme = useTheme()

  const initialValues: IValue = {}

  const formikContext = useFormikContext<IValue>()

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log(values)
    },
  })

  const onSubmit = async () => {
    if (formik.values.currentConfig?.incomeExpenseType === EIncomeExpenseType.Fund) {
      delete formik.values.currentConfig?.variableOrFixed
    }

    props.onSubmit && props.onSubmit(formik.values.currentConfig as OtherIncomeOrExpenseConfigItem)
  }

  const handleClose = () => {
    props.onClose && props.onClose(false)
  }

  useEffect(() => {
    if (props.open && formikContext.values.currentConfig?.id) {
      formik.setFieldValue('currentConfig', formikContext.values.currentConfig)
      return
    }

    if (!formik.values.currentConfig || !props.open) {
      formik.setFieldValue('currentConfig', {
        incomeExpenseType: EIncomeExpenseType.Income,
        income40Type: E40Type.I401,
        roundType: ERoundType.None,
        incomeOrExpense: EIncomeOrExpense.Income,
        variableOrFixed: EVariableOrFixed.Fixed,
      })
    }

    if (!props.open) {
      formikContext.setFieldValue('currentConfig', null)
    }
  }, [props.open])

  useEffect(() => {
    if (
      !formik.values.currentConfig?.incomeExpenseType ||
      formik.values.currentConfig?.incomeExpenseType === formikContext.values.currentConfig?.incomeExpenseType
    )
      return

    const { incomeExpenseType } = formik.values.currentConfig
    if (incomeExpenseType === EIncomeExpenseType.Income) {
      formik.setFieldValue('currentConfig.incomeOrExpense', EIncomeOrExpense.Income)
      formik.setFieldValue('currentConfig.variableOrFixed', EVariableOrFixed.Fixed)
    } else if (incomeExpenseType === EIncomeExpenseType.Expense) {
      formik.setFieldValue('currentConfig.incomeOrExpense', EIncomeOrExpense.Expense)
      formik.setFieldValue('currentConfig.variableOrFixed', EVariableOrFixed.Fixed)
    } else if (incomeExpenseType === EIncomeExpenseType.Fund) {
      formik.setFieldValue('currentConfig.incomeOrExpense', EIncomeOrExpense.Expense)
      formik.setFieldValue('currentConfig.variableOrFixed', null)
    } else {
      formik.setFieldValue('currentConfig.incomeOrExpense', null)
      formik.setFieldValue('currentConfig.variableOrFixed', null)
    }
  }, [formik.values.currentConfig?.incomeExpenseType])

  const isTypeAuto = React.useMemo(() => {
    return formik.values.currentConfig?.incomeExpenseType === EIncomeExpenseType.Auto
  }, [formik.values.currentConfig?.incomeExpenseType])

  return (
    <>
      <Modal open={props.open} onClose={handleClose}>
        <ModalContainer>
          <ModalHeader>
            <h2>เพิ่มรายรับรายหัก</h2>
            <Box onClick={handleClose}>
              <CloseIcon />
            </Box>
          </ModalHeader>
          <ModalBody>
            <FormikProvider value={formik}>
              <Grid container columns={5} sx={{ height: '100%' }} columnSpacing="3rem">
                <Grid item xs={2}>
                  <Stack
                    sx={{ border: `2px dashed ${theme.colors.gray1}`, borderRadius: '18px', p: '12px', height: '100%' }}
                  >
                    <Box component="p" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                      ประเภทรูปแบบคำนวณ
                    </Box>
                    <ToggleGroup.Formik
                      name="currentConfig.incomeExpenseType"
                      disabled={!!formikContext.values.currentConfig?.id}
                    >
                      <Stack rowGap="1rem" mt="1rem">
                        {MenuTopicList.map((menu) => (
                          <ToggleItem key={menu.key} value={menu.key}>
                            {({ isActive, disabled }) => (
                              <Stack
                                justifyContent="center"
                                sx={{
                                  border: `3px solid ${theme.colors.blueLight2}`,
                                  height: '3.5rem',
                                  borderRadius: '13px',
                                  px: '1.5rem',
                                  fontSize: '18px',
                                  fontWeight: 'bold',
                                  cursor: disabled ? 'default' : 'pointer',
                                  color: isActive ? 'white' : 'black',
                                  backgroundColor: isActive ? theme.colors.primary : 'white',
                                }}
                              >
                                {menu.label}
                              </Stack>
                            )}
                          </ToggleItem>
                        ))}
                      </Stack>
                    </ToggleGroup.Formik>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack rowGap="1rem">
                    <InputTextComponent _name="currentConfig.name" _label="ชื่อประเภท" _disabled={isTypeAuto} />
                    {formik.values.currentConfig?.incomeExpenseType &&
                      [EIncomeExpenseType.Expense, EIncomeExpenseType.Income].includes(
                        formik.values.currentConfig.incomeExpenseType,
                      ) && (
                        <>
                          <Stack>
                            <Box sx={{ fontWeight: 'bold' }}>รูปแบบรายรับรายจ่าย</Box>

                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                              <InputRadioComponent
                                _row
                                _name="currentConfig.variableOrFixed"
                                _menuList={[
                                  { value: EVariableOrFixed.Fixed, label: 'คงที่' },
                                  { value: EVariableOrFixed.Variable, label: 'ไม่คงที่' },
                                ]}
                              />
                              <Stack sx={{ width: '60%', fontSize: '10px', color: 'rgba(0, 0, 0, 0.4)' }}>
                                <p>รูปแบบคงที่ คือ รายรับรายจ่ายที่พนักงานจะได้ เหมือนกันทุกเดือน</p>
                                <p>รูปแบบไม่คงที่ คือรายรับรายจ่ายที่บางเดือนได้ บางเดือนไม่ได้</p>
                              </Stack>
                            </Stack>
                          </Stack>
                        </>
                      )}

                    <Stack direction="row" columnGap="2rem">
                      <Stack>
                        <Box>ประเภทรายรับรายจ่าย</Box>

                        <InputRadioComponent
                          _row
                          _name="currentConfig.incomeOrExpense"
                          _disabled
                          _menuList={[
                            { value: EIncomeOrExpense.Income, label: 'รายรับ' },
                            { value: EIncomeOrExpense.Expense, label: 'รายจ่าย' },
                          ]}
                        />
                      </Stack>
                      <Stack>
                        <Box sx={{ fontWeight: 'bold' }}>ประเภทรายรับรายจ่าย 40 (x)</Box>
                        <InputRadioComponent
                          _row
                          _name="currentConfig.income40Type"
                          _disabled={isTypeAuto}
                          _menuList={[
                            { value: E40Type.I401, label: '40(1)' },
                            { value: E40Type.I402, label: '40(2)' },
                            { value: E40Type.I403, label: '40(3)' },
                          ]}
                        />
                      </Stack>
                    </Stack>
                    <Divider />
                    <Stack>
                      <Box sx={{ fontWeight: 'bold' }}>ตั้งค่าการปัดเศษ</Box>
                      <InputRadioComponent
                        _row
                        _name="currentConfig.roundType"
                        _disabled={isTypeAuto}
                        _menuList={[
                          { value: ERoundType.None, label: 'ไม่ปัดเศษ' },
                          { value: ERoundType.Round, label: 'ปัดเศษ' },
                        ]}
                      />
                    </Stack>
                    {!formik.values.currentConfig?.id &&
                      formik.values.currentConfig?.incomeExpenseType === EIncomeExpenseType.Auto && (
                        <>
                          <Box sx={{ color: theme.colors.colorRed }}>
                            *** หากต้องการเพิ่มรายรับรายหักที่มีหลักเกณฑ์พิเศษ สำหรับการคำนวณ
                            สามารถติดต่อเจ้าหน้าที่เพื่อเพิ่มรายการนี้ได้
                          </Box>
                        </>
                      )}
                  </Stack>
                </Grid>
              </Grid>
            </FormikProvider>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" columnGap="1rem" justifyContent="flex-end" alignItems="center">
              <ButtonComponent
                _colorBG="gray"
                _colorText="white"
                _text="ยกเลิก"
                _type="button"
                _variant="text"
                _sx={{ width: 'fit-content', height: 'fit-content' }}
                _functionOnClick={handleClose}
              />
              <ButtonComponent
                _colorBG="blue"
                _colorText="white"
                _text="บันทึก"
                _type="button"
                _variant="text"
                _sx={{ width: 'fit-content', height: 'fit-content' }}
                _functionOnClick={onSubmit}
                _isDisabled={isTypeAuto}
              />
            </Stack>
          </ModalFooter>
        </ModalContainer>
      </Modal>
    </>
  )
}

// interface EnhanceFormikValueProps {
//   children: React.ReactNode | EnhanceFormikValueChildFn
//   name: string
// }

// type EnhanceFormikValueChildFn = (value: any) => React.ReactNode

// const EnhanceFormikValue: React.FC<EnhanceFormikValueProps> = (props) => {
//   const [{ value }] = useField(props.name)

//   const prevValRef = React.useRef<unknown>()

//   const isNew = () => value !== prevValRef.current

//   const prevChild = React.useMemo(() => {
//     console.log('rerender111')
//     prevValRef.current = value
//     return props.children
//   }, [isNew()])

//   const renderChild = React.useCallback(() => {
//     // if (isNew()) return withChildFunction(prevChild)
//     // return withChildFunction(props.children)

//     console.log('isNew()', isNew())

//     if (isNew()) return props.children
//     return prevChild
//   }, [isNew()])

//   const withChildFunction = React.useCallback((child: any) => {
//     if (typeof child !== 'function') return child
//     const _value = isNew() ? value : prevValRef.current
//     return (child as EnhanceFormikValueChildFn)(_value)
//   }, [])

//   return <>{renderChild()}</>
// }

const ModalContainer = styled('div')`
  width: 100vw;
  max-width: 55rem;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  outline: none;
`
const ModalHeader = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.primary,
  height: '3.5rem',
  color: 'white',
  fontSize: '24px',
  fontWeight: 'bold',
  padding: '0 2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const ModalBody = styled('div')`
  flex-grow: 1;
  padding: 2rem;
`
const ModalFooter = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.colors.gray8};
  margin: 0 2rem;
  padding: 1rem 0;
`

const CloseIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" cursor="pointer">
    <path
      d="M22.7825 0.75L13 10.5325L3.2175 0.75L0.75 3.2175L10.5325 13L0.75 22.7825L3.2175 25.25L13 15.4675L22.7825 25.25L25.25 22.7825L15.4675 13L25.25 3.2175L22.7825 0.75Z"
      fill="#fff"
    />
  </svg>
)

const MenuTopicList = [
  {
    key: 'INCOME',
    label: 'Income',
  },
  {
    key: 'EXPENSE',
    label: 'Expense',
  },
  {
    key: 'FUND',
    label: 'Fund',
  },
  {
    key: 'AUTO',
    label: 'Auto',
  },
]
