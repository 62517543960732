import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import allAction from '../../state/actions'
import styles from './index.module.scss'

//AssignrdCard
import SearchIcon from '@mui/icons-material/Search'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import AssignedCard from './AssignedCard'

// Components
import {
  ButtonComponent,
  InputPasswordComponent,
  InputSelectComponent,
  InputTextComponent,
  ProfileUpload,
} from '../../component'
//Modal
import ModalForgetPassword from './ModalForgetPassword'

const UserManagementNewEdit: React.FC = (): JSX.Element => {
  //ChangePassword
  const [isChangePassword, setIsChangePassword] = useState(false)
  //Modal
  const [openModalForgetPassword, setOpenModalForgetPassword] = useState(false)
  //AssignedCArd
  const [searchModule, setSearchModule] = useState('')
  const searchChange = (event: any) => {
    setSearchModule(event.target.value)
  }
  const validationSchema = yup.object({
    username: yup.string().required('กรอกชื่อผู้ใช้'),
    password: yup.string().required('กรอกรหัสผ่าน'),
    email: yup.string().email('กรอก Email').required('กรอก Email'),
    employeeid: yup.string().required('กรอกรหัสพนักงาน'),
    realName: yup.string().required('กรอกชื่อ'),
    lastName: yup.string().required('กรอกนามสกุล'),
    role: yup.string().required('ระบุสิทธิ์'),
    department: yup.string().required('กรอกชื่อแผนก'),
    position: yup.string().required('กรอกชื่อตำแหน่งงาน'),
    team: yup.string().required('กรอกชื่อทีม'),
  })
  const [image, setImage] = useState('')
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  useEffect(() => {
    if (id) {
      setMode('update')
    } else {
      setMode('create')
    }
  }, [])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <>
      <ModalForgetPassword
        topic="กู้คืนรหัสผ่านใหม่"
        openModalForgetPassword={openModalForgetPassword}
        setOpenModalForgetPassword={setOpenModalForgetPassword}
      />
      <Formik
        initialValues={{
          username: '',
          password: '',
          employeeid: '',
          realName: '',
          lastName: '',
          email: '',
          role: '',
          department: '',
          position: '',
          team: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2))
            actions.setSubmitting(false)
          }, 1000)
          dispatch(
            allAction.modalConfirmAction.setOpenModalConfirm(
              'ยืนยันการทำรายการ',
              '',
              'คุณต้องการยืนยันการทำรายของ UserID?',
              'ใช่, ทำรายการ',
              () => {
                dispatch(allAction.modalAction.setOpenModalAlertStatus('ยืนยันการแก้ไขเรียบร้อย', '', 'success'))
                dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
              },
            ),
          )
        }}
      >
        {(props: any) => (
          <form onSubmit={props.handleSubmit}>
            <div className={styles.container}>
              <section className={styles.topicPageWrapper}>
                <p className={styles.topicPage}>{mode === 'create' ? 'สร้างผู้ใช้งาน' : 'แก้ไขผู้ใช้งาน'}</p>
              </section>
              <div className={styles.contentContainer}>
                <section className={styles.cardDetail}>
                  <section className={styles.profileUpload}>
                    {/* <ProfileUpload image={image} setImage={setImage} /> */}
                  </section>
                  <section className={styles.inputContainer}>
                    <InputTextComponent _name="username" _label="username" _helperText={props.errors.username} />

                    {mode === 'update' && isChangePassword === false ? (
                      <div>
                        <p>Password</p>
                        <button
                          type="button"
                          className={styles.btnChangePassword}
                          onClick={() => setIsChangePassword(true)}
                        >
                          <p>เปลี่ยนรหัสผ่าน</p>
                        </button>
                      </div>
                    ) : mode === 'update' && isChangePassword === true ? (
                      <>
                        <br />
                        <InputTextComponent
                          _name="oldPassword"
                          _label="รหัสผ่านปัจจุบัน"
                          _helperText={props.errors.oldPassword}
                        />
                        <div className={styles.btnWrapperEnd}>
                          <button
                            type="button"
                            className={styles.btnChangePassword}
                            onClick={() => setOpenModalForgetPassword(true)}
                          >
                            <p>ลืมรหัสผ่าน?</p>
                          </button>
                        </div>
                        <InputTextComponent
                          _name="newPassword"
                          _label="รหัสผ่านใหม่"
                          _helperText={props.errors.newPassword}
                        />{' '}
                        <InputTextComponent
                          _name="confirmPassword"
                          _label="ยืนยันรหัสผ่าน"
                          _helperText={props.errors.confirmPassword}
                        />
                        <br />
                      </>
                    ) : (
                      <>
                        <InputPasswordComponent
                          _name="password"
                          _label="password"
                          _helperText={props.errors.password}
                        />
                        {/* <InputRadioComponent
                              _name="radio"
                              _menuList={[
                                { label: 'สุ่มรหัสผ่านอัตโนมัติ', value: 'สุ่มรหัสผ่านอัตโนมัติ' },
                                { label: 'ตั้งค่ารหัสผ่านเอง', value: 'ตั้งค่ารหัสผ่านเอง' },
                              ]}
                            /> */}
                      </>
                    )}

                    <InputTextComponent _name="employeeid" _label="รหัสพนักงาน" _helperText={props.errors.employeeid} />

                    <article className={styles.rowInputWrapper}>
                      <InputTextComponent _name="realName" _label="ชื่อ" _helperText={props.errors.realName} />
                      <InputTextComponent _name="lastName" _label="นามสกุล" _helperText={props.errors.lastName} />
                    </article>
                    <InputTextComponent _name="email" _label="Email" _helperText={props.errors.email} />
                    <InputSelectComponent
                      _name="role"
                      _label="Role"
                      _helperText={props.errors.selected}
                      _menuList={[
                        { label: 'Admin', value: 'Admin' },
                        { label: 'Editer', value: 'Editer' },
                        { label: 'Employee', value: 'Employee' },
                        { label: 'User', value: 'User' },
                      ]}
                    />
                    <InputSelectComponent
                      _name="department"
                      _label="ชื่อแผนก"
                      _helperText={props.errors.department}
                      _menuList={[
                        { label: 'e.g. Product', value: 'e.g. Product' },
                        { label: 'Team B', value: 'Team B' },
                        { label: 'Team C', value: 'Team C' },
                        { label: 'Team D', value: 'Team D' },
                      ]}
                    />
                    <InputSelectComponent
                      _name="position"
                      _label="ชื่อตำแหน่งงาน"
                      _helperText={props.errors.position}
                      _menuList={[
                        { label: 'FrontEnd Devaloper', value: 'FrontEnd Devaloper' },
                        { label: 'BackEnd Devaloper', value: 'BackEnd Devaloper' },
                        { label: 'UX/UI', value: 'UX/UI' },
                        { label: 'Project Manager', value: 'Project Manager' },
                        { label: 'e.g. Product manager', value: 'e.g. Product manager' },
                      ]}
                    />
                    <InputSelectComponent
                      _name="team"
                      _label="ชื่อทีม"
                      _helperText={props.errors.department}
                      _menuList={[
                        { label: 'Team A', value: 'Team A' },
                        { label: 'Team B', value: 'Team B' },
                        { label: 'Team C', value: 'Team C' },
                        { label: 'Team D', value: 'Team D' },
                      ]}
                    />
                  </section>
                </section>
                <section className={styles.cardAssigned}>
                  <div className={styles.assignedContainer}>
                    <section>
                      <article className={styles.topicAssignedWrapper}>
                        <p className={styles.textTopic}>Assigned </p>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-search" sx={{ fontFamily: 'KanitRegular' }}>
                            ค้นหาโมดูล
                          </InputLabel>
                          <OutlinedInput
                            size="small"
                            id="outlined-adornment-search"
                            value={searchModule}
                            onChange={searchChange}
                            startAdornment={<SearchIcon />}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            label="ค้นหาโมดูล"
                          />
                        </FormControl>
                      </article>
                      <article className={styles.columnGapWrapper}>
                        {assignedData.map((assignedData: any, index: number) => {
                          return (
                            <div key={index}>
                              <AssignedCard isSearch={searchModule} topic="Assigned" data={assignedData.assigned} />
                              <AssignedCard
                                isSearch={searchModule}
                                topic="Not Assigned"
                                data={assignedData.notAssigned}
                              />
                            </div>
                          )
                        })}
                      </article>
                    </section>
                  </div>
                </section>
              </div>
              <footer className={styles.btnFooterWrapper}>
                <div className={styles.btnFooter}>
                  <ButtonComponent
                    _variant="text"
                    _text="Cancle"
                    _colorText="red"
                    _colorBG="white"
                    _type="button"
                    _functionOnClick={() => navigate(-1)}
                  />
                </div>
                <div className={styles.btnFooter}>
                  <ButtonComponent
                    _variant="contained"
                    _text="Confirm"
                    _colorText="white"
                    _colorBG="blue"
                    _type="submit"
                  />
                </div>
              </footer>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}
export default UserManagementNewEdit

const assignedData = [
  {
    assigned: [
      { title: 'Human Resource ', subtitle: 'All 9 function assigned ' },
      { title: 'Inventory (ระบบคลังสินค้า)  ', subtitle: 'All 9 function assigned ' },
    ],
    notAssigned: [
      { title: 'Human Resource ', subtitle: 'All 9 function assigned ' },
      { title: 'Inventory (ระบบคลังสินค้า) ', subtitle: 'All 9 function assigned ' },
    ],
  },
]
// const assignedData = [{ title: 'Human Resource ', subtitle: 'All 9 function assigned ' }]
// const notAssignedData = [
//   { title: 'human resource (ระบบบริหารทรัพยากรบุคคล) ', subtitle: '' },
//   { title: 'Inventory (ระบบคลังสินค้า) ', subtitle: '' },
// ]

// console.log('lll', notAssignedData.length)
