import React, { createContext, FC, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Calendar, DateHeaderProps, EventProps, momentLocalizer, NavigateAction, View } from 'react-big-calendar'
import moment from 'moment'
import * as yup from 'yup'
import { format } from 'date-fns'
import {
  calendarTypeColor,
  classes,
  combineList,
  dateFmtShort,
  dateRange,
  dateToTime,
  deepClone,
  ECalendarEventType,
  ECalendarType,
  getThaiDate,
  isAfterShift,
  isBeforeShift,
  toTime,
} from '../../../utils/common'
import {
  Grid,
  Popper,
  Stack,
  Modal,
  AvatarGroup,
  Avatar,
  Tooltip,
  Divider,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material'
import styles from './index.module.scss'
import Switch from '../../../component/Switch'
import CloseIcon from '../../../image/closeGray.svg'
import DelIcon from '../../../image/delBadege.svg'
import infiniteSVG from '../../../image/infinite.svg'
import NextIcon from '../../../image/nex.svg'
import { ButtonComponent, ButtonSelectCustom, ProgessBarComponent } from '../../../component'
import { ELeave, ELeavForm, SaveType, SaveTypeEmployee } from '../../AttendanceReport/EType'
import TimeIcon from '../../../image/clock.svg'
import {
  InputDateComponentCustome,
  InputSelectComponentCustom,
  InputTextComponentCustom,
  InputTimeComponentCustom,
} from '../../../component/InputsFormik'
import { Formik, useFormikContext } from 'formik'
import AdddUserIcon from '../../../image/addEmp.svg'
import {
  CreateLeaveDocument,
  CreateOvertimeDocument,
  DelRequestLeave,
  DelRequestOvertime,
  QueryEmployeeList,
  queryListShift,
  ResetAttendanceReport,
  UpdateAttendanceReport,
  useQueryShareCalendar,
} from '../../../adapter/xhr'
import { useDispatch, useSelector } from 'react-redux'
import allAction from '../../../state/actions'
import { activeEmployeeFilter } from 'src/adapter/xhr/query'
import swal from 'sweetalert'
import { useQueryListLeaveList } from 'src/adapter/api'
import Swal from 'sweetalert2'
import { EStatus } from 'src/utils/generated'
import { gql, useMutation } from '@apollo/client'
import { mutationCreateLeaveDocument } from 'src/adapter/xhr/mutation'
import useMyLocation from 'src/hooks/useMyLocation'
import { ICombineReducers } from 'src/state/reducers'
import { useGetEmployee } from 'src/adapter/api/employee'
import LeaveSelect from 'src/component/LeaveSelect'

const localizer = momentLocalizer(moment)
type DateClickHandler = (date: Date, anchorEl: null | HTMLElement) => void

const Context = createContext<{ events?: CalendarEvent[]; onDateClick?: DateClickHandler }>({})

interface Props {
  events: CalendarEvent[]
  eventData: any[]
  onNavigate?: (newDate: Date, view: View, action: NavigateAction) => void
  loading?: any
  data?: any
}
interface CalendarEvent {
  title: string
  start: Date
  end: Date
  type: ECalendarEventType
  date: string
  isHoliday: boolean
  holidayDescription: string
}
const CalendarEventComponent = (props: EventProps<CalendarEvent>) => {
  const { data } = useQueryListLeaveList({})
  const LeaveData = data.map((ele: any) => ele)
  const Data = LeaveData.push(
    { id: 'ABSENT', name: 'ขาด' },
    { id: 'WORK', name: 'ล่วงเวลา' },
    { id: 'OVERTIME', name: 'ล่วงเวลา' },
    { id: 'LATE', name: 'มาสาย' },
  )
  return <div>{LeaveData.find((ele: any) => ele.id === props.event.title)?.name}</div>
}

const CalendarDateComponent = (props: DateHeaderProps) => {
  const { adminOrEmployee } = useMyLocation()
  const context = useContext(Context)
  const currentDate = new Date()
  const isCurrentDate = props.date.toLocaleDateString() === currentDate.toLocaleDateString()
  const holiday = context?.events?.find(
    (e) => new Date(e.date).toLocaleDateString() === props.date.toLocaleDateString(),
  )

  const getDateLabel = useMemo(() => {
    return Number(props.label)
  }, [props.label])
  return (
    <div>
      <style lang="scss">{`
        .circleDate {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          padding: 10px;
          border-radius: 50%;
          cursor: pointer;
          margin: 0.5rem;
          font-size: 21px;
        }

        .circleDate:hover {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          padding: 10px;
          border-radius: 50%;
          cursor: pointer;
          margin: 0.5rem;
          font-size: 21px;
          background-color: #CAD6EA;


        }

        .currentDate {
          background-color: rgba(247, 175, 29, 1) !important;
          color: #fff;
        }

        .holidayDate {
          background-color: rgba(83, 87, 187, 1);
          color: #fff;
          border: 4px solid rgba(83, 87, 187, 1);
        }
      `}</style>
      <Tooltip title={holiday?.holidayDescription || ''} placement="top" arrow>
        <div
          className={classes('circleDate', isCurrentDate && 'currentDate', holiday?.isHoliday && 'holidayDate')}
          onClick={(e) => {
            context.onDateClick?.call(null, props.date, e.currentTarget)
          }}
        >
          {getDateLabel}
        </div>
      </Tooltip>
    </div>
  )
}

export interface CalendarRef {
  handleNextMonth: () => Date
  handlePreviousMonth: () => Date
  handleToday: () => Date
  handleSelect: (date: Date) => Date
}

const BigCalendar = (props: Props, ref: any) => {
  const { data } = useQueryListLeaveList({})
  const currentDate = new Date()
  const [calendarDate, setCalendarDate] = useState<Date>(currentDate)
  const [showPopepr, setShowPopper] = useState<boolean>(false)
  const [openPopperDate, setOpenPopperDate] = useState<Date>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<{ events: any[]; date: Date }>({ events: [], date: calendarDate })

  const onDateClick: DateClickHandler = (date, anchorEl) => {
    const montCalendar = calendarDate.getMonth()
    const monthCurrent = new Date(date).getMonth()
    if (monthCurrent !== montCalendar) return
    else {
      setAnchorEl(anchorEl)
      setShowPopper(openPopperDate?.toLocaleDateString() !== date.toLocaleDateString() || !showPopepr)
      const _date = new Date(date)
      _date.setHours(7)
      setOpenPopperDate(_date)
    }
  }

  const handleChangeMonth = (month: number) => {
    const newDate = new Date(calendarDate.setMonth(month))
    return newDate
  }
  useImperativeHandle(ref, () => ({
    handleNextMonth: () => {
      const newDate = handleChangeMonth(calendarDate.getMonth() + 1)
      setCalendarDate(newDate)
      return newDate
    },
    handlePreviousMonth: () => {
      const newDate = handleChangeMonth(calendarDate.getMonth() - 1)
      setCalendarDate(newDate)
      return newDate
    },
    handleToday: () => {
      setCalendarDate(currentDate)
      return currentDate
    },
    handleSelect: (date: Date) => {
      setCalendarDate(date)
      return date
    },
  }))

  const events = useMemo(() => {
    return props.events
  }, [props.events])

  const getEventType = (type: ECalendarEventType): ECalendarType => {
    if (data.map((item: any) => item.id).includes(type)) return 'LEAVE'
    else return type as ECalendarType
  }

  const handleOpenModal = (event: CalendarEvent) => {
    setModalData({
      date: new Date(event.date),
      events: props.eventData
        .find((e) => e.date === event.date)
        .events.map((e: any) => {
          if (e.Type === 'LATE') {
            return {
              title: e.Type,
              type: e.event.leaveType || e.Type,
              date: new Date(event.date),
              start: new Date('2022-09-07T01:00:00.000Z'),
              end: new Date('2022-09-07T10:00:00.000Z'),
              employee: e,
            }
          } else if (e.Type === 'OVERTIME') {
            return {
              title: e.Type,
              type: e.event.leaveType || e.Type,
              date: new Date(event.date),
              start: new Date(e.event.overtimeApproveList[0].startedAt),
              end: new Date(e.event.overtimeApproveList[0].endedAt),
              employee: e,
            }
          } else
            return {
              title: e.Type,
              type: e.event.leaveType || e.Type,
              date: new Date(event.date),
              start: new Date(e.event.startedAt),
              end: new Date(e.event.endedAt),
              employee: e,
            }
        }),
    })
    setShowModal(true)
  }
  const msg = {
    showMore: (total: any) => `อีก ${total} รายการ`,
  }
  // const [arrowRef, setArrowRef] = useState<any>(null)
  // const buttonRef = useRef(null)
  // const popperRef = useRef(null)

  return (
    <Context.Provider value={{ events, onDateClick }}>
      <style lang="scss">{`
        .rbc-show-more {
          font-weight: lighter;
          color: rgba(187, 187, 187, 1);
          padding-top: 10px
        }
        `}</style>
      {props.loading === true ? (
        <div className={styles.ContainerLoad}>
          <ProgessBarComponent />
        </div>
      ) : (
        <Calendar
          selectable={true}
          view="month"
          style={{
            position: 'relative',
            height: '90vh',
            // margin: '0 30px 0 30px',
          }}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={['month']}
          toolbar={false}
          messages={msg}
          components={{
            event: CalendarEventComponent,
            month: { dateHeader: CalendarDateComponent },
          }}
          dayPropGetter={(date) => {
            const style: React.CSSProperties = { backgroundColor: 'rgba(255, 255, 255, 1)' }
            if ([0, 6].includes(date.getDay())) {
              style.backgroundColor = 'rgba(232, 232, 232, 1)'
            }
            return {
              style,
            }
          }}
          date={calendarDate}
          onSelectEvent={handleOpenModal}
          eventPropGetter={(event) => {
            const style: React.HTMLAttributes<HTMLDivElement>['style'] = {
              width: 'min-content',
              fontSize: '12px',
              marginLeft: '9px',
            }
            switch (getEventType(event.type)) {
              case 'LEAVE':
                style.backgroundColor = 'rgba(255, 150, 27, 0.1)'
                style.color = 'rgba(255, 159, 45, 1)'
                break
              case 'OVERTIME':
                style.backgroundColor = 'rgba(232, 232, 232, 1)'
                style.color = 'rgba(70, 69, 69, 1)'
                break
              case 'ABSENT':
                style.backgroundColor = 'rgba(224, 19, 34, 0.15)'
                style.color = 'rgba(224, 19, 34, 1)'
                break
              case 'LATE':
                style.backgroundColor = 'rgba(12, 108, 255, 0.15)'
                style.color = 'rrgba(12, 108, 255, 1)'
                break
            }
            return {
              style,
            }
          }}
        />
      )}

      <Popper
        open={showPopepr}
        anchorEl={anchorEl}
        disablePortal={false}
        placement="right"
        className={styles.calendarPopper}
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
          },
        ]}
      >
        <Box>
          <CalendarPopper
            calendarDate={calendarDate}
            popperDate={openPopperDate}
            showPopepr={showPopepr}
            setShowPopper={setShowPopper}
            eventData={props.eventData}
          />
        </Box>
      </Popper>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <CalendarModal {...modalData} open={showModal} setShowModal={setShowModal} leaveConfig={data} />
      </Modal>
    </Context.Provider>
  )
}

//Modal Onclick digit date

const CalendarPopper = (props: any) => {
  console.log(props, 'props')
  const { refetch } = useQueryShareCalendar(null, true)
  const { decodedAccessToken } = useSelector((state: ICombineReducers) => state.loginReducer)
  const userId = decodedAccessToken.userID
  const { adminOrEmployee } = useMyLocation()
  const filterEmployee = {
    isAll: false,
    isAbsent: true,
    isLate: true,
    isLeave: true,
    isOvertime: true,
  }
  const filterAdmin = {
    isAll: true,
    isAbsent: true,
    isLate: true,
    isLeave: true,
    isOvertime: true,
  }
  const filterItem = adminOrEmployee === 'ADMIN' ? filterAdmin : filterEmployee

  const filter = {
    input: {
      year: props.calendarDate.getFullYear(),
      month: props.calendarDate.getMonth() + 1,
      filter: filterItem,
    },
  }

  const dispatch = useDispatch()
  const [userList, setUserlist] = useState<any>([])
  const { data } = useQueryListLeaveList({})
  useEffect(() => {
    console.log(userList, 'userList')
  }, [userList])
  const [popEmployee, setPopEmployee] = useState(false)
  const [anchorPop, setAnchorPop] = useState<HTMLElement | null>()
  const [status, setStatus] = React.useState('PENDING')
  const [typeleave, setTypeLeave] = React.useState('')
  const [employee, setEmployeeList] = React.useState<any>([])
  const [listShift, setListShift] = React.useState<any>([])
  const [shiftData, setShiftData] = useState<any>()
  const [breakData, setBreakData] = useState<any>()
  const [leaveForm, setLeaveForm] = useState('')
  const [value, setValues] = useState<any>('')

  const formikRef: any = useRef()

  useEffect(() => {
    QueryEmployeeList({
      year: '2022',
      filter: {
        status: {
          eq: EStatus.Active,
        },
      },
    }).then((res: any) => {
      setEmployeeList(res.data.data.listEmployee)
    })
  }, [])

  useEffect(() => {
    queryListShift().then((res: any) => {
      setListShift(res.data.data.listShift)
      formikRef.current.setFieldValue('shift', res.data.data.listShift.getDefaultShift)
    })
  }, [])

  const day = new Date(props.popperDate).getDay()

  const GetTimeLeaveShift = (id: string) => {
    if (id) {
      const dataBreak = listShift?.items?.find((items: any) => items.shiftID === id).breakPolicy
      const dataShift = listShift?.items?.find((items: any) => items.shiftID === id).workDayPolicy
      const dataBreakTime = dataBreak?.find((item: any) => item.dayList.includes(day))
      const dataShiftTime = dataShift?.find((item: any) => item.dayList.includes(day))
      setShiftData(dataShiftTime)
      setBreakData(dataBreakTime)
    }
  }
  const SetIntervalsLeave = (leaveForm: any, formik: any) => {
    if (leaveForm === 'FULLDAY_LEAVE') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', dateFmtShort(props.popperDate))
      formik.setFieldValue('endedAt', dateFmtShort(props.popperDate))
    } else if (leaveForm == 'HALFDAY_MORNING_LEAVE') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', shiftData.timeList[0].startTime)
      formik.setFieldValue('endedAt', breakData.timeList[0].startTime)
    } else if (leaveForm == 'HALFDAY_AFTERNOON_LEAVE') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', breakData.timeList[0].endTime)
      formik.setFieldValue('endedAt', shiftData.timeList[0].endTime)
    } else if (leaveForm == 'CUSTOM_TIME') {
      formik.setFieldValue('leaveForm', leaveForm)
      formik.setFieldValue('startedAt', '')
      formik.setFieldValue('endedAt', '')
    }

    return leaveForm
  }
  useEffect(() => {
    SetIntervalsLeave(leaveForm, value)
  }, [leaveForm, value])

  const CheckHoliday = props.eventData.find((item: any) => item.date === dateFmtShort(props.popperDate)).isHoliday

  /*ListShift Select Items*/
  const ListShift = listShift.items?.map((ele: any) => ({ label: ele.shiftName, value: ele.shiftID }))

  /*LeaveType ITEMS */
  const LeaveListConfig = data.filter((ele) => ele.isActive === true).map((ele) => ({ label: ele.name, value: ele.id }))

  const years = new Date().getFullYear()
  const { data: dataEMP } = useGetEmployee({
    getEmployeeId: userId,
    year: `${years}`,
    listWelfareQuotaYear2: `${years}`,
  })

  console.log(data, 'LeaveConfig')
  useEffect(() => {
    console.log(typeleave, 'typeleave')
  }, [typeleave])

  const LeaveFormList = [
    {
      label: 'กำหนดเอง',
      value: 'CUSTOM_TIME',
    },
    {
      label: 'ลาเต็มวัน',
      value: 'FULLDAY_LEAVE',
    },
    {
      label: 'ลาครึ่งวันเช้า',
      value: 'HALFDAY_MORNING_LEAVE',
    },
    {
      label: 'ลาครึ่งวันบ่าย',
      value: 'HALFDAY_AFTERNOON_LEAVE',
    },
  ]

  const EType: any = {
    Leave: 'การลา',
    Overtime: 'การทำงานล่วงเวลา',
  }
  const OTList = [
    { label: 'วันทำงาน', value: 'WORK' },
    { label: 'ทำงานล่วงเวลา', value: 'OVERTIME' },
  ]
  const statusType = (e: any) => {
    setStatus(e)
  }

  /*Validate Yup*/
  const validationSchema = yup.object({
    leaveType: yup.string().required('เลือกประเภทการลา'),
    startedAt: yup.string().required('กรอกเวลาเริ่มต้น'),
    // .test(
    //   'start_time_test',
    //   `ไม่อยู่ในช่วงเวลา Shift (${shiftData?.timeList[0].startTime} - ${shiftData?.timeList[0].endTime}) `,
    //   function (value) {
    //     return isBeforeShift(value, shiftData?.timeList[0].startTime)
    //   },
    // ),
    endedAt: yup.string().required('กรอกเวลาสิ้นสุด'),
    // .test(
    //   'start_time_test',
    //   `ไม่อยู่ในช่วงเวลา Shift (${shiftData?.timeList[0].startTime} - ${shiftData?.timeList[0].endTime}) `,
    //   function (value) {
    //     return isAfterShift(value, shiftData?.timeList[0].endTime)
    //   },
    // ),
  })

  const handleClick = (event: any) => {
    setAnchorPop(event.currentTarget)
    setPopEmployee((previousOpen) => !previousOpen)
  }
  const otherprops = props

  const [CreateLeave, { error }] = useMutation(gql(mutationCreateLeaveDocument))

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: error.name,
        html: `${error.message} `,
        customClass: {
          container: 'swal',
        },
      })
    }
  }, [error])

  return (
    <div className={styles.PopperDocument}>
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        initialValues={{
          leaveType: '',
          startedAt: '',
          endedAt: '',
          leaveDocumentAttachment: '',
          isLeaveDeductMoney: false,
          status: '',
          leaveForm: '',
          type: 'Leave',
          overtimeType: '',
          search: '',
          shift: '',
        }}
        onSubmit={(values) => {
          if (adminOrEmployee === 'ADMIN' && userList.length === 0) {
            Swal.fire({
              icon: 'warning',
              title: 'กรุณาเลือกพนักงานที่ต้องการสร้างเอกสาร',
            })
          } else {
            Swal.fire({
              title: `บันทึกข้อมูล${EType[`${values.type}`]}`,
              html: `วันที่ ${dateFmtShort(props.popperDate)} <br>  จำนวนพนักงาน ${userList.length} คน`,
              showCancelButton: true,
              cancelButtonText: 'ยกเลิก',
              confirmButtonColor: '#3080f8',
              confirmButtonText: 'ตกลง',
              reverseButtons: true,
              customClass: {
                container: 'swal',
              },
            }).then((leave) => {
              if (leave.isConfirmed) {
                if (values.type === 'Leave') {
                  const LeaveInput: any = {
                    input: {
                      date: dateFmtShort(props.popperDate),
                      employeeID: '',
                      leaveType: values.leaveType,
                      leaveForm: values.leaveForm,
                      startedAt: values.startedAt,
                      endedAt: values.endedAt,
                      isLeaveDeductMoney: typeleave === 'leave' ? false : true,
                      status: status,
                    },
                  }
                  if (adminOrEmployee === 'EMPLOYEE') {
                    const LeaveInputEmployee = deepClone(LeaveInput)
                    LeaveInputEmployee.input.employeeID = userId
                    const Leavelist: any[] = []
                    const LeaveDeduct: any[] = []
                    CreateLeave({ variables: LeaveInputEmployee }).then((res: any) => {
                      console.log(res, 'Create LEAVE')
                      const data = res.data.createLeaveRequest
                      if (data.isLeaveDeductMoney === false) {
                        Leavelist.push({
                          leaveType: data.leaveType,
                          startedAt: new Date(data.startedAt).toISOString(),
                          endedAt: new Date(data.endedAt).toISOString(),
                          status: data.status,
                          leaveForm: data.leaveForm,
                          leaveRequestID: data.id,
                          leaveMinute: data.leaveMinute,
                        })
                      } else {
                        LeaveDeduct.push({
                          leaveType: data.leaveType,
                          startedAt: new Date(data.startedAt).toISOString(),
                          endedAt: new Date(data.endedAt).toISOString(),
                          status: data.status,
                          leaveForm: data.leaveForm,
                          leaveRequestID: data.id,
                          leaveMinute: data.leaveMinute,
                        })
                      }
                      const UpdateAttendanceVariables = {
                        input: {
                          employeeID: userId,
                          date: dateFmtShort(props.popperDate),
                          leaveDeductList: LeaveDeduct,
                          leaveList: Leavelist,
                        },
                      }
                      const leaveList = combineList(Leavelist, LeaveDeduct)
                      if (leaveList.find((ele: any) => ele.leaveForm === 'FULLDAY_LEAVE')) {
                        const startDate = leaveList.find((ele: any) => ele.leaveForm === 'FULLDAY_LEAVE').startedAt
                        const EndDate = leaveList.find((ele: any) => ele.leaveForm === 'FULLDAY_LEAVE').endedAt
                        const dates = dateRange(dateFmtShort(startDate), dateFmtShort(EndDate))
                        console.log(dates, 'DATE')
                        for (let i = 0; i < dates.length; i++) {
                          const params: any = deepClone(UpdateAttendanceVariables)
                          params.input.date = dates[i]
                          UpdateAttendanceReport(params).then((res: any) => {
                            console.log(res, 'Update')
                          })
                        }
                      } else {
                        UpdateAttendanceReport(UpdateAttendanceVariables).then((res: any) => {
                          console.log(res, 'Update')
                        })
                      }
                      Swal.fire({
                        title: `บันทึกข้อมูล${EType[`${values.type}`]}เรียบร้อย`,
                        icon: 'success',
                        timer: 1500,
                        showConfirmButton: false,
                      })
                      refetch({
                        input: {
                          year: props.calendarDate.getFullYear(),
                          month: props.calendarDate.getMonth() + 1,
                          filter: filterItem,
                        },
                      })
                      otherprops.setShowPopper(false)
                    })
                  } else {
                    for (let i = 0; i < userList.length; i++) {
                      const Leavelist: any[] = []
                      const LeaveDeduct: any[] = []
                      const params: any = deepClone(LeaveInput)
                      params.input.employeeID = userList[i].id
                      CreateLeave({ variables: params }).then((res: any) => {
                        console.log(res, 'Create LEAVE')
                        const data = res.data.createLeaveRequest
                        console.log(data, 'data')
                        if (data.isLeaveDeductMoney === false) {
                          Leavelist.push({
                            leaveType: data.leaveType,
                            startedAt: new Date(data.startedAt).toISOString(),
                            endedAt: new Date(data.endedAt).toISOString(),
                            status: data.status,
                            leaveForm: data.leaveForm,
                            leaveRequestID: data.id,
                            leaveMinute: data.leaveMinute,
                          })
                        } else {
                          LeaveDeduct.push({
                            leaveType: data.leaveType,
                            startedAt: new Date(data.startedAt).toISOString(),
                            endedAt: new Date(data.endedAt).toISOString(),
                            status: data.status,
                            leaveForm: data.leaveForm,
                            leaveRequestID: data.id,
                            leaveMinute: data.leaveMinute,
                          })
                        }
                        const UpdateAttendanceVariables = {
                          input: {
                            employeeID: userList[i].id,
                            date: dateFmtShort(props.popperDate),
                            leaveDeductList: LeaveDeduct,
                            leaveList: Leavelist,
                          },
                        }
                        const leaveList = combineList(Leavelist, LeaveDeduct)
                        if (leaveList.find((ele: any) => ele.leaveForm === 'FULLDAY_LEAVE')) {
                          const startDate = leaveList.find((ele: any) => ele.leaveForm === 'FULLDAY_LEAVE').startedAt
                          const EndDate = leaveList.find((ele: any) => ele.leaveForm === 'FULLDAY_LEAVE').endedAt
                          const dates = dateRange(dateFmtShort(startDate), dateFmtShort(EndDate))
                          console.log(dates, 'DATE')
                          for (let i = 0; i < dates.length; i++) {
                            const params: any = deepClone(UpdateAttendanceVariables)
                            params.input.date = dates[i]
                            UpdateAttendanceReport(params).then((res: any) => {
                              console.log(res, 'Update')
                            })
                          }
                        } else {
                          UpdateAttendanceReport(UpdateAttendanceVariables).then((res: any) => {
                            console.log(res, 'Update')
                          })
                        }
                        Swal.fire({
                          title: `บันทึกข้อมูล${EType[`${values.type}`]}เรียบร้อย`,
                          icon: 'success',
                          timer: 1500,
                          showConfirmButton: false,
                        })
                        otherprops.setShowPopper(false)
                      })
                      // 1. const doc = await mutation to main doc (params)
                      // 2. if (save and approve) then updateReport(doc.id)
                    }
                  }
                } else {
                  const OvertimeInput: any = {
                    input: {
                      date: new Date(props.popperDate).toISOString().slice(0, 10),
                      employeeID: '',
                      startedAt: values.startedAt,
                      endedAt: values.endedAt,
                      status: status,
                      overtimeType: values.overtimeType,
                    },
                  }
                  for (let i = 0; i < userList.length; i++) {
                    const params: any = deepClone(OvertimeInput)
                    params.input.employeeID = userList[i].id
                    const OvertimeList: any[] = []

                    CreateOvertimeDocument(params).then((res: any) => {
                      const data = res.data.data.createOvertimeRequest
                      OvertimeList.push({
                        startedAt: new Date(data.startedAt).toISOString(),
                        endedAt: new Date(data.endedAt).toISOString(),
                        status: data.status,
                        overtimeType: data.overtimeType,
                        overtimeRequestID: data.id,
                      })
                      const OvertimeInput: any = {
                        input: {
                          employeeID: userList[i].id,
                          date: new Date(props.popperDate).toISOString().slice(0, 10),
                          overtimeApproveList: OvertimeList,
                        },
                      }
                      ResetAttendanceReport({
                        input: {
                          employeeID: userList[i].id,
                          startDate: new Date(props.popperDate).toISOString().slice(0, 10),
                          endDate: new Date(props.popperDate).toISOString().slice(0, 10),
                        },
                      }).then((res: any) => {
                        console.log(res, 'res')
                      })
                      dispatch(
                        allAction.modalAction.setOpenModalAlertStatus(
                          'บันทึกข้อมูลการทำงานล่วงเวลาเรียบร้อย',
                          '',
                          'success',
                        ),
                      )
                      dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                      otherprops.setShowPopper(false)
                    })
                    // 1. const doc = await mutation to main doc (params)
                    // 2. if (save and approve) then updateReport(doc.id)
                  }
                }
              }
            })
          }
        }}
      >
        {(props: any) => (
          <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
            {GetTimeLeaveShift(props.values.shift)}
            <section className={styles.Header}>
              <div className={styles.button}>
                <ButtonComponent
                  _colorBG="red"
                  _colorText="white"
                  _text="ยกเลิก"
                  _type="button"
                  _variant="contained"
                  _functionOnClick={() => {
                    otherprops.setShowPopper(false)
                  }}
                />
              </div>
              <p>สร้างใหม่</p>
              <ButtonSelectCustom
                _text={'Save'}
                _selectData={adminOrEmployee === 'ADMIN' ? SaveType : SaveTypeEmployee}
                _onchange={(e: any) => {
                  statusType(e)
                }}
              />
            </section>
            {CheckHoliday === true ? (
              <section className={styles.filedInputFlex}>
                <p>สร้าง</p>
                <InputSelectComponentCustom
                  _label="สร้างรายการ"
                  _name="type"
                  _disabled
                  _value={'Overtime'}
                  _menuList={[
                    { label: 'การลา', value: 'Leave' },
                    { label: 'การทำงานล่วงเวลา', value: 'Overtime' },
                  ]}
                />
              </section>
            ) : (
              <section className={styles.filedInputFlex}>
                <p>สร้าง</p>
                <InputSelectComponentCustom
                  _label="สร้างรายการ"
                  _name="type"
                  _value={props.values.type}
                  _menuList={[
                    { label: 'การลา', value: 'Leave' },
                    { label: 'การทำงานล่วงเวลา', value: 'Overtime' },
                  ]}
                />
              </section>
            )}

            {props.values.type === 'Leave' && CheckHoliday === false ? (
              <div className={styles.FormGroup}>
                {adminOrEmployee === 'ADMIN' ? (
                  <div className={styles.filedInputFlex}>
                    <p>Shift</p>
                    <div className={styles.inputBox}>
                      <InputSelectComponentCustom
                        _name="shift"
                        _label="กะการทำงาน"
                        _menuList={ListShift}
                        _value={props.values.shift}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {adminOrEmployee === 'EMPLOYEE' ? (
                  <div className={styles.filedInputFlex}>
                    <p>ประเภทการลา</p>
                    <div className={styles.inputBox}>
                      <LeaveSelect name="leaveType" employee={dataEMP} />
                    </div>
                  </div>
                ) : (
                  <div className={styles.filedInputFlex}>
                    <p>ประเภทการลา</p>
                    <div className={styles.inputBox}>
                      <InputSelectComponentCustom
                        _name="leaveType"
                        _label={'ประเภทการลา'}
                        _menuList={LeaveListConfig}
                        _helperText={props.errors.leaveType}
                      />
                    </div>
                  </div>
                )}

                <div className={styles.toggle}>
                  <Switch
                    options={[
                      { label: 'ลาไม่หักเงิน', value: 'leave' },
                      { label: 'ลาหักเงิน', value: 'leaveDeduct' },
                    ]}
                    onChange={(value: any) => {
                      setTypeLeave(value)
                    }}
                  />
                </div>

                <div className={styles.filedInputFlex}>
                  <p>รูปแบบการลา</p>
                  <div className={styles.inputBox}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        shrink
                        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white', padding: '0px 5px' }}
                      >
                        {'รูปแบบการลา'}
                      </InputLabel>
                      <Select
                        id="leaveForm"
                        defaultValue={props.values.leaveForm}
                        sx={{ borderRadius: 1, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
                        value={props.values.leaveForm}
                        label={'รูปแบบการลา'}
                        onChange={(e: any) => {
                          setLeaveForm(e.target.value)
                          setValues(props)
                        }}
                        name="leaveForm"
                      >
                        {LeaveFormList.map((data: any, index: number) => (
                          <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
                            {data.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {props.values.leaveForm === 'FULLDAY_LEAVE' ? (
                  <div className={styles.filedInputTime}>
                    <p>ช่วงวันที่</p>
                    <div className={styles.GroupBox}>
                      <InputDateComponentCustome
                        _name="startedAt"
                        _label="วันที่เริ่ม"
                        _value={props.values.startedAt}
                      />
                      <InputDateComponentCustome _name="endedAt" _label="วันที่สิ้นสุด" _value={props.values.endedAt} />
                    </div>
                  </div>
                ) : (
                  <div className={styles.filedInputTime}>
                    <p>ช่วงเวลา</p>
                    <div className={styles.GroupBox}>
                      <InputTimeComponentCustom
                        _name="startedAt"
                        _label={'เริ่มต้น'}
                        _helperText={props.errors.startedAt}
                        _value={props.values.startedAt}
                        _disabled={
                          props.values.leaveForm === 'HALFDAY_AFTERNOON_LEAVE' ||
                          props.values.leaveForm === 'HALFDAY_MORNING_LEAVE'
                            ? true
                            : false
                        }
                      />
                      <InputTimeComponentCustom
                        _name="endedAt"
                        _label={'สิ้นสุด'}
                        _helperText={props.errors.endedAt}
                        _value={props.values.endedAt}
                        _disabled={
                          props.values.leaveForm === 'HALFDAY_AFTERNOON_LEAVE' ||
                          props.values.leaveForm === 'HALFDAY_MORNING_LEAVE'
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                )}

                <div className={styles.filedInputFlex}>
                  <p>วันที่ </p>
                  <div className={styles.inputBox}>
                    <InputDateComponentCustome
                      _name={'date'}
                      _value={dateFmtShort(otherprops.popperDate)}
                      _disabled={true}
                      _label={'วันที่'}
                    />
                  </div>
                </div>
                {adminOrEmployee === 'ADMIN' ? (
                  <div className={styles.filedInputFlex}>
                    <p>พนักงาน</p>
                    <div className={styles.Group}>
                      <AvatarGroup className={styles.allAvata} max={3}>
                        {userList.map((item: any, index: number) => {
                          return <Avatar key={index} alt={item.profileImage} src={item.profileImage} />
                        })}
                      </AvatarGroup>
                      <img
                        src={AdddUserIcon}
                        alt="AdddUserIcon"
                        className={props.values.shift ? styles.Icon : styles.IconDisabled}
                        onClick={(e: any) => handleClick(e)}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className={styles.FormGroup}>
                <div className={styles.filedInputFlex}>
                  <p>รูปแบบโอที</p>
                  <div className={styles.inputBox}>
                    <InputSelectComponentCustom
                      _name="overtimeType"
                      _label={'รูปแบบการทำงานล่วงเวลา'}
                      _placeholder="เลือกรูปแบบการทำงานล่วงเวลา"
                      _menuList={OTList}
                    />
                  </div>
                </div>

                <div className={styles.filedInputTime}>
                  <p>ช่วงเวลา</p>
                  <div className={styles.GroupBox}>
                    <div className={styles.InputTime}>
                      <InputTimeComponentCustom
                        _name="startedAt"
                        _label={'เริ่มต้น'}
                        _helperText={props.errors.startedAt}
                      />
                    </div>
                    <div className={styles.InputTime}>
                      <InputTimeComponentCustom _name="endedAt" _label={'สิ้นสุด'} _helperText={props.errors.endedAt} />
                    </div>
                  </div>
                </div>

                <div className={styles.filedInputFlex}>
                  <p>พนักงาน</p>
                  <div className={styles.Group}>
                    <AvatarGroup className={styles.allAvata} max={3}>
                      {userList.map((item: any, index: number) => {
                        return <Avatar key={index} alt={item.profileImage} src={item.profileImage} />
                      })}
                    </AvatarGroup>
                    <img src={AdddUserIcon} alt="" onClick={(e: any) => handleClick(e)} />
                  </div>
                </div>
              </div>
            )}
            <Popper open={popEmployee} anchorEl={anchorPop} placement="top" style={{ zIndex: '999999' }}>
              <PopperEmployeeList
                employee={employee}
                userList={userList}
                setUserlist={setUserlist}
                setPopEmployee={setPopEmployee}
                popEmployee={popEmployee}
                shiftID={props.values.shift}
                LeaveConfig={props.values.leaveType}
                leaveDeduct={typeleave}
              />
            </Popper>
          </form>
        )}
      </Formik>
    </div>
  )
}

// interface IPROPS {
//   employee: any
//   userList: any
//   setUserlist: any
// }

const PopperEmployeeList = (props: any) => {
  const { data } = useQueryListLeaveList({})

  const [leaveConfig, setLeaveConfig] = useState('')
  const { values } = useFormikContext<any>()
  const handelSelect = (e: any, id: any, item: any) => {
    const { checked } = e.target
    props.setUserlist([...props.userList, item])
    if (!checked) {
      props.setUserlist(props.userList.filter((item: any) => item.id !== id))
    }
  }

  const packValue = (valueList: any) => {
    const packVal = valueList.map((element: any) => {
      const value: any = {
        ...element,
        employeeName:
          (element?.fullName ? element?.fullName : null) + ' ' + (element?.lastName ? element?.lastName : null),
      }
      return value
    })
    return packVal
  }

  useEffect(() => {
    setLeaveConfig(props.LeaveConfig)
    console.log(props.LeaveConfig)
  }, [props.LeaveConfig])

  const employeeList = packValue(props.employee.items)
  const CheckQuota = (quota: number) => {
    if (isNaN(quota)) {
      return 0
    } else {
      return quota
    }
  }

  const GetQuota = (dataList: any) => {
    if (props.leaveDeduct === 'leave') {
      const balanceQaota = dataList.items?.find((ele: any) => ele.prev >= 0 && ele.leaveConfig.id === leaveConfig)
      return CheckQuota(balanceQaota?.begin - balanceQaota?.used)
    } else {
      const balanceQaota = dataList.items?.find((ele: any) => ele.prev < 0 && ele.leaveConfig.id === leaveConfig)
      return CheckQuota(balanceQaota?.begin - balanceQaota?.used)
    }
  }
  return (
    <div className={styles.PopperEmployee}>
      <div className={styles.header}>
        <div className={styles.group}>
          <section className={styles.AreaGroup}>
            <InputTextComponentCustom _name="search" _label="ค้นหาชื่อ - สกุล/รหัสพนักงาน" />
          </section>
        </div>
      </div>

      <div className={styles.GroupEmp}>
        {employeeList
          .filter((item: any) => {
            if (values.search || props.shiftID == '') {
              return item
            } else if (item.getCurrentShift.shiftID === props.shiftID) {
              return item
            }
            // } else if (
            //   item.employeeName.toLocaleLowerCase().includes(values.search.toLocaleLowerCase()) ||
            //   item.id.toLocaleLowerCase().includes(values.search.toLocaleLowerCase())
            // ) {
            //   return item
            // }
          })
          .map((itemUser: any, UserIndex: number) => (
            <section className={styles.EmpList} key={UserIndex}>
              <div className={styles.Form}>
                <div className={styles.groupAvatar}>
                  <Avatar src={itemUser.profileImage} className={styles.images} />
                  <div className={styles.Bade}>
                    {GetQuota(itemUser.listLeaveQuota) < 0 ? (
                      <img src={infiniteSVG} alt="infiniteSVG" width={15} height={15} />
                    ) : (
                      GetQuota(itemUser.listLeaveQuota)
                    )}
                  </div>
                </div>
                <div className={styles.GroupText}>
                  <p className={styles.text}>
                    {itemUser.fullName} {itemUser.lastName}
                  </p>
                  <p className={styles.textsub}>
                    คงเหลือ {''}
                    {GetQuota(itemUser.listLeaveQuota) < 0 ? 'ไม่จำกัด' : GetQuota(itemUser.listLeaveQuota)}
                    {''} วัน
                  </p>
                </div>
              </div>
              <Checkbox
                sx={{
                  color: '#5357BB',
                  '&.Mui-checked': {
                    color: '#5357BB',
                  },
                }}
                disabled={GetQuota(itemUser.listLeaveQuota) === 0 ? true : false}
                onChange={(e: any) => handelSelect(e, itemUser.id, itemUser)}
                checked={props.userList.map((item: any) => item.id).includes(itemUser.id)}
              />
            </section>
          ))}
      </div>
      <section className={styles.Footer}>
        <div className={styles.ButtonForm}>
          <ButtonComponent
            _colorBG="gray"
            _text="ยกเลิก"
            _colorText="white"
            _type="button"
            _variant="contained"
            _functionOnClick={() => {
              props.setPopEmployee(false)
              props.setUserlist([])
            }}
          />
          <ButtonComponent
            _colorBG="blue"
            _text="บันทึก"
            _colorText="white"
            _type="button"
            _variant="contained"
            _functionOnClick={() => props.setPopEmployee(false)}
          />
        </div>
      </section>
    </div>
  )
}
interface CalendarModalProps {
  events: any[]
  date: Date
  open?: boolean
  setShowModal?: any
  leaveConfig?: any
}

const CalendarModal: FC<CalendarModalProps> = (props: CalendarModalProps) => {
  const [Popepr, setPopper] = useState<boolean>(false)
  const [anchorElpop, setAnchorElpop] = useState<HTMLElement | null>()
  const [editeData, setEditData] = useState(false)
  const [dataEvent, setDataEvent] = useState<any>({})

  const CalendarThainame = (eventType: any) => {
    const LeaveConfigList: any[] = props.leaveConfig.map((ele: any) => ele.id)
    const LeaveData = props.leaveConfig.map((ele: any) => ele)
    const Data = LeaveData.push(
      { id: 'ABSENT', name: 'ขาด' },
      { id: 'WORK', name: 'ล่วงเวลา' },
      { id: 'OVERTIME', name: 'ล่วงเวลา' },
      { id: 'LATE', name: 'มาสาย' },
    )

    return {
      eventTypeThaiName: LeaveData.find((ele: any) => ele.id === eventType)?.name || 'null',
      calendarType: LeaveConfigList.includes(eventType) ? 'LEAVE' : eventType,
    }
  }

  const formats = {
    timeGutterFormat: (date: any, culture: any, localizer: any) => localizer.format(date, 'HH:mm น.', culture),
    eventTimeRangeFormat: (range: any) => {
      return `${format(range.start, 'HH:mm น.')} – ${format(range.end, 'HH:mm น.')}`
    },
  }

  const handleClick = (event: any) => {
    setAnchorElpop(event.currentTarget)
    setPopper((previousOpen) => !previousOpen)
  }
  const eventUnique = useMemo(() => {
    if (!props.events?.length) return []
    const uniqueMap: any = {}
    props.events.forEach((e: any) => {
      const key = `${e.type}|${e.start.toISOString()}|${e.end.toISOString()}`
      if (!uniqueMap[key]) uniqueMap[key] = []
      uniqueMap[key].push(e)
    })

    const _events: any[] = []

    Object.keys(uniqueMap).forEach((key) => {
      console.log(uniqueMap[key], 'KEY')
      const [_type, _start, _end] = key.split('|')
      const { eventTypeThaiName, calendarType } = CalendarThainame(_type as any)
      _events.push({
        title: eventTypeThaiName,
        type: calendarType,
        start: new Date(_start),
        end: new Date(_end),
        employeeList: uniqueMap[key].map((e: any) => e.employee),
      })
    })
    return _events
  }, [props.events])

  const emploeeEventList = useMemo(() => {
    if (!eventUnique?.length) return []

    const events = eventUnique.map((e: any) => {
      return {
        title: e.title,
        dateTimeTitle: `${dateToTime(e.start)} - ${dateToTime(e.end)}`,
        employeeList: e.employeeList,
      }
    })

    return events
  }, [eventUnique])

  emploeeEventList

  const sumEmployee = (type: string) => {
    const arr: any[] = []
    const sum = props.events.map((item: any) => {
      if (item.title === type) {
        return arr.push(item)
      }
    })

    return arr.length
  }

  const TypeDocument: any = {
    OVERTIME: 'การทำงานล่วงเวลา',
    LEAVE: 'การลา',
  }

  const customerEvent = (event: any) => {
    return (
      <Stack justifyContent="space-between" style={{ height: '100%', paddingBottom: 8 }}>
        <div>{event.event.title}</div>
        <AvatarGroup
          max={4}
          style={{ display: 'flex', justifyContent: 'start' }}
          sx={{
            '.MuiAvatarGroup-avatar': {
              width: 24,
              height: 24,
              fontSize: 12,
            },
          }}
        >
          {event.event.employeeList.map((employee: any) => {
            return (
              <Avatar
                sx={{ width: 24, height: 24 }}
                key={employee.employee.id}
                alt={employee.employee.fullName}
                src={employee.employee.profileImage}
              />
            )
          })}
        </AvatarGroup>
      </Stack>
    )
  }

  return (
    <div className={styles.calendarModalContainer}>
      <style lang="scss">{`
        .swal {
        z-index: 3000;
        }
        `}</style>
      <section className={styles.calendarEvent}>
        <Calendar
          localizer={localizer}
          eventPropGetter={(event: any) => {
            const style: React.HTMLAttributes<HTMLDivElement>['style'] = {}
            const { main, light } = calendarTypeColor[event.type as ECalendarType]
            style.backgroundColor = light
            style.color = main
            style.border = 'none'
            style.borderLeft = `3px solid ${main}`
            return {
              style,
            }
          }}
          components={{
            event: customerEvent,
          }}
          events={eventUnique}
          startAccessor="start"
          endAccessor="end"
          formats={formats}
          views={['day']}
          defaultView="day"
          toolbar={false}
          defaultDate={props.date}
        />
      </section>
      <section className={styles.eventListWrapper}>
        <section className={styles.HeadDate}>
          <div className={styles.GroupText}>
            <p>{getThaiDate(props.date).format('day date month year')}</p>
            <img src={CloseIcon} alt="" onClick={() => props.setShowModal(false)} style={{ cursor: 'pointer' }} />
          </div>
        </section>

        {!editeData ? (
          <p className={styles.textEdite} onClick={() => setEditData(!editeData)}>
            แก้ไขรายการ
          </p>
        ) : (
          <p className={styles.textEdite} onClick={() => setEditData(!editeData)}>
            เสร็จสิ้น
          </p>
        )}
        <div className={styles.Group}>
          {emploeeEventList.map((event: any, index: number) => {
            return (
              <Stack key={index} className={styles.GroupList}>
                {editeData ? (
                  <div>
                    <p className={styles.title}>{event.title}</p>
                    <p>{event.dateTimeTitle}</p>
                    <div className={styles.ListEdite}>
                      {event.employeeList.map((employee: any, employeeIndex: number) => {
                        return (
                          <div className={styles.GroupEmployee} key={employeeIndex}>
                            {console.log(employee, 'employee')}
                            <div className={styles.form}>
                              <div className={styles.Badge}>
                                {employee.event.status === 'APPROVE' ? (
                                  <div className={styles.pillStatus}>
                                    <p className={styles.text}>{employee.event.status}</p>
                                  </div>
                                ) : employee.event.status === 'PENDING' ? (
                                  <div className={styles.pillStatusPending}>
                                    <p className={styles.text}>{employee.event.status}</p>
                                  </div>
                                ) : (
                                  <>
                                    {employee.Type === 'ABSENT' ? (
                                      ''
                                    ) : (
                                      <img
                                        src={DelIcon}
                                        alt=""
                                        className={styles.badge}
                                        onClick={() => {
                                          Swal.fire({
                                            icon: 'warning',
                                            title: `ลบเอกสาร${TypeDocument[`${employee.Type}`]}`,
                                            html: `${employee.employee.fullName} ${employee.employee.lastName}`,
                                            showCancelButton: true,
                                            cancelButtonText: 'ยกเลิก',
                                            confirmButtonColor: '#d32f2f',
                                            confirmButtonText: 'ตกลง',
                                            reverseButtons: true,
                                            customClass: {
                                              container: 'swal',
                                            },
                                          }).then((del) => {
                                            if (del.isConfirmed) {
                                              if (employee.Type === 'OVERTIME') {
                                                DelRequestOvertime({
                                                  input: {
                                                    employeeID: employee.employeeID,
                                                    id: employee.event.id,
                                                  },
                                                }).then((res: any) => {
                                                  Swal.fire({
                                                    icon: 'success',
                                                    title: `ลบเอกสาร${TypeDocument[`${employee.Type}`]}เรียบร้อยแล้ว`,
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                    customClass: {
                                                      container: 'swal',
                                                    },
                                                  })
                                                  props.setShowModal(false)
                                                  ResetAttendanceReport({
                                                    input: {
                                                      employeeID: employee.employeeID,
                                                      startDate: dateFmtShort(props.date),
                                                      endDate: dateFmtShort(props.date),
                                                    },
                                                  }).then((res) => {
                                                    console.log(res, 'Reset')
                                                  })
                                                  console.log(res, 'res')
                                                })
                                              } else if (employee.Type === 'LEAVE') {
                                                DelRequestLeave({
                                                  input: {
                                                    employeeID: employee.employeeID,
                                                    id: employee.event.id,
                                                  },
                                                }).then((res: any) => {
                                                  Swal.fire({
                                                    icon: 'success',
                                                    title: `ลบเอกสาร${TypeDocument[`${employee.Type}`]}เรียบร้อยแล้ว`,
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                    customClass: {
                                                      container: 'swal',
                                                    },
                                                  })
                                                  props.setShowModal(false)
                                                  ResetAttendanceReport({
                                                    input: {
                                                      employeeID: employee.employeeID,
                                                      startDate: dateFmtShort(props.date),
                                                      endDate: dateFmtShort(props.date),
                                                    },
                                                  }).then((res) => {
                                                    console.log(res, 'Reset')
                                                  })
                                                  console.log(res, 'DelLeave')
                                                })
                                              }
                                            }
                                          })
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                                <Avatar
                                  alt={employee.employee.profileImage}
                                  src={employee.employee.profileImage}
                                  className={
                                    employee.event.status === 'APPROVE'
                                      ? styles.avatar
                                      : employee.event.status === 'PENDING'
                                      ? styles.avatarPending
                                      : ''
                                  }
                                />
                              </div>
                              <p className={styles.NameText}>
                                {employee.employee.fullName} {employee.employee.lastName}
                              </p>
                            </div>
                            <img
                              src={NextIcon}
                              alt=""
                              className={styles.Icon}
                              onClick={(e: any) => {
                                handleClick(e)
                                setDataEvent(employee)
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className={styles.title}>{event.title}</p>
                    <p>{event.dateTimeTitle}</p>
                    <AvatarGroup max={6} style={{ display: 'flex', justifyContent: 'start' }}>
                      {event.employeeList.map((employee: any) => {
                        return (
                          <Avatar
                            key={employee.id}
                            alt={employee.employee.profileImage}
                            src={employee.employee.profileImage}
                          />
                        )
                      })}
                    </AvatarGroup>
                  </div>
                )}
              </Stack>
            )
          })}
        </div>

        <section className={styles.bottomCard}>
          <Divider />
          <div className={styles.GroupData}>
            <p className={styles.txetHead}>ยอดรวมวันนี้</p>
            <div className={styles.GroupCard}>
              <div className={styles.Card}>
                <p className={styles.title}>{sumEmployee('ABSENT')}</p>
                <p className={styles.subtitle}>ขาด (คน)</p>
              </div>
              <div className={styles.CardLeave}>
                <p className={styles.title}>{sumEmployee('LEAVE')}</p>
                <p className={styles.subtitle}>ลา (คน)</p>
              </div>
              <div className={styles.CardLate}>
                <p className={styles.title}>0</p>
                <p className={styles.subtitle}>มาสาย (คน)</p>
              </div>
              <div className={styles.CardOt}>
                <p className={styles.title}>{sumEmployee('OVERTIME')}</p>
                <p className={styles.subtitle}>ล่วงเวลา (คน)</p>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* <Grid container spacing={4} columns={{ xs: 12 }}>
        <Grid item xs={5} className={styles.EvenList}>
          <section className={styles.HeadDate}>
            <div className={styles.GroupText}>
              <p>{getThaiDate(props.date).format('day date month year')}</p>
              <img src={CloseIcon} alt="" onClick={() => props.setShowModal(false)} style={{ cursor: 'pointer' }} />
            </div>
          </section>
          <Divider />
          <Stack spacing={4}>
            {!editeData ? (
              <p className={styles.textEdite} onClick={() => setEditData(!editeData)}>
                แก้ไขรายการ
              </p>
            ) : (
              <p className={styles.textEdite} onClick={() => setEditData(!editeData)}>
                เสร็จสิ้น
              </p>
            )}

            <div className={styles.Group}>
              {emploeeEventList.map((event: any, index: number) => {
                return (
                  <Stack key={index} className={styles.GroupList}>
                    {editeData ? (
                      <div>
                        <p className={styles.title}>{event.title}</p>
                        <p>{event.dateTimeTitle}</p>
                        <div className={styles.ListEdite}>
                          {event.employeeList.map((employee: any, employeeIndex: number) => {
                            return (
                              <div className={styles.GroupEmployee} key={employeeIndex}>
                                <div className={styles.form}>
                                  <div className={styles.Badge}>
                                    {employee.event.status === 'APPROVE' ? (
                                      <div className={styles.pillStatus}>
                                        <p className={styles.text}>{employee.event.status}</p>
                                      </div>
                                    ) : (
                                      <>
                                        {employee.Type === 'ABSENT' ? (
                                          ''
                                        ) : (
                                          <img
                                            src={DelIcon}
                                            alt=""
                                            className={styles.badge}
                                            onClick={() => {
                                              swal({
                                                dangerMode: true,
                                                title: `ลบเอกสาร${TypeDocument[`${employee.Type}`]}`,
                                                icon: 'warning',
                                                text: ` ${employee.employee.fullName} ${employee.employee.lastName}`,
                                                buttons: ['ยกเลิก', 'ลบเอกสาร'],
                                              }).then((wildel) => {
                                                console.log('DElete')
                                                if (employee.Type === 'OVERTIME') {
                                                  DelRequestOvertime({
                                                    input: {
                                                      employeeID: employee.employeeID,
                                                      id: employee.event.id,
                                                    },
                                                  }).then((res: any) => {
                                                    ResetAttendanceReport({
                                                      input: {
                                                        employeeID: employee.employeeID,
                                                        startDate: dateFmtShort(props.date),
                                                        endDate: dateFmtShort(props.date),
                                                      },
                                                    }).then((res) => {
                                                      console.log(res, 'Reset')
                                                    })
                                                    console.log(res, 'res')
                                                  })
                                                } else if (employee.Type === 'LEAVE') {
                                                  DelRequestLeave({
                                                    input: {
                                                      employeeID: employee.employeeID,
                                                      id: employee.event.id,
                                                    },
                                                  }).then((res: any) => {
                                                    ResetAttendanceReport({
                                                      input: {
                                                        employeeID: employee.employeeID,
                                                        startDate: dateFmtShort(props.date),
                                                        endDate: dateFmtShort(props.date),
                                                      },
                                                    }).then((res) => {
                                                      console.log(res, 'Reset')
                                                    })
                                                    console.log(res, 'DelLeave')
                                                  })
                                                }
                                              })

                                              console.log(`Close`, employee.event.id)
                                            }}
                                          />
                                        )}
                                      </>
                                    )}
                                    {console.log(employee, 'employee')}
                                    <Avatar
                                      alt={employee.profileImage}
                                      src={employee.profileImage}
                                      className={employee.event.status === 'APPROVE' ? styles.avatar : ''}
                                    />
                                  </div>
                                  <p className={styles.NameText}>
                                    {employee.employee.fullName} {employee.employee.lastName}
                                  </p>
                                </div>
                                <img
                                  src={NextIcon}
                                  alt=""
                                  className={styles.Icon}
                                  onClick={(e: any) => {
                                    handleClick(e)
                                    setDataEvent(employee)
                                  }}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className={styles.title}>{event.title}</p>
                        <p>{event.dateTimeTitle}</p>
                        <AvatarGroup max={6} style={{ display: 'flex', justifyContent: 'start' }}>
                          {event.employeeList.map((employee: any) => {
                            return <Avatar key={employee.id} alt={employee.profileImage} src={employee.profileImage} />
                          })}
                        </AvatarGroup>
                      </div>
                    )}
                  </Stack>
                )
              })}
            </div>
          </Stack>

          <section className={styles.bottomCard}>
            <Divider />
            <div className={styles.GroupData}>
              <p className={styles.txetHead}>ยอดรวมวันนี้</p>
              <div className={styles.GroupCard}>
                <div className={styles.Card}>
                  <p className={styles.title}>{sumEmployee('ABSENT')}</p>
                  <p className={styles.subtitle}>ขาด (คน)</p>
                </div>
                <div className={styles.CardLeave}>
                  <p className={styles.title}>{sumEmployee('LEAVE')}</p>
                  <p className={styles.subtitle}>ลา (คน)</p>
                </div>
                <div className={styles.CardLate}>
                  <p className={styles.title}>0</p>
                  <p className={styles.subtitle}>มาสาย (คน)</p>
                </div>
                <div className={styles.CardOt}>
                  <p className={styles.title}>{sumEmployee('OVERTIME')}</p>
                  <p className={styles.subtitle}>ล่วงเวลา (คน)</p>
                </div>
              </div>
            </div>
          </section>
        </Grid>
        <Popper open={Popepr} anchorEl={anchorElpop} placement="auto" style={{ zIndex: '999999' }}>
          <ShowEventPopper data={dataEvent} />
        </Popper>
      </Grid> */}
    </div>
  )
}

const ShowEventPopper = (props: any) => {
  return (
    <div className={styles.popper}>
      <section className={styles.GroupData}>
        {props.data.Type === 'OVERTIME' ? (
          <div className={styles.InputData}>
            <section className={styles.Group}>
              <p>สร้าง</p>
              <div className={styles.InputFiled}>
                <p>{props.data.Type}</p>
              </div>
            </section>
            <section className={styles.Group}>
              <p>ช่วงเวลา</p>
              <div className={styles.Grouptime}>
                <div className={styles.InputL}>
                  <p>{toTime(props.data.event.startedAt)}</p>
                </div>
                <div className={styles.InputR}>
                  <p>{toTime(props.data.event.endedAt)}</p>
                  <img src={TimeIcon} alt="" />
                </div>
              </div>
            </section>
            <section className={styles.Group}>
              <p>ช่วงวันที่</p>
              <div className={styles.InputFiled}>
                <p>
                  {new Date(props.data.event.startedAt).toLocaleDateString('th-TH', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                  })}
                </p>
                {/* <img src={CalendarIcon} alt="" /> */}
              </div>
            </section>
            <section className={styles.Group}>
              <p>พนักงาน</p>
              <div className={styles.EmpProfile}>
                <Avatar
                  alt={props.data.employee.profileImage}
                  src={props.data.employee.profileImage}
                  className={styles.avatar}
                />
                <p>
                  {props.data.employee.fullName} {props.data.employee.lastName}
                </p>
              </div>
            </section>
          </div>
        ) : (
          <div className={styles.InputData}>
            <section className={styles.Group}>
              <p>สร้าง</p>
              <div className={styles.InputFiled}>
                <p>{props.data.Type}</p>
              </div>
            </section>
            <section className={styles.Group}>
              <p>ประเภทการลา</p>
              <div className={styles.InputFiled}>
                <p>{ELeave[`${props.data.event.leaveType}`]}</p>
              </div>
            </section>
            {props.data.event.isLeaveDeductMoney ? (
              <section className={styles.toggle}>
                <div className={styles.button}>
                  <div className={props.data.event.isLeaveDeductMoney === true ? styles.btLeave : styles.bt}>
                    ลาหักเงิน
                  </div>
                  <div className={props.data.event.isLeaveDeductMoney === true ? styles.bt : styles.btLeave}>
                    ลาไม่หักเงิน
                  </div>
                </div>
              </section>
            ) : (
              ''
            )}

            <section className={styles.Group}>
              <p>รูปแบบการลา</p>
              <div className={styles.InputFiled}>
                {props.data.event.leaveForm ? (
                  <p>{ELeavForm[`${props.data.event.leaveForm}`]}</p>
                ) : (
                  <p>{ELeave[`${props.data.event.leaveType}`]}</p>
                )}
              </div>
            </section>
            <section className={styles.Group}>
              <p>ช่วงเวลา</p>
              <div className={styles.Grouptime}>
                <div className={styles.InputL}>
                  <p>{toTime(props.data.event.startedAt)}</p>
                </div>
                <div className={styles.InputR}>
                  <p>{toTime(props.data.event.endedAt)}</p>
                  <img src={TimeIcon} alt="" />
                </div>
              </div>
            </section>
            <section className={styles.Group}>
              <p>ช่วงวันที่</p>
              <div className={styles.InputFiled}>
                <p>
                  {new Date(props.data.event.startedAt).toLocaleDateString('th-TH', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                  })}
                </p>
                {/* <img src={CalendarIcon} alt="" /> */}
              </div>
            </section>
            <section className={styles.Group}>
              <p>พนักงาน</p>
              <div className={styles.EmpProfile}>
                <Avatar
                  alt={props.data.employee.fullName}
                  src={props.data.employee.profileImage}
                  className={styles.avatar}
                />
                <p>
                  {props.data.employee.fullName} {props.data.employee.lastName}
                </p>
              </div>
            </section>
          </div>
        )}
      </section>
      <section className={styles.pointer}></section>
    </div>
  )
}

export default React.forwardRef<CalendarRef, Props>(BigCalendar)
