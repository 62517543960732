import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface NotificationState {
  title: string
  description: string
  isOpen: boolean
  isLoading: boolean
  typeNotification: 'danger' | 'success' | 'warning' | 'info' | 'loading' | ''
}

export interface SetOpenNotificationPayload {
  title: string
  description: string
  typeNotification: 'danger' | 'success' | 'warning' | 'info' | 'loading' | ''
}

export interface SetOpenNotificationLoadingPayload {
  title: string
  description: string
  isLoading: boolean
  typeNotification: 'danger' | 'success' | 'warning' | 'info' | 'loading' | ''
}

const initialState: NotificationState = {
  title: '',
  description: '',
  isOpen: false,
  isLoading: false,
  typeNotification: '',
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setOpenNotification: (state, action: PayloadAction<SetOpenNotificationPayload>) => {
      state.title = action.payload.title
      state.description = action.payload.description
      state.isOpen = true
      state.typeNotification = action.payload.typeNotification
    },
    setOpenNotificationLoading: (state, action: PayloadAction<SetOpenNotificationLoadingPayload>) => {
      state.title = action.payload.title
      state.description = action.payload.description
      state.isOpen = true
      state.isLoading = action.payload.isLoading
      state.typeNotification = action.payload.typeNotification
    },
    setCloseNotification: (state) => {
      state.title = ''
      state.description = ''
      state.isOpen = false
      state.isLoading = false
      state.typeNotification = ''
    },
  },
})

// Action creators are generated for each case reducer function
export const { setOpenNotification, setOpenNotificationLoading, setCloseNotification } = notificationSlice.actions

export default notificationSlice.reducer
