import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'

import { Formik } from 'formik'
import * as yup from 'yup'
import { PaginationBar } from '../../../component'
import { TableAttendancePayroll } from './table'
import { paginationFunc } from '../../../utils/common'
import SearchInput from 'src/component/SearchInput'
import Payroll from '..'
import { QueryListPayroll } from 'src/adapter/xhr'
import { IUsePaginationQueryOutput } from 'src/hooks/usePaginationQuery'
import { TimeAttendanceReportResponse } from 'src/utils/generated'
import { CircularProgress } from '@mui/material'

interface WorkListPayrollProps {
  payrollTimeAttendanceQuery: IUsePaginationQueryOutput<TimeAttendanceReportResponse>
}

const WorkListPayroll: React.FC<WorkListPayrollProps> = (props: WorkListPayrollProps): JSX.Element => {
  const {
    dataCache,
    getFlattenDataCache,
    currentTableData,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    windowSize,
    maxHasNextToken,
    currentMaxPageIndex,
    loading,
    clearCacheRefetch,
    error,
  } = props.payrollTimeAttendanceQuery

  const validationSchema = yup.object({})
  const [pageSite, setPageSite] = React.useState<number>(10)
  const [listItemIndex, setListItemIndex] = React.useState<number>(0)
  const [searchVal, setSearchVal] = React.useState<string>('')
  const [filter, setFilter] = useState<any>([])
  const [showFilter, setShowFilter] = useState(false)
  // const [nextToken, setNextToken] = useState<any>(null)
  // const [loading, setLoading] = useState(true)

  const formikRef: any = useRef(null)
  // const ReportPayroll = props.payrollList

  const handleClick = (value: any) => {
    if (filter.includes(value) === false) {
      setFilter([...filter, value])
    } else {
      setFilter(filter.filter((item: any) => item !== value))
    }
  }

  // const [workList, setWorkList] = useState<any>([])

  // useEffect(() => {
  //   console.log(filter, 'filterUse')
  // }, [filter])

  // useEffect(() => {
  //   QueryListPayroll({ yearMonth: ReportPayroll.yearMonth, period: ReportPayroll.period }).then((res: any) => {
  //     console.log(res, 'res')

  //     setWorkList(res.data.data.listPayroll.timeAttendanceReportList)
  //     setNextToken(res.data.data.listPayroll.nextToken)
  //   })
  // }, [])

  return (
    <Formik
      innerRef={formikRef}
      initialValues={
        {
          // income: mockUpIncome,
          // expense: mockUpExpense,
        }
      }
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        console.log(values, 'SUBMITTING')
      }}
    >
      {(props: any) => (
        <form className={styles.container} onSubmit={props.handleSubmit}>
          <section className={styles.Filterbar}>
            <>
              {/* <SearchInput setValues={setSearchVal} values={searchVal} label="ค้นหาชื่อ - สกุล/รหัสพนักงาน" /> */}
            </>
            <section className={styles.FilterDetail}>
              <div
                className={showFilter ? styles.CardFilterSelect : styles.CardFilter}
                onClick={() => setShowFilter(!showFilter)}
              >
                ตัวกรอง
                {filter.length > 0 && <div className={styles.Bade}> {filter.length}</div>}
              </div>
              {showFilter ? (
                <div className={styles.GroupCard}>
                  {Filter.map((filteritem: any, index: number) => (
                    <div
                      key={index}
                      onClick={(e: any) => {
                        handleClick(filteritem.key)
                        console.log(filter, 'FILTER')
                      }}
                      className={
                        filter.map((item: any) => item).includes(filteritem.key) === true
                          ? styles.CardFilterSelect
                          : styles.CardFilter
                      }
                    >
                      {filteritem.title}
                    </div>
                  ))}
                  <p className={styles.textFilter} onClick={() => setFilter([])}>
                    ล้างตัวกรอง
                  </p>
                </div>
              ) : (
                ''
              )}
            </section>
          </section>
          {/* <InputTextComponent
            _name="searchValues"
            _label="ค้นหาแผนก/รหัส/รายชื่อพนักงาน"
            _helperText={props.errors.searchValues}
          />
          <div className={styles.toolbarsContainer}>
            <section className={styles.leftToolbars}>
              <article className={styles.configBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="blue"
                  _colorText="white"
                  _variant="outlined"
                  _isIcon="expenseIncome"
                  _text="ตั้งค่ารายจ่าย"
                  _functionOnClick={() => {
                    console.log('button Click !!')
                  }}
                />
              </article>
              <article className={styles.filterBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="transparent"
                  _colorText="blue"
                  _variant="text"
                  _isIcon="filter"
                  _text="ตัวกรองข้อมูล"
                  _functionOnClick={() => {
                    console.log('button Click !!')
                  }}
                />
              </article>
            </section>
          </div> */}

          <section className={styles.TableReport}>
            {loading && <CircularProgress />}
            <TableAttendancePayroll
              // ReportPayroll={ReportPayroll}
              // report={
              //   paginationFunc(workList, pageSite)[listItemIndex]
              //     ? paginationFunc(workList, pageSite)[listItemIndex]
              //     : []
              // }
              searchVal={searchVal}
              filter={filter}
              // loading={loading}
              employeeGroupList={currentTableData ? currentTableData : []}
            />
            <PaginationBar
              // Convert from PageIndex to PageNumber
              currentTotalPageCount={currentMaxPageIndex + 1}
              // currentTotalPageCount={6}
              currentPage={currentPage}
              hasNextToken={maxHasNextToken}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setCurrentPage={setCurrentPage}
              windowSize={windowSize}
            />
            {/* <PaginationBar
              _dropDownValue={pageSite}
              _onDropdownChange={(e: any) => {
                setPageSite(e.target.value)
                setListItemIndex(0)
              }}
              _selectedNumber={listItemIndex + 1}
              _numberList={paginationFunc(workList, pageSite).map((e: any, index: number) => index + 1)}
              _setSelectedNumber={(event: any, number: number, index: number) => {
                if (listItemIndex !== index) {
                  setListItemIndex(index)
                  if (index + 2 === paginationFunc(workList, pageSite).length && nextToken) {
                    QueryListPayroll({
                      yearMonth: ReportPayroll.yearMonth,
                      period: ReportPayroll.period,
                      nextToken: nextToken,
                    }).then((res: any) => {
                      if (
                        res.data.data.listPayroll.timeAttendanceReportList &&
                        res.data.data.listPayroll.timeAttendanceReportList.length > 0
                      ) {
                        setWorkList([...workList, ...res.data.data.listPayroll.timeAttendanceReportList])
                      }
                      setNextToken(res.data.data.listPayroll.nextToken)
                      setLoading(false)
                    })
                  }
                }
              }}
            /> */}

            {/*             
<PaginationBar
              _dropDownValue={pageSite}
              _onDropdownChange={(e: any) => {
                setPageSite(e.target.value)
                setListItemIndex(0)
              }}
              _selectedNumber={listItemIndex + 1}
              _numberList={paginationFunc(Listdocument, pageSite).map((e: any, index: number) => index + 1)}
              _setSelectedNumber={(event: any, number: number, index: number) => {
                if (listItemIndex !== index) {
                  setListItemIndex(index)
                  if (index + 2 === paginationFunc(Listdocument, pageSite).length && nextToken) {
                    ListLeaveDocument({ nextToken: nextToken, filter: filter }).then((res: any) => {
                      // if (
                      //   res.data.data.listLeaveGroupByEmployee.items &&
                      //   res.data.data.listLeaveGroupByEmployee.items.length > 0
                      // ) {
                      setListDocument([...Listdocument, ...res.data.data.listLeaveGroupByEmployee.items])
                      // }
                      setNextToken(res.data.data.listLeaveGroupByEmployee.nextToken)
                    })
                  }
                }
              }}
            /> */}
          </section>
        </form>
      )}
    </Formik>
  )
}

export default WorkListPayroll

const Filter = [
  {
    title: 'กะการทำงาน',
    key: 'SHIFT',
  },
  {
    title: 'ทำงานล่วงเวลา',
    key: 'OVERTIME',
  },
  {
    title: 'ลาหักเงิน',
    key: 'LEAVE_DEDUCT',
  },
  {
    title: 'ลาไม่หักเงิน',
    key: 'LEAVE',
  },
  {
    title: 'หมายเหตุ',
    key: 'REMARK',
  },
]
