//lib
import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

//Include Project
import { minuteToHourHH } from 'src/utils/common'
import { InputNumberComponentCustomTran } from 'src/component'
import styles from './index.module.scss'

interface Props {
  report: any
}

const SumCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { values } = useFormikContext<any>()
  const [workTime, setWorkTime] = useState<any>(0)

  useEffect(() => {
    setWorkTime(values.report.workingMinute / 60)
  }, [])

  return (
    <>
      {values.report.isLink === true ? (
        <div className={styles.SumCard}>
          <div className={styles.cardTotalWork}>
            <p className={styles.textTotal}>{minuteToHourHH(props.report?.workingMinute || 0)}</p>
            <p className={styles.text}>ทำงาน (ชม.)</p>
          </div>
          <div className={styles.cardTotalOT}>
            <p className={styles.textTotal}>
              {minuteToHourHH(props.report?.overtimeMinute + props.report?.overtimeApproveMinute || 0)}
            </p>
            <p className={styles.text}>ล่วงเวลา (ชม.)</p>
          </div>
          <div className={styles.cardTotalLeave}>
            <div className={styles.GroupText}>
              <p className={styles.textTotal}>{minuteToHourHH(props.report?.leaveDeductMinute || 0)}</p>
              <p className={styles.text}>ลาหักเงิน (ชม.)</p>
            </div>
            <div className={styles.line}></div>
            <div className={styles.GroupText}>
              <p className={styles.textTotal}>{minuteToHourHH(props.report?.leaveMinute || 0)}</p>
              <p className={styles.text}>ลาไม่หักเงิน (ชม.)</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.SumCard}>
          <div className={styles.cardTotalWork}>
            <p className={styles.textTotal}>
              <InputNumberComponentCustomTran _name={'workingMinute'} _value={workTime} />
            </p>
            <p className={styles.text}>ทำงาน (ชม.)</p>
          </div>
          <div className={styles.cardTotalOT}>
            <p className={styles.textTotal}>
              <InputNumberComponentCustomTran _name={'overtimeMinute'} />
            </p>
            <p className={styles.text}>ล่วงเวลา (ชม.)</p>
          </div>
          <div className={styles.cardTotalLeave}>
            <div className={styles.GroupText}>
              <p className={styles.textTotal}>
                <div style={{ width: '50px' }}>
                  <InputNumberComponentCustomTran _name={'leaveMinute'} />
                </div>
              </p>
              <p className={styles.text}>ลาหักเงิน (ชม.)</p>
            </div>
            <div className={styles.line}></div>
            <div className={styles.GroupText}>
              <p className={styles.textTotal}>
                <div style={{ width: '50px' }}>
                  <InputNumberComponentCustomTran _name={'leaveDeductMinute'} />
                </div>
              </p>
              <p className={styles.text}>ลาไม่หักเงิน (ชม.)</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SumCard
