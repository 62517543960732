import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { ICombineReducers } from 'src/state/reducers'

export const RequireAuth: React.FC<RequireAuthProps> = ({ redirectTo }: RequireAuthProps) => {
  const { isLoggedIn } = useSelector((state: ICombineReducers) => state.loginReducer)

  return isLoggedIn ? <Outlet /> : <Navigate to={redirectTo} />
}
interface RequireAuthProps {
  redirectTo: string
}
