import * as React from 'react'
import { Fade, Popper, Tooltip } from '@mui/material'
import styles from './index.module.scss'
import { Formik } from 'formik'
import allAction from '../../state/actions'
import { QueryGetAttendance, UpdateAttendanceReport } from '../../adapter/xhr'
import { ButtonComponent, InputTextComponentCustom } from '../../component'
import { useDispatch } from 'react-redux'
import closeIcon from '../../image/close.svg'
import noteBlueIcon from '../../image/noteBlue.svg'
import AddIcon from '../../image/addblue.svg'
import { toTime } from './EType'
import { ModalOverTimePopper } from './ModalPopper'

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  anchorEl: any
  employeeID: any
  setAnchorEl: any
  loadData: any
  dataCache: any
  reportOT: any
  date: any
  startDate: any
  endDate: any
  QueryReport: any
}

export const PopperOverTime: React.FC<Props> = (props: Props): JSX.Element => {
  const [openModalOT, setOpenModalOT] = React.useState(false)
  const [getReport, setGetReport] = React.useState<any>({})
  const [otListIndex, setOtListIndex] = React.useState<number>(0)

  const sendReport = (index: number) => {
    setGetReport(props.reportOT[index])
  }

  const reportOtList = React.useMemo(() => {
    return props.reportOT.slice(0, 3)
  }, [props.reportOT])

  return (
    <>
      <div>
        <Popper transition placement="right" anchorEl={props.anchorEl} open={props.open} sx={{ zIndex: 99 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div className={styles.popperWrapper}>
                <div className={styles.pointer}></div>
                <div className={styles.modal}>
                  <div className={styles.modalGroup}>
                    {reportOtList.map((listot: any, OTIndex: number) => (
                      <div key={OTIndex} className={styles.GroupText}>
                        <section className={styles.detail}>
                          <p>
                            {toTime(listot.startedAt)} - {toTime(listot.endedAt)}
                          </p>
                        </section>
                        <div
                          className={
                            listot.status === 'PENDING'
                              ? styles.pillWarn
                              : listot.status === 'REJECT'
                              ? styles.pillReject
                              : listot.status === 'DRAFT'
                              ? styles.pillDraft
                              : listot.status === 'APPROVE'
                              ? styles.pill
                              : styles.notpill
                          }
                        >
                          {listot.status ? (
                            <p>{listot.status}</p>
                          ) : (
                            <Tooltip title={<p>สร้างรายการเอกสารโอที</p>} placement="bottom" arrow>
                              <img
                                src={AddIcon}
                                alt=""
                                className={styles.Icon}
                                onClick={() => {
                                  setOpenModalOT(true)
                                  sendReport(listot.index)
                                  setOtListIndex(listot.index)
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                        <img src={noteBlueIcon} alt="" className={styles.Icon} />
                      </div>
                    ))}
                    {props?.reportOT.length > 3 && (
                      <p className={styles.textBottom}>+ {props.reportOT.length - 3} รายการ</p>
                    )}
                  </div>
                </div>
              </div>
            </Fade>
          )}
        </Popper>
        <ModalOverTimePopper
          open={openModalOT}
          setOpen={setOpenModalOT}
          overtimeData={getReport}
          date={props.date}
          employeeID={props.employeeID}
          onSentOvertime={(res: any, status: string) => {
            const newOverTime: any = {
              overtimeRequestID: res.data.createOvertimeRequest.id,
              overtimeType: res.data.createOvertimeRequest.overtimeType,
              status: res.data.createOvertimeRequest.status,
              startedAt: new Date(res.data.createOvertimeRequest.startedAt).toISOString(),
              endedAt: new Date(res.data.createOvertimeRequest.endedAt).toISOString(),
            }
            const employeeReport = props.dataCache[props.employeeID]
            const dateReport = employeeReport.find((e: any) => e.date === props.date)

            // UPDATE OT LIST IN REPORT
            dateReport.overtimeList[otListIndex].status = status
            dateReport.overtimeApproveList.push(newOverTime)
            if (dateReport.overtimeApproveList.length) dateReport.overtimeList = []

            const UpdateAttendanceVariables = {
              input: {
                employeeID: props.employeeID,
                date: props.date,
                overtimeApproveList: dateReport.overtimeApproveList,
                overtimeList: dateReport.overtimeList,
              },
            }

            UpdateAttendanceReport(UpdateAttendanceVariables).then((res: any) => {
              console.log(res, 'attendance')
              props.QueryReport()
              // setTimeout(() => {
              //   props.QueryReport()
              //   props.loadData({
              //     variables: {
              //       startDate: props.startDate,
              //       endDate: props.endDate,
              //       employeeId: props.employeeID,
              //       limit: 365,
              //     },
              //   })
              // }, 100)
            })
          }}
        />
      </div>
    </>
  )
}

export const PooperRemark: React.FC<any> = (props: any): JSX.Element => {
  const inputRef = React.useRef<any>()
  const dispatch = useDispatch()

  const QueryRemark = () => [
    QueryGetAttendance({ date: props.date, employeeId: props.employeeId }).then((res: any) => {
      if (res.data.data?.getTimeAttendanceReport?.remark) {
        inputRef.current.setFieldValue('remark', res.data.data.getTimeAttendanceReport.remark)
      } else {
        inputRef.current.setFieldValue('remark', '')
      }
    }),
  ]
  React.useEffect(() => {
    QueryRemark()
  }, [props.date, props.employeeId])

  const propscurrent = props

  return (
    <Formik
      innerRef={inputRef}
      initialValues={{
        remark: '',
      }}
      onSubmit={(values, action) => {
        const VariablesRemark: any = {
          input: {
            employeeID: props.employeeId,
            date: props.date,
            remark: values.remark,
          },
        }
        dispatch(
          allAction.modalConfirmAction.setOpenModalConfirm(
            `เพิ่มหมายเหตุ`,
            `${values.remark}`,
            '',
            'ใช่,ทำรายการ',
            () => {
              UpdateAttendanceReport(VariablesRemark).then((res: any) => console.log(res))
              dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
              props.setPopperRemark(false)
              dispatch(allAction.modalAction.setOpenModalAlertStatus('บันทึกการแก้ไขเรียบร้อยแล้ว', '', 'success'))
              setTimeout(() => {
                props.loadData({
                  variables: {
                    startDate: props.startdate,
                    endDate: props.endAt,
                    employeeId: props.employeeId,
                    limit: 365,
                  },
                })
              }, 100)
              // inputRef.current.setFieldValue('remark', values.remark)
            },
          ),
        )
      }}
      //   validationSchema={validationSchema}
    >
      {(props: any) => (
        <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
          <div>
            <Popper open={propscurrent.popperRemark} anchorEl={propscurrent.anchorEl} placement="left">
              <div className={styles.area}>
                <div className={styles.modal}>
                  <div className={styles.modalGroup}>
                    <div className={styles.header}>
                      <p className={styles.text}>เพิ่มหมายเหตุ</p>
                      <img
                        src={closeIcon}
                        alt=""
                        className={styles.close}
                        onClick={() => propscurrent.setPopperRemark(false)}
                      />
                    </div>
                    <InputTextComponentCustom _name="remark" _value={props.values.remark} />
                    <section className={styles.buttonFooter}>
                      <ButtonComponent
                        _colorBG="white"
                        _colorText="red"
                        _text="ลบ"
                        _variant="contained"
                        _type="button"
                        _functionOnClick={() => inputRef.current.setFieldValue('remark', '')}
                      />
                      <ButtonComponent
                        _colorBG="blue"
                        _colorText="white"
                        _text="บันทึก"
                        _variant="contained"
                        _type="submit"
                        _functionOnClick={props.handleSubmit}
                      />
                    </section>
                  </div>
                </div>
                <div className={styles.pointer}></div>
              </div>
            </Popper>
          </div>
        </form>
      )}
    </Formik>
  )
}
