import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IInitialStateDepartment {
  departmentList: []
  departmentListOption: any[]
  refetch: () => void
}
const initialState: IInitialStateDepartment = {
  departmentList: [],
  departmentListOption: [],
  refetch: () => null,
}

export const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setDepartment: (state, action: PayloadAction<any>) => {
      state.departmentList = action.payload.items
      state.departmentListOption = action.payload.items?.map((data: any) => ({
        value: data.id,
        label: data.departmentName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setDepartment } = departmentSlice.actions

export default departmentSlice.reducer
