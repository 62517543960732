//lib
import React, { useState } from 'react'
import { Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useFormikContext } from 'formik'
import _ from 'lodash'

//Include Project
import { UpdateEmployeeProfile } from 'src/adapter/xhr'
import { CalendarEvent, InputTextComponentCustom, ProfileUpload } from 'src/component'
import styles from './index.module.scss'
//Image
import questionIcon from '../../image/question.svg'
import deleteIcon from '../../image/deleteRedIcon.svg'
import { EOverTime } from 'src/pages/AttendanceReport/EType'

interface Props {
  date: string
}

const CalendarEventContainer: React.FC<Props> = (props: Props): JSX.Element => {
  const { values } = useFormikContext<any>()

  const GetEvent = (getAttendance: any) => {
    const newAttendance: any[] = []
    getAttendance?.leaveList?.map((eventLeave: any) => {
      return newAttendance.push({
        title: `${eventLeave.leaveConfig.name ? eventLeave.leaveConfig.name : 'ขาด'}`,
        allDay: false,
        start: new Date(`${props.date}:${eventLeave.startedAt}`),
        end: new Date(`${props.date}:${eventLeave.endedAt}`),
        type: 'Leave',
      })
    })
    getAttendance?.overtimeList?.map((eventOT: any) => {
      return newAttendance.push({
        title: `${EOverTime[`${eventOT.overtimeType}`]}`,
        allDay: false,
        start: new Date(`${props.date}:${eventOT.startedAt}`),
        end: new Date(`${props.date}:${eventOT.endedAt}`),
        type: 'OT',
      })
    })
    return newAttendance
  }

  return (
    <div className={styles.container}>
      <CalendarEvent
        CalaendarDate={new Date(props.date)}
        getEventData={GetEvent({
          leaveList: values.report.leaveList,
          overtimeList: values.report.overtimeList,
        })}
      />
    </div>
  )
}

export default CalendarEventContainer
