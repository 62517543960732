import { Avatar, AvatarGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ButtonComponent } from '../../component'
import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import ImgIcon from '../../image/picUser.svg'
import { ModalCreatePackage } from './ModalCreate'
import { useQuery } from '@apollo/client'
import { LIST_PACKAGE_GROUP } from 'src/adapter/xhr/query'
import { FetchData } from 'src/adapter/xhr/interface'
import { PackageGroupQuery } from 'src/utils/generated'
import Swal from 'sweetalert2'

const CreatePackage: React.FC = (): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [addUser, setAddUser] = useState(false)
  const [newlistUser, setNewlistUser] = useState<any>([])
  const [movePackageEmployee, setMovePackageEmployee] = useState(false)
  const [listpackage, setListPackage] = useState<any>({})
  const [packageGropID, setPackageGroupID] = useState('')
  const navigate = useNavigate()

  const mockUser = [
    {
      name: 'แผนกบัญชี',
      user: [
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '01',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '02',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '03',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '04',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '05',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '06',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '07',
        },
      ],
    },
    {
      name: 'แผนกการตลาด',
      user: [
        {
          name: 'Adison Saris',
          img: ImgIcon,
          money: 300,
          id: '08',
        },
        {
          name: 'Adison Saris',
          img: ImgIcon,
          money: 300,
          id: '09',
        },
        {
          name: 'Adison Saris',
          img: ImgIcon,
          money: 300,
          id: '10',
        },
      ],
    },
    {
      name: 'แผนกฝ่ายบุคคล',
      user: [
        {
          name: 'Talan Schleifer',
          img: ImgIcon,
          money: 300,
          id: '11',
        },
        {
          name: 'Beam Schleifer',
          img: ImgIcon,
          money: 300,
          id: '12',
        },
      ],
    },
  ]

  const Leave = [
    {
      name: 'ลาป่วย',
      workAge: 18,
      total: 70,
      totalofLeave: 8,
      link: false,
      // choice: true,
    },
    {
      name: 'ลาพบเเพทย์',
      workAge: 24,
      total: 70,
      totalofLeave: 0,
      link: true,

      // choice: true,
    },
    {
      name: 'ลากิจ',
      workAge: 121,
      total: 6,
      totalofLeave: 0,
      link: true,

      // choice: true,
    },
    {
      name: 'ลาบวช',
      workAge: 4,
      total: 70,
      totalofLeave: 0,
      link: false,

      // choice: true,
    },
    {
      name: 'ลาคลอด',
      workAge: 18,
      total: 70,
      totalofLeave: 0,
      link: true,

      // choice: true,
    },
    // {
    //   name: 'ลาพักร้อน',
    // },
  ]

  const { data, loading, error, refetch } = useQuery<FetchData<PackageGroupQuery>>(LIST_PACKAGE_GROUP)
  const [list, setPackage] = useState<any>([])

  // console.log(data, 'data')

  useEffect(() => {
    if (data && !loading && !error) {
      console.log(data.listPackageGroup.items)
      setPackage(data.listPackageGroup.items)
    } else if (error) {
      Swal.fire({
        icon: 'error',
        title: error.name,
        text: error.message,
      })
    }
  }, [data, loading])

  useEffect(() => {
    if (open === false) {
      refetch()
    }
  }, [open])

  return (
    <>
      <div className={styles.mainInPage}>
        <div className={styles.headZone}>
          <h1 className={styles.title}>ตั้งค่าเเพ็กเกจ</h1>
          <div className={styles.btnZone}>
            <div className={styles.button}>
              <ButtonComponent
                _colorBG="transparent"
                _text="ย้ายเเพ็กเกจพนักงาน"
                _colorText="blue"
                _isDisabled
                _type={'button'}
                _variant="outlined"
                _functionOnClick={() => navigate('../employee/tranPackage')}
              />
            </div>

            <div className={styles.button}>
              <ButtonComponent
                _colorBG="blue"
                _text="เพิ่มแพ็กเกจ"
                _colorText="white"
                _type={'button'}
                _variant="contained"
                _functionOnClick={() => {
                  setOpen(true)
                  setPackageGroupID('')
                  setListPackage({})
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.dataZone}>
          {movePackageEmployee && (
            <div className={styles.rowUserZone} style={{ width: '50%', backgroundColor: '#fff' }}>
              {mockUser.map((c: any, n: number) => {
                return (
                  <div className={styles.oneDepartment} key={n}>
                    <h4 className={styles.departmentText}>{c.name}</h4>
                    <div className={styles.allUser}>
                      {c.user.map((e: any, i: number) => {
                        return (
                          <div className={styles.oneUser} key={i}>
                            <div className={styles.leftZoneUser}>
                              <img className={styles.userImg} src={e.img} alt={e.name} />
                              <div>{e.name}</div>
                            </div>
                            <input
                              className={styles.checkboxItem}
                              onClick={() => {
                                setNewlistUser([...newlistUser, e])
                              }}
                              type="checkbox"
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          <div
            style={
              movePackageEmployee
                ? {
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '2em 3em',
                  }
                : { width: '100%' }
            }
            className={styles.allPackage}
          >
            {list.map((e: any, i: number) => {
              return (
                <div
                  className={styles.onePackage}
                  key={i}
                  onClick={() => {
                    setListPackage(e)
                    setOpen(true)
                    setPackageGroupID(e.packageGroupID)
                  }}
                >
                  <h2>{e.name}</h2>
                  <div className={styles.GroupConfig}>
                    <div className={styles.detailInOnePackage}>
                      {e.leavePackageList.map((leaveItem: any, leaveIndex: number) => {
                        return (
                          <div className={styles.detailItem} key={leaveIndex}>
                            <div>{leaveItem.leaveConfig.name}</div>
                            <div>{leaveItem.customQuotaList[0].customQuota}</div>
                          </div>
                        )
                      })}
                    </div>
                    <div className={styles.detailInOnePackage}>
                      {e.welfarePackageList.map((welfareItem: any, welfareItenIndex: number) => {
                        return (
                          <div className={styles.detailItem} key={welfareItenIndex}>
                            <div>{welfareItem.welfareConfig.name}</div>
                            <div>{welfareItem.customQuotaList[0].customQuota}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <AvatarGroup className={styles.allAvata} max={4}>
                    {e.employeeList.items.map((employee: any, employeeIndex: number) => {
                      return (
                        <Avatar
                          key={employeeIndex}
                          alt={employee.getEmployee.id}
                          src={employee.getEmployee.profileImage}
                        />
                      )
                    })}
                  </AvatarGroup>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <ModalCreatePackage open={open} setOpen={setOpen} packegeId={packageGropID} />
    </>
  )
}
export default CreatePackage
