export const EStatus: any = {
  APPROVE: 'อนุมัติ',
  DRAFT: 'แบบร่าง',
  PENDING: 'รอการอนุมัติ',
  REJECT: 'ไม่ผ่านการอนุมัติ',
  null: 'เอกสารแนะนำ',
}

export const ELeave: any = {
  REASON_SICK: 'ลาป่วย',
  REASON_ANNUAL_LEAVE: 'ลาพักร้อน',
  REASON_PERSONAL_LEAVE: 'ลากิจ',
  ABSENT: 'ขาด',
}

export const ELeavForm: any = {
  CUSTOM_TIME: 'กำหนดเอง',
  FULLDAY_LEAVE: 'ลาเต็มวัน',
  HALFDAY_MORNING_LEAVE: 'ลาครึ่งวันเช้า',
  HALFDAY_AFTERNOON_LEAVE: 'ลาครึ่งวันบ่าย',
}

export const ELeaveType: any = {
  leave: 'ลาไม่หักเงิน',
  leaveDeduct: 'ลาหักเงิน',
}
export const EOverTime: any = {
  WORK: 'โอทีวันทำงาน',
  OVERTIME: 'โอทีวันหยุด',
}

export const ESaveDescrip: any = {
  APPROVE: 'บันทึกและอนุมัติ',
  DRAFT: 'บันทึกแบบร่าง',
  PENDING: 'บันทึก',
}

export const EDayType: any = {
  WORKDAY: 'วันทำงาน',
  WEEKEND: 'วันหยุดสุดสัปดาห์',
  HOLIDAY: 'วันหยุดประจำปี',
}

export const SaveType = [
  {
    menu: 'Save',
    value: 'PENDING',
  },
  { menu: 'Save Draft', value: 'DRAFT' },
  { menu: 'Save and Approve', value: 'APPROVE' },
]

export const SaveTypeEmployee = [
  {
    menu: 'Save',
    value: 'PENDING',
  },
  { menu: 'Save Draft', value: 'DRAFT' },
]
export const toTime = (dateTime: any) => {
  return (
    new Date(dateTime).getHours().toString().padStart(2, '0') +
    ':' +
    new Date(dateTime).getMinutes().toString().padStart(2, '0')
  )
}
