import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { handleDecimalsOnValue } from 'src/utils/common'
import styles from './index.module.scss'

interface Props {
  netIncome: any
}

export const TableCalculateTaxes: React.FC<Props> = (props: Props): JSX.Element => {
  const { setFieldValue } = useFormikContext<any>()

  const calculateTax = () => {
    if (props.netIncome >= 0 && props.netIncome <= 150000) {
      return 0
    } else if (props.netIncome > 150000 && props.netIncome <= 300000) {
      return (props.netIncome - 150000) * 0.05
    } else if (props.netIncome > 300000 && props.netIncome <= 500000) {
      return (props.netIncome - 300000) * 0.1 + 7500
    } else if (props.netIncome > 500000 && props.netIncome <= 750000) {
      return (props.netIncome - 500000) * 0.15 + 27500
    } else if (props.netIncome > 750000 && props.netIncome <= 1000000) {
      return (props.netIncome - 750000) * 0.2 + 65000
    } else if (props.netIncome > 1000000 && props.netIncome <= 2000000) {
      return (props.netIncome - 1000000) * 0.25 + 115000
    } else if (props.netIncome > 2000000 && props.netIncome <= 5000000) {
      return (props.netIncome - 2000000) * 0.3 + 365000
    } else if (props.netIncome > 5000000) {
      return (props.netIncome - 5000000) * 0.35 + 1265000
    } else return 0
  }

  const summaryTax = calculateTax()

  useEffect(() => {
    setFieldValue('employeeTax.totalTaxExpense', summaryTax)
  }, [props.netIncome])

  return (
    <section className={styles.groupTable}>
      <table className="tableRounded">
        <tr>
          <th>เงินได้สุทธิ</th>
          <th>อัตราภาษี</th>
          <th>เงินได้แต่ละขั้นภาษี</th>
          <th>ภาษีสูงสุดที่ต้องชำระ</th>
        </tr>
        <tr>
          <td>1 - 150,000</td>
          <td>0</td>
          <td>
            {props.netIncome > 150000
              ? handleDecimalsOnValue(150000)
              : props.netIncome - 150000 < 0
              ? 0
              : handleDecimalsOnValue(props.netIncome)}
          </td>
          <td>0</td>
        </tr>
        <tr>
          <td>150,001 - 300,000</td>
          <td>5%</td>
          <td>
            {props.netIncome > 300000
              ? handleDecimalsOnValue(150000)
              : props.netIncome - 150000 < 0
              ? 0
              : handleDecimalsOnValue(props.netIncome - 150000)}
          </td>
          <td>
            {props.netIncome - 150000 > 300000
              ? handleDecimalsOnValue(150000 * 0.05)
              : handleDecimalsOnValue((props.netIncome - 150000) * 0.05)}
          </td>
        </tr>
        <tr>
          <td>300,001 - 500,000</td>
          <td>10%</td>
          <td>
            {props.netIncome > 500000
              ? handleDecimalsOnValue(200000)
              : props.netIncome - 300000 < 0
              ? 0
              : handleDecimalsOnValue(props.netIncome - 300000)}
          </td>
          <td>
            {props.netIncome - 300000 > 300000
              ? handleDecimalsOnValue(200000 * 0.1)
              : handleDecimalsOnValue((props.netIncome - 300000) * 0.1)}
          </td>
        </tr>
        <tr>
          <td>500,001 - 750,000</td>
          <td>15%</td>
          <td>
            {props.netIncome > 750000
              ? handleDecimalsOnValue(250000)
              : props.netIncome - 500000 < 0
              ? 0
              : handleDecimalsOnValue(props.netIncome - 500000)}
          </td>

          <td>
            {props.netIncome - 500000 > 750000
              ? handleDecimalsOnValue(250000 * 0.15)
              : handleDecimalsOnValue((props.netIncome - 500000) * 0.15)}
          </td>
        </tr>
        <tr>
          <td>750,001 - 1,000,000</td>
          <td>20%</td>
          <td>
            {props.netIncome > 1000000
              ? handleDecimalsOnValue(250000)
              : props.netIncome - 750000 < 0
              ? 0
              : handleDecimalsOnValue(props.netIncome - 750000)}
          </td>
          <td>
            {props.netIncome - 750000 > 1000000
              ? handleDecimalsOnValue(250000 * 0.2)
              : handleDecimalsOnValue((props.netIncome - 750000) * 0.2)}
          </td>
        </tr>
        <tr>
          <td>1,000,001 - 2,000,000</td>
          <td>25%</td>
          <td>
            {props.netIncome > 2000000
              ? handleDecimalsOnValue(1000000)
              : props.netIncome - 1000000 < 0
              ? 0
              : handleDecimalsOnValue(props.netIncome - 1000000)}
          </td>

          <td>
            {props.netIncome - 1000000 > 2000000
              ? handleDecimalsOnValue(1000000 * 0.25)
              : handleDecimalsOnValue((props.netIncome - 1000000) * 0.25)}
          </td>
        </tr>
        <tr>
          <td>2,000,001 - 5,000,000</td>
          <td>30%</td>
          <td>
            {props.netIncome > 5000000
              ? handleDecimalsOnValue(3000000)
              : props.netIncome - 2000000 < 0
              ? 0
              : handleDecimalsOnValue(props.netIncome - 2000000)}
          </td>

          <td>
            {props.netIncome - 2000000 > 5000000
              ? handleDecimalsOnValue(3000000 * 0.3)
              : handleDecimalsOnValue((props.netIncome - 3000000) * 0.3)}
          </td>
        </tr>
        <tr>
          <td>5,000,000 - ไม่จำกัด</td>
          <td>35%</td>
          <td>{props.netIncome > 5000000 ? handleDecimalsOnValue(props.netIncome - 5000000) : 0}</td>

          <td>
            {props.netIncome - 5000000 > 5000000
              ? handleDecimalsOnValue(5000000 * 0.35)
              : handleDecimalsOnValue((props.netIncome - 5000000) * 0.35)}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>ผลรวม</td>
          <td>{handleDecimalsOnValue(summaryTax)}</td>
        </tr>
      </table>
    </section>
  )
}
