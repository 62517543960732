import { PutTimeAttendanceConfigInput, TimeAttendanceConfig } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationPutTimeAttendanceConfig } from '../xhr/mutation'
import { queryGetTimeAttendanceConfig } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function getTimeAttendanceConfig() {
  return gqlResponseType<TimeAttendanceConfig>(
    post('/', {
      query: queryGetTimeAttendanceConfig,
    }),
    'getTimeAttendanceConfig',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------

export function putTimeAttendanceConfig(input: PutTimeAttendanceConfigInput) {
  return gqlResponseType<TimeAttendanceConfig>(
    post('/', {
      query: mutationPutTimeAttendanceConfig,
      variables: { input },
    }),
    'putTimeAttendanceConfig',
  )
}
