// import React from 'react'
import { toTime } from './common'

export enum ETranformationDataToClient {
  LeaveDocumentList = 'LEAVE_DOCUMENT_LIST',
  OvertimeDocumentList = 'OVERTIME_DOCUMENT_LIST',
  WorkTimelist = 'WORK_TIME_LIST',
  OvertimeResetData = 'OVERTIME_RESET_DATA',
  OvertimeReport = 'OVERTIME_LIST_REPORT',
}

const CheckType = (type: string, value: string) => {
  if (type === 'FULLDAY_LEAVE') {
    return value.split('T')[0]
  }
  return toTime(value)
}

export const transformationDataToClient = ({ values, type }: { values: any; type: ETranformationDataToClient }) => {
  switch (type) {
    case ETranformationDataToClient.LeaveDocumentList:
      const _leaveList: any[] = []
      values.leave.map((leaveElement: any) => {
        _leaveList.push({
          ...leaveElement,
          startedAt: CheckType(leaveElement.leaveForm, leaveElement.startedAt),
          endedAt: CheckType(leaveElement.leaveForm, leaveElement.endedAt),
          type: 'leave',
        })
      })

      values.leaveDeduct.map((leaveElement: any) => {
        _leaveList.push({
          ...leaveElement,
          startedAt: CheckType(leaveElement.leaveForm, leaveElement.startedAt),
          endedAt: CheckType(leaveElement.leaveForm, leaveElement.endedAt),
          type: 'leaveDeduct',
        })
      })

      return _leaveList

    case ETranformationDataToClient.OvertimeDocumentList:
      const overtimeList: any[] = []
      values.overtime.map((OverTimeList: any) => {
        overtimeList.push({
          ...OverTimeList,
          startedAt: toTime(OverTimeList.startedAt),
          endedAt: toTime(OverTimeList.endedAt),
          type: 'overtime',
        })
      })

      values.overtimeApproveList.map((OverTimeElement: any) => {
        overtimeList.push({
          ...OverTimeElement,
          startedAt: toTime(OverTimeElement.startedAt),
          endedAt: toTime(OverTimeElement.endedAt),
          type: 'overtimeApprove',
        })
      })

      return overtimeList

    case ETranformationDataToClient.WorkTimelist:
      const WorkTimeList: any = []
      values.map((workItem: any) => {
        WorkTimeList.push({
          ...workItem,
          startedAt: toTime(workItem.startedAt),
          endedAt: toTime(workItem.endedAt),
        })
      })
      return WorkTimeList

    // case ETranformationDataToClient.OvertimeReport:
    //   const newOvertimeList: any[] = []
    //   const newOvertimeApprove: any[] = []
    //   deepClone(values.report.overtimeList || []).map((overtimeObject: any) => {
    //     overtimeObject.startedAt = new Date(`${props.Date}:${overtimeObject.startedAt}`).toISOString()
    //     overtimeObject.endedAt = new Date(`${props.Date}:${overtimeObject.endedAt}`).toISOString()
    //     if (overtimeObject.type === 'overtime') {
    //       delete overtimeObject.type
    //       return newOvertimeList.push(overtimeObject)
    //     } else {
    //       delete overtimeObject.type
    //       return newOvertimeApprove.push(overtimeObject)
    //     }
    //   })
    //   return ''

    default:
      break
  }
}

export enum ETranformationDataToServer {
  UpdateAttendance = 'UPDATE_ATTENDANCE',
}

export const transformationDataToServer = ({ values, type }: { values: any; type: ETranformationDataToServer }) => {
  switch (type) {
    case ETranformationDataToServer.UpdateAttendance:
      const reportInput = values.report
      const dataInput = values.attendance
      return {
        input: {
          employeeID: dataInput.employeeID,
          date: dataInput.date,
          scanIn: reportInput?.scanIn,
          scanOut: reportInput?.scanOut,
          overtimeApproveList: reportInput?.overtimeApproveList,
          overtimeList: reportInput?.overtimeList,
          leaveDeductList: reportInput?.leaveDeductList,
          leaveList: reportInput?.leaveList,
          inDiffMinute: reportInput?.inDiffMinute,
          breakDiffMinute: reportInput?.breakDiffMinute,
          outDiffMinute: reportInput?.outDiffMinute,
          isLink: dataInput.isLink,
          Type: reportInput?.Type,
          leaveDeductMinute: reportInput?.leaveDeductMinute,
          leaveMinute: reportInput?.leaveMinute,
          overtimeApproveMinute: reportInput?.overtimeApproveMinute,
          overtimeMinute: reportInput?.overtimeMinute,
          workingMinute: reportInput?.workingMinute,
          workingTimeList: reportInput?.workingTimeList,
        },
      }

    default:
      break
  }
}
