import { ApolloError, ApolloQueryResult, DocumentNode, OperationVariables, useQuery } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FetchData, GenericQuery } from 'src/adapter/xhr/interface'
// import { Maybe } from 'src/utils/generated'
import swal from 'sweetalert'

/**
 * For usage of pagination in the actual page itself
 * will include cache of that particular data query from apollo
 *
 * @param currentTotalPageCount - The current max page count
 * @param currentPage - Current page index + 1 (1-indexed) ie. 1 2 3 ...
 * @param hasNextToken - Whether there is nextToken in the latest query
 * @param windowSize - The pagePills that will be shown alongside the currentPage
 * @returns PaginationRange ie. [1 ... 14 15 16  17]
 */
// TypeScript Generics for Arrow Function
// https://stackoverflow.com/questions/32308370/what-is-the-syntax-for-typescript-arrow-functions-with-generics
const usePaginationQuery = <DataType>({
  query,
  convertRefetch,
  additionalVariables = {},
  initialPageSize = 50,
  initialWindowSize = 7,
}: IUsePaginationQuery<DataType>): IUsePaginationQueryOutput<DataType> => {
  // Pagination State
  const [pageSize, setPageSize] = useState<number>(initialPageSize)
  const [windowSize, setWindowSize] = useState<number>(initialWindowSize)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const limit = pageSize * windowSize
  const siblingCount = (windowSize - 1) / 2
  // Actual Data
  const { loading, error, data, refetch } = useQuery<FetchData<DataType>>(query, {
    variables: { limit, nextToken: null, ...additionalVariables },
    fetchPolicy: 'network-only', // tryto do debugging
    notifyOnNetworkStatusChange: true, // set loading to true everytime it refetches
  })
  const [fetchPageGroupIndex, setFetchPageGroupIndex] = useState<number>(0)
  //   console.log('loading', loading)
  //   console.log('error')
  //   console.log(error)
  //   console.log('fetchedData')
  //   console.log(data)

  const [dataCache, setDataCache] = useState<Array<GenericQuery<DataType>>>([])
  const currentMaxPageGroupIndex = dataCache.length - 1
  const currentMaxPageIndex =
    currentMaxPageGroupIndex >= 0
      ? currentMaxPageGroupIndex * windowSize +
        Math.ceil(dataCache[currentMaxPageGroupIndex].items.length / pageSize) -
        1 // full blocks + partial blocks
      : -1
  const maxHasNextToken =
    currentMaxPageGroupIndex >= 0 ? (dataCache[currentMaxPageGroupIndex].nextToken ? true : false) : false
  const currentTableData = useMemo(() => {
    return dataCache.length > 0 ? getDataFromCache<DataType>(currentPage, dataCache, refetch) : []
  }, [currentPage, dataCache, pageSize])

  // console.log('currentPage', currentPage)
  // console.log('dataCache', dataCache)
  // console.log('currentTableData', currentTableData)

  //Everytime data changes, put it in dataCache
  // This doesn't work when data is used from Cache!! it will not trigger since same object! so I put in loading too.
  useEffect(() => {
    if (data && !error && !loading) {
      const pageGroupIndex = fetchPageGroupIndex
      // Update Data Cache and wait for next refresh
      // console.log('I JUST QUERIED DATA!!', data)
      const clonedDataCache = [...dataCache] // Need to clone else the pagination will not refresh and be stuck!!
      clonedDataCache[pageGroupIndex] = convertRefetch(data)
      setDataCache(clonedDataCache)
      // console.log('clonedDataCache')
      // console.log(clonedDataCache)
    } else if (error) {
      // If error, set fetch Page to the prev one
      setFetchPageGroupIndex(fetchPageGroupIndex - 1)
      swal({
        icon: 'error',
        title: error.name,
        text: error.message,
        dangerMode: true,
      })
    }
  }, [data, loading])

  // Everytime change pageSize, just reset cache & refetch
  useEffect(() => {
    clearCacheRefetch()
  }, [pageSize])

  const clearCacheRefetch = (customVariables?: Partial<OperationVariables> | undefined) => {
    setDataCache([])
    setFetchPageGroupIndex(0)
    return refetch({ limit, nextToken: null, ...additionalVariables, ...customVariables })
  }

  const getFlattenDataCache = () => dataCache.flatMap((e) => e.items)

  /**
   * Returns the data slice from Cache
   *
   * @param currentPage - The current page Index
   * @param dataCache - the data cache
   * @param refetchDataCache - function to refetch the data cache
   * @param convertRefetch - transform graphql fetches to the actual data (with the key of the query)
   * @returns data slice
   */
  function getDataFromCache<T>(
    currentPage: number,
    dataCache: Array<GenericQuery<T>>,
    // setDataCache: React.Dispatch<React.SetStateAction<Array<GenericQuery<T>>>>,
    refetchDataCache: (
      variables?: Partial<OperationVariables> | undefined,
    ) => Promise<ApolloQueryResult<Record<string, GenericQuery<T>>>>,
    // convertRefetch: (result: Record<string, GenericQuery<T>>) => GenericQuery<T>,
  ): T[] | null {
    // Change from currentPage --> pageGroupIndex
    const pageGroupIndex = Math.floor((currentPage - 1) / windowSize)
    // Start, End Index of that page group index
    const i = (currentPage - 1) % windowSize
    const startIndex = i * pageSize
    const endIndex = (i + 1) * pageSize

    // Check 2: If requires data fetching:
    //           Not at the end! (End has next token)
    //           && currentPage + siblingCount > currentMaxPageIndex + 1 (total # pages)
    //           && isn't already fetching
    const maxHasNextToken = dataCache[dataCache.length - 1].nextToken ? true : false
    const isFetchRequired =
      maxHasNextToken &&
      currentPage + siblingCount > currentMaxPageIndex + 1 &&
      fetchPageGroupIndex !== pageGroupIndex + 1
    // ASSUME that the prev has nextToken!!! Hopefully windowSize is large enough compared to number of pills. This will have no problem
    if (isFetchRequired && dataCache[pageGroupIndex]) {
      setFetchPageGroupIndex(pageGroupIndex + 1) // set what pageGroupIndex that I will fetch, so I know where to update & what i am fetching
      refetchDataCache({ limit, nextToken: dataCache[pageGroupIndex].nextToken, ...additionalVariables })
    }

    // If have, return from Cache; Else return nothing
    if (dataCache[pageGroupIndex]) return dataCache[pageGroupIndex].items.slice(startIndex, endIndex)
    else return null
  }

  // console.log('currentMaxPageIndex', currentMaxPageIndex)

  return {
    currentTableData,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    windowSize,
    maxHasNextToken,
    currentMaxPageIndex,
    loading,
    clearCacheRefetch,
    error,
    getFlattenDataCache,
    dataCache,
  }
}

export default usePaginationQuery

interface IUsePaginationQuery<T> {
  query: DocumentNode
  convertRefetch: (result: Record<string, GenericQuery<T>>) => GenericQuery<T>
  additionalVariables?: Partial<OperationVariables> | undefined
  initialPageSize?: number
  initialWindowSize?: number
}

export interface IUsePaginationQueryOutput<T> {
  currentTableData: Array<T> | null
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  windowSize: number
  maxHasNextToken: boolean
  currentMaxPageIndex: number
  loading: boolean
  clearCacheRefetch: (
    customVariables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<FetchData<T>>>
  error: ApolloError | undefined
  getFlattenDataCache: () => Array<T>
  dataCache: Array<GenericQuery<T>>
}

// const currentTableData = useMemo(() => {
//     if (loadingAttendLog || !dataAttendLog) return []
//     const startPageIndex = currentPage * pageSize
//     const endPageIndex = startPageIndex + pageSize
//     return dataAttendLog.listTimeAttendance.items
//   }, [currentPage, dataAttendLog])
