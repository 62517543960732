import { useFormikContext } from 'formik'
import React from 'react'

//Images
import styles from './index.module.scss'
import { handleDecimalsOnValue } from 'src/utils/common'
import { TableCalculateTaxes } from '../EmployeeTax/table'

interface Props {
  totalIncomePerYear: any
  sumDiscount: any
  sumNetIncome: any
  calculateTaxPerMonth: any
}
const EmployeeTaxResult: React.FC<Props> = (props: Props): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <p className={styles.title}>คาดการณ์รายได้</p>
        <div className={styles.line} />
        <div className={styles.groupTextSum}>
          <div className={styles.wrapperText}>
            <p>รวมรายได้ : </p>
            <div className={styles.numberText}>
              <p className={styles.textHead}>{handleDecimalsOnValue(props.totalIncomePerYear)}</p>
              <p>บาท</p>
            </div>
          </div>
        </div>
        <div className={styles.groupTextSum}>
          <div className={styles.wrapperText}>
            <p>รวมค่าใช้จ่ายและค่าลดหย่อน : </p>
            <div className={styles.numberText}>
              <p className={styles.textHead}>{handleDecimalsOnValue(props.sumDiscount)}</p>
              <p>บาท</p>
            </div>
          </div>
        </div>
        <div className={styles.groupTextSum}>
          <div className={styles.wrapperText}>
            <p>เงินได้สุทธิ : </p>
            <div className={styles.numberText}>
              <p className={styles.textHead}>
                {props.sumNetIncome < 0 ? '-' : handleDecimalsOnValue(props.sumNetIncome)}
              </p>
              <p>บาท</p>
            </div>
          </div>
        </div>

        <div className={styles.wrapperTable}>
          <TableCalculateTaxes netIncome={props.sumNetIncome} />
        </div>

        <div className={styles.bottomGroup}>
          <p className={styles.text}>ภาษีที่ต้องจ่ายปีนี้ :</p>
          <div className={styles.wrapperText}>
            <p className={styles.textResult}>{handleDecimalsOnValue(values.employeeTax.totalTaxExpense)}</p>
            <p className={styles.text}>บาท</p>
          </div>
        </div>
        <p className={styles.textSub}>เฉลี่ยเดือนละ : {props.calculateTaxPerMonth()} บาท</p>
      </div>
    </div>
  )
}

export default EmployeeTaxResult
