import { Avatar, AvatarGroup, Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ButtonComponent } from '../../component'
import Dropper from '../../image/dropper.svg'
import ImgIcon from '../../image/picUser.svg'
import allAction from '../../state/actions'
import styles from './index.module.scss'

const TranPackage: React.FC = (): JSX.Element => {
  const [packageId, setPackageId] = useState<string>('')
  const [userGroup, setUserGroup] = useState<any>([])
  const [hover, setHover] = useState(-1)
  const [avatarshow, setAvatarShow] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const showCartHandler = (index: any) => {
    setHover(index)
  }
  const mockUser = [
    {
      name: 'แผนกบัญชี',
      user: [
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '01',
          packageId: '01',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '02',
          packageId: '01',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '03',
          packageId: '02',
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
          id: '04',
          packageId: '01',
        },
      ],
    },
    {
      name: 'แผนกการตลาด',
      user: [
        {
          name: 'Adison Saris',
          img: ImgIcon,
          money: 300,
          id: '08',
          packageId: '01',
        },
        {
          name: 'Adison Saris',
          img: ImgIcon,
          money: 300,
          id: '09',
          packageId: '01',
        },
        {
          name: 'Adison Saris',
          img: ImgIcon,
          money: 300,
          id: '10',
          packageId: '03',
        },
      ],
    },
    {
      name: 'แผนกฝ่ายบุคคล',
      user: [
        {
          name: 'Talan Schleifer',
          img: ImgIcon,
          money: 300,
          id: '11',
          packageId: '02',
        },
        {
          name: 'Beam Schleifer',
          img: ImgIcon,
          money: 300,
          id: '12',
          packageId: '01',
        },
      ],
    },
  ]
  const PackageData: any[] = [
    {
      name: 'เเพ็กเกจ 01',
      id: '01',
      leaveDetail: [
        {
          name: 'ลาป่วย',
          workAge: 18,
          total: 70,
          totalofLeave: 8,
        },
        {
          name: 'ลาพบเเพทย์',
          workAge: 24,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลากิจ',
          workAge: 121,
          total: 6,
          totalofLeave: 0,
        },
        {
          name: 'ลาบวช',
          workAge: 4,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลาคลอด',
          workAge: 18,
          total: 70,
          totalofLeave: 0,
        },
      ],
      userLits: [
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
      ],
    },
    {
      id: '02',
      name: 'เเพ็กเกจ 02',
      leaveDetail: [
        {
          name: 'ลาป่วย',
          workAge: 18,
          total: 70,
          totalofLeave: 8,
        },
        {
          name: 'ลาพบเเพทย์',
          workAge: 24,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลากิจ',
          workAge: 121,
          total: 6,
          totalofLeave: 0,
        },
        {
          name: 'ลาบวช',
          workAge: 4,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลาคลอด',
          workAge: 18,
          total: 70,
          totalofLeave: 0,
        },
      ],
      userLits: [
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
      ],
    },
    {
      id: '03',
      name: 'เเพ็กเกจ 03',
      leaveDetail: [
        {
          name: 'ลาป่วย',
          workAge: 18,
          total: 70,
          totalofLeave: 8,
        },
        {
          name: 'ลาพบเเพทย์',
          workAge: 24,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลากิจ',
          workAge: 121,
          total: 6,
          totalofLeave: 0,
        },
        {
          name: 'ลาบวช',
          workAge: 4,
          total: 70,
          totalofLeave: 0,
        },
        {
          name: 'ลาคลอด',
          workAge: 18,
          total: 70,
          totalofLeave: 0,
        },
      ],
      userLits: [
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
        {
          name: 'Zaire Siphron',
          img: ImgIcon,
          money: 300,
        },
      ],
    },
  ]

  useEffect(() => {
    console.log('Group User', userGroup)
  }, [userGroup])

  //SELECT Employee Tran Package
  const handleselect = (e: any, item: any) => {
    const { value, checked } = e.target
    const id = item.id
    setUserGroup([...userGroup, item])
    if (!checked) {
      setUserGroup(userGroup.filter((item: any) => item.id !== id))
    }
  }

  const FilterItem = (id: any) => {
    return console.log('Filter')
  }

  return (
    <div className={styles.mainInPage}>
      <div className={styles.headZone}>
        <h1 className={styles.title}>ย้ายแพ็กเกจพนักงาน</h1>
        <div className={styles.btnZone}>
          <div className={styles.button}>
            <ButtonComponent
              _colorBG="blue"
              _text="บันทึก"
              _colorText="white"
              _type={'button'}
              _variant="contained"
              _functionOnClick={() => {
                navigate(-1)
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.dataZone}>
        <div className={styles.rowUserZone} style={{ width: '50%', backgroundColor: '#fff' }}>
          {mockUser.map((c: any, n: number) => {
            return (
              <div className={styles.oneDepartment} key={n}>
                <h4 className={styles.departmentText}>{c.name}</h4>
                <div className={styles.allUser}>
                  {c.user
                    .filter((item: any) => {
                      if (packageId === '') return item
                      else {
                        return item.packageId === packageId
                      }
                    })
                    .map((itemUser: any, i: number) => {
                      return (
                        <div className={styles.oneUser} key={i}>
                          <div className={styles.leftZoneUser}>
                            <img className={styles.userImg} src={itemUser.img} alt={itemUser.name} />
                            <div>{itemUser.name}</div>
                          </div>
                          <Checkbox
                            sx={{
                              color: '#5357BB',
                              '&.Mui-checked': {
                                color: '#5357BB',
                              },
                            }}
                            onChange={(e: any) => handleselect(e, itemUser)}
                          />
                        </div>
                      )
                    })}
                </div>
              </div>
            )
          })}
        </div>
        <div className={styles.allPackage}>
          <div className={styles.GroupPackage}>
            {PackageData.map((e: any, Index: number) => {
              return (
                <div
                  className={styles.onePackage}
                  key={Index}
                  onClick={() => {
                    showCartHandler(Index)
                    setPackageId(e.id)
                  }}
                >
                  {avatarshow && hover === Index ? (
                    <div className={styles.DropArea}>
                      <AvatarGroup className={styles.allAvata} max={3}>
                        {userGroup.map((item: any, userIndex: number) => (
                          <Avatar key={userIndex} src={item.img}></Avatar>
                        ))}
                      </AvatarGroup>
                    </div>
                  ) : (
                    <div className={styles.DropArea}>
                      {userGroup.length && hover === Index ? (
                        <div>
                          <img
                            src={Dropper}
                            alt=""
                            className={styles.drop}
                            onClick={() => {
                              dispatch(
                                allAction.modalConfirmAction.setOpenModalConfirm(
                                  'ยืนยันการทำรายการ',
                                  `คุณต้องการย้ายพนักงาน ${userGroup.length} คน ไปยังแพ็กเกจ ${e.name}`,
                                  '',
                                  'ใช่,ยืนยัน',
                                  () => {
                                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                                    dispatch(
                                      allAction.modalAction.setOpenModalAlertStatus(
                                        'ย้ายแพ็กเกจเรียบร้อยแล้ว',
                                        '',
                                        'success',
                                      ),
                                    )
                                  },
                                ),
                              )

                              setAvatarShow(!avatarshow)
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  )}

                  <h2>{e.name}</h2>
                  <div className={styles.detailInOnePackage}>
                    {e.leaveDetail.map((a: any, n: number) => {
                      return (
                        <div className={styles.detailItem} key={n}>
                          <div>{a.name}</div>
                          <div>{a.totalofLeave}</div>
                        </div>
                      )
                    })}
                  </div>
                  <AvatarGroup className={styles.allAvata} max={4}>
                    {e.userLits.map((q: any, w: number) => {
                      return <Avatar key={w} alt={q.name} src={q.img} />
                    })}
                  </AvatarGroup>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default TranPackage
