import React from 'react'
import styles from './index.module.scss'

interface Props {
  topic: string
  amount: number
  approve: number
  spent: number
  balance: number
  unit: string
}

const Eunit: any = {
  PER_YEAR: 'วัน/ปี',
  BAHT_PER_YEAR: 'บาท/ปี',
}

const CheckType = (type: any) => {
  if (typeof type === 'number') {
    return type.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return type
  }
}

const CardQuotaComponent: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <>
      <div className={styles.cardContainer}>
        <p className={styles.textHead}>{props.topic}</p>
        <section className={styles.formText}>
          <p>โควต้า</p>
          <span>
            {props.amount < 0 ? <p>ไม่จำกัด</p> : CheckType(props.amount)}
            {props.amount < 0 ? '' : <span>{Eunit[`${props.unit}`]}</span>}
          </span>
        </section>
        <section className={styles.formText}>
          <p>ใช้ไป</p>
          <span>
            {props.spent} <span>{Eunit[`${props.unit}`]}</span>
          </span>
        </section>
        <section className={styles.formText}>
          <p>อนุมัติ</p>
          <span>
            {props.approve} <span>{Eunit[`${props.unit}`]}</span>
          </span>
        </section>

        <section className={styles.formText}>
          <p className={styles.text}>คงเหลือ</p>
          <span className={styles.textSum}>
            {props.balance < 0 ? <p>ไม่จำกัด</p> : CheckType(props.balance)}
            {props.amount < 0 ? '' : <span>{Eunit[`${props.unit}`]}</span>}
          </span>
        </section>
      </div>
    </>
  )
}
export default CardQuotaComponent
