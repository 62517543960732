import { CreateProvidentFundConfigInput, ProvidentFundConfig } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationCreateProvidentFundConfig } from '../xhr/mutation'
import { queryGetProvidentFundConfig } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function getProvidentFundConfig() {
  return gqlResponseType<ProvidentFundConfig>(
    post('/', {
      query: queryGetProvidentFundConfig,
    }),
    'getProvidentFundConfig',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------

export function createProvidentFundConfig(input: CreateProvidentFundConfigInput) {
  return gqlResponseType<ProvidentFundConfig>(
    post('/', {
      query: mutationCreateProvidentFundConfig,
      variables: { input },
    }),
    'createProvidentFundConfig',
  )
}
