import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ButtonComponent, CardMasterDate } from '../../component'
import allAction from '../../state/actions'
import styles from './index.module.scss'
// Components
const BenefitPolicyPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      <section className={styles.topicPageWrapper}>
        <p className={styles.topicPage}>สวัสดิการ</p>
      </section>
      <div className={styles.contentContainer}>
        <section className={styles.btnTopWrapper}>
          <div className={styles.btnTop}>
            <ButtonComponent
              _type="button"
              _colorBG="blue"
              _colorText="white"
              _variant="outlined"
              _isIcon="add"
              _text="เพิ่มสวัสดิการ"
              _functionOnClick={() => navigate('add')}
            />
          </div>
        </section>
        <section className={styles.cardDetailWrapper}>
          {BenefitCard.map((data: any, index: number) => {
            return (
              <div key={index}>
                <CardMasterDate
                  _topic={data.topic}
                  _subTopic={data.subTopic}
                  _title={data.title}
                  _content={data.content}
                  _functionOnClickEdit={() => navigate('edit')}
                  _functionOnClickDelete={() =>
                    dispatch(
                      allAction.modalConfirmAction.setOpenModalConfirm(
                        'ลบสวัสดิการ',
                        'คุณต้องการลบรายการ สวัสดิการ ใช่หรือไม่',
                        'หากลบแล้ว ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                        'ใช่, ลบรายการ',
                        () => {
                          dispatch(
                            allAction.modalAction.setOpenModalAlertStatus(
                              'ลบข้อมูลรายการสวัสดิการเรียบร้อยแล้ว',
                              '',
                              'success',
                            ),
                          )
                          dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                        },
                      ),
                    )
                  }
                />
              </div>
            )
          })}
        </section>
      </div>
    </div>
  )
}
export default BenefitPolicyPage
const BenefitCard = [
  { topic: 'ขนม', subTopic: 'สวัสดิการ', title: 'งบ / เดือน', content: '1,000 .-' },
  { topic: 'การเดินทาง', subTopic: 'ค่าใช้จ่าย', title: 'งบ / เดือน', content: '1,000 .-' },
]
