import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { useMemo } from 'react'
import { mutationAttendanceReport, MUTATION_APPROVE_TIMEATTENDANCE } from 'src/adapter/xhr/mutation'
import { queryGetAttendance } from 'src/adapter/xhr/query'
import useHandleResponseAPI from '../useHandleResponseAPI'

function useTimeAttendanceReport(varibles?: any) {
  const [getTimeReport, { loading, error, refetch }] = useLazyQuery(gql(queryGetAttendance), {
    fetchPolicy: 'no-cache',
    variables: varibles,
  })
  const [updateAttendanceReport, optionUpdateAttendance] = useMutation(gql(mutationAttendanceReport))
  const [approveAttendanceReport, optionApprove] = useMutation(gql(MUTATION_APPROVE_TIMEATTENDANCE))

  const isLoading = useMemo(() => {
    return loading || optionUpdateAttendance.loading || optionApprove.loading
  }, [loading, optionUpdateAttendance.loading, optionApprove.loading])

  const errMsg = useMemo(() => {
    if (error) return JSON.stringify(error)
    else if (optionUpdateAttendance?.error) return JSON.stringify(optionUpdateAttendance?.error)
    else if (optionApprove?.error) return JSON.stringify(optionApprove?.error)
    else return ''
  }, [error, optionUpdateAttendance.error, optionApprove.error])

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg,
  })

  return { refetch, getTimeReport, loading, updateAttendanceReport, approveAttendanceReport }
}

export default useTimeAttendanceReport
