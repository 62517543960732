import {
  Box,
  Grid,
  Stack,
  useTheme,
  Paper,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useFormControl,
} from '@mui/material'
import { FastField, FastFieldProps, Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect } from 'react'
import {
  EPeriodCount,
  EWorkingHourDivisor,
  EWorkingDayCount,
  ERoundType,
  PayrollConfig,
  EFulltimeDayPaidHoliday,
  PutPayrollConfigInput,
} from '../../utils/generated'
import {
  ContentContainer,
  FastFieldProvider,
  InputSelectComponent,
  InputDateComponent,
  ButtonComponent,
  InputDateComponentCustom,
  InputDateComponentNoFormik,
} from '../../component'
import { deepClone } from '../../utils/common'
import { MONTH_THAI } from '../../utils/enums'
import { getPayrollConfig, putPayrollConfig } from '../../adapter/api'
import Swal from 'sweetalert'
import moment from 'moment'

type IValue = Partial<PayrollConfig>

const now = new Date()

const getUnIntersection = <T,>(list: T[], removeList: T[]): T => {
  return list.find((e) => !removeList.includes(e)) as T
}

const cutDate = (date: Date) => {
  const [y, m, d] = date.toISOString().split('T')[0].split('-')
  return `${Number(d)} ${MONTH_THAI[Number(m) - 1]}`
}

const WithDate = (date: string): Date => {
  const isDate = date.split('-').length === 3
  if (isDate) return new Date(date)
  return new Date(`${now.getUTCFullYear()}-${date}`)
}

const CalculationPolicyPage = () => {
  const initialValues: IValue = {
    periodStart: 1,
    periodCount: EPeriodCount.One,
    period1Day: 1,
    workingDayCount: EWorkingDayCount.N30,
    workingDayCountNewEmployee: EWorkingDayCount.N30,
    resetLeaveDay: '12-31',
    workingHourDivisor: EWorkingHourDivisor.N8,
    roundType: ERoundType.Round,
    fulltimeDayPaidHoliday: EFulltimeDayPaidHoliday.Paid,
  }

  const [prevConfig, setPrevConfig] = React.useState<IValue>()

  const onSubmit = async (values: IValue) => {
    try {
      await putPayrollConfig(values as PutPayrollConfigInput)
      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const periodSelectList = React.useMemo(() => {
    const list = GET_PERIOD()
    return list.map((e) => ({ label: e, value: ~~e.split('-')[0] }))
  }, [])

  const EPeriodCountToNumber: Record<string, number> = {
    [EPeriodCount.One]: 1,
    [EPeriodCount.Two]: 2,
    [EPeriodCount.Three]: 3,
    [EPeriodCount.Four]: 4,
  }

  const caledarSelectBuilder = React.useMemo<number[]>(() => {
    const { periodCount } = formik.values

    if (!periodCount) return []

    const _periodCount = EPeriodCountToNumber[periodCount]

    const selected = [formik.values.period1Day, formik.values.period2Day, formik.values.period3Day]

    const selectedCount = selected.slice(0, _periodCount - 1) as number[]

    for (let i = 0; i < _periodCount - 1; i++) {
      if (selectedCount[i] === undefined || selectedCount[i] === null) {
        selectedCount[i] = getUnIntersection([1, 2, 3, 4, 5, 6, 7], selectedCount)
      }
    }

    return selectedCount
  }, [formik.values.periodCount, formik.values.period1Day, formik.values.period2Day, formik.values.period3Day])

  useEffect(() => {
    if (!formik.values.periodCount) return
    for (let i = 0; i < 4; i++) formik.setFieldValue(`period${i + 1}Day`, caledarSelectBuilder[i])
  }, [formik.values.periodCount])

  const fetchPayrolConfig = async () => {
    const res = await getPayrollConfig()
    setPrevConfig(res || {})
    formik.setValues(res || {})
  }

  React.useEffect(() => {
    fetchPayrolConfig()
  }, [])
  const handleReset = () => {
    formik.setValues(deepClone(prevConfig) as IValue)
  }

  const getValueResetQuota = React.useMemo(() => {
    const { resetLeaveDay } = formik.values

    if (!resetLeaveDay)
      return {
        label: '',
        value: '',
      }

    const startDate = WithDate(resetLeaveDay)
    const endDate = new Date(startDate)
    endDate.setDate(endDate.getDate() - 1)
    return {
      label: `${cutDate(startDate)} - ${cutDate(endDate)}`,
      value: startDate.toISOString().split('T')[0],
    }
  }, [formik.values.resetLeaveDay])

  return (
    <ContentContainer>
      <FormikProvider value={formik}>
        <FastFieldProvider>
          <Form>
            <Stack rowGap="2rem">
              <FormInputWrapper
                description={
                  <>
                    <p>รอบคำนวณเงินเดือนจะคำนวณเมื่อไหร่ ex: ตัดรอบทุกวันที่ 25ของเดือน</p>
                    <p>ระบบจะคำนวณเงิน 26 ธค - 25 มค</p>
                  </>
                }
              >
                <InputSelectComponent _name="periodStart" _menuList={periodSelectList} _label="1. รอบคำนวณเงินเดือน" />
              </FormInputWrapper>
              <FormInputWrapper
                description={
                  <>
                    <p>การแบ่งจ่ายเป็นงวด ภายในเดือน หรือสิ้นสุดรอบตัดเงินเดือนนั้น</p>
                    <p>Ex. รอบตัดเงินเดือนคือ สิ้นเดือน ตั้งค่าคำนวณเงินเดือนระบุไว้ 2 งวด งวดที่ 1</p>
                    <p>คือตั้งแต่วันที่ 1-15 / งวดที่ 2 ตั้งแต่วันที่ 16- สิ้นเดือน</p>
                  </>
                }
              >
                <InputSelectComponent
                  _name="periodCount"
                  _menuList={[
                    {
                      value: EPeriodCount.One,
                      label: 1,
                    },
                    {
                      value: EPeriodCount.Two,
                      label: 2,
                    },
                    {
                      value: EPeriodCount.Three,
                      label: 3,
                    },
                    {
                      value: EPeriodCount.Four,
                      label: 4,
                    },
                  ]}
                  _label="2. แบ่งงวดจ่ายเงิน"
                />
              </FormInputWrapper>

              {formik.values.periodCount && EPeriodCountToNumber[formik.values.periodCount] > 1 && (
                <FormInputWrapper>
                  <Box sx={{ mb: '1rem' }}>วันตัดงวด</Box>
                  <CalendarDayPicker
                    startDay={formik.values.periodStart || 1}
                    selected={caledarSelectBuilder}
                    disabledDays={[29, 30, 31]}
                    onChange={(value) => {
                      for (let i = 0; i < 4; i++) formik.setFieldValue(`period${i + 1}Day`, value[i])
                    }}
                  />
                </FormInputWrapper>
              )}

              <FormInputWrapper
                description={
                  <>
                    <p>ตัวที่จะนำไปหารค่าแรงต่อวัน</p>
                    <p>Ex. ค่าแรงต่อวัน = เงินเดือน / จำนวนวันทำงาน</p>
                  </>
                }
              >
                <FormRadioGroup
                  label="3. จำนวนวันทำงาน"
                  name="workingDayCount"
                  row
                  menuList={[
                    {
                      value: EWorkingDayCount.N30,
                      label: '30 วัน',
                    },
                    {
                      value: EWorkingDayCount.Actual,
                      label: 'ตามจริง',
                    },
                  ]}
                />
              </FormInputWrapper>

              <FormInputWrapper
                description={
                  <>
                    <p>กรณีพนักงาน เข้าใหม่/ลาออก จะไม่ได้ค่าแรงเต็มเดือน</p>
                    <p>จะคำนวณออกมาเป็นค่าแรงต่อวัน แล้วนำไปคูณกับจำนวนวันทำงานที่มาจริง</p>
                    <br />
                    <p>Ex. ค่าแรงต่อวัน = เงินเดือน / จำนวนวันทำงาน</p>
                  </>
                }
              >
                <FormRadioGroup
                  label="4. จำนวนวันทำงาน กรณีพนักงานใหม่/ลาออก"
                  name="workingDayCountNewEmployee"
                  row
                  menuList={[
                    {
                      value: EWorkingDayCount.N30,
                      label: '30 วัน',
                    },
                    {
                      value: EWorkingDayCount.Actual,
                      label: 'ตามจริง',
                    },
                  ]}
                />
              </FormInputWrapper>
              <FormInputWrapper
                description={
                  <>
                    <p>รอบตัดการคำนวณโควต้า อาทิ การลา สวัสดิการ ตัดรอบทุกวันที่เท่าไหร่ของเดือน</p>
                    <p>Ex. ตั้งแต่เดือน 1 มกราคม - 31 ธันวาคม</p>
                  </>
                }
              >
                <InputDateComponentCustom
                  _name="resetLeaveDay"
                  maskValue={getValueResetQuota.label}
                  _label="5. รอบตัดการคำนวณโควต้า"
                  _value={getValueResetQuota.value ?? ''}
                  _onChange={(e) => formik.setFieldValue('resetLeaveDay', moment(e.target.value).format('MM-DD'))}
                />
              </FormInputWrapper>
              <FormInputWrapper
                description={
                  <>
                    <p>ตัวที่จะนำไปหารค่าแรงต่อชั่วโมง</p>
                    <p>Ex. ค่าแรงต่อชั่วโมง = ค่าแรงต่อวัน / จำนวนชั่วโมงการทำงาน</p>
                  </>
                }
              >
                <FormRadioGroup
                  label="6. จำนวนชั่วโมงการทำงาน"
                  name="workingHourDivisor"
                  row
                  menuList={[
                    {
                      value: EWorkingHourDivisor.N7,
                      label: '07:00 ชั่วโมง',
                    },
                    {
                      value: EWorkingHourDivisor.N8,
                      label: '08:00 ชั่วโมง',
                    },
                    {
                      value: EWorkingHourDivisor.N85,
                      label: '08:30 ชั่วโมง',
                    },
                    {
                      value: EWorkingHourDivisor.N9,
                      label: '09:00 ชั่วโมง',
                    },
                  ]}
                />
              </FormInputWrapper>
              <FormInputWrapper
                description={
                  <>
                    <p>ปัดเศษทศนิยมของจำนวนเงินที่เกิดจากการคำนวณของโปรแกรม</p>
                    <p>Ex. พนักงานเงินเดือน 13,000/30=433.33 บาท ถ้าเลือกปัดเศษจะเท่ากับ 433 บาท</p>
                  </>
                }
              >
                <FormRadioGroup
                  label="7. ปัดเศษทศนิยมสำหรับการคำนวณเงิน"
                  name="roundType"
                  row
                  menuList={[
                    {
                      value: ERoundType.Round,
                      label: 'ปัดเศษ',
                    },
                    {
                      value: ERoundType.Floor,
                      label: 'ไม่ปัดเศษ',
                    },
                  ]}
                />
              </FormInputWrapper>
              <FormInputWrapper
                description={
                  <>
                    <p>
                      ถ้าเลือกได้รับค่าแรง ถึงแม้ว่าพนักงานจะไม่มาทำงาน ในวันหยุดระบบก็จะคิดค่าแรงในวันหยุดนักขัตฤกษ์
                    </p>
                  </>
                }
              >
                <FormRadioGroup
                  label="8. พนักงานรายวันได้รับค่าแรงในวันหยุดนักขัตฤกษ์"
                  name="fulltimeDayPaidHoliday"
                  row
                  menuList={[
                    {
                      value: EFulltimeDayPaidHoliday.Paid,
                      label: 'ได้รับค่าแรง',
                    },
                    {
                      value: EFulltimeDayPaidHoliday.Nopaid,
                      label: 'ไม่ได้รับค่าแรง',
                    },
                  ]}
                />
              </FormInputWrapper>

              <Stack direction="row" columnGap="1rem">
                <ButtonComponent
                  _colorBG="white"
                  _colorText="red"
                  _text="ยกเลิก"
                  _type="button"
                  _variant="contained"
                  _sx={{ width: 'fit-content' }}
                  _functionOnClick={handleReset}
                />
                <ButtonComponent
                  _colorBG="blue"
                  _colorText="white"
                  _text="บันทึก"
                  _type="submit"
                  _variant="contained"
                  _sx={{ width: 'fit-content' }}
                />
              </Stack>
            </Stack>
          </Form>
        </FastFieldProvider>
      </FormikProvider>
    </ContentContainer>
  )
}

interface FormInputWrapperProps {
  children: React.ReactNode
  description?: React.ReactNode
}
const FormInputWrapper: React.FC<FormInputWrapperProps> = (props) => {
  return (
    <Grid container columns={2} columnSpacing="4rem" alignItems="center">
      <Grid item xs={1}>
        {props.children}
      </Grid>
      <Grid item xs={1}>
        <Box sx={{ color: 'rgba(0, 0, 0, 0.2)', lineHeight: '120%' }}>{props.description}</Box>
      </Grid>
    </Grid>
  )
}

const GET_PERIOD = (MAX_PERIOD = 28) => {
  const list: string[] = []

  for (let i = 0; i < MAX_PERIOD; i++) {
    const start = i + 1
    const end = i === 0 ? 'EOM' : i
    list.push(`${start}-${end}`)
  }

  return list
}

const InputWrapper: React.FC<{ children: React.ReactNode; label: string }> = ({ children, label }) => {
  const theme = useTheme()
  const { focused } = useFormControl() || {}
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          px: '1rem',
          pb: label ? '0.25rem' : '0',
          borderRadius: '10px',
          border: `1px solid ${focused ? theme.colors.primary : 'rgba(0, 0, 0, 0.23)'}`,
          boxShadow: focused ? `0px 0px 0px 2px ${theme.colors.primary}` : '',
          '&:hover': {
            borderColor: focused ? theme.colors.primary : 'black',
          },
          width: '100%',
        }}
        component="fieldset"
      >
        {label && (
          <Box
            sx={{
              fontSize: '12px',
              color: focused ? theme.colors.primary : 'rgba(0, 0, 0, 0.6)',
              backgroundColor: 'white',
              px: '0.25rem',
            }}
            component="legend"
          >
            {label}
          </Box>
        )}

        {children}
      </Box>
    </>
  )
}

interface CalendarDayPickerProps {
  startDay: number
  disabledDays?: number[]
  selected?: number[]
  onChange?: (value: number[]) => void
}

const CalendarDayPicker: React.FC<CalendarDayPickerProps> = ({
  startDay,
  disabledDays = [],
  selected: _selected,
  onChange,
}) => {
  const [selected, setSelected] = React.useState<number[]>([])

  React.useEffect(() => {
    setSelected(_selected || [])
  }, [_selected])

  const MAX_NUM = 31
  const TOTAL_DAYS = 35

  const SHIFT_COLUMN_INDEX = 2

  const theme = useTheme()

  const dayList = React.useMemo<number[]>(() => {
    const days: number[] = []

    // begin
    for (let i = SHIFT_COLUMN_INDEX; i > 0; i--) {
      const day = startDay - i

      if (day <= 0) days.push(MAX_NUM - i + 1)
      else days.push(day)
    }

    // middle + trailing days
    for (let i = 0; i < TOTAL_DAYS - SHIFT_COLUMN_INDEX; i++) {
      days.push(((startDay + i - 1) % MAX_NUM) + 1)
    }

    return days
  }, [startDay])

  const [hold, setHold] = React.useState<number | null>(null)

  const getDayStyle = (day: number, index: number) => {
    const isDisabled =
      disabledDays.includes(day) || index < SHIFT_COLUMN_INDEX || index > TOTAL_DAYS - SHIFT_COLUMN_INDEX - 1

    const isHold = !isDisabled && day === hold

    const isActive = !isHold && !isDisabled && selected.includes(day)

    return {
      active: isActive,
      hold: isHold,
      disabled: isDisabled,
    }
  }

  const isValidStyle = (style: React.CSSProperties | null | false | undefined) =>
    style !== null && style !== false && style !== undefined

  const styleInline = (...style: (React.CSSProperties | null | false | undefined)[]) => {
    const validStyle = style.filter((e) => isValidStyle(e)) as React.CSSProperties[]

    return validStyle.reduce<Record<string, string>>((acc, curr) => {
      return Object.assign(acc, curr)
    }, {})
  }

  const handleClick = (day: number) => {
    if (!selected.includes(day) && !hold) return

    if (hold === null) {
      setHold(day)
    } else {
      const newSelected = [...selected.filter((e) => e !== hold), day]
      setHold(null)
      setSelected(newSelected)
      onChange && onChange(newSelected.sort((a, b) => a - b))
    }
  }

  return (
    <Paper sx={{ px: '1rem', py: '2rem', userSelect: 'none' }}>
      <Grid container columns={7} columnSpacing="1rem" rowSpacing="4rem">
        {dayList.map((day, index) => (
          <Grid key={index} item xs={1}>
            <Stack alignItems="center">
              <Box
                component="button"
                type="button"
                sx={{
                  backgroundColor: 'white',
                  ...styleInline(
                    getDayStyle(day, index).active && { backgroundColor: theme.colors.primary, color: 'white' },
                    getDayStyle(day, index).hold && { border: `2px dashed ${theme.colors.primary}`, color: 'black' },
                  ),

                  borderRadius: '50%',
                  '&:disabled': {
                    color: '#E0E0E0',
                    cursor: 'not-allowed',
                  },
                  width: '2rem',
                  height: '2rem',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(day)}
                disabled={getDayStyle(day, index).disabled || (hold !== null && hold !== day && selected.includes(day))}
              >
                {day}
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

interface FormRadioGroupMenu {
  value: any
  label: any
  disabled?: boolean
}

interface FormRadioGroupProps {
  menuList: FormRadioGroupMenu[]
  row?: boolean
  name: string
  label?: string
  disabled?: boolean
}
const FormRadioGroup: React.FC<FormRadioGroupProps> = (props) => {
  return (
    <FormControl fullWidth>
      <FastField name={props.name}>
        {({ form, meta }: FastFieldProps) => (
          <InputWrapper label={props.label || ''}>
            <RadioGroup
              row={props.row}
              value={meta.value || ''}
              onChange={(_, value) => form.setFieldValue(props.name, value)}
            >
              {props.menuList.map((menu, index) => (
                <FormControlLabel
                  key={index}
                  value={menu.value}
                  control={
                    <Radio
                      sx={{
                        color: '#5357BB',
                        '&.Mui-checked': {
                          color: '#5357BB',
                        },
                      }}
                      size="small"
                    />
                  }
                  label={menu.label}
                  disabled={props.disabled || menu.disabled}
                />
              ))}
            </RadioGroup>
          </InputWrapper>
        )}
      </FastField>
    </FormControl>
  )
}

export default CalculationPolicyPage
