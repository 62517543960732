import React, { useState } from 'react'
import styles from './index.module.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
//Images
import EditIcon from '../../image/editicon.svg'
import DeleteIcon from '../../image/delete.svg'
import CalendarColored from '../../image/31CalendarColored.svg'
import Calendar from '../../image/31Calendar.svg'

interface Props {
  _topic: string
  _subTopic?: string
  _title?: string
  _content?: string
  _functionOnClickDefault?: any
  isDefault?: boolean
  _functionOnClickEdit?: any
  _functionOnClickDelete?: any
  _style?: number
  _defaultIcon?: boolean
  _defaultData?: any
}
const CardMasterDate: React.FC<Props> = (props: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.contentConteiner}>
          <section className={styles.topicWrapper}>
            <p className={styles.textTopic}>{props._topic}</p>
            {props._subTopic !== ''
              ? props?._subTopic?.split(',').map((line: string, index: number) => {
                  return (
                    <p className={styles.subTopic} key={index}>
                      {line}
                    </p>
                  )
                })
              : ''}

            <button
              type="button"
              className={styles.btnMoreVert}
              id="fade-button"
              aria-controls="fade-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(event: any) => handleClick(event)}
            >
              <MoreVertIcon className={styles.iconMoreVert} />
            </button>
            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
            >
              {props?._functionOnClickDefault ? (
                <MenuItem
                  onClick={() => {
                    handleClose
                    props?._functionOnClickDefault?.()
                  }}
                >
                  <div className={props?.isDefault ? styles.textIconWrapperColored : styles.textIconWrapper}>
                    <img src={props?.isDefault ? CalendarColored : Calendar} /> Default
                  </div>
                </MenuItem>
              ) : null}
              <MenuItem
                onClick={() => {
                  handleClose
                  props?._functionOnClickEdit?.()
                }}
              >
                <div className={styles.textIconWrapper}>
                  <img src={EditIcon} /> Edit
                </div>
              </MenuItem>
              {props.isDefault === true ? (
                ''
              ) : (
                <MenuItem
                  onClick={() => {
                    handleClose
                    props?._functionOnClickDelete?.()
                  }}
                >
                  <div className={styles.textIconWrapper}>
                    <img src={DeleteIcon} /> Delete
                  </div>
                </MenuItem>
              )}
            </Menu>
          </section>
          <section className={styles.footerWrapper}>
            <p className={styles.subTopic}>{props._title}</p>
            <p className={styles.textTopic}>{props._content}</p>
          </section>
        </div>
      </div>
    </>
  )
}
export default CardMasterDate
