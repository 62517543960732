import { Box, BoxProps, styled, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import AddIcon from '../../image/addblue.svg'

type Props = BoxProps & {
  image?: File | string
  onChange?: (files: File | null) => void
  placeholder?: string | JSX.Element
  alt?: string
}

const ImageUpload: React.FC<Props> = ({ image, placeholder, onChange, alt, ...boxProps }) => {
  const previewImage = useMemo(() => {
    if (image instanceof File) return URL.createObjectURL(image)
    return image
  }, [image])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange?.call(null, e.target.files ? e.target.files[0] : null)
  }

  return (
    <Box {...boxProps}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          component="input"
          type="file"
          accept="image/*"
          sx={{ position: 'absolute', inset: 0, opacity: 0, cursor: 'pointer' }}
          onChange={handleChange}
        />
        {image ? <Image src={previewImage} alt={alt} /> : <ImagePlaceholder image={placeholder} />}
      </Box>
    </Box>
  )
}

const ImagePlaceholder = ({ image }: { image?: string | JSX.Element }) => {
  if (!image)
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: '#E9E9E9',
          color: 'rgba(0, 0, 0, 0.2)',
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        UPLOAD LOGO
      </Box>
    )
  if (typeof image === 'string') return <Box component="img" src={image} />
  return image
}

const Image = styled('img')`
  width: 100%;
  height: auto;
  object-fit: cover;
`
export default ImageUpload
