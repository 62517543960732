import { deepClone } from '../../utils/common'
import {
  CreateOvertimeConfigInput,
  EOverTimeType,
  OvertimeConfig,
  UpdateOvertimeConfigInput,
} from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationCreateOvertimeConfig } from '../xhr/mutation'
import { queryGetOvertimeConfig } from '../xhr/query'

function deserializeOvertimeConfig(overtimeConfig: OvertimeConfig) {
  overtimeConfig.overtimeMonth = JSON.parse(overtimeConfig.overtimeMonth || '{}')
  overtimeConfig.overtimeDay = JSON.parse(overtimeConfig.overtimeDay || '{}')
  return overtimeConfig
}

function serializeUpdateOvertimeConfig(overtimeConfig: OvertimeConfig) {
  const serialize = (data: any) =>
    Object.entries(data).map(([key, value]: [string, any]) => {
      return {
        workDay: key,
        overtimeTypeList: [
          {
            overtimeType: EOverTimeType.Work,
            multiply: value[EOverTimeType.Work],
          },
          {
            overtimeType: EOverTimeType.Overtime,
            multiply: value[EOverTimeType.Overtime],
          },
        ],
      }
    })

  overtimeConfig.overtimeMonth = serialize(overtimeConfig.overtimeMonth)
  overtimeConfig.overtimeDay = serialize(overtimeConfig.overtimeDay)
}

// ----------------------------------------------- Query -----------------------------------------------
export async function getOvertimeConfig() {
  const res = await gqlResponseType<OvertimeConfig>(
    post('/', {
      query: queryGetOvertimeConfig,
      variables: {},
    }),
    'getOvertimeConfig',
  )

  return deserializeOvertimeConfig(res)
}

// ----------------------------------------------- Mutation -----------------------------------------------

export async function updateOvertimeConfig(input: Partial<CreateOvertimeConfigInput>) {
  const variables = { input: deepClone(input) }

  serializeUpdateOvertimeConfig(variables.input)

  const res = await gqlResponseType<OvertimeConfig>(
    post('/', {
      query: mutationCreateOvertimeConfig,
      variables,
    }),
    'createOvertimeConfig',
  )

  return deserializeOvertimeConfig(res)
}
