/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme } from '@mui/material/styles'

const colors = {
  colorRed: '#d32f2f',
  feistaRed: '#FF6161',
  lightRed: '#FFF5F8',

  info: '#3080f8',
  primary: '#3a77da',
  secondary: '#5357bb',
  text: '#004ca8',
  darkinfo: '#004CA8',
  mainColorLight: '#cad6ea',
  blueLight: '#EFF3FC',
  blueLight2: '#CAD6EA',
  bgmodal: '#F9F9FB',
  bg: '#e5e5e5',
  bgDark: '#dadae2',
  gray: '#f8f8f8',
  gray1: '#9e9e9e',
  gray2: '#f1f1f1',
  gray3: '#626262',
  gray4: '#bebebe',
  gray5: '#e2e2e2',
  gray6: '#bbbbbb',
  gray7: '#b4b4b4',
  gray8: '#e0e0e0',
  gray9: '#f5f5f5',
  gray10: '#999999',
  grayBg: '#F9F9F9',
  light: '#ffffff',
  light2: '#f5f4f6',
  black: '#393939',
  black2: '#000000',
  card: '#eff3fc',

  greenMint: '#dff7ea',
  greenLemon: '#8ac82d',
  lightGreen: '#E8FFF3',
  green: '#28c76f',
  darkGreen: '#3F8524',
  orange: '#f7af1d',
  cream: '#fdebc6',
  aqua: ' aqua',
}

type IColors = typeof colors

declare module '@mui/material/styles' {
  interface Theme {
    colors: IColors
  }
  //   allow configuration using `createTheme`
  interface ThemeOptions {
    colors: IColors
  }
}

const theme = createTheme({
  colors,
  typography: {
    fontFamily: ['KanitRegular'].join(','),
  },
})

export default theme
