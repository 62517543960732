import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import useIntitialListOption from 'src/hooks/useIntitialListOption'
import { NavTopBarComponent, NavSideBarComponent } from '../component'
// import styles from './index.module.scss'

export const Layout: React.FC = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState<boolean>(false)

  useIntitialListOption()

  return (
    <div className="layout-main">
      <div className="header">
        <NavTopBarComponent isSideNavOpen={isSideNavOpen} setIsSideNavOpen={setIsSideNavOpen} />
      </div>

      <aside className="sidebar" id="side-navigation" data-visible={isSideNavOpen}>
        <NavSideBarComponent isSideNavOpen={isSideNavOpen} setIsSideNavOpen={setIsSideNavOpen} />
      </aside>
      <div className="overlay" onClick={() => setIsSideNavOpen(false)} />
      <div className="main">
        <Outlet />
      </div>
      {/* <div className="footer">Footer</div> */}
    </div>
  )
}
