import { useMutation, gql } from '@apollo/client'
import { useMemo } from 'react'
import { mutationCreateLeaveDocument, mutationDeleteLeave } from 'src/adapter/xhr/mutation'
import useHandleResponseAPI from '../useHandleResponseAPI'

function useLeaveDocument() {
  const [createLeaveDocument, optionCreate] = useMutation(gql(mutationCreateLeaveDocument))
  const [deleteLeaveDocument, optionDelete] = useMutation(gql(mutationDeleteLeave))

  const isLoading = useMemo(() => {
    return optionCreate.loading || optionDelete.loading
  }, [optionCreate.loading, optionDelete.loading])

  const errMsg = useMemo(() => {
    if (optionCreate?.error?.message) return JSON.stringify(optionCreate?.error?.message)
    else if (optionDelete?.error?.message) return JSON.stringify(optionDelete?.error?.message)
    else return ''
  }, [optionCreate.error, optionDelete.error])

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg,
  })

  return {
    createLeaveDocument,
    deleteLeaveDocument,
  }
}

export default useLeaveDocument
