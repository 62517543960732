import React, { useState } from 'react'
import styles from './index.module.scss'
import SearchIcon from './images/search.svg'

import { FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import { useFormikContext, Formik, Form, Field } from 'formik'

interface Props {
  values?: any
  setValues?: any
  color?: string
  label?: string
  name?: string
}
const SearchInput: React.FC<Props> = (props: Props): JSX.Element => {
  // const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<any>()

  return (
    <>
      {/* <input
        type="text"
        name={props.name}
        onChange={(e) => {
          console.log(e, 'e')
          setFieldValue(`${props.name}`, e.target.value)
        }}
      /> */}
      {/* <FormControl fullWidth sx={{ fontFamily: 'KanitRegular' }}>
        <OutlinedInput
          fullWidth
          size="small"
          id="outlined-adornment-amount"
          value={props.values}
          onChange={(e: any) => {
            props.setValues(e.target.value)
          }}
          endAdornment={
            <InputAdornment position="start">
              <img src={SearchIcon} alt="search" width={30} height={30} />
            </InputAdornment>
          }
          sx={{ fontFamily: 'KanitRegular', borderRadius: 3, backgroundColor: `${props.color}` }}
          placeholder={`${props.label}`}
        />
      </FormControl> */}
    </>
  )
}
export default SearchInput
