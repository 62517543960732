import { useLocation } from 'react-router-dom'

interface MyLocation {
  adminOrEmployee: 'ADMIN' | 'EMPLOYEE'
  module: 'HR'
  topic?: string
  subtopic?: string
}
const getMyLocation = (pathname: string): MyLocation => {
  // pathname: "/bbq/pig-pickins"
  const locationSplit = pathname.split('/')
  const adminOrEmployee = locationSplit[1].toUpperCase() as 'ADMIN' | 'EMPLOYEE'
  const module = locationSplit[2].toUpperCase() as 'HR'
  const topic = locationSplit[3]
  const subtopic = locationSplit[4]
  return { adminOrEmployee, module, topic, subtopic }
}

const useMyLocation = (): MyLocation => {
  const location = useLocation()

  const myLocation = getMyLocation(location.pathname)

  return myLocation
}

export default useMyLocation
