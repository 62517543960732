import React from 'react'
// import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'
// import { Formik } from 'formik'
// import * as yup from 'yup'
// import { InputTextComponent, InputTextComponentCustom } from '../../component/InputsFormik'
// import ButtonComponent from '../../component/Button'

import { BellSVG, ChatSVG, QuestionSVG, HumanSVG, UserSVG, DatabilityLogo, LogoIcon, UserInfoIcon } from './images'
// import SearchInput from 'src/component/SearchInput'
import { FormControl, Icon, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const HomePage: React.FC = (): JSX.Element => {
  const navigate = useNavigate()

  // const [anchorEl, setAnchorEl] = useState(null)

  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const validationSchema = yup.object({
  //   search: yup.string().required('กรุณากรอกข้อมูลที่ต้องการค้นหา'),
  // })

  const [value, setValue] = React.useState('')
  return (
    <>
      <div className={styles.container}>
        <section className={styles.navTop}>
          <>
            <img src={LogoIcon} alt="Logo" />
          </>
          <div className={styles.GroupIcon}>
            <section className={styles.Textfield}>
              <FormControl fullWidth sx={{ fontFamily: 'KanitRegular' }}>
                <OutlinedInput
                  fullWidth
                  size="small"
                  id="outlined-adornment-amount"
                  value={value}
                  onChange={(e: any) => {
                    setValue(e.target.value)
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{ fontFamily: 'KanitRegular', borderRadius: 2, backgroundColor: '#ffffff' }}
                  placeholder="Search"
                />
              </FormControl>
            </section>
            <img src={ChatSVG} alt="chatsvg" />
            <img src={BellSVG} alt="bell" />
            <img src={UserInfoIcon} alt="user" />
          </div>
        </section>

        <section className={styles.MenuArea}>
          {Menu.filter((item: any) => {
            if (value === '') {
              return item
            } else if (item.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
              return item
            }
          }).map((itemMenu: any, menuIndex: number) => (
            <div className={styles.IconCard} key={menuIndex} onClick={() => navigate(`${itemMenu.path}`)}>
              <div className={styles.circleMenu}>
                <img src={itemMenu.img} alt="" />
              </div>
              <p className={styles.text}>{itemMenu.label}</p>
            </div>
          ))}
        </section>

        <section className={styles.bottomLogo}>
          <p>From</p>
          <img src={DatabilityLogo} alt="" />
        </section>
      </div>
    </>
  )
}

export default HomePage

const Menu = [
  {
    label: 'User Management',
    img: UserSVG,
    path: '../usermanage/team',
  },
  {
    label: 'Human Resource',
    img: HumanSVG,
    path: '../hr',
  },
]
