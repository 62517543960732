import React from 'react'
import styles from './index.module.scss'
// Components
import { ButtonComponent } from '../../component'

//Formik
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { InputCheckboxComponent, InputRadioComponent, InputSelectComponent, InputTextComponent } from '../../component'
import allAction from '../../state/actions'

const BenefitPolicyEditPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const validationSchema = yup.object({
    nameBenefit: yup.string().required('Name Benefit is required'),
    type: yup.string().required('Type is required'),
    compensation1: yup.string().required('Compensation is required'),
    compensationUnit1: yup.string().required('Compensation Unit is required'),
    compensation2: yup.string().required('Compensation is required'),
    compensationUnit2: yup.string().required('Compensation Unit is required'),
    detail: yup.string().required('Detail is required'),
  })
  return (
    <Formik
      initialValues={{
        nameBenefit: '',
        type: '',
        compensation1: '',
        compensationUnit1: '',
        compensation2: '',
        compensationUnit2: '',
        employee: false,
        daily: false,
        hourly: false,
        outsourceDaily: false,
        outsourceHourly: false,
        outsourceMonthly: false,
        outsourceProject: false,
        detail: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          // alert(JSON.stringify(values, null, 2))
          actions.setSubmitting(false)
        }, 1000)
        dispatch(
          allAction.modalConfirmAction.setOpenModalConfirm(
            'ยืนยันการทำรายการ',
            'คุณต้องการยืนยันการทำรายการ',
            'เพิ่มข้อมูลการลา?',
            'ใช่, ทำรายการ',
            () => {
              dispatch(allAction.modalAction.setOpenModalAlertStatus('เพิ่มรายการเรียบร้อย', '', 'success'))
              dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
            },
          ),
        )
      }}
    >
      {(props: any) => (
        <div className="main">
          <form onSubmit={props.handleSubmit}>
            {console.log('benefit', props.values)}
            <div className={styles.container}>
              <section className={styles.topicPageWrapper}>
                <p className={styles.topicPage}>เพิ่มสวัสดิการ/ค่าใช้จ่าย</p>
              </section>

              <div className={styles.cardContainer}>
                <section className={styles.inputBox}>
                  <InputTextComponent
                    _name="nameBenefit"
                    _label="ชื่อสวัสดิการ/ค่าใช้จ่าย"
                    _helperText={props.errors.nameBenefit}
                  />
                </section>

                <section>
                  <p className={styles.subTopic}>ประเภท</p>
                  <div className={styles.rowWrapper}>
                    <InputRadioComponent
                      _name="type"
                      _menuList={[
                        { label: 'สวัสดิการ', value: 'สวัสดิการ' },
                        { label: 'ค่าใข้จ่าย', value: 'ค่าใข้จ่าย' },
                      ]}
                    />
                  </div>
                </section>

                <section className={styles.rowGapInputContainer}>
                  <div className={styles.rowInputWrapper}>
                    <InputTextComponent
                      _name="compensation1"
                      _label="ค่าตอบแทน"
                      _helperText={props.errors.compensation1}
                    />
                    <InputSelectComponent
                      _name="compensationUnit1"
                      _label="หน่วยเวลา"
                      _helperText={props.errors.compensationUnit1}
                      _menuList={[
                        { label: 'เดือน', value: 'เดือน' },
                        { label: 'ปี', value: 'ปี' },
                      ]}
                    />
                  </div>
                  <div className={styles.rowInputWrapper}>
                    <InputTextComponent
                      _name="compensation2"
                      _label="ค่าตอบแทน"
                      _helperText={props.errors.compensation2}
                    />
                    <InputSelectComponent
                      _name="compensationUnit2"
                      _label="หน่วยเวลา"
                      _helperText={props.errors.compensationUnit2}
                      _menuList={[
                        { label: 'เดือน', value: 'เดือน' },
                        { label: 'ปี', value: 'ปี' },
                      ]}
                    />
                  </div>
                </section>

                <section>
                  <p className={styles.subTopic}>ประเภทงาน</p>
                  <div className={styles.rowWrapper}>
                    {dataCheckBox1.map((Element, index) => {
                      return (
                        <div key={index} className={styles.checkBox}>
                          <InputCheckboxComponent _label={Element.checkboxLabel} _name={Element.checkboxName} />
                        </div>
                      )
                    })}
                  </div>
                  <div className={styles.rowWrapper}>
                    {dataCheckBox2.map((Element, index) => {
                      return (
                        <div key={index} className={styles.checkBox}>
                          <InputCheckboxComponent _label={Element.checkboxLabel} _name={Element.checkboxName} />
                        </div>
                      )
                    })}
                  </div>
                </section>

                <section className={styles.inputBox}>
                  <InputTextComponent _name="detail" _label="รายละเอียด" _helperText={props.errors.detail} />
                </section>

                <section className={styles.btnFooterWrapper}>
                  <div className={styles.btnBox}>
                    <ButtonComponent
                      _colorBG="white"
                      _colorText="red"
                      _text="ยกเลิก"
                      _variant="contained"
                      _type="button"
                      _functionOnClick={() => navigate(-1)}
                    />
                  </div>
                  <div className={styles.btnBox}>
                    <ButtonComponent
                      _colorBG="blue"
                      _colorText="white"
                      _text="เพิ่ม"
                      _variant="contained"
                      _type="submit"
                    />
                  </div>
                </section>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  )
}
export default BenefitPolicyEditPage

const dataCheckBox1 = [
  {
    checkboxLabel: 'พนักงานรายเดือน',
    checkboxName: 'employee',
  },
  {
    checkboxLabel: 'รายวัน',
    checkboxName: 'daily',
  },
  {
    checkboxLabel: 'รายชั่วโมง',
    checkboxName: 'hourly',
  },
]
const dataCheckBox2 = [
  {
    checkboxLabel: 'จ้างเหมารายวัน',
    checkboxName: 'outsourceDaily',
  },
  {
    checkboxLabel: 'จ้างเหมารายชั่วโมง',
    checkboxName: 'outsourceHourly',
  },
  {
    checkboxLabel: 'จ้างเหมารายเดือน',
    checkboxName: 'outsourceMonthly',
  },
  {
    checkboxLabel: 'จ้างเหมารายโปรเจ็ค',
    checkboxName: 'outsourceProject',
  },
]
