//lib
import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Swal from 'sweetalert2'
import { useFormikContext } from 'formik'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'

//Include Project
import { InputSelectComponentCustom, InputTimeComponentCustom } from 'src/component'
import styles from './index.module.scss'
import { EStatus } from 'src/pages/AttendanceReport/EType'
import { ModalOverTime } from 'src/pages/AttendanceReport/ModalAttendance/ModalOT'
import { ModalMultiplierOvertime } from '..'

//Images
import AddIcon from '../../image/plusIcon.svg'
import editSVG from '../../image/edit.svg'
import closeSVG from '../../image/closeGray.svg'
import { mutationCreateOvertimeDocument } from 'src/adapter/xhr/mutation'
import { DelRequestOvertime } from 'src/adapter/xhr'
import { toMinute } from 'src/utils/resetTimeAttendanceReport'

const OvertimeAttendaceReport: React.FC = (): JSX.Element => {
  const [openModalmulti, setOpenModalmulti] = useState(false)
  const [overtimeIndex, setOvertimeIndex] = useState(0)
  const [openModalOT, setOpenModalOT] = React.useState<boolean>(false)
  const { values, setFieldValue } = useFormikContext<any>()
  const [tabAction, setTabAction] = useState<any>({ 0: false, 1: false })

  const navigate = useNavigate()

  const isDuplicateOvertimeList = () => {
    let index = 0
    let idx = 0
    const newArr = []
    for (let i = 0; i < values.report.overtimeList.length - 1; i++) {
      for (let j = i + 1; j < values.report.overtimeList.length; j++) {
        if (values.report.overtimeList[i].startedAt === values.report.overtimeList[j].startedAt) {
          newArr[index] = values.report.overtimeList[i]
          idx = i
          index++
        }
      }
    }
    if (idx !== undefined && idx !== -1 && values.report.overtimeList && values.report.overtimeList[idx]) {
      values.report.overtimeList.splice(idx, 1)
      setFieldValue(`report.overtimeList`, values.report.overtimeList)
    }
    return idx
  }

  const handleTabAction = (index: any) => {
    const tab = tabAction[index]
    if (tabAction[index] === true) {
      setTabAction({ ...tabAction, ...{ [index]: !tab } })
    } else {
      setTabAction({ ...tabAction, ...{ [index]: !tab } })
    }
  }

  const [mutateOvertimeDoc, { error: errorOvertime, loading: loadOvertime }] = useMutation(
    gql(mutationCreateOvertimeDocument),
  )
  useEffect(() => {
    if (errorOvertime) {
      Swal.fire({
        icon: 'error',
        title: errorOvertime.name,
        html: `มีเอกสารช่วงเวลานี้อยู่ในระบบแล้ว </br> กรุณาเลือกช่วงเวลาอื่น </br> ${errorOvertime.message} `,
        customClass: {
          container: 'swal',
        },
      })
    }
  }, [errorOvertime])

  const onSubmitStatusOvertime = (status: string, index: number, value: any) => {
    return Swal.fire({
      title: 'บันทึกเอกสารโอที',
      html: `วันที่ ${values.attendance.date} <br> เวลา ${value.startedAt} - ${value.endedAt}`,
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#3080f8',
      confirmButtonText: 'ตกลง',
      reverseButtons: true,
      customClass: {
        container: 'swal',
      },
    }).then((save) => {
      if (save.isConfirmed) {
        console.log(' I AM SAVING', toMinute(new Date(value.endedAt).getTime() - new Date(value.startedAt).getTime()))
        mutateOvertimeDoc({
          variables: {
            input: {
              employeeID: values.attendance.employeeID,
              date: values.attendance.date,
              startedAt: value.startedAt,
              endedAt: value.endedAt,
              status: status,
              overtimeType: value.overtimeType,
              overtimeMinute: toMinute(new Date(value.endedAt).getTime() - new Date(value.startedAt).getTime()),
            },
          },
        }).then((res: any) => {
          console.log(res, 'res')
          Swal.fire({
            title: 'บันทึกเอกสารโอทีเรียบร้อยแล้ว',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              container: 'swal',
            },
          })
          const _data = res.data.createOvertimeRequest
          values.report.overtimeList.push({
            startedAt: value.startedAt,
            endedAt: value.endedAt,
            status: status,
            overtimeType: _data.overtimeType,
            overtimeRequestID: _data.id,
            overtimeMinute: _data.overtimeMinute,
          })

          setFieldValue(`isSaveSuggestOt`, true)
          isDuplicateOvertimeList()
          handleTabAction(index)
        })
      }
    })
  }

  const onDeleteOvertime = (value: any, index: number) => {
    return Swal.fire({
      title: 'ลบรายการเอกสาร',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#FF6161',
      confirmButtonText: 'ตกลง',
      reverseButtons: true,
      customClass: {
        container: 'swal',
      },
    }).then((del) => {
      if (del.isConfirmed) {
        values.report.overtimeList.splice(index, 1)
        setFieldValue(`report.overtimeList`, values.report.overtimeList)
        if (value.overtimeRequestID) {
          DelRequestOvertime({
            input: {
              employeeID: values.attendance.employeeID,
              id: value.overtimeRequestID,
            },
          }).then((res: any) => {
            console.log(res, 'res')
            Swal.fire({
              title: 'ลบรายการเอกสารเรียบร้อย',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                container: 'swal',
              },
            })
          })
        }
      }
    })
  }

  const handleSettingOvertime = (index: number) => {
    setOpenModalmulti(true)
    setOvertimeIndex(index)
  }

  const actionButtonList = (type: string, index: number, item: any) => {
    switch (type) {
      case 'SAVE':
        return onSubmitStatusOvertime('PENDING', index, item)
      case 'SAVE_APPROVE':
        return onSubmitStatusOvertime('APPROVE', index, item)
      case 'DELETE':
        return onDeleteOvertime(index, item)
      case 'OVERTIME_SETTING':
        return handleSettingOvertime(index)
      case 'VIEW_DOCUMENT':
        return navigate('/admin/hr/document/OvertimeDocument')

      default:
        break
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapperInput}>
        <div className={styles.gropHead}>
          <p className={styles.TextHead}>การทำงานล่วงเวลา</p>
          <img
            src={AddIcon}
            alt="addIcon"
            onClick={() => {
              console.log('click')
              setOpenModalOT(true)

              setFieldValue('report.overtimeList', values.report.overtimeList)
            }}
          />
        </div>
        {values?.report.overtimeList?.map((itemOvertime: any, IndexOvertime: any) => (
          <section className={styles.cardDocument} key={IndexOvertime}>
            <div className={styles.box}>
              <div className={styles.headButton}>
                <section className={returnstyles(itemOvertime.status)}>
                  <p>{EStatus[`${itemOvertime.status}`]}</p>
                </section>
                <div className={styles.Icon}>
                  <img
                    src={editSVG}
                    style={{ cursor: 'pointer' }}
                    alt="DetailIcon"
                    onClick={() => handleTabAction(IndexOvertime)}
                  />
                </div>
              </div>
              <section className={styles.inputBox}>
                <p className={styles.descriptionText}>ประเภทโอที</p>
                <InputSelectComponentCustom
                  _name={`report.overtimeList[${IndexOvertime}].overtimeType`}
                  _label={'ประเภทโอที'}
                  _menuList={OTList}
                  _value={itemOvertime.overtimeType}
                  _disabled={itemOvertime.status ? true : false}
                />
              </section>
              <section className={styles.inputBox}>
                <p className={styles.descriptionText}>ช่วงเวลา</p>

                <div className={styles.groupBox}>
                  <InputTimeComponentCustom
                    _name={`report.overtimeList[${IndexOvertime}].startedAt`}
                    _label={'เริ่มต้น'}
                    _value={itemOvertime.startedAt}
                    _disabled={itemOvertime.status ? true : false}
                  />

                  <InputTimeComponentCustom
                    _name={`report.overtimeList[${IndexOvertime}].endedAt`}
                    _label={'สิ้นสุด'}
                    _value={itemOvertime.endedAt}
                    _disabled={itemOvertime.status ? true : false}
                  />
                </div>
              </section>
              {tabAction[IndexOvertime] === true ? (
                <section className={styles.buttonForm}>
                  {loadOvertime ? (
                    <CircularProgress />
                  ) : (
                    <div className={styles.Icon}>
                      <img
                        src={closeSVG}
                        style={{ cursor: 'pointer' }}
                        alt="closeSVG"
                        onClick={() => handleTabAction(IndexOvertime)}
                      />
                    </div>
                  )}

                  <div className={styles.buttonGroup}>
                    {ButtonListOvertime(itemOvertime.status)?.map((item: any, index: number) => (
                      <div
                        className={item.styles}
                        key={index}
                        onClick={() => {
                          actionButtonList(item.value, IndexOvertime, itemOvertime)
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </section>
              ) : (
                ''
              )}
            </div>
          </section>
        ))}
      </div>
      <ModalOverTime open={openModalOT} setOpen={setOpenModalOT} />
      <ModalMultiplierOvertime
        open={openModalmulti}
        setOpen={setOpenModalmulti}
        overtimeID={values.report.overtimeList[overtimeIndex]?.id}
      />
      {/* <ModalMultiplierOT
        open={openModalmulti}
        setOpen={setOpenModalmulti}
        // values={formikprops}
        // overtimeList={formikprops.values.overtimeList[overtimeIndex]}
        // employeeID={attendance.employeeID}
        // overtimeIndex={overtimeIndex}
        // overtimConfig={overtimeconfig}
        // attendance={attendance}
      /> */}
    </div>
  )
}
const OTList = [
  { label: 'วันทำงาน', value: 'WORK' },
  { label: 'ทำงานล่วงเวลา', value: 'OVERTIME' },
]
const returnstyles = (status: any) => {
  if (status === 'APPROVE') {
    return `${styles.pillApprove}`
  } else if (status === 'PENDING') {
    return `${styles.pillPending}`
  } else if (status === 'DRAFT') {
    return `${styles.pillDraft}`
  } else if (status === 'REJECT') {
    return `${styles.pillReject}`
  } else {
    return `${styles.pillSuggest}`
  }
}

const ButtonListOvertime = (status: string) => {
  const ButtonListApprove = [
    {
      label: 'ไปหน้าเอกสาร',
      styles: `${styles.buttonDocument}`,
      value: 'VIEW_DOCUMENT',
    },
  ]
  const ButtonList = [
    {
      label: 'บันทึก',
      styles: `${styles.buttonSaveOT}`,
      value: 'SAVE',
    },
    {
      label: 'บันทึกและอนุมัติ',
      styles: `${styles.buttonSaveApproveOT}`,
      value: 'SAVE_APPROVE',
    },
    {
      label: 'กำหนดตัวคูณโอที',
      styles: `${styles.buttonOTSetting}`,
      value: 'OVERTIME_SETTING',
    },
    {
      label: 'ลบ',
      styles: `${styles.buttonDel}`,
      value: 'DEETE',
    },
  ]
  if (status == 'APPROVE' || status == 'PENDING') {
    return ButtonListApprove
  } else if (status == null) {
    return ButtonList
  }
}
export default OvertimeAttendaceReport
