//lib
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { CircularProgress, TextField } from '@mui/material'
import { useFormikContext, withFormik } from 'formik'
import { gql, useMutation } from '@apollo/client'

//Includes Project
import { deepClone, downloadFile } from 'src/utils/common'
import { TableAttendReport } from './TableAttendance'
import usePaginationQuery from 'src/hooks/usePaginationQuery'
import { Employee } from '../../utils/generated'
import { convertDateString } from '../../utils/periodCalCulate'
import { activeEmployeeFilter, GET_ACTIVE_EMPLOYEE } from 'src/adapter/xhr/query'
import { ResetAttendanceReport } from '../../adapter/xhr'
import {
  ButtonComponent,
  InputDateComponentCustom,
  InputTextComponentCustom,
  PaginationBar,
  ProgessBarComponent,
} from '../../component'
import styles from './index.module.scss'
import { ModalHistory } from './modal'

//Images
import HistoryIcon from '../../image/historyEdit.svg'
import yupTimeAttendanceReport from 'src/validations/yupTimeAttendanceReport.validate'
import { HeaderSection } from 'src/container'

const AttendanceReport: React.FC = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [filter, setFilter] = useState<any[]>([])
  const [showFilter, setShowFilter] = useState(false)
  const [modalHistory, setModalHistory] = useState(false)
  const [searchName, setSearchName] = useState<string>('')
  const [resetLoading, setResetLoading] = React.useState<boolean>(false)

  // Actual Data
  // Pagination State
  const {
    currentTableData,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    windowSize,
    maxHasNextToken,
    currentMaxPageIndex,
    loading,
    clearCacheRefetch,
  } = usePaginationQuery<Employee>({
    query: GET_ACTIVE_EMPLOYEE,
    convertRefetch: (s) => s.listEmployee,
    additionalVariables: activeEmployeeFilter(),
    initialPageSize: 20,
  })

  const handleClick = (value: any) => {
    if (filter.includes(value) === false) {
      setFilter([...filter, value])
    } else {
      setFilter(filter.filter((item: any) => item !== value))
    }
  }

  // 3 Button Actions: Search, Clear, Reset
  const onSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const variables = activeEmployeeFilter()
    variables.filter.id = { beginsWith: values.filter.employeeID }
    clearCacheRefetch(variables)
  }
  const onClear = () => {
    setFieldValue('filter', { ...values.filter, employeeID: '', startDate: '', endDate: '' })
  }
  const onReset = () => {
    if (values.filter.startDate && values.filter.endDate) {
      Swal.fire({
        title: 'รีเซ็ตข้อมูล',
        html: `${values.filter.employeeID} <br> จากวันที่ ${values.filter.startDate} ถึงวันที่ ${values.filter.endDate} <br> การรีเซ็ตจะเป็นการดึงข้อมูลเริ่มต้นจาก Attendance Log`,
        showCancelButton: true,
        confirmButtonColor: '#FF6161',
        cancelButtonColor: '#d33',
        confirmButtonText: 'รีเซ็ต',
        reverseButtons: true,
        heightAuto: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setResetLoading(true)
          ResetAttendanceReport({
            input: {
              employeeID: values.filter.employeeID,
              endDate: values.filter.endDate,
              startDate: values.filter.startDate,
            },
          }).then(() => {
            setResetLoading(false)
            const variables = activeEmployeeFilter()
            variables.filter.id = { beginsWith: values.filter.employeeID }
            clearCacheRefetch(variables)
          })
        }
      })
    } else {
      Swal.fire({
        title: 'กรุณาเลือกวันที่การรีเซ็ตข้อมูล',
        icon: 'error',
        confirmButtonColor: '#FF6161',
      })
    }
  }

  const [loadReport, { loading: loadingReport }] = useMutation(MUTATION_dowloadReport)
  const onDowloadExcel = () => {
    const typeDocument = [
      'TIMEREPORT_ATTENDANCE_FULLTIME_DAY',
      'TIMEREPORT_ATTENDANCE_FULLTIME_MONTH_OVERTIME',
      'TIMEREPORT_ATTENDANCE_OVERTIME_WEEKEND',
      'TIMEREPORT_ATTENDANCE_FULLTIME_MONTH',
    ]
    const variablesReport = {
      input: {
        documentType: '',
        startedAt: values.filter.startDate,
        endedAt: values.filter.endDate,
        departmentID: null,
        employeeID: null,
      },
    }

    for (let i = 0; i < typeDocument.length; i++) {
      const params: any = deepClone(variablesReport)
      params.input.documentType = typeDocument[i]
      loadReport({ variables: params }).then((res) => {
        console.log(res, 'res')
        const downloadURL = res.data.downloadEXCELDocument
        downloadFile(downloadURL, downloadURL.split('/').pop())
      })
    }
  }

  const [loadPaySlip, { loading: loadingPaySlip }] = useMutation(MUTATION_dowloadPayroll)
  const onDowloadPaySlip = () => {
    // console.log(!values.filter.employeeID, 'employeeID')
    if (!values.filter.employeeID) {
      return Swal.fire({
        title: 'กรุณากรอกรหัสพนักงาน',
        text: 'ที่ต้องการดาวน์โหลดรายงาน',
      })
    }
    // const endDate = new Date(values.filter.endDate)
    const variablesReport = {
      input: {
        documentType: 'PAYROLL_SALARY_PERMONTH',
        // documentType: 'PAYROLL_SALARY_DETAILED',
        startedAt: values.filter.startDate,
        endedAt: values.filter.endDate,
        employeeID: [values.filter.employeeID],
        departmentID: null,
        // yearMonth: '2022-10',
        // yearMonth: `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}`,
      },
    }
    loadPaySlip({ variables: variablesReport }).then((res) => {
      const downloadURL = res.data.downloadEXCELDocument
      downloadFile(downloadURL, downloadURL.split('/').pop())
    })
  }
  return (
    <>
      <div className={styles.contain}>
        <HeaderSection section="Attendance Report" />
        {/* Search Card */}
        <div className={styles.cardSearch}>
          <form className={styles.wrapperForm} onSubmit={onSearch}>
            <div className={styles.InputArea}>
              <div className={styles.inputBox}>
                <InputTextComponentCustom
                  _name="filter.employeeID"
                  _value={values.filter.employeeID}
                  _label="รหัสพนักงาน"
                />
              </div>
              <div className={styles.inputBox}>
                <TextField
                  name="employeeName"
                  label="ชื่อ-นามสกุลพนักงาน"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    style: { fontFamily: 'SFThonburiRegular', backgroundColor: 'white' },
                  }}
                  InputProps={{
                    style: {
                      color: 'gray',
                      borderRadius: 4,
                      backgroundColor: 'white',
                      fontFamily: 'SFThonburiRegular',
                    },
                  }}
                  size="small"
                  placeholder="ค้นหาพนักงาน"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchName(e.target.value)
                  }}
                />
              </div>
            </div>
            <div className={styles.InputArea}>
              <div className={styles.inputBox}>
                <InputDateComponentCustom
                  _name="filter.startDate"
                  _value={values.filter.startDate}
                  _label="วันที่เริ่มต้น"
                />
              </div>

              <span className={styles.text}>-</span>
              <div className={styles.inputBox}>
                <InputDateComponentCustom
                  _name="filter.endDate"
                  _value={values.filter.endDate}
                  _label="วันที่สิ้นสุด"
                />
              </div>
            </div>
            <article className={styles.btnSearchWrapper}>
              <div className={styles.buttonGroup}>
                <div className={styles.btSearch}>
                  <ButtonComponent
                    _isIcon="search"
                    _variant="contained"
                    _text="ค้นหา"
                    _colorText="white"
                    _colorBG="blue"
                    _type="submit"
                  />
                </div>
                <div className={styles.btSearch}>
                  <ButtonComponent
                    _isIcon="reset"
                    _variant="outlined"
                    _text="รีเซตตัวกรอง"
                    _colorText="blue"
                    _colorBG="white"
                    _type="button"
                    _functionOnClick={onClear}
                  />
                </div>
                {/* <div className={styles.btReset}>
                    <ButtonComponent
                      _sx={{ fontSize: '14px' }}
                      _variant="contained"
                      _text="อัปเดตข้อมูลพนักงาน"
                      _colorText="white"
                      _colorBG="secondary"
                      _type="button"
                      _functionOnClick={onUpdateEmployee}
                    />
                  </div> */}
              </div>

              <div className={styles.buttonGroup}>
                <div className={styles.btSearch}>
                  <ButtonComponent
                    _sx={{ fontSize: '16px' }}
                    _variant="contained"
                    _text="Reset"
                    _colorText="white"
                    _colorBG="red"
                    _type="button"
                    _functionOnClick={onReset}
                  />
                </div>
                <div className={styles.btSearch}>
                  <ButtonComponent
                    _sx={{ fontSize: '14px' }}
                    _variant="contained"
                    _text="Report"
                    _colorText="white"
                    _colorBG="blue"
                    _type="button"
                    _functionOnClick={onDowloadExcel}
                    _isIcon="file"
                  />
                </div>
                <div className={styles.btSearch}>
                  <ButtonComponent
                    _sx={{ fontSize: '14px' }}
                    _variant="contained"
                    _text="Detailed"
                    _colorText="white"
                    _colorBG="darkblue"
                    _type="button"
                    _functionOnClick={onDowloadPaySlip}
                    _isIcon="file"
                  />
                </div>
              </div>
              {(loading || loadingReport || loadingPaySlip) && (
                <div className={styles.loadingCircle}>
                  <CircularProgress />
                </div>
              )}
            </article>
          </form>
        </div>

        {/* Main Content Card */}
        <div className={styles.cardTable}>
          {/* Head for History Icon */}
          <div className={styles.head}>
            <div className={styles.historybutton} onClick={() => setModalHistory(true)}>
              <img src={HistoryIcon} alt="" className={styles.Icon} />
              <p>ประวัติการแก้ไขข้อมูลคลิกที่นี่</p>
            </div>
          </div>
          {/* Filter Report and Color Legend Section */}
          <div className={styles.dayType}>
            <section className={styles.Filterbar}>
              <section className={styles.FilterDetail}>
                <div
                  className={showFilter ? styles.CardFilterSelect : styles.CardFilter}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  ตัวกรอง
                  {filter.length > 0 && <div className={styles.Bade}> {filter.length}</div>}
                </div>
                {showFilter ? (
                  <div className={styles.GroupCard}>
                    {Filter.map((filteritem: any, index: number) => (
                      <div
                        key={index}
                        onClick={(e: any) => {
                          handleClick(filteritem.key)
                        }}
                        className={
                          filter.map((item: any) => item).includes(filteritem.key) === true
                            ? styles.CardFilterSelect
                            : styles.CardFilter
                        }
                      >
                        {filteritem.title}
                      </div>
                    ))}
                    <p className={styles.textFilter} onClick={() => setFilter([])}>
                      ล้างตัวกรอง
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </section>
            </section>
            <div className={styles.rightCard}>
              <ColorLegend />
            </div>
          </div>

          {/* Actual Table */}
          {resetLoading && (
            <div className={styles.LoadingCard}>
              <ProgessBarComponent />
            </div>
          )}

          <div className={styles.table}>
            <TableAttendReport
              searchName={searchName}
              startDate={values.filter.startDate}
              endDate={values.filter.endDate}
              filter={filter}
              employeeList={currentTableData ? currentTableData : []}
            />

            <PaginationBar
              currentTotalPageCount={currentMaxPageIndex + 1}
              currentPage={currentPage}
              hasNextToken={maxHasNextToken}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setCurrentPage={setCurrentPage}
              windowSize={windowSize}
            />
          </div>
        </div>
        <ModalHistory modalHistory={modalHistory} setModalHistory={setModalHistory} />
      </div>
    </>
  )
}
const myCurrentDate = new Date()

const myPastDate = new Date(myCurrentDate.getTime())
myPastDate.setDate(myPastDate.getDate() - 30)

const EnhancedAttendanceReport = withFormik({
  mapPropsToValues: () => ({
    filter: {
      employeeID: '',
      startDate: convertDateString(new Date('08-01-2022')),
      endDate: convertDateString(new Date('08-30-2022')),
    },
    report: {
      date: '',
      scanIn: null,
      scanOut: null,
      overtimeApproveList: [],
      overtimeList: [],
      leaveDeductList: [],
      leaveList: [],
      inDiffMinute: 0,
      breakDiffMinute: 0,
      outDiffMinute: 0,
      isLink: true,
      workingTimeList: [],
      remark: '',
      workingMinute: 0,
      overtimeMinute: 0,
      leaveMinute: 0,
      leaveDeductMinute: 0,
    },
    attendance: {},
    documentOvertime: {
      startedAt: '',
      endedAt: '',
      status: '',
      overtimeType: '',
    },
    documentLeave: {
      leaveType: '',
      startedAt: '',
      endedAt: '',
      leaveDocumentAttachment: '',
      isLeaveDeductMoney: false,
      status: '',
      leaveForm: '',
    },
  }),
  validationSchema: yupTimeAttendanceReport,

  handleSubmit: () => {
    console.log('SUBMIT')
  },
})(AttendanceReport)

export default EnhancedAttendanceReport

const ColorLegend: React.FC = () => {
  return (
    <>
      {attendanceTypeList.map((listItem, listIndex) => (
        <section className={styles.group} key={listIndex}>
          <div className={styles[`color${listItem.type}`]}></div>
          <p className={styles.namecolor}>{listItem.name}</p>
        </section>
      ))}
    </>
  )
}

interface IAttendanceType {
  type: string
  color: string
  name: string
}

const attendanceTypeList: IAttendanceType[] = [
  {
    type: 'OT',
    color: '#FFD24C',
    name: 'ล่วงเวลา (OT)',
  },
  {
    type: 'LEAVE',
    color: '#FF6363',
    name: 'วันลา',
  },
  {
    type: 'WEEKEND',
    color: '#DFDFDE',
    name: 'วันหยุดประจำสัปดาห์',
  },
  {
    type: 'HOLIDAY',
    color: '#FFA8A8',
    name: 'วันหยุดประจำปี',
  },
  {
    type: 'NULL',
    color: '#FFFFFF',
    name: 'ข้อมูลไม่สมบูรณ์',
  },
]

const Filter = [
  {
    title: 'กะการทำงาน',
    key: 'SHIFT',
  },
  {
    title: 'ทำงานล่วงเวลา',
    key: 'OVERTIME',
  },
  {
    title: 'ลาหักเงิน',
    key: 'LEAVE_DEDUCT',
  },
  {
    title: 'ลาไม่หักเงิน',
    key: 'LEAVE',
  },
  {
    title: 'หมายเหตุ',
    key: 'REMARK',
  },
]

const MUTATION_dowloadReport = gql`
  mutation _7_TARFullMonth($input: DownloadOptional!) {
    downloadEXCELDocument(input: $input)
  }
`

const MUTATION_dowloadPayroll = gql`
  mutation _5_Payroll($input: DownloadOptional!) {
    downloadEXCELDocument(input: $input)
  }
`
