import axios from 'axios'
import fileDownload from 'js-file-download'
import AWS from 'aws-sdk'
import {
  albumBucketName,
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_KEY,
  // bucketRegion,
  // IdentityPoolId,
  USER_POOL_REGION,
} from './configAws'
import { EEmployeeType, ELeaveForm } from './generated'
import moment from 'moment'
import { useQueryListLeaveList } from 'src/adapter/api'
// import AWS

export const paginationFunc = <T>(arr: T[], pageSize = 10): T[][] => {
  let firstIndexSlice = 0
  // let secondIndexSlice = 15
  let secondIndexSlice = pageSize
  const arrLastPiece = (arr.length % pageSize) * -1
  const paginationTrackingListData = []
  arr.map((arrEle, arrIndex: number) => {
    const realNumeric = arrIndex + 1
    if (realNumeric % pageSize === 0) {
      paginationTrackingListData.push(arr.slice(firstIndexSlice, secondIndexSlice))
      firstIndexSlice = firstIndexSlice + pageSize
      secondIndexSlice = secondIndexSlice + pageSize
    }
  })
  if (arrLastPiece !== 0) {
    paginationTrackingListData.push(arr.slice(arrLastPiece))
  }

  return paginationTrackingListData
}

//! Function gen
export const packCSVFile = (csvText: any) => {
  const splitLine = csvText.split('\r\n')
  const packSplitLine: any = { items: [] }
  splitLine.map((splitLineElement: any, splitLineIndex: number) => {
    const splitText = splitLineElement.split(',')
    if (splitLineIndex > 0) {
      if (splitText.length > 1) {
        const filterBlankTime = splitText[3].split(' ').filter((e: string) => e.trim() != '')
        if (filterBlankTime.join('') !== '') {
          const dateParts = splitText[2].split(' ').join('').split('/')
          const packSpiltTextIN = {
            employeeID: splitText[1].split(' ').join(''),
            locationType: 'OFFICE',
            lat: '',
            long: '',
            scanType: 'WEB',
            scanReason: 'SCAN_IN',
            deviceId: 'DV-01',
            scanAt: new Date(
              dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2] + ' ' + filterBlankTime[0],
            ).toISOString(),
          }
          packSplitLine.items.push(packSpiltTextIN)
          if (filterBlankTime.length > 1 && filterBlankTime[0] !== filterBlankTime[filterBlankTime.length - 1]) {
            const packSplitTextOut = {
              employeeID: splitText[1].split(' ').join(''),
              locationType: 'OFFICE',
              lat: '',
              long: '',
              scanType: 'WEB',
              scanReason: 'SCAN_OUT',
              deviceId: 'DV-01',
              scanAt: new Date(
                dateParts[1] +
                  '/' +
                  dateParts[0] +
                  '/' +
                  dateParts[2] +
                  ' ' +
                  filterBlankTime[filterBlankTime.length - 1],
              ).toISOString(),
            }
            packSplitLine.items.push(packSplitTextOut)
          }
        }
      }
    }
  })
  return packSplitLine
}

export const countHourScanInOutString = (date: string, scanIn: string, scanOut: string) => {
  const startDate = new Date(`${date}:${scanIn}`).getTime()
  const endDate = new Date(`${date}:${scanOut}`).getTime()
  // console.log(startDate, 'startDate')
  // console.log(endDate, 'endDate')
  const secondsDate = endDate - startDate
  const minute = Math.floor((secondsDate / 1000 / 60) % 60)
  const hour = Math.floor((secondsDate / 1000 / 60 / 60) % 24)
  const textString = `${hour} ชั่วโมง ${minute ? minute + ' นาที' : ''}`
  return textString
}

export const minuteToHourAndMinute = (inputMinute: number) => {
  const minute = Math.floor(inputMinute % 60)
  const hour = Math.floor((inputMinute / 60) % 24)
  const timeObj: { hour: number; minute?: number } = { hour: hour }
  if (hour) {
    timeObj.hour = hour
  } else {
    timeObj.hour = 0
  }
  if (minute) {
    timeObj.minute = minute
  } else {
    timeObj.minute = 0
  }
  return timeObj
}

export const isColorLight = (hex: string) => {
  const c = hex.substring(1) // strip #
  const rgb = parseInt(c, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  if (luma <= 128) {
    return true
  } else {
    false
  }
}

export const daySelect = [
  { title: 'วันอาทิตย์', value: 0 },
  { title: 'วันจันทร์', value: 1 },
  { title: 'วันอังคาร', value: 2 },
  { title: 'วันพุธ', value: 3 },
  { title: 'วันพฤหัสบดี', value: 4 },
  { title: 'วันศุกร์', value: 5 },
  { title: 'วันเสาร์', value: 6 },
]

export const WeekDay: any[] = [
  { Fullname: 'Sunday', name: 'SUN' },
  { Fullname: 'Monday', name: 'MON' },
  { Fullname: 'Tuesday', name: 'TUE' },
  { Fullname: 'Wednesday', name: 'WED' },
  { Fullname: 'Thursday', name: 'THU' },
  { Fullname: 'Friday', name: 'FRI' },
  { Fullname: 'Saturday', name: 'SAT' },
]

export interface IMonth {
  month: string
  thMonth: string
  thShortMonth: string
}

export const months: IMonth[] = [
  { month: 'January', thMonth: 'มกราคม', thShortMonth: 'ม.ค' },
  { month: 'Febuary', thMonth: 'กุมภาพันธ์', thShortMonth: 'ก.พ' },
  { month: 'March', thMonth: 'มีนาคม', thShortMonth: 'มี.ค' },
  { month: 'April', thMonth: 'เมษายน', thShortMonth: 'เม.ย' },
  { month: 'May', thMonth: 'พฤษภาคม', thShortMonth: 'พ.ค' },
  { month: 'June', thMonth: 'มิถุนายน', thShortMonth: 'มิ.ย' },
  { month: 'July', thMonth: 'กรกฎาคม', thShortMonth: 'ก.ค' },
  { month: 'August', thMonth: 'สิงหาคม', thShortMonth: 'ส.ค' },
  { month: 'September', thMonth: 'กันยายน', thShortMonth: 'ก.ย' },
  { month: 'October', thMonth: 'ตุลาคม', thShortMonth: 'ต.ค' },
  { month: 'November', thMonth: 'พฤศจิกายน', thShortMonth: 'พ.ย' },
  { month: 'December', thMonth: 'ธันวาคม', thShortMonth: 'ธ.ค' },
]

export const getHour = (date: any, start: any, and: any) => {
  const HourStart: any = new Date(`${date}:${start}`)
  const HourEnd: any = new Date(`${date}:${and}`)
  const milliseconds = HourEnd - HourStart
  const second = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(second / 60)
  const Hour = Math.floor(minutes / 60)
  const Minutes = Math.floor(minutes % 60)

  return `${Hour.toString().padStart(2, '0')}:${Minutes.toString().padStart(2, '0')}`
}

export const DateToMinutes = (start: any, and: any) => {
  const HourStart: any = new Date(start)
  const HourEnd: any = new Date(and)
  const milliseconds = HourEnd - HourStart
  const second = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(second / 60)
  const Hour = Math.floor(minutes / 60)
  const Minutes = Math.floor(minutes % 60)

  return minutes
}

export const toTime = (dateTime: any) => {
  if (dateTime === null) return null
  return (
    new Date(dateTime).getHours().toString().padStart(2, '0') +
    ':' +
    new Date(dateTime).getMinutes().toString().padStart(2, '0')
  )
}

export const timeToMinute = (time: any) => {
  try {
    const [h, m] = time.split(':')
    return Number(h) * 60 + Number(m)
  } catch (error) {
    return time
  }
}

//Calculate Minutes Leave With BreakTime

export const toMinute = (ms: number) => ms / 60 / 1000
export const withRange = (current: any, min: any, max: any, eq = true) => {
  return eq ? min <= current && current <= max : min < current && current < max
}

export const avoidBreakTimeMinute = ({
  startedAt,
  endedAt,
  breakList,
}: {
  startedAt: Date
  endedAt: Date
  breakList: any[]
}): number => {
  let breakOffsetMinute = 0
  const totalMinute = toMinute(endedAt.getTime() - startedAt.getTime())

  breakList?.forEach((b: any) => {
    const inBreakRange = (time: Date) => withRange(time.getTime(), b.startedAt!.getTime(), b.endedAt?.getTime())
    b.startedAt = new Date(b.startedAt)
    b.endedAt = new Date(b.endedAt)

    // over break
    if (b.startedAt!.getTime() > startedAt.getTime() && b.endedAt!.getTime() < endedAt.getTime()) {
      breakOffsetMinute += toMinute(b.endedAt!.getTime() - b.startedAt!.getTime())
    }
    // in break
    else if (inBreakRange(startedAt) || inBreakRange(endedAt)) {
      const start = Math.max(startedAt.getTime(), b.startedAt!.getTime())
      const end = Math.min(endedAt.getTime(), b.endedAt!.getTime())
      breakOffsetMinute += toMinute(end - start)
    }
  })

  return totalMinute - breakOffsetMinute
}

export const minuteToHour = (minute: number) => {
  const h = Math.floor(minute / 60)
    .toString()
    .padStart(2, '0')
  const m = Math.floor(minute % 60)
    .toString()
    .padStart(2, '0')
  if (parseInt(h) <= 0 && parseInt(m) <= 0) {
    return ''
  }
  return `${h} ชม. ${m} น.`
}

//Format yyyy-dd-mm
export const dateFmtShort = (date: any) => {
  return new Date(date).toISOString().split('T')[0]
}

export const minuteToHourHH = (minute: number) => {
  const h = Math.floor(minute / 60)
    .toString()
    .padStart(2, '0')
  const m = (minute % 60).toString().padStart(2, '0')
  return `${h}:${m}`
}

export const minuteToHourFloat = (minute: number) => {
  const h = Math.floor(minute / 60)
  const m = minute % 60
  return `${h}.${m}`
}

export const classes = (...classList: any[]) => {
  return classList.filter((c) => typeof c === 'string').join(' ')
}

export const CALENDAR_TYPE_THAI = {
  LEAVE: 'ลา',
  OVERTIME: 'ล่วงเวลา',
  ABSENT: 'ขาด',
  LATE: 'มาสาย',
}

export const CALENDAR_EVENT_TYPE_THAI = {
  REASON_SICK: 'ลาป่วย',
  REASON_PERSONAL_LEAVE: 'ลากิจ',
  REASON_PERSONAL_LEAVE_WITHOUT_DEDUCT_MONEY: 'ลากิจไม่หักเงิน',
  REASON_ANNUAL_LEAVE: 'ลาพักร้อน',
  ABSENT: 'ขาด',
  WORK: 'ล่วงเวลา',
  OVERTIME: 'ล่วงเวลา',
  LATE: 'มาสาย',
}

// export const EVENT_NAME = () => {}
export type ECalendarType = keyof typeof CALENDAR_TYPE_THAI
export type ECalendarEventType = keyof typeof CALENDAR_EVENT_TYPE_THAI

export const getCalendarThaiType = (eventType: ECalendarEventType) => {
  const leaveReasonList = [
    'REASON_SICK',
    'REASON_PERSONAL_LEAVE',
    'REASON_PERSONAL_LEAVE_WITHOUT_DEDUCT_MONEY',
    'REASON_ANNUAL_LEAVE',
  ]

  return {
    eventTypeThaiName: CALENDAR_EVENT_TYPE_THAI[eventType],
    calendarType: leaveReasonList.includes(eventType) ? 'LEAVE' : eventType,
  }
}

export const calendarTypeColor: Record<ECalendarType, { main: string; light: string }> = {
  LEAVE: {
    light: 'rgba(255, 244, 232, 1)',
    main: 'rgba(255, 159, 45, 1)',
  },
  OVERTIME: {
    light: 'rgba(232, 232, 232, 1)',
    main: 'rgba(88, 87, 87, 1)',
  },
  ABSENT: {
    light: 'rgba(255, 235, 235, 1)',
    main: 'rgba(233, 44, 44, 1)',
  },
  LATE: {
    light: 'rgba(229, 243, 255, 1)',
    main: 'rgba(0, 133, 255, 1)',
  },
}

export const getThaiDate = (date: Date) => {
  const _date = new Intl.DateTimeFormat('th-TH', { dateStyle: 'full' }).format(date).split(' ')

  const thaiDate = {
    day: _date[0],
    date: _date[1],
    month: _date[2],
    year: _date[4],
  }

  return {
    ...thaiDate,
    format(pattern: string) {
      return Object.entries(thaiDate).reduce((acc, [key, value]) => {
        return acc.replace(new RegExp(key, 'gi'), value)
      }, pattern)
    },
  }
}

export const dateToTime = (date: Date | string | number) => {
  const _date = new Date(date)

  return _date.toLocaleTimeString('en', {
    timeStyle: 'short',
    hour12: false,
    timeZone: 'Asia/Bangkok',
  })
}
export const CheckBreak = (workTime: any, breaktime: any, leavetime: any, overtime: any) => {
  console.log(breaktime, 'breaktime')
  const breakTominutes = parseInt(breaktime) * 60
  const sum = workTime - breakTominutes - leavetime - overtime
  return minuteToHour(sum)
}

export const WorkingTime = (scanIn: any, workTime: any, breaktime: any, leavetime: any, overtime: any) => {
  console.log(workTime, 'workTime')

  const leaveMinutes = timeToMinute(leavetime)
  const overtimeMinutes = timeToMinute(overtime)
  const breakMinutes = timeToMinute(breaktime)

  console.log(workTime, leaveMinutes, overtimeMinutes, breakMinutes)
  console.log(breaktime, 'breaktime')

  // const SumData = workTime - breakMinutes - leaveMinutes - overtimeMinutes
  const SumData = workTime - breakMinutes - leaveMinutes - overtimeMinutes

  console.log(SumData, 'SumData')

  return minuteToHour(SumData)
}

export const deepClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj || ''))

export const combineList = (...arr: any[]) => {
  const newArr: any[] = []
  arr.forEach((e: any) => {
    newArr.push(...e)
  })

  return newArr
}

// //Dowload File
export const downloadFile = async (url: string, filename: string): Promise<void> => {
  const res = await axios.get(url, { responseType: 'blob' })
  fileDownload(res.data, decodeURI(filename))
  console.log('hello file', url, filename)
}

//Upload File To S3 Bucket
export const handleUploadFile = (value: any, albumLocation: string) => {
  // AWS.config.update({
  //   region: bucketRegion,
  //   credentials: new AWS.CognitoIdentityCredentials({
  //     IdentityPoolId: IdentityPoolId,
  //   }),
  // })

  //P'N

  AWS.config.update({ region: USER_POOL_REGION, accessKeyId: AWS_ACCESS_KEY_ID, secretAccessKey: AWS_SECRET_KEY })
  const files: any = Array.from(value)
  if (files.length === 0) return
  // const albumPhotosKey = `PAT/${patId}/customer/`
  const albumPhotosKey = albumLocation
  const imgURLList: any = []
  return files.map((file: any, index: number) => {
    console.log('file', file)
    const photoKey = albumPhotosKey + file.name
    // Use S3 ManagedUpload class as it supports multipart uploads
    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: file,
        ContentType: file.type,
      },
    })
    return upload
      .promise()
      .then(function (resAws) {
        console.log('resAws', resAws)
        return resAws.Location
      })
      .catch(function (err) {
        console.log(err)
      })
  })
}

export const CheckIntervals = (timeList: any, newIntervals: any) => {
  const Intervals: any = []
  const ListItem = timeList.map((timeitem: any) => {
    console.log(timeitem, 'timeitem')
    Intervals.push({ startedAt: timeitem.startedAt, endedAt: timeitem.endedAt })
  })

  let result = false
  for (let i = 0; i < Intervals.length; i++) {
    if (Intervals[i].startedAt < newIntervals.endedAt && newIntervals.startedAt < Intervals[i].endedAt) {
      result = true
    }
  }

  return result
}

export const DateStyleShort = (date: any) => {
  return new Date(date).toISOString().split('T')[0]
}

// export const TimeToHour = ()

export const resolvePath = <T = any>(object: Record<any, any>, path: string): T =>
  path?.split('.').reduce((o, p) => o?.[p], object)

export const isObject = (object: any): boolean => {
  return object && typeof object === 'object'
}

export function remove__typename(object: Record<any, any>) {
  if (object['__typename']) delete object['__typename']
  Object.values(object).forEach((value) => {
    if (isObject(value)) remove__typename(value)
  })
}

export function numberFormatter(value: number): string {
  return Intl.NumberFormat().format(value)
}

//LeaveForm
export const LeaveFormList = [
  {
    label: 'กำหนดเอง',
    value: ELeaveForm.CustomTime,
  },
  {
    label: 'ลาเต็มวัน',
    value: ELeaveForm.FulldayLeave,
  },
  {
    label: 'ลาครึ่งวันเช้า',
    value: ELeaveForm.HalfdayMorningLeave,
  },
  {
    label: 'ลาครึ่งวันบ่าย',
    value: ELeaveForm.HalfdayAfternoonLeave,
  },
]

export const EMPLOYEE_TYPES_LIST = [
  {
    value: EEmployeeType.FulltimeMonth,
    label: 'พนักงานรายเดือน',
  },
  {
    value: EEmployeeType.FulltimeDay,
    label: 'พนักงานรายวัน',
  },

  {
    value: EEmployeeType.Parttime,
    label: 'พนักงานพาร์ทไทม์',
  },
  {
    value: EEmployeeType.Outsource,
    label: 'พนักงานรายจ้างเหมา',
  },
]

export const isBeforeShift = (startTime: any, shiftTime: any) => {
  return !moment(startTime, 'HH:mm').isBefore(moment(`${shiftTime}`, 'HH:mm'))
}

export const isAfterShift = (startTime: any, shiftTime: any) => {
  return !moment(startTime, 'HH:mm').isAfter(moment(`${shiftTime}`, 'HH:mm'))
}
export const isValidStyle = (style: React.CSSProperties | null | false | undefined) =>
  style !== null && style !== false && style !== undefined

export const styleInline = (...style: (React.CSSProperties | null | false | undefined)[]) => {
  const validStyle = style.filter((e) => isValidStyle(e)) as React.CSSProperties[]

  return validStyle.reduce<Record<string, string>>((acc, curr) => {
    return Object.assign(acc, curr)
  }, {})
}

//Find out what days are from the start date. end date
/*ex. dateRange('2022-01-09','2022-03-09') => ['2022-01-09','2022-02-09','2022-03-09']*/
export const dateRange = (startDate: string, endDate: string, steps = 1) => {
  const dateArray = []
  const currentDate = new Date(startDate)
  while (currentDate <= new Date(endDate)) {
    dateArray.push(dateFmtShort(new Date(currentDate).toISOString()))
    currentDate.setUTCDate(currentDate.getUTCDate() + steps)
  }
  return dateArray
}

export const handleDecimalsOnValue = (value: any) => {
  if (isNaN(value)) return 0
  if (value < 0) return 0
  return parseFloat(value)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const eqTime = (t1: Date, t2: Date) => {
  new Date(t1).toLocaleTimeString('th', { timeStyle: 'short' }) ===
    new Date(t2).toLocaleTimeString('th', { timeStyle: 'short' })
}

// export const MaxDate = (date: any[]) => {
//   // console.log(arr.reduce((a: any, b: any) => (a.date > b.date ? a.date : b.date)))

//   console.log(date, 'date common')
//   // if (date.length > 0) {
//   //   return date.reduce((a: any, b: any) => (a > b ? a : b))
//   // }
//   return date
// }
