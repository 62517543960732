import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { ButtonComponent, CardMasterDate } from '../../component'
import { useNavigate } from 'react-router-dom'
import { queryListShift, updateDefaultShift, deleteShift } from '../../adapter/xhr'
import { daySelect } from '../../utils/common'
import Swal from 'sweetalert2'
// Components
const AttendancePage: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const [listShift, setListShift] = useState<any>()

  const queryShift = () => {
    queryListShift()
      .then((res: any) => {
        setListShift(res.data.data.listShift)
      })
      .catch((err: any) => {
        console.log(err, 'err')
      })
  }
  useEffect(() => {
    queryShift()
  }, [])

  return (
    <div className={styles.container}>
      <section className={styles.topicPageWrapper}>
        <p className={styles.topicPage}>ระเบียบการเข้าทำงาน</p>
      </section>
      <div className={styles.contentContainer}>
        <section className={styles.btnTopWrapper}>
          <div className={styles.btnTop}>
            <ButtonComponent
              _type="button"
              _colorBG="blue"
              _colorText="white"
              _variant="outlined"
              _isIcon="add"
              _text="เพิ่มระเบียบการทำงาน"
              _functionOnClick={() => navigate('add')}
            />
          </div>
        </section>
        <section>
          <style lang="scss">{`
          .swal {
          z-index: 3000;
          }
          `}</style>
          <div className={styles.wrapperCard}>
            {listShift?.items.map((shiftElement: any, shiftIndex: number) => {
              console.log(listShift.getDefaultShift)
              return (
                <CardMasterDate
                  key={shiftIndex}
                  _defaultData={listShift.getDefaultShift}
                  _topic={shiftElement.shiftName}
                  isDefault={listShift.getDefaultShift === shiftElement.shiftID}
                  _subTopic={shiftElement.workDayPolicy
                    .map(
                      (element: any, index: number) =>
                        `${daySelect[element.dayList[0]].title}${
                          element.dayList.length > 1
                            ? `-${daySelect[element.dayList[element.dayList.length - 1]].title}`
                            : ''
                        } ${element.timeList[0].startTime}-${element.timeList[0].endTime},`,
                    )
                    .join(',')}
                  _functionOnClickDefault={() => {
                    Swal.fire({
                      icon: 'warning',
                      title: `เปลี่ยนแปลงข้อมูลการทำงานเริ่มต้น`,
                      html: `shift มีผลต่อการคำนวณเวลาการทำงาน/เงินเดือน`,
                      showCancelButton: true,
                      cancelButtonText: 'ยกเลิก',
                      confirmButtonColor: '#3080f8',
                      confirmButtonText: 'ตกลง',
                      reverseButtons: true,
                      customClass: {
                        container: 'swal',
                      },
                    }).then((update) => {
                      if (update.isConfirmed) {
                        updateDefaultShift({ shiftId: shiftElement.shiftID }).then((res: any) => {
                          console.log(res.data.data, 'response defaultShift')
                        })
                      }
                    })
                  }}
                  _functionOnClickEdit={() => navigate(`edit/${shiftElement.shiftID}`)}
                  _functionOnClickDelete={() =>
                    Swal.fire({
                      icon: 'warning',
                      title: `ลบระเบียบการเข้างาน`,
                      html: `shift : ${shiftElement.shiftName}`,
                      showCancelButton: true,
                      cancelButtonText: 'ยกเลิก',
                      confirmButtonColor: '#d32f2f',
                      confirmButtonText: 'ตกลง',
                      reverseButtons: true,
                      customClass: {
                        container: 'swal',
                      },
                    }).then((del) => {
                      if (del.isConfirmed) {
                        deleteShift({ input: { shiftID: shiftElement.shiftID } }).then((res: any) => {
                          Swal.fire({
                            icon: 'success',
                            html: `ลบ shift : ${shiftElement.shiftName} <br> เรียบร้อยแล้ว`,
                            showConfirmButton: false,
                            timer: 1500,
                          })
                          setTimeout(() => {
                            queryShift()
                          }, 500)
                        })
                      }
                    })
                  }
                />
              )
            })}
          </div>
        </section>
      </div>
    </div>
  )
}
export default AttendancePage
