import { useMemo } from 'react'

// Pagination States:
// 1.  1 2 3 4 5          Total page count is less than the page pills we want to show.
// 2.  1 2 3 4 5 ...        has nextToken,
// 3.  1 ... 13 14 15 ...   Total page count is greater than the page pills, has next Token
// 4.  1 ... 14 15 16  17   Total page count is greater than page pills, no next Token
// https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/

export const DOTS = -1

const usePagination = ({
  currentTotalPageCount,
  currentPage,
  hasNextToken,
  pageSize = 20,
  windowSize = 3,
}: IUsePagination): number[] => {
  const paginationRange = useMemo(() => {
    return getPaginationRange(currentTotalPageCount, currentPage, hasNextToken, pageSize, windowSize)
  }, [currentTotalPageCount, currentPage, hasNextToken, pageSize, windowSize])

  return paginationRange
}

/**
 * Returns the list of PaginationRange ie. [1 ... 14 15 16  17]
 *
 * @param currentTotalPageCount - The current max page count
 * @param currentPage - Current page (1-index) ie. 1...2...3
 * @param hasNextToken - Whether there is nextToken in the latest query
 * @param windowSize - The pagePills that will be shown alongside the currentPage
 * @returns PaginationRange ie. [1 ... 14 15 16  17]
 */
export function getPaginationRange(
  currentTotalPageCount: number,
  currentPage: number,
  hasNextToken: boolean,
  pageSize: number,
  windowSize: number,
): number[] {
  // Max Page Pills = windowSize (withinCurrent) + first + 2*DOTS
  // A page pill is one button of the pagination component
  // maxPagePills means the maximum page pills that will be shown
  const maxPagePills = windowSize + 3
  const siblingCount = (windowSize - 1) / 2
  const currentTotalPageCountWithDots = hasNextToken ? currentTotalPageCount + 1 : currentTotalPageCount

  /*
  Case 1:
  If the number of pages is less than the page numbers we want to show in our
  paginationComponent, we return the range [1..totalPageCount]
*/
  if (currentTotalPageCountWithDots <= maxPagePills)
    return hasNextToken ? [...range(1, currentTotalPageCount), DOTS] : range(1, currentTotalPageCount)

  /*
    Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
*/
  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
  const rightSiblingIndex = Math.min(
    currentPage + siblingCount,
    hasNextToken ? currentTotalPageCount + 1 : currentTotalPageCount,
  )
  // console.log('rightSiblingIndex', rightSiblingIndex)

  /*
  We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
*/
  const shouldShowLeftDots = leftSiblingIndex > 3
  const shouldShowRightDots = hasNextToken || rightSiblingIndex < currentTotalPageCount - 1 // should show right dots when hasNextToken OR tot - currentPage  -1 > sibling

  /*
    Case 2: No left dots to show, but rights dots to be shown
*/
  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftItemCount = 2 + windowSize
    const leftRange = range(1, leftItemCount)

    return [...leftRange, DOTS]
  }

  /*
    Case 3: No right dots to show, but left dots to be shown
*/
  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightItemCount = 1 + windowSize
    const rightRange = range(currentTotalPageCount - rightItemCount + 1, currentTotalPageCount)
    return [1, DOTS, ...rightRange]
  }

  /*
    Case 4: Both left and right dots to be shown
*/
  if (shouldShowLeftDots && shouldShowRightDots) {
    const middleRange = range(leftSiblingIndex, rightSiblingIndex)
    return [1, DOTS, ...middleRange, DOTS]
  }
  return []
}

export interface IUsePagination {
  currentTotalPageCount: number
  currentPage: number
  hasNextToken: boolean
  pageSize: number
  windowSize: number
}

export default usePagination

const range = (start: number, end: number) => {
  const length = end - start + 1
  /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
  return Array.from({ length }, (_, idx) => idx + start)
}
