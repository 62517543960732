import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
// import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
// import moment from 'moment'
import { TimeAttendance } from '../../utils/generated'

export const TableAttend: React.FC<AttendanceTableProps> = ({ log }: AttendanceTableProps): JSX.Element => {
  return (
    <Table aria-label="simple table" size="small" padding="none">
      <TableHead>
        <TableRow>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            <h4>รหัสพนักงาน</h4>
          </TableCell>
          <TableCell align="center">
            <h4>ชื่อ - นามสกุล</h4>
          </TableCell>
          <TableCell align="center">
            <h4>ประเภท</h4>
          </TableCell>
          <TableCell align="center">
            <h4>สถานที่</h4>
          </TableCell>
          <TableCell align="center">
            <h4>เวลาที่แสกน</h4>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {log.length > 0 &&
          log.map((EmpItem, EmpIndex) => (
            <TableRow key={EmpIndex}>
              <TableCell align="center">
                <img src={EmpItem.imageUrl as string} alt="" />
              </TableCell>
              <TableCell align="center">
                <p style={{ color: '#626262' }}> {EmpItem.employeeID}</p>
              </TableCell>
              <TableCell align="center">
                <span style={{ color: '#626262' }}>
                  {EmpItem.employee?.fullName} {EmpItem.employee?.lastName}
                </span>
              </TableCell>
              <TableCell align="center">
                <p
                  style={{
                    backgroundColor: EScan[EmpItem.scanReason].backgroundColor,
                    color: EScan[EmpItem.scanReason].textColor,
                  }}
                >
                  {EScan[EmpItem.scanReason].text}
                </p>
              </TableCell>
              <TableCell align="center">
                <p style={{ color: '#626262' }}> {EmpItem.locationType}</p>
              </TableCell>
              <TableCell align="center">
                <p style={{ color: '#3A77DA' }}>
                  {new Date(EmpItem.scanAt).toLocaleString('en-GB', {
                    hour12: true,
                    // dateStyle: 'full',
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                    // timeStyle: 'short',
                  })}
                </p>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}
const EScan = {
  SCAN_IN: { text: 'สแกนเข้างาน', backgroundColor: '#8AC82D', textColor: 'white' },
  SCAN_OUT: { text: 'สแกนออกงาน', backgroundColor: '#FF6161', textColor: 'white' },
}

interface AttendanceTableProps {
  log: TimeAttendance[]
}
