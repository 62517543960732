//lib
import React from 'react'
import { useFormikContext } from 'formik'

//Include Project
import { minuteToHourHH, timeToMinute } from 'src/utils/common'
import { InputTimeComponentCustom } from 'src/component'
import styles from './index.module.scss'

//Images
import addButtonSVG from '../../image/addButton.svg'
import minusButtonSVG from '../../image/minusButton.svg'

const WorkingTimeAttendanceReport: React.FC = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()

  const CheckInDiff = (scanIn: any, startShift: any) => {
    const scanMinutes = timeToMinute(scanIn)
    const shiftMinutes = timeToMinute(startShift)
    const sum = scanMinutes - shiftMinutes
    if (sum > 60) {
      return 0
    } else if (sum < -60) {
      return 0
    }
    return sum
  }
  const CheckOutDiff = (scanIn: any, EndShift: any) => {
    const scanMinutes = timeToMinute(scanIn)
    const shiftMinutes = timeToMinute(EndShift)
    const sum = scanMinutes - shiftMinutes
    if (sum > 60) {
      return 0
    } else if (sum < -60) {
      return 0
    }
    return sum
  }

  const CalTimeOut = (scanIn: any) => {
    const Time = timeToMinute(scanIn) - 1
    return minuteToHourHH(Time)
  }
  const CalTimeLate = (scanIn: any) => {
    const Time = timeToMinute(scanIn) + 1
    return minuteToHourHH(Time)
  }
  const inDiff = CheckInDiff(values.report.scanIn, values.attendance?.shiftStartTime)
  const OutDiff = CheckOutDiff(values.report.scanOut, values.attendance?.shiftEndTime)
  const renderCountInput = (name: string) => {
    switch (name) {
      case 'report.inDiffMinute':
        return (
          <div className={styles.wrapperCount}>
            <p>{inDiff < 0 ? 'เข้าก่อน' : 'เข้าสาย'}</p>
            <div className={styles.InputNumber}>
              <img
                src={minusButtonSVG}
                alt="minusButtonSVG"
                onClick={() => {
                  setFieldValue('report.inDiffMinute', values.report.inDiffMinute - 1)
                  setFieldValue('report.scanIn', CalTimeOut(values.report.scanIn))
                }}
              />

              <div className={styles.inputCustom}>
                <input readOnly className={styles.inputBox} type="number" name={'inDiffMinute'} value={inDiff}></input>
              </div>
              <img
                src={addButtonSVG}
                alt="addButtonSVG"
                onClick={() => {
                  setFieldValue('report.inDiffMinute', values.report.inDiffMinute + 1)
                  setFieldValue('scanIn', CalTimeLate(values.report.scanIn))
                }}
              />
            </div>
          </div>
        )

      case 'report.breakDiffMinute':
        return (
          <div className={styles.wrapperCount}>
            <p>{values.report.breakDiffMinute < 0 ? 'พักเร็ว' : 'พักเกิน'}</p>
            <div className={styles.InputNumber}>
              <img
                src={minusButtonSVG}
                alt="minusButtonSVG"
                onClick={() => {
                  setFieldValue('report.breakDiffMinute', values.report.breakDiffMinute - 1)
                }}
              />

              <div className={styles.inputCustom}>
                <input
                  readOnly
                  className={styles.inputBox}
                  type="number"
                  name={'report.breakDiffMinute'}
                  value={values.report.breakDiffMinute}
                ></input>
              </div>
              <img
                src={addButtonSVG}
                alt="addButtonSVG"
                onClick={() => {
                  setFieldValue('report.breakDiffMinute', values.report.breakDiffMinute + 1)
                }}
              />
            </div>
          </div>
        )

      case 'report.outDiffMinute':
        return (
          <div className={styles.wrapperCount}>
            <p>{OutDiff < 0 ? 'ออกก่อน' : 'ออกหลัง'}</p>
            <div className={styles.InputNumber}>
              <img
                src={minusButtonSVG}
                alt="minusButtonSVG"
                onClick={() => {
                  setFieldValue('report.outDiffMinute', values.report.breakDiffMinute - 1)
                  setFieldValue('report.scanOut', CalTimeOut(values.report.scanOut))
                }}
              />

              <input
                readOnly
                className={styles.inputBox}
                type="number"
                name={'report.outDiffMinute'}
                value={OutDiff}
              ></input>
              <img
                src={addButtonSVG}
                alt="addButtonSVG"
                onClick={() => {
                  setFieldValue('report.outDiffMinute', values.report.outDiffMinute - 1)
                  setFieldValue('report.scanOut', CalTimeOut(values.report.scanOut))
                }}
              />
            </div>
          </div>
        )

      default:
        break
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.wrapperInput}>
        <div className={styles.gropHead}>
          <p className={styles.TextHead}>ช่วงเวลาทำงาน</p>
        </div>
        {values?.report.workingTimeList?.map((itemWork: any, IndexWorktime: number) => (
          <section className={styles.inputBox} key={IndexWorktime}>
            <p className={styles.descriptionText}>ช่วงเวลา</p>
            <div className={styles.groupBox}>
              <InputTimeComponentCustom
                _name={`report.workingTimeList[${IndexWorktime}].startedAt`}
                _label={'เริ่มต้น'}
                _value={itemWork.startedAt}
                _disabled={true}
              />
              <InputTimeComponentCustom
                _name={`report.workingTimeList[${IndexWorktime}].endedAt`}
                _label={'สิ้นสุด'}
                _value={itemWork.endedAt}
                _disabled={true}
              />
            </div>
          </section>
        ))}
        {values.report.workingTimeList?.length > 0 && (
          <section className={styles.groupButton}>
            <section className={styles.CountInput}>{renderCountInput('report.inDiffMinute')}</section>
            <section className={styles.CountInput}>{renderCountInput('report.breakDiffMinute')}</section>
            <section className={styles.CountInput}>{renderCountInput('report.outDiffMinute')}</section>
          </section>
        )}
      </div>
    </div>
  )
}

export default WorkingTimeAttendanceReport
