//lib
import { Divider } from '@mui/material'
import { Form, useFormikContext } from 'formik'
import React, { useState } from 'react'
import _ from 'lodash'

//Include Project
import {
  InputDateComponent,
  InputDateComponentCustom,
  InputNumberComponentCustom,
  InputRadioComponent,
  InputSelectComponentCustom,
  InputTextComponentCustom,
} from 'src/component'
import { EBloodType, EMilitaryStatus, ENameTitle, ENationalityType, EPersonalRelation, ESex } from 'src/utils/generated'
import styles from './index.module.scss'
import { Table } from './table'

const EmployeePersonalTab: React.FC = (): JSX.Element => {
  const { values, setFieldValue, errors } = useFormikContext<any>()
  const [addressCurrent, setAddressCurrent] = useState(false)

  // console.log(`${_.get(errors, 'formInput.fullName')}`, 'error')

  const changAddress = () => {
    setAddressCurrent(!addressCurrent)
    if (!addressCurrent) {
      setFieldValue('formInput.addressCurrent', {
        ...values.formInput.addressCurrent,
        addressNumber: values.formInput.addressPersonalID.addressNumber,
        group: values.formInput.addressPersonalID.group,
        optional: values.formInput.addressPersonalID.optional,
        province: values.formInput.addressPersonalID.province,
        subDistrict: values.formInput.addressPersonalID.subDistrict,
        district: values.formInput.addressPersonalID.district,
        zipCode: values.formInput.addressPersonalID.zipCode,
      })
    } else {
      setFieldValue('formInput', {
        ...values.formInput,
        addressCurrent: '',
      })
    }
  }

  return (
    <Form className={styles.container}>
      <div className={styles.header}>1. ข้อมูลพื้นฐาน</div>
      <div className={styles.card}>
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.flexBox}>
              <div className={styles.gap}>
                <p className={styles.title}>คำนำหน้า</p>
                <InputRadioComponent _row={true} _name="formInput.nameTitle" _menuList={NameTitle} />
              </div>
              <div className={styles.gap}>
                <p className={styles.title}>เพศ</p>
                <InputRadioComponent _name="formInput.sex" _row={true} _menuList={EGenger} />
              </div>
            </div>

            <div className={styles.form}>
              <p className={styles.title}>ภาษาไทย</p>
              <div className={styles.flexBox}>
                <InputTextComponentCustom
                  _name="formInput.fullName"
                  _label="ชื่อ"
                  _value={values.formInput.fullName}
                  _helperText={`${_.get(errors, 'formInput.fullName')}`}
                />
                <InputTextComponentCustom
                  _name="formInput.middleName"
                  _label="ชื่อกลาง"
                  _value={values.formInput.middleName}
                />
                <InputTextComponentCustom
                  _name="formInput.lastName"
                  _label="นามสกุล"
                  _value={values.formInput.lastName}
                  _helperText={`${_.get(errors, 'formInput.lastName')}`}
                />
              </div>
            </div>

            <div className={styles.form}>
              <p className={styles.title}>ภาษาอังกฤษ</p>
              <div className={styles.flexBox}>
                <InputTextComponentCustom
                  _name="formInput.fullNameEnglish"
                  _label="fullName"
                  _value={values.formInput.fullNameEnglish}
                />
                <InputTextComponentCustom
                  _name="middleNameEnglish"
                  _label="Mid name"
                  _value={values.formInput.middleNameEnglish}
                />
                <InputTextComponentCustom
                  _name="formInput.lastNameEnglish"
                  _label="Last name"
                  _value={values.formInput.lastNameEnglish}
                />
              </div>
            </div>

            <div className={styles.flexBox}>
              <div className={styles.gap}>
                <InputDateComponent
                  _name="formInput.birthDate"
                  _label="วันเกิด"
                  _helperText={`${_.get(errors, 'formInput.birthDate')}`}
                  _value={values.formInput.birthDate}
                />
              </div>
              <div className={styles.gap}>
                <InputTextComponentCustom
                  _name="formInput.nickName"
                  _label="ชื่อเล่น"
                  _value={values.formInput.nickName}
                />
              </div>
            </div>
          </div>
        </section>
        <Divider />
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.form}>
              <p className={styles.title}>ประเภท</p>
              <div className={styles.flexBox}>
                <button
                  type="button"
                  className={
                    values.formInput.nationalityType === ENationalityType.Thailand
                      ? styles.typeButtonSelect
                      : styles.typeButton
                  }
                  onClick={() => setFieldValue('formInput.nationalityType', ENationalityType.Thailand)}
                >
                  คนไทย
                </button>
                <button
                  type="button"
                  className={
                    values.formInput.nationalityType === ENationalityType.Foreigner
                      ? styles.typeButtonSelect
                      : styles.typeButton
                  }
                  onClick={() => setFieldValue('formInput.nationalityType', ENationalityType.Foreigner)}
                >
                  ต่างชาติ
                </button>
              </div>
            </div>

            {values.formInput.nationalityType === ENationalityType.Thailand ? (
              <>
                <div className={styles.flexBox}>
                  <InputTextComponentCustom
                    _name="formInput.personalID"
                    _label="รหัสประจำตัว"
                    _value={values.formInput.personalID}
                    _helperText={`${_.get(errors, 'formInput.personalID')}`}
                  />
                  <InputTextComponentCustom
                    _name="formInput.personalIDAt"
                    _label="สถานที่ออกบัตร"
                    _value={values.formInput.personalIDAt}
                  />
                </div>

                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <InputDateComponentCustom
                      _name="formInput.personalIDIssue"
                      _label="วันที่่ออกบัตร"
                      _value={values.personalIDIssue}
                    />
                  </div>
                  <div className={styles.gap}>
                    <InputDateComponentCustom
                      _name="formInput.personalIDExpire"
                      _label="วันบัตรหมดอายุ"
                      _value={values.formInput.personalIDExpire}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.flexBox}>
                  <InputTextComponentCustom
                    _name="formInput.formInput.personalID"
                    _label="เลขประจำตัวผู้เสียภาษี"
                    _value={values.formInput.personalID}
                    _helperText={`${_.get(errors, 'formInput.personalID')}`}
                  />
                  <InputTextComponentCustom
                    _name="formInput.personalPassportID"
                    _label="เลขที่หนังสือเดินทาง"
                    _value={values.formInput.personalPassportID}
                  />
                </div>

                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <InputDateComponentCustom
                      _name="formInput.personalWorkID"
                      _label="เลขใบอนุญาตทำงาน"
                      _value={values.formInput.personalWorkID}
                    />
                  </div>
                  <div className={styles.gap}>
                    <InputDateComponentCustom
                      _name="formInput.personalIDIssue"
                      _label="วันที่ออกใบอนุญาต"
                      _value={values.formInput.personalIDIssue}
                    />
                  </div>
                </div>

                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <InputDateComponentCustom
                      _name="formInput.personalIDExpire"
                      _label="วันที่ใบอนุญาตทำงานหมดอายุ"
                      _value={values.formInput.personalIDExpire}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        <Divider />
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.flexBox}>
              <InputTextComponentCustom
                _name="formInput.ethnicity"
                _label="เชื้อชาติ"
                _value={values.formInput.ethnicity}
              />
              <InputTextComponentCustom
                _name="formInput.nationality"
                _label="สัญชาติ"
                _value={values.formInput.nationality}
              />
            </div>

            <div className={styles.flexBox}>
              <InputTextComponentCustom _name="formInput.religion" _label="ศาสนา" _value={values.formInput.religion} />
              <InputSelectComponentCustom
                _name="formInput.bloodType"
                _label="หมู่เลือด"
                _menuList={Eblood}
                _value={values.formInput.bloodType}
              />
            </div>
            <div className={styles.flexBox}>
              <InputNumberComponentCustom _name="formInput.weight" _label="น้ำหนัก" _value={values.formInput.weight} />
              <InputNumberComponentCustom _name="formInput.height" _label="ส่วนสูง" _value={values.formInput.height} />
            </div>
            <div className={styles.flexBox}>
              <InputSelectComponentCustom
                _name="formInput.militaryStatus"
                _label="สถานภาพทางทหาร"
                _menuList={SelectMilitaryStatus}
                _value={values.formInput.militaryStatus}
              />
              <InputTextComponentCustom _name="formInput.remark" _label="หมายเหตุ" _value={values.formInput.remark} />
            </div>
          </div>
        </section>
        <Divider />
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <Table Header={HeaderFamily} />
            <div className={styles.flexBox}>
              <InputSelectComponentCustom
                _name="formInput.personalRelation"
                _label="สถานภาพ"
                _menuList={PersonalRelation}
                _value={values.formInput.personalRelation}
              />
              <InputTextComponentCustom
                _name="formInput.detailsFamily.childrens"
                _label="จำนวนบุตร (คน)"
                _value={values.formInput.detailsFamily?.childrens}
              />
            </div>
            <div className={styles.flexBox}>
              <InputTextComponentCustom
                _name="formInput.detailsFamily.fullName"
                _label="ชื่อ (คู่สมรส)"
                _value={values.formInput.detailsFamily?.fullName}
              />
              <InputTextComponentCustom
                _name="formInput.detailsFamily.lastName"
                _label="นามสกุล (คู่สมรส)"
                _value={values.detailsFamily?.lastName}
              />
            </div>
            <div className={styles.flexBox}>
              <InputTextComponentCustom
                _name="formInput.detailsFamily.job"
                _label="อาชีพ (คู่สมรส)"
                _value={values.detailsFamily?.job}
              />
              <InputTextComponentCustom
                _name="detailsFamily.positionName"
                _label="ตำแหน่งงาน (คู่สมรส)"
                _value={values.detailsFamily?.positionName}
              />
            </div>
            <div className={styles.flexBox}>
              <InputTextComponentCustom
                _name="detailsFamily.companyName"
                _label="ชื่อบริษัท (คู่สมรส)"
                _value={values.detailsFamily?.companyName}
              />
              <InputTextComponentCustom
                _name="detailsFamily.tel"
                _label="เบอร์ติดต่อ (คู่สมรส)"
                _value={values.detailsFamily?.tel}
              />
            </div>
            <div className={styles.flexBox}>
              <InputTextComponentCustom
                _name="detailsFamily.siblings"
                _label="จำนวนพี่น้อง (รวมพนักงาน)"
                _value={values.detailsFamily?.siblings}
              />
              <InputNumberComponentCustom
                _name="detailsFamily.myNumber"
                _label="ท่านเป็นบุตรลำดับที่"
                _value={values.detailsFamily?.myNumber}
              />
            </div>
            <div className={styles.flexBox}>
              <InputNumberComponentCustom
                _name="detailsFamily.maleNumber"
                _label="ผู้ชาย (คน)"
                _value={values.detailsFamily?.maleNumber}
              />
              <InputNumberComponentCustom
                _name="detailsFamily.femaleNumber"
                _label="ผู้หญิง (คน)"
                _value={values.detailsFamily?.femaleNumber}
              />
            </div>
          </div>
        </section>
        <Divider />
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.form}>
              <p className={styles.title}>ข้อมูลติดต่อ</p>
              <div className={styles.flexBox}>
                <InputTextComponentCustom
                  _name="formInput.tel"
                  _label="เบอร์โทรศัพท์"
                  _helperText={`${_.get(errors, 'formInput.tel')}`}
                  _value={values.formInput.tel}
                />
                <InputTextComponentCustom _name="formInput.email" _label="email" _value={values.formInput.email} />
              </div>
              <div className={styles.flexBox}>
                <InputTextComponentCustom _name="formInput.lineId" _label="ID Line" _value={values.formInput.lineId} />
              </div>
            </div>
            <div className={styles.form}>
              <p className={styles.title}>ผู้ติดต่อกรณีฉุกเฉิน</p>
              <div className={styles.flexBox}>
                <InputTextComponentCustom
                  _name="formInput.emergencyContact.fullName"
                  _label="ชื่อ"
                  _value={values.formInput.emergencyContact?.fullName}
                />
                <InputTextComponentCustom
                  _name="formInput.emergencyContact.lastName"
                  _label="นามสกุล"
                  _value={values.formInput.emergencyContact?.lastName}
                />
              </div>
              <div className={styles.flexBox}>
                <InputTextComponentCustom
                  _name="formInput.emergencyContact.relation"
                  _label="ความสัมพันธ์"
                  _value={values.formInput.emergencyContact?.relation}
                />
                <InputTextComponentCustom
                  _name="formInput.emergencyContact.tel"
                  _label="เบอร์โทรศัพท์"
                  _value={values.formInput.emergencyContact?.tel}
                />
              </div>
              <div className={styles.flexBox}>
                <InputTextComponentCustom
                  _name="formInput.emergencyContact.address"
                  _label="ที่อยู่"
                  _value={values.formInput.emergencyContact?.address}
                />
              </div>
            </div>
          </div>
        </section>
        <Divider />
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.form}>
              <p className={styles.title}>ที่อยู่ตามบัตรประชาชน</p>
              <InputTextComponentCustom
                _name={`formInput.addressPersonalID.addressNumber`}
                _label="บ้านเลขที่"
                _value={values.formInput.addressPersonalID?.addressNumber}
              />
              <InputTextComponentCustom
                _name={`formInput.addressPersonalID.group`}
                _label="หมู่"
                _value={values.formInput.addressPersonalID?.group}
              />
              <InputTextComponentCustom
                _name={`formInput.addressPersonalID.optional`}
                _label="ข้อมูลเพิ่มเติมอื่น ๆ (ถ้ามี)"
                _value={values.formInput.addressPersonalID?.optional}
              />
              <InputTextComponentCustom
                _name={`formInput.addressPersonalID.province`}
                _label="จังหวัด"
                _value={values.formInput.addressPersonalID?.province}
              />
              <div className={styles.flexBox}>
                <InputTextComponentCustom
                  _name={`formInput.addressPersonalID.district`}
                  _label="เขต/อำเภอ"
                  _value={values.formInput.addressPersonalID?.district}
                />
                <InputTextComponentCustom
                  _name={`formInput.addressPersonalID.subDistrict`}
                  _label="แขวง/ตำบล"
                  _value={values.formInput.addressPersonalID?.subDistrict}
                />
              </div>
              <InputNumberComponentCustom
                _name={`formInput.addressPersonalID.zipCode`}
                _label="รหัสไปรษณีย์"
                _value={values.formInput.addressPersonalID?.zipCode}
              />
            </div>
          </div>
        </section>

        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.form}>
              <div className={styles.titleHead}>
                <p className={styles.title}>ที่อยู่ปัจจุบัน</p>
                <p className={styles.wrapperTextLink} onClick={() => changAddress()}>
                  {addressCurrent ? 'ใช้ที่อยู่ปัจจุบัน' : 'ใช้ที่อยู่ตามบัตรประชาชน'}
                </p>
              </div>
              <InputTextComponentCustom
                _name={`formInput.addressCurrent.addressNumber`}
                _label="บ้านเลขที่"
                _value={values.formInput.addressCurrent?.addressNumber}
              />
              <InputTextComponentCustom
                _name={`formInput.addressCurrent.group`}
                _label="หมู่"
                _value={values.formInput.addressCurrent?.group}
              />
              <InputTextComponentCustom
                _name={`formInput.addressCurrent.optional`}
                _label="ข้อมูลเพิ่มเติมอื่น ๆ (ถ้ามี)"
                _value={values.formInput.addressCurrent?.optional}
              />
              <InputTextComponentCustom
                _name={`formInput.addressCurrent.province`}
                _label="จังหวัด"
                _value={values.formInput.addressCurrent?.province}
              />
              <div className={styles.flexBox}>
                <InputTextComponentCustom
                  _name={`formInput.addressCurrent.district`}
                  _label="เขต/อำเภอ"
                  _value={values.formInput.addressCurrent?.district}
                />
                <InputTextComponentCustom
                  _name={`formInput.addressCurrent.subDistrict`}
                  _label="แขวง/ตำบล"
                  _value={values.formInput.addressCurrent?.subDistrict}
                />
              </div>
              <InputNumberComponentCustom
                _name={`formInput.addressCurrent.zipCode`}
                _label="รหัสไปรษณีย์"
                _value={values.formInput.addressCurrent?.zipCode}
              />
            </div>
          </div>
        </section>
      </div>
    </Form>
  )
}

export default EmployeePersonalTab

const NameTitle = [
  { label: 'นาย', value: ENameTitle.Mr },
  { label: 'นางสาว', value: ENameTitle.Miss },
  { label: 'นาง', value: ENameTitle.Mrs },
]

const EGenger = [
  { label: 'ชาย', value: ESex.Male },
  { label: 'หญิง', value: ESex.Female },
]

const Eblood = [
  { label: 'เอ', value: EBloodType.A },
  { label: 'บี', value: EBloodType.B },
  { label: 'เอบี', value: EBloodType.Ab },
  { label: 'โอ', value: EBloodType.O },
]

const SelectMilitaryStatus = [
  { label: 'ผ่านการเกณฑ์ทหารแล้ว', value: EMilitaryStatus.Pass },
  { label: 'ยังไม่ผ่านการเกณฑ์ทหาร', value: EMilitaryStatus.NotPass },
  { label: 'ได้รับการยกเว้น', value: EMilitaryStatus.Exception },
]

const PersonalRelation = [
  { label: 'โสด', value: EPersonalRelation.Single },
  { label: 'สมรส', value: EPersonalRelation.Married },
  { label: 'หย่าร้าง', value: EPersonalRelation.Divorce },
]

const HeaderFamily = ['ความสัมพันธ์', 'ชื่อ', 'วันเดือนปีเกิด', 'อาชีพ', 'สถานะ', '']
