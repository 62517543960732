import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import { useQueryListLeaveList } from 'src/adapter/api'
import { EEmployeeType, Employee, ESex } from 'src/utils/generated'
import { InputSelectComponentCustom } from '../InputsFormik'
interface Props {
  name: any
  label?: any
  value?: any
  employee?: Employee
  _helperText?: any
  disbled?: boolean
  _functionOnclick?: any
}

const LeaveSelect: React.FC<Props> = (props: Props): JSX.Element => {
  const { setFieldValue } = useFormikContext<any>()

  const { data } = useQueryListLeaveList({})
  const LeaveList: any = []
  const LeavePolicy = data
    .filter(
      (ele) =>
        ele.isActive === true &&
        ele.employeeAllowSexList?.includes(props.employee?.sex as ESex) === true &&
        ele.employeeAllowTypeList?.includes(props.employee?.employeeType as EEmployeeType) === true,
    )
    .map((ele) => {
      LeaveList.push({
        label: ele.name,
        value: ele.id,
      })
    })

  LeaveList.push({
    label: 'ขาด',
    value: 'ABSENT',
  })

  return (
    <>
      {/* <FormControl fullWidth size="small">
        <InputLabel
          shrink
          sx={{
            fontFamily: 'KanitRegular',
            backgroundColor: 'white',
            padding: '0px 5px',
          }}
        >
          {'รูปแบบการลา'}
        </InputLabel>
        <Select
          id="leaveForm"
          sx={{
            borderRadius: 1,
            fontFamily: 'KanitRegular',
            backgroundColor: 'white',
          }}
          value={props.value}
          // value={itemLeave.leaveForm}
          label={'รูปแบบการลา'}
          onChange={(e: any) => {
            setFieldValue(props.name, e.target.value)
            props._functionOnclick()
            // setLeaveForm(e.target.value)
            // setValues(formikprops)
            // setIndex(Index)
          }}
          name={props.name}
        >
          {LeaveList.map((data: any, index: number) => (
            <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
              {data.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <InputSelectComponentCustom
        _name={props.name}
        _label={'ประเภทการลา'}
        _value={props.value}
        _menuList={LeaveList}
        _helperText={props._helperText}
        _disabled={props.disbled}
      />
    </>
  )
}

export default LeaveSelect
