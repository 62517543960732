import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Avatar } from '@mui/material'
import styles from '../Menu_Employee/index.module.scss'
import { useNavigate } from 'react-router-dom'
//svg

export default function CollapsibleTable(props: any) {
  const navigate = useNavigate()
  return (
    <Table aria-label="collapsible table" padding="normal">
      <colgroup>
        <col style={{ width: '5%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '15%' }} />
      </colgroup>
      <TableHead sx={{ position: 'sticky', top: '-10px', backgroundColor: '#FFFFFF', zIndex: 999 }}>
        <TableRow>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            <h4 className={styles.text}>รหัสพนักงาน</h4>
          </TableCell>
          <TableCell align="left">
            <h4 className={styles.text}>ชื่อ - นามสกุล</h4>
          </TableCell>
          <TableCell align="center">
            <h4 className={styles.text}>ชื่อเล่น</h4>
          </TableCell>
          <TableCell align="center">
            <h4 className={styles.text}>วันเริ่มงาน</h4>
          </TableCell>
          <TableCell align="center">
            <h4 className={styles.text}>ตำแหน่ง</h4>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {props.employeeList
            .filter((item: any) => {
              if (props.employeename === '') {
                return item
              } else if (
                item.fullName.toLocaleLowerCase().includes(props.employeename.toLocaleLowerCase()) ||
                item.lastName.toLocaleLowerCase().includes(props.employeename.toLocaleLowerCase())
              ) {
                return item
              }
            })
            .map((item: any, index: number) => (
              <TableRow key={index} style={{ cursor: 'pointer' }} onClick={() => navigate(`edit/${item.id}`)}>
                <TableCell align="center">
                  <Avatar src={item.profileImage} />
                </TableCell>
                <TableCell align="center">
                  <p>{item.id}</p>
                </TableCell>
                <TableCell align="left">
                  <p>
                    {item.fullName} {item.lastName}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p>{item.nickName}</p>
                </TableCell>
                <TableCell align="center">
                  <p>{item.hireDate}</p>
                </TableCell>
                <TableCell align="center">
                  <p>{item.department?.departmentName}</p>
                </TableCell>
              </TableRow>
            ))}
        </React.Fragment>
      </TableBody>
    </Table>
  )
}
