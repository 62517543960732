import { useLazyQuery } from '@apollo/client'
import { useMemo } from 'react'
import { FetchData } from 'src/adapter/xhr/interface'
import { LIST_ATTENDANCE_REPORT_EMP } from 'src/adapter/xhr/query'
import { TimeAttendanceReport } from 'src/utils/generated'
import useHandleResponseAPI from '../useHandleResponseAPI'

function useTimeAttendanceReportByEmployee() {
  const [getAttendanceReportByEmployee, { loading, data, error, refetch }] = useLazyQuery<
    FetchData<TimeAttendanceReport>
  >(LIST_ATTENDANCE_REPORT_EMP, {
    notifyOnNetworkStatusChange: true, // set loading to true everytime it refetches
    // since the same query data will come from cache!! & UseEffect will not trigger!
    fetchPolicy: 'no-cache', // Bypass cache!! https://www.apollographql.com/docs/react/caching/advanced-topics/#bypassing-the-cache
  })

  const isLoading = useMemo(() => {
    return loading
  }, [loading])

  const errMsg = useMemo(() => {
    if (error) return JSON.stringify(error)
    else return ''
  }, [error])

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg,
  })

  return { refetch, getAttendanceReportByEmployee, data: data?.listTimeAttendanceReportByEmployeeID, loading }
}

export default useTimeAttendanceReportByEmployee
