import { FormikProps, FormikErrors } from 'formik'
import { useEffect } from 'react'
import Swal from 'sweetalert2'

export function useFormikAlertError(formik: FormikProps<any>, onError?: (err: FormikErrors<any>) => void) {
  useEffect(() => {
    const hasErrors = !!Object.keys(formik.errors ?? null).length

    if (formik.isSubmitting && hasErrors) {
      if (onError) return onError(formik.errors)
      Swal.fire({ icon: 'error', html: Object.values(formik.errors).join('<br />') })
      formik.setSubmitting(false)
    } else if (formik.isSubmitting && !hasErrors) {
      formik.setSubmitting(false)
    }
  }, [formik.isSubmitting, formik.errors])
}
