import React, { useState } from 'react'
import styles from './index.module.scss'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FilterIcon from '../../../image/filter2.svg'
import { PopperFilter } from '../modalApprove'
import SearchInput from 'src/component/SearchInput'
interface Props {
  list: string
  setList: any
  data: any
  _name?: any
  _checked?: boolean
  _onChange?: any
}
const NameComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openpopper, setOpenPopper] = useState(false)

  const handleClickPopper = (event: any) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper((previousOpen) => !previousOpen)
  }

  return (
    <>
      <div className={styles.container}>
        <section>
          <SearchInput values={''} setValues={''} label={'ค้นหาพนักงาน'} color="#FFFFFF" />
          {/* <TextField
            className={styles.textField}
            id="outlined-select-currency"
            select
            label=""
            value={isSelect}
            onChange={isSelectChange}
            size="small"
          >
            {props.dataList.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                <p>{option.label}</p>
              </MenuItem>
            ))}
          </TextField> */}
        </section>
        <section className={styles.btnWrapper}>
          <div>
            <FormControlLabel
              style={{ color: '#FFFFFF' }}
              control={
                <div className={styles.checkboxBox}>
                  <Checkbox
                    sx={{
                      color: '#3A77DA',
                      '&.Mui-checked': {
                        color: '#3A77DA',
                      },
                    }}
                    checked={props._checked}
                    onChange={props._onChange}
                    // value={props._name}
                  />
                </div>
              }
              label={<p>เลือกทั้งหมด</p>}
            />
          </div>
          <>
            <img src={FilterIcon} alt="" className={styles.Icon} onClick={(e) => handleClickPopper(e)} />
          </>
          {/* <div className={styles.btnBox}>
            <ButtonComponent
              _variant="outlined"
              _text="ค้นหา"
              _colorText="white"
              _colorBG="darkblue"
              _type="button"
              _functionOnClick={() => props.setList(isSelect)}
            />
          </div> */}
        </section>
      </div>
      <PopperFilter anchorEl={anchorEl} openpopper={openpopper} />
    </>
  )
}
export default NameComponent
