import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { EStatus } from 'src/pages/AttendanceReport/EType'
import { setDepartment } from 'src/state/slice/department'
import { setEmployee } from 'src/state/slice/employee'
import { setHoliday } from 'src/state/slice/holiday'
import { setPackage } from 'src/state/slice/package'
import { setPosition } from 'src/state/slice/position'
import { setShift } from 'src/state/slice/shift'
import { setTimeAttendanceConfig } from 'src/state/slice/timeAttendanceConfig'
import useDepartment from './api/useDepartment'
import useEmployee from './api/useEmployee'
import useHoliday from './api/useHoliday'
import usePackage from './api/usePackage'
import usePosition from './api/usePosition'
import useShift from './api/useShift'
import useTimeAttendanceConfig from './api/useTimeAttendanceConfig'

const useIntitialListOption = () => {
  const dispatch = useDispatch()

  //<---------------- Department ------------------------------------------------------------->
  const { data: resDepartment, refetch: refetchDepartment } = useDepartment()
  useEffect(() => {
    dispatch(setDepartment({ items: resDepartment?.items || [], refetch: refetchDepartment }))
  }, [resDepartment])
  //<---------------- Department -------------------------------------------------------------->

  //<---------------- Holiday ------------------------------------------------------------->
  const { data: resHoliday, refetch: refetchHoliday } = useHoliday()

  useEffect(() => {
    dispatch(setHoliday({ items: resHoliday?.items || [], refetch: refetchHoliday }))
  }, [resHoliday])

  //<---------------- Holiday ------------------------------------------------------------->

  //<---------------- SHIFT ------------------------------------------------------------->
  const { data: resShift, refetch: refetchShift } = useShift()

  useEffect(() => {
    dispatch(setShift({ items: resShift?.items || [], refetch: refetchShift }))
  }, [resShift])

  //<---------------- SHIFT ------------------------------------------------------------->

  //<---------------- PACKAGE ------------------------------------------------------------->
  const { data: resPackage, refetch: refetchPackage } = usePackage()

  useEffect(() => {
    dispatch(setPackage({ items: resPackage?.items || [], refetch: refetchPackage }))
  }, [resPackage])

  //<---------------- PACKAGE ------------------------------------------------------------->

  //<---------------- POSITION ------------------------------------------------------------->
  const { data: resPosition, refetch: refetchPosition } = usePosition()

  useEffect(() => {
    dispatch(setPosition({ items: resPosition?.items || [], refetch: refetchPosition }))
  }, [resPosition])

  //<---------------- POSITION ------------------------------------------------------------->

  //<---------------- EMPLOYEE ------------------------------------------------------------->
  const { data: resEmployeeActive, refetch: refetchEmployee } = useEmployee({
    filter: {
      status: {
        eq: 'ACTIVE',
      },
    },
  })

  useEffect(() => {
    dispatch(setEmployee({ items: resEmployeeActive?.items || [], refetch: refetchEmployee }))
  }, [resEmployeeActive])

  //<---------------- EMPLOYEE ------------------------------------------------------------->

  //<---------------- ATTENDANCE CONFIG ------------------------------------------------------------->
  const { data: resAttendanceConfig, refetch: refetchTimeAttendanceConfig } = useTimeAttendanceConfig()

  useEffect(() => {
    dispatch(setTimeAttendanceConfig({ data: resAttendanceConfig, refetch: refetchTimeAttendanceConfig }))
  }, [resAttendanceConfig])

  //<---------------- ATTENDANCE CONFIG ------------------------------------------------------------->
}

export default useIntitialListOption
