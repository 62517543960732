import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { DelHoliday, QueryCalendarPolicy, UpdateDefaultHoliday } from '../../adapter/xhr'
import { ButtonComponent, CardMasterDate } from '../../component'
import allAction from '../../state/actions'
import styles from './index.module.scss'
// Components
const HolidayPolicyPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [calendarlist, setCalendarList] = useState<any>([])
  const NewQueryCalendar = () => {
    QueryCalendarPolicy().then((res: any) => {
      console.log(res, 'res')

      setCalendarList(res.data.data.listHoliday)
      console.log(calendarlist, 'listCalendar')
    })
  }
  useEffect(() => {
    NewQueryCalendar()
  }, [])

  const now = new Date()

  return (
    <div className={styles.container}>
      <section className={styles.topicPageWrapper}>
        <p className={styles.topicPage}>ระเบียบวันหยุดประจำปี</p>
      </section>
      <div className={styles.contentContainer}>
        <section className={styles.btnTopWrapper}>
          <div className={styles.btnTop}>
            <ButtonComponent
              _type="button"
              _colorBG="blue"
              _colorText="white"
              _variant="outlined"
              _isIcon="add"
              _text="เพิ่มระเบียบวันหยุดประจำปี"
              _functionOnClick={() => navigate('edit')}
            />
          </div>
        </section>
        <section className={styles.cardDetailWrapper}>
          <style lang="scss">{`
        .swal {
        z-index: 3000;
        }
        `}</style>
          {calendarlist.items?.map((data: any, index: number) => {
            return (
              <div key={index}>
                <CardMasterDate
                  _topic={data.holidayName}
                  _subTopic={data.subTopic}
                  _title="จำนวนวัน"
                  _content={JSON.parse(data.holidayYear)[now.getFullYear()]?.length || 0}
                  _defaultData={calendarlist.getDefaultHoliday}
                  isDefault={calendarlist.getDefaultHoliday === data.holidayID}
                  _functionOnClickDefault={() => {
                    Swal.fire({
                      icon: 'warning',
                      title: `เปลี่ยนแปลงข้อมูลวันหยุดประจำปี`,
                      html: `วันหยุดประจำปี มีผลต่อการคำนวณเวลาการทำงาน/เงินเดือน`,
                      showCancelButton: true,
                      cancelButtonText: 'ยกเลิก',
                      confirmButtonColor: '#3080f8',
                      confirmButtonText: 'ตกลง',
                      reverseButtons: true,
                      customClass: {
                        container: 'swal',
                      },
                    }).then((update) => {
                      if (update.isConfirmed) {
                        UpdateDefaultHoliday({ holidayId: data.holidayID }).then((res: any) => {
                          console.log(res, 'Update Default')
                          setTimeout(() => {
                            NewQueryCalendar()
                          }, 300)
                        })
                      }
                    })
                  }}
                  _functionOnClickEdit={() => navigate(`edit/${data.holidayID}`)}
                  _functionOnClickDelete={() => {
                    Swal.fire({
                      icon: 'warning',
                      title: `ลบระเบียบวันหยุดประจำปี`,
                      html: `${data.holidayName}`,
                      showCancelButton: true,
                      cancelButtonText: 'ยกเลิก',
                      confirmButtonColor: '#d32f2f',
                      confirmButtonText: 'ตกลง',
                      reverseButtons: true,
                      customClass: {
                        container: 'swal',
                      },
                    }).then((del) => {
                      if (del.isConfirmed) {
                        DelHoliday({ holidayId: data.holidayID }).then((res: any) => {
                          console.log(res, 'Del')
                          Swal.fire({
                            icon: 'success',
                            html: `ลบ ${data.holidayName} เรียบร้อยแล้ว`,
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                              container: 'swal',
                            },
                          })
                          setTimeout(() => {
                            NewQueryCalendar()
                          }, 300)
                        })
                      }
                    })
                  }}
                />
              </div>
            )
          })}
        </section>
      </div>
    </div>
  )
}
export default HolidayPolicyPage
