import React, { useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'

import { Formik, FormikBag, FormikProps, useFormikContext, withFormik } from 'formik'
import * as yup from 'yup'
import { ButtonComponent, PaginationBar } from '../../../component'
import TableIncomeExpense, { ITableHeader } from './table'
import { putEmployeePayroll } from '../../../adapter/xhr'
import swal from 'sweetalert'
import usePaginationQuery, { IUsePaginationQueryOutput } from 'src/hooks/usePaginationQuery'
import { EIncomeExpenseType, EmployeePayroll, EVariableOrFixed, OtherIncomeOrExpense } from 'src/utils/generated'
import { CircularProgress } from '@mui/material'

interface IExpenseIncome {
  // payrollList?: any
  period?: string
  date?: string

  payrollEmployeeQuery: IUsePaginationQueryOutput<EmployeePayroll>
}

const ExpenseIncome: React.FC<IExpenseIncome & FormikProps<{ income: never[]; expense: never[] }>> = (
  props: IExpenseIncome & FormikProps<{ income: never[]; expense: never[] }>,
): JSX.Element => {
  const {
    currentTableData,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    windowSize,
    maxHasNextToken,
    currentMaxPageIndex,
    loading,
    clearCacheRefetch,
    error,
  } = props.payrollEmployeeQuery

  const [incomeTableHeaderList, expenseTableHeaderList] = useMemo(
    () => packTableHeader(currentTableData),
    [currentTableData],
  )
  const incomeValueList = useMemo(() => packValue(currentTableData, 'otherIncomeList'), [currentTableData])
  const expenseValueList = useMemo(() => packValue(currentTableData, 'otherExpenseList'), [currentTableData])

  // console.log(currentTableData, 'currentTableData')
  // const validationSchema = yup.object({})
  // const formikRef: any = useRef(null)
  const [searchVal, setSearchVal] = useState<string>('')

  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    setFieldValue('income', incomeValueList)
    setFieldValue('expense', expenseValueList)
  }, [currentPage])

  if (loading) return <CircularProgress />
  else if (error) return <>Error OR no data! {error?.message}</>

  return (
    <form className={styles.container} onSubmit={props.handleSubmit}>
      <section className={styles.Filterbar}>
        <>{/* <SearchInput name="search" label="ค้นหาชื่อ - สกุล/รหัสพนักงาน" /> */}</>
      </section>
      {/* <InputTextComponent
            _name="searchValues"
            _label="ค้นหาแผนก/รหัส/รายชื่อพนักงาน"
            _helperText={props.errors.searchValues}
          />
          <div className={styles.toolbarsContainer}>
            <section className={styles.leftToolbars}>
              <article className={styles.configBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="blue"
                  _colorText="white"
                  _variant="outlined"
                  _isIcon="expenseIncome"
                  _text="ตั้งค่ารายจ่าย"
                  _functionOnClick={() => {
                    console.log('button Click !!')
                  }}
                />
              </article>
              <article className={styles.filterBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="transparent"
                  _colorText="blue"
                  _variant="text"
                  _isIcon="filter"
                  _text="ตัวกรองข้อมูล"
                  _functionOnClick={() => {
                    console.log('button Click !!')
                  }}
                />
              </article>
            </section>
            <section className={styles.rightToolbars}>
              <label className={styles.fileUpload}>
                <article className={styles.chooseFileBtn}>
                  <ButtonComponent
                    _type="button"
                    _colorBG="lightBlue"
                    _colorText="blue"
                    _variant="outlined"
                    _text="เลือกไฟล์"
                  />
                </article>
                ไม่ได้เลือกไฟล์ใด
              </label>
              <input
                className={styles.inputfile}
                type="file"
                onChange={(event: any) => {
                  console.log(event.target.files)
                }}
              />
              <article className={styles.uploadFileBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="lightBlue"
                  _colorText="blue"
                  _variant="outlined"
                  _text="อัพโหลดไฟล์"
                />
              </article>
              <article className={styles.downloadTemplateBtn}>
                <ButtonComponent
                  _type="button"
                  _colorBG="secondary"
                  _colorText="white"
                  _variant="outlined"
                  _text="ดาวน์โหลดเทมเพลท"
                />
              </article>
            </section>
          </div> */}
      <TableIncomeExpense
        topic="รายรับ"
        prefixValues="income"
        tableHeaderList={incomeTableHeaderList}
        tableBodyList={props.values.income}
        searchVal={searchVal}
      />
      <TableIncomeExpense
        topic="รายจ่าย"
        prefixValues="expense"
        tableHeaderList={expenseTableHeaderList}
        tableBodyList={props.values.expense}
      />

      <PaginationBar
        // Convert from PageIndex to PageNumber
        currentTotalPageCount={currentMaxPageIndex + 1}
        // currentTotalPageCount={6}
        currentPage={currentPage}
        hasNextToken={maxHasNextToken}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        windowSize={windowSize}
      />
      {currentTableData && (
        <section className={styles.submitSection}>
          <div className={styles.submitBtnContainer}>
            <ButtonComponent _type="submit" _colorBG="blue" _colorText="white" _variant="outlined" _text="บันทึก" />
          </div>
        </section>
      )}
    </form>
  )
}

const EnhancedExpenseIncome = withFormik({
  mapPropsToValues: (props: IExpenseIncome) => {
    const {
      currentTableData,
      currentPage,
      setCurrentPage,
      pageSize,
      setPageSize,
      windowSize,
      maxHasNextToken,
      currentMaxPageIndex,
      loading,
      clearCacheRefetch,
      error,
    } = props.payrollEmployeeQuery
    const incomeValueList = packValue(currentTableData, 'otherIncomeList')
    const expenseValueList = packValue(currentTableData, 'otherExpenseList')
    return {
      income: incomeValueList,
      expense: expenseValueList,
    }
  },
  handleSubmit: (values: { income: never[]; expense: never[] }, { props }) => {
    // console.log(values.income, 'val')
    // console.log(fcProps.payrollList, 'fcProps.payrollList')
    const unfrozenFcProps = JSON.parse(JSON.stringify(props.payrollEmployeeQuery)) // First this is props... will need to change later!!
    const cloneListPayroll = unfrozenFcProps.payrollList.employeePayrollList.map((employeePayrollElement: any) => {
      delete employeePayrollElement.__typename
      delete employeePayrollElement.employeePeriod
      // console.log('employeePayrollElement', employeePayrollElement)
      values.income.map((incomeElement: any) => {
        const { departmentName, employeeName, employeeID, ...incomeFilterElement } = incomeElement
        if (employeeID === employeePayrollElement.employeeID) {
          Object.entries(incomeFilterElement).forEach(([key, value]) => {
            employeePayrollElement.otherIncomeList.map((otherIncomeElement: any) => {
              delete otherIncomeElement.__typename
              // console.log(otherIncomeElement, 'otherIncomeElement')
              if (otherIncomeElement.id === key) {
                value === '' || !value ? (otherIncomeElement.amount = 0) : (otherIncomeElement.amount = value)
              }
            })
          })
        }
      })
      console.log('values.income', values.income)
      values.expense.map((expenseElement: any) => {
        const { departmentName, employeeName, employeeID, ...expenseFilterElement } = expenseElement
        if (employeeID === employeePayrollElement.employeeID) {
          Object.entries(expenseFilterElement).forEach(([key, value]: any) => {
            employeePayrollElement.otherExpenseList.map((otherExpenseElement: any) => {
              delete otherExpenseElement.__typename

              if (otherExpenseElement.id === key) {
                value === '' || !value
                  ? (otherExpenseElement.amount = 0)
                  : (otherExpenseElement.amount = -Math.abs(value))
              }
            })
          })
        }
      })
      const {
        income,
        otTypeWorkIncome,
        otTypeOTIncome,
        otherIncomeList,
        workDiligentIncome,
        welfareList,
        inEarlyIncome,
        breakEarlyIncome,
        outLateIncome,
        //expense
        otherExpenseList,
        inLateExpense,
        breakLateExpense,
        outEarlyExpense,
      } = employeePayrollElement

      employeePayrollElement.netPositiveIncome =
        income +
        otTypeWorkIncome +
        otTypeOTIncome +
        (otherIncomeList.length
          ? JSON.parse(JSON.stringify(otherIncomeList))
              .map((incomeElement: any) => incomeElement.amount)
              .reduce((a: number, b: number) => a + b)
          : 0) +
        workDiligentIncome +
        inEarlyIncome +
        breakEarlyIncome +
        outLateIncome +
        (welfareList.length
          ? JSON.parse(JSON.stringify(welfareList))
              .map((welfareElement: any) => welfareElement.amount)
              .reduce((a: number, b: number) => a + b)
          : 0)

      employeePayrollElement.netNegativeIncome =
        (otherExpenseList.length
          ? JSON.parse(JSON.stringify(otherExpenseList))
              .map((expenseElement: any) => expenseElement.amount)
              .reduce((a: number, b: number) => a + b)
          : 0) +
        inLateExpense +
        breakLateExpense +
        outEarlyExpense
      employeePayrollElement.netIncome =
        employeePayrollElement.netPositiveIncome + employeePayrollElement.netNegativeIncome
      const { employee, ...putVariables } = employeePayrollElement
      return putVariables
    })

    console.log(cloneListPayroll, 'cloneListPayroll')

    const variables = {
      yearMonth: props.date,
      period: props.period,
      input: cloneListPayroll,
    }
    console.log(variables, 'variables')
    swal('', {
      icon: 'warning',
      title: 'บันทึกข้อมูลรายรับรายจ่าย',
      text: 'ไม่สามารถแก้ไขการเปลี่ยนแปลงได้',
      buttons: ['ยกเลิก', 'บันทึก'],
    }).then((willSubmit: any) => {
      if (willSubmit) {
        putEmployeePayroll(variables).then((res: any) => {
          console.log(res, 'res putEmployeePayroll')
        })
        swal('', {
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
        })
      }
    })
  },
})(ExpenseIncome as any)

export default EnhancedExpenseIncome

const globalTableHeaderList: ITableHeader[] = [
  {
    text: 'ลำดับ',
    key: 'No.',
    type: 'text',
  },
  {
    text: 'แผนก',
    key: 'departmentName',
    type: 'text',
  },
  {
    text: 'รหัสพนักงาน',
    key: 'employeeID',
    type: 'text',
  },
  {
    text: 'ชื่อพนักงาน',
    key: 'employeeName',
    type: 'text',
  },
]

const packTableHeader = (employeePayrollList: EmployeePayroll[] | null) => {
  if (!employeePayrollList) return []

  function convertToHeader(someList: OtherIncomeOrExpense[] | null | undefined): ITableHeader[] {
    const outputList: ITableHeader[] = [...globalTableHeaderList]
    if (someList)
      outputList.push(
        ...someList.flatMap((element) => {
          if (
            element.variableOrFixed === EVariableOrFixed.Variable &&
            element.incomeExpenseType !== EIncomeExpenseType.Auto
          )
            return [
              {
                text: element.name,
                key: element.incomeOrExpenseID,
                type: 'input',
                inputType: 'number',
              } as ITableHeader,
            ]

          return []
        }),
      )
    return outputList
  }

  const incomeTableHeaderList = convertToHeader(employeePayrollList[0].otherIncomeList)
  const expenseTableHeaderList = convertToHeader(employeePayrollList[0].otherExpenseList)

  return [incomeTableHeaderList, expenseTableHeaderList]
}

const packValue = (
  valueList: EmployeePayroll[] | null,
  otherIncomeOrOtherExpense: 'otherIncomeList' | 'otherExpenseList',
): never[] & any[] => {
  if (!valueList) return []
  const packValueList = valueList.map((employeeElement) => {
    const packValue: any = {
      departmentName: employeeElement?.employee?.department?.departmentName,
      employeeID: employeeElement?.employeeID,
      employeeName:
        (employeeElement?.employee?.fullName ? employeeElement?.employee?.fullName : null) +
        ' ' +
        (employeeElement?.employee?.lastName ? employeeElement?.employee?.lastName : null),
    }

    employeeElement[`${otherIncomeOrOtherExpense}`]!.map((element) => {
      if (element.variableOrFixed === 'VARIABLE' && element.incomeExpenseType !== 'AUTO') {
        packValue[element.incomeOrExpenseID] = Math.abs(element.amount || 0)
      }
    })
    return packValue
  })
  console.log('THIS IS PACK VALUE: ', packValueList)
  return packValueList as never[] & any[]
}
