import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { listPosition } from 'src/adapter/xhr/query'
import useHandleResponseAPI from '../useHandleResponseAPI'

function usePosition() {
  const { data, refetch, error, loading } = useQuery(gql(listPosition))

  const isLoading = useMemo(() => {
    return loading
  }, [loading])

  const errMsg = useMemo(() => {
    if (error) return JSON.stringify(error)
    else return ''
  }, [error])

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg,
  })

  return {
    data: data?.listPosition,
    refetch,
  }
}

export default usePosition
