import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCloseNotification, setOpenNotificationLoading } from 'src/state/slice/notification'
import Swal from 'sweetalert2'

interface Props {
  isLoading: boolean
  errMsg: string
}

const useHandleResponseAPI = ({ isLoading, errMsg }: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (errMsg) {
      Swal.fire({
        icon: 'error',
        title: 'Error API',
        text: errMsg,
        customClass: {
          container: 'swal',
        },
      })
    } else if (isLoading) {
      dispatch(
        setOpenNotificationLoading({
          title: 'Loading Data .....',
          description: 'In progress',
          typeNotification: 'loading',
          isLoading: true,
        }),
      )
    } else dispatch(setCloseNotification())
  }, [isLoading, errMsg])
}

export default useHandleResponseAPI
