import { PutPayrollConfigInput, PayrollConfig } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationPutPayrollConfig } from '../xhr/mutation'
import { queryGetPayrollConfig } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function getPayrollConfig() {
  return gqlResponseType<PayrollConfig>(
    post('/', {
      query: queryGetPayrollConfig,
    }),
    'getPayrollConfig',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------

export function putPayrollConfig(input: PutPayrollConfigInput) {
  return gqlResponseType<PayrollConfig>(
    post('/', {
      query: mutationPutPayrollConfig,
      variables: { input },
    }),
    'putPayrollConfig',
  )
}
