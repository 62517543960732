//lib
import React from 'react'
import { useFormikContext } from 'formik'

//Include Project
import { getThaiDate } from 'src/utils/common'
import { ButtonComponent } from 'src/component'
import styles from './index.module.scss'

interface Props {
  date: string
  functionOnClick: any
  handleClose: () => void
}

const HeadModalAttendaneReport: React.FC<Props> = (props: Props): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <div className={styles.headDate}>
      <div className={styles.headButton}>
        <div className={styles.buttonForm}>
          <ButtonComponent
            _colorBG="white"
            _colorText="red"
            _text="ยกเลิก"
            _variant="text"
            _type="button"
            _functionOnClick={() => {
              props.handleClose()
            }}
          />
        </div>
        <div className={styles.buttonForm}>
          <ButtonComponent
            _colorBG="white"
            _colorText="blue"
            _text="บันทึก"
            _variant="text"
            _type="submit"
            _functionOnClick={() => {
              props.functionOnClick()
            }}
          />
        </div>
      </div>
      <p className={styles.dateText}>{getThaiDate(new Date(props.date)).format('day date month year')}</p>
      {values.attendance?.shiftName && (
        <div className={styles.subText}>
          <p>{values.attendance.shiftName}</p>:
          <span>
            {values.attendance.shiftStartTime} - {values.attendance.shiftEndTime}
          </span>
          {values.attendance.shiftVersion && (
            <div>
              <section className={styles.vershift}>
                <p>{values.attendance.shiftVersion}</p>
              </section>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default HeadModalAttendaneReport
