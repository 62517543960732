import { Box, Stack } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import React from 'react'
import { AutocompleteComponent, ButtonComponent, ContentContainer, InputCheckboxComponentToggle } from '../../component'
import {
  OtherIncomeOrExpenseConfigItem,
  TaxConfig,
  WelfareConfig,
  OtherIncomeOrExpenseConfig,
  EIncomeExpenseType,
  EOverTimeType,
} from '../../utils/generated'
import {
  createTaxConfig,
  getListOtherIncomeOrExpenseConfig,
  getListWelfareConfig,
  getTaxConfig,
} from '../../adapter/api'

import Swal from 'sweetalert'
import { deepClone } from '../../utils/common'

type IValue = {
  taxConfig?: TaxConfig
  incomeOrExpenseConfig?: OtherIncomeOrExpenseConfig
  incomeList?: OtherIncomeOrExpenseConfigItem[]
  expenseList?: OtherIncomeOrExpenseConfigItem[]
  welfareList?: WelfareConfig[]
}

const TaxPolicyPage = () => {
  const initialValues: IValue = {}

  const [prevConfig, setPrevConfig] = React.useState<TaxConfig>()

  const onSubmit = async (values: IValue) => {
    try {
      await createTaxConfig(values.taxConfig!)
      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
    }
  }

  const formik = useFormik({ initialValues, onSubmit })

  const fethcTaxConfig = async () => {
    const res = await getTaxConfig()
    formik.setFieldValue('taxConfig', res)
    setPrevConfig(deepClone(res))
  }

  const fetchIncomeExpense = async () => {
    const res = await getListOtherIncomeOrExpenseConfig()
    formik.setFieldValue('incomeOrExpenseConfig', res)
    formik.setFieldValue('incomeList', res.otherIncomeConfigList)
    formik.setFieldValue('expenseList', res.otherExpenseConfigList)
  }

  const fetchWelfareConfig = async () => {
    const res = await getListWelfareConfig()
    formik.setFieldValue('welfareList', res.items)
  }

  React.useEffect(() => {
    fethcTaxConfig()
    fetchIncomeExpense()
    fetchWelfareConfig()
  }, [])

  const incomeSelectList = React.useMemo(() => {
    const list = formik.values.incomeList || []

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.incomeList])

  const expenseSelectList = React.useMemo(() => {
    const list = formik.values.expenseList || []

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.expenseList])

  const welfareSelectList = React.useMemo(() => {
    const list = formik.values.welfareList || []

    return list.map((e) => ({ title: e.name as string, value: e.welfareID }))
  }, [formik.values.welfareList])

  const fundSelectList = React.useMemo(() => {
    if (!formik.values.incomeOrExpenseConfig) return []

    const { otherExpenseConfigList, otherIncomeConfigList } = formik.values.incomeOrExpenseConfig

    const fundFilter = (item: OtherIncomeOrExpenseConfigItem) => item.incomeExpenseType === EIncomeExpenseType.Fund

    const list = [otherIncomeConfigList.filter(fundFilter), otherExpenseConfigList.filter(fundFilter)].flat()

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.incomeOrExpenseConfig])

  const autoSelectList = React.useMemo(() => {
    if (!formik.values.incomeOrExpenseConfig) return []

    const { otherExpenseConfigList, otherIncomeConfigList } = formik.values.incomeOrExpenseConfig

    const autoFilter = (item: OtherIncomeOrExpenseConfigItem) => item.incomeExpenseType === EIncomeExpenseType.Auto

    const list = [otherIncomeConfigList.filter(autoFilter), otherExpenseConfigList.filter(autoFilter)].flat()

    return list.filter((e) => e.name !== 'หัก ณ ที่จ่าย').map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.incomeOrExpenseConfig])

  const overtimeSelectList = React.useMemo(() => {
    // Overtime UI ไม่มี?: Dropdown EOverTimeType OT Work (วันทำงานในวันหยุด), OT OT (วันทำงานล่วงเวลา)
    return [
      {
        title: 'วันทำงานในวันหยุด',
        value: EOverTimeType.Work,
      },
      {
        title: 'วันทำงานล่วงเวลา',
        value: EOverTimeType.Overtime,
      },
    ]
  }, [])

  // const handleToggleAll = () => {
  //   const { values, setFieldValue } = formik
  //   setFieldValue('taxConfig.baseSalary', values.taxConfig?.baseSalary ? false : true)
  // }

  const handleReset = () => {
    formik.setFieldValue('taxConfig', deepClone(prevConfig))
  }

  return (
    <div>
      <ContentContainer>
        <FormikProvider value={formik}>
          <Form>
            <Stack rowGap="1rem" sx={{ width: '50%' }}>
              <Stack direction="row" alignItems="center" columnGap="1rem">
                <Box sx={{ fontWeight: 'bold', fontSize: '18px' }}>เงื่อนไขที่นำมาคำนวณ</Box>
                {/* <Box
                  sx={{ color: theme.colors.primary, textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={handleToggleAll}
                >
                  เลือกทั้งหมด
                </Box> */}
              </Stack>

              <InputCheckboxComponentToggle _name="taxConfig.isBaseSalary" _label="ฐานเงินเดือน" />

              <AutocompleteComponent _name="taxConfig.income" _option={incomeSelectList} _label="รายรับ" />
              <AutocompleteComponent _name="taxConfig.expense" _option={expenseSelectList} _label="รายหัก" />
              <AutocompleteComponent _name="taxConfig.welfare" _option={welfareSelectList} _label="สวัสดิการ" />
              <AutocompleteComponent _name="taxConfig.fund" _option={fundSelectList} _label="กองทุน" />
              <AutocompleteComponent _name="taxConfig.auto" _option={autoSelectList} _label="Auto" />
              <AutocompleteComponent
                _name="taxConfig.overtime"
                _option={overtimeSelectList}
                _label="การทำงานล่วงเวลา"
              />

              <Stack direction="row" columnGap="1rem">
                <ButtonComponent
                  _colorBG="white"
                  _colorText="red"
                  _text="ยกเลิก"
                  _type="button"
                  _variant="contained"
                  _sx={{ width: 'fit-content' }}
                  _functionOnClick={handleReset}
                />
                <ButtonComponent
                  _colorBG="blue"
                  _colorText="white"
                  _text="บันทึก"
                  _type="submit"
                  _variant="contained"
                  _sx={{ width: 'fit-content' }}
                />
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </ContentContainer>
    </div>
  )
}

export default TaxPolicyPage
