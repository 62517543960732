//lib
import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

//Include Project
import { eqTime, LeaveFormList } from 'src/utils/common'
import { InputDateComponentCustome, InputTimeComponentCustom, UploadFileComponent } from 'src/component'
import styles from './index.module.scss'
import Switch from 'src/component/Switch'
import LeaveSelect from 'src/component/LeaveSelect'
import { ModalLeaveDocument } from 'src/pages/AttendanceReport/ModalAttendance/ModalLeave'
import { ELeavForm, EStatus } from 'src/pages/AttendanceReport/EType'
import { useLeaveDocument } from 'src/hooks'

//Images
import AddIcon from '../../image/plusIcon.svg'
import editSVG from '../../image/edit.svg'
import closeSVG from '../../image/closeGray.svg'
import Swal from 'sweetalert2'
import moment from 'moment'

const LeaveAttendaceReport: React.FC = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [tabAction, setTabAction] = useState<any>({ 0: false, 1: false })
  const [openModalLeave, setOpenModalLeave] = React.useState(false)
  const [leaveForm, setLeaveForm] = useState('')
  const [index, setIndex] = useState<number>(0)

  const handleTabAction = (index: any) => {
    const tab = tabAction[index]
    if (tabAction[index] === true) {
      setTabAction({ ...tabAction, ...{ [index]: !tab } })
    } else {
      setTabAction({ ...tabAction, ...{ [index]: !tab } })
    }
  }
  const CheckQuota = (quota: number) => {
    if (isNaN(quota)) {
      return 0
    } else {
      return quota
    }
  }
  const GetQuota = (leaveType: string, type: string) => {
    const employeeQuata = values.attendance.employee.listLeaveQuota?.items
    if (type === 'leave') {
      const balanceQaota = employeeQuata.find((ele: any) => ele.prev >= 0 && ele.leaveConfig.id === leaveType)
      // console.log(CheckQuota(balanceQaota?.begin - balanceQaota?.used), 'check1')

      return CheckQuota(balanceQaota?.begin - balanceQaota?.used)
    } else {
      const balanceQaota = employeeQuata.find((ele: any) => ele.prev < 0 && ele.leaveConfig.id === leaveType)
      // console.log(CheckQuota(balanceQaota?.begin - balanceQaota?.used), 'check2')

      return CheckQuota(balanceQaota?.begin - balanceQaota?.used)
    }
  }

  const { createLeaveDocument, deleteLeaveDocument } = useLeaveDocument()

  const onDeleteLeave = (value: any, index: number) => {
    Swal.fire({
      title: 'ลบรายการเอกสาร',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#FF6161',
      confirmButtonText: 'ตกลง',
      reverseButtons: true,
      customClass: {
        container: 'swal',
      },
    }).then((del) => {
      if (del.isConfirmed) {
        values.report.leaveList.splice(index, 1)
        setFieldValue(`leaveList`, values.report.leaveList)
        deleteLeaveDocument({
          variables: {
            input: {
              employeeID: values.attendance.employeeID,
              id: value.leaveRequestID,
            },
          },
        }).then(() => {
          Swal.fire({
            title: 'ลบรายการเอกสารเรียบร้อยแล้ว',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              container: 'swal',
            },
          })
        })
      }
    })
  }

  const SetIntervalsLeave = (leaveForm: any, index: number) => {
    if (leaveForm === 'FULLDAY_LEAVE') {
      setFieldValue(`report.leaveList[${index}].leaveForm`, leaveForm)
      setFieldValue(`report.leaveList[${index}].startedAt`, values.attendance.date)
      setFieldValue(`report.leaveList[${index}].endedAt`, values.attendance.date)
    } else if (leaveForm == 'HALFDAY_MORNING_LEAVE') {
      setFieldValue(`report.leaveList[${index}].leaveForm`, leaveForm)
      setFieldValue(`report.leaveList[${index}].startedAt`, values.attendance.shiftStartTime)
      setFieldValue(`report.leaveList[${index}].endedAt`, values.attendance.breakTimeList[0].startTime)
    } else if (leaveForm == 'HALFDAY_AFTERNOON_LEAVE') {
      setFieldValue(`report.leaveList[${index}].leaveForm`, leaveForm)
      setFieldValue(`report.leaveList[${index}].startedAt`, values.attendance.breakTimeList[0].endTime)
      setFieldValue(`report.leaveList[${index}].endedAt`, values.attendance.shiftEndTime)
    } else if (leaveForm == 'CUSTOM_TIME') {
      setFieldValue(`report.leaveList[${index}].leaveForm`, leaveForm)
      setFieldValue(`report.leaveList[${index}].startedAt`, '')
      setFieldValue(`report.leaveList[${index}].endedAt`, '')
    }
    return leaveForm
  }
  useEffect(() => {
    SetIntervalsLeave(leaveForm, index)
  }, [leaveForm])

  const onSubmitStatusLeave = (value: any, status: string, quota: any) => {
    if (value.leaveForm === 'FULLDAY_LEAVE') {
      const diffInDays = moment(value.endedAt).diff(moment(value.startedAt), 'days')
      if (quota < diffInDays) {
        return Swal.fire({
          title: 'วันลาคงเหลือไม่เพียงพอ',
          icon: 'error',
          customClass: {
            container: 'swal',
          },
        })
      }
    }

    if (quota === 0) {
      return Swal.fire({
        title: 'วันลาคงเหลือไม่เพียงพอ',
        icon: 'error',
        customClass: {
          container: 'swal',
        },
      })
    } else {
      return Swal.fire({
        title: 'บันทึกเอกสารลา',
        html: `วันที่ ${values.attendance.date} <br> เวลา ${value.startedAt} - ${value.endedAt}`,
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#3080f8',
        confirmButtonText: 'ตกลง',
        reverseButtons: true,
        customClass: {
          container: 'swal',
        },
      }).then((save) => {
        if (save.isConfirmed) {
          createLeaveDocument({
            variables: {
              input: {
                date: values.attendance.date,
                employeeID: values.attendance.employeeID,
                status: status,
                leaveType: value.leaveType,
                startedAt: value.startedAt,
                endedAt: value.endedAt,
                isLeaveDeductMoney: value.type === 'leaveDeduct' ? true : false,
                leaveForm: value.leaveForm,
                leaveDocumentAttachment: value.leaveDocumentAttachment,
              },
            },
          }).then((res: any) => {
            Swal.fire({
              title: 'บันทึกเอกสารลาเรียบร้อยแล้ว',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                container: 'swal',
              },
            })
            const _data = res.data.createLeaveRequest
            values.report.leaveList.push({
              leaveType: value.leaveType,
              startedAt: value.startedAt,
              endedAt: value.endedAt,
              leaveDocumentAttachment: value.leaveDocumentAttachment,
              status: status,
              leaveForm: _data.leaveForm,
              type: _data.isLeaveDeductMoney === true ? 'leaveDeduct' : 'leave',
              leaveRequestID: _data.id,
              leaveConfig: _data.leaveConfig,
            })
            values.report.leaveList
              .filter((e: any) => !e?.leaveRequestID)
              .forEach((ot: any) => {
                const idx: any = values.report.leaveList?.findIndex((e: any) =>
                  eqTime(new Date(`${values.report.date}T${ot.startedAt}+07:00`), e.startedAt),
                )

                if (idx !== undefined && idx !== -1 && values.report.leaveList && values.report.leaveList[idx]) {
                  values.report.leaveList.splice(idx, 1)
                  setFieldValue(`report.leaveList`, values.report.leaveList)
                }
              })
          })
        }
      })
    }
  }

  const handleSwithTypeLeave = (value: string, index: number) => {
    if (value === 'leaveDeduct') {
      setFieldValue(`report.leaveList[${index}].type`, 'leaveDeduct')
    } else if (value === 'leave') {
      setFieldValue(`report.leaveList[${index}].type`, 'leave')
    }
  }

  return (
    <div className={styles.container}>
      <style lang="scss">{`
      .swal {
          z-index: 3000;
            }
      `}</style>
      <div className={styles.wrapperInput}>
        <div className={styles.gropHead}>
          <p className={styles.TextHead}>การลา</p>
          <img
            src={AddIcon}
            alt="addIcon"
            onClick={() => {
              setOpenModalLeave(true)
              setFieldValue('report.overtimeList', values.report.overtimeList)
            }}
          />
        </div>
        {values.report.leaveList?.map((itemLeave: any, Index: any) => (
          <section className={styles.cardDocument} key={Index}>
            <div className={styles.box}>
              <div className={styles.headButton}>
                <section className={returnstyles(itemLeave.status)}>
                  <p>{EStatus[`${itemLeave.status}`]}</p>
                </section>
                <Switch
                  disables={itemLeave.status ? true : false}
                  value={itemLeave.type}
                  options={[
                    { label: 'ลาไม่หักเงิน', value: 'leave' },
                    { label: 'ลาหักเงิน', value: 'leaveDeduct' },
                  ]}
                  onChange={(value: any) => {
                    handleSwithTypeLeave(value, Index)
                  }}
                />
                <div className={styles.Icon}>
                  <img
                    src={editSVG}
                    style={{ cursor: 'pointer' }}
                    alt="DetailIcon"
                    onClick={() => handleTabAction(Index)}
                  />
                </div>
              </div>

              <section className={styles.inputBox}>
                <p className={styles.descriptionText}>ประเภทการลา</p>
                <div className={styles.input}>
                  <LeaveSelect
                    name={`report.leaveList[${Index}].leaveType`}
                    value={itemLeave.leaveType}
                    employee={values.attendance.employee}
                    disbled={itemLeave.status ? true : false}
                  />
                </div>
              </section>
              {values.attendance?.employee.listLeaveQuota?.items?.length > 0 && itemLeave.leaveType !== 'ABSENT' ? (
                <p className={styles.textQuota}>
                  วันลาคงเหลือ{' '}
                  {itemLeave.type === 'leave' && GetQuota(itemLeave.leaveType, itemLeave.type) < 0
                    ? '0'
                    : itemLeave.type === 'leave' && GetQuota(itemLeave.leaveType, itemLeave.type) > 0
                    ? GetQuota(itemLeave.leaveType, itemLeave.type)
                    : GetQuota(itemLeave.leaveType, itemLeave.type) < 0
                    ? 'ไม่จำกัด'
                    : GetQuota(itemLeave.leaveType, itemLeave.type)}{' '}
                  <span> {''}วัน</span>
                </p>
              ) : (
                ''
              )}

              {itemLeave.leaveType === 'ABSENT' ? (
                ''
              ) : (
                <section className={styles.inputBox}>
                  <p className={styles.descriptionText}>รูปแบบการลา</p>
                  <div className={styles.input}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        shrink
                        sx={{
                          fontFamily: 'SFThonburiRegular',
                          backgroundColor: 'white',
                          padding: '0px 5px',
                        }}
                      >
                        {'รูปแบบการลา'}
                      </InputLabel>
                      <Select
                        disabled={itemLeave.status ? true : false}
                        id="leaveForm"
                        sx={{
                          borderRadius: 1,
                          fontFamily: 'SFThonburiRegular',
                          backgroundColor: 'white',
                        }}
                        value={itemLeave.leaveForm}
                        label={'รูปแบบการลา'}
                        onChange={(e: any) => {
                          setLeaveForm(e.target.value)
                          setIndex(Index)
                        }}
                        name="leaveForm"
                      >
                        {LeaveFormList.map((data: any, index: number) => (
                          <MenuItem key={index} value={data.value} sx={{ fontFamily: 'SFThonburiRegular' }}>
                            {data.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </section>
              )}

              {itemLeave.leaveForm === 'FULLDAY_LEAVE' ? (
                <section className={styles.inputBox}>
                  <p className={styles.descriptionText}>ช่วงวันที่</p>
                  <div className={styles.groupBox}>
                    <InputDateComponentCustome
                      _name={`report.leaveList[${Index}].startedAt`}
                      _label={'เริ่มต้น'}
                      _value={itemLeave.startedAt}
                      _disabled={itemLeave.status ? true : false}
                    />
                    <InputDateComponentCustome
                      _name={`report.leaveList[${Index}].endedAt`}
                      _label={'สิ้นสุด'}
                      _value={itemLeave.endedAt}
                      _disabled={itemLeave.status ? true : false}
                    />
                  </div>
                </section>
              ) : (
                <section className={styles.inputBox}>
                  <p className={styles.descriptionText}>ช่วงเวลา</p>
                  <div className={styles.groupBox}>
                    <InputTimeComponentCustom
                      _name={`report.leaveList[${Index}].startedAt`}
                      _label={'เริ่มต้น'}
                      _value={itemLeave.startedAt}
                      _disabled={
                        itemLeave.status ||
                        itemLeave.leaveForm === 'HALFDAY_AFTERNOON_LEAVE' ||
                        itemLeave.leaveForm === 'HALFDAY_MORNING_LEAVE'
                          ? true
                          : false
                      }
                    />
                    <InputTimeComponentCustom
                      _name={`report.leaveList[${Index}].endedAt`}
                      _label={'สิ้นสุด'}
                      _value={itemLeave.endedAt}
                      _disabled={
                        itemLeave.status ||
                        itemLeave.leaveForm === 'HALFDAY_AFTERNOON_LEAVE' ||
                        itemLeave.leaveForm === 'HALFDAY_MORNING_LEAVE'
                          ? true
                          : false
                      }
                    />
                  </div>
                </section>
              )}
              {itemLeave.leaveType === 'ABSENT' ? (
                ''
              ) : (
                <section className={styles.inputBox}>
                  <p className={styles.descriptionText}>แนบไฟล์</p>
                  <div className={styles.input}>
                    <UploadFileComponent
                      name={`report.leaveList[${Index}].leaveDocumentAttachment`}
                      flodername={'LeaveDocument'}
                      valueShow={itemLeave.leaveDocumentAttachment}
                      label="แนบเอกสารการลา"
                      id={values.attendance.employeeID}
                      disabled={itemLeave.status ? true : false}
                    />
                  </div>
                </section>
              )}
              {tabAction[Index] === true ? (
                <section className={styles.buttonForm}>
                  <div className={styles.Icon}>
                    <img
                      src={closeSVG}
                      style={{ cursor: 'pointer' }}
                      alt="closeSVG"
                      onClick={() => handleTabAction(Index)}
                    />
                  </div>
                  <div className={styles.buttonGroup}>
                    {ButtonListLeave(itemLeave.status)?.map((item: any, index: number) => (
                      <div
                        className={item.styles}
                        key={index}
                        onClick={() => {
                          if (item.value === 'save') {
                            onSubmitStatusLeave(itemLeave, 'PENDING', GetQuota(itemLeave.leaveType, itemLeave.type))
                          } else if (item.value === 'saveapprove') {
                            onSubmitStatusLeave(itemLeave, 'APPROVE', GetQuota(itemLeave.leaveType, itemLeave.type))
                          } else if (item.value === 'delete') {
                            onDeleteLeave(itemLeave, Index)
                          }
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </section>
              ) : (
                ''
              )}
            </div>
          </section>
        ))}
      </div>
      <ModalLeaveDocument open={openModalLeave} setOpen={setOpenModalLeave} createLeaveFunction={createLeaveDocument} />
    </div>
  )
}

const returnstyles = (status: string) => {
  if (status === 'APPROVE') {
    return `${styles.pillApprove}`
  } else if (status === 'PENDING') {
    return `${styles.pillPending}`
  } else if (status === 'DRAFT') {
    return `${styles.pillDraft}`
  } else if (status === 'REJECT') {
    return `${styles.pillReject}`
  } else {
    return `${styles.pillSuggest}`
  }
}

const ButtonListLeave = (status: string) => {
  const ButtonListApprove = [
    {
      label: 'ไปหน้าเอกสาร',
      styles: `${styles.buttonDocument}`,
      value: 'document',
    },
    {
      label: 'ลบ',
      styles: `${styles.buttonDeleDisabled}`,
      value: 'delete',
    },
  ]
  const ButtonList = [
    {
      label: 'บันทึก',
      styles: `${styles.buttonSave}`,
      value: 'save',
    },
    {
      label: 'บันทึกและอนุมัติ',
      styles: `${styles.buttonSaveApprove}`,
      value: 'saveapprove',
    },
    {
      label: 'ลบ',
      styles: `${styles.buttonDeletes}`,
      value: 'delete',
    },
  ]

  if (status === 'APPROVE' || status === 'PENDING') {
    return ButtonListApprove
  } else {
    return ButtonList
  }
}

export default LeaveAttendaceReport
