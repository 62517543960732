import { Tooltip } from '@mui/material'
import React, { FC } from 'react'

interface Props {
  label: any
  child: any
}

const TooltipArrow: FC<Props> = (props: Props) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffffff',
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',

            '& .MuiTooltip-arrow': {
              color: '#ffffff',
            },
          },
        },
      }}
      title={<p style={{ color: 'black' }}>{props.label}</p>}
      placement="top-start"
      arrow={true}
    >
      {props.child}
    </Tooltip>
  )
}

export default TooltipArrow
