//lib
import React, { useState } from 'react'
import { useFormikContext } from 'formik'

//Includes Project
import {
  InputDateComponentCustome,
  InputNumberComponentCustom,
  InputSelectComponentCustom,
  InputTextComponentCustom,
} from '../../component/InputsFormik'
import { EFundDeductContribution, EWelfareQuotaUnit } from 'src/utils/generated'
import styles from './index.module.scss'
import { ModalFund } from './modal'

export const TableIncomeExpense: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()
  const HeadIncome = ['ประเภท', 'รายการ', 'มูลค่า', 'หน่วย']

  return (
    <table className="tableRounded">
      <tr>
        {HeadIncome.map((item: any, index: number) => (
          <th key={index}>{item}</th>
        ))}
      </tr>
      {values.formInput.incomeExpense?.map((ele: any, index: number) => (
        <tr key={index}>
          <td>{EINCOME_EXPENSE[ele.incomeExpenseType]}</td>
          <td>{ele.name}</td>
          <td>
            <InputNumberComponentCustom _name={`formInput.incomeExpense[${index}].total`} _value={ele.total} />
          </td>
          <td>
            <InputSelectComponentCustom
              _name={`formInput.incomeExpense[${index}].unit`}
              _menuList={Eunit}
              _label={'เลือกรายการ'}
              _value={ele.unit}
            />
          </td>
        </tr>
      ))}
    </table>
  )
}

export const TableFunde: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()
  const [open, setOpen] = useState(false)
  const Headfund = [
    'ชื่อกองทุน',
    'เลขที่กองทุน',
    'วันที่สัญญากองทุน',
    'วิธีการหักเงิน',
    'เรทกองทุน',
    'วิธีการสมทบ',
    'บริษัทสมทบ',
    'ยอดสมทบ',
    'ผู้ได้รับผลประโยชน์',
    'หมายเหตุ',
  ]

  return (
    <div className={styles.tableCard}>
      <p className={styles.textLink} onClick={() => setOpen(true)}>
        ขยายตาราง
      </p>
      <table className="tableRounded">
        <tr>
          {Headfund.map((item: any, index: number) => (
            <th key={index}>{item}</th>
          ))}
        </tr>
        {values.formInput.employeeFund.map((ele: any, index: number) => (
          <tr key={index}>
            <td>
              <InputTextComponentCustom _name={`formInput.employeeFund[${index}].name`} _value={ele.name} />
            </td>
            <td>
              <InputNumberComponentCustom
                _name={`formInput.employeeFund[${index}].fundNumber`}
                _value={ele.fundNumber}
              />
            </td>
            <td>
              <InputDateComponentCustome
                _name={`formInput.employeeFund[${index}].contractDate`}
                _value={ele.contractDate}
                _label="เลือกวันที่"
              />
            </td>
            <td>
              <InputSelectComponentCustom
                _label="เลือกรายการ"
                _name={`formInput.employeeFund[${index}].deductMethod`}
                _value={ele.deductMethod}
                _menuList={EFundDeduct}
              />
            </td>
            <td>
              <InputNumberComponentCustom _name={`formInput.employeeFund[${index}].fundRate`} _value={ele.fundRate} />
            </td>
            <td>
              <InputSelectComponentCustom
                _label="เลือกรายการ"
                _name={`formInput.employeeFund[${index}].contributionMethod`}
                _value={ele.contributionMethod}
                _menuList={EFundDeduct}
              />
            </td>
            <td>
              <InputTextComponentCustom
                _name={`formInput.employeeFund[${index}].contributionCompany`}
                _value={ele.contributionCompany}
              />
            </td>
            <td>
              <InputTextComponentCustom
                _name={`formInput.employeeFund[${index}].contributionTotal`}
                _value={ele.contributionTotal}
              />
            </td>
            <td>
              <InputTextComponentCustom
                _name={`formInput.employeeFund[${index}].beneficiary`}
                _value={ele.beneficiary}
              />
            </td>
            <td>
              <InputTextComponentCustom _name={`formInput.employeeFund[${index}].remark`} _value={ele.remark} />
            </td>
          </tr>
        ))}
      </table>
      <ModalFund open={open} setOpen={setOpen} headTable={Headfund} />
    </div>
  )
}
const EINCOME_EXPENSE: any = {
  INCOME: 'รายได้',
  EXPENSE: 'รายหัก',
}

const Eunit = [
  { label: 'บาท/วัน', value: EWelfareQuotaUnit.BahtPerDay },
  { label: 'บาท/เดือน', value: EWelfareQuotaUnit.BahtPerMonth },
  { label: 'บาท/ปี', value: EWelfareQuotaUnit.BahtPerYear },
]

const EFundDeduct = [
  { label: 'คิดเป็น %', value: EFundDeductContribution.Percentage },
  { label: 'คิดเป็นบาท', value: EFundDeductContribution.Baht },
]
