import React, { useState } from 'react'
import {
  InputDateComponentCustome,
  InputSelectComponentCustom,
  InputTextComponentCustom,
} from '../../component/InputsFormik'
import { EFamilyStatus } from 'src/utils/generated'
import styles from './index.module.scss'
import deleteSVG from '../../image/deleteRedIcon.svg'
import { useFormikContext } from 'formik'

interface ITable {
  Header: string[]
}
export const Table: React.FC<ITable> = (props: ITable): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [editFamily, setEditFamily] = useState(false)

  const changDataFamily = () => {
    console.log('Click')
    setEditFamily(!editFamily)
  }

  const addRowData = () => {
    setFieldValue('formInput.historyFamily', [
      ...values.formInput.historyFamily,
      { fullName: '', age: '', job: '', relation: '' },
    ])
  }

  return (
    <section className={styles.groupTable}>
      <p className={styles.textHead}>ข้อมูลครอบครัว</p>
      <div className={styles.tableCard}>
        <p className={styles.wrapperTextLink} onClick={() => changDataFamily()}>
          {!editFamily ? 'แก้ไขข้อมูลครอบครัว' : 'เสร็จสิ้น'}
        </p>
        <table className="tableRounded">
          <tr>
            {props.Header.map((item: any, index: number) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
          {values.formInput.historyFamily?.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <InputTextComponentCustom _name={`formInput.historyFamily[${index}].relation`} _value={item.relation} />
              </td>
              <td>
                <InputTextComponentCustom _name={`formInput.historyFamily[${index}].fullName`} _value={item.fullName} />
              </td>
              <td>
                <InputDateComponentCustome
                  _name={`formInput.historyFamily[${index}].birthDate`}
                  _value={item.birthDate}
                  _label="เลือกวันที่"
                />
              </td>
              <td>
                <InputTextComponentCustom _name={`formInput.historyFamily[${index}].job`} _value={item.job} />
              </td>
              <td>
                <InputSelectComponentCustom
                  _label="เลือกรายการ"
                  _name={`formInput.historyFamily[${index}].familyStatus`}
                  _value={item.familyStatus}
                  _menuList={SelectFamilyStatus}
                />
              </td>
              <td>
                {editFamily ? (
                  <img
                    src={deleteSVG}
                    alt="deleteSVG"
                    className={styles.Icon}
                    onClick={() => {
                      values.formInput.historyFamily.splice(index, 1)
                      setFieldValue('formInput.historyFamily', values.formInput.historyFamily)
                    }}
                  />
                ) : (
                  ''
                )}
              </td>
            </tr>
          ))}
        </table>

        <p className={styles.wrapperTextLink} onClick={() => addRowData()}>
          เพิ่มแถวตาราง
        </p>
      </div>
    </section>
  )
}

const SelectFamilyStatus = [
  { label: 'ยังมีชีวิต', value: EFamilyStatus.Alive },
  { label: 'ถึงแก่กรรม', value: EFamilyStatus.Died },
]
