import React from 'react'

//Include Project
import styles from './index.module.scss'

interface Props {
  leaveList: any
}

const ChipLeave: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      {props.leaveList.length ? (
        <div className={styles.group}>
          {props.leaveList?.slice(0, 2).map((itemLeaveDeduct: any, IndexLeave: number) => (
            <div className={styles.groupPill} key={IndexLeave}>
              <section className={styles.pillDiffEarly}>
                {itemLeaveDeduct.leaveConfig.name ? <p>{itemLeaveDeduct.leaveConfig.name}</p> : <p>ขาด</p>}
              </section>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
export default ChipLeave
