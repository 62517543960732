import { useFormikContext } from 'formik'
import React from 'react'

//Images
import styles from './index.module.scss'
import { handleDecimalsOnValue } from 'src/utils/common'
import { Checkbox } from '@mui/material'

interface Props {
  summaryParentDiscount: any
  summarySpouseParentDiscount: any
}
const EmployeeTaxParentsDiscount: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.checkboxForm}>
          <div className={styles.flexForm}>
            <div className={styles.flexBox}>
              <p className={styles.text}>{`ค่าลดหย่อน บิดา-มารดา (ตนเอง) :`}</p>
              <div className={styles.groupCheckbox}>
                <Checkbox
                  sx={{
                    color: '#5357BB',
                    '&.Mui-checked': {
                      color: '#5357BB',
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue('employeeTax.fatherAllowance', !values.employeeTax.fatherAllowance)
                    props.summaryParentDiscount(e.target.checked)
                  }}
                  value={values.employeeTax.fatherAllowance}
                  checked={values.employeeTax.fatherAllowance}
                />
                <p>บิดา</p>
              </div>
              <div className={styles.groupCheckbox}>
                <Checkbox
                  sx={{
                    color: '#5357BB',
                    '&.Mui-checked': {
                      color: '#5357BB',
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue('employeeTax.motherAllowance', !values.employeeTax.motherAllowance)
                    props.summaryParentDiscount(e.target.checked)
                  }}
                  value={values.employeeTax.motherAllowance}
                  checked={values.employeeTax.motherAllowance}
                />
                <p>มารดา</p>
              </div>
            </div>
            <div className={styles.textNum}>
              <p className={styles.textHead}>{handleDecimalsOnValue(values.employeeTax.parentDiscount)}</p>
              <p>บาท</p>
            </div>
          </div>
          <p className={styles.textSubtitle}>
            {`คนละ 30,000 บาท (บิดามารดาต้องมีอายุเกิน 60 ปี และมีเงินได้ไม่เกิน 30,000 บาทต่อปี)`}
          </p>
        </div>

        {values.employeeTax.personalRelation === 'SPOUSE_NOT_INCOME' && (
          <div className={styles.checkboxForm}>
            <div className={styles.flexForm}>
              <div className={styles.flexBox}>
                <p className={styles.text}>{`ค่าลดหย่อน บิดา-มารดา (คู่สมรส) :`}</p>
                <div className={styles.groupCheckbox}>
                  <Checkbox
                    sx={{
                      color: '#5357BB',
                      '&.Mui-checked': {
                        color: '#5357BB',
                      },
                    }}
                    onChange={(e) => {
                      setFieldValue('employeeTax.fatherSPAllowance', !values.employeeTax.fatherSPAllowance)
                      props.summarySpouseParentDiscount(e.target.checked)
                    }}
                    value={values.employeeTax.fatherSPAllowance}
                    checked={values.employeeTax.fatherSPAllowance}
                  />
                  <p>บิดา</p>
                </div>
                <div className={styles.groupCheckbox}>
                  <Checkbox
                    sx={{
                      color: '#5357BB',
                      '&.Mui-checked': {
                        color: '#5357BB',
                      },
                    }}
                    onChange={(e) => {
                      setFieldValue('employeeTax.motherSPAllowance', !values.employeeTax.motherSPAllowance)
                      props.summarySpouseParentDiscount(e.target.checked)
                    }}
                    value={values.employeeTax.motherSPAllowance}
                    checked={values.employeeTax.motherSPAllowance}
                  />
                  <p>มารดา</p>
                </div>
              </div>
              <div className={styles.textNum}>
                <p className={styles.textHead}>{values.employeeTax.parentSpouseDiscountCount}</p>
                <p>บาท</p>
              </div>
            </div>
            <p className={styles.textSubtitle}>{`คนละ 30,000 บาท (เฉพาะกรณีคู่สมรสไม่มีรายได้)`}</p>
          </div>
        )}

        <div className={styles.bottomGroup}>
          <p className={styles.text}>รวมค่าลดหย่อนบิดา-มารดา : </p>
          <div className={styles.wrapperText}>
            <p className={styles.textResult}>
              {handleDecimalsOnValue(values.employeeTax.parentDiscount + values.employeeTax.parentSpouseDiscountCount)}
            </p>
            <p className={styles.text}>บาท</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeTaxParentsDiscount
