import { useFormikContext } from 'formik'
import React from 'react'

//Images
import styles from './index.module.scss'
import { handleDecimalsOnValue } from 'src/utils/common'
import { InputCurrencyFormat } from 'src/component/InputCurrency'

interface Props {
  totalInsuranceDiscount: any
}

const EmployeeTaxInsurance: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>เบี้ยประกันชีวิตบำนาญ: :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.lifePensionInsurance" />

              {/* <InputNumberUnitComponent
                      _name="employeeTax.lifePensionInsurance"
                      _value={values.employeeTax.lifePensionInsurance}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.lifePensionInsurance')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 200,000 บาท</p>
        </div>

        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>ค่าประกันชีวิต :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.lifeInsurance" />

              {/* <InputNumberUnitComponent
                      _name="employeeTax.lifeInsurance"
                      _value={values.employeeTax.lifeInsurance}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.lifeInsurance')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 100,000 บาท</p>
        </div>

        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>เบี้ยประกันสุขภาพบิดา,มารดา ตัวเองและคู่สมรส :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.familyHealthInsurance" />
              {/* 
                    <InputNumberUnitComponent
                      _name="employeeTax.familyHealthInsurance"
                      _value={values.employeeTax.familyHealthInsurance}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.familyHealthInsurance')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 15,000 บาท</p>
        </div>

        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>เบี้ยประกันสุขภาพคู่สมรส :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.familyHealthInsurance" />
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 15,000 บาท</p>
        </div>

        <div className={styles.bottomGroup}>
          <p className={styles.text}>รวมค่าลดหย่อนประกัน : </p>
          <div className={styles.wrapperText}>
            <p className={styles.textResult}>{handleDecimalsOnValue(props.totalInsuranceDiscount)}</p>
            <p className={styles.text}>บาท/ปี</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeTaxInsurance
