import React from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { useFormik, FormikProvider, Form } from 'formik'
import {
  AutocompleteComponent,
  ButtonComponent,
  ContentContainer,
  InputCheckboxComponentToggle,
  InputRadioComponent,
} from '../../component'
import {
  createProvidentFundConfig,
  getListOtherIncomeOrExpenseConfig,
  getListWelfareConfig,
  getProvidentFundConfig,
} from '../../adapter/api'
import {
  EIncomeExpenseType,
  EOverTimeType,
  EQualifyFund,
  OtherIncomeOrExpenseConfig,
  OtherIncomeOrExpenseConfigItem,
  ProvidentFundConfig,
  WelfareConfig,
} from '../../utils/generated'
import { deepClone } from '../../utils/common'
import Swal from 'sweetalert'

type IValue = {
  providentFundConfig?: ProvidentFundConfig
  incomeOrExpenseConfig?: OtherIncomeOrExpenseConfig
  incomeList?: OtherIncomeOrExpenseConfigItem[]
  expenseList?: OtherIncomeOrExpenseConfigItem[]
  welfareList?: WelfareConfig[]
}

const ProvidentFundPolicyPage = () => {
  const initialValues: IValue = {}

  const [prevConfig, setPrevConfig] = React.useState<ProvidentFundConfig>()

  const onSubmit = async (values: IValue) => {
    try {
      await createProvidentFundConfig(values.providentFundConfig!)
      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const fethcProvidentFundConfig = async () => {
    const res = await getProvidentFundConfig()
    formik.setFieldValue('providentFundConfig', res)
    setPrevConfig(deepClone(res))
  }

  const fetchIncomeExpense = async () => {
    const res = await getListOtherIncomeOrExpenseConfig()
    formik.setFieldValue('incomeOrExpenseConfig', res)
    formik.setFieldValue('incomeList', res.otherIncomeConfigList)
    formik.setFieldValue('expenseList', res.otherExpenseConfigList)
  }

  const fetchWelfareConfig = async () => {
    const res = await getListWelfareConfig()
    formik.setFieldValue('welfareList', res.items)
  }

  React.useEffect(() => {
    fethcProvidentFundConfig()
    fetchIncomeExpense()
    fetchWelfareConfig()
  }, [])

  const incomeSelectList = React.useMemo(() => {
    const list = formik.values.incomeList || []

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.incomeList])

  const expenseSelectList = React.useMemo(() => {
    const list = formik.values.expenseList || []

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.expenseList])

  const welfareSelectList = React.useMemo(() => {
    const list = formik.values.welfareList || []

    return list.map((e) => ({ title: e.name as string, value: e.welfareID }))
  }, [formik.values.welfareList])

  const fundSelectList = React.useMemo(() => {
    if (!formik.values.incomeOrExpenseConfig) return []

    const { otherExpenseConfigList, otherIncomeConfigList } = formik.values.incomeOrExpenseConfig

    const fundFilter = (item: OtherIncomeOrExpenseConfigItem) => item.incomeExpenseType === EIncomeExpenseType.Fund

    const list = [otherIncomeConfigList.filter(fundFilter), otherExpenseConfigList.filter(fundFilter)].flat()

    return list.map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.incomeOrExpenseConfig])

  const autoSelectList = React.useMemo(() => {
    if (!formik.values.incomeOrExpenseConfig) return []

    const { otherExpenseConfigList, otherIncomeConfigList } = formik.values.incomeOrExpenseConfig

    const autoFilter = (item: OtherIncomeOrExpenseConfigItem) => item.incomeExpenseType === EIncomeExpenseType.Auto

    const list = [otherIncomeConfigList.filter(autoFilter), otherExpenseConfigList.filter(autoFilter)].flat()

    return list.filter((e) => e.name !== 'หัก ณ ที่จ่าย').map((e) => ({ title: e.name, value: e.id }))
  }, [formik.values.incomeOrExpenseConfig])

  const overtimeSelectList = React.useMemo(() => {
    // Overtime UI ไม่มี?: Dropdown EOverTimeType OT Work (วันทำงานในวันหยุด), OT OT (วันทำงานล่วงเวลา)
    return [
      {
        title: 'วันทำงานในวันหยุด',
        value: EOverTimeType.Work,
      },
      {
        title: 'วันทำงานล่วงเวลา',
        value: EOverTimeType.Overtime,
      },
    ]
  }, [])

  const handleReset = () => {
    formik.setFieldValue('providentFundConfig', deepClone(prevConfig))
  }

  return (
    <div>
      <ContentContainer>
        <FormikProvider value={formik}>
          <Form>
            <Box sx={{ fontSize: '18px', mb: '1rem' }}>เงื่อนไขที่นำมาคำนวณ</Box>
            <Grid container columns={2} columnSpacing="4rem">
              <Grid item xs={1}>
                <Stack rowGap="1rem">
                  <InputCheckboxComponentToggle _name="providentFundConfig.isBaseSalary" _label="ฐานเงินเดือน" />

                  <AutocompleteComponent
                    _name="providentFundConfig.income"
                    _option={incomeSelectList}
                    _label="รายรับ"
                  />
                  <AutocompleteComponent
                    _name="providentFundConfig.expense"
                    _option={expenseSelectList}
                    _label="รายหัก"
                  />
                  <AutocompleteComponent
                    _name="providentFundConfig.welfare"
                    _option={welfareSelectList}
                    _label="สวัสดิการ"
                  />
                  <AutocompleteComponent _name="providentFundConfig.fund" _option={fundSelectList} _label="กองทุน" />
                  <AutocompleteComponent _name="providentFundConfig.auto" _option={autoSelectList} _label="Auto" />
                  <AutocompleteComponent
                    _name="providentFundConfig.overtime"
                    _option={overtimeSelectList}
                    _label="การทำงานล่วงเวลา"
                  />

                  <Stack direction="row" columnGap="1rem">
                    <ButtonComponent
                      _colorBG="white"
                      _colorText="red"
                      _text="ยกเลิก"
                      _type="button"
                      _variant="contained"
                      _sx={{ width: 'fit-content' }}
                      _functionOnClick={handleReset}
                    />
                    <ButtonComponent
                      _colorBG="blue"
                      _colorText="white"
                      _text="บันทึก"
                      _type="submit"
                      _variant="contained"
                      _sx={{ width: 'fit-content' }}
                    />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={1}>
                <Box sx={{ fontWeight: 'bold', mb: '1rem' }}>คุณสมบัติของทุน</Box>
                <InputRadioComponent
                  _name="providentFundConfig.qualifyFund"
                  _menuList={[
                    { value: EQualifyFund.ImmediatelyCompany, label: 'ทันทีตั้งแต่เริ่มงานกับบริษัท' },
                    { value: EQualifyFund.PassedProbation, label: 'ผ่านทดลองงาน' },
                  ]}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </ContentContainer>
    </div>
  )
}

export default ProvidentFundPolicyPage
