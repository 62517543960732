const setOpenModalConfirm = (
  title: string,
  subtitle: string,
  description: string,
  btnDone: string,
  functionCallBack: () => void,
): {
  type: string
  title: string
  subtitle: string
  description: string
  btnDone: string
  functionCallBack: () => void
} => {
  return {
    type: 'SET_OPEN_MODAL_CONFIRM',
    title: title,
    subtitle: subtitle,
    description: description,
    btnDone: btnDone,
    functionCallBack: functionCallBack,
  }
}

const setCloseModalConfirm = (): { type: string } => {
  return {
    type: 'SET_CLOSE_MODAL_CONFIRM',
  }
}

export default {
  setOpenModalConfirm,
  setCloseModalConfirm,
}
