import DashboardSVG from './images/dashboard.svg'
import EmployeeSVG from './images/employee.svg'
import PayrollSVG from './images/payroll.svg'
import Calendar from './images/calendarSvg.svg'
import CalendarWhite from './whiteImages/calendar.svg'
import DashboardWhite from './whiteImages/dashborad.svg'
import EmployeeWhite from './whiteImages/employee.svg'
import PayrollWhite from './whiteImages/payroll.svg'
import DocumentSVG from './images/document.svg'
import DocumentWhite from './whiteImages/document.svg'
import ReportSVG from './images/report.svg'
import ReportWhite from './whiteImages/report.svg'
import HelperSVG from './images/helpIcon.svg'
import HelpWhite from './whiteImages/help.svg'
import SettingSVG from './images/setting.svg'
import SettingWhite from './whiteImages/setting.svg'
import ApproveSVG from './images/approve.svg'
import ArroveWhite from './whiteImages/approve.svg'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'src/state/reducers'

export const menuList: IMenuItem[] = [
  {
    img: DashboardSVG,
    imgHover: DashboardWhite,
    name: 'Dashboard',
    link: `.`,
  },
  {
    img: Calendar,
    imgHover: CalendarWhite,
    name: 'Calendar',
    link: `./calendar`,
  },
  {
    img: ApproveSVG,
    imgHover: ArroveWhite,
    name: 'Approve',
    link: `./approve`,
    addDivider: true,
  },
  {
    img: EmployeeSVG,
    imgHover: EmployeeWhite,
    name: 'Employee Management',
    link: './employee/',
    expanded: false,
    subMenu: [
      { name: 'Employee Profile', link: './employee/employee' },
      { name: 'Position', link: './employee/position' },
      { name: 'Department', link: './employee/department' },
      { name: 'Team', link: './employee/team' },
      { name: 'Package Group', link: './employee/creatPackage' },
    ],
  },

  {
    img: PayrollSVG,
    imgHover: PayrollWhite,
    name: 'Payroll Management',
    // link: `/wage/payroll`,
    link: './wage/',
    expanded: false,
    subMenu: [
      { name: 'Attendance Log', link: './wage/attendanceLog' },
      { name: 'Attendance Report', link: './wage/attendanceReport' },
      { name: 'Payroll', link: './wage/payroll' },
    ],
  },

  {
    img: DocumentSVG,
    imgHover: DocumentWhite,
    name: 'Document Management',
    // link: `/wage/payroll`,
    link: './document/',
    expanded: false,
    subMenu: [
      { name: 'Leave', link: './document/LeaveDocument' },
      { name: 'OT', link: './document/OvertimeDocument' },
      // { name: 'Payroll', link: '/wage/payroll' },
    ],
  },
  {
    img: ReportSVG,
    imgHover: ReportWhite,
    name: 'Report',
    // link: `/wage/payroll`,
    link: './report/attendance/leavesummary',
    addDivider: true,
  },
  {
    img: SettingSVG,
    imgHover: SettingWhite,
    name: 'Setting',
    link: './masterdata/holidayPolicy/',
  },
  {
    img: HelperSVG,
    imgHover: HelpWhite,
    name: 'Help',
    link: './help',
    addDivider: true,
  },

  // {
  //   img: ApprovalSVG,
  //   name: 'การอนุมัติรายการ',
  //   link: `/approve`,
  // },
  // {
  //   img: ReportSVG,
  //   name: 'Report',
  //   link: `/report`,
  // },
  // {
  //   img: MasterDataSVG,
  //   name: 'Master Data',
  //   link: `/masterdata/attendance`,
  //   subMenu: [
  //     { name: 'ระเบียบการเข้างาน', link: '/masterdata/attendance' },
  //     { name: 'ระเบียบการลา', link: '/masterdata/leavepolicy' },
  //     { name: 'ระเบียบสวัสดิการ/ค่าใช้จ่าย', link: '/masterdata/benefitpolicy' },
  //     { name: 'ระเบียบปฏิทิน', link: '/masterdata/holidayPolicy' },
  //     { name: 'ระเบียบการทำงานล่วงเวลา', link: '/masterdata/overtimepolicy' },
  //   ],
  // },
  // {
  //   img: IntragationSVG,
  //   name: 'Intregation',
  //   link: `/intregation`,
  // },
  // {
  //   img: PackageSVG,
  //   name: 'แพคเกจ',
  //   link: `/package`,
  // },
]

export const menuReportList: IMenuItem[] = [
  {
    img: '',
    imgHover: '',
    name: 'Attendance Summary Report',
    link: `./report`,
    subMenu: [
      { name: 'Late,Leave,OT Report', link: 'report/attendance/leavesummary' },
      { name: 'Leave Quota Report', link: 'report/attendance/leavequota' },
      { name: 'Attendance Report', link: 'report/attendance/attendancereport' },
      { name: 'Month Attendance Report', link: 'report/attendance/attendancemonthreport' },
      { name: 'Annual Attendance Report', link: 'report/attendance/attendanceyearreport' },
      { name: 'Attendance Log', link: 'report/attendance/attendancelog' },
    ],
  },
  {
    img: '',
    imgHover: '',
    name: 'Payroll Report',
    link: `./report`,
    subMenu: [
      { name: 'Month Payroll Report', link: 'report/payroll/payrollpermonth' },
      { name: 'Annual Payroll Report', link: 'report/payroll/payrollannual' },
      { name: 'Payslip', link: 'report/payroll/payrollslip' },
      { name: 'Provident Fund Report', link: 'report/payroll/providentfund' },
      { name: 'PND 1 (ภงด 1)', link: '' },
      { name: 'Salary Raise Report', link: 'report/payroll/salaryraise' },
      { name: 'Social Security Report', link: 'report/payroll/socialsecurity' },
    ],
  },
  {
    img: '',
    imgHover: '',
    name: 'Revenue Department Report',
    link: `./report`,
    subMenu: [
      { name: 'กท.20 ก', link: 'report/revenue/compensationfund' },
      { name: 'ภ.ง.ด. 1ก', link: 'report/revenue/pnd1k' },
      { name: 'ภ.ง.ด.', link: 'report/revenue/pnd' },
      { name: '50 ทวิ', link: 'report/revenue/fiftybis' },
    ],
  },
  {
    img: '',
    imgHover: '',
    name: 'Employee Report',
    link: `./report`,
    subMenu: [
      { name: 'Employee List Report', link: 'report/employee/employee' },
      { name: 'New Employee Report', link: 'report/employee/newemployee' },
      { name: 'Full Employee List Report', link: 'report/employee/fullemployee' },
    ],
  },
  {
    img: '',
    imgHover: '',
    name: 'Welfare Report',
    link: `./report`,
  },
]

//Employee Nav
export const menuListEmployee: IMenuItem[] = [
  {
    img: Calendar,
    imgHover: CalendarWhite,
    name: 'Calendar',
    link: `.`,
  },
  {
    img: EmployeeSVG,
    imgHover: EmployeeWhite,
    name: 'Employee Management',
    link: './employee/',
    expanded: false,
    subMenu: [{ name: 'Employee Profile', link: `./employee/employee/edit/:id` }],
  },
  {
    img: PayrollSVG,
    imgHover: PayrollWhite,
    name: 'Payroll Management',
    // link: `/wage/payroll`,
    link: './wage/',
    expanded: false,
    subMenu: [{ name: 'Attendance Report', link: './wage/attendanceReport' }],
  },
  {
    img: ReportSVG,
    imgHover: ReportWhite,
    name: 'Report',
    // link: `/wage/payroll`,
    link: './report/attendance/leavesummary',
    addDivider: true,
  },
]

export const menuReportListEmployee: IMenuItem[] = [
  {
    img: '',
    imgHover: '',
    name: 'Attendance Summary Report',
    link: `./report`,
    subMenu: [{ name: 'Late,Leave,OT Report', link: 'report/attendance/leavesummary' }],
  },
  {
    img: '',
    imgHover: '',
    name: 'Payroll Report',
    link: `./report`,
    subMenu: [{ name: 'Payslip', link: 'report/payroll/payrollslip' }],
  },
]
export interface IMenuItem {
  img: string
  imgHover: string
  name: string
  link: string
  addDivider?: boolean
  expanded?: boolean
  subMenu?: ISubMenuItem[]
}

interface ISubMenuItem {
  name: string
  link: string
}
