import { EBankProviderThailand, EEmployeeType, EPersonalRelation, ESocialSecurity, EStatus } from './generated'

export const EBANK_PROVIDER_THAILAND = {
  [EBankProviderThailand.Krungthep]: 'ธนาคารกรุงเทพ',
  [EBankProviderThailand.Krungthai]: 'ธนาคารกรุงไทย',
  [EBankProviderThailand.Krungsri]: 'ธนาคารกรุงศรีอยุธยา',
  [EBankProviderThailand.Kasikorn]: 'ธนาคารกสิกรไทย',
  [EBankProviderThailand.Thanachat]: 'ธนาคารทหารไทยธนชาต',
  [EBankProviderThailand.ThaiPanich]: 'ธนาคารไทยพาณิชย์',
  [EBankProviderThailand.Cimb]: 'ธนาคารซีไอเอ็มบี ไทย',
  [EBankProviderThailand.StandardChadter]: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
  [EBankProviderThailand.Uob]: 'ธนาคารยูโอบี',
  [EBankProviderThailand.Tisco]: 'ธนาคารทิสโก้',
  [EBankProviderThailand.KietNakin]: 'ธนาคารเกียรตินาคินภัทร',
  [EBankProviderThailand.LandAndHouse]: 'ธนาคารแลนด์ แอนด์ เฮ้าส์',
  [EBankProviderThailand.Icbc]: 'ธนาคารไอซีบีซี (ไทย)',
  [EBankProviderThailand.ThaiCredit]: 'ธนาคารไทยเครดิต เพื่อรายย่อย',
  [EBankProviderThailand.MegePanich]: 'ธนาคารเมกะ สากลพาณิชย์',
  [EBankProviderThailand.Chinese]: 'ธนาคารแห่งประเทศจีน (ไทย)',
  [EBankProviderThailand.Anz]: 'ธนาคารเอเอ็นแซด (ไทย)',
  [EBankProviderThailand.SumitomoMitsuiTrust]: 'ธนาคารซูมิโตโม มิตซุย ทรัสต์ (ไทย)',
}

export const MONTH_THAI = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]
export const MONTH_ENGLISH = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const RELATIONSHIP_THAI: any = {
  [EPersonalRelation.Divorce]: 'หย่าร้าง',
  [EPersonalRelation.Married]: 'สมรส',
  [EPersonalRelation.Single]: 'โสด',
}

export const EMPLOYEETYPE_THAI: any = {
  [EEmployeeType.FulltimeDay]: 'รายวัน',
  [EEmployeeType.FulltimeMonth]: 'รายเดือน',
}

export const SOCIAL_SECURITY_THAI: any = {
  [ESocialSecurity.PersonalIdcard]: 'บัตรประจำตัวประชาชน',
  [ESocialSecurity.License]: 'ใบอนุญาต',
  [ESocialSecurity.ForeignIdcard]: 'พาสปอร์ต',
}
