import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import {
  InputDateComponentCustome,
  InputNumberComponentCustom,
  InputSelectComponentCustom,
  InputTextComponentCustom,
} from '../../component/InputsFormik'
import { EFundDeductContribution, EWelfareQuotaUnit } from 'src/utils/generated'
import styles from './index.module.scss'

interface ITable {
  Header: string[]
  values: any
}
export const TableFunde: React.FC<ITable> = (props: ITable): JSX.Element => {
  return (
    <table className={styles.tableRounded}>
      <tr>
        {props.Header.map((item: any, index: number) => (
          <th key={index}>{item}</th>
        ))}
      </tr>
      {props.values.map((ele: any, index: number) => (
        <tr key={index}>
          <td>
            <InputTextComponentCustom _name={`employeeFund[${index}].name`} _value={ele.name} />
          </td>
          <td>
            <InputNumberComponentCustom _name={`employeeFund[${index}].fundNumber`} _value={ele.fundNumber} />
          </td>
          <td>
            <InputDateComponentCustome _name={`employeeFund[${index}].contractDate`} _value={ele.contractDate} />
          </td>
          <td>
            <InputSelectComponentCustom
              _label="เลือกรายการ"
              _name={`employeeFund[${index}].deductMethod`}
              _value={ele.deductMethod}
              _menuList={EFundDeduct}
            />
          </td>
          <td>
            <InputNumberComponentCustom _name={`employeeFund[${index}].fundRate`} _value={ele.fundRate} />
          </td>
          <td>
            <InputSelectComponentCustom
              _label="เลือกรายการ"
              _name={`employeeFund[${index}].contributionMethod`}
              _value={ele.contributionMethod}
              _menuList={EFundDeduct}
            />
          </td>
          <td>
            <InputTextComponentCustom
              _name={`employeeFund[${index}].contributionCompany`}
              _value={ele.contributionCompany}
            />
          </td>
          <td>
            <InputTextComponentCustom
              _name={`employeeFund[${index}].contributionTotal`}
              _value={ele.contributionTotal}
            />
          </td>
          <td>
            <InputTextComponentCustom _name={`employeeFund[${index}].beneficiary`} _value={ele.beneficiary} />
          </td>
          <td>
            <InputTextComponentCustom _name={`employeeFund[${index}].remark`} _value={ele.remark} />
          </td>
        </tr>
      ))}
    </table>
  )
}

export const TableTaxDeduct: React.FC<ITable> = (props: ITable): JSX.Element => {
  return (
    <table className={styles.tableRounded}>
      <tr>
        {props.Header.map((item: any, index: number) => (
          <th key={index}>{item}</th>
        ))}
      </tr>
      {props.values.map((ele: any, index: number) => (
        <tr key={index}>
          <td>
            <InputTextComponentCustom _name={`taxDeductOne[${index}].year`} _value={ele.year} />
          </td>
          <td>
            <InputTextComponentCustom _name={`taxDeductOne[${index}].month`} _value={ele.month} />
          </td>
          <td>
            <InputNumberComponentCustom _name={`taxDeductOne[${index}].total`} _value={ele.total} />
          </td>
        </tr>
      ))}
    </table>
  )
}

export const TableTaxDeductII: React.FC<ITable> = (props: ITable): JSX.Element => {
  return (
    <table className={styles.tableRounded}>
      <tr>
        {props.Header.map((item: any, index: number) => (
          <th key={index}>{item}</th>
        ))}
      </tr>
      {props.values.map((ele: any, index: number) => (
        <tr key={index}>
          <td>
            <InputTextComponentCustom _name={`taxDeductTwo[${index}].name`} _value={ele.name} />
          </td>
          <td>
            <InputNumberComponentCustom _name={`taxDeductTwo[${index}].rateDeduct`} _value={ele.rateDeduct} />
          </td>
          <td>
            <InputNumberComponentCustom _name={`taxDeductTwo[${index}].totalDeduct`} _value={ele.totalDeduct} />
          </td>
        </tr>
      ))}
    </table>
  )
}

export const TableIncomeExpense: React.FC<ITable> = (props: ITable): JSX.Element => {
  return (
    <table className={styles.tableRounded}>
      <tr>
        {props.Header.map((item: any, index: number) => (
          <th key={index}>{item}</th>
        ))}
      </tr>
      {props.values.map((ele: any, index: number) => (
        <tr key={index}>
          <td>{EINCOME_EXPENSE[ele.incomeExpenseType]}</td>
          <td>{ele.name}</td>
          <td>
            <InputNumberComponentCustom _name={`incomeExpense[${index}].total`} _value={ele.total} />
          </td>
          <td>
            <InputSelectComponentCustom
              _name={`incomeExpense[${index}].unit`}
              _menuList={Eunit}
              _label={'เลือกรายการ'}
              _value={ele.unit}
            />
          </td>
        </tr>
      ))}
    </table>
  )
}
export const TableLeaveQuota: React.FC<ITable> = (props: ITable): JSX.Element => {
  return (
    <table className={styles.tableRounded}>
      {/* {console.log(props.values, 'quota')} */}
      <tr>
        {props.Header.map((item: any, index: number) => (
          <th key={index}>{item}</th>
        ))}
      </tr>
      {props.values.map((ele: any, index: number) => (
        <tr key={index}>
          <td>
            {ele.leaveConfig.name}
            <span className={styles.textsub}> ({ele.prev < 0 ? 'ลาแบบหักเงิน' : 'ลาแบบไม่หักเงิน'})</span>
          </td>
          <td>{ele.prev < 0 ? 'ไม่จำกัด' : ele.begin}</td>
          <td>{ele.used}</td>
          <td>{ele.approved}</td>
          <td>{ele.prev < 0 ? 'ไม่จำกัด' : ele.begin - ele.used}</td>
        </tr>
      ))}
    </table>
  )
}

const Eunit = [
  { label: 'บาท/วัน', value: EWelfareQuotaUnit.BahtPerDay },
  { label: 'บาท/เดือน', value: EWelfareQuotaUnit.BahtPerMonth },
  { label: 'บาท/ปี', value: EWelfareQuotaUnit.BahtPerYear },
]
const EFundDeduct = [
  { label: 'คิดเป็น %', value: EFundDeductContribution.Percentage },
  { label: 'คิดเป็นบาท', value: EFundDeductContribution.Baht },
]

const EINCOME_EXPENSE: any = {
  INCOME: 'รายได้',
  EXPENSE: 'รายหัก',
}
