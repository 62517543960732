import InputAdornment from '@mui/material/InputAdornment'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ButtonComponent from '../../component/Button'
import delIcon from '../../image/del.svg'
import detail from '../../image/detail.svg'
import editIcon from '../../image/edit.svg'
import search from '../../image/search.svg'
import allAction from '../../state/actions'
import styles from './index.module.scss'
import { TableTeam } from './table'
//Api
import { queryListTeamEmployee, queryListTeamEmployeeTable } from '../../adapter/xhr'

const Team: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [listTeam, setListteam] = useState<any>([])
  const [listTeamTable, setListteamTable] = useState<any>([])
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [searchbar, setSearchbar] = useState(false)
  const [teamId, setTeamId] = useState('')
  // const [teamName, setTeamName] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    queryListTeamEmployeeTable().then((res: any) => {
      // console.log('list team table', res.data.data.listTeam.items)
      if (res.data.data.listTeam.items) {
        setListteamTable(res.data.data.listTeam.items)
      }
    })

    queryListTeamEmployee().then((res: any) => {
      console.log('list team', res)
      // if (res.data.data.listTeam.items) {
      //   setListteam(res.data.data.listTeam.items)
      // }
    })
  }, [])
  // console.log('Listteam', listTeam)
  // console.log('teamId', teamId)
  // console.log('listTeamTable', listTeamTable)
  return (
    <div className={styles.container}>
      <div className={styles.navtop}>
        <h1 className={styles.title}>ทีมงาน</h1>
        <div className={styles.head}>
          <div className={styles.iconBar}>
            {!searchbar && (
              <div className={styles.ellipse}>
                <img src={search} alt="" className={styles.search} onClick={() => setSearchbar(true)} />
              </div>
            )}

            {searchbar && (
              <div className={styles.searchbar} onClick={() => setSearchbar(false)}>
                <TextField
                  label="Search Employee"
                  id="outlined-start-adornment"
                  sx={{ m: 1, width: '25ch' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={search} alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.cardView}>
        <div className={styles.head}>
          <div className={styles.btAdd}>
            {' '}
            <ButtonComponent
              _isIcon="add"
              _variant="contained"
              _text="เพิ่มทีม"
              _colorText="white"
              _colorBG="blue"
              _type="button"
              _functionOnClick={() => navigate('./add')}
            />
          </div>
        </div>
        <div className={styles.content}>
          {listTeam &&
            listTeam.map((team: any) => (
              <div
                className={styles.card}
                key={team.id}
                onClick={() => {
                  setTeamId(team.id)
                }}
              >
                <div className={styles.headCard}>
                  <p className={styles.text}>{team.teamName} </p>
                  <img src={detail} alt="" className={styles.detail} onClick={handleClick} />
                </div>
                <div className={styles.bottomCard}>
                  <p className={styles.text}>Team members</p>
                  <p className={styles.textTotal}>{team.listMemberID.length}</p>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => navigate(`./edit/${team.id}`)}>
                    <span className={styles.menulist}>
                      <img src={editIcon} alt="" /> Edit
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      dispatch(
                        allAction.modalConfirmAction.setOpenModalConfirm(
                          'ลบข้อมูลแผนก',
                          'คุณต้องการลบข้อมูลแผนก ?',
                          'ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                          'ใช่,ลบข้อมูล',
                          () => {
                            dispatch(allAction.modalAction.setOpenModalAlertStatus('ลบข้อมูลสำเร็จ', '', 'success'))
                            dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                          },
                        ),
                      )
                    }
                  >
                    <span className={styles.menulist}>
                      <img src={delIcon} alt="" /> Delete
                    </span>
                  </MenuItem>
                </Menu>
              </div>
            ))}
        </div>
      </div>
      {teamId ? (
        <>
          {listTeamTable &&
            listTeamTable
              .filter((listTeamTable: any) => listTeamTable.id === teamId)
              .map((listTeamTable: any) => {
                return (
                  <div className={styles.cardTable} key={listTeamTable.id}>
                    <p className={styles.title}>{listTeamTable.teamName} Team</p>
                    <TableTeam teamLeader={listTeamTable.teamLeader} listMember={listTeamTable.listMember} />
                  </div>
                )
              })}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Team
