// import { gql, useQuery } from '@apollo/client'
// import {
//   Employee,
//   EmployeePayroll,
//   PayrollConfig,
//   PayrollQuery,
//   PayrollRoot,
//   TimeAttendanceReportResponse,
// } from '../../utils/generated'
// import usePaginationQuery, { IUsePaginationQueryOutput } from 'src/hooks/usePaginationQuery'
import React, { useEffect } from 'react'
import styles from './index.module.scss'
// import { FetchData } from 'src/adapter/xhr/interface'
// import { endpoint, post } from 'src/adapter/xhr'
// import axios from 'axios'

const PageNotFound: React.FC = (): JSX.Element => {
  return (
    <div className={styles.center}>
      <div className={styles.error}>
        <div className={styles.number}>4</div>
        <div className={styles.illustration}>
          <div className={styles.circle}></div>
          <div className={styles.clip}>
            <div className={styles.paper}>
              <div className={styles.face}>
                <div className={styles.eyes}>
                  <div className={`${styles.eye} ${styles['eye-left']}`}></div>
                  <div className={`${styles.eye} ${styles['eye-right']}`}></div>
                </div>
                <div className={`${styles.rosyCheeks} ${styles['rosyCheeks-left']}`}></div>
                <div className={`${styles.rosyCheeks} ${styles['rosyCheeks-right']}`}></div>
                <div className={styles.mouth}></div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.number}>4</div>
      </div>

      <div className={styles.text}>{`We're coming soon. Please wait...`}</div>
      {/* <a className={styles.button} href="/">
              Back Home
            </a> */}
    </div>
  )
}
export default PageNotFound
