import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IInitialStateShift {
  itemsList: []
  itemsListOption: any[]
  refetch: () => void
}
const initialState: IInitialStateShift = {
  itemsList: [],
  itemsListOption: [],
  refetch: () => null,
}

export const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    setShift: (state, action: PayloadAction<any>) => {
      state.itemsList = action.payload.items
      state.itemsListOption = action.payload.items?.map((data: any) => ({
        value: data.shiftID,
        label: data.shiftName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setShift } = shiftSlice.actions

export default shiftSlice.reducer
