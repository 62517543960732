import { Divider } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useGetEmployee } from 'src/adapter/api/employee'
import { ICombineReducers } from 'src/state/reducers'
import { EMPLOYEETYPE_THAI, RELATIONSHIP_THAI, SOCIAL_SECURITY_THAI } from 'src/utils/enums'
import styles from './index.module.scss'

const ProfilePage: React.FC = (): JSX.Element => {
  const { decodedAccessToken } = useSelector((state: ICombineReducers) => state.loginReducer)
  const userId = decodedAccessToken.userID
  const years = new Date().getFullYear()
  const { data } = useGetEmployee({ getEmployeeId: userId, year: `${years}`, listWelfareQuotaYear2: `${years}` })

  return (
    <div className={styles.container}>
      <section className={styles.cardProfile}>
        <section className={styles.imgProfile}>
          <img src={`${data?.profileImage}`} alt="profile" />
          <p className={styles.textHead}>{`${data?.fullName}  ${data?.lastName}`}</p>
          <p>{`${data?.department?.departmentName}`}</p>
        </section>
        <section className={styles.textWrapper}>
          <p className={styles.title}>ข้อมูลพื้นฐาน</p>
          {text('ชื่อ - นามสกุล :', `${data?.fullName}  ${data?.lastName}`)}
          {text('ชื่อเล่น:', data?.nickName)}
          {text('วัน/เดือน/ปี เกิด :', data?.birthDate)}
          {text('เลขบัตรประชาชน / ID พาสปอร์ต :', data?.personalID)}
          {text('เบอร์โทร :', data?.tel)}
          {text('สถานภาพ :', RELATIONSHIP_THAI[`${data?.personalRelation}`])}
          <Divider />
          <p className={styles.title}>ที่อยู่ปัจจุบัน</p>
          {text('บ้านเลขที่ :', `${data?.addressCurrent?.addressNumber}`)}
          {text('แขวง/ตำบล :', `${data?.addressCurrent?.subDistrict}`)}
          {text('เขต/อำเภอ :', `${data?.addressCurrent?.district}`)}
          {text('จังหวัด :', `${data?.addressCurrent?.province}`)}
          {text('รหัสไปรษณีย์ :', `${data?.addressCurrent?.zipCode}`)}
          <Divider />
          <p className={styles.title}>ที่อยู่ตามบัตรประชาชน</p>
          {text('บ้านเลขที่ :', `${data?.addressPersonalID?.addressNumber}`)}
          {text('แขวง/ตำบล :', `${data?.addressPersonalID?.subDistrict}`)}
          {text('เขต/อำเภอ :', `${data?.addressPersonalID?.district}`)}
          {text('จังหวัด :', `${data?.addressPersonalID?.province}`)}
          {text('รหัสไปรษณีย์ :', `${data?.addressPersonalID?.zipCode}`)}
          <Divider />
          <p className={styles.title}>ข้อมูลจ้างงาน</p>
          {text('รหัสพนักงาน :', `${data?.id}`)}
          {text('วันเริ่มงาน :', `${data?.hireDate}`)}
          {text('วันสิ้นสุดงาน :', `${data?.retireDate}`)}
          {text('ประเภทพนักงาน :', EMPLOYEETYPE_THAI[`${data?.employeeType}`])}
          {text('แผนก :', `${data?.position?.positionName}`)}
          {text('ตำแหน่ง :', `${data?.department?.departmentName}`)}
          {text('สถานะพนักงาน :', `${data?.status}`)}
          <Divider />
          <p className={styles.title}>ข้อมูลค่าตอบแทน</p>
          {text('เงินเดือน :', `${data?.salary?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`)}
          <Divider />
          <p className={styles.title}>ประกันสังคม</p>
          {text('ประเภทบัตรประจำตัว :', SOCIAL_SECURITY_THAI[`${data?.socialSecurity}`])}
          {text('รหัสบัตรประจำตัว :', `${data?.personalID}`)}
          {text('โรงพยาบาล :', `${data?.hospitalService}`)}
          {text('การคำนวณประกันสังคม :', `${data?.socialSecurityCalculate}`)}
          <Divider />
          <p className={styles.title}>ข้อมูลการจ่ายเงิน</p>
          {text('ช่องทางการชำระเงิน :', `${data?.bankPayment}`)}
          {text('ธนาคารต้นทาง :', `${data?.bankPayment}`)}
          {text('ธนาคารปลายทางพนักงาน :', `${data?.bankAccountName}`)}
          {text('เลขที่บัญชี :', `${data?.bankAccountId}`)}
          <Divider />
          <p className={styles.title}>ข้อมูลผู้ใช้</p>
          {text('Username :', `${data?.username}`)}
          {text('Password :', `${data?.password}`)}
        </section>
      </section>
    </div>
  )
}

const text = (textHead: string, data: any) => {
  return (
    <div className={styles.textGroup}>
      <p className={styles.textHead}>{textHead}</p>
      {data === 'null' ? <p className={styles.text}>-</p> : <p className={styles.text}>{data}</p>}
    </div>
  )
}

export default ProfilePage
