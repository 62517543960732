import { Box, Grid, MenuItem, Paper, Select, Stack, styled, useTheme } from '@mui/material'
import {
  FastField,
  FastFieldProps,
  Form,
  FormikProvider,
  getIn,
  setIn,
  useFormik,
  useFormikContext,
  withFormik,
} from 'formik'
import React from 'react'
import {
  EAttendanceRangeType,
  ECountType,
  EDeductType,
  ENoneFingerPrint,
  EPaidType,
  ERoundType,
  ETimeBreak,
  PutTimeAttendanceConfigInput,
  TimeAttendanceConfig,
} from '../../utils/generated'
import {
  ButtonComponent,
  CustomizedSwitches,
  FormRadioGroup,
  InputNumberUnitComponent,
  InputRadioComponent,
  InputTimeComponent,
  PopperContainer,
} from '../../component'
import { deepClone, styleInline } from '../../utils/common'
import QuestionIcon from '../../image/question.svg'
import { getTimeAttendanceConfig, putTimeAttendanceConfig } from '../../adapter/api'

import Swal from 'sweetalert'

type IValue = {
  selectedMenu: string
  timeAttendanceConfig: TimeAttendanceConfig
}

const AttendancePolicyPage = () => {
  const theme = useTheme()

  const initialValues: IValue = {
    selectedMenu: 'attendanceConfig',
    timeAttendanceConfig: {
      dayShift: '03:00',
      nightShift: '12:00',
      countType: ECountType.Minute,
      noneFingerPrint: ENoneFingerPrint.Fullday,
      roundType: ERoundType.None,
      timeBreak: ETimeBreak.M60,

      isWorkInEarly: true,
      isWorkInLate: true,
      isBreakOvertime: true,
      isBreakEarly: true,
      isWorkOutEarly: true,
      isWorkOutLate: true,

      workInEarlyPaidType: EPaidType.Baht,

      maxWorkInLateType: EAttendanceRangeType.OnTime,
      workInLateDeductType: EDeductType.Baht,

      breakOvertimeType: EAttendanceRangeType.OnTime,
      breakOvertimeDedcutType: EDeductType.Baht,

      breakEarlyPaidType: EPaidType.Baht,

      workOutEarlyDeductType: EDeductType.Baht,
      workOutEarlyType: EAttendanceRangeType.OnTime,

      isWorkOutLatePaidType: EPaidType.Baht,
    },
  }

  const onSubmit = async (values: IValue) => {
    const input = deepClone(values.timeAttendanceConfig) as PutTimeAttendanceConfigInput

    try {
      await putTimeAttendanceConfig(input)
      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  })

  const fetchTimeAttendanceConfig = async () => {
    const res = await getTimeAttendanceConfig()
    Object.keys(initialValues.timeAttendanceConfig).forEach((key) => {
      if (res[key as keyof TimeAttendanceConfig] === null || res[key as keyof TimeAttendanceConfig] === undefined)
        res[key as keyof TimeAttendanceConfig] = initialValues.timeAttendanceConfig[key as keyof TimeAttendanceConfig]
    })
    formik.setFieldValue('timeAttendanceConfig', res)
  }

  React.useEffect(() => {
    formik.setValues(initialValues)
    fetchTimeAttendanceConfig()
  }, [])

  return (
    <Stack direction="row" columnGap="2rem" sx={{ backgroundColor: theme.colors.grayBg }}>
      <FormikProvider value={formik}>
        <TabMenuList />
        <AttendanceForm />
      </FormikProvider>
    </Stack>
  )
}
const menuList = [
  {
    value: 'attendanceConfig',
    name: '',
    label: 'การตั้งค่าการลงเวลาทำงาน',
  },
  {
    value: 'inEarly',
    name: 'timeAttendanceConfig.isWorkInEarly',
    label: 'เข้าก่อนเวลา',
    showSwitch: true,
  },
  {
    value: 'inLate',
    name: 'timeAttendanceConfig.isWorkInLate',
    label: 'เข้าสาย',
    showSwitch: true,
  },
  {
    value: 'breakOvertime',
    name: 'timeAttendanceConfig.isBreakOvertime',
    label: 'พักเกินเวลา',
    showSwitch: true,
  },
  {
    value: 'breakEarly',
    name: 'timeAttendanceConfig.isBreakEarly',
    label: 'พักเร็ว',
    showSwitch: true,
  },
  {
    value: 'outEarly',
    name: 'timeAttendanceConfig.isWorkOutEarly',
    label: 'ออกงานก่อนเวลา',
    showSwitch: true,
  },
  {
    value: 'outLate',
    name: 'timeAttendanceConfig.isWorkOutLate',
    label: 'ออกงานหลังเวลา',
    showSwitch: true,
  },
]

const TabMenuList = () => {
  const formik = useFormikContext<IValue>()
  const handleToggle = async (name: string, checked: boolean) => {
    formik.setFieldValue(name, checked)
    const values = setIn(formik.values, name, checked)
    const input = deepClone(values.timeAttendanceConfig) as PutTimeAttendanceConfigInput

    try {
      await putTimeAttendanceConfig(input)
      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
    }
  }

  return (
    <FastField name="selectedMenu">
      {({ field, meta, form }: FastFieldProps) => (
        <Stack
          sx={{
            maxWidth: '30rem',
            minWidth: '30rem',
            backgroundColor: 'white',
            height: 'calc(100vh - 60px)',
            overflowY: 'auto',
          }}
          rowGap="1rem"
          px="2rem"
          py="2rem"
        >
          {menuList.map((menu, index) => (
            <TabMenuItem
              key={index}
              isActive={meta.value === menu.value}
              item={menu}
              onSelect={(value) => form.setFieldValue(field.name, value)}
              onToggle={handleToggle}
            />
          ))}
        </Stack>
      )}
    </FastField>
  )
}

interface TabMenuItemProps {
  item: typeof menuList[number]
  isActive: boolean
  onSelect: (value: string) => void
  onToggle: (name: string, status: boolean) => void
}

const TabMenuItem: React.FC<TabMenuItemProps> = ({ item, isActive, onSelect, onToggle }) => {
  const theme = useTheme()
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={onSelect.bind(null, item.value!)}
      sx={{
        ...styleInline(
          isActive
            ? { backgroundColor: theme.colors.primary, color: 'white' }
            : { backgroundColor: 'white', color: 'black' },
        ),
        fontSize: '16px',
        fontWeight: 'bold',
        px: '1.5rem',
        py: '1rem',
        borderRadius: '13px',
        cursor: 'pointer',
        border: `3px solid ${theme.colors.mainColorLight}`,
        userSelect: 'none',
      }}
    >
      <p>{item.label}</p>

      {item.showSwitch && (
        <CustomizedSwitches.Formik
          _name={item.name}
          _onClick={(e: any, checked: boolean) => onToggle(item.name, checked)}
        />
      )}
    </Stack>
  )
}

const AttendanceForm = () => {
  const formik = useFormikContext<IValue>()
  const getComponent = React.useMemo(() => {
    switch (formik.values.selectedMenu) {
      case 'attendanceConfig':
        return (
          <Stack rowGap="2rem">
            <FormLabelSide
              label={
                <p>
                  กะปกติ คือ เวลาเริ่มน้อยกว่าเวลาออก และอยู่ภายในวันเดียวกัน เช่น 08:00 - 17:00 น.
                  จะให้ระบบตัดขึ้นวันใหม่ตอนกี่โมง <br /> Ex: ตั้งไว้ 03:00 น. ถ้าลงเวลา 23:30 ถือว่าเป็นวันเดิม
                  ถ้าลงเวลา 03:10 ถือเป็นวันใหม่
                </p>
              }
            >
              <InputTimeComponent _name="timeAttendanceConfig.dayShift" _label="1. ตัดเวลาขึ้นวันใหม่ กะปกติ" />
            </FormLabelSide>

            <FormLabelSide
              label={
                <p>
                  กะข้ามคืน คือ เวลาเริ่มมากกว่าเวลาออก และเวลาทำงานอยู่ข้ามวัน เช่น 20:00 - 05:00 น.
                  จะให้ระบบตัดขึ้นวันใหม่ตอนกี่โมง Ex: ตั้งไว้ 12:00 น. ถ้าลงเวลา 11:00 ถือว่าเป็นวันก่อนหน้า ถ้าลงเวลา
                  12:10 ถือเป็นวันใหม่ <br />
                </p>
              }
            >
              <InputTimeComponent _name="timeAttendanceConfig.nightShift" _label="2. ตัดเวลาขึ้นวันใหม่ กะข้ามคืน" />
            </FormLabelSide>

            <FormLabelSide
              label={
                <p>
                  ลงเวลามา 08:30:52 น. พนักงานต้องเริ่มงาน 08.00 น.
                  <br />
                  Ex. คิดเป็นวินาที พนักงานคนนี้จะสายเท่ากับ 30 นาที 52 วินาที คิดเป็นนาที พนักงานคนนี้จะสายเท่ากับ 30
                  นาที
                </p>
              }
            >
              <FormRadioGroup
                name="timeAttendanceConfig.countType"
                label="3. คำนวณเวลาแบบ"
                row
                menuList={[
                  {
                    value: ECountType.Minute,
                    label: 'คิดเป็นนาที',
                  },
                  {
                    value: ECountType.Second,
                    label: 'คิดเป็นวินาที',
                  },
                ]}
              />
            </FormLabelSide>

            <FormLabelSide
              label={
                <p>
                  การตั้งค่าเพื่อปรับโทษพนักงานสำหรับกรณีที่ลงเวลางานไม่ครบ <br />
                  Ex. หากมีการสแกนนิ้วไม่ครบเช่น สแกนเข้างาน 08:00น. แต่ไม่มีการสแกนออก
                  ระบบจะทำการตัดขาดงานได้เต็มวันหรือครึ่งวันตามที่ตั้งค่าไว้
                </p>
              }
            >
              <FormRadioGroup
                name="timeAttendanceConfig.noneFingerPrint"
                label="4. ถ้าสแกนนิ้วไม่ครบ จัดว่าเป็น"
                row
                menuList={[
                  {
                    value: ENoneFingerPrint.Fullday,
                    label: 'หักเต็มวัน',
                  },
                  {
                    value: ENoneFingerPrint.Halfday,
                    label: 'หักครึ่งวัน',
                  },
                ]}
              />
            </FormLabelSide>

            <FormLabelSide
              label={
                <p>
                  คำนวณชั่วโมงการทำงาน ไม่ปัดเศษ คือการปัดเศษทิ้งนาที
                  <br />
                  Ex. พนักงานทำงาน 2:34:21 ชั่วโมง โอที คือ 2 ชั่วโมง 34 นาที
                </p>
              }
            >
              <FormRadioGroup
                name="timeAttendanceConfig.roundType"
                label="5. การปัดเศษชั่วโมงการทำงาน"
                row
                menuList={[
                  { value: ERoundType.None, label: 'ไม่ปัดเศษ' },
                  { value: ERoundType.Round, label: 'ปัดเศษ' },
                  { value: ERoundType.Floor, label: 'ปัดเศษทิ้ง' },
                ]}
              />
            </FormLabelSide>

            <FormLabelSide
              label={
                <p>
                  ปัดเศษมี 2 แบบ ปัดเศษเต็มชั่วโมง
                  <br />
                  1. ปัดเศษทิ้ง ( Floor) คือ ทำงานโอที 2:10 ชั่วโมง = 2 ชั่วโมง
                  <br />
                  &emsp;a. ปัดเศษ (Round) คือ ทำงานโอที 2:10 ชั่วโมง = 2 ชั่วโมง
                  <br />
                  &emsp;b. ทำงานโอที 2:50 ชั่วโมง = 3 ชั่วโมง
                </p>
              }
            >
              <FormRadioGroup
                name="timeAttendanceConfig.timeBreak"
                label="6. เวลาการพักติดต่อกันไม่น้อยกว่า"
                row
                menuList={[
                  { value: ETimeBreak.M50, label: '50 นาที' },
                  { value: ETimeBreak.M55, label: '55 นาที' },
                  { value: ETimeBreak.M60, label: '60 นาที' },
                ]}
              />
            </FormLabelSide>

            {/* <FormLabelSide label={<></>}>
              <FormRadioGroup
                name="timeAttendanceConfig.timeBreakFirst"
                label="7. พักติดต่อกันภายในกี่ชั่วโมงแรกของการทำงาน"
                row
                menuList={[
                  { value: ETimeBreakFirst.H4, label: '4 ชั่วโมง' },
                  { value: ETimeBreakFirst.H5, label: '5 ชั่วโมง' },
                  { value: ETimeBreakFirst.H6, label: '6 ชั่วโมง' },
                ]}
              />
            </FormLabelSide> */}
          </Stack>
        )
      case 'inEarly':
        return (
          <Grid container columns={2} spacing="2rem">
            <>
              <Grid item xs={1}>
                <TopicLabel>การเข้างานก่อนเวลา</TopicLabel>
              </Grid>
              <Grid item xs={1}></Grid>
            </>

            <>
              <Grid item xs={1}>
                <Stack rowGap="1rem">
                  <InputNumberUnitComponent
                    _name="timeAttendanceConfig.maxWorkInEarlyMinute"
                    _label="เริ่มนับเข้างานก่อนเวลา"
                    _unit="นาที"
                  />
                  <DiligentSwitch
                    name="timeAttendanceConfig.isWorkInEarlyDiligent"
                    text={
                      <p>
                        ถ้าพนักงาน<BlueText>เข้างานก่อนเวลา</BlueText> จะได้รับเบี้ยขยันในเดือนนั้น
                      </p>
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  &quot;สมมติเวลาเริ่มงานคือ 8:00 น. ถ้าพนักงานเริ่มงาน <BlueText>08:50</BlueText> น. จะคำนวณ
                  &quot;สาย&quot; 50 นาที&quot; แต่ถ้าพนักงานเข้างาน 09:00 ระบบจะคำนวณว่า
                  <RedText>ขาดงาน 1 ชั่วโมง</RedText>
                </DescriptionText>
              </Grid>
            </>

            <>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <TopicLabel>เงื่อนไขวิธีการรับเงิน</TopicLabel>
                  <CustomizedSwitches.Formik _name="timeAttendanceConfig.isWorkInEarlyPaid" />
                </Stack>
              </Grid>
              <Grid item xs={1}></Grid>
            </>

            <>
              <Grid item xs={1}>
                <InputNumberUnitComponent
                  _name="timeAttendanceConfig.workInEarlyPaidRate"
                  _label="ค่าตอบแทนพิเศษ"
                  selectMenuList={[
                    { label: 'บาท', value: EPaidType.Baht },
                    {
                      label: 'เท่าของค่าแรง',
                      value: EPaidType.Multiple,
                    },
                  ]}
                  selectUnit={(menuList) => (
                    <UnstyledSelect name="timeAttendanceConfig.workInEarlyPaidType" menuList={menuList} />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  &quot;สมมติเวลาเริ่มงานคือ 8:00 น. ถ้าพนักงานเริ่มงาน 07:00 น. จะคำนวณ &quot;เข้างานก่อน&quot; 15
                  นาที&quot; ได้รับเงินเพิ่ม 15*0.5 = 7.5 บาท
                </DescriptionText>
              </Grid>
            </>
          </Grid>
        )
      case 'inLate':
        return (
          <Grid container columns={2} spacing="2rem">
            <>
              <Grid item xs={1}>
                <TopicLabel>การเข้างานสาย</TopicLabel>
              </Grid>
              <Grid item xs={1}></Grid>
            </>
            <>
              <Grid item xs={1}>
                <Stack rowGap="1rem">
                  <InputNumberUnitComponent
                    _name="timeAttendanceConfig.maxWorkInLateMinute"
                    _label="นับเข้าสายมากสุดกี่นาที/วัน"
                    _unit="นาที"
                  />
                  <DiligentSwitch
                    text={
                      <p>
                        อนุญาตให้พนักงาน<BlueText>สาย</BlueText>ได้ โดยได้รับเบี้ยขยันในเดือนนั้นๆ
                      </p>
                    }
                    name="timeAttendanceConfig.isWorkInLateDiligent"
                  />
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  &quot;สมมติเวลาเริ่มงานคือ 8:00 น. ถ้าพนักงานเริ่มงาน <BlueText>08:50</BlueText> น. จะคำนวณ
                  &quot;สาย&quot; 50 นาที&quot; แต่ถ้าพนักงานเข้างาน 09:00 ระบบจะคำนวณว่า
                  <RedText>ขาดงาน 1 ชั่วโมง</RedText>
                </DescriptionText>
              </Grid>
            </>

            <>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <TopicLabel>เงื่อนไขวิธีการหักเงิน</TopicLabel>
                  <CustomizedSwitches.Formik _name="timeAttendanceConfig.isWorkInLateDeduct" />
                </Stack>
              </Grid>
              <Grid item xs={1}></Grid>
            </>

            <>
              <Grid item xs={1}>
                <InputNumberUnitComponent
                  _name="timeAttendanceConfig.workInLateDeductRate"
                  _label="หักเงินเข้างานสาย"
                  selectMenuList={[
                    { label: 'บาท', value: EDeductType.Baht },
                    {
                      label: 'เท่าของค่าแรง',
                      value: EDeductType.Multiple,
                    },
                  ]}
                  selectUnit={(menuList) => (
                    <UnstyledSelect name="timeAttendanceConfig.workInLateDeductType" menuList={menuList} />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  <>
                    ตัวอย่างการคิดเป็น &quot;บาท&quot; &quot;สมมติเวลาเริ่มงานคือ 8:00 น. ถ้าพนักงานเริ่มงาน 08:11 น.
                    จะคำนวณ &quot;สาย&quot; 11 นาที&quot; หักเงิน 55 บาท
                  </>
                  {/* <>
                    ตัวอย่างการคิดเป็นเท่าของค่าแรง <br />
                    เงินเดือน 9000 บาท / 30 วันในเดือน = 300 บาทต่อวัน <br />
                    300 บาทต่อวัน / 8 ชั่วโมงต่อวัน = 37.5 บาทต่อชั่วโมง <br />
                    37.5 บาทต่อชั่วโมง x 1 เท่า = หักเงิน 37.5 บาท <br />
                  </> */}
                </DescriptionText>
              </Grid>
            </>

            <>
              <Grid item xs={1}>
                <TopicLabel>การเข้าสาย</TopicLabel>
                <InputRadioComponent
                  _name="timeAttendanceConfig.maxWorkInLateType"
                  _menuList={[
                    { value: EAttendanceRangeType.OnTime, label: 'นับสายตั้งแต่นาทีแรก' },
                    { value: EAttendanceRangeType.NotExceed, label: 'อนุโลมไม่เกินเวลาที่กำหนด' },
                    { value: EAttendanceRangeType.NotExceedQty, label: 'อนุโลมไม่เกินครั้งที่กำหนด' },
                  ]}
                />
              </Grid>
              <Grid item xs={1}></Grid>

              {formik.values.timeAttendanceConfig.maxWorkInLateType === EAttendanceRangeType.NotExceed && (
                <>
                  <Grid item xs={1}>
                    <InputNumberUnitComponent
                      _name="timeAttendanceConfig.workInLateQuotaMinute"
                      _label="โควต้าเข้างานสาย"
                      _unit="นาที/เดือน"
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                </>
              )}
              {formik.values.timeAttendanceConfig.maxWorkInLateType === EAttendanceRangeType.NotExceedQty && (
                <>
                  <Grid item xs={1}>
                    <InputNumberUnitComponent
                      _name="timeAttendanceConfig.workInLateQuotaQuantity"
                      _label="โควต้าเข้างานสาย"
                      _unit="ครั้ง/เดือน"
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                </>
              )}
            </>
          </Grid>
        )
      case 'breakOvertime':
        return (
          <Grid container columns={2} spacing="2rem">
            <>
              <Grid item xs={1}>
                <TopicLabel>การพักเกินเวลา</TopicLabel>
              </Grid>
              <Grid item xs={1}></Grid>
            </>
            <>
              <Grid item xs={1}>
                <Stack rowGap="1rem">
                  <InputNumberUnitComponent
                    _name="timeAttendanceConfig.maxBreakOvertimeMinute"
                    _label="นับพักเกินเวลามากสุดกี่นาที/วัน"
                    _unit="นาที"
                  />
                  <DiligentSwitch
                    text={
                      <p>
                        <p>
                          อนุญาตให้พนักงาน<BlueText>พักเกิน</BlueText>ได้ โดยได้รับเบี้ยขยันในเดือนนั้นๆ
                        </p>
                      </p>
                    }
                    name="timeAttendanceConfig.isBreakOvertimeDiligent"
                  />
                </Stack>
              </Grid>
              <Grid item xs={1}></Grid>
            </>

            <>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <TopicLabel>เงื่อนไขวิธีการหักเงิน</TopicLabel>
                  <CustomizedSwitches.Formik _name="timeAttendanceConfig.isBreakOvertimeDeduct" />
                </Stack>
              </Grid>
              <Grid item xs={1}></Grid>
            </>

            <>
              <Grid item xs={1}>
                <InputNumberUnitComponent
                  _name="timeAttendanceConfig.breakOvertimeDeductRate"
                  _label="หักเงินพักเกินเวลา"
                  selectMenuList={[
                    { label: 'บาท', value: EDeductType.Baht },
                    {
                      label: 'เท่าของค่าแรง',
                      value: EDeductType.Multiple,
                    },
                  ]}
                  selectUnit={(menuList) => (
                    <UnstyledSelect name="timeAttendanceConfig.breakOvertimeDedcutType" menuList={menuList} />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  <>
                    &quot;สมมติเวลาพักคือ 11:00-12.00 น. ถ้าพนักงานเริ่มพัก 11.59 -13:01 น. จะคำนวณ &quot;การพักเกิน
                    &quot; 2 นาที &quot; หักเงิน 5 บาท
                  </>
                  {/* <>
                ตัวอย่างการคิดเป็นเท่าของค่าแรง <br />
                เงินเดือน 9000 บาท / 30 วันในเดือน = 300 บาทต่อวัน <br />
                300 บาทต่อวัน / 8 ชั่วโมงต่อวัน = 37.5 บาทต่อชั่วโมง <br />
                37.5 บาทต่อชั่วโมง x 1 เท่า = หักเงิน 37.5 บาท <br />
              </> */}
                </DescriptionText>
              </Grid>
            </>

            <>
              <Grid item xs={1}>
                <TopicLabel>พักเกินเวลา</TopicLabel>
                <InputRadioComponent
                  _name="timeAttendanceConfig.breakOvertimeType"
                  _menuList={[
                    { value: EAttendanceRangeType.OnTime, label: 'นับการพักเกินตั้งแต่นาทีแรก' },
                    { value: EAttendanceRangeType.NotExceed, label: 'อนุโลมไม่เกินเวลาที่กำหนด' },
                  ]}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </>
          </Grid>
        )
      case 'breakEarly':
        return (
          <Grid container columns={2} spacing="2rem">
            <>
              <Grid item xs={1}>
                <TopicLabel>พักเร็วหลังเวลาพัก</TopicLabel>
              </Grid>
              <Grid item xs={1}></Grid>
            </>
            <>
              <Grid item xs={1}>
                <Stack rowGap="1rem">
                  <InputNumberUnitComponent
                    _name="timeAttendanceConfig.maxBreakEarlyMinute"
                    _label="พักเร็วหลังเวลาพัก"
                    _unit="นาที"
                  />
                  <DiligentSwitch
                    text={
                      <p>
                        ถ้าพนักงาน<BlueText>เข้างานก่อนเวลา</BlueText> จะได้รับเบี้ยขยันในเดือนนั้น
                      </p>
                    }
                    name="timeAttendanceConfig.isBreakEarlyDiligent"
                  />
                </Stack>
              </Grid>
              <Grid item xs={1}></Grid>
            </>

            <>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <TopicLabel>เงื่อนไขวิธีการรับเงิน</TopicLabel>
                  <CustomizedSwitches.Formik _name="timeAttendanceConfig.isBreakEarlyPaid" />
                </Stack>
              </Grid>
              <Grid item xs={1}></Grid>
            </>

            <>
              <Grid item xs={1}>
                <InputNumberUnitComponent
                  _name="timeAttendanceConfig.breakEarlyPaidRate"
                  _label="ค่าตอบแทนพิเศษ"
                  selectMenuList={[
                    { label: 'บาท', value: EPaidType.Baht },
                    {
                      label: 'เท่าของค่าแรง',
                      value: EPaidType.Multiple,
                    },
                  ]}
                  selectUnit={(menuList) => (
                    <UnstyledSelect name="timeAttendanceConfig.breakEarlyPaidType" menuList={menuList} />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  <>
                    &quot;สมมติหมดเวลาพัก คือ 13:00 น. ถ้าพนักงานเข้างานก่อน 12:30 น. จะคำนวณ <br /> &quot;พักเร็ว&quot;
                    15นาที&quot; ได้รับเงินเพิ่ม 15*0.5 = 7.5 บาท
                  </>
                  {/* <>
                ตัวอย่างการคิดเป็นเท่าของค่าแรง <br />
                เงินเดือน 9000 บาท / 30 วันในเดือน = 300 บาทต่อวัน <br />
                300 บาทต่อวัน / 8 ชั่วโมงต่อวัน = 37.5 บาทต่อชั่วโมง <br />
                37.5 บาทต่อชั่วโมง x 1 เท่า = หักเงิน 37.5 บาท <br />
              </> */}
                </DescriptionText>
              </Grid>
            </>
          </Grid>
        )
      case 'outEarly':
        return (
          <Grid container columns={2} spacing="2rem">
            <>
              <Grid item xs={1}>
                <TopicLabel>การออกงานก่อนเวลา</TopicLabel>
              </Grid>
              <Grid item xs={1}></Grid>
            </>
            <>
              <Grid item xs={1}>
                <Stack rowGap="1rem">
                  <InputNumberUnitComponent
                    _name="timeAttendanceConfig.maxWorkOutEarlyMinute"
                    _label="นับกลับก่อนเวลามากสุดกี่นาที/วัน"
                    _unit="นาที"
                  />
                  <DiligentSwitch
                    text={
                      <p>
                        อนุญาตให้พนักงาน<BlueText>ออกงานก่อนเวลา</BlueText>ได้ โดยได้รับเบี้ยขยันในเดือนนั้นๆ
                      </p>
                    }
                    name="timeAttendanceConfig.isWorkOutEarlyDiligent"
                  />
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  &quot;สมมติเวลาเลิกงานคือ 17:00 น. ถ้าพนักงานกลับก่อน 16:50 น. จะคำนวณ &quot;กลับก่อนเวลา&quot; 10
                  นาที&quot; แต่ถ้าพนักงานกลับก่อน 16:00 ระบบจะคำนวณว่าขาดงาน 1 ชั่วโมง
                </DescriptionText>
              </Grid>
            </>

            <>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <TopicLabel>เงื่อนไขวิธีการหักเงิน</TopicLabel>
                  <CustomizedSwitches.Formik _name="timeAttendanceConfig.isWorkOutEarlyDeduct" />
                </Stack>
              </Grid>
              <Grid item xs={1}></Grid>
            </>

            <>
              <Grid item xs={1}>
                <InputNumberUnitComponent
                  _name="timeAttendanceConfig.workOutEarlyDeductRate"
                  _label="หักเงินออกงานก่อนเวลา"
                  selectMenuList={[
                    { label: 'บาท', value: EDeductType.Baht },
                    {
                      label: 'เท่าของค่าแรง',
                      value: EDeductType.Multiple,
                    },
                  ]}
                  selectUnit={(menuList) => (
                    <UnstyledSelect name="timeAttendanceConfig.workOutEarlyDeductType" menuList={menuList} />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  <>
                    &quot;สมมติเวลาเลิกงานคือ 17:00 น. ถ้าพนักงานออกตอน 16:59 น. ระบบจะคำนวณ &quot;การออกก่อน&quot; 1
                    นาที&quot; ถือว่าหักเงิน 5 บาท
                  </>
                  {/* <>
                ตัวอย่างการคิดเป็นเท่าของค่าแรง <br />
                เงินเดือน 9000 บาท / 30 วันในเดือน = 300 บาทต่อวัน <br />
                300 บาทต่อวัน / 8 ชั่วโมงต่อวัน = 37.5 บาทต่อชั่วโมง <br />
                37.5 บาทต่อชั่วโมง x 1 เท่า = หักเงิน 37.5 บาท <br />
              </> */}
                </DescriptionText>
              </Grid>
            </>

            <>
              <Grid item xs={1}>
                <TopicLabel>พักเกินเวลา</TopicLabel>
                <InputRadioComponent
                  _name="timeAttendanceConfig.workOutEarlyType"
                  _menuList={[
                    { value: EAttendanceRangeType.OnTime, label: 'นับออกงานก่อนเวลาตั้งแต่นาทีแรก' },
                    { value: EAttendanceRangeType.NotExceed, label: 'อนุโลมไม่เกินเวลาที่กำหนด' },
                  ]}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </>
          </Grid>
        )
      case 'outLate':
        return (
          <Grid container columns={2} spacing="2rem">
            <>
              <Grid item xs={1}>
                <TopicLabel>ออกงานหลังเวลา</TopicLabel>
              </Grid>
              <Grid item xs={1}></Grid>
            </>
            <>
              <Grid item xs={1}>
                <Stack rowGap="1rem">
                  <InputNumberUnitComponent
                    _name="timeAttendanceConfig.maxWorkOutLateMinute"
                    _label="ออกงานหลังเวลา"
                    _unit="นาที"
                  />
                  <DiligentSwitch
                    text={
                      <p>
                        ถ้าพนักงาน<BlueText>ออกงานหลังเวลา</BlueText> จะได้รับเบี้ยขยันในเดือนนั้น
                      </p>
                    }
                    name="timeAttendanceConfig.isWorkOutLateDiligent"
                  />
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  &quot;สมมติเวลาเลิกงานคือ 17:00 น. ถ้าพนักงานกลับก่อน 16:50 น. จะคำนวณ &quot;กลับก่อนเวลา&quot; 10
                  นาที&quot; แต่ถ้าพนักงานกลับก่อน 16:00 ระบบจะคำนวณว่าขาดงาน 1 ชั่วโมง
                </DescriptionText>
              </Grid>
            </>

            <>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <TopicLabel>เงื่อนไขวิธีการรับเงิน</TopicLabel>
                  <CustomizedSwitches.Formik _name="timeAttendanceConfig.isWorkOutLatePaid" />
                </Stack>
              </Grid>
              <Grid item xs={1}></Grid>
            </>

            <>
              <Grid item xs={1}>
                <InputNumberUnitComponent
                  _name="timeAttendanceConfig.isWorkOutLatePaidRate"
                  _label="ค่าตอบแทนพิเศษ"
                  selectMenuList={[
                    { label: 'บาท', value: EPaidType.Baht },
                    {
                      label: 'เท่าของค่าแรง',
                      value: EPaidType.Multiple,
                    },
                  ]}
                  selectUnit={(menuList) => (
                    <UnstyledSelect name="timeAttendanceConfig.isWorkOutLatePaidType" menuList={menuList} />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <DescriptionText>
                  <>
                    &quot;สมมติเวลาเลิกงานคือ 17:00 น. ถ้าพนักงานเลิกงาน 17:30 น. จะคำนวณ &quot;ออกงานหลังเวลา&quot;
                    15นาที&quot; ได้รับเงินเพิ่ม 15*0.5 = 7.5 บาท
                  </>
                  {/* <>
                ตัวอย่างการคิดเป็นเท่าของค่าแรง <br />
                เงินเดือน 9000 บาท / 30 วันในเดือน = 300 บาทต่อวัน <br />
                300 บาทต่อวัน / 8 ชั่วโมงต่อวัน = 37.5 บาทต่อชั่วโมง <br />
                37.5 บาทต่อชั่วโมง x 1 เท่า = หักเงิน 37.5 บาท <br />
              </> */}
                </DescriptionText>
              </Grid>
            </>
          </Grid>
        )
      default:
        return <></>
    }
  }, [formik.values.selectedMenu, formik.values.timeAttendanceConfig.maxWorkInLateType])

  return (
    <Form>
      <Stack sx={{ backgroundColor: 'white', flexGrow: 1, p: '2rem' }} justifyContent="space-between">
        {getComponent}
        <Stack direction="row" columnGap="1rem" mt="3rem">
          <ButtonComponent
            _text="ยกเลิก"
            _colorBG="white"
            _colorText="red"
            _type="button"
            _variant="contained"
            _sx={{ width: 'fit-content' }}
          />
          <ButtonComponent
            _text="บันทึก"
            _colorBG="blue"
            _colorText="white"
            _type="submit"
            _variant="contained"
            _sx={{ width: 'fit-content' }}
          />
        </Stack>
      </Stack>
    </Form>
  )
}

const FormLabelSide: React.FC<{ children: React.ReactNode; label: React.ReactNode }> = ({ children, label }) => {
  const theme = useTheme()
  return (
    <Grid container columns={2} columnSpacing="2rem" alignItems="center">
      <Grid item xs={1}>
        {children}
      </Grid>
      <Grid item xs={1}>
        <Box sx={{ color: theme.colors.gray7, fontSize: '14px' }}>{label}</Box>
      </Grid>
    </Grid>
  )
}

interface DiligentSwitchProps {
  text: React.ReactNode
  name: string
}
const DiligentSwitch: React.FC<DiligentSwitchProps> = ({ text, name }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" columnGap="1rem" alignItems="center">
        <Box sx={{ fontWeight: 'bold' }}>เบี้ยขยัน</Box>
        <PopperContainer button={<QuetionIcon src={QuestionIcon} />} placement="right">
          <Paper sx={{ width: '12rem', ml: '0.5rem', p: '0.5rem', fontSize: '14px' }}>{text}</Paper>
        </PopperContainer>
      </Stack>
      <CustomizedSwitches.Formik _name={name} />
    </Stack>
  )
}

const QuetionIcon = styled('img')`
  width: 1.25rem;
  margin-bottom: -4px;
`

const BlueText = styled('span')(({ theme }) => ({
  color: theme.colors.primary,
}))

const DescriptionText = styled('p')(({ theme }) => ({ fontSize: '14px', color: theme.colors.gray3 }))

const RedText = styled('span')(({ theme }) => ({ color: theme.colors.colorRed }))

const TopicLabel = styled('p')(({ theme }) => ({ fontWeight: 'bold', fontSize: '16px' }))

const UnstyledSelect: React.FC<{ name: string; menuList: { label: any; value: any }[] }> = ({ name, menuList }) => {
  return (
    <FastField name={name}>
      {({ field, meta }: FastFieldProps) => (
        <Select
          name={name}
          // sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}

          variant="standard"
          value={meta.value || meta.initialValue || ''}
          onChange={field.onChange}
        >
          {menuList.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      )}
    </FastField>
  )
}

export default AttendancePolicyPage
