//lib
import React, { useState } from 'react'
import { Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useFormikContext } from 'formik'
import _ from 'lodash'

//Include Project
import { UpdateEmployeeProfile } from 'src/adapter/xhr'
import { InputTextComponentCustom, ProfileUpload } from 'src/component'
import styles from './index.module.scss'

//Image
import questionIcon from '../../image/question.svg'
import deleteIcon from '../../image/deleteRedIcon.svg'

const EmployeeUserTab: React.FC = (): JSX.Element => {
  const { values, errors } = useFormikContext<any>()
  const [image, setImages] = useState<any>('')
  const navigate = useNavigate()

  const onDelete = () => {
    return Swal.fire({
      title: 'ลบบัญชีพนักงาน',
      html: `${values.fullName} ${values.lastName} <br /> ${values.id}`,
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ลบบัญชี',
      confirmButtonColor: '#d32f2f',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        UpdateEmployeeProfile({ input: { id: values.id, status: 'INACTIVE' } }).then((res: any) => {
          console.log('Del Employee', res)
        })
        navigate(-1)
      }
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>6. การตั้งค่าผู้ใช้งาน</div>
      <div className={styles.card}>
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <ProfileUpload valueImages={image} Id={'1'} setImages={setImages} />

            <div className={styles.form}>
              <InputTextComponentCustom
                _name="formInput.username"
                _label="Username"
                _value={values.formInput.username}
                _helperText={`${_.get(errors, 'formInput.username')}`}
              />
            </div>
            <div className={styles.form}>
              <InputTextComponentCustom
                _name="formInput.password"
                _label="Password"
                _value={values.formInput.password}
                _helperText={`${_.get(errors, 'formInput.password')}`}
              />
              <p className={styles.txetLink} onClick={() => navigate('../forgotpassword')}>
                เปลี่ยนรหัสผ่าน
              </p>
              {values.formInput.id ? (
                <div className={styles.bottom}>
                  <p className={styles.textLink}>ลืมรหัสผ่านใช่ไหม ?</p>
                  <div
                    className={styles.GroupText}
                    onClick={() => {
                      onDelete()
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                    <p className={styles.wranText}>ลบ Account พนักงาน</p>
                    <Tooltip
                      arrow
                      title={
                        <p>
                          ลบข้อมูลของพนักงานคนนี้ทั้งหมดในฐานข้อมูล จะไม่สามารถค้นหา หรือตั้งค่าพนักงานคนนี้ได้อีก
                          การดำเนินการนี้จะเริ่มต้นเมื่อกดปุ่มยืนยัน และการดำเนินการนี้จะไม่กระทบกับพนักงานคนอื่น
                        </p>
                      }
                    >
                      <img src={questionIcon} alt="" />
                    </Tooltip>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default EmployeeUserTab
