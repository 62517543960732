// import React from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { ICombineReducers } from 'src/state/reducers'

const useCompanyID = (): string => {
  // const dispatch = useDispatch()
  // const { decodedAccessToken } = useSelector((state: ICombineReducers) => state.loginReducer)
  // const PartyID = decodedAccessToken.partyID
  // console.log(decodedAccessToken, 'decodedAccessToken')

  const getSubdomain = (domain: string): string => {
    const domainSplit = domain.split('.')
    if (domainSplit.length < 3 || domain.startsWith('192.168')) return 'hrlinklook' // This is localhost
    // return domainSplit[domainSplit.length - 2]

    console.log(domainSplit, 'domainSplitdomainSplit')
    return domainSplit[0] // Change it back to first, since wildcard must be at far left
  }

  const companyID = getSubdomain(window.location.host).toUpperCase()
  // const companyID = useSelector((state: ICombineReducers) => state.companyIDReducer.companyID)
  // if (subdomain !== companyID) {
  //   dispatch({
  //     type: 'COMPANY/CHANGE',
  //     companyID: subdomain,
  //   })
  // }

  // console.log(getSubdomain(), 'company')

  return companyID
}

export default useCompanyID
