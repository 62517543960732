const setShift = (shiftList: [], refetch: any): { type: string; shiftList: []; refetch: any } => {
  return {
    type: 'shift',
    shiftList: shiftList,
    refetch: refetch,
  }
}
export default {
  setShift,
}
