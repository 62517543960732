import { gql, useLazyQuery } from '@apollo/client'
import React from 'react'
import { flushSync } from 'react-dom'
import { CreateLeaveConfig, LeaveConfig, UpdateLeaveConfig, DeleteLeaveConfig, Employee } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationCreateLeaveConfig, mutationUpdateLeaveConfig, mutationDeleteLeaveConfig } from '../xhr/mutation'
import { queryListLeaveConfig, querygetEmployee } from '../xhr/query'
// ----------------------------------------------- GET EMPLOYEE -----------------------------------------------

export function useGetEmployee(variables: any, isLazy = false) {
  const [data, setData] = React.useState<Employee>()
  const [loading, setLoading] = React.useState(true)
  const [_fetch] = useLazyQuery(gql(querygetEmployee))

  const refetch = async (variables: any) => {
    setLoading(true)
    const res = await _fetch({ variables, fetchPolicy: 'network-only' })
    setLoading(false)
    const _data = res.data.getEmployee as Employee

    flushSync(() => {
      setData(_data)
      setLoading(false)
    })

    return _data
  }

  React.useEffect(() => {
    if (!isLazy) refetch(variables)
  }, [])
  return { data, loading, refetch }
}
