import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'

// Components
import {
  NavTopBarComponent,
  NavSideBarComponent,
  ButtonComponent,
  InputNumberUnitComponent,
  InputRadioComponent,
} from '../../component'

//Formik
import * as yup from 'yup'
import { Formik, FormikProps, useFormikContext } from 'formik'
import { InputSelectComponent } from '../../component'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { useNavigate } from 'react-router-dom'
import { Box, Stack } from '@mui/system'
import { Drawer, Paper, Popper } from '@mui/material'
import CustomizedSwitches from '../../component/AntSwitch'
import SettingIcon from '../../image/setting.svg'
import CloseIcon from '../../image/close.svg'
import QuestionIcon from '../../image/question.svg'

import {
  EOvertimeHourHalf,
  EOverTimeMultiplyType,
  EOverTimeRoundFloor,
  EOverTimeRoundType,
  EStatus,
  OvertimeConfig,
} from '../../utils/generated'
import { getOvertimeConfig, updateOvertimeConfig } from '../../adapter/api'
import Swal from 'sweetalert'

type IValue = OvertimeConfig

const OvertimePolicyEditPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formikRef = useRef<FormikProps<IValue> | null>(null)

  const validationSchema = yup.object({
    // nameOvertime: yup.string().required('Name overtime is required'),
    // typeOvertime: yup.string().required('Type overtime is required'),
    // wage: yup.string().required('Wage is required'),
  })

  const initialValues: IValue = {}

  const monthRows = (values: IValue) => {
    if (!values.overtimeMonth) return []

    return [
      createRowData('HOLIDAY', 'วันหยุดประจำปี', values.overtimeMonth['HOLIDAY']),
      createRowData('WEEKEND', 'วันหยุดสุดสัปดาห์', values.overtimeMonth['WEEKEND']),
      createRowData('WORKDAY', 'วันทำงาน', values.overtimeMonth['WORKDAY']),
    ]
  }

  const dayRows = (values: IValue) => {
    if (!values.overtimeMonth) return []

    return [
      createRowData('HOLIDAY', 'วันหยุดประจำปี', values.overtimeDay['HOLIDAY']),
      createRowData('WEEKEND', 'วันหยุดสุดสัปดาห์', values.overtimeDay['WEEKEND']),
      createRowData('WORKDAY', 'วันทำงาน', values.overtimeDay['WORKDAY']),
    ]
  }

  const [open, setOpen] = React.useState<boolean>(false)

  const fetchOvertimeConfig = async () => {
    const res = await getOvertimeConfig()
    formikRef.current!.setValues(res)
  }

  useEffect(() => {
    fetchOvertimeConfig()
  }, [])

  const handleUpdate = async (values: IValue) => {
    await updateOvertimeConfig(values)
  }

  const onSubmit = (values: IValue) => {
    // dispatch(
    //   allAction.modalConfirmAction.setOpenModalConfirm(
    //     'ยืนยันการทำรายการ',
    //     'คุณต้องการยืนยันการทำรายการ',
    //     'เพิ่มข้อมูลการทำงานล่วงเวลา?',
    //     'ใช่, ทำรายการ',
    //     () => {
    //       dispatch(allAction.modalAction.setOpenModalAlertStatus('เพิ่มรายการเรียบร้อย', '', 'success'))
    //       dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
    //     },
    //   ),
    // )
  }

  const handleStatus = (values: IValue, name: 'overtimeMonthStatus' | 'overtimeDayStatus') => {
    const value = values[name]
    return {
      isActive() {
        return value === EStatus.Active
      },
      async toggle(
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        status?: EStatus,
      ) {
        if (status) return setFieldValue(name, status)
        const _status = value === EStatus.Active ? EStatus.Inactive : EStatus.Active
        setFieldValue(name, _status)

        const values = formikRef.current!.values!
        handleUpdate({
          ...values,
          [name]: _status,
        })
      },
    }
  }

  return (
    <>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          innerRef={formikRef}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <div className="main">
              <form onSubmit={handleSubmit} className={styles.container}>
                <Stack rowGap={2}>
                  <div className={styles.header}>
                    <h1 className={styles.topicPage}>การทำงานล่วงเวลา</h1>
                    <img onClick={() => setOpen(true)} src={SettingIcon} className={styles.icon} alt="" />
                    <RightSideBar open={open} onClose={setOpen} />
                  </div>
                  <div className={styles.tableContainer}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <h4>รายเดือน</h4>
                      <CustomizedSwitches
                        _name="overtimeMonthStatus"
                        _checked={handleStatus(values, 'overtimeMonthStatus').isActive()}
                        _onClick={() => handleStatus(values, 'overtimeMonthStatus').toggle(setFieldValue)}
                      />
                    </Stack>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>ตัวคูณเท่าค่าทำงานปกติ</th>
                          <th>ตัวคูณเท่าค่าล่วงเวลา</th>
                        </tr>
                      </thead>
                      <tbody>
                        {monthRows(values).map((row) => (
                          <tr key={row.key}>
                            <td>{row.label}</td>
                            <td>{row.data.WORK}</td>
                            <td>{row.data.OVERTIME}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.tableContainer}>
                    <Stack direction="row" justifyContent="space-between">
                      <h4>รายวัน</h4>
                      <CustomizedSwitches
                        _name="overtimeDayStatus"
                        _checked={handleStatus(values, 'overtimeDayStatus').isActive()}
                        _onClick={() => handleStatus(values, 'overtimeDayStatus').toggle(setFieldValue)}
                      />
                    </Stack>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>ตัวคูณเท่าค่าทำงานปกติ</th>
                          <th>ตัวคูณเท่าค่าล่วงเวลา</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dayRows(values).map((row) => (
                          <tr key={row.key}>
                            <td>{row.label}</td>
                            <td>{row.data.WORK}</td>
                            <td>{row.data.OVERTIME}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Stack>
              </form>
            </div>
          )}
        </Formik>
        <div className="footer">Footer</div>
      </div>
    </>
  )
}
export default OvertimePolicyEditPage

interface RigtSideBarProps {
  open: boolean
  onClose: (status: boolean) => void
}
const RightSideBar: React.FC<RigtSideBarProps> = ({ open, onClose }) => {
  const { values } = useFormikContext<IValue>()
  const handleClose = () => {
    onClose(false)
  }

  const [anchor, setAnchor] = useState<HTMLElement | null>(null)

  const [openPopper, setOpenPopper] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
    setOpenPopper((prev) => !prev)
  }

  const handleUpdate = async () => {
    try {
      await updateOvertimeConfig(values)
      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
    }
  }

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <div className={styles.rigtSideBarContainer}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <h3>การตั้งค่า</h3>
          <img src={CloseIcon} className={styles.icon} onClick={handleClose} />
        </Stack>
        <Stack rowGap={2} mt={2}>
          <div>
            <p>1. ทำงานล่วงเวลา นับก่อนเริ่มงานกี่นาที</p>
            <InputNumberUnitComponent
              _name="overtimeBeforeMinute"
              _label=""
              _unit="นาที"
              _descriptionText="เวลาเริ่มงาน 08:00 เริ่มงานตอน 07:00 ถือว่าล่วงเวลา 1 ชั่วโมง เริ่มงาน 07:02 ไม่ถือว่าล่วงเวลา"
            />
          </div>
          <div>
            <p>2. ทำงานล่วงเวลา นับหลังเลิกงานกี่นาที</p>
            <InputNumberUnitComponent
              _name="overtimeAfterMinute"
              _label=""
              _unit="นาที"
              _descriptionText="เวลาเลิกงาน 17:00 เลิกงานตอน 18:00 ถือว่าล่วงเวลา 1 ชั่วโมง เลิกงาน 17:58 ไม่ถือว่าล่วงเวลา"
            />
          </div>
          <div>
            <p>3. การคำนวณค่าแรงโอที</p>
            <InputRadioComponent
              _name="overtimeMultiplyType"
              _menuList={[
                { label: 'บาท/นาที', value: EOverTimeMultiplyType.BahtPerMinute },
                { label: 'บาท/ชั่วโมง', value: EOverTimeMultiplyType.BahtPerHour },
                { label: 'คูณจำนวนเท่าของค่าแรง', value: EOverTimeMultiplyType.MultiplyPerWage },
              ]}
            />
          </div>
          <div>
            <Stack direction="row" alignItems="center" columnGap={1}>
              <p>4. การปัดเศษคำนวณค่าล่วงเวลา</p>
              <img onClick={handleClick} src={QuestionIcon} id="popper-ot" />

              <Popper id="popper-ot" anchorEl={anchor} open={openPopper} placement="left" sx={{ zIndex: 1201 }}>
                <Paper sx={{ width: '30rem', mr: 1, p: 2, borderRadius: '14px' }}>
                  <p>ตัวที่จะนำไปคำนวณชั่วโมงการทำโอที</p>
                  <p>ไม่ปัดเศษ คือการปัดเศษทิ้งนาที</p>
                  <p>Ex. พนักงานทำงาน 2:34:21 ชั่วโมง โอที คือ 2 ชั่วโมง 34 นาที</p>
                  <p>ปัดเศษมี 2 แบบ</p>
                  <Box sx={{ mt: 2 }}>
                    <p>1. ปัดเศษเต็มชั่วโมง</p>
                    <Box sx={{ ml: 2 }}>
                      <p>a. ปัดเศษทิ้ง ( Floor) คือ ทำงานโอที 2:10 ชั่วโมง = 2 ชั่วโมง</p>
                      <Stack direction="row">
                        <p>b. ปัดเศษ (Round) คือ</p>
                        <Box sx={{ ml: 1 }}>
                          <p>ทำงานโอที 2:10 ชั่วโมง = 2 ชั่วโมง</p>
                          <p>ทำงานโอที 2:50 ชั่วโมง = 3 ชั่วโมง</p>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                  <p>2. ปัดเศษครึ่งชั่วโมง</p>
                  <Box sx={{ ml: 2 }}>
                    <p>a. ปัดเศษทิ้ง ( Floor) คือ ทำงานโอที 2:10 ชั่วโมง = 2 ชั่วโมง</p>
                    <Stack direction="row">
                      <p>b. ปัดเศษ (Round) คือ</p>
                      <Box sx={{ ml: 1 }}>
                        <p>ทำงานโอที 2:10 ชั่วโมง = 2 ชั่วโมง</p>
                        <p>ทำงานโอที 2:15 ชั่วโมง = 2:30 ชั่วโมง</p>
                      </Box>
                    </Stack>
                  </Box>
                </Paper>
              </Popper>
            </Stack>
            <InputRadioComponent
              _name="overtimeRoundFloor"
              _menuList={[
                { label: 'ปัดเศษ', value: EOverTimeRoundFloor.Round },
                { label: 'ไม่ปัดเศษ', value: EOverTimeRoundFloor.Floor },
              ]}
            />
            {values.overtimeRoundFloor === EOverTimeRoundFloor.Round && (
              <Stack direction="row" columnGap={1}>
                <InputSelectComponent
                  _name="overtimeRoundHour"
                  _menuList={[
                    { label: 'เต็มชั่วโมง', value: EOvertimeHourHalf.FullHour },
                    { label: 'ครึ่งชั่วโมง', value: EOvertimeHourHalf.HalfHour },
                  ]}
                  _label=""
                />
                <InputSelectComponent
                  _name="overtimeRoundType"
                  _menuList={[
                    { label: 'ปัดเศษทิ้ง', value: EOverTimeRoundType.NotRound },
                    { label: 'ปัดเศษ', value: EOverTimeRoundType.Round },
                  ]}
                  _label=""
                />
              </Stack>
            )}
          </div>
          <div>
            <p>5. เวลาพักก่อนเริ่มโอทีหลังเลิกงาน </p>
            <InputNumberUnitComponent
              _name="overtimeBreakBeforeMinute"
              _label=""
              _unit="นาที"
              _descriptionText="เวลาปกติ 08:00 - 17:00"
            />
          </div>
          <div>
            <p>6. ไม่นับเวลาพักก่อนเริ่มโอที เมื่อเกินเวลารวมหลังเลิกงาน</p>
            <InputNumberUnitComponent _name="overtimeBreakAfterMinute" _label="" _unit="นาที" />
          </div>

          <ButtonComponent
            _text="บันทึก"
            _colorBG="blue"
            _colorText="white"
            _variant="text"
            _type="button"
            _sx={{
              fontSize: 16,
              padding: '0.5rem',
              mt: 2,
            }}
            _functionOnClick={handleUpdate}
          />
        </Stack>
      </div>
    </Drawer>
  )
}

const createRowData = (key: string, label: string, data: { WORK: number; OVERTIME: number }) => {
  return {
    key,
    label,
    data,
  }
}
