import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { AutocompleteComponent, ButtonComponent, InputTextAreaComponent, InputTextComponent } from '../../component'
import allAction from '../../state/actions'
import styles from './index.module.scss'
//* Api
import { createDepartment, queryGetDepartment, updateDepartment } from '../../adapter/xhr'

const DepartmentNewEdit: React.FC = (): JSX.Element => {
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  //data
  const work = [
    { title: 'Shift A', value: 'Shift A' },
    { title: 'Shift B', value: 'Shift B' },
    { title: 'Shift C', value: 'Shift C' },
    { title: 'Shift D', value: 'Shift D' },
    { title: 'Shift E', value: 'Shift E' },
    { title: 'Shift F', value: 'Shift F' },
  ]

  const leave = [
    { title: 'ลาป่วย', value: 'ลาป่วย' },
    { title: 'ลากิจ', value: '2' },
    { title: 'ลาคลอด', value: 'ลากิจ' },
    { title: 'ลาพักร้อน', value: 'ลาพักร้อน' },
  ]
  // const benefit = [
  //   { title: 'สวัสดิการอาหาร', value: '1' },
  //   { title: 'สวัสดิการขนม', value: '2' },
  //   { title: 'ค่าใช้จ่าย - น้ำมัน', value: '3' },
  //   { title: 'เบิกอื่น ๆ ', value: '4' },
  // ]
  const calendar = [
    { title: 'Shift A', value: 'Shift A' },
    { title: 'Shift B', value: 'Shift B' },
    { title: 'Shift C', value: 'Shift C' },
    { title: 'Shift D', value: 'Shift D' },
    { title: 'Shift E', value: 'Shift E' },
    { title: 'Shift F', value: 'Shift F' },
  ]
  const ot = [
    { title: 'ทำงานล่วงเวลาวันหยุด', value: 'ทำงานล่วงเวลาวันหยุด' },
    { title: 'ทำงานล่วงเวลาวันปกติ', value: 'ทำงานล่วงเวลาวันปกติ' },
    { title: 'ทำงานล่วงเวลาพิเศษ', value: 'ทำงานล่วงเวลาพิเศษ' },
  ]
  useEffect(() => {
    if (id) {
      setMode('update')
      const getDepartmentValiable = {
        getDepartmentId: id,
      }
      queryGetDepartment(getDepartmentValiable).then((res: any) => {
        console.log('RES GET DEPARTMENT', res.data.data)
        //! get String Radio ? select >> data is not Arr
        const resDepartment = res.data.data.getDepartment
        const attendance: any = []
        console.log('>> attendance', attendance)

        // resDepartment.map((e: any) => {
        //   return attendance.push({
        //     title: `${e.getDepartment.regulationWorkAttendance}`,
        //     value: ` ${e.getDepartment.regulationWorkAttendance}`,
        //   })
        // })
      })
    } else {
      setMode('create')
    }
  }, [])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const validationSchema = yup.object({
    depart: yup.string().required('ใส่ชื่อแผนก'),
  })
  return (
    <>
      <nav className={styles.navtop}>
        {mode === 'create' && <h1 className={styles.title}>เพิ่มแผนก</h1>}
        {mode === 'update' && <h1 className={styles.title}>แก้ไขข้อมูลแผนก</h1>}
      </nav>
      <div className={styles.container}>
        <div className={styles.cardform}>
          <Formik
            initialValues={{
              depart: '',
              work: ['Shift A'], //! test data
              leave: [],
              benefit: [],
              calendar: [],
              ot: [],
              detail: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              if (mode === 'create') {
                console.log('valuessss', values.leave)
                const createDepartmentValiable = {
                  input: {
                    departmentName: values.depart,
                    regulationWorkAttendance: values.work,
                    regulationLeave: values.leave,
                    regulationCalendar: values.calendar,
                    regulationOverTime: values.ot,
                    departmentDetail: values.detail,
                  },
                }
                createDepartment(createDepartmentValiable).then((res: any) => {
                  console.log('create department api call', res.data.data)
                })
                dispatch(allAction.modalAction.setOpenModalAlertStatus('เพิ่มรายการเรียบร้อย', '', 'success'))
              } else {
                const updateDepartmentValiable = {
                  input: {
                    id: 'asdasd4-45asd-454848qd',
                    departmentName: 'Develope',
                    regulationWorkAttendance: 'SHIFT_A',
                    regulationLeave: 'REASON_ANNUAL_LEAVE',
                    regulationCelendar: 'SHIFT_B',
                    regulationOverTime: 'OT_B',
                    departmentDetail: 'test product',
                  },
                }
                updateDepartment(updateDepartmentValiable).then((res: any) => {
                  console.log('update department api call', res.data.data)
                })
                dispatch(allAction.modalAction.setOpenModalAlertStatus('แก้ไขรายการเรียบร้อย', '', 'success'))
              }
            }}
          >
            {(props: any) => (
              <form onSubmit={props.handleSubmit} className={styles.formgroup}>
                {console.log('DPM Value', props.values)}
                <div className={styles.BoxInput}>
                  <InputTextComponent _name="depart" _label="ชื่อแผนก" _helperText={props.errors.depart} />
                  <AutocompleteComponent
                    _label="ระเบียบการเข้างาน"
                    _placeholder="เลือกระเบียบการเข้างาน"
                    _name="work"
                    _option={work}
                  />
                  <AutocompleteComponent
                    _label="ระเบียบการลา"
                    _placeholder="เลือกระเบียบการลา"
                    _name="leave"
                    _option={leave}
                  />
                  {/* <AutocompleteComponent
                      _label="สวัสดิการ"
                      _placeholder="เลือกสวัสดิการ"
                      _name="benefit"
                      _option={benefit}
                    /> */}
                  <AutocompleteComponent
                    _label="ระเบียบปฏิทิน"
                    _placeholder="เลือกระเบียบปฏิทิน"
                    _name="calendar"
                    _option={calendar}
                  />
                  <AutocompleteComponent
                    _label="ระเบียบการทำงานล่วงเวลา"
                    _placeholder="เลือกระเบียบการทำงานล่วงเวลา"
                    _name="ot"
                    _option={ot}
                  />

                  <InputTextAreaComponent _name="detail" _label="รายละเอียด" />
                </div>

                <div className={styles.formButton}>
                  <div className={styles.btCancle}>
                    <ButtonComponent
                      _colorBG="white"
                      _colorText="red"
                      _text="Cancle"
                      _variant="contained"
                      _type="button"
                      _functionOnClick={() => navigate(-1)}
                    />
                  </div>
                  {mode === 'create' && (
                    <div className={styles.btAdd}>
                      <ButtonComponent
                        _colorBG="blue"
                        _colorText="white"
                        _text="Add"
                        _variant="contained"
                        _type="submit"
                      />
                    </div>
                  )}
                  {mode === 'update' && (
                    <div className={styles.btAdd}>
                      <ButtonComponent
                        _colorBG="blue"
                        _colorText="white"
                        _text="Edit"
                        _variant="contained"
                        _type="submit"
                      />
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
export default DepartmentNewEdit
