import { gql } from '@apollo/client'

//Department
export const mutationCreateDepartment = `
mutation Mutation($input: CreateDepartmentInput!) {
  createDepartment2(input: $input) {
    departmentName
    regulationWorkAttendance
    regulationLeave
    regulationCalendar
    regulationOverTime
    departmentDetail
  }
}
`
export const mutationUpdateDepartment = `
mutation UpdateDepartment($input: UpdateDepartmentInput!) {
  updateDepartment(input: $input) {
    id
    departmentName
    regulationWorkAttendance
    regulationLeave
    regulationCalendar
    regulationOverTime
    departmentDetail
  }
}
`
export const mutationDeleteDepartment = `
mutation DeleteDepartment($input: DeleteDepartmentInput!) {
  deleteDepartment(input: $input) {
    id
    departmentName
  }
}
`

//Employee
export const mutationCreateTeam2 = `
mutation CreateTeam2($input: CreateTeamInput!) {
  createTeam2(input: $input) {
    id
    teamName
    teamLeaderID
    listMemberID
    remark
  }
}
`
export const mutationUpdateTeam = `
mutation Mutation($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    id
    teamName
  }
}

`
//Position
export const mutationCreatePosition = `
mutation CreatePosition2($input: CreatePositionInput!) {
  createPosition2(input: $input) {
    id
    positionName
    positionLevel
    remark
  }
}
`
export const mutationUpdatePosition = `
mutation Mutation($input: UpdatePositionInput!) {
  updatePosition(input: $input) {
    id
    positionName
    positionLevel
    remark
  }
}
`
export const mutationDeletePosition = `
mutation DeletePosition($input: DeletePositionInput!) {
  deletePosition(input: $input) {
    id
    positionName
    positionLevel
    remark
  }
}
`

export const mutationCreateTimeAttendanceBatch = `
mutation Mutation($input: CreateTimeAttendanceBatchInput!, $startDate: AWSDate!, $endDate: AWSDate!) {
  createTimeAttendanceBatch(input: $input, startDate: $startDate, endDate: $endDate)
}`

export const mutationUpdateTimeAttendanceReport = `
mutation UpdateTimeAttendanceReport($input: UpdateTimeAttendanceReportInput!) {
  updateTimeAttendanceReportTypeAttendance(input: $input) {
    employeeID
  }
}`

export const mutationUpdateLeaveAttendance = `
mutation UpdateTimeAttendanceReportTypeLeave($input: UpdateTimeAttendanceReportInput!) {
  updateTimeAttendanceReportTypeLeave(input: $input) {
    employeeID
  }
}`

export const mutationUpdateOTAttendance = `
mutation UpdateTimeAttendanceReportTypeOvertime($input: UpdateTimeAttendanceReportInput!) {
  updateTimeAttendanceReportTypeOvertime(input: $input) {
    employeeID
    date
  }
}`

export const mutationDeleteTimeAttendanceReport = `
mutation DeleteTimeAttendanceReport($input: DeleteTimeAttendanceReportInput!) {
  deleteTimeAttendanceReport(input: $input) {
    employeeID  
  }
}`

//Admin New
export const mutationCreateHolidayPolicy = `
mutation Mutation($input: CreateHolidayInput!, $year: Int!) {
  createHolidayOnYear(input: $input, year: $year) {
    holidayID
    holidayName
    holidayYear
  }
}
`

export const mutationCreateShift = `
mutation CreateShift($input: CreateShiftInput!) {
  createShift(input: $input) {
    shiftID
      workDayPolicy {
        dayList
        timeList {
          startTime
          endTime
          isScanEnd
          isScanEnd
        }
      }
      breakPolicy {
        dayList
        timeList {
          startTime
          endTime
          isScanEnd
          isScanEnd
        }
      }
      employeeType
      workType
  }
}`

export const mutationUpdateShift = `
mutation UpdateShift($input: UpdateShiftInput!) {
  updateShift(input: $input) {
    shiftID
  }
}`

export const mutationPutHexColorList = `
mutation PutShiftColor($input: PutShiftColorInput!) {
  putShiftColor(input: $input) {
    hexColorList
  }
}`

export const mutationUpdateDefaultShift = `
mutation UpdateDefaultShift($shiftId: ID!) {
  updateDefaultShift(shiftID: $shiftId) {
    shiftColor
  }
}`

export const mutationDeleteShift = `
mutation DeleteShift($input: DeleteShiftInput!) {
  deleteShift(input: $input) {
    shiftID
  }
}

`
//UpdateHoliday
export const mutationUpdateHoliday = `
mutation Mutation($input: UpdateHolidayInput!) {
  updateHolidayOnYear(input: $input) {
    holidayID
    holidayName
    holidayYear
  }
}
`

//DelHoliday
export const mutationDelHoliday = `
mutation Mutation($holidayId: ID!) {
  deleteHoliday(holidayID: $holidayId) {
    holidayID
    holidayName
    holidayYear
  }
}
`

//Attendancereport
export const mutationAttendanceReport = `
mutation TimeReport_update($input: UpdateTimeAttendanceReportInput!) {
  updateTimeAttendanceReport(input: $input) {
    employeeID
    date
    scanIn
    scanOut
    workingTimeList {
      startedAt
      endedAt
    }

    inDiffMinute
    breakDiffMinute
    outDiffMinute

    overtimeList {
      overtimeType
      startedAt
      endedAt
    }


    overtimeApproveList {
      startedAt
      endedAt
      overtimeMinute
      overtimeType
      locationType
      status
      overtimeRequestID
    }

    leaveDeductList {
      leaveType
      startedAt
      endedAt
      leaveDocumentAttachment
      leaveForm
      leaveRequestID
      status
    }
    leaveList {
      leaveType
      startedAt
      endedAt
      leaveDocumentAttachment
      leaveMinute
      leaveForm
      leaveRequestID
      status
    }
    isLink
    leaveMinute
    leaveDeductMinute
    overtimeApproveMinute
    overtimeMinute
    workingMinute
  }
}
`

export const mutationCreatePayroll = `
mutation CreatePayroll($yearMonth: String!) {
  createPayroll(yearMonth: $yearMonth)
}`

export const mutationPutEmployeePayroll = `
mutation PutEmployeePayroll($input: [PutEmployeePayrollInput!]!, $period: String!, $yearMonth: String!) {
  putEmployeePayroll(input: $input, period: $period, yearMonth: $yearMonth)
}`

//Mutation Document Leave
export const mutationCreateLeaveDocument = `
mutation CreateLeaveRequest($input: CreateLeaveInput!) {
  createLeaveRequest(input: $input) {
    id
    date
    employeeID
    leaveType
    isLeaveDeductMoney
    leaveForm
    startedAt
    endedAt
    leaveDocumentAttachment
    status
    leaveMinute
    leaveConfig {
      id
      name
      isActive
      leaveAmountType
      leaveConfigType
      employeeAllowSexList
      employeeAllowTypeList
      isLeaveBeforehand
      leaveBeforehandDay
      isLeaveDiligent
      isLeaveCompound
      leaveCompoundType
      maxLeaveCompound
      isLookupNotWorkday
      isLeaveAfterhand
      isCountProbation
      isExceedQuota
      isLeaveOnce
    }
  }
}
`

//CreateOvertime
export const mutationCreateOvertimeDocument = `
mutation RequestOT_create($input: CreateOvertimeInput!) {
  createOvertimeRequest(input: $input) {
    id
    date
    employeeID
    startedAt
    endedAt
    status
    overtimeType
    overtimeMinute
  }
}
 `

//ResetAttendance
export const mutationResetAttendance = `
mutation ResetTimeAttendanceReport($input: ResetTimeAttendanceReportInput!) {
  resetTimeAttendanceReport(input: $input) {
    companyID
    date
    employeeID
    createdAt
    updatedAt
    Type
    dayType
    isLink
    remark
    shiftName
    shiftStartTime
    shiftEndTime
    shiftVersion
    shiftMinute
    breakTimeList {
      startTime
      endTime
      startedAt
      endedAt
    }
    scanIn
    scanOut
    inDiffMinute
    breakDiffMinute
    outDiffMinute
    workingTimeList {
      startedAt
      endedAt
      workingMinute
    }
    workingMinute
    overtimeList {
      startedAt
      endedAt
      overtimeMinute
      overtimeType
      locationType
      status
      overtimeRequestID
      isCustomOvertimeMultiply
      overtimeMultiply
    }
    overtimeMinute
    overtimeApproveList {
      startedAt
      endedAt
      overtimeMinute
      overtimeType
      locationType
      status
      overtimeRequestID
      isCustomOvertimeMultiply
      overtimeMultiply
    }
    overtimeApproveMinute
    leaveList {
      startedAt
      endedAt
      leaveMinute
      leaveType
      leaveForm
      leaveDocumentAttachment
      leaveRequestID
      status
      
    }
    leaveMinute
    leaveDeductList {
      startedAt
      endedAt
      leaveMinute
      leaveType
      leaveForm
      leaveDocumentAttachment
      leaveRequestID
      status
    
    }
    leaveDeductMinute
    status
  
    timeAttendanceLog {
      scanIn
      scanOut
    }
  }
}
 `

//Del OT Document
export const mutationDelRequestOverTime = `
mutation RequestOT_delete($input: DeleteRequestInput!) {
  deleteOvertimeRequest(input: $input) {
    employeeID
    id
  }
} 
 `

//Del Leave Document
export const mutationDeleteLeave = `
 mutation RequestLeave_delete($input: DeleteRequestInput!) {
  deleteLeaveRequest(input: $input) {
    employeeID
    id
  }
}
 `

//Approve Document
export const mutationApproveDocument = `
mutation Mutation($input: UpdateApprove!) {
  updateApprove(input: $input) {
    id
    employeeID
    approveBy
    status
    remarkApprove
    Type
    timestamp
    updatedAt
    createdAt
  }
}
 `

//Approve Document Arrray
export const mutationApproveBatch = `
 mutation Mutation($input: [UpdateApprove!]!) {

  updateApproveBatch(input: $input)

}
 `

export const mutationUpdateDefaultCalendar = `
 mutation Holiday_default($holidayId: ID!) {
  updateDefaultHoliday(holidayID: $holidayId) {
    holidayID
  }
}
 `

export const mutationLogin = `
mutation Login($username: String!, $password: String!, $partyId: String!) {
  login(username: $username, password: $password, partyID: $partyId)
}
 `

export const mutationRefreshToken = `
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken)
  }
`

//Employee Profile
export const mutationCreateEmployeeProfile = `
mutation Mutation($input: CreateEmployeeInput!) {
  createEmployee2(input: $input) {
    bankPayment
   id
 } 
 }
`

//UpdateEmployee
export const mutationupdateEmployeeProfile = `
mutation Mutation($input: UpdateEmployeeInput!) {
  updateEmployee2(input: $input) {
    id
    taxFixedPerMonth
    taxCalculate
    taxStartedMonth
    positionID
    departmentID
    personalID
    teamList
    fingerPrintId
    leaderTeam
    shiftWorkingID
    packageGroupID
    holidayID
    getHolidayID
    personalIDNumber
    bankAccountId
    lineUserId
    status
    employeePeriod
    nameTitle
    fullName
    middleName
    lastName
    nationality
    religion
    ethnicity
    fullNameEnglish
    middleNameEnglish
    lastNameEnglish
    birthDate
    nickName
    sex
    addressPersonalID {
      addressNumber
      group
      optional
      province
      subDistrict
      district
      zipCode
    }
    addressCurrent {
      addressNumber
      group
      optional
      province
      subDistrict
      district
      zipCode
    }
    personalRelation
    hireDate
    retireDate
    employeeType
    employeeWorkStatus
    isTimeAttendance
    setShiftWorking
    workPlace
    holidayLink
    salaryOptional
    salary
    incomeExpense {
      incomeOrExpenseID
      incomeExpenseType
      name
      total
      unit
    }
    socialSecurity
    hospitalService
    socialSecurityCalculate
    diligentAllowance
    employeeFund {
      incomeOrExpenseID
      name
      fundNumber
      contractDate
      deductMethod
      fundRate
      contributionMethod
      contributionCompany
      contributionTotal
      beneficiary
      remark
    }
    taxDeductOne {
      year
      month
      total
    }
    taxDeductTwo {
      name
      rateDeduct
      totalDeduct
    }
    username
    password
    profileImage
    bankPayment
    bankAccountName
    paymentMethod
    employeeHandicappedAndAgeOut
    tel
    email
    salaryUOM
    educationHighest
    unusedLatestSalary
    position {
      id
      positionName
      positionLevel
      remark
    }
    historyFamily {
      fullName
      age
      job
      relation
    }
    department {
      id
      departmentName
    }
    historyEducation {
      educationLevel
      educationName
      majorName
      startedYear
      endedYear
    }
    personalOptionalAttachment
    personalTrainningAttachment
    personalEducationalAttachment
    personalHouseAttachment
    personalIdAttachment
    historyWork {
      workName
      startedAt
      endedAt
      positionName
      descPosition
      salary
      leaveReason
    }
    lineId
    listTeam {
      items {
        id
      }
      nextToken
      startedAt
    }
    personalTalent {
      isComputer
      isDrive
      drivingLicenseID
      drivingLicenseExpire
      hobby
      sport
      other
    }
    bloodType
    detailsFamily {
      childrens
      fullName
      lastName
      job
      positionName
      companyName
      tel
      siblings
      myNumber
      maleNumber
      femaleNumber
    }
    emergencyContact {
      fullName
      lastName
      relation
      tel
      address
    }
    height
    historySick {
      sickName
      isSick
      remark
    }
    historyTraining {
      trainingName
      courseName
      date
      period
    }
    jobDescAttachment
    language {
      language
      speak
      write
      read
    }
    militaryStatus
    nationalityType
    personalIDAt
    personalIDExpire
    personalIDIssue
    personalMarriageAttachment
    personalWorkID
    weight
  }
}
`

//Close Payroll
export const mutationClosePayroll = `
mutation ClosePayroll($yearMonth: String!, $period: String!, $closeDate: String) {
  closePayroll(yearMonth: $yearMonth, period: $period, closeDate: $closeDate) {
    employeeCount
    status
    createdAt
    updatedAt
    timeAttendanceReportList {
      employeeID
      employee {
        id
        positionID
        departmentID
      }
      items {
        companyID
        date
        employeeID
        dayType
      }
    }
    employeePayrollList {
      employeeID
      workingMinute
      leaveMinute
      leaveDeductMinute
      otTypeWorkMinute
      otTypeOTMinute
      income
      otTypeWorkIncome
      otTypeOTIncome
      otherIncomeList {
        name
        incomeOrExpense
        incomeExpenseType
        variableOrFixed
        income40Type
        amount
        incomeOrExpenseID
      }
      workDiligentIncome
      inEarlyIncome
      breakEarlyIncome
      outLateIncome
      netPositiveIncome
      otherExpenseList {
        name
        incomeOrExpense
        incomeExpenseType
        variableOrFixed
        income40Type
        amount
        incomeOrExpenseID
      }
      inLateExpense
      breakLateExpense
      netNegativeIncome
      netIncome
      daysInPeriod
      paySlipURL
      paymentID
    }
    totalPayroll
    closeDate
    nextToken
    employeeTypeCount {
      FULLTIME_MONTH
      FULLTIME_DAY
      PARTTIME
      OUTSOURCE
    }
    employeeRetireCount
    employeeHireCount
    paySlipEmpPeriod
  }
}
`

//Payroll Slip
export const mutationPayrollSlip = `
mutation PDF_SlipPeyment($input: DownloadOptional!) {
  downloadPDFDocument(input: $input)
}
`

//UpdateOvertimeDocument
export const mutationUpdateOvertime = `
mutation Overtime_update($input: UpdateOvertimeInput!) {
  updateOvertime2(input: $input) {
    id
    employeeID
    date
    startedAt
    endedAt
    overtimeType
    status
    overtimeMultiply
  }
}
`

//DeleteOvertimeDocument
export const mutationDeleteOvertime = `
mutation DeleteOvertime2($input: DeleteOvertimeInput!) {
  deleteOvertime2(input: $input) {
    id
    employeeID
    date
    startedAt
    endedAt
    overtimeType
    placeType
    status
  }
}
`

//UpdateLeaveDocument
export const mutationUpdateLeaveDocument = `
mutation Leave_update($input: UpdateLeaveInput!) {
  updateLeave2(input: $input) {
    id
    employeeID
    createdAt
    date
    startedAt
    endedAt
    leaveType
    status
  }
}
`

export const mutationDeleteLeaveDocument = `
mutation Leave_delete($input: DeleteLeaveInput!) {
  deleteLeave2(input: $input) {
    id
    employeeID
    createdAt
    date
    startedAt
    endedAt
    leaveType
    leaveForm
    status
  }
}

`

export const mutationCreateLeaveConfig = `
mutation LeaveConfig_Create($input: CreateLeaveConfig!) {
  createLeaveConfig(input: $input) {

    id
    name
    isActive
    leaveAmountType
    leaveConfigType
    employeeAllowSexList
    employeeAllowTypeList
    leaveQuotaList {
      workMonthExperience
      quotaLeaveDay
      maxLeavePaidDay
    }
    isLeaveBeforehand
    leaveBeforehandDay
    isLeaveDiligent
    isLeaveCompound
    leaveCompoundType
    maxLeaveCompound
    isLookupNotWorkday
    isLeaveAfterhand
    isCountProbation
    isExceedQuota
    isLeaveOnce

  }
}
`

export const mutationUpdateLeaveConfig = `
mutation LeaveConfig_Update($input: UpdateLeaveConfig!) {
  updateLeaveConfig(input: $input) {

    id
    name
    isActive
    leaveAmountType
    leaveConfigType
    employeeAllowSexList
    employeeAllowTypeList
    leaveQuotaList {
      workMonthExperience
      quotaLeaveDay
      maxLeavePaidDay
    }
    isLeaveBeforehand
    leaveBeforehandDay
    isLeaveDiligent
    isLeaveCompound
    leaveCompoundType
    maxLeaveCompound
    isLookupNotWorkday
    isLeaveAfterhand
    isCountProbation
    isExceedQuota
    isLeaveOnce

  }
}
`

export const mutationDeleteLeaveConfig = `
mutation LeaveConfig_Delete($input: DeleteLeaveConfig!) {
  deleteLeaveConfig(input: $input) {
    id
  }
}
`

export const mutationCreateOvertimeConfig = `
mutation CreateOvertimeConfig($input: CreateOvertimeConfigInput!) {
  createOvertimeConfig(input: $input) {
    overtimeMonthStatus
    overtimeMonth
    overtimeDayStatus
    overtimeDay
    overtimeBeforeMinute
    overtimeAfterMinute
    overtimeBreakBeforeMinute
    overtimeBreakAfterMinute
    overtimeMultiplyType
    overtimeRoundType
    overtimeRoundHour
    overtimeRoundFloor
  }
}
`

export const mutationCreateCompanyDetail = `
mutation CreateCompanyDetail($input: CreateCompanyInput!) {
  createCompanyDetail(input: $input) {

    companyProfileImage
    name
    taxID
    status
    companyAddress {
      addressNumber
      group
      optional
      province
      subDistrict
      district
      zipCode
    }
    bankAccountInformation {
      bankPayment
      bankAccountId
      bankReferenceCompanyName
    }
    companyContactInformation {
      tel
    }
    
  }
}
`

export const mutationCreateDiligent = `
mutation CreateDiligent($input: CreateDiligentInput!) {
  createDiligent(input: $input) {
    diligentPerMonth
    diligentType
    incrementPerRound
    maxIncrementRound
    extraMoney
    monthStart
    monthEnd
    monthPayment
    status
  }
}
`

export const mutationCreateOtherIncomeExpenseConfig = `
mutation CreateOtherIncomeExpenseConfig($input: CreateOtherIncomeOrExpenseConfigInput!) {
  createOtherIncomeOrExpenseConfig(input: $input) {
    id
    name
    incomeOrExpense
    status
    incomeExpenseType
    variableOrFixed
    roundType
    income40Type
  }
}
`

export const mutationUpdateOtherIncomeExpenseConfig = `
mutation updateInEx($input: UpdateOtherIncomeOrExpenseConfigInput!) {
  updateOtherIncomeOrExpenseConfig(input: $input) {
    id
    name
    incomeOrExpense
    status
    incomeExpenseType
    variableOrFixed
    roundType
    income40Type
  }
}
`

export const mutationCreateTaxConfig = `
mutation CreateTaxConfig($input: CreateTaxConfigInput!) {
  createTaxConfig(input: $input) {
    isBaseSalary
    income
    expense
    welfare
    fund
    auto
    overtime
  }
}
`

export const mutationCreateWelfareConfig = `
mutation createWelfare($input: CreateWelfareConfig!) {
  createWelfareConfig(input: $input) {
    welfareID
    name
    isActive
    income40Type
    roundType
    isCountProbation
    isCompound
    welfareCompoundType
    maxWelfareCompound
    isExceedQuota
    isShare
    employeeAllowSexList
    employeeAllowTypeList
    welfareQuotaList {
      workMonthExperience
      quota
      quotaUOM
    }
  }
}
`

export const mutationUpdateWelfareConfig = `
mutation updateWelfare($input: UpdateWelfareConfig!) {
  updateWelfareConfig(input: $input) {
    welfareID
    name
    isActive
    income40Type
    roundType
    employeeAllowSexList
    employeeAllowTypeList
    welfareQuotaList {
      workMonthExperience
      quota
      quotaUOM
    }
    isCountProbation
    isCompound
    isExceedQuota
    isShare
  }
}`

export const mutationDeleteWelfareConfig = `
mutation deleteWelfare($input: DeleteWelfareConfig!) {
  deleteWelfareConfig(input: $input) {
    welfareID
  }
}
`

export const mutationCreateProvidentFundConfig = `
mutation Mutation($input: CreateProvidentFundConfigInput!) {
  createProvidentFundConfig(input: $input) {
    isBaseSalary
    qualifyFund
    income
    expense
    welfare
    fund
    auto
    overtime
  }
}
`

export const mutationPutSocialSecurityConfig = `
mutation PutSocialSecurityConfig($input: PutSocialSecurityConfigInput!) {
  putSocialSecurityConfig(input: $input) {
    status
    employeePercent
    employerPercent
    isBaseSalary
    income
    expense
    welfare
    fund
    auto
    overtime
    dependencyList
  }
}
`

export const mutationCreateWithdrawalConfig = `
mutation Mutation($input: CreateWithdrawalConfig!) {
  createWithdrawalConfig(input: $input) {
    withdrawalID
    name
    isActive
    roundType
    employeeAllowSexList
    employeeAllowTypeList
    withdrawalQuotaList {
      workMonthExperience
      quota
      quotaUOM
    }
    isCountProbation
    isShare
  }
}
`

export const mutationUpdateWithdrawalConfig = `
mutation Mutation($input: UpdateWithdrawalConfig!) {
  updateWithdrawalConfig(input: $input) {
    withdrawalID
    name
    isActive
    roundType
    employeeAllowSexList
    employeeAllowTypeList
    withdrawalQuotaList {
      workMonthExperience
      quota
      quotaUOM
    }
    isCountProbation
    isShare
  }
}`

export const mutationDeleteWithdrawalConfig = `
mutation DeleteWithdrawalConfig($input: DeleteWithdrawalConfig!) {
  deleteWithdrawalConfig(input: $input) {
    withdrawalID
    name
  }
}`

export const mutationPutPayrollConfig = `
mutation PutPayrollConfig($input: PutPayrollConfigInput!) {
  putPayrollConfig(input: $input) {
    periodStart
    periodCount
    period1Day
    period2Day
    period3Day
    workingDayCount
    workingDayCountNewEmployee
    resetLeaveDay
    workingHourDivisor
    roundType
    fulltimeDayPaidHoliday
  }
}`
export const mutationPDF = gql`
  mutation Mutation($input: DownloadOptional!) {
    downloadPDFDocument(input: $input)
  }
`
export const mutationExcel = gql`
  mutation _2_EmpNew($input: DownloadOptional!) {
    downloadEXCELDocument(input: $input)
  }
`

export const mutationPutTimeAttendanceConfig = `
mutation PutTimeAttendanceConfig($input: PutTimeAttendanceConfigInput!) {
  putTimeAttendanceConfig(input: $input) {
    dayShift
    nightShift
    countType
    noneFingerPrint
    roundType
    timeBreak
    timeBreakFirst
    isWorkInEarly
    maxWorkInEarlyMinute
    isWorkInEarlyPaid
    workInEarlyPaidRate
    workInEarlyPaidType
    isWorkInEarlyDiligent
    isWorkInLate
    maxWorkInLateMinute
    isWorkInLateDiligent
    maxWorkInLateType
    isWorkInLateDeduct
    workInLateDeductRate
    workInLateDeductType
    isBreakOvertime
    maxBreakOvertimeMinute
    isBreakOvertimeDiligent
    breakOvertimeType
    isBreakOvertimeDeduct
    breakOvertimeDeductRate
    breakOvertimeDedcutType
    isBreakEarly
    maxBreakEarlyMinute
    isBreakEarlyDiligent
    isBreakEarlyPaid
    breakEarlyPaidRate
    breakEarlyPaidType
    isWorkOutEarly
    isWorkOutEarlyDiligent
    workOutEarlyType
    maxWorkOutEarlyMinute
    isWorkOutEarlyDeduct
    workOutEarlyDeductRate
    workOutEarlyDeductType
    isWorkOutLate
    isWorkOutLateDiligent
    maxWorkOutLateMinute
    workOutLateQuotaType
    isWorkOutLatePaid
    isWorkOutLatePaidRate
    isWorkOutLatePaidType
  }
}`

export const MUTATION_CREATE_PACKAGE = gql`
  mutation createPackageGroup($input: CreatePackageGroupInput!) {
    createPackageGroup(input: $input) {
      packageGroupID
      name
      leavePackageList {
        leaveConfigID
        customQuotaList {
          workMonthExperience
          isCustomQuota
          customQuota
        }
      }
      welfarePackageList {
        welfareConfigID
        customQuotaList {
          workMonthExperience
          isCustomQuota
          customQuota
          customQuotaUOM
        }
      }
      employeeList {
        items {
          id
          getEmployee {
            id
            positionID
            departmentID
            fullName
            middleName
            lastName
            profileImage
          }
        }
      }
    }
  }
`

export const MUTATION_UPDATE_PACKAGE = gql`
  mutation updatePackageGroup($input: UpdatePackageGroupInput!) {
    updatePackageGroup(input: $input) {
      packageGroupID
      name
      leavePackageList {
        leaveConfigID
        customQuotaList {
          workMonthExperience
          isCustomQuota
          customQuota
        }
      }
      welfarePackageList {
        welfareConfigID
        customQuotaList {
          workMonthExperience
          isCustomQuota
          customQuota
          customQuotaUOM
        }
      }
      employeeCount
      employeeList {
        items {
          id
        }
      }
    }
  }
`

export const MUTATION_DELETE_PACKAGE = gql`
  mutation Mutation($packageGroupId: ID!) {
    deletePackageGroup(packageGroupID: $packageGroupId) {
      packageGroupID
      name
    }
  }
`

export const MUTATION_APPROVE_TIMEATTENDANCE = `
mutation Mutation($input: ApproveTimeAttendanceReportInput!) {
  approveTimeAttendanceReport(input: $input) {
    employeeID
    date
    status
  }
}
`
