//lib
import React from 'react'
import { useFormikContext } from 'formik'

//Include Project
import { toTime } from 'src/utils/common'
import { TooltipArrow } from 'src/component'
import styles from './index.module.scss'

//Images
import TimeLogSVG from '../../image/TimeLog.svg'

const ScanCardAttendanceReportView: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()
  const TooltipTimeLog = () => {
    return (
      <div className={styles.IconTime}>
        <img src={TimeLogSVG} alt="TimeLogSVG" />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapperInput}>
        <div className={styles.gropHead}>
          <p className={styles.TextHead}>เวลาจริงจากเครื่องสแกนนิ้ว</p>
          <TooltipArrow
            child={TooltipTimeLog()}
            label={
              values.attendance?.timeAttendanceLog?.scanOut.length > 0
                ? `เวลาจริงจากเครื่องสแกนนิ้ว ${toTime(values.attendance.timeAttendanceLog?.scanIn)} น. - ${toTime(
                    values.attendance.timeAttendanceLog?.scanOut,
                  )} น.`
                : 'ไม่มีข้อมูลการสแกนนิ้ว'
            }
          />
        </div>
        {values.report.scanIn || values.report.scanOut ? (
          <section className={styles.inputBox}>
            <p className={styles.descriptionText}>ช่วงเวลา</p>
            <div className={styles.groupBox}>
              <p>
                {values.report.scanIn} - {values.report.scanOut}
              </p>
            </div>
          </section>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default ScanCardAttendanceReportView
