//lib
import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { Checkbox } from '@mui/material'

//Include Project
import {
  InputDateComponentCustom,
  InputDateComponentCustome,
  InputNumberComponentCustom,
  InputSelectComponentCustom,
  InputTextComponentCustom,
} from '../../component/InputsFormik'
import { ELevel } from 'src/utils/generated'
import styles from './index.module.scss'

//Images
import deleteSVG from '../../image/deleteRedIcon.svg'

interface ITable {
  Header: string[]
}
export const TableEducation: React.FC<ITable> = (props: ITable): JSX.Element => {
  const [edit, setEdite] = useState(false)
  const { values, setFieldValue } = useFormikContext<any>()

  const changDataEdit = () => {
    setEdite(!edit)
  }

  const addRowData = () => {
    setFieldValue('formInput.historyEducation', [
      ...values.formInput.historyEducation,
      { educationLevel: '', educationName: '', majorName: '', startedYear: '', endedYear: '', grade: '' },
    ])
  }

  return (
    <section className={styles.groupTable}>
      <p className={styles.textHead}>ประวัติการศึกษา</p>
      <div className={styles.tableCard}>
        <p onClick={() => changDataEdit()} className={styles.LinkText}>
          {!edit ? 'แก้ไขประวัติการศึกษา' : 'เสร็จสิ้น'}
        </p>
        <table className="tableRounded">
          <tr>
            {props.Header.map((item: any, index: number) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
          {values.formInput.historyEducation?.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <InputTextComponentCustom
                  _name={`formInput.historyEducation[${index}].educationLevel`}
                  _value={item.educationLevel}
                />
              </td>
              <td>
                <InputTextComponentCustom
                  _name={`formInput.historyEducation[${index}].educationName`}
                  _value={item.educationName}
                />
              </td>
              <td>
                <InputTextComponentCustom
                  _name={`formInput.historyEducation[${index}].majorName`}
                  _value={item.majorName}
                />
              </td>
              <td>
                <InputDateComponentCustom
                  _name={`formInput.historyEducation[${index}].startedYear`}
                  _value={item.startedYear}
                  _label="เลือกวันที่"
                />
              </td>
              <td>
                <InputDateComponentCustom
                  _name={`formInput.historyEducation[${index}].endedYear`}
                  _value={item.endedYear}
                  _label="เลือกวันที่"
                />
              </td>
              <td>
                <InputTextComponentCustom _name={`formInput.historyEducation[${index}].grade`} _value={item.grade} />
              </td>
              <td>
                {edit ? (
                  <img
                    src={deleteSVG}
                    alt=""
                    className={styles.Icon}
                    onClick={() => {
                      values.historyEducation.splice(index, 1)
                      setFieldValue('historyEducation', values.historyEducation)
                    }}
                  />
                ) : (
                  ''
                )}
              </td>
            </tr>
          ))}
        </table>

        <p
          className={styles.LinkText}
          onClick={() => {
            addRowData()
          }}
        >
          เพิ่มแถวตาราง
        </p>
      </div>
    </section>
  )
}

export const TableExperian: React.FC<ITable> = (props: ITable): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [edit, setEdite] = useState(false)

  const changDataEdit = () => {
    setEdite(!edit)
  }

  const addRowData = () => {
    setFieldValue('formInput.historyWork', [
      ...values.formInput.historyWork,
      {
        workName: '',
        startedAt: null,
        endedAt: null,
        positionName: '',
        descPosition: '',
        referral: '',
        tel: '',
        salary: 0,
        leaveReason: '',
      },
    ])
  }

  return (
    <section className={styles.groupTable}>
      <p className={styles.textHead}>ประวัติการทำงาน</p>
      <div className={styles.tableCard}>
        <p className={styles.LinkText} onClick={() => changDataEdit()}>
          {!edit ? 'แก้ไขประวัติการทำงาน' : 'เสร็จสิ้น'}
        </p>
        <table className="tableRounded">
          <tr>
            {props.Header.map((item: any, index: number) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
          {values.formInput.historyWork?.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <InputTextComponentCustom _name={`formInput.historyWork[${index}].workName`} _value={item.workName} />
              </td>
              <td>
                <InputDateComponentCustome
                  _name={`formInput.historyWork[${index}].startedAt`}
                  _value={item.startedAt}
                  _label="เลือกวันที่"
                />
              </td>
              <td>
                <InputDateComponentCustome
                  _name={`formInput.historyWork[${index}].endedAt`}
                  _value={item.endedAt}
                  _label="เลือกวันที่"
                />
              </td>
              <td>
                <InputTextComponentCustom
                  _name={`formInput.historyWork[${index}].positionName`}
                  _value={item.positionName}
                />
              </td>
              <td>
                <InputNumberComponentCustom
                  _name={`formInput.historyWork[${index}].descPosition`}
                  _value={item.descPosition}
                />
              </td>
              <td>
                <InputNumberComponentCustom _name={`formInput.historyWork[${index}].salary`} _value={item.salary} />
              </td>
              <td>
                <InputTextComponentCustom _name={`formInput.historyWork[${index}].referral`} _value={item.referral} />
              </td>
              <td>
                <InputTextComponentCustom _name={`formInput.historyWork[${index}].tel`} _value={item.tel} />
              </td>
              <td>
                <InputTextComponentCustom
                  _name={`formInput.historyWork[${index}].leaveReason`}
                  _value={item.leaveReason}
                />
              </td>
              <td>
                {edit ? (
                  <img
                    src={deleteSVG}
                    alt=""
                    className={styles.Icon}
                    onClick={() => {
                      values.historyWork.splice(index, 1)
                      setFieldValue('historyWork', values.historyWork)
                    }}
                  />
                ) : (
                  ''
                )}
              </td>
            </tr>
          ))}
        </table>

        <p
          className={styles.LinkText}
          onClick={() => {
            addRowData()
          }}
        >
          เพิ่มแถวตาราง
        </p>
      </div>
    </section>
  )
}

export const TableTraining: React.FC<ITable> = (props: ITable): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [edit, setEdite] = useState(false)

  const changDataEdit = () => {
    setEdite(!edit)
  }

  const addRowData = () => {
    setFieldValue('formInput.historyTraining', [
      ...values.formInput.historyTraining,
      {
        workName: '',
        startedAt: null,
        endedAt: null,
        positionName: '',
        descPosition: '',
        referral: '',
        tel: '',
        salary: 0,
        leaveReason: '',
      },
    ])
  }
  return (
    <section className={styles.groupTable}>
      <p className={styles.textHead}>ประวัติการฝึกอบรม</p>
      <div className={styles.tableCard}>
        <p className={styles.LinkText} onClick={() => changDataEdit()}>
          {!edit ? 'แก้ไขประวัติการฝึกอบรม' : 'เสร็จสิ้น'}
        </p>

        <table className="tableRounded">
          <tr>
            {props.Header.map((item: any, index: number) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
          {values.formInput.historyTraining?.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <InputTextComponentCustom
                  _name={`formInput.historyTraining[${index}].trainingName`}
                  _value={item.trainingName}
                />
              </td>
              <td>
                <InputTextComponentCustom
                  _name={`formInput.historyTraining[${index}].courseName`}
                  _value={item.courseName}
                />
              </td>
              <td>
                <InputDateComponentCustome
                  _name={`formInput.historyTraining[${index}].date`}
                  _value={item.date}
                  _label="เลือกวันที่"
                />
              </td>
              <td>
                <InputTextComponentCustom _name={`formInput.historyTraining[${index}].period`} _value={item.period} />
              </td>

              <td>
                {edit ? (
                  <img
                    src={deleteSVG}
                    alt=""
                    className={styles.Icon}
                    onClick={() => {
                      values.historyTraining.splice(index, 1)
                      setFieldValue('historyTraining', values.historyTraining)
                    }}
                  />
                ) : (
                  ''
                )}
              </td>
            </tr>
          ))}
        </table>

        <p
          className={styles.LinkText}
          onClick={() => {
            addRowData()
          }}
        >
          เพิ่มแถวตาราง
        </p>
      </div>
    </section>
  )
}

export const TableLanguage: React.FC<ITable> = (props: ITable): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <section className={styles.groupTable}>
      <p className={styles.textHead}>ภาษา</p>
      <div className={styles.tableCard}>
        <table className="tableRounded">
          <tr>
            {props.Header.map((item: any, index: number) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
          {values.formInput.language?.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <p>{item.languageName}</p>
              </td>
              <td>
                <InputSelectComponentCustom
                  _name={`formInput.language[${index}].speak`}
                  _label="เลือกรายการ"
                  _menuList={selectLevel}
                  _value={item?.speak}
                />
              </td>
              <td>
                <InputSelectComponentCustom
                  _name={`formInput.language[${index}].write`}
                  _label="เลือกรายการ"
                  _menuList={selectLevel}
                  _value={item?.write}
                />
              </td>
              <td>
                <InputSelectComponentCustom
                  _name={`formInput.language[${index}].read`}
                  _label="เลือกรายการ"
                  _menuList={selectLevel}
                  _value={item?.read}
                />
              </td>
            </tr>
          ))}
        </table>
      </div>
    </section>
  )
}

export const TableMedicalHistory: React.FC<ITable> = (props: ITable): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()

  return (
    <section className={styles.groupTable}>
      <p className={styles.textHead}>ประวัติการป่วยหนักและโรคติดต่อร้ายแรง</p>
      <div className={styles.tableCard}>
        <table className="tableRounded">
          <tr>
            {props.Header.map((item: any, index: number) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
          {values.formInput.historySick?.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <p>{item.sickName}</p>
              </td>

              <td>
                <Checkbox
                  onChange={() => {
                    setFieldValue(`formInput.historySick[${index}].isSick`, true)
                  }}
                  checked={item.isSick === true}
                />
              </td>
              <td>
                <Checkbox
                  onChange={() => {
                    setFieldValue(`formInput.historySick[${index}].isSick`, false)
                  }}
                  checked={item.isSick === false}
                />
              </td>
              <td>
                <InputTextComponentCustom _name={`formInput.historySick[${index}].remark`} _value={item.remark} />
              </td>
            </tr>
          ))}
        </table>
      </div>
    </section>
  )
}
const selectLevel = [
  { label: 'ดีมาก', value: ELevel.Hight },
  { label: 'ปานกลาง', value: ELevel.Medium },
  { label: 'พอใช้', value: ELevel.Low },
]
