import { gql, useLazyQuery } from '@apollo/client'
import React from 'react'
import { flushSync } from 'react-dom'
import { CreateLeaveConfig, LeaveConfig, UpdateLeaveConfig, DeleteLeaveConfig } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationCreateLeaveConfig, mutationUpdateLeaveConfig, mutationDeleteLeaveConfig } from '../xhr/mutation'
import { queryListLeaveConfig } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function useQueryListLeaveList(variables: any, isLazy = false) {
  const [data, setData] = React.useState<LeaveConfig[]>([])
  const [loading, setLoading] = React.useState(true)
  const [_fetch] = useLazyQuery(gql(queryListLeaveConfig))

  const refetch = async (variables: any) => {
    setLoading(true)
    const res = await _fetch({ variables, fetchPolicy: 'network-only' })
    setLoading(false)
    const _data = res.data.listLeaveConfig.items as LeaveConfig[]

    flushSync(() => {
      setData(_data)
      setLoading(false)
    })

    return _data
  }

  React.useEffect(() => {
    if (!isLazy) refetch(variables)
  }, [])
  return { data, loading, refetch }
}
// ----------------------------------------------- Mutation -----------------------------------------------
export function createLeaveConfig(input: CreateLeaveConfig): Promise<LeaveConfig> {
  const variables = { input }
  return gqlResponseType<LeaveConfig>(
    post('/', {
      query: mutationCreateLeaveConfig,
      variables,
    }),
    'createLeaveConfig',
  )
}

export function updateLeaveConfig(input: UpdateLeaveConfig): Promise<LeaveConfig> {
  const variables = { input }
  return gqlResponseType<LeaveConfig>(
    post('/', {
      query: mutationUpdateLeaveConfig,
      variables,
    }),
    'updateLeaveConfig',
  )
}

export function deleteLeaveConfig(input: DeleteLeaveConfig): Promise<LeaveConfig> {
  const variables = { input }
  return gqlResponseType<LeaveConfig>(
    post('/', {
      query: mutationDeleteLeaveConfig,
      variables,
    }),
    'deleteLeaveConfig',
  )
}
