import { useMutation } from '@apollo/client'
import { useMemo } from 'react'
import { LATE_LEAVE_PER_EMP } from 'src/queries/documentExcel.queries'
import useHandleResponseAPI from '../useHandleResponseAPI'

function useExcelReport() {
  const [getLateLeaveExcelDoc, optionGetLateLeaveExcelDoc] = useMutation(LATE_LEAVE_PER_EMP)

  const isLoading = useMemo(() => {
    return optionGetLateLeaveExcelDoc.loading
  }, [optionGetLateLeaveExcelDoc.loading])

  const errMsg = useMemo(() => {
    if (optionGetLateLeaveExcelDoc.error) return JSON.stringify(optionGetLateLeaveExcelDoc.error)
    else return ''
  }, [optionGetLateLeaveExcelDoc.error])

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg,
  })

  return { getLateLeaveExcelDoc, data: optionGetLateLeaveExcelDoc?.data }
}
export default useExcelReport
