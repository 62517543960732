import { useFormikContext } from 'formik'
import React from 'react'

//Images
import styles from './index.module.scss'
import { handleDecimalsOnValue } from 'src/utils/common'
import { InputCurrencyFormat } from 'src/component/InputCurrency'

interface Props {
  totalOtherDiscount: any
}
const EmployeeTaxDiscountOther: React.FC<Props> = (props: Props): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>ค่าลดหย่อน ดูแลผู้พิการ :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.handicappedDiscount" />
              {/* 
                    <InputNumberUnitComponent
                      _name="employeeTax.handicappedDiscount"
                      _value={values.employeeTax.handicappedDiscount}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.handicappedDiscount')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 120,000 บาท</p>
        </div>

        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>เงินสะสมประกันสังคม :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.socialSecurityDeposit" />

              {/*                     
                    <InputNumberUnitComponent
                      _name="employeeTax.socialSecurityDeposit"
                      _value={values.employeeTax.socialSecurityDeposit}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.socialSecurityDeposit')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>{`ไม่เกิน 9,000 บาท`}</p>
        </div>

        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>ค่าลดหย่อนกองทุนสำรองเลี้ยงชีพ :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.funcDeposit" />
              {/* 
                    <InputNumberUnitComponent
                      _name="employeeTax.funcDeposit"
                      _value={values.employeeTax.funcDeposit}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.funcDeposit')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 500,000 บาท</p>
        </div>

        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>ค่าดอกเบี้ยกู้บ้าน :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.homeLoanIncrease" />

              {/* <InputNumberUnitComponent
                      _name="employeeTax.homeLoanIncrease"
                      _value={values.employeeTax.homeLoanIncrease}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.homeLoanIncrease')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 100,000 บาท</p>
        </div>

        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>ดอกเบี้ยกู้ยืมเงิน :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.loanIncrease" />
              {/* 
                    <InputNumberUnitComponent
                      _name="employeeTax.loanIncrease"
                      _value={values.employeeTax.loanIncrease}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.loanIncrease')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 100,000 บาท</p>
        </div>

        <div>
          <div className={styles.flexBoxTitle}>
            <p className={styles.text}>กองทุนเพื่อการออม :</p>
            <div className={styles.wrapperInput}>
              <InputCurrencyFormat name="employeeTax.loanIncrease" />
              {/* 
                    <InputNumberUnitComponent
                      _name="employeeTax.loanIncrease"
                      _value={values.employeeTax.loanIncrease}
                      _unit="บาท"
                      _helperText={`${_.get(errors, 'employeeTax.loanIncrease')}`}
                    /> */}
            </div>
          </div>
          <p className={styles.textSubtitle}>ไม่เกิน 100,000 บาท</p>
        </div>

        <div className={styles.bottomGroup}>
          <p className={styles.text}>รวมค่าลดหย่อนอื่น ๆ : </p>
          <div className={styles.wrapperText}>
            <p className={styles.textResult}>{handleDecimalsOnValue(props.totalOtherDiscount)}</p>
            <p className={styles.text}>บาท</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeTaxDiscountOther
