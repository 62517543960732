import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonComponent, InputTextComponent } from '../../component'
import allAction from '../../state/actions'
import styles from './index.module.scss'
// import { useFormikContext } from 'formik'

//Api
import { createPosition, queryGetPosition, updatePosition } from '../../adapter/xhr'

const CreatePosition: React.FC = (): JSX.Element => {
  // const { setFieldValue } = useFormikContext<any>()
  const formikRef: any = useRef(null)
  const [mode, setMode] = useState<'create' | 'update'>('create')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams<any>()
  console.log('formikRef', formikRef)

  useEffect(() => {
    if (id) {
      // console.log('IDDD', id)
      setMode('update')
      const getPositionValiable: any = {
        getPositionId: id,
      }

      queryGetPosition(getPositionValiable).then((res: any) => {
        formikRef.current.setFieldValue('positionName', res.data.data.getPosition.positionName)
        formikRef.current.setFieldValue('level', res.data.data.getPosition.positionLevel)
        formikRef.current.setFieldValue('description', res.data.data.getPosition.remark)
        // setGetPosition(res.data.data.getPosition)
        // setPositionName(res.data.data.getPosition.positionName)
      })
    } else {
      setMode('create')
    }
  }, [])
  console.log('mode', mode)
  //todo setDefault value mode >> Update

  return (
    <div className={styles.container}>
      <div className={styles.navtop}>
        <h1 className={styles.title}>{mode === 'create' ? 'เพิ่มตำแหน่งงาน' : 'แก้ไขตำแหน่งงาน'}</h1>
      </div>
      <div className={styles.formInput}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            positionName: '',
            level: '',
            description: '',
          }}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2))
              actions.setSubmitting(false)
            }, 1000)
            if (mode === 'create') {
              dispatch(
                allAction.modalConfirmAction.setOpenModalConfirm(
                  'ยืนยันการทำรายการ',
                  'คุณต้องการยืนยันการทำรายการ',
                  'เพิ่มตำแหน่งงาน?',
                  'ใช่, ทำรายการ',
                  () => {
                    const createPositionValiable = {
                      input: {
                        positionName: values.positionName,
                        positionLevel: values.level,
                        remark: values.description,
                      },
                    }
                    createPosition(createPositionValiable).then((res: any) => {
                      console.log(' res createPosition', res.data.data)
                    })
                    dispatch(allAction.modalAction.setOpenModalAlertStatus('เพิ่มรายการเรียบร้อย', '', 'success'))
                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                  },
                ),
              )
            } else {
              dispatch(
                allAction.modalConfirmAction.setOpenModalConfirm(
                  'ยืนยันการทำรายการ',
                  'คุณต้องการยืนยันการทำรายการ',
                  'แก้ไขตำแหน่งงาน?',
                  'ใช่, ทำรายการ',
                  () => {
                    const updatePositionValiable = {
                      input: {
                        id: id,
                        positionName: values.positionName,
                        positionLevel: values.level,
                        remark: values.description,
                      },
                    }
                    updatePosition(updatePositionValiable).then((res: any) => {
                      console.log('res updatePosition', res.data.data)
                    })
                    dispatch(allAction.modalAction.setOpenModalAlertStatus('แก้ไขรายการเรียบร้อย', '', 'success'))
                    dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                  },
                ),
              )
            }
          }}
        >
          {(props: any) => (
            <form onSubmit={props.handleSubmit}>
              {console.log(' value', props.values)}
              <div className={styles.groupForm}>
                <InputTextComponent _name="positionName" _label="ตำแหน่งงาน" />
                <InputTextComponent _name="level" _label="ระดับ" />
                <InputTextComponent _name="description" _label="รายละเอียด" />
              </div>

              <div className={styles.formButton}>
                <div className={styles.btCancle}>
                  <ButtonComponent
                    _colorBG="white"
                    _colorText="red"
                    _text="Cancle"
                    _variant="contained"
                    _type="button"
                    _functionOnClick={() => navigate(-1)}
                  />
                </div>
                <div className={styles.btAdd}>
                  <ButtonComponent
                    _colorBG="blue"
                    _colorText="white"
                    _text={id ? 'Edit' : 'Add'}
                    _variant="contained"
                    _type="submit"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default CreatePosition
