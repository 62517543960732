import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Tooltip } from '@mui/material'
import styles from './index.module.scss'
//svg
// import editIcon from '../../image/edit.svg'
// import deleteIcon from '../../image/del.svg'
// import NoteIcon from '../../image/note.svg'
import quesIcon from '../../image/question.svg'
// import { useDispatch } from 'react-redux'
// import Accordion from '@mui/material/Accordion'
// import AccordionSummary from '@mui/material/AccordionSummary'
// import AccordionDetails from '@mui/material/AccordionDetails'
// import Popper from '@mui/material/Popper'
// import closeIcon from '../../image/close.svg'
// import * as yup from 'yup'
// import NoteDataIcon from '../../image/notedata.svg'
// import { ButtonComponent, InputTextComponentCustom } from '../../component'
// import { useRef } from 'react'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// import { Formik } from 'formik'
// import { QueryGetAttendance, UpdateAttendanceReport } from '../../adapter/xhr'
// import allAction from '../../state/actions'

export const TableAttend: React.FC<any> = (props: any): JSX.Element => {
  // const [open, setOpen] = React.useState(false)
  // const [selectdate, setSelectDate] = React.useState<any>()
  // const [openmodal, setOpenModal] = React.useState(false)
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const [modalIndex, setModalIndex] = React.useState<any>()
  // const [modalRowIndex, setModalRowIndex] = React.useState<any>()
  // const [employeeId, setEmployeeId] = React.useState<any>()
  // const [attendance, setAttendance] = React.useState({})

  // const [remarkIndex, setRemarkIndex] = React.useState<number>(0)

  // const handleClickPopper = (event: any) => {
  //   setAnchorEl(event.currentTarget)
  //   setOpen((previousOpen) => !previousOpen)
  // }

  // const handleClose = (index: number) => {
  //   setAnchorEl(null)
  //   setRemarkIndex(index - 1)
  //   // setModalIndex(-1)
  // }

  const EDayType: any = {
    WORKDAY: 'วันทำงาน',
    WEEKEND: 'วันหยุดสุดสัปดาห์',
    HOLIDAY: 'วันหยุดประจำปี',
  }
  const MockUpData = [
    {
      name: 'หาญ วรรณเกต',
      depart: 'Accounting',
      shift: 'shif 1',
      shiftTime: '08:00 - 17:00',
      scanIn: '08:00',
      scanOut: '17:20',
      workTime: '09:00',
      OT: '03:00',
      timelate: 0,
      outLate: 20,
    },
    {
      name: 'MYO NGE',
      depart: 'Accounting',

      shift: 'shif 1',
      shiftTime: '08:00 - 17:00',
      scanIn: '08:00',
      scanOut: '17:20',
      workTime: '09:00',
      OT: '03:00',
      timelate: 0,
      outLate: 20,
    },
    {
      name: 'Michel Wang',
      depart: 'Accounting',
      shift: 'shif 1',
      shiftTime: '08:00 - 17:00',
      scanIn: '08:00',
      scanOut: '17:00',
      workTime: '09:00',
      OT: '03:00',
      timelate: 0,
      outLate: 0,
    },
    {
      name: 'Anna Lanla',
      depart: 'Accounting',
      shift: 'shif 1',
      shiftTime: '08:00 - 17:00',
      scanIn: '08:10',
      scanOut: '17:00',
      workTime: '09:00',
      OT: '03:00',
      timelate: 10,
      outLate: 0,
    },
  ]
  return (
    <>
      {/* {' '}
      {props.listAttend ? ( */}
      <div className={styles.tableShow}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <p>ชื่อพนักงาน/แผนก</p>
              </TableCell>
              <TableCell align="left">
                <p>กะการทำงาน</p>
              </TableCell>
              <TableCell align="center">
                <p>เวลาทำงานจริง</p>
              </TableCell>
              <TableCell align="center">
                <p>ชั่วโมงการทำงานจริง</p>
              </TableCell>
              <TableCell align="center">
                <p>การทำงานล่วงเวลา</p>
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {MockUpData.map((itemRow: any, RowIndex: any) => (
              <TableRow key={RowIndex}>
                <TableCell align="center">
                  <p>{itemRow.name}</p>
                  <p className={styles.sub}>{itemRow.depart}</p>
                </TableCell>
                <TableCell align="left">
                  {itemRow.shift && (
                    <Tooltip title={`${itemRow.shiftTime}`} placement="top-start" arrow={true}>
                      <div className={styles.textShift}>
                        <p> {itemRow.shift}</p> <img src={quesIcon} alt="" />
                      </div>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center">
                  <div>
                    {itemRow.scanIn && (
                      <div className={styles.group}>
                        <div className={itemRow.timelate > 0 ? styles.textScanLate : styles.textScan}>
                          <p>{itemRow.scanIn}</p>
                          <div className={styles.groupPill}>
                            <section className={styles.pillDiffEarly}>
                              {itemRow.timelate > 0 ? <p>มาสาย</p> : <p>มาก่อน</p>}
                            </section>
                            <p className={styles.textIn}>+{itemRow.timelate}</p>
                          </div>
                        </div>
                        <div className={itemRow.outLate < 0 ? styles.textScanLate : styles.textScanOut}>
                          <p>{itemRow.scanOut}</p>
                          <div className={styles.groupPill}>
                            <section className={styles.pillDiffEarly}>
                              {itemRow.outLate < 0 ? <p>ออกก่อน</p> : <p>ออกหลัง</p>}
                            </section>
                            <p className={styles.textIn}>+{itemRow.outLate}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <p>{itemRow.workTime} ชม.</p>
                </TableCell>
                <TableCell align="center">
                  <p>{itemRow.OT} ชม .</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
