import { useFormikContext } from 'formik'
import React, { useEffect, useMemo } from 'react'

//Images
import styles from './index.module.scss'
import { handleDecimalsOnValue } from 'src/utils/common'
import EmployeeTaxIncome from '../EmployeeTaxIncome'
import EmployeeTaxExpenses from '../EmployeeTaxExpenses'
import EmployeeTaxParentsDiscount from '../EmployeeTaxParentsDiscount'
import EmployeeTaxDiscountOther from '../EmployeeTaxDiscountOther'
import EmployeeTaxDonation from '../EmployeeTaxDonation'
import EmployeeTaxInsurance from '../EmployeeTaxInsurance'
import EmployeeTaxResult from '../EmployeeTaxResult'

const EmployeeTax: React.FC = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()

  const totalIncomePerYear = useMemo(() => {
    return parseFloat(values.employeeTax.salary) + parseFloat(values.employeeTax.otherIncomePerYear)
  }, [values.employeeTax.salary, values.employeeTax.otherIncomePerYear])

  useEffect(() => {
    setFieldValue(`employeeTax.personalDiscount`, 60000)
  }, [])

  useEffect(() => {
    if (parseFloat(values.employeeTax.salary) / 2 > 100000) {
      setFieldValue(`employeeTax.deductExpense`, 100000)
    } else {
      setFieldValue(`employeeTax.deductExpense`, parseFloat(values.employeeTax.salary) / 2)
    }
  }, [values.employeeTax.salary])

  const summaryParentDiscount = (checked: boolean) => {
    if (checked) {
      setFieldValue('employeeTax.parentDiscount', values.employeeTax.parentDiscount + 30000)
    } else {
      setFieldValue('employeeTax.parentDiscount', values.employeeTax.parentDiscount - 30000)
    }
  }

  const summarySpouseParentDiscount = (checked: boolean) => {
    if (checked) {
      setFieldValue('employeeTax.parentSpouseDiscountCount', values.employeeTax.parentSpouseDiscountCount + 30000)
    } else {
      setFieldValue('employeeTax.parentSpouseDiscountCount', values.employeeTax.parentSpouseDiscountCount - 30000)
    }
  }

  const totalDiscountFamily = useMemo(() => {
    return (
      parseFloat(values.employeeTax.personalDiscount) +
      parseFloat(values.employeeTax.deductExpense) +
      parseFloat(values.employeeTax.spouseDiscount) +
      parseFloat(values.employeeTax.childDiscount)
    )
  }, [
    values.employeeTax.personalDiscount,
    values.employeeTax.deductExpense,
    values.employeeTax.spouseDiscount,
    values.employeeTax.childDiscount,
  ])

  const totalOtherDiscount = useMemo(() => {
    return (
      parseFloat(values.employeeTax.socialSecurityDeposit) +
      parseFloat(values.employeeTax.handicappedDiscount) +
      parseFloat(values.employeeTax.funcDeposit) +
      parseFloat(values.employeeTax.homeLoanIncrease) +
      parseFloat(values.employeeTax.loanIncrease)
    )
  }, [
    values.employeeTax.socialSecurityDeposit,
    values.employeeTax.handicappedDiscount,
    values.employeeTax.funcDeposit,
    values.employeeTax.homeLoanIncrease,
    values.employeeTax.loanIncrease,
  ])

  const totalInsuranceDiscount = useMemo(() => {
    return (
      parseFloat(values.employeeTax.lifePensionInsurance) +
      parseFloat(values.employeeTax.lifeInsurance) +
      parseFloat(values.employeeTax.familyHealthInsurance)
    )
  }, [
    values.employeeTax.lifePensionInsurance,
    values.employeeTax.lifeInsurance,
    values.employeeTax.familyHealthInsurance,
  ])

  //<------------------ Calculate Discount ------------------>
  const sumDiscount = useMemo(() => {
    return (
      totalDiscountFamily +
      totalOtherDiscount +
      totalInsuranceDiscount +
      parseFloat(values.employeeTax.educationDonate) +
      parseFloat(values.employeeTax.parentSpouseDiscountCount) +
      parseFloat(values.employeeTax.parentSpouseDiscountCount)
    )
  }, [
    totalDiscountFamily,
    totalOtherDiscount,
    totalInsuranceDiscount,
    values.employeeTax.educationDonate,
    values.employeeTax.parentSpouseDiscountCount,
    values.employeeTax.parentSpouseDiscountCount,
  ])
  //<------------------ Calculate Discount ------------------>

  //<------------------ Calculate net income (total income - discount) ------------------>
  const sumNetIncome = useMemo(() => {
    return totalIncomePerYear - sumDiscount
  }, [totalIncomePerYear, sumDiscount])
  //<------------------ Calculate net income (total income - discount) ------------------>

  const calculateTaxPerMonth = () => {
    return handleDecimalsOnValue(values.employeeTax.totalTaxExpense / 12)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>9. ลดหย่อนภาษี</div>
      <div className={styles.card}>
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <EmployeeTaxIncome totalIncomePerYear={totalIncomePerYear} />
            <EmployeeTaxExpenses totalDiscountFamily={totalDiscountFamily} />
            <EmployeeTaxParentsDiscount
              summaryParentDiscount={summaryParentDiscount}
              summarySpouseParentDiscount={summarySpouseParentDiscount}
            />
            <EmployeeTaxDiscountOther totalOtherDiscount={totalOtherDiscount} />
            <EmployeeTaxDonation />
            <EmployeeTaxInsurance totalInsuranceDiscount={totalInsuranceDiscount} />
            <EmployeeTaxResult
              totalIncomePerYear={totalIncomePerYear}
              sumDiscount={sumDiscount}
              sumNetIncome={sumNetIncome}
              calculateTaxPerMonth={calculateTaxPerMonth}
            />
          </div>
        </section>
      </div>
    </div>
  )
}

export default EmployeeTax
