import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { mutationPDF } from 'src/adapter/xhr/mutation'
import { ReportFilter } from 'src/component'
import { useExcelReport } from 'src/hooks'
import { dateFmtShort } from 'src/utils/common'
import { EDocumentType } from 'src/utils/generated'
// import Swal from 'sweetalert2'
import styles from '../../pages/index.module.scss'

const AttendanceSummaryReportPage: React.FC = (): JSX.Element => {
  const [showfile, setShowfile] = useState<boolean>(false)
  const [fileData, setFileData] = useState()
  const [typefile, setTypeFile] = useState<string>()

  const [mutationData, { loading }] = useMutation(mutationPDF)
  const { getLateLeaveExcelDoc, data: resLateLeaveExcelDoc } = useExcelReport()

  return (
    <div className={styles.container}>
      <section className={styles.cardFilter}>
        <div className={styles.wrapperCard}>
          <Formik
            initialValues={{
              departmentID: [],
              employeeID: [],
              documentType: 'EXCEL',
              startedAt: dateFmtShort(new Date()),
              checkDepartment: true,
              employeeall: true,
              endedAt: dateFmtShort(new Date()),
            }}
            onSubmit={(values) => {
              console.log(values)
            }}
          >
            {(props: any) => (
              <form onSubmit={props.handleSubmit} className={styles.filterCard}>
                <ReportFilter
                  headerLabel="รายงานสรุปจำนวนครั้ง ขาด ลา มาสาย"
                  value={props}
                  documentType={EDocumentType.TimeAttendanceLeaveLateDocPerEmp}
                  mutationData={getLateLeaveExcelDoc}
                  loading={loading}
                  setFileData={setFileData}
                  setShowfile={setShowfile}
                  setTypeFile={setTypeFile}
                  defaultData={resLateLeaveExcelDoc}
                />
              </form>
            )}
          </Formik>
        </div>
      </section>
      <div className={styles.line} />
      <section className={styles.cardReport}>
        <div className={styles.wrapperCard}>
          {showfile === true ? (
            <iframe
              src={typefile === 'PDF' ? fileData : `https://view.officeapps.live.com/op/embed.aspx?src=${fileData}`}
              className={styles.viewFram}
              frameBorder="0"
            ></iframe>
          ) : (
            <p className={styles.text}>หน้าแสดงข้อมูล</p>
          )}
        </div>
      </section>
    </div>
  )
}

export default AttendanceSummaryReportPage
