import InputLabel from '@mui/material/InputLabel'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// import Fade from '@mui/material/Fade'

import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
// Components
import { ButtonComponent } from '../../component'
import AssignedCard from './AssignedCard'
// Image
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import BloclIcon from '../../image/block.svg'
import EditIcon from '../../image/editicon.svg'
import Lady from '../../image/lady2.svg'
import RegisterIcon from '../../image/register.svg'
// import SearchIcon from '../../image/search.svg'
import SearchIcon from '@mui/icons-material/Search'

const UserManagementView: React.FC = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const [searchModule, setSearchModule] = useState('')
  const searchChange = (event: any) => {
    setSearchModule(event.target.value)
  }
  const navigate = useNavigate()
  return (
    <div className={styles.container}>
      <section className={styles.topicPageWrapper}>
        <p className={styles.topicPage}>ผู้ใช้งาน</p>
      </section>
      <div className={styles.contentContainer}>
        <section className={styles.cardUser}>
          <section className={styles.profileIMG}>
            <img src={Lady} alt="profile" className={styles.image} />
          </section>
          <button
            className={styles.btnMore}
            type="button"
            id="fade-button"
            aria-controls="fade-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event: any) => handleClick(event)}
          >
            <MoreHorizIcon sx={{ color: '#626262' }} fontSize="medium" />
          </button>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
          >
            <MenuItem onClick={() => setAnchorEl(null)}>
              <div className={styles.btnMoreWrapper}>
                <img src={BloclIcon} alt="block" /> <p>Block</p>
              </div>
            </MenuItem>
            <MenuItem onClick={() => setAnchorEl(null)}>
              <div className={styles.btnMoreWrapper}>
                <img src={RegisterIcon} alt="register" /> <p>Register</p>
              </div>
            </MenuItem>
          </Menu>

          <section className={styles.textContainer}>
            <p className={styles.textName}>Thanyarat.S</p>
            <article className={styles.rowWrapper}>
              <p className={styles.textUser}>User</p>
              <button type="button" className={styles.btnEdit} onClick={() => navigate('../usermanage/user/edit/:id')}>
                <img src={EditIcon} alt="edit" />
                <p className={styles.textLightGray}>Change role</p>
              </button>
            </article>
            <p className={styles.textNormal}>Status : Vetified</p>
          </section>

          <hr className={styles.line} />
          <section className={styles.textContainer}>
            <p className={styles.textBold}>
              รหัสพนักงาน &nbsp;&nbsp;<span className={styles.textContent}> Data220100</span>
            </p>
            <article className={styles.rowWrapper}>
              <p className={styles.textBold}>
                ชื่อ &nbsp;&nbsp;<span className={styles.textContent}> Thanyarat</span>
              </p>
              <p className={styles.textBold}>
                นามสกุล &nbsp;&nbsp;<span className={styles.textContent}> TTTDf</span>
              </p>
            </article>
            <p className={styles.textBold}>
              Email &nbsp;&nbsp;&nbsp;<span className={styles.textEmail}> Thanyarat@gmail.com</span>
            </p>
            <br />
            <article className={styles.textPositionWrapper}>
              <section className={styles.columnWrapper}>
                <p className={styles.textBold}>ชื่อแผนก</p>
                <p className={styles.textBold}>ชื่อตำแหน่ง</p>
                <p className={styles.textBold}>ชื่อทีม</p>
              </section>
              <section className={styles.columnWrapper}>
                <p className={styles.textContent}>Product</p>
                <p className={styles.textContent}>Product Manager</p>
                <p className={styles.textContent}></p>
              </section>
            </article>
            <br />
          </section>
        </section>
        <section className={styles.cardAssigned}>
          <div className={styles.assignedContainer}>
            <section>
              <article className={styles.topicAssignedWrapper}>
                <p className={styles.textTopic}>Assigned </p>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-search" sx={{ fontFamily: 'KanitRegular' }}>
                    ค้นหาโมดูล
                  </InputLabel>
                  <OutlinedInput
                    size="small"
                    id="outlined-adornment-search"
                    value={searchModule}
                    onChange={searchChange}
                    startAdornment={<SearchIcon />}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    label="ค้นหาโมดูล"
                  />
                </FormControl>
              </article>
              <article className={styles.columnGapWrapper}>
                {assignedData.map((assignedData: any, index: number) => {
                  return (
                    <div key={index}>
                      <AssignedCard isSearch={searchModule} topic="Assigned" data={assignedData.assigned} />
                      <AssignedCard isSearch={searchModule} topic="Not Assigned" data={assignedData.notAssigned} />
                    </div>
                  )
                })}
                {/* <AssignedCard topic="Not Assigned" data={notAssignedData} /> */}
              </article>
            </section>
          </div>
        </section>
        <section>
          <div className={styles.btnFooter}>
            <ButtonComponent
              _colorBG="gray"
              _colorText="white"
              _text="ย้อนกลับ"
              _variant="contained"
              _type="button"
              _functionOnClick={() => navigate(-1)}
            />
          </div>
        </section>
      </div>
    </div>
  )
}
export default UserManagementView

const assignedData = [
  {
    assigned: [
      { title: 'Human Resource ', subtitle: 'All 9 function assigned ' },
      { title: 'Inventory (ระบบคลังสินค้า)  ', subtitle: 'All 9 function assigned ' },
    ],
    notAssigned: [
      { title: 'Human Resource ', subtitle: 'All 9 function assigned ' },
      { title: 'Inventory (ระบบคลังสินค้า) ', subtitle: 'All 9 function assigned ' },
    ],
  },
]

// const notAssignedData = [
//   { title: 'Inventory (ระบบคลังสินค้า) ', subtitle: '', status: 'assigned' },
//   { title: 'Inventory (ระบบคลังสินค้า) ', subtitle: '', status: 'notAssigned' },
// ]
