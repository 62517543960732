import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { LIST_PACKAGE_GROUP } from 'src/adapter/xhr/query'
import useHandleResponseAPI from '../useHandleResponseAPI'

function usePackage() {
  const { data, refetch, loading, error } = useQuery(LIST_PACKAGE_GROUP, { fetchPolicy: 'no-cache' })

  const isLoading = useMemo(() => {
    return loading
  }, [loading])

  const errMsg = useMemo(() => {
    if (error) return JSON.stringify(error)
    else return ''
  }, [error])

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg,
  })

  return {
    data: data?.listPackageGroup,
    refetch,
  }
}

export default usePackage
