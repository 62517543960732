//lib
import React from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'

//Include Project
import { toTime } from 'src/utils/common'
import { InputTimeComponentCustom, TooltipArrow } from 'src/component'
import styles from './index.module.scss'

//Images
import TimeLogSVG from '../../image/TimeLog.svg'
// import InputTime from 'src/component/InputTime'

const ScanCardAttendanceReport: React.FC = (): JSX.Element => {
  const { values, errors } = useFormikContext<any>()
  const TooltipTimeLog = () => {
    return (
      <div className={styles.IconTime}>
        <img src={TimeLogSVG} alt="TimeLogSVG" />
      </div>
    )
  }

  const onresetFingerprint = (values: any) => {
    values.setFieldValue('scanIn', '')
    values.setFieldValue('scanOut', '')
  }

  // console.log(values, '<<<< VALUES')

  return (
    <div className={styles.container}>
      <div className={styles.wrapperInput}>
        <div className={styles.gropHead}>
          <p className={styles.TextHead}>เวลาจริงจากเครื่องสแกนนิ้ว</p>
          <TooltipArrow
            child={TooltipTimeLog()}
            label={
              values.attendance?.timeAttendanceLog?.scanOut.length > 0
                ? `เวลาจริงจากเครื่องสแกนนิ้ว ${toTime(values.attendance.timeAttendanceLog?.scanIn)} น. - ${toTime(
                    values.attendance.timeAttendanceLog?.scanOut,
                  )} น.`
                : 'ไม่มีข้อมูลการสแกนนิ้ว'
            }
          />
        </div>
        <section className={styles.inputBox}>
          <p className={styles.descriptionText}>ช่วงเวลา</p>
          <div className={styles.groupBox}>
            <InputTimeComponentCustom
              _name="report.scanIn"
              _label={'สแกนเข้างาน'}
              _value={values.report.scanIn}
              _helperText={`${_.get(errors, 'report.scanIn')}`}
            />
            <InputTimeComponentCustom _name="report.scanOut" _label={'สแกนออกงาน'} _value={values.report.scanOut} />
          </div>
        </section>
      </div>
    </div>
  )
}

export default ScanCardAttendanceReport
