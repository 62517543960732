import { Company, CreateCompanyInput } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationCreateCompanyDetail } from '../xhr/mutation'
import { queryGetCompanyDetail } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------
export function getCompanyDetail(): Promise<Company> {
  return gqlResponseType<Company>(
    post('/', {
      query: queryGetCompanyDetail,
    }),
    'getCompanyDetail',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------
export function createCompanyDetail(input: CreateCompanyInput): Promise<Company> {
  return gqlResponseType<Company>(
    post('/', {
      query: mutationCreateCompanyDetail,
      variables: {
        input,
      },
    }),
    'createCompanyDetail',
  )
}
