import React from 'react'

//images
import timeLogSVG from '../../image/TimeLog.svg'

//Include Project
import styles from './index.module.scss'
import { TooltipArrow } from 'src/component'
import { toTime } from 'src/utils/common'

interface Props {
  outDiffMinute: any
  scanOut: any
  timeAttendanceLog: any
}
const TooltipTimeLog = () => {
  return (
    <div className={styles.IconTime}>
      <img src={timeLogSVG} alt="timeLogSVG" />
    </div>
  )
}
const ChipOutDiffMinutes: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      {props.scanOut && (
        <div className={styles.textScan}>
          <div className={styles.groupText}>
            <p>{toTime(props.scanOut)}</p>
            <TooltipArrow
              label={
                props.timeAttendanceLog?.scanOut.length > 0
                  ? `เวลาจริงจากเครื่องสแกนนิ้ว ${toTime(props.timeAttendanceLog?.scanOut)} น.`
                  : 'ไม่มีข้อมูลการสแกนนิ้ว'
              }
              child={TooltipTimeLog()}
            />
          </div>

          {props.outDiffMinute !== 0 && (
            <div className={styles.groupPill}>
              <section className={props.outDiffMinute < 0 ? styles.pillDiffLate : styles.pillDiffEarly}>
                {props.outDiffMinute < 0 ? <p>ออกก่อน</p> : <p>ออกหลัง</p>}
              </section>
              <p className={props.outDiffMinute > 0 ? styles.textIn : styles.textLate}>{props.outDiffMinute}</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default ChipOutDiffMinutes
