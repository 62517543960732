/* eslint-disable react/no-children-prop */
import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import './index.scss'
import { ModalAlertStatus, ModalConfirm, Notification } from './component'
import {
  HomePage,
  LoginAdminPage,
  Attendance,
  AttendanceEdit,
  LeavePolicy,
  LeavePolicyEdit,
  BenefitPolicy,
  BenefitPolicyEdit,
  MenuEmployeeMain,
  Department,
  DepartmentNewEdit,
  ApprovalSystemPage,
  Payroll,
  // PayrollCreate,
  OvertimePolicy,
  // OvertimePolicyEdit,
  // ApprovalCheckInOut,
  // ApprovalCheckInOutEdit,
  AttendanceLog,
  Team,
  TeamNewEdit,
  MainPosition,
  CreatePosition,
  UserManageTeam,
  UserManageTeamAdduser,
  UserManageTeamNewEdite,
  UserManagementView,
  UserManagementNewEdit,
  // PayMent,
  HolidayPolicyPage,
  HolidayPolicyEditPage,
  AttendanceLogPage,
  CreatePackage,
  // Dashboard,
  CalendarDetailPage,
  TranPackage,
  MenuEmployeeEdit,
  ForgotPassword,
  PageNotFound,
  LeaveDocument,
  OvertimeDocument,
  OvertimePolicyEdit,
  SocialSecurityPolicyPage,
  CompanyDetailPolicyPage,
  ApprovalPolicyPage,
  IncomeExpensePolicyPage,
  DiligentPolicyPage,
  WelfarePolicyPage,
  TaxPolicyPage,
  ProvidentFundPolicyPage,
  CalculationPolicyPage,
  WithdrawalPolicyPage,
  AttendanceSummaryReportPage,
  AttendanceLogReport,
  AttendanceRportDocumentPage,
  AttendanceLeaveQaotaPage,
  AttendanceMonthReportPage,
  AttendanceYearReportPage,
  EmployeeNewRportPage,
  EmployeeFullReportPage,
  PayrollMonthReport,
  PayrollAnnualPageReport,
  PayrollSlipReportPage,
  ProvidentFundReportPage,
  SalaryRaiseReportPage,
  SocialSecurityReportPage,
  CompensationFundReportPage,
  PND1KReportPage,
  FIFTYBISReportPage,
  PNDReportPage,
  AttendancePolicyPage,
  ProfilePage,
  EmployeeProfile,
  // SocialSecurityPolicyPage,
  // CompanyDetailPolicyPage,
  // ApprovalPolicyPage,
} from './pages'
import useCompanyID from './hooks/useCompanyID'
import { ThemeProvider } from '@mui/material'
import theme from './utils/theme'
import { Layout } from './layout'
import { RequireAuth } from './layout/requireAuth'
import EmployeeReportPage from './pages/EmployeeReport'
import { useParams } from 'react-router-dom'
import useMyLocation from './hooks/useMyLocation'
import EnhancedEmployeeProfile from './pages/EmployeeProfile'

// Route-based Code Splitting
const Dashboard = React.lazy(() => import('./pages/Dashboard'))

// The Main Route for Linklook will be:
// /admin/hr/topic[H1]/subtopic[H2]
// [0] ADMIN/EMPLOYEE: First will be admin/employee side depending on Login.
// [1] MODULE:         The module inside: HR/Inventory/OneAuth...
// [2] TOPIC/H1:       The Topic inside (Index)  ie. Payroll
// [3] SUBTOPIC/H2:    The Subtopic inside       ie. Payroll/Attendance

const App: React.FC = (): JSX.Element => {
  useCompanyID()

  return (
    <>
      {/* Suspense here for fallback on dynamic Route Code-Spliting */}
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {/* Central Login */}
            <Route path="/" element={<LoginAdminPage />} />

            {/* Admin Route */}
            <Route path="/admin" element={<RequireAuth redirectTo="/" />}>
              {/* Admin Homepage Select ERP Module: HR/Inventory/... */}
              <Route index element={<HomePage />} />

              {/* Linklook HR Module */}
              <Route path="hr" element={<Layout />}>
                {/* Index: Dashboard */}
                <Route index element={<Dashboard />} />
                {/* Calendar */}
                <Route path="calendar" element={<CalendarDetailPage />} />
                {/* Approval System */}
                <Route path="approve" element={<ApprovalSystemPage />} />

                {/* Employee Group */}
                <Route path="employee">
                  <Route path="employee" element={<MenuEmployeeMain />} />
                  <Route path="employee/add" element={<EmployeeProfile />} />
                  <Route path="employee/edit/:id" element={<EnhancedEmployeeProfile />} />
                  <Route path="position" element={<MainPosition />} />
                  <Route path="position/add" element={<CreatePosition />} />
                  <Route path="position/edit/:id" element={<CreatePosition />} />
                  <Route path="department" element={<Department />} />
                  <Route path="department/add" element={<DepartmentNewEdit />} />
                  <Route path="department/edit/:id" element={<DepartmentNewEdit />} />
                  <Route path="team" element={<Team />} />
                  <Route path="team/add" element={<TeamNewEdit />} />
                  <Route path="team/edit/:id" element={<TeamNewEdit />} />
                  <Route path="creatPackage" element={<CreatePackage />} />
                  <Route path="tranPackage" element={<TranPackage />} />
                </Route>

                {/* Wage Group */}
                <Route path="wage">
                  <Route path="attendanceLog" element={<AttendanceLogPage />} />
                  <Route path="attendanceReport" element={<AttendanceLog />} />
                  <Route path="payroll" element={<Payroll />} />
                  {/* <Route path="payroll/add" element={<PayrollCreate />} />
                <Route path="payroll/edit/:id" element={<PayrollCreate />} /> */}
                  {/* <Route path="approval" element={<ApprovalCheckInOut />} />
                <Route path="approval/add" element={<ApprovalCheckInOutEdit />} />
                <Route path="approval/edit/:id" element={<ApprovalCheckInOutEdit />} />
                <Route path="payment" element={<PayMent />} /> */}
                </Route>

                {/* Document Management */}
                <Route path="document">
                  <Route path="leaveDocument" element={<LeaveDocument />} />
                  <Route path="overtimeDocument" element={<OvertimeDocument />} />
                </Route>

                {/* Setting/Master Data  */}
                <Route path="masterdata">
                  <Route path="attendance" element={<Attendance />} />
                  <Route path="attendance/add" element={<AttendanceEdit />} />
                  <Route path="attendance/edit/:id" element={<AttendanceEdit />} />
                  <Route path="leavepolicy" element={<LeavePolicy />} />
                  {/* <Route path="leavepolicy/add" element={<LeavePolicyEdit />} />
                  <Route path="leavepolicy/edit" element={<LeavePolicyEdit />} /> */}
                  <Route path="benefitpolicy" element={<BenefitPolicy />} />
                  <Route path="benefitpolicy/add" element={<BenefitPolicyEdit />} />
                  <Route path="benefitpolicy/edit" element={<BenefitPolicyEdit />} />
                  <Route path="overtimepolicy" element={<OvertimePolicyEdit />} />
                  {/* <Route path="overtimepolicy/add" element={<OvertimePolicyEdit />} />
                  <Route path="overtimepolicy/edit" element={<OvertimePolicyEdit />} /> */}
                  <Route path="holidayPolicy" element={<HolidayPolicyPage />} />
                  <Route path="holidayPolicy/edit/:id" element={<HolidayPolicyEditPage />} />
                  <Route path="holidayPolicy/edit/" element={<HolidayPolicyEditPage />} />

                  <Route path="socialSecurityPolicy/" element={<SocialSecurityPolicyPage />} />
                  <Route path="companyDetailPolicy/" element={<CompanyDetailPolicyPage />} />
                  <Route path="approvalPolicy/" element={<ApprovalPolicyPage />} />
                  <Route path="incomeExpensePolicy/" element={<IncomeExpensePolicyPage />} />
                  <Route path="diligentPolicy/" element={<DiligentPolicyPage />} />
                  <Route path="welfarePolicy/" element={<WelfarePolicyPage />} />
                  <Route path="taxPolicy/" element={<TaxPolicyPage />} />
                  <Route path="providentFundPolicy/" element={<ProvidentFundPolicyPage />} />
                  <Route path="calculationPolicy/" element={<CalculationPolicyPage />} />
                  <Route path="withdrawalPolicy/" element={<WithdrawalPolicyPage />} />
                  <Route path="attendancePolicy/" element={<AttendancePolicyPage />} />
                </Route>

                <Route path="report">
                  <Route path="attendance/leavesummary" element={<AttendanceSummaryReportPage />} />
                  <Route path="attendance/attendancelog" element={<AttendanceLogReport />} />
                  <Route path="attendance/attendancereport" element={<AttendanceRportDocumentPage />} />
                  <Route path="attendance/leavequota" element={<AttendanceLeaveQaotaPage />} />
                  <Route path="attendance/attendancemonthreport" element={<AttendanceMonthReportPage />} />
                  <Route path="attendance/attendanceyearreport" element={<AttendanceYearReportPage />} />
                  <Route path="employee/employee" element={<EmployeeReportPage />} />
                  <Route path="employee/newemployee" element={<EmployeeNewRportPage />} />
                  <Route path="employee/fullemployee" element={<EmployeeFullReportPage />} />
                  <Route path="payroll/payrollpermonth" element={<PayrollMonthReport />} />
                  <Route path="payroll/payrollannual" element={<PayrollAnnualPageReport />} />
                  <Route path="payroll/payrollslip" element={<PayrollSlipReportPage />} />
                  <Route path="payroll/providentfund" element={<ProvidentFundReportPage />} />
                  <Route path="payroll/salaryraise" element={<SalaryRaiseReportPage />} />
                  <Route path="revenue/compensationfund" element={<CompensationFundReportPage />} />
                  <Route path="revenue/pnd1k" element={<PND1KReportPage />} />
                  <Route path="revenue/fiftybis" element={<FIFTYBISReportPage />} />
                  <Route path="revenue/pnd" element={<PNDReportPage />} />
                  <Route path="payroll/socialsecurity" element={<SocialSecurityReportPage />} />
                </Route>

                <Route path="usermanage/team" element={<UserManageTeam />} />
                <Route path="usermanage/team/adduser" element={<UserManageTeamAdduser />} />
                <Route path="usermanage/team/addteam" element={<UserManageTeamNewEdite />} />
                <Route path="usermanage/team/addteam/:id" element={<UserManageTeamNewEdite />} />

                <Route path="usermanage/user/view" element={<UserManagementView />} />
                <Route path="usermanage/user/new" element={<UserManagementNewEdit />} />
                <Route path="usermanage/user/edit/:id" element={<UserManagementNewEdit />} />

                <Route path="forgotpassword" element={<ForgotPassword />} />
              </Route>
            </Route>

            {/* Employee Route */}
            <Route path="/employee">
              <Route index element={<HomePage />} />
              <Route path="hr" element={<Layout />}>
                <Route index element={<CalendarDetailPage />} />

                <Route path="profilepage" element={<ProfilePage />} />

                <Route path="employee">
                  <Route path="employee" element={<MenuEmployeeMain />} />
                  <Route path="employee/add" element={<MenuEmployeeEdit />} />
                  <Route path="employee/edit/:id" element={<MenuEmployeeEdit />} />
                </Route>
              </Route>
            </Route>

            {/* 404 Route */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
        <Notification />
        <ModalConfirm />
        <ModalAlertStatus />
      </ThemeProvider>
    </>
  )
}

export default App
