import React, { useRef, useState, useEffect, useMemo } from 'react'
import styles from './index.module.scss'

import { Formik } from 'formik'
import * as yup from 'yup'
import SummarySalaryTable, { ITableSSHeader } from './table'
import { CircularProgress, FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import SearchInput from 'src/component/SearchInput'
import usePaginationQuery, { IUsePaginationQueryOutput } from 'src/hooks/usePaginationQuery'
import { Employee, EmployeePayroll } from 'src/utils/generated'
import { activeEmployeeFilter, GET_ACTIVE_EMPLOYEE } from 'src/adapter/xhr/query'
import { PaginationBar } from 'src/component'

const generateMinuteToHours = (minute: number | null | undefined) => {
  if (!minute) return (0).toFixed()
  const hr = minute / 60 / 8
  return Math.floor(hr) === hr ? hr.toFixed() : hr.toFixed(2)
}

interface ISummaryIncome {
  // payrollList?: any
  period?: string
  date?: string
  isShowMoney?: boolean

  payrollEmployeeQuery: IUsePaginationQueryOutput<EmployeePayroll>
}

const SummaryIncome: React.FC<ISummaryIncome> = (props: ISummaryIncome): JSX.Element => {
  const {
    dataCache,
    getFlattenDataCache,
    currentTableData,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    windowSize,
    maxHasNextToken,
    currentMaxPageIndex,
    loading,
    clearCacheRefetch,
    error,
  } = props.payrollEmployeeQuery
  const flatDataCache = useMemo(() => getFlattenDataCache(), [dataCache])

  const tableHeaderList = getTableHeaderList(flatDataCache)
  const sumTableHeaderList = getSummaryTableHeaderList(flatDataCache)
  // const tableValueList = getSummaryTableValueList(flatDataCache)
  // Make all nested list just flat
  const tableValueList = flatDataCache.map((ep) => ({
    ...ep,
    ...ep.otherIncomeList!.reduce<any>((map, e) => {
      map[e.incomeOrExpenseID] = e.amount
      return map
    }, {}),
    ...ep.otherExpenseList!.reduce<any>((map, e) => {
      map[e.incomeOrExpenseID] = e.amount
      return map
    }, {}),
    ...ep.welfareList!.reduce<any>((map, e) => {
      map[e.welfareID] = e.amount
      return map
    }, {}),
  }))

  // const [sumTableHeaderList, setSumTableHeaderList] = useState<any>([])
  const [searchVal, setSearchVal] = useState('')
  // const validationSchema = yup.object({})
  // const formikRef: any = useRef(null)

  if (loading) return <CircularProgress />
  else if (error) return <>Error OR no data! {error?.message}</>
  return (
    <form
      className={styles.container}
      // onSubmit={props.handleSubmit}
    >
      <div className={styles.BoxInput}>
        {/* <SearchInput values={searchVal} setValues={setSearchVal} label="ค้นหาชื่อ - สกุล/รหัสพนักงาน" /> */}
      </div>
      {/* <div className={styles.toolbarsContainer}>
            <article className={styles.downloadFileBtn}>
              <ButtonComponent
                _type="button"
                _colorBG="blue"
                _colorText="white"
                _variant="outlined"
                _isIcon="file"
                _text="ดาวน์โหลดไฟล์"
                _functionOnClick={() => {
                  console.log('button Click !!')
                }}
              />
            </article>
            <article className={styles.filterBtn}>
              <ButtonComponent
                _type="button"
                _colorBG="transparent"
                _colorText="blue"
                _variant="text"
                _isIcon="filter"
                _text="ตัวกรองข้อมูล"
                _functionOnClick={() => {
                  console.log('button Click !!')
                }}
              />
            </article>
          </div> */}
      <div className={styles.TableContainer}>
        <SummarySalaryTable
          tableHeaderList={tableHeaderList}
          // tableBodyUniqueDepartment={Object.keys(props.values.summary).length ? props.values.summary : null}
          valueList={tableValueList}
          TableSummaryHeaderList={sumTableHeaderList}
          // employeeCount={fcProps.payrollList.employeeCount}
          isShowMoney={props.isShowMoney}
          searchValue={searchVal}
        />
      </div>
    </form>
  )
}

export default SummaryIncome

const getTableHeaderList = (flatDataCache: EmployeePayroll[]): ITableSSHeader[] => {
  if (flatDataCache.length <= 0) return []
  const dayColor = 'purple'
  const positiveColor = '#75B516'
  const negativeColor = 'red'
  const dayWidth = '80px'
  return [
    { text: 'ลำดับ', key: 'No.', align: 'center', columnWidth: '60px' },
    { text: 'รหัส', key: 'employeeID', align: 'center', isBold: true, columnWidth: '100px' },
    { text: 'ชื่อพนักงาน', key: 'employeeName', align: 'left', isBold: true, columnWidth: '150px' },
    {
      text: 'วันมาทำงาน',
      key: 'workingMinute',
      align: 'center',
      columnWidth: dayWidth,
      color: dayColor,
      transformer: generateMinuteToHours,
    },
    {
      text: 'วันลาหัก',
      key: 'leaveDeductMinute',
      align: 'center',
      columnWidth: dayWidth,
      color: dayColor,
      transformer: generateMinuteToHours,
    },
    {
      text: 'วันลาไม่หัก',
      key: 'leaveMinute',
      align: 'center',
      columnWidth: dayWidth,
      color: dayColor,
      transformer: generateMinuteToHours,
    },
    {
      text: 'วันโอที',
      key: 'otTypeOTMinute',
      align: 'center',
      columnWidth: dayWidth,
      color: dayColor,
      transformer: generateMinuteToHours,
    },
    {
      text: 'ทำงานวันหยุด',
      key: 'otTypeWorkMinute',
      align: 'center',
      columnWidth: dayWidth,
      color: dayColor,
      transformer: generateMinuteToHours,
    },
    {
      text: 'ค่าจ้าง',
      key: 'income',
      columnWidth: '110px',
      color: positiveColor,
      isBold: true,
      isEnableShow: true,
    },
    { text: 'ค่าทำงานล่วงเวลา', key: 'otTypeOTIncome', columnWidth: '110px', color: positiveColor, isEnableShow: true },
    {
      text: 'ค่าทำงานวันหยุด',
      key: 'otTypeWorkIncome',
      columnWidth: '110px',
      color: positiveColor,
      isEnableShow: true,
    },
    ...flatDataCache[0].otherIncomeList!.map((element) => {
      return {
        text: element.name,
        key: element.incomeOrExpenseID,
        columnWidth: '110px',
        color: positiveColor,
        isEnableShow: true,
      }
    }), // รายรับ D(+)
    ...flatDataCache[0].welfareList!.map((element) => {
      return {
        text: element.name,
        key: element.welfareID,
        columnWidth: '110px',
        color: positiveColor,
        isEnableShow: true,
      }
    }), // สวัสดิการ F(+)
    { text: 'เบี้ยขยัน', key: 'workDiligentIncome', columnWidth: '110px', color: positiveColor, isEnableShow: true },
    { text: 'เข้าก่อน', key: 'inEarlyIncome', columnWidth: '110px', color: positiveColor, isEnableShow: true },
    { text: 'พักเร็ว', key: 'breakEarlyIncome', columnWidth: '110px', color: positiveColor, isEnableShow: true },
    { text: 'ออกสาย', key: 'outLateIncome', columnWidth: '110px', color: positiveColor, isEnableShow: true },
    {
      text: 'รวมรายรับ',
      key: 'netPositiveIncome',
      columnWidth: '110px',
      color: positiveColor,
      isBold: true,
      isEnableShow: true,
    },
    ...flatDataCache[0].otherExpenseList!.map((element) => {
      return {
        text: element.name,
        key: element.incomeOrExpenseID,
        columnWidth: '110px',
        color: negativeColor,
        isEnableShow: true,
      }
    }), // รายหัก D (-)
    { text: 'เข้าสาย', key: 'inLateExpense', columnWidth: '110px', color: negativeColor, isEnableShow: true },
    { text: 'พักเกิน', key: 'breakLateExpense', columnWidth: '110px', color: negativeColor, isEnableShow: true },
    { text: 'ออกก่อน', key: 'outEarlyExpense', columnWidth: '110px', color: negativeColor, isEnableShow: true },
    {
      text: 'รวมรายจ่าย',
      key: 'netNegativeIncome',
      columnWidth: '110px',
      color: negativeColor,
      isBold: true,
      isEnableShow: true,
    },
    {
      text: 'รวมสุทธิ',
      key: 'netIncome',
      bodyBackgroundColor: '#E0E0E0',
      isBold: true,
      isEnableShow: true,
    },
  ]
}

const getSummaryTableHeaderList = (flatDataCache: EmployeePayroll[]): string[] => {
  if (flatDataCache.length <= 0) return []
  return [
    'workDay',
    'leaveDay',
    'leaveDeductMinute',
    'otTypeOTMinute',
    'otTypeWorkMinute',
    'income',
    'otTypeWorkIncome',
    'otTypeOTIncome',
    ...flatDataCache[0].otherIncomeList!.map((element) => element.incomeOrExpenseID),
    ...flatDataCache[0].welfareList!.map((element) => element.welfareID),
    'workDiligentIncome',
    'inEarlyIncome',
    'breakEarlyIncome',
    'outLateIncome',
    'netPositiveIncome',
    ...flatDataCache[0].otherExpenseList!.map((element) => element.incomeOrExpenseID),
    'inLateExpense',
    'breakLateExpense',
    'outEarlyExpense',
    'netNegativeIncome',
    'netIncome',
  ]
}

// const getSummaryTableValueList = (flatDataCache: EmployeePayroll[]) => {
//   return flatDataCache.map((ep) => {
//     return {
//       employeeID: ep.employeeID,
//       employeeName:
//         (ep.employee?.fullName ? ep.employee?.fullName : null) +
//         ' ' +
//         (ep.employee?.lastName ? ep.employee?.lastName : null),
//       workDay: generateMinuteToHours(ep.workingMinute),
//       leaveDay: generateMinuteToHours(ep.leaveMinute),
//       leaveDeductMinute: generateMinuteToHours(ep.leaveDeductMinute),
//       otTypeOTMinute: generateMinuteToHours(ep.otTypeOTMinute),
//       otTypeWorkMinute: generateMinuteToHours(ep.otTypeWorkMinute),
//       income: ep.income,
//       otTypeWorkIncome: ep.otTypeWorkIncome,
//       otTypeOTIncome: ep.otTypeOTIncome,
//       ...ep.otherIncomeList!.reduce<any>((map, e) => {
//         map[e.id] = e.amount
//         return map
//       }, {}),
//       ...ep.otherExpenseList!.reduce<any>((map, e) => {
//         map[e.id] = e.amount
//         return map
//       }, {}),
//       ...ep.welfareList!.reduce<any>((map, e) => {
//         map[e.id] = e.amount
//         return map
//       }, {}),
//       workDiligentIncome: ep.workDiligentIncome,
//       inEarlyIncome: ep.inEarlyIncome,
//       breakEarlyIncome: ep.breakEarlyIncome,
//       outLateIncome: ep.outLateIncome,
//       netPositiveIncome: ep.netPositiveIncome,
//     }
//   })
// }

const defaultSummaryHeaderList = [
  {
    text: 'ลำดับ',
    key: 'No.',
  },
  {
    text: 'รหัสพนักงาน',
    key: 'employeeID',
  },
  {
    text: 'ชื่อพนักงาน',
    key: 'employeeName',
  },
  {
    text: 'วันมาทำงาน',
    key: 'workDay',
  },
  {
    text: 'วันลาหัก',
    key: 'leaveDay',
  },
  { text: 'วันลาไม่หัก', key: 'leaveDeductMinute' },
  { text: 'วันหยุดโอที', key: 'otTypeOTMinute' },
  { text: 'วันโอที', key: 'otTypeWorkMinute' },
  {
    text: 'เงิน A',
    key: 'income',
    isfontWeightBold: true,
  },
]

const tableSummaryHeaderList = [
  {
    text: 'ลำดับ',
    key: 'No.',
  },
  {
    text: 'รหัสพนักงาน',
    key: 'employeeID',
  },
  {
    text: 'ชื่อพนักงาน',
    key: 'employeeName',
  },
  {
    text: 'วันมาทำงาน',
    key: 'workDay',
  },
  {
    text: 'วันลา',
    key: 'leaveDay',
  },
  {
    text: 'การทำงานล่วง',
    key: 'otDay',
  },
  {
    text: 'รวมเงินเดือน',
    key: 'income',
    isfontWeightBold: true,
  },
  {
    text: 'ประกันสังคม',
    key: 'socialSecurityCost',
  },
  {
    text: 'กองทุนสำรอง',
    key: 'reserveFund',
  },
  {
    text: 'เบี้ยขยัน',
    key: 'diligent',
  },
  {
    text: 'ค่าอาหาร',
    key: 'foodCost',
  },
  {
    text: 'ค่าตำแหน่ง',
    key: 'positionValue',
  },
  {
    text: 'หักกิจ+คชจ',
    key: 'otherExpense',
  },
  {
    text: 'หักเงิน กยศ.',
    key: 'studentLoan',
  },
  {
    text: 'รวมสุทธิ',
    key: 'totalIncome',
    bodyBackgroundColor: '#E0E0E0',
  },
]
