import React, { useEffect, useState, useRef } from 'react'
import styles from './index.module.scss'
import {
  Modal,
  Backdrop,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AvatarGroup,
  Avatar,
  Checkbox,
} from '@mui/material'
import * as yup from 'yup'
import LinkIcon from '../../image/link.svg'
import UnLinkIcon from '../../image/Unlink.svg'
import AddIcon from '../../image/addEmp.svg'
import BackIcon from '../../image/back.svg'
import CountIcon from '../../image/countUser.svg'
import { Formik } from 'formik'
import { ButtonComponent, InputNumberComponentCustom, InputTextComponentCustom } from '../../component'
import DelIcon from '../../image/deleteRedIcon.svg'
import allAction from '../../state/actions'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_ACTIVE_EMPLOYEE, GET_PACKAGE_GROUP } from 'src/adapter/xhr/query'
import { useQueryListLeaveList } from 'src/adapter/api'
import Swal from 'sweetalert2'
import { MUTATION_CREATE_PACKAGE, MUTATION_DELETE_PACKAGE, MUTATION_UPDATE_PACKAGE } from 'src/adapter/xhr/mutation'

interface IPROPS {
  open: boolean
  setOpen: any
  packegeId: string
}

export const ModalCreatePackage: React.FC<IPROPS> = (props: IPROPS): JSX.Element => {
  const [adduser, setAddUser] = useState(false)
  const [userList, setUserlist] = useState<any>([])
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [isCheck, setIsCheck] = useState<any>([])
  const [loaddata, { refetch }] = useLazyQuery(GET_PACKAGE_GROUP)
  const { data: dataLeave } = useQueryListLeaveList({})
  const { data: dataEMP } = useQuery<any>(GET_ACTIVE_EMPLOYEE)
  console.log(userList, 'userList')
  const formikRef: any = useRef()
  const otherprops = props

  useEffect(() => {
    if (props.open === true) {
      if (props.packegeId) {
        loaddata({ variables: { packageGroupId: props.packegeId } }).then((res: any) => {
          const _data = res.data.getPackageGroup
          const _leavePackageList: any[] = []
          const dataleavePackageList = _data.leavePackageList.map((ele: any) => {
            _leavePackageList.push({
              leaveConfigID: ele.leaveConfigID,
              leaveConfig: ele.leaveConfig,
              customQuotaList: [
                {
                  customQuota: ele.customQuotaList[0].customQuota,
                  isCustomQuota: ele.customQuotaList[0].isCustomQuota,
                  workMonthExperience: ele.customQuotaList[0].workMonthExperience,
                },
              ],
              select: true,
            })
          })
          const data = dataLeave.map((ele: any) => {
            return _leavePackageList.push({
              leaveConfigID: ele.id,
              leaveConfig: ele,
              customQuotaList: [
                {
                  customQuota: ele.leaveQuotaList[0].quotaLeaveDay,
                  isCustomQuota: false,
                  workMonthExperience: ele.leaveQuotaList[0].workMonthExperience,
                },
              ],
              select: false,
            })
          })

          formikRef.current.setFieldValue('name', _data.name)
          formikRef.current.setFieldValue(
            'leavePackageList',
            _leavePackageList.filter(
              (ele: any, index: number) =>
                _leavePackageList.findIndex((item: any) => item.leaveConfigID === ele.leaveConfigID) === index,
            ),
          )
          formikRef.current.setFieldValue('employeeList', _data.employeeList.items)
          setUserlist(_data.employeeList.items)
          refetch({ variables: { packageGroupId: props.packegeId } })
        })

        setMode('update')
      } else {
        const _leavePackage: any[] = []
        const data = dataLeave?.map((ele: any) => {
          return _leavePackage.push({
            leaveConfigID: ele.id,
            leaveConfig: ele,
            customQuotaList: [
              {
                customQuota: ele.leaveQuotaList[0].quotaLeaveDay,
                isCustomQuota: false,
                workMonthExperience: ele.leaveQuotaList[0].workMonthExperience,
              },
            ],
            select: true,
          })
        })

        setTimeout(() => {
          console.log(formikRef)
          formikRef.current?.setFieldValue('leavePackageList', _leavePackage)
        }, 500)
        setMode('create')
      }
    }
  }, [props.packegeId, props.open])

  const handleClick = (e: any, id: any, item: any) => {
    const { value, checked } = e.target
    setUserlist([...userList, item])
    if (!checked) {
      setUserlist(userList.filter((item: any) => item.id !== id))
    }
  }

  const [mutationCreate, { error }] = useMutation(MUTATION_CREATE_PACKAGE)
  const [mutationUpdate, { error: errUpdate }] = useMutation(MUTATION_UPDATE_PACKAGE)
  const [mutationDelete, { error: errDel }] = useMutation(MUTATION_DELETE_PACKAGE)

  const onSubmit = (value: any) => {
    const _leavePackageList: any[] = []
    const _dataleavePackageList = value.leavePackageList
      .filter((ele: any) => ele.select === true)
      .map((ele: any) => {
        _leavePackageList.push({
          leaveConfigID: ele.leaveConfigID,
          customQuotaList: ele.customQuotaList,
        })
      })
    const _EmployeeList: any[] = []
    const _dataEmployees = value.employeeList.map((ele: any) => {
      return _EmployeeList.push(ele.id)
    })
    const updatePackageInput: any = {
      input: {
        packageGroupID: props.packegeId,
        name: value.name,
        leavePackageList: _leavePackageList,
        welfarePackageList: [],
        employeeList: _EmployeeList,
      },
    }
    const createPackageInput: any = {
      input: {
        name: value.name,
        leavePackageList: _leavePackageList,
        welfarePackageList: [],
        employeeList: _EmployeeList,
      },
    }

    if (!userList.length) {
      return Swal.fire({
        title: 'กรุณาเพิ่มพนักงานที่ต้องการ',
        text: `${value.name}`,
        icon: 'warning',
        customClass: {
          container: 'swal',
        },
      })
    } else {
      return Swal.fire({
        title: `${mode === 'create' ? 'สร้าง' : 'แก้ไข'}ข้อมูลแพ็คเกจ`,
        html: `${value.name}`,
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#3080f8',
        confirmButtonText: 'ตกลง',
        reverseButtons: true,
        customClass: {
          container: 'swal',
        },
      }).then((res) => {
        if (res.isConfirmed) {
          if (mode === 'create') {
            mutationCreate({ variables: createPackageInput }).then((res: any) => {
              console.log(res, 'res')
              props.setOpen(false)
              Swal.fire({
                title: `สร้างข้อมูลแพ็คเกจ`,
                html: `สำเร็จ`,
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                customClass: {
                  container: 'swal',
                },
              })
            })
          } else if (mode === 'update') {
            mutationUpdate({ variables: updatePackageInput }).then((res: any) => {
              props.setOpen(false)
              Swal.fire({
                title: `แก้ไขข้อมูลแพ็คเกจ`,
                html: `สำเร็จ`,
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                customClass: {
                  container: 'swal',
                },
              })
            })
          }
        }
      })
    }
  }

  const onDelete = (value: any) => {
    return Swal.fire({
      icon: 'warning',
      title: `ลบข้อมูลแพ็คเกจ`,
      html: `${value.name}`,
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: '#FF6161',
      confirmButtonText: 'ตกลง',
      reverseButtons: true,
      customClass: {
        container: 'swal',
      },
    }).then((del) => {
      if (del.isConfirmed) {
        mutationDelete({ variables: { packageGroupId: props.packegeId } }).then((res: any) => {
          console.log(res, 'del')
          props.setOpen(false)
          Swal.fire({
            title: `ลบข้อมูลแพ็คเกจ`,
            html: `สำเร็จ`,
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              container: 'swal',
            },
          })
        })
      }
    })
  }

  /*Yup*/
  const validationSchema = yup.object({
    name: yup.string().required('กรอกชื่อแพ็คเกจ'),
  })

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalApprove}>
        <style lang="scss">{`
        .swal {
        z-index: 3000;
        }
        `}</style>
        <div className={styles.card}>
          <Formik
            innerRef={formikRef}
            validationSchema={validationSchema}
            initialValues={{
              name: '',
              remark: '',
              quota: [],
              leavePackageList: [],
              employeeList: [],
              link: '',
              search: '',
            }}
            onSubmit={(values) => {
              onSubmit(values)
            }}
          >
            {(formikprops: any) => (
              <form className={styles.wrapperForm} onSubmit={formikprops.handleSubmit}>
                {!adduser ? (
                  <div>
                    <div className={styles.packageModal}>
                      <section className={styles.HeadData}>
                        {mode === 'create' ? (
                          <h2 className={styles.headModalText}>สร้างเเพ็กเกจใหม่</h2>
                        ) : (
                          <h2 className={styles.headModalText}>แก้ไขแพ็กเกจ</h2>
                        )}
                        <Divider />
                        <div className={styles.nameOfPackage}>
                          <h4 className={styles.namePackage}>ชื่อเเพ็กเกจ</h4>
                          <div className={styles.inputPackage}>
                            <InputTextComponentCustom
                              _name="name"
                              _label="ชื่อแพ็กเกจ"
                              _value={formikprops.values.name}
                              _helperText={formikprops.errors.name}
                            />
                          </div>
                        </div>
                      </section>

                      <div className={styles.detailModalText}>
                        <section className={styles.walfareArea}>
                          <h4 className={styles.textHead}>การลา</h4>
                          <TableContainer style={{ overflow: 'scroll', height: '300px' }}>
                            <Table>
                              <TableHead
                                style={{ backgroundColor: '#3A77DA', position: 'sticky', top: '0', zIndex: 5 }}
                              >
                                <TableRow>
                                  {headerLeave.map((itemTable: any, Index: number) => (
                                    <TableCell key={Index} align="center">
                                      <p className={styles.text}>{itemTable}</p>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody style={{ backgroundColor: '#ECF3FF' }}>
                                {formikprops.values.leavePackageList.map((itemLeave: any, Index: number) => (
                                  <TableRow key={Index}>
                                    <TableCell align="center" sx={{ border: 'none', padding: 0, margin: 0 }}>
                                      <p>{itemLeave.leaveConfig.name}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>
                                        {itemLeave.customQuotaList[0].workMonthExperience === null
                                          ? 0
                                          : itemLeave.customQuotaList[0].workMonthExperience}
                                      </p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <section className={styles.GroupArea}>
                                        <div className={styles.inputBox}>
                                          <InputNumberComponentCustom
                                            _name={`leavePackageList[${Index}].customQuotaList[${0}].customQuota`}
                                            _value={itemLeave.customQuotaList[0].customQuota}
                                            _disabled={
                                              itemLeave.customQuotaList[0].isCustomQuota === false ? true : false
                                            }
                                          />
                                        </div>
                                        <img
                                          src={
                                            itemLeave.customQuotaList[0].isCustomQuota === false ? LinkIcon : UnLinkIcon
                                          }
                                          onClick={() => {
                                            formikprops.setFieldValue(
                                              `leavePackageList[${Index}].customQuotaList[${0}].isCustomQuota`,
                                              !itemLeave.customQuotaList[0].isCustomQuota,
                                            )
                                          }}
                                          alt=""
                                          className={styles.Icon}
                                        />
                                      </section>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.customQuotaList[0].customQuota}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <Checkbox
                                        name={`leavePackageList[${Index}].select`}
                                        value={itemLeave.select}
                                        checked={itemLeave.select}
                                        onChange={(e) => {
                                          formikprops.setFieldValue(
                                            `leavePackageList[${Index}].select`,
                                            e.target.checked,
                                          )
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                                {/* {otherprops.leaveData.map((itemLeave: any, Indexitem: number) => (
                                  <TableRow key={Indexitem}>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.name}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.workAge}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <section className={styles.GroupArea}>
                                        <div className={styles.inputBox}>
                                          <InputNumberComponentCustom
                                            _name={`quota[${Indexitem}]`}
                                            _value={props.values.quota[Indexitem]}
                                            _disabled={itemLeave.link === true ? true : false}
                                          />
                                        </div>
                                        <img
                                          src={itemLeave.link === true ? LinkIcon : UnLinkIcon}
                                          alt=""
                                          className={styles.Icon}
                                        />
                                      </section>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.total}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <Checkbox />
                                    </TableCell>
                                  </TableRow>
                                ))} */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </section>

                        {/* <section className={styles.walfareArea}>
                          <h4>สวัสดิการ</h4>
                          <TableContainer style={{ overflow: 'scroll', height: '300px' }}>
                            <Table>
                              <TableHead
                                style={{ backgroundColor: '#3A77DA', position: 'sticky', top: '0', zIndex: 5 }}
                              >
                                <TableRow>
                                  {headerLeave.map((itemTable: any, Index: number) => (
                                    <TableCell key={Index} align="center">
                                      <p className={styles.text}>{itemTable}</p>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody style={{ backgroundColor: '#ECF3FF' }}>
                                {otherprops.leaveData.map((itemLeave: any, Indexitem: number) => (
                                  <TableRow key={Indexitem}>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.name}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.workAge}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <section className={styles.GroupArea}>
                                        <div className={styles.inputBox}>
                                          <InputNumberComponentCustom
                                            _name={`quota[${Indexitem}]`}
                                            _value={props.values.quota[Indexitem]}
                                            _disabled={itemLeave.link === true ? true : false}
                                          />
                                        </div>
                                        <img
                                          src={itemLeave.link === true ? LinkIcon : UnLinkIcon}
                                          alt=""
                                          className={styles.Icon}
                                        />
                                      </section>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <p>{itemLeave.total}</p>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                      <Checkbox />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </section> */}
                        <div className={styles.AddPackage}>
                          <p>พนักงาน</p>
                          <AvatarGroup className={styles.allAvata} max={4}>
                            {formikprops.values.employeeList.map((employee: any, employeeIndex: number) => {
                              console.log(employee, 'employee')
                              return <Avatar key={employeeIndex} alt={employee.fullName} src={employee.profileImage} />
                            })}
                          </AvatarGroup>
                          <img src={AddIcon} alt="" onClick={() => setAddUser(true)} className={styles.Icon} />
                        </div>
                      </div>
                    </div>
                    <section className={styles.bottom}>
                      <div className={styles.DelPackage}>
                        {mode === 'update' ? (
                          <div
                            className={styles.Group}
                            onClick={() => {
                              onDelete(formikprops.values)
                            }}
                          >
                            <img src={DelIcon} alt="Delete" />
                            <p>ลบแพ็กเกจ</p>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className={styles.formButton}>
                        <div className={styles.button}>
                          <ButtonComponent
                            _colorBG="red"
                            _colorText="white"
                            _text="ยกเลิก"
                            _type="button"
                            _variant="text"
                            _functionOnClick={() => otherprops.setOpen(false)}
                          />
                        </div>
                        <div className={styles.button}>
                          <ButtonComponent
                            _colorBG="blue"
                            _colorText="white"
                            _text="บันทึก"
                            _type="submit"
                            _variant="text"
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                ) : (
                  <div>
                    <div className={styles.packageModal}>
                      <section className={styles.HeadData}>
                        <div className={styles.GroupHead}>
                          <div className={styles.Groupleft}>
                            <img src={BackIcon} alt="" className={styles.Icon} onClick={() => setAddUser(false)} />
                            {mode === 'create' ? (
                              <h2 className={styles.headText}>สร้างเเพ็กเกจใหม่</h2>
                            ) : (
                              <h2 className={styles.headText}>แก้ไขแพ็กเกจ</h2>
                            )}
                            <h4>เพิ่มพนักงาน</h4>
                          </div>
                          <div className={styles.GroupRigth}>
                            <img src={CountIcon} alt="" />
                            <h4 className={styles.bade}>{userList.length}</h4>
                          </div>
                        </div>
                        <Divider />
                        <div className={styles.nameOfPackage}>
                          <h4 className={styles.namePackage}>ค้นหาพนักงาน</h4>
                          <div className={styles.inputPackage}>
                            <InputTextComponentCustom _name="search" _value={formikprops.values.search} />
                          </div>
                        </div>
                      </section>
                      {/* <section className={styles.HeadData}>
                        <div className={styles.Groupleft}>
                          <img src={BackIcon} alt="" className={styles.Icon} onClick={() => setAddUser(false)} />
                          <h3 className={styles.headModalText}>สร้างแพ็กเกจใหม่</h3>
                          <h4>เพิ่มพนักงาน</h4>
                        </div>

                        <div className={styles.GroupRigth}>
                          <img src={CountIcon} alt="" />
                          <h4 className={styles.bade}>{userList.length}</h4>
                        </div>
                      </section> */}
                      {/* <Divider /> */}
                      {/* <div className={styles.searchBox}>
                        <p className={styles.text}>ค้นหาพนักงาน</p>
                        <div className={styles.InputBox}>
                          <InputTextComponentCustom _name="search" />
                        </div>
                      </div> */}

                      <div className={styles.userArea}>
                        <div className={styles.departArea}>
                          <div className={styles.userCard}>
                            <div className={styles.Group}>
                              <p>เลือกทั้งหมด</p>
                            </div>
                            <Checkbox
                              sx={{
                                color: '#5357BB',
                                '&.Mui-checked': {
                                  color: '#5357BB',
                                },
                              }}
                              // onChange={(e: any) => handleClick(e, item.id, item)}
                              // checked={userList.map((ele: any) => ele.id).includes(item.id)}
                            />
                          </div>
                          {dataEMP?.listEmployee?.items.map((item: any, index: number) => (
                            <div key={index} className={styles.userCard}>
                              <div className={styles.Group}>
                                <Avatar src={item.profileImage} alt={item.fullName} />
                                <p>
                                  {item.fullName} {item.lastName}
                                </p>
                              </div>
                              <Checkbox
                                sx={{
                                  color: '#5357BB',
                                  '&.Mui-checked': {
                                    color: '#5357BB',
                                  },
                                }}
                                onChange={(e: any) => handleClick(e, item.id, item)}
                                checked={userList.map((ele: any) => ele.id).includes(item.id)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <section className={styles.bottom}>
                      <div className={styles.formButton}>
                        <div className={styles.button}>
                          <ButtonComponent
                            _colorBG="white"
                            _colorText="gray"
                            _text="รีเซ็ต"
                            _type="button"
                            _variant="text"
                            _functionOnClick={() => {
                              setUserlist([])
                              formikprops.setFieldValue('employeeList', [])
                            }}
                          />
                        </div>
                        <div className={styles.button}>
                          <ButtonComponent
                            _colorBG="transparent"
                            _colorText="blue"
                            _text="เพิ่มพนักงาน"
                            _type="submit"
                            _variant="outlined"
                            _functionOnClick={() => {
                              formikprops.setFieldValue('employeeList', userList)
                              setAddUser(false)
                            }}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

const headerLeave = ['ชื่อ', 'อายุงาน (เดือน)', 'จำนวน (โควต้า)', 'จำนวนวันลา (ที่ได้เงิน)', 'ตัวเลือก']
