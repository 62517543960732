import { useFormikContext } from 'formik'
import _ from 'lodash'
import React, { useMemo } from 'react'
import CurrencyInput from 'react-currency-input-field'
import styles from './index.module.scss'

interface Props {
  name: string
  helperText?: string
}

export const InputCurrencyFormat: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setFieldValue, errors } = useFormikContext<any>()

  const handleChange = (e: any) => {
    e.preventDefault()
    const { value = '' } = e.target
    const parsedValue = value.replace(/[^\d.]/gi, '')
    setFieldValue(`${props.name}`, parsedValue)
  }

  const handleOnBlur = () => setFieldValue(`${props.name}`, Number(_.get(values, `${props.name}`)).toFixed(2))

  const errMsg = useMemo(() => {
    return _.get(errors, `${props.name}`)
  }, [props.name])

  const handleFocus = () => {
    if (_.get(values, `${props.name}`) <= 0) {
      setFieldValue(`${props.name}`, '')
    }
  }

  return (
    <div className={styles.wrapper}>
      <CurrencyInput
        className={styles.inputCurrency}
        name={props.name}
        id="currencyInput"
        data-number-to-fixed="2"
        data-number-stepfactor="100"
        value={_.get(values, `${props.name}`)}
        placeholder=""
        onChange={handleChange}
        onBlur={handleOnBlur}
        onFocus={handleFocus}
        allowDecimals
        disableAbbreviations
      />
      <p className={styles.errMsg}>{errMsg && errMsg}</p>
    </div>
  )
}
