//lib
import React from 'react'

//Include Project
import styles from './index.module.scss'
import { TableEducation, TableExperian, TableLanguage, TableMedicalHistory, TableTraining } from './table'

const EmployeeBackgroundTab: React.FC = (): JSX.Element => {
  return (
    <div className={styles.container} id="personal">
      <div className={styles.header}>2. ประวัติส่วนตัว</div>
      <div className={styles.card}>
        <TableEducation Header={Header} />
        <TableExperian Header={HeaderWork} />
        <TableTraining Header={HeaderTraining} />
        <TableLanguage Header={HeaderLanguage} />

        {/* <section className={styles.group}>
          <p className={styles.title}>ความสามารถพิเศษ</p>
          <div className={styles.formGroup}>
            <div className={styles.flexBox}>
              <p className={styles.title}>คอมพิวเตอร์</p>
              <InputRadioComponent _row={true} _name="personalTalent.isComputer" _menuList={isComputer} />
            </div>
            <div className={styles.formGap}>
              <div className={styles.groupFlex}>
                <p className={styles.title}>ขับรถยนตร์</p>
                <InputRadioComponent _row={true} _name="personalTalent.isDrive" _menuList={isComputer} />
              </div>
              {values.personalTalent.isDrive === 'true' ? (
                <div className={styles.leftSide}>
                  <div>
                    <InputTextComponentCustom
                      _name="drivingLicenseID"
                      _value={values.drivingLicenseID}
                      _label="เลขที่ใบขับขี่"
                    />
                  </div>
                  <div>
                    <InputDateComponentCustom
                      _name="drivingLicenseExpire"
                      _value={values.drivingLicenseExpire}
                      _label="วันหมดอายุ"
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className={styles.form}>
              <InputTextComponentCustom
                _name="personalTalent.hobby"
                _label="งานอดิเรก"
                _value={values.personalTalent.hobby}
              />
              <InputTextComponentCustom
                _name="personalTalent.sport"
                _label="กีฬาที่ชอบ"
                _value={values.personalTalent.sport}
              />
              <InputTextComponentCustom
                _name="personalTalent.other"
                _label="ความสามารถพิเศษอื่น ๆ "
                _value={values.personalTalent.other}
              />
            </div>
          </div>
        </section> */}

        <TableMedicalHistory Header={HeaderMedical} />
      </div>
    </div>
  )
}

export default EmployeeBackgroundTab

const Header = [
  'ระดับการศึกษา',
  'สถาบันการศึกษา',
  'สาขาวิชา',
  'ปีที่เริ่มการศึกษา',
  'ปีที่จบการศึกษา',
  'เกรดเฉลี่ย',
  '',
]
const HeaderWork = [
  'สถานที่ทำงาน',
  'วันเริ่มงาน',
  'วันออกงาน',
  'ตำแหน่งงาน',
  'ลักษณะงาน',
  'ค่าจ้าง',
  'บุคคลอ้างอิง',
  'เบอร์ติดต่อ',
  'เหตุผลที่ออก',
  '',
]
const HeaderMedical = ['กลุ่มโรคติดต่อร้ายแรง', 'เคย', 'ไม่เคย', 'หมายเหตุ']

const HeaderTraining = ['สถานที่ฝึกอบรม', 'ชื่อหลักสูตร', 'วัน/เดือน/ปี', 'ระยะเวลา', '']
const isComputer = [
  { label: 'ได้', value: true },
  { label: 'ไม่ได้', value: false },
]
const HeaderLanguage = ['ภาษา', 'พูด', 'เขียน', 'อ่าน']
