import { format } from 'date-fns'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { ELeave, EOverTime } from 'src/pages/AttendanceReport/EType'

interface CalendarProps {
  CalaendarDate: any
  getEventData: any
  label?: any
}
interface EventProps {
  getEventData: any
}

const GetEvent = (props: CalendarProps) => {
  const newAttendance: any[] = []
  props?.getEventData.leaveList?.map((eventLeave: any) => {
    return newAttendance.push({
      title: `${ELeave[`${eventLeave.leaveType}`]}`,
      allDay: false,
      start: new Date(`${props.CalaendarDate}:${eventLeave.startedAt}`),
      end: new Date(`${props.CalaendarDate}:${eventLeave.endedAt}`),
      type: 'Leave',
    })
  })
  props?.getEventData.overtimeList?.map((eventOT: any) => {
    return newAttendance.push({
      title: `${EOverTime[`${eventOT.overtimeType}`]}`,
      allDay: false,
      start: new Date(`${props.CalaendarDate}:${eventOT.startedAt}`),
      end: new Date(`${props.CalaendarDate}:${eventOT.endedAt}`),
      type: 'OT',
    })
  })
  return newAttendance
}
const CalendarEvent: React.FC<CalendarProps> = (props: CalendarProps): JSX.Element => {
  const localizer = momentLocalizer(moment)

  const formats = {
    timeGutterFormat: (date: any, culture: any, localizer: any) => localizer.format(date, 'HH:mm น.', culture),
    eventTimeRangeFormat: (range: any) => {
      return `${format(range.start, 'HH:mm น.')} – ${format(range.end, 'HH:mm น.')}`
    },
  }

  return (
    <Calendar
      localizer={localizer}
      eventPropGetter={(event: any) => {
        const backgroundColor = event.type === 'Leave' ? '#FFC5C8' : event.type === 'OT' ? '#F8E8BD' : ''
        return {
          style: {
            backgroundColor,
            border: '1px solid #FFFFFF',
            color: '#393939',
            fontSize: '14px',
          },
        }
      }}
      events={props.getEventData}
      startAccessor="start"
      endAccessor="end"
      formats={formats}
      views={['day']}
      defaultView="day"
      toolbar={false}
      defaultDate={new Date(props.CalaendarDate)}
    />
  )
}

export default CalendarEvent
