//lib
import React from 'react'
import { Modal, Backdrop } from '@mui/material'
import { useFormikContext } from 'formik'

//Include Project
import styles from './index.module.scss'
import {
  InputDateComponentCustome,
  InputNumberComponentCustom,
  InputSelectComponentCustom,
  InputTextComponentCustom,
} from 'src/component'

//Image
import closeIcon from '../../image/close.svg'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  headTable: string[]
}

export const ModalFund: React.FC<Props> = (props: Props): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modal}>
        <div className={styles.card}>
          <div className={styles.head}>
            <p className={styles.textHead}>กองทุน</p>
            <img
              src={closeIcon}
              alt="closeIcon"
              width={18}
              height={18}
              onClick={() => props.setOpen(false)}
              className={styles.Closeicon}
            />
          </div>
          <table className={styles.tableRounded}>
            <tr>
              {props.headTable.map((item: any, index: number) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
            {values.formInput.employeeFund.map((ele: any, index: number) => (
              <tr key={index}>
                <td>
                  <InputTextComponentCustom _name={`formInput.employeeFund[${index}].name`} _value={ele.name} />
                </td>
                <td>
                  <InputNumberComponentCustom
                    _name={`formInput.employeeFund[${index}].fundNumber`}
                    _value={ele.fundNumber}
                  />
                </td>
                <td>
                  <InputDateComponentCustome
                    _name={`formInput.employeeFund[${index}].contractDate`}
                    _value={ele.contractDate}
                    _label="เลือกวันที่"
                  />
                </td>
                <td>
                  <InputSelectComponentCustom
                    _label="เลือกรายการ"
                    _name={`formInput.employeeFund[${index}].deductMethod`}
                    _value={ele.deductMethod}
                    // _menuList={EFundDeduct}
                    _menuList={[]}
                  />
                </td>
                <td>
                  <InputNumberComponentCustom
                    _name={`formInput.employeeFund[${index}].fundRate`}
                    _value={ele.fundRate}
                  />
                </td>
                <td>
                  <InputSelectComponentCustom
                    _label="เลือกรายการ"
                    _name={`formInput.employeeFund[${index}].contributionMethod`}
                    _value={ele.contributionMethod}
                    // _menuList={EFundDeduct}
                    _menuList={[]}
                  />
                </td>
                <td>
                  <InputTextComponentCustom
                    _name={`formInput.employeeFund[${index}].contributionCompany`}
                    _value={ele.contributionCompany}
                  />
                </td>
                <td>
                  <InputTextComponentCustom
                    _name={`formInput.employeeFund[${index}].contributionTotal`}
                    _value={ele.contributionTotal}
                  />
                </td>
                <td>
                  <InputTextComponentCustom
                    _name={`formInput.employeeFund[${index}].beneficiary`}
                    _value={ele.beneficiary}
                  />
                </td>
                <td>
                  <InputTextComponentCustom _name={`formInput.employeeFund[${index}].remark`} _value={ele.remark} />
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </Modal>
  )
}
