//lib
import { getIn, useFormikContext } from 'formik'
import React from 'react'
import moment from 'moment'
import { TextField } from '@mui/material'
import _ from 'lodash'

//Include Project
import {
  CustomizedSwitches,
  InputDateComponentCustome,
  InputRadioComponent,
  InputSelectComponentCustom,
  InputTextComponentCustom,
  UploadFileComponent,
} from 'src/component'
import { EEmployeeType, EEmployeeWorkStatus, EPlaceType, ESetShiftWorking } from 'src/utils/generated'
import styles from './index.module.scss'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'src/state/reducers'

const EmployeeEmploymentTab: React.FC = (): JSX.Element => {
  const { values, setFieldValue, errors, touched } = useFormikContext<any>()

  const GetRetireDate = (EmployeeType: any, birthDate: string, hireDate: string) => {
    const probation: any = 90
    const retireAge: any = 60
    const hireDateNew = new Date(hireDate)
    const birthDateNew = new Date(birthDate)
    if (EmployeeType === 'PROBATION') {
      const retireDate = moment(new Date(new Date().setDate(hireDateNew.getDate() + probation))).format('YYYY-MM-DD')
      if (moment(retireDate).isValid() === false) return
      return retireDate
    } else {
      return moment(new Date(new Date().setFullYear(birthDateNew.getFullYear() + retireAge))).format('YYYY-MM-DD')
    }
  }

  //<------------------ List Option Query---------------------------------->
  const departmentReducer = useSelector((state: ICombineReducers) => state.departmentReducer)
  const shiftReducer = useSelector((state: ICombineReducers) => state.shiftReducer)
  const holidayReducer = useSelector((state: ICombineReducers) => state.holidayReducer)
  const packageReducer = useSelector((state: ICombineReducers) => state.packageReducer)
  const positionReducer = useSelector((state: ICombineReducers) => state.positionReducer)
  const employeeReducer = useSelector((state: ICombineReducers) => state.employeeReducer)
  //<------------------ List Option  Query---------------------------------->

  return (
    <div className={styles.container}>
      <div className={styles.header}>3. ข้อมูลจ้างงาน</div>
      <div className={styles.card}>
        <section className={styles.group}>
          <div className={styles.formGroup}>
            <div className={styles.flexBox}>
              <InputTextComponentCustom _name="formInput.id" _label="รหัสพนักงาน" _value={values.formInput.id} />
            </div>

            <section className={styles.flexBox}>
              <div className={styles.gap}>
                <TextField
                  InputProps={{
                    style: {
                      fontFamily: 'SFThonburiRegular',
                      color: 'gray',
                      borderRadius: 4,
                    },
                  }}
                  size="small"
                  type="date"
                  fullWidth
                  value={values.hireDate}
                  id="outlined-basic"
                  label="วันที่เริ่มงาน"
                  name="formInput.hireDate"
                  error={getIn(touched, 'formInput.hireDate') && Boolean(getIn(errors, 'formInput.hireDate'))}
                  helperText={getIn(touched, 'formInput.hireDate') && getIn(errors, 'formInput.hireDate')}
                  onChange={(e) => {
                    setFieldValue('formInput.hireDate', e.target.value)
                    setFieldValue(
                      'formInput.retireDate',
                      GetRetireDate(values.formInput.employeeWorkStatus, values.formInput.birthDate, e.target.value),
                    )
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 16, fontFamily: 'SFThonburiRegular' },
                  }}
                />
              </div>
              <div className={styles.gap}>
                <InputDateComponentCustome
                  _name="formInput.retireDate"
                  _label={values.employeeWorkStatus === 'PROBATION' ? 'วันที่สิ้นสุดการทดลองงาน' : 'วันสิ้นสุดการทำงาน'}
                  _value={values.formInput.retireDate}
                  _disabled
                />
              </div>
            </section>
            <div className={styles.flexBox}>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>ประเภทพนักงาน</p>
                <div className={styles.wrapperInput}>
                  <InputSelectComponentCustom
                    _name={`formInput.employeeType`}
                    _label="เลือกรายการ"
                    _menuList={employeeType}
                    _value={values.formInput.employeeType}
                    _helperText={`${_.get(errors, 'formInput.employeeType')}`}
                  />
                </div>
              </div>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>แผนก</p>
                <div className={styles.wrapperInput}>
                  <InputSelectComponentCustom
                    _name={`formInput.departmentID`}
                    _label="เลือกรายการ"
                    _menuList={departmentReducer.departmentListOption}
                    _value={values.formInput.departmentID}
                    _helperText={`${_.get(errors, 'formInput.departmentID')}`}
                  />
                </div>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>ทีมงาน</p>
                <div className={styles.wrapperInput}>
                  <InputTextComponentCustom
                    _name="formInput.personalIDNumber"
                    _label="ทีมงาน"
                    _value={values.formInput.personalID}
                    _disabled
                  />
                </div>
              </div>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>ตำแหน่ง</p>
                <div className={styles.wrapperInput}>
                  <InputSelectComponentCustom
                    _name={`formInput.positionID`}
                    _label="เลือกรายการ"
                    _menuList={positionReducer.itemsListOption}
                    _value={values.formInput.positionID}
                    _helperText={`${_.get(errors, 'formInput.positionID')}`}
                  />
                </div>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>หัวหน้างาน</p>
                <div className={styles.wrapperInput}>
                  <InputSelectComponentCustom
                    _name={`formInput.leaderTeam`}
                    _label="เลือกรายการ"
                    _menuList={employeeReducer.itemsListOption}
                    _value={values.formInput.leaderTeam}
                  />
                </div>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.gap}>
                <p className={styles.title}>สถานะพนักงาน</p>
                <InputRadioComponent _row={true} _name="formInput.employeeWorkStatus" _menuList={employeeWorkStataus} />
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>บันทึกเวลาเข้า-ออกงาน</p>
                <div className={styles.wrapperInput}>
                  <CustomizedSwitches
                    _name="formInput.isTimeAttendance"
                    _onClick={(e: any) => setFieldValue('formInput.isTimeAttendance', e.target.checked)}
                    _checked={values.formInput.isTimeAttendance}
                  />
                </div>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>กะการทำงาน</p>
                <div className={styles.wrapperInput}>
                  <InputRadioComponent
                    _row={true}
                    _name="formInput.setShiftWorking"
                    _menuList={[
                      {
                        label: 'เปิดใช้งาน',
                        value: ESetShiftWorking.Enable,
                      },
                      {
                        label: 'ปิดใช้งาน',
                        value: ESetShiftWorking.Disable,
                      },
                    ]}
                  />
                </div>
                {values.formInput.setShiftWorking === ESetShiftWorking.Enable ? (
                  <InputSelectComponentCustom
                    _name={`formInput.shiftWorkingID`}
                    _label="เลือกรายการ"
                    _menuList={shiftReducer.itemsListOption}
                    _value={values.formInput.shiftWorkingID}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={styles.form}>
              <p className={styles.title}>{`ขอบเขตหน้าที่และความรับผิดชอบตำแหน่งงาน (Job description)`}</p>
              <UploadFileComponent
                name={'formInput.jobDescAttachment'}
                flodername={'DocumentPersonal'}
                valueShow={values.formInput.jobDescAttachment}
                id={values.formInput.id}
                label="อัปโหลดเอกสาร Job description"
              />
            </div>
            <div className={styles.flexBox}>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>สถานที่ทำงาน</p>
                <div className={styles.wrapperInput}>
                  <InputSelectComponentCustom
                    _name={`formInput.workPlace`}
                    _label="เลือกรายการ"
                    _menuList={[
                      { label: 'OFFICE', value: EPlaceType.Office },
                      { label: 'OFFSITE', value: EPlaceType.Office },
                    ]}
                    _value={values.formInput.workPlace}
                  />
                </div>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>แพ็คเกจ Group</p>
                <div className={styles.wrapperInput}>
                  <InputSelectComponentCustom
                    _name={`formInput.packageGroupID`}
                    _label="เลือกรายการ"
                    _menuList={packageReducer.itemsListOption}
                    _value={values.formInput.packageGroupID}
                  />
                </div>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.flexBoxTitle}>
                <p className={styles.title}>วันหยุดประจำปี</p>
                <div className={styles.wrapperInput}>
                  <InputSelectComponentCustom
                    _name={`formInput.holidayID`}
                    _label="เลือกรายการ"
                    _menuList={holidayReducer.holidayListOption}
                    _value={values.formInput.holidayID}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default EmployeeEmploymentTab

const employeeWorkStataus = [
  {
    label: 'ทดลองงาน',
    value: EEmployeeWorkStatus.Probation,
  },
  {
    label: 'บรรจุพนักงานแล้ว',
    value: EEmployeeWorkStatus.Employee,
  },
  {
    label: 'พ้นสภาพ',
    value: EEmployeeWorkStatus.Retired,
  },
]

const employeeType = [
  {
    label: 'พนักงานรายเดือน',
    value: EEmployeeType.FulltimeMonth,
  },
  {
    label: 'พนักงานรายวัน',
    value: EEmployeeType.FulltimeDay,
  },
  {
    label: 'พนักงานรายชั่วโมง',
    value: EEmployeeType.Parttime,
  },
  {
    label: 'บุคลากรภายนอก (จ้างงานชั่วคราว)',
    value: EEmployeeType.Outsource,
  },
]
