import React from 'react'
// import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
// import { Checkbox } from '@mui/material'
// import imageShow from '../../image/image.svg'
import styles from '../Menu_Employee/index.module.scss'
import { useNavigate } from 'react-router-dom'
//svg
import editIcon from '../../image/edit.svg'
import deleteIcon from '../../image/del.svg'
// import pinIcon from '../../image/pin.svg'
// import tellIcon from '../../image/tell.svg'
// import mailIcon from '../../image/mail.svg'
// import lineIcon from '../../image/line.svg'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { deletePosition } from '../../adapter/xhr'

export default function CollapsibleTable(props: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // console.log(props.list)
  return (
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell align="center">ID</TableCell>
          <TableCell align="center">ตำแหน่ง</TableCell>
          <TableCell align="center">ระดับ</TableCell>
          <TableCell align="center">จำนวนพนักงาน</TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.list.map((row: any) => {
          return (
            <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' }, fontFamily: 'Kanit' }}>
              <TableCell align="center">
                <p>{row.id}</p>
              </TableCell>
              <TableCell align="center">
                <p>{row.positionName}</p>
              </TableCell>
              <TableCell align="center">{row.positionLevel ? <p>{row.positionLevel}</p> : '-'}</TableCell>
              <TableCell align="center">
                <p>{row.listEmployee.items.length}</p>
              </TableCell>
              <TableCell>
                <div className={styles.action}>
                  <button type="button" className={styles.btEdit}>
                    <img src={editIcon} alt="" onClick={() => navigate(`./edit/${row.id}`)} />
                  </button>
                  <button type="button" className={styles.btDelete}>
                    <img
                      src={deleteIcon}
                      alt=""
                      onClick={() =>
                        dispatch(
                          allAction.modalConfirmAction.setOpenModalConfirm(
                            'ลบตำแหน่งงาน',
                            'คุณต้องการลบข้อมูลตำแหน่งงาน Front End Developer ใช่หรือไม่',
                            'หากลบแล้ว ไม่สามารถเปลี่ยนแปลงได้ภายหลัง',
                            'ยิืนยัน',
                            () => {
                              const deletePositionValiable = {
                                input: {
                                  id: row.id,
                                },
                              }
                              deletePosition(deletePositionValiable).then((res: any) => {
                                props.setDeletelist(res.data.data)
                                // console.log('res delete position', res.data.data)
                              })
                              dispatch(
                                allAction.modalAction.setOpenModalAlertStatus(
                                  'ลบข้อมูลตำแหน่งงานเรียบร้อยแล้ว',
                                  '',
                                  'success',
                                ),
                              )
                              dispatch(allAction.modalConfirmAction.setCloseModalConfirm())
                            },
                          ),
                        )
                      }
                    />
                  </button>
                  {/* <div> </div> */}
                </div>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
