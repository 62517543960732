import { Grid, Stack, styled, useTheme } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import React, { ChangeEvent } from 'react'
import { ButtonComponent, InputTextIconComponent, CustomizedSwitches, ContentContainer } from '../../component'

import EditIcon from '../../image/edit-blue.svg'
import { IncomeExpenseModal } from './Modal'
import {
  createOtherIncomeOrExpenseConfig,
  getListOtherIncomeOrExpenseConfig,
  updateOtherIncomeOrExpenseConfig,
} from '../../adapter/api'
import { OtherIncomeOrExpenseConfig, EStatus, OtherIncomeOrExpenseConfigItem } from '../../utils/generated'
import Fuse from 'fuse.js'
import Swal from 'sweetalert'

export type IValue = Partial<OtherIncomeOrExpenseConfig> & {
  search?: string
  searchResult?: OtherIncomeOrExpenseConfig
  currentConfig?: Partial<OtherIncomeOrExpenseConfigItem>
}

const IncomeExpensePolicyPage = () => {
  const initialValues: IValue = {}

  const onSubmit = (values: IValue) => {
    // console.log(values)
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const [showModal, setShowModal] = React.useState<boolean>(false)

  const fetchIncomeOrExpense = async () => {
    const res = await getListOtherIncomeOrExpenseConfig()

    formik.setValues(res)
  }

  React.useEffect(() => {
    fetchIncomeOrExpense()
  }, [])

  const handleSearchChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const options = {
      keys: ['id', 'name', 'incomeExpenseType'],
      includeScore: false,
      threshold: 0.1,
    }

    const { values } = formik

    const pattern = event.target.value

    const fuseIncome = new Fuse(values.otherIncomeConfigList || [], options)
    const resultIncome = fuseIncome.search(pattern).map((e) => e.item)

    const fuseExpense = new Fuse(values.otherExpenseConfigList || [], options)
    const resultExpense = fuseExpense.search(pattern).map((e) => e.item)

    const searchResult = {
      otherIncomeConfigList: resultIncome,
      otherExpenseConfigList: resultExpense,
    }

    formik.setFieldValue('searchResult', searchResult)
  }

  const getFilterSearchList = React.useMemo<OtherIncomeOrExpenseConfig>(() => {
    const { values } = formik
    if (!values.search) {
      return {
        otherIncomeConfigList: values.otherIncomeConfigList || [],
        otherExpenseConfigList: values.otherExpenseConfigList || [],
      }
    }

    return {
      otherIncomeConfigList: values.searchResult?.otherIncomeConfigList || [],
      otherExpenseConfigList: values.searchResult?.otherExpenseConfigList || [],
    }
  }, [formik.values.search, formik.values.otherExpenseConfigList?.length, formik.values.otherIncomeConfigList?.length])

  const handleSelectConfig = async (config: OtherIncomeOrExpenseConfigItem) => {
    formik.setFieldValue('currentConfig', config)
    setShowModal(true)
  }

  const handleModalSubmit = async (config: OtherIncomeOrExpenseConfigItem) => {
    try {
      if (config.id) {
        await updateOtherIncomeOrExpenseConfig(config)
      } else {
        await createOtherIncomeOrExpenseConfig({ ...config, status: EStatus.Active })
      }

      fetchIncomeOrExpense()

      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
      console.log('error', error)
    }
    setShowModal(false)
  }

  const handleToggleStatus = async (config: OtherIncomeOrExpenseConfigItem, status: EStatus) => {
    await handleModalSubmit({ ...config, status })
  }

  return (
    <div>
      <FormikProvider value={formik}>
        <ContentContainer rowGap="2rem">
          <Stack direction="row" alignItems="center" width="40%" columnGap="1rem" mx="auto">
            <InputTextIconComponent _name="search" _onChange={handleSearchChange} />
            <ButtonComponent
              _colorBG="blue"
              _colorText="white"
              _text="เพิ่มรายการ"
              _type="button"
              _variant="text"
              _isIcon="add"
              _sx={{ width: 'fit-content', whiteSpace: 'nowrap', px: '2rem', py: '0.5rem' }}
              _functionOnClick={() => setShowModal(true)}
            />
          </Stack>

          <div>
            <HeadingBox>รายรับ</HeadingBox>
            <RowHeader />
            <GridTopic>ค่าเริ่มต้น</GridTopic>

            {getFilterSearchList.otherIncomeConfigList.map((row, index) => (
              <RowItem
                key={index}
                item={row}
                index={index}
                onSelectConfig={handleSelectConfig}
                onToggleStatus={handleToggleStatus}
                name="otherIncomeConfigList"
              />
            ))}
          </div>

          <div>
            <HeadingBox>รายจ่าย</HeadingBox>
            <RowHeader />
            <GridTopic>ค่าเริ่มต้น</GridTopic>

            {getFilterSearchList.otherExpenseConfigList?.map((row, index) => (
              <RowItem
                key={index}
                item={row}
                index={index}
                onSelectConfig={handleSelectConfig}
                onToggleStatus={handleToggleStatus}
                name="otherExpenseConfigList"
              />
            ))}
          </div>
          <IncomeExpenseModal open={showModal} onClose={setShowModal} onSubmit={handleModalSubmit} />
        </ContentContainer>
      </FormikProvider>
    </div>
  )
}

interface RowItemProps {
  item: OtherIncomeOrExpenseConfigItem
  index: number
  name: string
  onSelectConfig: (config: OtherIncomeOrExpenseConfigItem) => any
  onToggleStatus: (config: OtherIncomeOrExpenseConfigItem, status: EStatus) => any
}
const RowItem: React.FC<RowItemProps> = ({ index, name, item, onSelectConfig, onToggleStatus }) => {
  const handleToggle = (_: any, status: any) => {
    onToggleStatus(item, status)
  }

  return (
    <Grid key={index} columns={5} sx={{ backgroundColor: 'white' }} container>
      <GridBody item xs={1}>
        {index + 1}
      </GridBody>
      <GridBody item xs={1}>
        {item.incomeExpenseType}
      </GridBody>
      <GridBody item xs={1}>
        {item.id}
      </GridBody>
      <GridBody item xs={1}>
        {item.name}
      </GridBody>
      <GridBody item xs={1}>
        <Stack direction="row" columnGap="1rem" alignItems="center">
          <CustomizedSwitches.Formik
            _name={`${name}[${index}].status`}
            activeValue={EStatus.Active}
            inactiveValue={EStatus.Inactive}
            _onClick={handleToggle}
          />
          <EditButton src={EditIcon} alt="" onClick={onSelectConfig.bind(null, item)} />
        </Stack>
      </GridBody>
    </Grid>
  )
}

const RowHeader = () => {
  const theme = useTheme()
  return (
    <Grid container columns={5} sx={{ backgroundColor: theme.colors.blueLight, height: '3.5rem' }} alignItems="center">
      <GridHeader item xs={1}>
        ลำดับ
      </GridHeader>
      <GridHeader item xs={1}>
        รูปแบบคำนวณ
      </GridHeader>
      <GridHeader item xs={1}>
        รหัสอ้างอิง
      </GridHeader>
      <GridHeader item xs={1}>
        รายการ
      </GridHeader>
      <GridHeader item xs={1}>
        การกระทำ
      </GridHeader>
    </Grid>
  )
}

const HeadingBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.primary,
  color: 'white',
  width: '12rem',
  fontSize: '18px',
  borderRadius: '7px 7px 0px 0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '2.5rem',
}))

const GridHeader = styled(Grid)`
  text-align: center;
  font-weight: bold;
`

const GridTopic = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.primary,
  color: 'white',
  padding: '0.5rem 1rem',
}))

const GridBody = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bg};
`
const EditButton = styled('img')`
  cursor: pointer;
  width: 1.5rem;
`

export default IncomeExpensePolicyPage
