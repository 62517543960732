import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
// Compontnts
import { ButtonComponent } from '../../component'
//Images
import RainbowColorPicker from '../../image/rainbowColorPicker.svg'
import Delete from '../../image/delete2.svg'
import LightCorrectSymbol from '../../image/lightCorrectSymbol.svg'
import DarkCorrectSymbol from '../../image/darkCorrectSymbol.svg'
import Close from '../../image/closeShift.svg'
//Formik
import * as yup from 'yup'
import { Formik } from 'formik'
import {
  InputTextComponent,
  // InputSelectComponent,
  InputCheckboxComponent,
  InputCheckboxComponentCustom,
  InputTimeComponentCustom,
  // InputTextUnitComponent,
  InputRadioComponent,
  AutocompleteComponentCustom,
  InputNumberComponentCustom,
} from '../../component'
import { useDispatch } from 'react-redux'
import allAction from '../../state/actions'
import { isColorLight, daySelect } from '../../utils/common'
import { queryListShiftColor, queryGetShift, createShift, putHexColorList, updateShift } from '../../adapter/xhr'
import Swal from 'sweetalert2'

const AttendanceEdit: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { id } = useParams<any>()
  const formikRef: any = useRef(null)

  useEffect(() => {
    queryListShiftColor()
      .then((res: any) => {
        if (res?.data?.data?.listShiftColor?.hexColorList) {
          formikRef.current.setFieldValue('colorGlobalList', res.data.data.listShiftColor.hexColorList)
        }
      })
      .catch((err: any) => {
        console.log(err, 'err')
      })
    if (id) {
      queryGetShift({ shiftId: id })
        .then((res: any) => {
          console.log(res.data.data.getShift, 'res get shift')
          const getShift = res.data.data.getShift
          if (getShift) {
            formikRef.current.setFieldValue('shiftID', getShift.shiftID)
            formikRef.current.setFieldValue('shiftName', getShift.shiftName)
            formikRef.current.setFieldValue('workType', getShift.workType)
            formikRef.current.setFieldValue('shiftColorIndex', getShift.shiftColorIndex)
            dataCheckBox1.map((dataElement: any) => {
              if (getShift.employeeType.includes(dataElement.checkboxName)) {
                formikRef.current.setFieldValue(`${dataElement.checkboxName}`, true)
              }
            })

            const workDayPolicy = getShift.workDayPolicy.map((workDayElement: any) => {
              workDayElement.dayList = workDayElement.dayList.map((dayIndex: number) => {
                return daySelect[dayIndex]
              })
              return workDayElement
            })
            formikRef.current.setFieldValue('workDayPolicy', workDayPolicy)

            const breakPolicy = getShift.breakPolicy.map((breakElement: any) => {
              breakElement.dayList = breakElement.dayList.map((dayIndex: number) => {
                console.log(daySelect[dayIndex], 'daySelect[dayIndex]')
                return daySelect[dayIndex]
              })
              return breakElement
            })
            formikRef.current.setFieldValue('breakPolicy', breakPolicy)
          }
        })
        .catch((err: any) => {
          console.log(err, 'err')
        })
    }
  }, [])

  const validationSchema = yup.object({
    shiftName: yup.string().required('shiftName is required'),
    workType: yup.string().required('workType is required'),
    // dayList: yup.string().required('dayList is required'),
    // lateTime: yup.string().required('Date is required'),
    // unitTime: yup.string().required('Unit time is required'),
    // deductedLateEntry: yup.string().required('Unit time is required'),
  })
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        shiftID: '',
        shiftName: '',
        colorGlobalList: [],
        shiftColorIndex: 0,
        FULLTIME_MONTH: false,
        FULLTIME_DAY: false,
        PARTTIME: false,
        OUTSOURCE: false,
        workType: '',
        workDayPolicy: [
          {
            dayList: [],
            timeList: [{ isScanStart: false, isScanEnd: false, startTime: '', endTime: '' }],
            totalHours: '',
          },
        ],
        breakPolicy: [
          {
            dayList: [],
            timeList: [{ isScanStart: false, isScanEnd: false, startTime: '', endTime: '' }],
            totalHours: '',
          },
        ],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values, 'submit')
        const newWorkDayPolicy = values.workDayPolicy.map((workDayElement: any) => {
          workDayElement.dayList = workDayElement.dayList.map((dayElement: any) => dayElement.value)
          if (!isNaN(parseInt(workDayElement.totalHours))) {
            workDayElement.totalHours = parseInt(workDayElement.totalHours)
          } else {
            workDayElement.totalHours = 0
          }
          console.log(workDayElement, 'workDayElement')
          return workDayElement
        })
        const newBreakPolicy = values.breakPolicy.map((breakElement: any) => {
          breakElement.dayList = breakElement.dayList.map((dayElement: any) => dayElement.value)
          if (!isNaN(parseInt(breakElement.totalHours))) {
            breakElement.totalHours = parseInt(breakElement.totalHours)
          } else {
            breakElement.totalHours = 0
          }
          return breakElement
        })
        const employeeType = []
        if (values.FULLTIME_MONTH) employeeType.push('FULLTIME_MONTH')
        if (values.FULLTIME_DAY) employeeType.push('FULLTIME_DAY')
        if (values.PARTTIME) employeeType.push('PARTTIME')
        if (values.OUTSOURCE) employeeType.push('OUTSOURCE')
        const variables: any = {
          input: {
            shiftName: values.shiftName,
            employeeType: employeeType,
            workType: values.workType,
            shiftColorIndex: values.shiftColorIndex,
            workDayPolicy: newWorkDayPolicy,
            breakPolicy: newBreakPolicy,
          },
        }
        console.log(variables, 'variables')
        const putShiftColorInput = {
          input: {
            hexColorList: values.colorGlobalList,
          },
        }
        if (id) variables.input.shiftID = values.shiftID
        Swal.fire({
          title: `${id ? 'แก้ไข' : 'เพิ่ม'}ระเบียบการเข้างาน`,
          html: `shift : ${values.shiftName}`,
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonColor: '#3080f8',
          confirmButtonText: 'ตกลง',
          reverseButtons: true,
          customClass: {
            container: 'swal',
          },
        }).then((submit) => {
          if (submit.isConfirmed) {
            if (id) {
              updateShift(variables)
                .then((res: any) => {
                  console.log(res.data.data, 'response updateShift')
                })
                .catch((err: any) => {
                  console.log(err, 'err')
                })
            } else {
              createShift(variables)
                .then((res: any) => {
                  console.log(res.data.data, 'response createShift')
                })
                .catch((err: any) => {
                  console.log(err, 'err ')
                })
            }
            putHexColorList(putShiftColorInput)
              .then((res: any) => {
                console.log(res.data.data, 'hex Color List')
              })
              .catch((err: any) => {
                console.log(err, 'err')
              })
            navigate(-1)
            Swal.fire({
              icon: 'success',
              title: `${id ? 'แก้ไข' : 'เพิ่ม'}ระเบียบการเข้างานเรียบร้อยแล้ว`,
              showConfirmButton: false,
              timer: 1500,
            })
          }
        })
      }}
    >
      {(props: any) => (
        <form onSubmit={props.handleSubmit}>
          <div className={styles.container}>
            <section className={styles.topicPageWrapper}>
              <p className={styles.topicPage}>{id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลการเข้าทำงาน</p>
            </section>
            <div className={styles.cardContainer}>
              <section className={styles.columnInputWrapper}>
                <div className={styles.leftSideTopicCard}>
                  <InputTextComponent
                    _name="shiftName"
                    _label="ชื่อช่วงเวลาการเข้างาน"
                    _helperText={props.errors.shiftName}
                  />
                </div>
                <div className={styles.rightSideTopicCard}>
                  <p className={styles.jobType}>กรุณาเลือกแถบสี</p>
                  <section className={styles.rightSideContent}>
                    {props.values.colorGlobalList.map((e: any, index: number) => {
                      return (
                        <>
                          <label
                            className={styles.colorLabel}
                            htmlFor={`color[${index}]`}
                            style={{ backgroundColor: `${e}` }}
                          >
                            {props.values.shiftColorIndex === index ? (
                              <img src={isColorLight(e) ? LightCorrectSymbol : DarkCorrectSymbol} alt="" />
                            ) : null}
                          </label>
                          <input
                            type="color"
                            id={`color[${index}]`}
                            className={styles.inputTypeColor}
                            value={e}
                            onChange={(event: any) => {
                              props.setFieldValue(`colorGlobalList[${index}]`, event.target.value)
                            }}
                            onClick={(event: any) => {
                              props.setFieldValue('shiftColorIndex', index)
                            }}
                          />
                        </>
                      )
                    })}
                    <label className={styles.colorLabel} htmlFor="colorGlobal">
                      <img src={RainbowColorPicker} alt="" />
                    </label>
                    <input
                      id="colorGlobal"
                      onBlur={(e: any) => {
                        if (props.values.colorGlobalList.length < 10) {
                          props.values.colorGlobalList.push(e.target.value)
                          props.setFieldValue('colorGlobalList', props.values.colorGlobalList)
                        }
                      }}
                      className={styles.inputTypeColor}
                      type="color"
                    />
                  </section>
                </div>
              </section>
              <section className={styles.checkboxSection}>
                <article className={styles.checkboxContainer}>
                  <p className={styles.jobType}>ประเภทงาน</p>
                  <div className={styles.rowWrapper}>
                    {dataCheckBox1.map((Element, index) => {
                      return (
                        <div key={index} className={styles.checkBox}>
                          <InputCheckboxComponent _label={Element.checkboxLabel} _name={Element.checkboxName} />
                        </div>
                      )
                    })}
                  </div>
                </article>
                <article className={styles.radioContainer}>
                  <p className={styles.jobType}>กำหนดรูปแบบวันทำงาน</p>

                  <div className={styles.checkBox}>
                    <InputRadioComponent
                      _name={'workType'}
                      _menuList={worktypeConfig}
                      _helperText={props.errors.workType}
                    />
                  </div>
                </article>
              </section>
              {shiftContentMapper.map((shiftConfigElement: any, shiftConfigIndex: number) => {
                return (
                  <section key={shiftConfigIndex}>
                    <div className={styles.dayWorkingContainer}>
                      {props.values[`${shiftConfigElement.type}`].map((shiftTypeObj: any, shiftTypeIndex: number) => {
                        return (
                          <div key={shiftTypeIndex} className={styles.dayWorking}>
                            <p className={styles.shiftTypeTopic}>{shiftConfigElement.topic}</p>
                            <p className={styles.shiftDescription}>{shiftConfigElement.description}</p>
                            {/* {props.values[`${shiftConfigElement.type}`].length > 1 && ( */}
                            <img
                              className={styles.closeBtn}
                              onClick={() => {
                                props.values[`${shiftConfigElement.type}`].splice(shiftTypeIndex, 1)
                                props.setFieldValue(
                                  `${shiftConfigElement.type}`,
                                  props.values[`${shiftConfigElement.type}`],
                                )
                              }}
                              src={Close}
                            />
                            {/* )} */}
                            <article className={styles.dayWorkingWrapper}>
                              <AutocompleteComponentCustom
                                _value={shiftTypeObj.dayList}
                                _name={`${shiftConfigElement.type}[${shiftTypeIndex}].dayList`}
                                _label="วัน"
                                // _helperText={props.errors.dayList}
                                _option={daySelect}
                                _style={{ flexBasis: '25%', display: 'flex' }}
                              />
                              <div className={styles.workingTimeContainer}>
                                {props.values.workType === 'HOUR' ? (
                                  <InputNumberComponentCustom
                                    _label="จำนวนชั่วโมงการทำงาน"
                                    _value={shiftTypeObj.hours}
                                    _name={`${shiftConfigElement.type}[${shiftTypeIndex}].totalHours`}
                                    // _helperText
                                  />
                                ) : (
                                  // null
                                  shiftTypeObj.timeList.map((timeObj: any, timeIndex: number) => {
                                    return (
                                      <div className={styles.timeWorkingWrapper} key={timeIndex}>
                                        <InputCheckboxComponentCustom
                                          _style={{ margin: 0 }}
                                          _label=""
                                          _name={`${shiftConfigElement.type}[${shiftTypeIndex}].timeList[${timeIndex}].isScanStart`}
                                          _value={timeObj.isScanStart}
                                        />

                                        <InputTimeComponentCustom
                                          _name={`${shiftConfigElement.type}[${shiftTypeIndex}].timeList[${timeIndex}].startTime`}
                                          _label={
                                            shiftConfigElement.type === 'workDayPolicy' ? 'เวลาเข้าทำงาน' : 'เวลาเริ่ม'
                                          }
                                          _helperText={props.errors.startTime}
                                          _value={timeObj.startTime}
                                        />
                                        <InputCheckboxComponentCustom
                                          _style={{ margin: 0 }}
                                          _label=""
                                          _name={`${shiftConfigElement.type}[${shiftTypeIndex}].timeList[${timeIndex}].isScanEnd`}
                                          _value={timeObj.isScanEnd}
                                        />

                                        <InputTimeComponentCustom
                                          _name={`${shiftConfigElement.type}[${shiftTypeIndex}].timeList[${timeIndex}].endTime`}
                                          _label={
                                            shiftConfigElement.type === 'workDayPolicy'
                                              ? 'เวลาเลิกทำงาน'
                                              : 'เวลาสิ้นสุด'
                                          }
                                          _helperText={props.errors.CheckOut}
                                          _value={timeObj.endTime}
                                        />
                                        <div className={styles.deleteTimeWrapper}>
                                          {shiftTypeObj.timeList.length > 1 ? (
                                            <button
                                              onClick={() => {
                                                props.values[`${shiftConfigElement.type}`][
                                                  shiftTypeIndex
                                                ].timeList.splice(timeIndex, 1)
                                                console.log(props.values, 'ping values')
                                                console.log('sssss', timeIndex)
                                                props.setFieldValue(
                                                  `${shiftConfigElement.type}`,
                                                  props.values[`${shiftConfigElement.type}`],
                                                )
                                              }}
                                              type="button"
                                              className={styles.deleteTime}
                                            >
                                              <img src={Delete} />
                                            </button>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    )
                                  })
                                )}
                              </div>
                            </article>
                            {shiftConfigElement.type === 'breakPolicy' && props.values.workType !== 'HOUR' ? (
                              <article className={styles.btnDayWorking}>
                                <div className={styles.btnBox}>
                                  <ButtonComponent
                                    _type="button"
                                    _colorBG="white"
                                    _colorText="secondary"
                                    _variant="outlined"
                                    _isIcon="add"
                                    _text="เพิ่มเวลาพักเบรก"
                                    _functionOnClick={() => {
                                      props.values[`${shiftConfigElement.type}`][shiftTypeIndex].timeList.push({
                                        isScanStart: false,
                                        isScanEnd: false,
                                        startTime: '',
                                        endTime: '',
                                      })
                                      props.setFieldValue(
                                        `${shiftConfigElement.type}`,
                                        props.values[`${shiftConfigElement.type}`],
                                      )
                                    }}
                                  />
                                </div>
                              </article>
                            ) : null}
                          </div>
                        )
                      })}
                    </div>

                    <div className={styles.btnBox}>
                      <ButtonComponent
                        _type="button"
                        _colorBG="white"
                        _colorText="secondary"
                        _variant="text"
                        _isIcon="add"
                        _text={shiftConfigElement.buttonText}
                        _functionOnClick={() => {
                          props.values[`${shiftConfigElement.type}`].push({
                            dayList: [],
                            timeList: [{ isScanStart: false, isScanEnd: false, startTime: '', endTime: '' }],
                          })
                          props.setFieldValue(`${shiftConfigElement.type}`, props.values[`${shiftConfigElement.type}`])
                          // console.log('เพิ่มวันทำงาน', props.values.dayWorking)
                        }}
                      />
                    </div>
                  </section>
                )
              })}
              <section className={styles.btnFooterWrapper}>
                <div className={styles.btnBox}>
                  <ButtonComponent
                    _colorBG="white"
                    _colorText="red"
                    _text="ยกเลิก"
                    _variant="contained"
                    _type="button"
                    _functionOnClick={() => history.back()}
                  />
                </div>
                <div className={styles.btnBox}>
                  <ButtonComponent
                    _colorBG="blue"
                    _colorText="white"
                    _text={id ? 'แก้ไข' : 'เพิ่ม'}
                    _variant="contained"
                    _type="submit"
                  />
                </div>
              </section>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
export default AttendanceEdit

const dataCheckBox1 = [
  {
    checkboxLabel: 'พนักงานรายเดือน',
    checkboxName: 'FULLTIME_MONTH',
  },
  {
    checkboxLabel: 'พนักงานรายวัน',
    checkboxName: 'FULLTIME_DAY',
  },
  {
    checkboxLabel: 'พนักงานพาร์ทไทม์',
    checkboxName: 'PARTTIME',
  },
  {
    checkboxLabel: 'พนักงานจ้างเหมา',
    checkboxName: 'OUTSOURCE',
  },
]

const worktypeConfig = [
  {
    label: 'กำหนดการเข้าออก คืนเดียวกัน',
    value: 'SAME_DAY',
  },
  {
    label: 'กำหนดการเข้าออก ข้ามเที่ยงคืนของพรุ่งนี้',
    value: 'TODAY_TOMORROW',
  },
  {
    label: 'กำหนดการเข้าออก ข้ามเที่ยงคืนของเมื่อวาน',
    value: 'YESTERDAY_TODAY',
  },
  {
    label: 'กำหนดชั่วโมงการทำงานรวม',
    value: 'HOUR',
  },
]

const shiftContentMapper = [
  {
    type: 'workDayPolicy',
    topic: 'วันทำงาน',
    buttonText: 'เพิ่มวันทำงาน',
    description: '',
  },
  {
    type: 'breakPolicy',
    topic: 'พักเบรก',
    buttonText: 'เพิ่มวันพักเบรก',
    description: 'ชั่วโมงพักเบรกจะไม่นำมาคิดเงินเดือน',
  },
]
