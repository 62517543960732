//lib
import React from 'react'
import { Modal, Backdrop } from '@mui/material'
import { Formik, FormikProps, useFormikContext } from 'formik'
import Swal from 'sweetalert2'
import * as Yup from 'yup'

//Include Project
import styles from './index.module.scss'
import { ButtonComponent, ButtonSelectCustom, InputTimeComponentCustom } from '../../../component'
import { InputDateComponentCustome, InputSelectComponentCustom } from '../../../component/InputsFormik'
import { CheckIntervals } from '../../../utils/common'
import { SaveType } from '../EType'
import { EOverTimeType } from 'src/utils/generated'
// import { toMinute } from 'src/utils/resetTimeAttendanceReport'
import { getOvertimeMinute } from 'src/utils/time'
import { useOvertimeDocumnet } from 'src/hooks'
import { IOvertimeDocument } from 'src/utils/formikInterfce'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ModalOverTime: React.FC<Props> = ({ open, setOpen }: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [status, setStatus] = React.useState('APPROVE')
  const statusType = (e: string) => {
    setStatus(e)
  }
  const { createOvertime } = useOvertimeDocumnet()

  const validationSchema = Yup.object({
    overtimeType: Yup.string().required('เลือกประเภทการทำงานล่วงเวลา'),
    startedAt: Yup.string().required('กรอกเวลาเริ่มต้น'),
    endedAt: Yup.string().required('กรอกเวลาสิ้นสุด'),
  })

  const handleClose = () => {
    setOpen(false)
  }

  const handleUpdate = (overtimeInput: IOvertimeDocument) => {
    const packData = {
      input: {
        date: values.attendance.date,
        employeeID: values.attendance.employeeID,
        startedAt: overtimeInput.startedAt,
        endedAt: overtimeInput.endedAt,
        status: status,
        overtimeType: overtimeInput.overtimeType,
        overtimeMinute: getOvertimeMinute(values.documentOvertime.startedAt, values.documentOvertime.endedAt),
      },
    }

    console.log(packData, 'packData')

    const NewIntervals: { startedAt: string; endedAt: string } = {
      startedAt: values.startedAt,
      endedAt: values.endedAt,
    }
    const resultIntervalsCheck = CheckIntervals(values.report.overtimeList, NewIntervals)
    if (resultIntervalsCheck === true) {
      Swal.fire({
        title: `มีช่วงเวลาโอทีนี้แล้ว`,
        html: `สามารถสร้างรายการจากเอกสารแนะนำหรือเลือกช่วงเวลาอื่น`,
        icon: 'error',
        customClass: {
          container: 'swal',
        },
      })
    } else if (resultIntervalsCheck === false) {
      Swal.fire({
        title: `บันทึกเอกสารการทำโอที`,
        html: `${values.attendance.employee.fullName} ${values.attendance.employee.lastName} </br> วันที่ ${values.attendance.date}`,
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#3080f8',
        confirmButtonText: 'ตกลง',
        reverseButtons: true,
        customClass: {
          container: 'swal',
        },
      }).then((create) => {
        if (create.isConfirmed) {
          createOvertime({ variables: packData }).then((res: any) => {
            values.report.overtimeList.push({
              startedAt: overtimeInput.startedAt,
              endedAt: overtimeInput.endedAt,
              status: status,
              overtimeType: overtimeInput.overtimeType,
              overtimeRequestID: res.data.createOvertimeRequest.id,
              overtimeMinute: values.documentOvertime.overtimeMinute,
            })
            setFieldValue(`report.overtimeList`, values.report.overtimeList)
            handleClose()
          })
        }
      })
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={styles.modalLeaveEdit}>
        <style lang="scss">{`
      .swal {
      z-index: 3000;
      }
      `}</style>
        <div className={styles.card}>
          <Formik
            initialValues={{
              overtimeType: '',
              startedAt: '',
              endedAt: '',
              status: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleUpdate(values)
              console.log(values)
            }}
          >
            {(props: FormikProps<IOvertimeDocument>) => (
              <form onSubmit={props.handleSubmit} className={styles.wrapperForm}>
                <div className={styles.header}>
                  <div className={styles.bt}>
                    <ButtonComponent
                      _type={'button'}
                      _colorBG={'white'}
                      _colorText={'red'}
                      _variant={'text'}
                      _text={'ยกเลิก'}
                      _sx={{ fontSize: '16px' }}
                      _functionOnClick={handleClose}
                    />
                  </div>
                  <p className={styles.textHead}>สร้างใหม่</p>
                  <div className={styles.btSelect}>
                    <ButtonSelectCustom
                      _selectData={SaveType}
                      _onchange={(e: any) => {
                        statusType(e)
                      }}
                      _functionOnclick={() => {
                        props.handleSubmit()
                      }}
                    />
                  </div>
                </div>
                <div className={styles.FormGroup}>
                  <div className={styles.filedInputFlex}>
                    <p>ประเภทโอที</p>
                    <div className={styles.inputBox}>
                      <InputSelectComponentCustom
                        _name="overtimeType"
                        _label={'ประเภทโอที'}
                        _menuList={[
                          { label: 'วันทำงาน', value: EOverTimeType.Work },
                          { label: 'ทำงานล่วงเวลา', value: EOverTimeType.Overtime },
                        ]}
                        _helperText={`${props.errors.overtimeType}`}
                      />
                    </div>
                  </div>

                  <div className={styles.filedInputTime}>
                    <p>ช่วงเวลา</p>
                    <section className={styles.GroupBox}>
                      <div className={styles.InputTime}>
                        <InputTimeComponentCustom
                          _name="startedAt"
                          _label={'เริ่มต้น'}
                          _helperText={`${props.errors.startedAt}`}
                        />
                      </div>
                      <div className={styles.InputTime}>
                        <InputTimeComponentCustom
                          _name="endedAt"
                          _label={'สิ้นสุด'}
                          _helperText={`${props.errors.endedAt}`}
                        />
                      </div>
                    </section>
                  </div>
                  <div className={styles.filedInputFlex}>
                    <p>วันที่</p>
                    <div className={styles.inutBox}>
                      <InputDateComponentCustome
                        _name={'date'}
                        _value={values.attendance.date}
                        _disabled={true}
                        _label={'วันที่'}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
