//lib
import React from 'react'
import { useFormikContext } from 'formik'

//Include Project
import { UploadFileComponent } from 'src/component'
import styles from './index.module.scss'
import { ELeaveType, EStatus } from 'src/pages/AttendanceReport/EType'

const LeaveAttendaceReportView: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()

  const CheckQuota = (quota: number) => {
    if (isNaN(quota)) {
      return 0
    } else {
      return quota
    }
  }
  const GetQuota = (leaveType: string, type: string) => {
    const employeeQuata = values.attendance.employee.listLeaveQuota?.items
    if (type === 'leave') {
      const balanceQaota = employeeQuata.find((ele: any) => ele.prev >= 0 && ele.leaveConfig.id === leaveType)
      return CheckQuota(balanceQaota?.begin - balanceQaota?.used)
    } else {
      const balanceQaota = employeeQuata.find((ele: any) => ele.prev < 0 && ele.leaveConfig.id === leaveType)
      return CheckQuota(balanceQaota?.begin - balanceQaota?.used)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapperInput}>
        <div className={styles.gropHead}>
          <p className={styles.TextHead}>การลา</p>
        </div>
        {values.report.leaveList?.map((itemLeave: any, Index: any) => (
          <section className={styles.cardDocument} key={Index}>
            <div className={styles.box}>
              <div className={styles.headButton}>
                <section className={returnstyles(itemLeave.status)}>
                  <p>{EStatus[`${itemLeave.status}`]}</p>
                </section>
              </div>

              <section className={styles.inputBox}>
                <p className={styles.descriptionText}>ประเภทการลา</p>
                <div className={styles.input}>
                  <p>
                    {itemLeave.leaveType} ({ELeaveType[`${itemLeave.type}`]})
                  </p>
                </div>
              </section>

              <section className={styles.inputBox}>
                <p className={styles.descriptionText}>รูปแบบการลา</p>
                <div className={styles.input}>
                  <p>{itemLeave.leaveForm == null ? 'ขาด' : itemLeave.leaveForm}</p>
                </div>
              </section>

              {itemLeave.leaveForm === 'FULLDAY_LEAVE' ? (
                <section className={styles.inputBox}>
                  <p className={styles.descriptionText}>ช่วงวันที่</p>
                  <div className={styles.groupBox}>
                    <p>
                      {itemLeave.startedAt} - {itemLeave.endedAt}
                    </p>
                  </div>
                </section>
              ) : (
                <section className={styles.inputBox}>
                  <p className={styles.descriptionText}>ช่วงเวลา</p>
                  <div className={styles.groupBox}>
                    <p>
                      {itemLeave.startedAt} - {itemLeave.endedAt}
                    </p>
                  </div>
                </section>
              )}

              <section className={styles.inputBox}>
                <p className={styles.descriptionText}>เอกสารแนบ</p>
                <div className={styles.input}>
                  <UploadFileComponent
                    name={`report.leaveList[${Index}].leaveDocumentAttachment`}
                    flodername={'LeaveDocument'}
                    valueShow={itemLeave.leaveDocumentAttachment}
                    label="แนบเอกสารการลา"
                    id={values.attendance.employeeID}
                    disabled={true}
                  />
                </div>
              </section>
              {values.attendance.employee.listLeaveQuota.items.length > 0 && itemLeave.leaveType !== 'ABSENT' ? (
                <p className={styles.textQuota}>
                  วันลาคงเหลือ{''}
                  <span>
                    {GetQuota(itemLeave.leaveType, itemLeave.type) < 0
                      ? 'ไม่จำกัด'
                      : GetQuota(itemLeave.leaveType, itemLeave.type)}
                  </span>
                  <span> {''}วัน</span>
                </p>
              ) : (
                ''
              )}
            </div>
          </section>
        ))}
      </div>
    </div>
  )
}

const returnstyles = (status: any) => {
  if (status === 'APPROVE') {
    return `${styles.pillApprove}`
  } else if (status === 'PENDING') {
    return `${styles.pillPending}`
  } else if (status === 'DRAFT') {
    return `${styles.pillDraft}`
  } else if (status === 'REJECT') {
    return `${styles.pillReject}`
  } else {
    return `${styles.pillSuggest}`
  }
}

export default LeaveAttendaceReportView
