import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
// Components
import { ButtonComponent, CalendarComponent } from '../../component'
//PrintCalendar
// import ReactToPrint from 'react-to-print'
//Formik
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { InputTextComponent } from '../../component'
// import PrinterIcon from '../../image/prin.svg'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { CreateHolidayPolicy, GetHoliday, UpdateHolidayOnYear } from '../../adapter/xhr'
import Swal from 'sweetalert2'

const HolidayPolicyEditPage: React.FC = (): JSX.Element => {
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [year, setYear] = useState<number>(new Date().getFullYear())
  // console.log(year, 'year')

  const formikRef: any = useRef()

  const baseURL =
    'https://www.googleapis.com/calendar/v3/calendars/th.th%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAoZVNllZof84luxMOlGXXJc9snfZb5UqI'
  const [holiday, setHoliday] = useState([])
  const queryHoliday = () => {
    axios.get(baseURL).then((res: any) => {
      // console.log(res, 'Calendar')
      setHoliday(res.data.items)
    })
  }
  useEffect(() => {
    queryHoliday()
  }, [])
  useEffect(() => {
    if (id) {
      console.log('SET HOLIDAY')
      GetHoliday({ holidayId: id, year: year })
        .then((res: any) => {
          formikRef.current.setFieldValue('holidayName', res.data.data.getHoliday.holidayName)
          formikRef.current.setFieldValue('holidayYear', JSON.parse(res.data.data.getHoliday.holidayYear)[year] || [])
        })
        .catch((error: any) => {
          console.log(error, 'error')
        })
      setMode('update')
    } else {
      setMode('create')
    }
  }, [year])

  const GoogleCalendar: any[] = []
  holiday.map((item: any) => {
    return GoogleCalendar.push({
      date: item.start.date,
      description: item.summary,
    })
  })
  const HolidayOfYear = GoogleCalendar.filter((findele: any) => {
    return new Date(findele.date).getFullYear() === year
  })

  const Sort = HolidayOfYear.sort((a, b) => b.date - a.date)
  // console.log(Sort, 'sort')
  const navigate = useNavigate()
  const validationSchema = yup.object({
    holidayName: yup.string().required('กรอกชื่อวันหยุด'),
  })

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        year: '',
        holidayName: '',
        holidayYear: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        const UpdateHolidayListVariable = {
          input: {
            holidayID: id,
            holidayName: values.holidayName,
            year: year,
            holidayYear: values.holidayYear,
          },
        }
        Swal.fire({
          title: `${mode === 'create' ? 'เพิ่ม' : 'แก้ไข'}ระเบียบวันหยุดประจำปี`,
          html: `${values.holidayName}`,
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonColor: '#3080f8',
          confirmButtonText: 'ตกลง',
          reverseButtons: true,
          customClass: {
            container: 'swal',
          },
        }).then((submit) => {
          if (submit.isConfirmed) {
            if (mode === 'create') {
              CreateHolidayPolicy({
                year: year,
                input: { holidayName: values.holidayName, holidayYear: values.holidayYear },
              }).then((res: any) => {
                console.log('Createt Holiday', res)
                Swal.fire({
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                  html: `เพิ่ม ${values.holidayName} เรียบร้อยแล้ว`,
                  customClass: {
                    container: 'swal',
                  },
                })
              })
            } else if (mode === 'update') {
              UpdateHolidayOnYear({
                input: {
                  year: year,
                  holidayID: id,
                  holidayName: values.holidayName,
                  holidayYear: values.holidayYear,
                },
              }).then((res: any) => {
                Swal.fire({
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                  html: `แก้ไขข้อมูล ${values.holidayName} เรียบร้อยแล้ว`,
                  customClass: {
                    container: 'swal',
                  },
                })
              })
            }

            navigate(-1)
          }
        })
      }}
    >
      {(props: any) => (
        <div className="main">
          <form onSubmit={props.handleSubmit}>
            <div className={styles.container}>
              <section className={styles.topicPageWrapper}>
                {mode === 'create' && <p className={styles.topicPage}>เพิ่มระเบียบวันหยุดประจำปี</p>}
                {mode === 'update' && <p className={styles.topicPage}>แก้ไขระเบียบวันหยุดประจำปี</p>}
                {/* <img src={PrinterIcon} alt="" onClick={() => window.print()} /> */}
                {/* <ReactToPrint trigger={() => <img src={PrinterIcon} alt="" />} content={() => } /> */}
              </section>

              <div className={styles.cardContainer}>
                <p className={styles.textred}>*เลือกวันหยุดประจำปีขั้นต่ำ 13 วันตามกฎหมายแรงงาน</p>
                <section className={styles.headerCalenDar}>
                  {' '}
                  <div className={styles.inputHoliday}>
                    {' '}
                    <InputTextComponent
                      _name="holidayName"
                      _label="ชื่อวันหยุดประจำปี"
                      _helperText={props.errors.holidayName}
                    />
                  </div>
                  <div className={styles.ButtonHoliday}>
                    {' '}
                    <ButtonComponent
                      _colorBG="blue"
                      _colorText="white"
                      _text="ดึงข้อมูลวันหยุด"
                      _variant="contained"
                      _type="button"
                      _functionOnClick={() => {
                        props.setFieldValue('holidayYear', HolidayOfYear)
                      }}
                    />
                  </div>
                </section>
                <CalendarComponent
                  values={props.values}
                  setFieldValue={props.setFieldValue}
                  year={year}
                  setYear={setYear}
                />
                <section className={styles.btnFooterWrapper}>
                  <div className={styles.btnBox}>
                    <ButtonComponent
                      _colorBG="white"
                      _colorText="red"
                      _text="ยกเลิก"
                      _variant="contained"
                      _type="button"
                      _functionOnClick={() => navigate(-1)}
                    />
                  </div>
                  <div className={styles.btnBox}>
                    <ButtonComponent
                      _colorBG="blue"
                      _colorText="white"
                      _text="บันทึกวันหยุด"
                      _variant="contained"
                      _type="submit"
                      // _functionOnClick={() => console.log('xxx')}
                    />
                  </div>
                </section>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  )
}
export default HolidayPolicyEditPage
