import React, { useRef, useState, useEffect } from 'react'
import styles from './index.module.scss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { InputNumberComponentCustom } from '../../../component'
import { EmployeePayroll } from 'src/utils/generated'
import cn from 'classnames'

export interface ITableSSHeader {
  text: string
  key: string
  columnWidth?: string
  color?: string
  align?: 'center' | 'left'
  transformer?: (s: any) => string
  isBold?: boolean
  bodyBackgroundColor?: string
  isEnableShow?: boolean
}

interface ITableProps {
  //   topic: 'รายรับ' | 'รายจ่าย'
  tableHeaderList: ITableSSHeader[]

  valueList: EmployeePayroll[]
  // tableBodyUniqueDepartment: any
  TableSummaryHeaderList: string[]
  // employeeCount: number
  isShowMoney?: boolean
  searchValue?: any
}

const CheckType = (type: any) => {
  if (typeof type === 'number') {
    return type.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return type
  }
}

const SummarySalaryTable: React.FC<ITableProps> = (props: ITableProps): JSX.Element => {
  function renderCell(epIndex: number, key: string, ep: EmployeePayroll, header: ITableSSHeader) {
    let content
    const cellStyle: React.CSSProperties = {}
    if (header.key === 'No.') {
      content = epIndex + 1
    } else if (header.key === 'employeeName') content = `${ep.employee?.fullName || ''} ${ep.employee?.lastName || ''}`
    else
      content = header.transformer
        ? header.transformer(ep[header.key as keyof EmployeePayroll])
        : CheckType(ep[header.key as keyof EmployeePayroll])
    // Styles
    if (header.key === 'No.') {
      cellStyle['position'] = 'sticky'
      cellStyle['left'] = '0px'
      // cellStyle['zIndex'] = '10'
    } else if (header.key === 'employeeID') {
      cellStyle['position'] = 'sticky'
      cellStyle['left'] = '60px'
    } else if (header.key === 'employeeName') {
      cellStyle['position'] = 'sticky'
      cellStyle['left'] = '160px'
    }
    if (header.align) cellStyle['justifyContent'] = header.align
    if (header.color) cellStyle['color'] = header.color
    if (header.isBold) cellStyle['fontWeight'] = 'bold'
    return (
      <td key={key} style={cellStyle}>
        {content || 0}
      </td>
    )
  }

  function headerStyle(header: ITableSSHeader): React.CSSProperties {
    // return {}
    if (header.key === 'No.') return { position: 'sticky', top: '0px', left: '0px', zIndex: '5' }
    else if (header.key === 'employeeID') return { position: 'sticky', top: '0px', left: '60px', zIndex: '5' }
    else if (header.key === 'employeeName') return { position: 'sticky', top: '0px', left: '160px', zIndex: '5' }
    return { position: 'sticky', top: '0px', zIndex: '4' }
  }
  return (
    <>
      <div className={styles.overflowContainer}>
        <table
          className={styles.tableWrapper}
          style={{ gridTemplateColumns: `${props.tableHeaderList.map((c) => c.columnWidth || '150px').join(' ')}` }}
        >
          {/* Table Header */}
          <thead>
            <tr>
              {props.tableHeaderList.map((header, headerIndex) => (
                // This needs to be sticky because thead,tr is display:content?
                <th key={headerIndex} style={headerStyle(header)}>
                  {header.text}
                </th>
              ))}
            </tr>
          </thead>
          {/* Table Contents */}
          <tbody>
            {props.valueList.map((ep, epIndex) => (
              <tr key={epIndex}>
                {props.tableHeaderList.map((header, headerIndex) =>
                  renderCell(epIndex, `${epIndex}-${headerIndex}`, ep, header),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* This table is removable, but now just keeping it for reference. */}
      <div className={styles.tableContainer}>
        <TableContainer>
          <Table aria-label="simple table">
            <colgroup>
              {props.tableHeaderList.map((headerString: ITableSSHeader, headerIndex: number) => (
                <col key={headerIndex} width={'200px'} />
              ))}
            </colgroup>
            {/* <TableHead>
              <TableRow
                sx={{
                  borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                  position: 'sticky',
                  top: '0',
                  backgroundColor: '#3A77DA',
                  zIndex: 5,
                }}
              >
                {[...props.tableHeaderList, ...props.tableHeaderList].map(
                  (headerString: ITableSSHeader, headerIndex: number) => {
                    return (
                      <TableCell
                        width="10%"
                        sx={
                          headerString.text === 'ลำดับ'
                            ? { position: 'sticky', left: 0, backgroundColor: '#3A77DA', color: '#FFFFFF' }
                            : headerString.text === 'รหัส'
                            ? {
                                position: 'sticky',
                                left: '65px',
                                backgroundColor: '#3A77DA',
                                color: '#FFFFFF',
                              }
                            : headerString.text === 'ชื่อพนักงาน'
                            ? {
                                position: 'sticky',
                                left: '145px',
                                backgroundColor: '#3A77DA',
                                color: '#FFFFFF',
                              }
                            : { border: 'none', color: 'white' }
                        }
                        align="center"
                        key={headerIndex}
                      >
                        <p>{headerString.text}</p>
                      </TableCell>
                    )
                  },
                )}
              </TableRow>
            </TableHead> */}
            {/* <TableBody>
              {props.valueList.map((ep, epIndex) => (
                <TableRow
                  sx={{
                    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                    borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                  }}
                  key={epIndex}
                >
                  {props.tableHeaderList.map((headerString, headerIndex) => {
                    return (
                      <>
                        {headerString.key === 'No.' ? (
                          <TableCell
                            sx={{
                              border: 'none',
                              position: 'sticky',
                              left: 0,
                              backgroundColor: '#E5E5E5',
                              fontWeight: 'bold',
                            }}
                            align="center"
                            key={headerIndex}
                          >
                            <p>{epIndex + 1}</p>
                          </TableCell>
                        ) : headerString.key === 'employeeID' ? (
                          <TableCell
                            sx={{
                              border: 'none',
                              position: 'sticky',
                              left: '65px',
                              backgroundColor: '#E5E5E5',
                              fontWeight: 'bold',
                            }}
                            align="center"
                            key={headerIndex}
                          >
                            {ep[headerString.key]}
                          </TableCell>
                        ) : headerString.key === 'employeeName' ? (
                          <TableCell
                            sx={{
                              border: 'none',
                              position: 'sticky',
                              left: '145px',
                              backgroundColor: '#E5E5E5',
                              fontWeight: 'bold',
                            }}
                            align="center"
                            key={headerIndex}
                          >
                            {(ep.employee?.fullName ? ep.employee?.fullName : null) +
                              ' ' +
                              (ep.employee?.lastName ? ep.employee?.lastName : null)}
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={
                              headerString.bodyBackgroundColor
                                ? { border: 'none', backgroundColor: headerString.bodyBackgroundColor }
                                : { border: 'none' }
                            }
                            align="center"
                            key={headerIndex}
                          >
                            {headerString.isEnableShow && !props.isShowMoney
                              ? 'xxx'
                              : CheckType(ep[headerString.key as keyof EmployeePayroll])}
                          </TableCell>
                        )}
                      </>
                    )
                  })}
                </TableRow>
              ))}
               {props?.tableBodyUniqueDepartment &&
            Object.keys(props?.tableBodyUniqueDepartment).length &&
            Object.keys(props?.tableBodyUniqueDepartment)
              // .filter((item: any) => {
              //   if (props.searchValue === '') {
              //     return item
              //   } else if (item.toLocaleLowerCase().includes(props.searchValue.toLocaleLowerCase())) {
              //     return item
              //   }
              // })
              .map((departmentName: string, tableBodyIndex: number) => {
                return (
                  <>
                    <TableRow
                      key={tableBodyIndex}
                      sx={{
                        borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                        borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                        color: '#3A77DA',
                        backgroundColor: '#CAD6EA',
                      }}
                    >
                      <TableCell sx={{ border: 'none' }} colSpan={props.tableHeaderList.length} align="left">
                        {departmentName}
                      </TableCell>
                    </TableRow>

                    {props?.tableBodyUniqueDepartment[departmentName]?.map(
                      (employeeElement: any, employeeIndex: number) => {
                        return (
                          <TableRow
                            sx={{
                              borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                              borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                              borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                            }}
                            key={employeeIndex}
                          >
                            {console.log(employeeElement, 'employeeElement')}
                            {props.tableHeaderList.map((headerString: ITableSSHeader, headerIndex: number) => {
                              return (
                                <>
                                  {headerString.key === 'No.' ? (
                                    <TableCell
                                      sx={{
                                        border: 'none',
                                        position: 'sticky',
                                        left: 0,
                                        backgroundColor: '#E5E5E5',
                                        fontWeight: 'bold',
                                      }}
                                      align="center"
                                      key={headerIndex}
                                    >
                                      <p>{tableBodyIndex + 1}</p>
                                    </TableCell>
                                  ) : headerString.key === 'employeeID' ? (
                                    <TableCell
                                      sx={{
                                        border: 'none',
                                        position: 'sticky',
                                        left: '65px',
                                        backgroundColor: '#E5E5E5',
                                        fontWeight: 'bold',
                                      }}
                                      align="center"
                                      key={headerIndex}
                                    >
                                      {employeeElement[headerString.key]}
                                    </TableCell>
                                  ) : headerString.key === 'employeeName' ? (
                                    <TableCell
                                      sx={{
                                        border: 'none',
                                        position: 'sticky',
                                        left: '145px',
                                        backgroundColor: '#E5E5E5',
                                        fontWeight: 'bold',
                                      }}
                                      align="center"
                                      key={headerIndex}
                                    >
                                      {employeeElement[headerString.key]}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      sx={
                                        headerString.bodyBackgroundColor
                                          ? { border: 'none', backgroundColor: headerString.bodyBackgroundColor }
                                          : { border: 'none' }
                                      }
                                      align="center"
                                      key={headerIndex}
                                    >
                                      {headerString.isEnableShow && !props.isShowMoney
                                        ? 'xxx'
                                        : CheckType(employeeElement[headerString.key])}
                                    </TableCell>
                                  )}
                                </>
                              )
                            })}
                          </TableRow>
                        )
                      },
                    )}
                    <TableRow
                      sx={{
                        borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                        borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                        color: '#3A77DA',
                        backgroundColor: '#CAD6EA',
                      }}
                    >
                      <TableCell sx={{ border: 'none' }} colSpan={3} align="left">
                        รวมจำนวนพนักงานของ {departmentName} {props?.tableBodyUniqueDepartment[departmentName]?.length}{' '}
                        คน
                      </TableCell>
                      {props.TableSummaryHeaderList?.map((sumHeaderString: string, sumHeaderIndex: number) => {
                        let summaryArr: any[] = props?.tableBodyUniqueDepartment[departmentName]?.map(
                          (employeeElement: any) => {
                            if (employeeElement[sumHeaderString]) {
                              return employeeElement[sumHeaderString]
                            }
                          },
                        )
                        summaryArr = summaryArr.filter(Boolean)
                        if (!props.isShowMoney) {
                          return (
                            <TableCell sx={{ border: 'none' }} align="center">
                              xxx
                            </TableCell>
                          )
                        } else {
                          if (summaryArr.length !== 0) {
                            const totalSum = summaryArr
                              .map((summaryVal: any) => {
                                return parseFloat(summaryVal)
                              })
                              .reduce((prev: number, curr: number) => prev + curr)
                            return (
                              <TableCell sx={{ border: 'none' }} align="center">
                                {Number.isInteger(totalSum)
                                  ? totalSum
                                  : totalSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </TableCell>
                            )
                          } else {
                            return (
                              <TableCell sx={{ border: 'none' }} align="center">
                                0
                              </TableCell>
                            )
                          }
                        }
                      })}
                    </TableRow>
                  </>
                )
              })} 
              <TableRow
                sx={{
                  borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
                  backgroundColor: '#3A77DA',
                }}
              >
                <TableCell
                  sx={{ border: 'none', color: 'white' }}
                  colSpan={props.tableHeaderList.length - 1}
                  align="left"
                >
                  รวมจำนวนพนักงานทั้งหมดของสำนักงานใหญ่ {props.valueList.length} คน
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="center">
                   {!props.isShowMoney
                ? 'xxx'
                : props?.tableBodyUniqueDepartment &&
                  Object.keys(props?.tableBodyUniqueDepartment).length &&
                  Object.keys(props?.tableBodyUniqueDepartment)
                    .map((departmentName: string) => {
                      const totalIncomeArr = props?.tableBodyUniqueDepartment[departmentName]
                        .map((employeeElement: any) => {
                          if (employeeElement.netIncome) {
                            return employeeElement.netIncome
                          }
                        })
                        .filter(Boolean)
                      const sumTotalIncome = totalIncomeArr.map((summaryVal: any) => {
                        return summaryVal.toFixed(2)
                      }).length
                        ? totalIncomeArr
                            .map((summaryVal: any) => {
                              return parseFloat(summaryVal)
                            })
                            .reduce((prev: number, curr: number) => prev + curr)
                        : 0

                      return sumTotalIncome
                    })
                    .reduce((prev: number, curr: number) => prev + curr)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                </TableCell>
              </TableRow>
            </TableBody> */}
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

export default SummarySalaryTable
