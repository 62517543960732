//lib
import { gql, useQuery } from '@apollo/client'
import { Form, useFormikContext, withFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { LinearProgress } from '@mui/material'
import * as yup from 'yup'
import _ from 'lodash'

//Images
import arrowSVG from '../../image/arrowCircle.svg'

//Include Project
import { queryotherIncome } from 'src/adapter/xhr/query'
import { ButtonComponent } from 'src/component'
import {
  EmployeeBackgroundTab,
  EmployeeCompensationTab,
  EmployeeDocument,
  EmployeeEmploymentTab,
  EmployeeLeaveQuotaTab,
  EmployeePaymentTab,
  EmployeeProfileNavbar,
  EmployeeTax,
  EmployeeUserTab,
} from 'src/container'
import EmployeePersonalTab from 'src/container/EmployeePersonal'
import styles from './index.module.scss'
import { combineList, remove__typename } from 'src/utils/common'
import { EIncomeExpenseType } from 'src/utils/generated'
import { useGetEmployee } from 'src/adapter/api/employee'
import useFormInput from 'src/hooks/useFormInput'
import { useEmployee } from 'src/hooks'

const EmployeeProfile: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { values, setFieldValue, errors, handleSubmit } = useFormikContext<any>()
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [tab, setTab] = useState<string>('personal')
  //Ref
  const personalRef = useRef(null)
  const backgroundRef = useRef(null)
  const compensationRef = useRef(null)
  const employmentRef = useRef(null)
  const paymentRef = useRef(null)
  const settingsRef = useRef(null)
  const documentRef = useRef(null)
  const leaveWelfareRef = useRef(null)
  const taxRef = useRef(null)

  const executeScroll = (ref: any) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const { createEmployee, updateEmployee } = useEmployee()

  const onSubmit = () => {
    const variables = {
      input: {
        //1
        id: values.formInput.id,
        nameTitle: values.formInput.nameTitle,
        fullName: values.formInput.fullName,
        middleName: values.formInput.middleName,
        lastName: values.formInput.lastName,
        personalID: values.formInput.personalID,
        personalIDAt: values.formInput.personalIDAt,
        personalPassportID: values.formInput.personalPassportID,
        personalWorkID: values.formInput.personalWorkID,
        personalIDIssue: values.formInput.personalIDIssue,
        personalIDExpire: values.formInput.personalIDExpire,
        nationality: values.formInput.nationality,
        ethnicity: values.formInput.ethnicity,
        bloodType: values.formInput.bloodType,
        height: values.formInput.height,
        weight: values.formInput.weight,
        militaryStatus: values.formInput.militaryStatus,
        remark: values.formInput.remark,
        fullNameEnglish: values.formInput.fullNameEnglish,
        middleNameEnglish: values.formInput.middleNameEnglish,
        lastNameEnglish: values.formInput.lastNameEnglish,
        nationalityType: values.formInput.nationalityType,
        sex: values.formInput.sex,
        personalRelation: values.formInput.personalRelation,
        birthDate: values.formInput.birthDate,
        nickName: values.formInput.nickName,
        tel: values.formInput.tel,
        email: values.formInput.email,
        lineId: values.formInput.lineId,
        historyFamily: values.formInput.historyFamily,
        detailsFamily: values.formInput.detailsFamily,
        emergencyContact: values.formInput.emergencyContact,
        addressPersonalID: values.formInput.addressPersonalID,
        addressCurrent: values.formInput.addressCurrent,
        // //Tab2
        historyEducation: values.formInput.historyEducation,
        historyWork: values.formInput.historyWork,
        historyTraining: values.formInput.historyTraining,
        language: values.formInput.language,
        personalTalent: values.formInput.personalTalent,
        historySick: values.formInput.historySick,
        // //Tab3
        hireDate: values.formInput.hireDate,
        retireDate: values.formInput.retireDate,
        employeeType: values.formInput.employeeType,
        departmentID: values.formInput.departmentID,
        positionID: values.formInput.positionID,
        leaderTeam: values.formInput.leaderTeam,
        employeeWorkStatus: values.formInput.employeeWorkStatus,
        isTimeAttendance: values.formInput.isTimeAttendance,
        setShiftWorking: values.formInput.setShiftWorking,
        shiftWorkingID: values.formInput.shiftWorkingID,
        jobDescAttachment: values.formInput.jobDescAttachment,
        workPlace: values.formInput.workPlace,
        packageGroupID: values.formInput.packageGroupID,
        holidayID: values.formInput.holidayID,
        // //Tab4
        salaryOptional: values.formInput.salaryOptional,
        socialSecurityCalculate: values.formInput.socialSecurityCalculate,
        socialSecurity: values.formInput.socialSecurity,
        salary: values.formInput.salary,
        hospitalService: values.formInput.hospitalService,
        personalIDNumber: values.formInput.socialSecurityCalculate,
        incomeExpense: values.formInput.incomeExpense,
        employeeFund: values.formInput.employeeFund,
        taxDeductOne: values.formInput.taxDeductOne,
        employeeHandicappedAndAgeOut: values.formInput.employeeHandicappedAndAgeOut,
        taxDeductTwo: values.formInput.taxDeductTwo,
        diligentAllowance: values.formInput.diligentAllowance,
        taxCalculate: values.formInput.taxCalculate,
        taxFixedPerMonth: values.formInput.taxFixedPerMonth,
        // taxStartedMonth: parseInt(values.formInput.taxStartedMonth.split('-')[1]),

        //Tab5
        paymentMethod: values.formInput.paymentMethod,
        bankPayment: values.formInput.bankPayment,
        bankAccountName: values.formInput.bankAccountName,
        bankAccountId: values.formInput.bankAccountId,
        //Tab6
        profileImage: values.formInput.profileImage,
        username: values.formInput.username,
        password: values.formInput.password,
        //Tab7
        personalIdAttachment: values.formInput.personalIdAttachment,
        personalHouseAttachment: values.formInput.personalHouseAttachment,
        personalEducationalAttachment: values.formInput.personalEducationalAttachment,
        personalTrainningAttachment: values.formInput.personalTrainningAttachment,
        personalOptionalAttachment: values.formInput.personalOptionalAttachment,
        personalMarriageAttachment: values.formInput.personalMarriageAttachment,
      },
    }

    if (Object.keys(errors).length !== 0) {
      const dataErr: any = _.get(errors, 'formInput')

      return Swal.fire({
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
        text: `${Object.values(dataErr)}`,
        icon: 'error',
        confirmButtonColor: '#d32f2f',
      })
    } else {
      return Swal.fire({
        title: `${mode === 'create' ? 'เพิ่มข้อมูลพนักงาน' : 'บันทึกข้อมูลพนักงาน'} `,
        html: `${values.formInput.fullName} ${values.formInput.lastName} <br /> รหัส ${values.formInput.id}`,
        showCancelButton: true,
        confirmButtonText: 'บันทึก',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
        confirmButtonColor: '#3080f8',
      }).then((result) => {
        if (result.isConfirmed) {
          remove__typename(variables)
          if (mode === 'create') {
            createEmployee({ variables: variables }).then(() => {
              Swal.fire({
                title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
              navigate(-1)
            })
          } else {
            updateEmployee({ variables: variables }).then((res) => {
              Swal.fire({
                title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
              navigate(-1)

              console.log(res, 'update')
            })
          }
        }
      })
    }
  }

  const { data: dataEmployee, loading: loadEmployee } = useGetEmployee({
    getEmployeeId: id,
    year: `2022`,
    listWelfareQuotaYear2: `2022`,
  })

  const { data: dataIncome } = useQuery(gql(queryotherIncome), {
    variables: {
      filter: {
        variableOrFixed: {
          eq: 'FIXED',
        },
        status: {
          eq: 'ACTIVE',
        },
      },
    },
  })

  const { handleSetFieldValue } = useFormInput()

  useEffect(() => {
    handleSetFieldValue(dataEmployee)
    if (id) {
      setMode('update')
    } else {
      setMode('create')
      if (dataIncome) {
        const _dataIncome = dataIncome.listOtherIncomeOrExpenseConfig.otherIncomeConfigList
          .filter((item: any) => item.incomeExpenseType === EIncomeExpenseType.Income)
          .map((element: any) => ({
            incomeExpenseType: element.incomeExpenseType,
            incomeOrExpenseID: element.id,
            name: element.name,
            total: 0,
            unit: null,
          }))
        const _dataExpense = dataIncome.listOtherIncomeOrExpenseConfig.otherExpenseConfigList
          .filter((item: any) => item.incomeExpenseType === EIncomeExpenseType.Expense)
          .map((element: any) => ({
            incomeExpenseType: element.incomeExpenseType,
            incomeOrExpenseID: element.id,
            name: element.name,
            total: 0,
            unit: null,
          }))

        const _dataIncomeExpense = combineList(_dataIncome, _dataExpense)
        const _dataFund = dataIncome.listOtherIncomeOrExpenseConfig.otherExpenseConfigList
          .filter((item: any) => item.incomeExpenseType === EIncomeExpenseType.Fund)
          .map((element: any) => ({
            incomeOrExpenseID: element.id,
            fundNumber: 0,
            name: element.name,
            contractDate: null,
            fundRate: 0,
            contributionMethod: null,
            contributionCompany: 0,
            contributionTotal: 0,
            beneficiary: null,
            remark: '',
          }))
        setFieldValue(`formInput.incomeExpense`, _dataIncomeExpense)
        setFieldValue(`formInput.employeeFund`, _dataFund)
      }
    }
  }, [mode, dataEmployee, id])
  const TabData = [
    { name: 'personal', title: 'ข้อมูลพื้นฐาน', ref: personalRef },
    { name: 'background', title: 'ประวัติส่วนตัว', ref: backgroundRef },
    { name: 'employment', title: 'ข้อมูลจ้างงาน', ref: employmentRef },
    { name: 'compensation', title: 'ข้อมูลค่าตอบแทน', ref: compensationRef },
    { name: 'payment', title: 'ข้อมูลการจ่ายเงิน', ref: paymentRef },
    { name: 'settings', title: 'การตั้งค่าผู้ใช้งาน', ref: settingsRef },
    { name: 'document', title: 'เอกสาร', ref: documentRef },
    { name: 'leaveWelfare', title: 'การลาและสวัสดิการ', ref: leaveWelfareRef },
    { name: 'tax', title: 'ลดหย่อนภาษี', ref: taxRef },
  ]

  return (
    <Form className={styles.containerProfile}>
      <div className={styles.stickyTab}>
        <EmployeeProfileNavbar tabItem={TabData} tab={tab} setTab={setTab} executeScroll={executeScroll} />
        <div className={styles.bottom}>
          <img src={arrowSVG} alt="arrowSVG" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }} />
          <div className={styles.buttonForm}>
            <div className={styles.bt}>
              <ButtonComponent
                _type={'button'}
                _colorBG={'white'}
                _colorText={'red'}
                _variant={'contained'}
                _text={'ยกเลิก'}
              />
            </div>

            <div className={styles.bt}>
              <ButtonComponent
                _type={'button'}
                _colorBG={'blue'}
                _colorText={'white'}
                _variant={'contained'}
                _text={'บันทึก'}
                _functionOnClick={() => {
                  handleSubmit()
                  onSubmit()
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {loadEmployee && id ? (
        <div className={styles.loadCard}>
          <LinearProgress />
          <p>กำลังโหลดข้อมูลพนักงาน</p>
        </div>
      ) : (
        <>
          <div className={styles.scrollArea} ref={personalRef}>
            <EmployeePersonalTab />
          </div>
          <div className={styles.scrollArea} ref={backgroundRef}>
            <EmployeeBackgroundTab />
          </div>
          <div className={styles.scrollArea} ref={employmentRef}>
            <EmployeeEmploymentTab />
          </div>
          <div className={styles.scrollArea} ref={compensationRef}>
            <EmployeeCompensationTab />
          </div>
          <div className={styles.scrollArea} ref={paymentRef}>
            <EmployeePaymentTab />
          </div>
          <div className={styles.scrollArea} ref={settingsRef}>
            <EmployeeUserTab />
          </div>

          <div className={styles.scrollArea} ref={documentRef}>
            <EmployeeDocument />
          </div>
          <div className={styles.scrollArea} ref={leaveWelfareRef}>
            <EmployeeLeaveQuotaTab />
          </div>
          <div className={styles.scrollArea} ref={taxRef}>
            <EmployeeTax />
          </div>
        </>
      )}
    </Form>
  )
}

const IntitialValuesFormInput: any = {
  nameTitle: 'MR',
  fullName: '',
  middleName: '',
  lastName: '',
  personalID: '',
  personalIDAt: '',
  personalPassportID: '',
  personalWorkID: '',
  personalIDIssue: null,
  personalIDExpire: null,
  nationality: '',
  ethnicity: '',
  bloodType: null,
  weight: 0,
  height: 0,
  militaryStatus: null,
  remark: '',
  fullNameEnglish: '',
  middleNameEnglish: '',
  lastNameEnglish: '',
  nationalityType: 'THAILAND',
  sex: 'MALE',
  personalRelation: 'SINGLE',
  birthDate: null,
  nickName: '',
  tel: '',
  email: null,
  lineId: '',
  historyFamily: [{ relation: '', fullName: '', birthDate: null, job: '', familyStatus: null }],
  detailsFamily: {
    childrens: 0,
    fullName: '',
    lastName: '',
    job: '',
    positionName: '',
    companyName: '',
    tel: '',
    siblings: 0,
    myNumber: 0,
    maleNumber: 0,
    femaleNumber: 0,
  },
  emergencyContact: {
    fullName: '',
    lastName: '',
    relation: '',
    tel: '',
    address: '',
  },
  addressPersonalID: {
    addressNumber: '',
    group: '',
    optional: '',
    province: '',
    subDistrict: '',
    district: '',
    zipCode: '',
  },
  addressCurrent: {
    addressNumber: '',
    group: '',
    optional: '',
    province: '',
    subDistrict: '',
    district: '',
    zipCode: '',
  },
  //Tab 2
  historyEducation: [
    { educationLevel: '', educationName: '', majorName: '', startedYear: '', endedYear: '', grade: 0 },
  ],
  historyWork: [
    {
      workName: '',
      startedAt: null,
      endedAt: null,
      positionName: '',
      descPosition: '',
      referral: '',
      tel: '',
      salary: 0,
      leaveReason: '',
    },
  ],
  historyTraining: [
    {
      trainingName: '',
      courseName: '',
      date: null,
      period: '',
    },
  ],
  language: [
    {
      languageName: 'ภาษาไทย (Thai)',
      speak: null,
      write: null,
      read: null,
    },
    {
      languageName: 'ภาษาอังกฤษ (English)',
      speak: null,
      write: null,
      read: null,
    },
  ],
  historySick: [
    {
      sickName: 'โรคตาบอดสี',
      isSick: false,
      remark: '',
    },
    {
      sickName: 'วัณโรค',
      isSick: false,
      remark: '',
    },
    {
      sickName: 'ไวรัสตับอักเสบบี',
      isSick: false,
      remark: '',
    },
  ],
  //Tab3
  id: '',
  hireDate: '',
  retireDate: '',
  employeeType: '',
  departmentID: '',
  positionID: '',
  getPackageGroup: '',
  leaderTeam: '',
  employeeWorkStatus: 'PROBATION',
  employeeWorkTypeShift: '',
  setShiftWorking: 'ENABLE',
  shiftWorkingID: '',
  workPlace: 'OFFICE',
  packageGroupID: '',
  holidayID: '',
  holidayLink: 'DEFAULT',
  jobDescAttachment: '',
  isTimeAttendance: true,
  //Tab4
  salaryOptional: 'STABLE',
  socialSecurityCalculate: 'BASE_ON_SALARY',
  socialSecurity: 'PERSONAL_IDCARD',
  listWelfareQuota: [],
  hospitalService: '',
  personalIDNumber: '',
  diligentAllowance: false,
  salary: 0,
  incomeExpense: [],
  taxCalculate: null,
  taxFixedPerMonth: 0,
  taxStartedMonth: '',
  employeeFund: [],
  taxDeductOne: [{ year: '', month: '', total: 0 }],
  employeeHandicappedAndAgeOut: '',
  taxDeductTwo: [{ name: '', rateDeduct: 0, totalDeduct: 0 }],
  //Tab5
  paymentMethod: 'BANK',
  bankPayment: null,
  bankAccountName: null,
  bankAccountId: '',
  //Tab6
  profileImage: '',
  username: '',
  password: '',

  //Tab7
  personalTalent: [],
  personalIdAttachment: '',
  personalHouseAttachment: '',
  personalEducationalAttachment: '',
  personalTrainningAttachment: '',
  personalOptionalAttachment: '',
  personalMarriageAttachment: '',

  //Leave
  listLeaveQuota: [],
}
const EnhancedEmployeeProfile = withFormik({
  // validateOnChange: true,
  mapPropsToValues: () => ({
    formInput: IntitialValuesFormInput,
    employeeTax: {
      bonus: 0,
      salary: 0,
      deductExpense: 0.0,
      otherIncomePerYear: 0,
      totalIncomePerYear: 0,
      personalDiscount: 0,
      personalRelation: 'SINGLE',
      parentDiscount: 0,
      fatherAllowance: false,
      motherAllowance: false,
      fatherSPAllowance: false,
      motherSPAllowance: false,
      parentSpouseDiscountCount: 0,
      eotherIncomePerYear: 0,
      spouseDiscount: 0,
      totalDiscountFamily: 0,
      child: 'true',
      socialSecurityDeposit: 0,
      handicappedDiscount: 0,
      funcDeposit: 0,
      homeLoanIncrease: 0,
      loanIncrease: 0,
      totalOtherDiscount: 0,
      educationDonate: 0,
      lifePensionInsurance: 0,
      lifeInsurance: 0,
      familyHealthInsurance: 0,
      totalInsuranceDiscount: 0,
      totalTaxExpense: 0,
      countChildBeforeYear: 0,
      countChildAfterYear: 0,
      childDiscount: 0,
      money: 0,
    },
  }),
  validationSchema: yup.object().shape({
    formInput: yup.object().shape({
      fullName: yup.string().required('กรุณากรอกชื่อ'),
      lastName: yup.string().required('กรุณากรอกนามสกุล'),
      birthDate: yup.string().required('กรุณากรอกวันเดือนปีที่เกิด'),
      personalID: yup.string().required('กรุณากรอกเลขบัตรประจำตัวประชาชน'),
      tel: yup.string().required('กรุณากรอกหมายเลขโทรศัพท์'),
      hireDate: yup.string().required('กรุณากรอกวันเริ่มงาน'),
      retireDate: yup.string().required('กรุณากรอกวันสิ้นสุดการทำงาน'),
      employeeType: yup.string().required('กรุณาเลือกประเภทพนักงาน'),
      positionID: yup.string().required('กรุณาเลือกตำแหน่งงาน'),
      departmentID: yup.string().required('กรุณาเลือกแผนก'),
      username: yup.string().required('กรุณากรอกชื่อผู้ใช้'),
      password: yup.string().required('กรุณากรอกรหัสผ่าน'),
    }),
    employeeTax: yup.object().shape({
      deductExpense: yup.number().max(100000, 'ไม่เกิน 100,000 บาท'),
      socialSecurityDeposit: yup.number().max(9000, 'ไม่เกิน 9,000 บาท'),
      handicappedDiscount: yup.number().max(120000, 'ไม่เกิน 120,000 บาท'),
      funcDeposit: yup.number().max(500000, 'ไม่เกิน 500,000 บาท'),
      homeLoanIncrease: yup.number().max(100000, 'ไม่เกิน 100,000 บาท'),
      loanIncrease: yup.number().max(100000, 'ไม่เกิน 100,000 บาท'),
      educationDonate: yup.number().max(100000, 'ไม่เกิน 100,000 บาท'),
      lifePensionInsurance: yup.number().max(200000, 'ไม่เกิน 200,000 บาท'),
      lifeInsurance: yup.number().max(100000, 'ไม่เกิน 100,000 บาท'),
      familyHealthInsurance: yup.number().max(15000, 'ไม่เกิน 15,000 บาท'),
    }),
  }),

  handleSubmit: (values) => {
    console.log(values, 'values >>>>')
  },
})(EmployeeProfile)

export default EnhancedEmployeeProfile
