import styled from '@emotion/styled'
import * as Icons from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Popper, Stack } from '@mui/material'
import React, { FC, useEffect, useRef, useState } from 'react'
import Switch from 'src/component/Switch'
import useMyLocation from 'src/hooks/useMyLocation'
import { useQueryShareCalendar } from '../../adapter/xhr'
import { calendarTypeColor, ECalendarType, getThaiDate, months } from '../../utils/common'
import BigCalendar, { CalendarRef } from './bigCalendar'
import styles from './index.module.scss'
import { ModalQuota } from './modalQuota'

const Card = styled.div<{ type: ECalendarType; padding?: any; minWidth?: any }>(({ type, padding, minWidth }) => ({
  border: `1px solid ${calendarTypeColor[type].main}`,
  backgroundColor: calendarTypeColor[type].light,
  padding,
  borderRadius: 4,
  minWidth,
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  justifyContent: 'center',
}))

const CalendarDetailPage: FC = (): JSX.Element => {
  const { adminOrEmployee } = useMyLocation()
  const [calendarDate, setCalendarDate] = useState<Date>(new Date())
  const filterEmployee = {
    isAll: false,
    isAbsent: true,
    isLate: true,
    isLeave: true,
    isOvertime: true,
  }
  const filterAdmin = {
    isAll: true,
    isAbsent: true,
    isLate: true,
    isLeave: true,
    isOvertime: true,
  }
  const filterItem = adminOrEmployee === 'ADMIN' ? filterAdmin : filterEmployee

  const [filterCalendar, setFilterCalendar] = useState(filterItem)
  const [calendar, setCalendar] = useState<'myCalendar' | 'sharedCalendar'>('myCalendar')
  const { data, loading, refetch } = useQueryShareCalendar(null, true)

  const [openModal, setOpenModal] = useState(false)

  const calendarRef = useRef<CalendarRef | null>(null)

  useEffect(() => {
    refetch({
      input: {
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth() + 1,
        filter: filterCalendar,
      },
    })
  }, [calendarDate, filterCalendar])

  const calendarEvents = React.useMemo<any[]>(() => {
    if (loading || !data) return []

    const _events: any[] = []

    data.items.forEach((item: any) => {
      if (!item.eventTypeUnique?.length && item.isHoliday) {
        _events.push({
          date: item.date,
          isHoliday: item.isHoliday,
          holidayDescription: item.holidayDescription,
        })
        return
      }
      item.eventTypeUnique.forEach((e: any) => {
        _events.push({
          title: e,
          date: item.date,
          start: new Date(item.date),
          end: new Date(item.date),
          isHoliday: item.isHoliday,
          holidayDescription: item.holidayDescription,
          type: e,
        })
      })
    })
    return _events
  }, [data, loading])

  const calendarEventData = React.useMemo<any[]>(() => {
    if (loading || !data) return []

    return data.items
  }, [data, loading])

  const sumEmployee = (type: string) => {
    const arr: any[] = []
    const sum = calendarEventData.map((item: any) => {
      item.events.forEach((e: any) => {
        if (e.Type === type) {
          return arr.push(e)
        }
      })
    })
    return arr.length
  }
  const onFilterChange = (key: keyof typeof filterCalendar) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterCalendar((prev) => ({ ...prev, [key]: e.target.checked }))
    }
  }

  const GetyearsPre = () => {
    const year = calendarDate.getFullYear()
    const yeararr: any[] = []
    for (let i = year; i >= year - 5; i--) {
      yeararr.push(i)
    }

    return yeararr.sort()
  }

  const GetyearsCurr = () => {
    const year = calendarDate.getFullYear() + 1
    const yeararr: any[] = []

    for (let i = year; i < year + 5; i++) {
      yeararr.push(i)
    }

    return yeararr
  }

  const yearLabel = GetyearsPre()
  const yearCurrLabel = GetyearsCurr()
  const Years = yearLabel.concat(yearCurrLabel)

  const onNextMonth = () => {
    const newDate = calendarRef.current!.handleNextMonth()
    setCalendarDate(newDate)
  }

  const onPreviousMonth = () => {
    const newDate = calendarRef.current!.handlePreviousMonth()
    setCalendarDate(newDate)
  }

  const onToday = () => {
    const newDate = calendarRef.current!.handleToday()
    setCalendarDate(newDate)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [hiddenMonth, setHiddenMonth] = useState(true)
  const [years, setYears] = useState<any>(calendarDate.getFullYear())
  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <>
      <div className={styles.toolsbar}>
        <Stack direction="row" spacing={2}>
          <h4
            className={styles.calendarTitle}
            onClick={(e: any) => {
              handleClick(e)
            }}
          >
            {getThaiDate(calendarDate).format('day date month year')}
          </h4>
          <div className={styles.PopperCalendar}>
            <Popper id={id} open={open} anchorEl={anchorEl} placement="right-start">
              <div className={styles.GroupYear}>
                <>
                  {hiddenMonth ? (
                    <div className={styles.Card}>
                      <div className={styles.CardGrid}>
                        {Years.map((item: any, index: number) => (
                          <button
                            className={styles.buttonYear}
                            key={index}
                            onClick={() => {
                              setHiddenMonth(!hiddenMonth)
                              setYears(item)
                              const newDate = new Date(years, 0)
                              setCalendarDate(newDate)
                              calendarRef.current!.handleSelect(newDate)
                            }}
                          >
                            {item + 543}
                          </button>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.Card}>
                      <p
                        className={styles.textHead}
                        onClick={() => {
                          setHiddenMonth(!hiddenMonth)
                        }}
                      >
                        {years + 543}
                      </p>
                      <div className={styles.CardGrid}>
                        {months.map((item: any, index: number) => (
                          <button
                            className={styles.buttonYear}
                            key={index}
                            onClick={() => {
                              const newDate = new Date(years, index)
                              setCalendarDate(newDate)
                              calendarRef.current!.handleSelect(newDate)
                              setAnchorEl(null)
                            }}
                          >
                            {item.thMonth}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              </div>
            </Popper>
          </div>

          <div className={styles.navigateBar}>
            <button onClick={onPreviousMonth} className={styles.navigate}>
              <Icons.ArrowBackIosNew fontSize="small" />
            </button>
            <button onClick={onToday} className={styles.navigateToday}>
              วันนี้
            </button>
            <button onClick={onNextMonth} className={styles.navigate}>
              <Icons.ArrowForwardIos fontSize="small" />
            </button>
          </div>
        </Stack>
        <Stack direction="row" spacing={2}>
          {adminOrEmployee === 'EMPLOYEE' ? (
            <>
              <Button
                onClick={() => {
                  setOpenModal(true)
                  console.log('Quota')
                }}
                variant="contained"
              >
                โควต้าการลาคงเหลือ
              </Button>
              <Button variant="contained">สวัสดิการคงเหลือ</Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                sx={{ minWidth: 0, width: 40, backgroundColor: '#fff', ':hover': { backgroundColor: '#fff' } }}
              >
                <Icons.MoreVert fontSize="small" style={{ color: '#000' }} />
              </Button>
              <Button variant="contained">ดาวน์โหลดไฟล์</Button>
            </>
          )}
        </Stack>
      </div>

      <div className={styles.calendarContainer}>
        <>
          <BigCalendar
            events={calendarEvents}
            eventData={calendarEventData}
            ref={calendarRef}
            loading={loading}
            data={refetch}
          />

          <div className={styles.bottomFilter}>
            <div className={styles.GroupCard}>
              <p>เลือกแสดง:</p>
              {adminOrEmployee === 'EMPLOYEE' && (
                <div className={styles.toggle}>
                  <Switch
                    options={[
                      { label: 'เฉพาะฉัน', value: 'myCalendar' },
                      { label: 'พนักงานทุกคน', value: 'sharedCalendar' },
                    ]}
                    onChange={(value: any) => {
                      if (value === 'myCalendar') {
                        setFilterCalendar(filterEmployee)
                        setCalendar('myCalendar')
                      } else if (value === 'sharedCalendar') {
                        setFilterCalendar(filterAdmin)
                        setCalendar('sharedCalendar')
                      }
                    }}
                  />
                </div>
              )}

              <Card type="ABSENT" padding="0 6px">
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ width: '40px', padding: 0, color: calendarTypeColor['ABSENT'].main }}
                      checked={filterCalendar.isAbsent}
                      onChange={onFilterChange('isAbsent')}
                    />
                  }
                  label={<p>ขาด</p>}
                />
              </Card>
              <Card type="LEAVE" padding="0 6px">
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ width: '40px', padding: 0, color: calendarTypeColor['LEAVE'].main }}
                      checked={filterCalendar.isLeave}
                      onChange={onFilterChange('isLeave')}
                    />
                  }
                  label={<p>ลา</p>}
                />
              </Card>
              <Card type="LATE" padding="0 6px">
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ width: '40px', padding: 0, color: calendarTypeColor['LATE'].main }}
                      checked={filterCalendar.isLate}
                      onChange={onFilterChange('isLate')}
                    />
                  }
                  label={<p>มาสาย</p>}
                />
              </Card>
              <Card type="OVERTIME" padding="0 6px">
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ width: '40px', padding: 0, color: calendarTypeColor['OVERTIME'].main }}
                      checked={filterCalendar.isOvertime}
                      onChange={onFilterChange('isOvertime')}
                    />
                  }
                  label={<p>ล่วงเวลา</p>}
                />
              </Card>
            </div>
            <div className={styles.GroupCard}>
              <Card type="ABSENT" padding="0 6px" minWidth={125}>
                {console.log(calendar, 'Calendar')}
                <p>{sumEmployee('ABSENT')}</p>
                <p>{`ขาด (คน)`}</p>
              </Card>
              <Card type="LEAVE" padding="0 6px" minWidth={125}>
                <p>{sumEmployee('LEAVE')}</p>
                <p>ลา (คน)</p>
              </Card>
              <Card type="LATE" padding="0 6px" minWidth={125}>
                <p>{sumEmployee('LATE')}</p>
                <p>มาสาย (คน)</p>
              </Card>
              <Card type="OVERTIME" padding="0 6px" minWidth={125}>
                <p>{sumEmployee('OVERTIME')}</p>
                <p>ล่วงเวลา (คน)</p>
              </Card>
            </div>
          </div>
        </>
        <ModalQuota open={openModal} setOpen={setOpenModal} />
      </div>
    </>
  )
}
export default CalendarDetailPage
