//Lib
import React from 'react'
import * as yup from 'yup'
import { Formik, FormikProps, useFormikContext } from 'formik'
import swal from 'sweetalert'
import { Modal, Backdrop, FormControl, InputLabel, Select, MenuItem } from '@mui/material'

//Include Project
import styles from './index.module.scss'
import { ButtonComponent, ButtonSelectCustom, InputTimeComponentCustom, UploadFileComponent } from '../../../component'
import Switch from '../../../component/Switch'
import { InputDateComponentCustome } from '../../../component/InputsFormik'
import { ESaveDescrip, SaveType } from '../EType'
import { CheckIntervals, isAfterShift, isBeforeShift, LeaveFormList } from '../../../utils/common'
import LeaveSelect from 'src/component/LeaveSelect'
import { ELeaveForm } from 'src/utils/generated'
import { ILeaveDocument } from 'src/utils/formikInterfce'
import Swal from 'sweetalert2'
import moment from 'moment'

interface IModal {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  createLeaveFunction?: any
}
export const ModalLeaveDocument: React.FC<IModal> = ({ open, setOpen, createLeaveFunction }: IModal): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [type, setType] = React.useState('leave')
  const [status, setStatus] = React.useState('APPROVE')
  const statusType = (e: string) => {
    setStatus(e)
  }
  const CheckQuota = (quota: number) => {
    if (isNaN(quota)) {
      return 0
    } else {
      return quota
    }
  }

  function GetQuota(leaveType: string, type: boolean) {
    const employeeQuata = values.attendance.employee?.listLeaveQuota?.items || []
    if (type === false) {
      const balanceQaota = employeeQuata.find((ele: any) => ele.prev >= 0 && ele.leaveConfig.id === leaveType)
      return CheckQuota(balanceQaota?.begin - balanceQaota?.used)
    } else {
      const balanceQaota = employeeQuata.find((ele: any) => ele.prev < 0 && ele.leaveConfig.id === leaveType)
      return CheckQuota(balanceQaota?.begin - balanceQaota?.used)
    }
  }

  const validationSchema = yup.object({
    leaveType: yup.string().required('เลือกประเภทการลา'),
    startedAt: yup.string().required('กรอกเวลาเริ่มต้น'),
    // startedAt: yup.object().when('leaveForm', {
    //   is: 'HALFDAY_MORNING_LEAVE' || 'HALFDAY_AFTERNOON_LEAVE' || 'CUSTOM_TIME',
    //   then: yup
    //     .object()
    //     .test(
    //       'start_time_test',
    //       `ไม่อยู่ในช่วงเวลา Shift (${values.attendance.shiftStartTime} - ${values.attendance.shiftEndTime}) `,
    //       function (value) {
    //         return isBeforeShift(value, values.attendance.shiftStartTime)
    //       },
    //     ),
    // }),

    endedAt: yup.string().required('กรอกเวลาสิ้นสุด'),
    // .test(
    //   'start_time_test',
    //   `ไม่อยู่ในช่วงเวลา Shift (${values.attendance.shiftStartTime} - ${values.attendance.shiftEndTime}) `,
    //   function (value) {
    //     return isAfterShift(value, values.attendance.shiftEndTime)
    //   },
    // ),
  })

  const SetIntervalsLeave = (leaveForm: ELeaveForm, setFieldValue: any) => {
    if (leaveForm === ELeaveForm.FulldayLeave) {
      setFieldValue('leaveForm', leaveForm)
      setFieldValue('startedAt', values.attendance.date)
      setFieldValue('endedAt', values.attendance.date)
    } else if (leaveForm == ELeaveForm.HalfdayMorningLeave) {
      setFieldValue('leaveForm', leaveForm)
      setFieldValue('startedAt', values.attendance.shiftStartTime)
      setFieldValue('endedAt', values.attendance.breakTimeList[0].startTime)
    } else if (leaveForm == ELeaveForm.HalfdayAfternoonLeave) {
      setFieldValue('leaveForm', leaveForm)
      setFieldValue('startedAt', values.attendance.breakTimeList[0].endTime)
      setFieldValue('endedAt', values.attendance.shiftEndTime)
    } else if (leaveForm == ELeaveForm.CustomTime) {
      setFieldValue('leaveForm', leaveForm)
      setFieldValue('startedAt', '')
      setFieldValue('endedAt', '')
    }
    return leaveForm
  }

  const handleUpdate = (leaveInput: ILeaveDocument) => {
    const packData = {
      input: {
        date: values.attendance.date,
        employeeID: values.attendance?.employeeID,
        status: status,
        leaveType: leaveInput.leaveType,
        startedAt: leaveInput.startedAt,
        endedAt: leaveInput.endedAt,
        isLeaveDeductMoney: leaveInput.isLeaveDeductMoney,
        leaveForm: leaveInput.leaveForm,
        leaveDocumentAttachment: leaveInput.leaveDocumentAttachment,
      },
    }
    const NewIntervals: any = { startedAt: leaveInput.startedAt, endedAt: leaveInput.endedAt }
    const resultIntervalsCheck = CheckIntervals(values.report.leaveList, NewIntervals)
    const Quota = GetQuota(leaveInput.leaveType, leaveInput.isLeaveDeductMoney)
    if (leaveInput.leaveForm === 'FULLDAY_LEAVE') {
      const diffInDays = moment(leaveInput.endedAt).diff(moment(leaveInput.startedAt), 'days')
      if (Quota < diffInDays) {
        return Swal.fire({
          title: 'วันลาคงเหลือไม่เพียงพอ',
          icon: 'error',
          customClass: {
            container: 'swal',
          },
        })
      }
    }

    if (Quota <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'โควต้าการลาคงเหลือไม่เพียงพอ',
      })
    }

    if (resultIntervalsCheck) {
      return swal({
        icon: 'error',
        title: 'มีช่วงเวลาการลานี้แล้ว',
        text: 'สามารถสร้างรายการจากเอกสารแนะนำหรือเลือกช่วงเวลาอื่น',
        dangerMode: true,
      })
    } else {
      return swal({
        title: `${ESaveDescrip[`${status}`]}เอกสารการลา`,
        text: `วันที่ ${values.attendance.date} เวลา ${leaveInput.startedAt} - ${leaveInput.endedAt}`,
        buttons: ['ยกเลิก', 'บันทึก'],
      }).then((willsubmit) => {
        if (willsubmit) {
          createLeaveFunction({ variables: packData }).then((res: any) => {
            console.log(res, 'res')
            values.report.leaveList.push({
              leaveType: leaveInput.leaveType,
              startedAt: leaveInput.startedAt,
              endedAt: leaveInput.endedAt,
              leaveDocumentAttachment: leaveInput.leaveDocumentAttachment,
              status: status,
              leaveForm: leaveInput.leaveForm,
              type: type,
              leaveRequestID: res.data.data.createLeaveRequest.id,
            })
            setFieldValue(`report.leaveList`, values.report.leaveList)
          })
        }
      })
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={styles.modalLeaveEdit}>
          <div className={styles.card}>
            <Formik
              initialValues={{
                leaveType: '',
                startedAt: '',
                endedAt: '',
                leaveDocumentAttachment: '',
                isLeaveDeductMoney: false,
                status: '',
                leaveForm: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleUpdate(values)
                console.log(values)
              }}
            >
              {(props: FormikProps<ILeaveDocument>) => (
                <form onSubmit={props.handleSubmit} className={styles.wrapperForm}>
                  <div className={styles.header}>
                    <div className={styles.bt}>
                      <ButtonComponent
                        _type={'button'}
                        _colorBG={'white'}
                        _colorText={'red'}
                        _variant={'text'}
                        _text={'ยกเลิก'}
                        _sx={{ fontSize: '16px' }}
                        _functionOnClick={() => setOpen(false)}
                      />
                    </div>
                    <p className={styles.textHead}>สร้างใหม่</p>

                    <div className={styles.btSelect}>
                      <ButtonSelectCustom
                        _text={'Save'}
                        _selectData={SaveType}
                        _onchange={(e: any) => {
                          statusType(e)
                        }}
                        _functionOnclick={() => {
                          props.handleSubmit()
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.FormGroup}>
                    <div className={styles.filedInputFlex}>
                      <p>ประเภทการลา</p>
                      <div className={styles.inputBox}>
                        <LeaveSelect
                          name={'leaveType'}
                          value={props.values.leaveType}
                          _helperText={props.errors.leaveType}
                          employee={values.attendance.employee}
                        />
                      </div>
                    </div>
                    <p className={styles.textQuota}>
                      วันลาคงเหลือ{' '}
                      {props.values.isLeaveDeductMoney === false &&
                      GetQuota(props.values.leaveType, props.values.isLeaveDeductMoney) < 0
                        ? '0'
                        : props.values.isLeaveDeductMoney === false &&
                          GetQuota(props.values.leaveType, props.values.isLeaveDeductMoney) > 0
                        ? GetQuota(props.values.leaveType, props.values.isLeaveDeductMoney)
                        : GetQuota(props.values.leaveType, props.values.isLeaveDeductMoney) < 0
                        ? 'ไม่จำกัด'
                        : GetQuota(props.values.leaveType, props.values.isLeaveDeductMoney)}{' '}
                      <span> {''}วัน</span>
                    </p>
                    <div>
                      <div className={styles.SwitchArea}>
                        <Switch
                          options={[
                            { label: 'ลาไม่หักเงิน', value: 'leave' },
                            { label: 'ลาหักเงิน', value: 'leaveDeduct' },
                          ]}
                          onChange={(value: any) => {
                            setType(value)
                            if (value === 'leaveDeduct') {
                              props.setFieldValue('isLeaveDeductMoney', true)
                            } else if (value === 'leave') {
                              props.setFieldValue('isLeaveDeductMoney', false)
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.filedInputFlex}>
                      <p>รูปแบบการลา</p>
                      <div className={styles.inputBox}>
                        <FormControl fullWidth size="small">
                          <InputLabel
                            shrink
                            sx={{ fontFamily: 'SFThonburiRegular', backgroundColor: 'white', padding: '0px 5px' }}
                          >
                            {'รูปแบบการลา'}
                          </InputLabel>
                          <Select
                            id="leaveForm"
                            defaultValue={props.values.leaveForm}
                            sx={{ borderRadius: 1, fontFamily: 'SFThonburiRegular', backgroundColor: 'white' }}
                            value={props.values.leaveForm}
                            label={'รูปแบบการลา'}
                            onChange={(e: any) => {
                              SetIntervalsLeave(e.target.value, props.setFieldValue)
                            }}
                            name="leaveForm"
                          >
                            {LeaveFormList.map((data: any, index: number) => (
                              <MenuItem key={index} value={data.value} sx={{ fontFamily: 'SFThonburiRegular' }}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <>
                      {props.values.leaveForm === ELeaveForm.FulldayLeave ? (
                        <div className={styles.filedInputTime}>
                          <p>วันที่</p>
                          <div className={styles.GroupBox}>
                            <div className={styles.InputTime}>
                              <InputDateComponentCustome
                                _name="startedAt"
                                _label={'วันที่เริ่มต้น'}
                                _helperText={props.errors.startedAt}
                                _value={props.values.startedAt}
                              />
                            </div>
                            <div className={styles.InputTime}>
                              <InputDateComponentCustome
                                _name="endedAt"
                                _label={'วันที่สิ้นสุด'}
                                _helperText={props.errors.endedAt}
                                _value={props.values.endedAt}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>

                    <>
                      {props.values.leaveForm === ELeaveForm.FulldayLeave ? (
                        ''
                      ) : (
                        <div className={styles.filedInputTime}>
                          <p>ช่วงเวลา</p>
                          <div className={styles.GroupBox}>
                            <div className={styles.InputTime}>
                              <InputTimeComponentCustom
                                _name="startedAt"
                                _label={'เริ่มต้น'}
                                _helperText={props.errors.startedAt}
                                _value={props.values.startedAt}
                              />
                            </div>
                            <div className={styles.InputTime}>
                              <InputTimeComponentCustom
                                _name="endedAt"
                                _label={'สิ้นสุด'}
                                _helperText={props.errors.endedAt}
                                _value={props.values.endedAt}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                    <div className={styles.filedInputFlex}>
                      <p>แนบไฟล์</p>
                      <div className={styles.inutBox}>
                        <UploadFileComponent
                          name={'leaveDocumentAttachment'}
                          flodername={'LeaveAttechment'}
                          valueShow={props.values.leaveDocumentAttachment}
                          id={values.attendance.employeeID}
                          label="แนบไฟล์เอกสารการลา (JPEG,PNG,PDF)"
                        />
                      </div>
                    </div>
                    <div className={styles.filedInputFlex}>
                      <p>วันที่</p>
                      <div className={styles.inputBox}>
                        <InputDateComponentCustome
                          _name={'date'}
                          _value={values.attendance.date}
                          _disabled={true}
                          _label={'วันที่'}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  )
}
