//lib
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { LinearProgress } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { PooperRemark, PopperOverTime } from './popper'
import { useFormikContext } from 'formik'
import Swal from 'sweetalert2'
import { useTimeAttendanceReport, useTimeAttendanceReportByEmployee } from 'src/hooks'
import { ChipInDiffMinutes, ChipLeave, ChipOutDiffMinutes } from 'src/container'
import { ChipDateType } from 'src/component'
import { reducerKey } from 'src/utils/resetTimeAttendanceReport'

//Include Project
import styles from './index.module.scss'
import { ModalAttendanceEdit } from './ModalAttendance'
import { minuteToHour } from '../../utils/common'
import {
  EDocumentStatus,
  Employee,
  ETimeAttendanceReportType,
  OvertimeReport,
  TimeAttendanceReport,
} from 'src/utils/generated'
import TooltipArrow from 'src/component/TooltipArrow'

//Images
import editIcon from '../../image/edit.svg'
import NoteIcon from '../../image/note.svg'
import quesIcon from '../../image/question.svg'
import NoteDataIcon from '../../image/notedata.svg'
import alertGrayIcon from '../../image/alertGray.svg'
import alertGreenIcon from '../../image/alertGreen.svg'
import alertWarningIcon from '../../image/alertWarnning.svg'
import alertRejectIcon from '../../image/alertRed.svg'
import approveSVG from '../../image/approve.svg'
import approveDarkSVG from '../../image/approve_dark.svg'

export const TableAttendReport: React.FC<ITARProps> = ({
  startDate,
  endDate,
  employeeList,
  searchName,
  filter,
}: ITARProps): JSX.Element => {
  const [selectdate, setSelectDate] = React.useState<string>('')
  const [openmodal, setOpenModal] = React.useState(false)
  const [openpopper, setOpenPopper] = React.useState(false)
  const [popperRemark, setPopperRemark] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [employeeId, setEmployeeId] = React.useState<string>('')
  const [employee, setEmployee] = React.useState<Employee>()
  const [date, setDatePopper] = React.useState<string>()
  const [employeePopper, setEmployeePopper] = React.useState<string>('')
  const [reportOT, setReportOT] = React.useState<any[]>([])

  const { approveAttendanceReport, getTimeReport, refetch: refetchGetTimeReport } = useTimeAttendanceReport()
  const { data, getAttendanceReportByEmployee, loading, refetch } = useTimeAttendanceReportByEmployee()
  const { setFieldValue } = useFormikContext()

  const statusDocument = (data: OvertimeReport[]) => {
    const _data = data as OvertimeReport[]
    const _status: string[] | null = []
    _data.map((item) => {
      _status.push(item.status as EDocumentStatus)
    })
    if (_status.includes(EDocumentStatus.Approve)) {
      return alertGreenIcon
    } else if (_status.includes(EDocumentStatus.Pending)) {
      return alertWarningIcon
    } else if (_status.includes(EDocumentStatus.Reject)) {
      return alertRejectIcon
    } else if (_status.length === 0) {
      return
    } else {
      return alertGrayIcon
    }
  }

  //Colors Rows
  const statusStyle = (Type: ETimeAttendanceReportType) => {
    switch (Type) {
      case ETimeAttendanceReportType.Attendance:
        return '#FFFFFF'
      case ETimeAttendanceReportType.Overtime:
        return '#F8E8BD'
      case ETimeAttendanceReportType.Leave:
        return '#FFC5C8'
      case ETimeAttendanceReportType.Weekend:
        return '#DFDFDE'
      case ETimeAttendanceReportType.Holiday:
        return '#E9C4E9'
      case ETimeAttendanceReportType.Incomplete:
        return '#F5F4F6'
      default:
        return '#FFFFFF'
    }
  }

  const Border = (Type: ETimeAttendanceReportType) => {
    switch (Type) {
      case ETimeAttendanceReportType.Incomplete:
        return '#F32424'
      default:
        return '#ffffff00'
    }
  }

  React.useEffect(() => {
    if (!date && !employeePopper) return
    getTimeReport({ variables: { date: date, employeeId: employeePopper, year: '2022' } }).then((res) => {
      const attenreport = res.data.getTimeAttendanceReport
      const arr: any[] = []
      attenreport?.overtimeList.forEach((ot: any, index: number) => {
        if (ot.status !== null) return
        arr.push({ ...ot, type: 'overtime', index })
      })
      attenreport?.overtimeApproveList.forEach((ot: any) => {
        arr.push({ ...ot, type: 'overtimeApprove' })
      })
      setReportOT(arr.sort((a) => (a.type === 'overtime' ? -1 : 1)))
    })
  }, [date, employeePopper, refetchGetTimeReport])

  const handleClickPopperRemark = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
    setPopperRemark((previousOpen) => !previousOpen)
  }

  // Using searchName to filter employeeList
  const filterEmployeeSearchName = (employee: Employee) => {
    if (searchName === '') return employee
    else if (
      employee.fullName?.toLocaleLowerCase().includes(searchName.toLocaleLowerCase()) ||
      employee.lastName?.toLocaleLowerCase().includes(searchName.toLocaleLowerCase())
    )
      return employee
  }

  // The Data Cache in this page
  // string (employeeID -> Employee Object)
  const [dataCache, setDataCache] = useState<Record<string, Array<TimeAttendanceReport>>>({})

  // Which Accordian (Key of EmployeeID) is expanded
  const [expanded, setExpanded] = useState<string | false>(false)
  const handleChange = (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    // Make the expanded by this one (or close it)
    setExpanded(isExpanded ? id : false)

    // Query from data if the id doesn't exist
    if (!dataCache.hasOwnProperty(id))
      getAttendanceReportByEmployee({ variables: { startDate, endDate, employeeId: id, limit: 365 } })
  }

  const handleClickPopper = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper((previousOpen) => !previousOpen)
    getAttendanceReportByEmployee({ variables: { startDate, endDate, employeeId: id, limit: 365 } })
  }
  // When the query finishes, add it inside dataCache
  useEffect(() => {
    if (data && !loading) {
      const dataCacheClone = { ...dataCache }
      // Assume that the data will have at least 1 item
      // const employeeID = data.listTimeAttendanceReportByEmployeeID.items[0].employeeID
      dataCacheClone[employeeId] = data.items
      setDataCache(dataCacheClone)
    }
  }, [data, loading, employeeId])

  // When the employeeList/currentTableData changes, clear the cache!
  useEffect(() => {
    if (employeeList.length > 0) {
      setDataCache({})
      setExpanded(false)
      // https://www.apollographql.com/docs/react/caching/advanced-topics/#resetting-the-cache
      // client.resetStore() // Reset Apollo Cache, since the same query data will come from cache!! & UseEffect will not trigger!
    }
  }, [employeeList])

  const TooltipQuestion = () => {
    return (
      <div className={styles.IconTime}>
        <img src={quesIcon} alt="quesIcon" />
      </div>
    )
  }

  const onApprove = (data: TimeAttendanceReport) => {
    if (data.status === 'APPROVE') {
      return Swal.fire({
        title: 'Approve',
        html: `เวลาทำงานและเอกสารทั้งหมด  <br> วันที่ ${data.date} ถูกอนุมัติแล้ว`,
        showCloseButton: true,
      })
    } else {
      return Swal.fire({
        title: 'Approve',
        html: `อนุมัติเวลาทำงานและเอกสารทั้งหมด  <br> วันที่ ${data.date} <br> เมื่ออนุมัติแล้วไม่สามารถแก้ไขการเปลี่ยนแปลงได้ `,
        showCloseButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          approveAttendanceReport({ variables: { input: { date: data.date, employeeID: data.employeeID } } }).then(
            () => {
              Swal.fire({
                title: 'Approve สำเร็จ',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
              })
              refetch()
            },
          )
        }
      })
    }
  }

  const handleOpenModal = (date: string, id: string, employee: Employee) => {
    setOpenModal(true)
    setSelectDate(date)
    setFieldValue('report.date', date)
    setEmployeeId(id)
    setEmployee(employee)
  }

  return (
    <>
      {/* The Table Header */}
      <Table
        aria-label="simple table"
        sx={{
          position: 'sticky',
          top: 0,
          padding: 50,
          maxHeight: '56.5px',
          zIndex: 99,
          ['background-color']: 'white',
        }}
      >
        {/* Setting Column Widths */}
        {/* https://stackoverflow.com/questions/51216285/material-ui-v1-set-table-column-widths */}
        {/* https://github.com/mui/material-ui/issues/1911 */}
        <colgroup>
          {/* Date */}
          <col style={{ width: '15%' }} />
          {/* Shift */}
          <col style={{ width: '10%' }} />
          {/* ScanIn/ScanOut */}
          <col style={{ width: '20%' }} />
          {/* Working Minute */}
          <col style={{ width: '10%' }} />
          {/* OT Minute */}
          <col style={{ width: '10%' }} />
          {/* Leave Deduct Minute */}
          <col style={{ width: '10%' }} />
          {/* Leave Minute */}
          <col style={{ width: '10%' }} />
          {/* Misc */}
          <col style={{ width: '10%' }} />
          {/* Misc */}
          <col style={{ width: '10%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            {Header.map((item, index) => (
              <TableCell
                align="center"
                key={index}
                sx={{
                  borderLeft: '1px solid #F2F2F2',
                  borderRight: '1px solid #F2F2F2',
                  borderTop: '1px solid #F2F2F2',
                  position: 'sticky',
                  top: 0,
                }}
              >
                <p>{item}</p>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      {/* The Accordian Pill of Every Employee */}
      {employeeList.filter(filterEmployeeSearchName).map((emp, empIndex) => (
        <Accordion
          key={empIndex}
          expanded={expanded === emp.id}
          onChange={handleChange(emp.id)}
          onClick={() => setEmployeeId(emp.id)}
          sx={{
            // This is here so that the expanded version will have blue background.
            backgroundColor: '#CAD6EA',
            borderRadius: '7px 7px 0px 0px',
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              position: 'sticky',
              top: '75px',
              backgroundColor: '#CAD6EA99',
              backdropFilter: 'blur(1px)',
              borderRadius: '7px 7px 0px 0px',
              zIndex: 88,
            }}
          >
            <section className={styles.headEmployee}>
              <div>
                <span className={styles.bold}>{emp.id}</span>
              </div>
              <div className={styles.name}>
                {emp.fullName} {emp.lastName} {emp.nickName ? `(${emp.nickName})` : ''}
              </div>
              <div className={styles.department}>แผนก : {emp.department?.departmentName}</div>
            </section>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0.5em 0' }}>
            <Table aria-label="simple table">
              <colgroup>
                {/* Date */}
                <col style={{ width: '15%' }} />
                {/* Shift */}
                <col style={{ width: '10%' }} />
                {/* ScanIn/ScanOut */}
                <col style={{ width: '20%' }} />
                {/* Working Minute */}
                <col style={{ width: '10%' }} />
                {/* OT Minute */}
                <col style={{ width: '10%' }} />
                {/* Leave Deduct Minute */}
                <col style={{ width: '10%' }} />
                {/* Leave Minute */}
                <col style={{ width: '10%' }} />
                {/* Misc */}
                <col style={{ width: '10%' }} />
                {/* Misc */}
                <col style={{ width: '10%' }} />
              </colgroup>
              <TableBody>
                {!dataCache.hasOwnProperty(emp.id) ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {dataCache[emp.id].length ? (
                      <>
                        {dataCache[emp.id]
                          .filter((itemRow: any) => {
                            if (emp.id === '1002' && itemRow.date === '2022-10-07') {
                              console.log('REAP', itemRow)
                            }

                            if (filter.length == 0) {
                              return itemRow
                            } else if (
                              (itemRow.shiftName && filter.includes('SHIFT')) ||
                              (itemRow.overtimeMinute > 0 && filter.includes('OVERTIME')) ||
                              (itemRow.leaveDeductMinute > 0 && filter.includes('LEAVE_DEDUCT')) ||
                              (itemRow.leaveMinute > 0 && filter.includes('LEAVE'))
                            ) {
                              return itemRow
                            }
                          })
                          .map((itemRow, RowIndex) => (
                            <TableRow
                              sx={{
                                backgroundColor: statusStyle(itemRow.Type as ETimeAttendanceReportType),
                                overflow: 'scroll',
                                border: `1px solid ${Border(itemRow.Type as ETimeAttendanceReportType)}`,
                                borderSpacing: 0,
                              }}
                              key={RowIndex}
                              className={styles.tableR}
                            >
                              <TableCell align="left" sx={{ border: 'none' }}>
                                <ChipDateType date={itemRow.date} dayType={itemRow.dayType} />
                              </TableCell>
                              <TableCell align="center" sx={{ border: 'none' }}>
                                {itemRow.shiftName ? (
                                  <div className={styles.textShift}>
                                    <p> {itemRow.shiftName}</p>
                                    <TooltipArrow
                                      label={`${itemRow.shiftStartTime} - ${itemRow.shiftEndTime}`}
                                      child={TooltipQuestion()}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                              <TableCell align="center" sx={{ border: 'none' }}>
                                <div>
                                  <div className={styles.group}>
                                    <ChipInDiffMinutes
                                      inDiffMinutes={itemRow?.inDiffMinute}
                                      scanIn={itemRow?.scanIn}
                                      timeAttendanceLog={itemRow?.timeAttendanceLog}
                                    />
                                    <ChipOutDiffMinutes
                                      outDiffMinute={itemRow.outDiffMinute}
                                      scanOut={itemRow.scanOut}
                                      timeAttendanceLog={itemRow?.timeAttendanceLog}
                                    />
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell align="center" sx={{ border: 'none' }}>
                                <h4 style={{ fontWeight: 'bold' }}>{minuteToHour(itemRow?.workingMinute as number)}</h4>
                              </TableCell>
                              <TableCell align="center" sx={{ border: 'none' }}>
                                <div className={styles.overtimeGroup}>
                                  <h4 style={{ fontWeight: 'bold' }}>
                                    {/* This is manual addition because pending isn't included !!! */}
                                    {minuteToHour(
                                      itemRow.overtimeList!.reduce(reducerKey('overtimeMinute', 0), 0) +
                                        itemRow.overtimeApproveList!.reduce(reducerKey('overtimeMinute', 0), 0),
                                    )}
                                  </h4>

                                  <img
                                    src={statusDocument(
                                      (itemRow.overtimeApproveList as OvertimeReport[]).concat(
                                        itemRow?.overtimeList as OvertimeReport[],
                                      ),
                                    )}
                                    alt=""
                                    style={{ cursor: 'pointer' }}
                                    onClick={(event: any) => {
                                      handleClickPopper(event, emp.id)
                                      setDatePopper(itemRow.date)
                                      setEmployeePopper(emp.id)
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="center" sx={{ border: 'none' }}>
                                <h4 style={{ fontWeight: 'bold' }}>
                                  {minuteToHour(itemRow?.leaveDeductMinute as number)}
                                </h4>
                                <ChipLeave leaveList={itemRow?.leaveDeductList} />
                              </TableCell>
                              <TableCell align="center" sx={{ border: 'none' }}>
                                <h4 style={{ fontWeight: 'bold' }}>{minuteToHour(itemRow?.leaveMinute as number)}</h4>
                                <ChipLeave leaveList={itemRow?.leaveList} />
                              </TableCell>
                              <TableCell align="center" sx={{ border: 'none' }}>
                                <div className={styles.action}>
                                  <img
                                    src={itemRow.remark === '' ? NoteIcon : NoteDataIcon}
                                    alt="NoteIcon"
                                    onClick={(event: any) => {
                                      handleClickPopperRemark(event)
                                      setDatePopper(itemRow.date)
                                      setEmployeePopper(emp.id)
                                    }}
                                    className={styles.NoteIcon}
                                  />

                                  <img
                                    src={editIcon}
                                    alt="editIcon"
                                    onClick={() => {
                                      handleOpenModal(itemRow.date, emp.id, emp)
                                    }}
                                    className={styles.EditeIcon}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="center" sx={{ border: 'none' }}>
                                <div className={styles.icon}>
                                  <img
                                    src={itemRow.status === 'APPROVE' ? approveSVG : approveDarkSVG}
                                    alt="approveIcon"
                                    onClick={() => {
                                      onApprove(itemRow)
                                    }}
                                  />
                                  <p>{itemRow.status}</p>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    ) : (
                      <TableRow sx={{ backgroundColor: '#FF6161' }}>
                        <TableCell colSpan={8} align="center" sx={{ border: 'none' }}>
                          <p style={{ color: '#FFFFFF', fontSize: '16px' }}>
                            วันที่ {startDate} ถึง {endDate} ไม่มีบันทึกข้อมูลการทำงาน (Attendance Log)
                          </p>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      ))}

      <ModalAttendanceEdit
        open={openmodal}
        setOpenModal={setOpenModal}
        startDate={startDate}
        endDate={endDate}
        employeeList={employee as Employee}
        Date={selectdate}
        refechData={refetch}
      />
      <PopperOverTime
        setOpen={setOpenModal}
        open={openpopper}
        anchorEl={anchorEl}
        employeeID={employeePopper}
        setAnchorEl={setAnchorEl}
        loadData={getAttendanceReportByEmployee}
        dataCache={dataCache}
        startDate={startDate}
        endDate={endDate}
        reportOT={reportOT}
        date={date}
        QueryReport={refetch}
      />
      <PooperRemark
        popperRemark={popperRemark}
        setPopperRemark={setPopperRemark}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        loadData={getAttendanceReportByEmployee}
        date={date}
        employeeId={employeePopper}
        startdate={startDate}
        endAt={endDate}
      />
    </>
  )
}

interface ITARProps {
  employeeList: Employee[]
  startDate: string
  endDate: string
  filter: any
  searchName: string
  [key: string]: any // Comment Later!
}

const Header = [
  'วันที่',
  'กะการทำงาน',
  'เวลาทำงานจริง',
  'ชั่วโมงทำงานจริง',
  'การทำงานล่วงเวลา',
  'ลาหักเงิน',
  'ลาไม่หักเงิน',
  'หมายเหตุ',
  'สถานะ',
]
