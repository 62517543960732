import Axios, { AxiosResponse } from 'axios'

import {
  listTimeAttendanceReport,
  listTeamEmployee,
  listTeamEmployeeTable,
  getTeam,
  listDepartment,
  listDepartmentTable,
  getDepartmant,
  listPosition,
  getPosition,
  listApprove,
  listApproveAttendance,
  listPayment,
  listTimeAttendanceReportTypeLeave,
  listTimeAttendanceReportTypeOT,
  queryGetTimeAttendanceStreamCount,
  listShift,
  listShiftColor,
  getShift,
  queryListCalendar,
  queryGetHoliday,
  // querylistAttendanceLog,
  // querylistPayroll,
  // queryListAttendanceReport,
  queryListPayroll,
  queryGetAttendance,
  queryPayrollConfig,
  getShareCalendar,
  ListEmployee,
  querygetEmployee,
  queryLeaveDocument,
  queryOvertimeDocument,
  querygetovertimeDocument,
  queryGetLeaveDocument,
  queryotherIncome,
  queryListLeaveConfig,
} from './query'

import {
  mutationCreateTeam2,
  mutationUpdateTeam,
  mutationCreateDepartment,
  mutationUpdateDepartment,
  mutationDeleteDepartment,
  mutationCreatePosition,
  mutationUpdatePosition,
  mutationDeletePosition,
  mutationCreateTimeAttendanceBatch,
  mutationUpdateTimeAttendanceReport,
  mutationDeleteTimeAttendanceReport,
  mutationUpdateLeaveAttendance,
  mutationUpdateOTAttendance,
  mutationCreateHolidayPolicy,
  mutationCreateShift,
  mutationPutHexColorList,
  mutationUpdateShift,
  mutationUpdateDefaultShift,
  mutationDeleteShift,
  mutationUpdateHoliday,
  mutationDelHoliday,
  mutationAttendanceReport,
  mutationCreatePayroll,
  mutationPutEmployeePayroll,
  mutationCreateLeaveDocument,
  mutationCreateOvertimeDocument,
  mutationResetAttendance,
  mutationDelRequestOverTime,
  mutationDeleteLeave,
  mutationApproveDocument,
  mutationApproveBatch,
  mutationUpdateDefaultCalendar,
  mutationLogin,
  mutationRefreshToken,
  mutationCreateEmployeeProfile,
  mutationupdateEmployeeProfile,
  mutationClosePayroll,
  mutationPayrollSlip,
  mutationUpdateOvertime,
  mutationDeleteOvertime,
  mutationUpdateLeaveDocument,
  mutationDeleteLeaveDocument,
  mutationCreateLeaveConfig,
  mutationUpdateLeaveConfig,
  mutationDeleteLeaveConfig,
} from './mutation'

import { ApolloClient, createHttpLink, from, gql, InMemoryCache, useLazyQuery } from '@apollo/client'

// import { setLoggedInFalse } from '../../state/reducers/token'
import jwt_decode from 'jwt-decode'
import store from '../../state/store'
import {
  CreateLeaveConfig,
  DeleteLeaveConfig,
  LeaveConfig,
  ModelSortDirection,
  TimeAttendanceQuery,
  UpdateLeaveConfig,
} from '../../utils/generated'
import React from 'react'
import { flushSync } from 'react-dom'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
// import history from '../../utils/history'

export const endpoint = process.env.REACT_APP_API_ENDPOINT

const httpLink = createHttpLink({
  uri: endpoint,
})

const authLink = setContext(async (_, { headers }) => {
  let accessToken: string | null = null

  accessToken = localStorage.getItem('accessToken')

  return {
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  }
})

const _onError = onError((context) => {
  console.log('onError', context)
  const statusCode = (context.networkError as any)?.statusCode
  if (statusCode === 401 && localStorage.getItem('refreshToken')) {
    checkAllTokens()
      .then((res: any) => {
        const { accessToken } = res
        localStorage.setItem('refreshToken', res.refreshToken)

        context.operation.setContext({
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
        })

        return context.forward(context.operation)
      })
      .catch((err) => {
        console.log('catch err redirect to login page')
        localStorage.clear()
        window.location.reload()
      })
  }
})

const retryLink = new RetryLink({
  delay: {
    initial: 2000,
    max: 2000,
    jitter: false,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  // link: authLink.concat(httpLink)!,
  link: from([retryLink, _onError, authLink, httpLink]),
})
function returnAxiosInstance() {
  const axiosInstance = Axios.create({
    baseURL: endpoint,
    timeout: 1000 * 20, // default is `0` (no timeout) 20 seconds 1000*20
  })

  // Setting headers for authorization. You can do this in instances or interceptors
  // https://stackoverflow.com/questions/45578844/how-to-set-header-and-options-in-axios
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    // Set for all requests (common) , or should we just do for post?
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }
  // axiosInstance.defaults.headers.common['Authorization'] =
  //   'Bearer eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySUQiOiJhZG1pbiIsInVzZXJuYW1lIjoiYWRtaW4iLCJ0eXBlIjoiYWNjZXNzIiwicm9sZVR5cGUiOiJBRE1JTiIsInBhcnR5SUQiOiJMSU5LTE9PSyIsImlhdCI6MTY0NTA0MDA5OSwiZXhwIjoxOTQ1MDQwOTk5fQ.IxZEoitimR75jR-G2ZNeHOYRdU4yX0j03M2QCYx_sIc'

  // Hacked 3 year Expire Access Token : eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJBRE1JTjAxIiwiY3VzdG9tQXR0cnMiOnsicm9sZSI6ImFkbWluIn0sInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE2MjgwMTUwOTQsImV4cCI6MTcyODAxNTM5NH0.SPxtOAbFcDqFkC9G2_9rLUvFfp5QoxqufuzkL8QQc-s
  // Working Token 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJBRE1JTjAxIiwiY3VzdG9tQXR0cnMiOnsicm9sZSI6ImFkbWluIn0sInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE2MjgwMTUwOTQsImV4cCI6MTcyODAxNTM5NH0.SPxtOAbFcDqFkC9G2_9rLUvFfp5QoxqufuzkL8QQc-s',
  //error Access Denied : eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJrcnZweDB3eWprOHh5IiwiY3VzdG9tQXR0cnMiOnsicm9sZSI6ImFkbWluIn0sInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE2MjgxMzgyNjIsImV4cCI6MTYyODEzODU2Mn0.rorTnP1f3zgIMllPGHM-lkTJ-ugIHOzTpB7UiwjA6fk
  // NTC access Denied : eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJrcnc4M2R2c2txSEF3IiwidXNlcm5hbWUiOiJudGNBZG1pbiIsInJvbGUiOiJ0ZWNobmljaWFuIiwidHlwZSI6ImFjY2VzcyIsImlhdCI6MTYyODc0Nzk4NywiZXhwIjoxNjI4NzQ4Mjg3fQ.Tzepb1TtHDq5Dx5k5tt8mTQXIeGAQi49DPwtjx68Oms

  // Add a response intercepter
  axiosInstance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      // console.log('INTERECEPTIOER response', response)
      // console.log('STORE STTATE', store.getState())
      // history.push('/asdpopk')
      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // There are 3 cases it can fall to this error response
      // 1. accessToken Expires in between page refresh, refreshToken not expire -> need to refreshToken, and shoot again
      // 2. accessToken Expires and refreshToken expire -> unfortunately, just handleFailure
      // 2. accessToken is compromised and the signature is not legit -> need to clear tokens

      // console.log('INTERECEPTIOER ERROR error', error)
      // console.log('INTERECEPTIOER ERROR error.config', error.config)
      // console.log('INTERECEPTIOER ERROR error.response', error.response)
      // console.log(
      //   'INTERECEPTIOER ERROR error.response.headers[x-amzn-errortype]',
      //   error.response.headers['x-amzn-errortype'],
      // )
      const originalRequest = error.config

      // This is still not correct because you need to do refresh token and THEN original Request
      if (
        error.response.headers['x-amzn-errortype'] === 'AccessDeniedException' || // 403 When Authorizer wrong key? (Expire, or Verify Fail)
        error.response.headers['x-amzn-errortype'] === 'UnauthorizedException' || // 401 When No Authorizer
        error.response.headers['x-amzn-errortype'] === 'AuthorizerConfigurationException' // 500 When Authorizer asdf -> not sure if this breaks something?
      ) {
        // console.info("Access Denied, I am attempting to Check all Tokens");
        // 1. Check if we should refresh
        // Need to delay original request by returning https://stackoverflow.com/questions/51563821/axios-interceptors-retry-original-request-and-access-original-promise
        return checkAllTokens()
          .then((refreshTokenResponse: any) => {
            const newAccessToken = refreshTokenResponse.accessToken
            const newRefreshToken = refreshTokenResponse.refreshToken
            // Immediately reject if no access Token Provided
            // if (!newAccessToken) handleFailure()
            // else localStorage.setItem('accessToken', newAccessToken)
            if (newRefreshToken) localStorage.setItem('refreshToken', newRefreshToken)
            // console.info(
            //   "[INFO] Successful refresh of tokens and kept in local storage"
            // );
            // store.dispatch(decodeNewToken(newAccessToken, newRefreshToken))
            // console.info("[INFO] Successful dispatch decodeNewToken");
            //2. Do the original Request
            const originalRequestNewHeaders = {
              ...originalRequest,
              headers: {
                ...originalRequest.headers,
                Authorization: newAccessToken,
              },
            }
            // console.log(
            //   "Successfull refresh, I am shooting the original request with new headers ",
            //   originalRequestNewHeaders
            // );
            return Axios(originalRequestNewHeaders)
              .then((originalRequestResponse) => {
                // console.log(
                //   "Successfull originalRequestResponse",
                //   originalRequestResponse
                // );
                return originalRequestResponse
              })
              .catch((originalRequestError) => {
                console.log('UNsuccessfull originalRequestResponse', originalRequestError.response)
                Promise.reject(originalRequestError)
              })
          })
          .catch((tokenErrorMessage: any) => {
            console.error('__________________', tokenErrorMessage)
            // handleFailure()
            Promise.reject(tokenErrorMessage)
          })
      }
      // If not in the if statement case, just reject the error as normal
      console.log('I DONT HAVE ERROR TOKEN, REJECTING', error)
      Promise.reject(error)
    },
  )

  return axiosInstance
}

// Similar to refreshNewTokens but also check for expiry date
// 1. Check if have refreshToken and if the refreshToken is not expired.
// 2. Check if accessToken is not expired, else refreshToken
export const checkAllTokens = () =>
  new Promise((resolve, reject) => {
    // console.log("START checkAllTokens");
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')

    // console.log(accessToken)

    // 1. Check if have refreshToken and if the refreshToken is not expired.
    if (!(refreshToken && isNotExpired(refreshToken))) {
      reject('No refresh token')
    } else if (!(accessToken && isNotExpired(accessToken))) {
      // 2. Check if accessToken is not expired
      // console.log("I am attempting to refresh Tokens");
      refreshNewTokens()
        .then((refreshTokenResponse) => {
          resolve(refreshTokenResponse)
        })
        .catch((err) => {
          reject(`Failed to refresh ${err} ${err.response}`)
        })
    }
  })

// export function handleFailure() {
//   localStorage.clear()
//   store.dispatch(setLoggedInFalse())
//   history.push(`/`)
// }

// Check if the Token is NOT expired
// else we will just shoot out expired tokens when saving
export function isNotExpired(rawToken: any) {
  const { exp }: any = jwt_decode(rawToken)
  if (Date.now() >= exp * 1000) return false
  return true
}

// Export Get and POST
export function get(url = '/') {
  const axios = returnAxiosInstance()
  return axios.get(url)
}

export function post(url = '/', requestData: any) {
  const axios = returnAxiosInstance()
  return axios.post(url, requestData)
}

export function postNoAuth(url = '/', requestData: any) {
  const axios = returnAxiosInstance()
  return axios.post(url, requestData, {
    headers: {
      'x-api-key': `${process.env.REACT_APP_KEY}`,
    },
  })
}

// END Export Get and POST

export const refreshNewTokens = () =>
  new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refreshToken')
    const refreshTokenPayload = {
      query: mutationRefreshToken,
      variables: {
        refreshToken: refreshToken,
      },
    }
    // New axios instance
    Axios.post(`${endpoint}`, refreshTokenPayload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'x-api-key': `${process.env.REACT_APP_KEY}`,
      },
    })
      .then((res) => {
        const { accessToken, refreshToken } = JSON.parse(res.data.data.refreshToken)
        console.log(res.data)
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)
        resolve({
          accessToken,
          refreshToken,
        })
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })

//! Query
export function getTimeAttendanceStreamCount(
  getTimeAttendanceStreamVariables: any,
  query = queryGetTimeAttendanceStreamCount,
) {
  const variables = { ...getTimeAttendanceStreamVariables }
  return post('/', {
    query,
    variables,
  })
}

export function queryListTimeAttendanceReport(listTimeAttendanceVariables: any, query = listTimeAttendanceReport) {
  const variables = { ...listTimeAttendanceVariables, sortDirection: 'DESC', limit: 250 }
  return post('/', {
    query,
    variables,
  })
}

export function queryListLeaveAttendanceReport(
  listLeaveAttendanceVariables: any,
  query = listTimeAttendanceReportTypeLeave,
) {
  const variables = { ...listLeaveAttendanceVariables, sortDirection: 'DESC', limit: 250 }
  return post('/', {
    query,
    variables,
  })
}

export function queryListOTAttendanceReport(listOTAttendanceVariables: any, query = listTimeAttendanceReportTypeOT) {
  const variables = { ...listOTAttendanceVariables, sortDirection: 'DESC', limit: 250 }
  return post('/', {
    query,
    variables,
  })
}

export function updateTimeAttendanceReport(
  updateTimeAttendanceReportVariables: any,
  query = mutationUpdateTimeAttendanceReport,
) {
  const variables = { ...updateTimeAttendanceReportVariables }
  return post('/', {
    query,
    variables,
  })
}

export function updateLeaveAttendance(updateLeaveAttendanceVariables: any, query = mutationUpdateLeaveAttendance) {
  const variables = { ...updateLeaveAttendanceVariables }
  return post('/', {
    query,
    variables,
  })
}
export function updateOvertimeAttendance(updateOTAttendanceVariables: any, query = mutationUpdateOTAttendance) {
  const variables = { ...updateOTAttendanceVariables }
  return post('/', {
    query,
    variables,
  })
}

export function deleteTimeAttendanceReport(
  deleteTimeAttendanceReportVariables: any,
  query = mutationDeleteTimeAttendanceReport,
) {
  const variables = { input: { ...deleteTimeAttendanceReportVariables, Type: 'ATTENDANCE' } }
  return post('/', {
    query,
    variables,
  })
}

export function deleteLeaveAttendanceReport(
  deleteLeaveAttendanceReportVariables: any,
  query = mutationDeleteTimeAttendanceReport,
) {
  const variables = { input: { ...deleteLeaveAttendanceReportVariables, Type: 'LEAVE' } }
  return post('/', {
    query,
    variables,
  })
}

export function deleteOTAttendanceReport(
  deleteOTAttendanceReportVariables: any,
  query = mutationDeleteTimeAttendanceReport,
) {
  const variables = { input: { ...deleteOTAttendanceReportVariables, Type: 'OVERTIME' } }
  return post('/', {
    query,
    variables,
  })
}

//* Department
export function queryListDepartment(query = listDepartment) {
  return post('/', {
    query,
  })
}
export function queryListDepartmentTable(query = listDepartmentTable) {
  return post('/', {
    query,
  })
}
export function queryGetDepartment(
  getDepartmentInput: any,
  query: string = getDepartmant,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...getDepartmentInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//* Team
export function queryListTeamEmployee(query = listTeamEmployee) {
  return post('/', {
    query,
  })
}
export function queryListTeamEmployeeTable(query = listTeamEmployeeTable) {
  return post('/', {
    query,
  })
}

export function queryGetTeam(getTeamInput: any, query: string = getTeam): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...getTeamInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//* Position
export function queryListPosition(query = listPosition) {
  return post('/', {
    query,
  })
}

export function queryGetPosition(getPositionInput: any, query: string = getPosition): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...getPositionInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//* Approve
export function queryListApprove(filter: any, query = listApprove) {
  const variables = {
    ...filter,
  }
  return post('/', {
    query,
    variables,
  })
}

//* ApproveAttendance
// export function queryListApproveAttendance(query = listApproveAttendance) {
//   return post('/', {
//     query,
//   })
// }
export function queryListApproveAttendance(
  listApproveAttendanceInput: any,
  query: string = listApproveAttendance,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...listApproveAttendanceInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//*Paymant
export function queryListPayment(query = listPayment) {
  return post('/', {
    query,
  })
}

export function queryListShift(query = listShift) {
  return post('/', {
    query,
  })
}

export function queryListShiftColor(query = listShiftColor) {
  return post('/', {
    query,
  })
}

export function queryGetShift(input: any, query = getShift) {
  const variables = { ...input }
  return post('/', {
    query,
    variables,
  })
}

export function useQueryShareCalendar(variables: any, isLazy = false) {
  const [data, setData] = React.useState<any>({})
  const [loading, setLoading] = React.useState(true)
  const [_fetch] = useLazyQuery(gql(getShareCalendar))

  const refetch = React.useCallback(
    async (variables: any) => {
      setLoading(true)

      const res = await _fetch({
        variables,
      })

      console.log(res)

      const _data = JSON.parse(JSON.stringify(res.data.getShareCalendar))

      _data.items = _data.items.map((item: any) => {
        const events: any[] = []

        const eventTypeUnique: any = {}

        item.events.forEach((e: any) => {
          const event = JSON.parse(e.event)

          if (e.Type === 'LEAVE') {
            eventTypeUnique[event.leaveType] = event.leaveType
          } else {
            eventTypeUnique[e.Type] = e.Type
          }

          if (e.Type === 'ABSENT') {
            event.leaveDeductList.forEach((l: any) => {
              if (l.leaveType === 'ABSENT') events.push({ ...e, event: l })
            })
          } else {
            events.push({ ...e, event })
          }
        })
        item.events = events
        item.eventTypeUnique = Object.keys(eventTypeUnique)
        return item
      })

      // batching renrender once instead 2 times, setData, setLoading
      flushSync(() => {
        setData(_data)
        setLoading(false)
      })

      return _data
    },
    [variables],
  )

  React.useEffect(() => {
    if (!isLazy) refetch(variables)
  }, [])

  return { data, loading, refetch }
}

//! Mutation
//* Department
export function createDepartment(
  createDepartmentInput: any,
  query: string = mutationCreateDepartment,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...createDepartmentInput,
  }
  return post('/', {
    query,
    variables,
  })
}
export function updateDepartment(
  updateDepartmentInput: any,
  query: string = mutationUpdateDepartment,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...updateDepartmentInput,
  }
  return post('/', {
    query,
    variables,
  })
}
export function deleteDepartment(
  deleteDepartmentInput: any,
  query: string = mutationDeleteDepartment,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...deleteDepartmentInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//* Employee
export function createTeam2(
  createTeam2Input: any,
  query: string = mutationCreateTeam2,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...createTeam2Input,
  }
  return post('/', {
    query,
    variables,
  })
}
export function updateTeam(updateTeamInput: any, query: string = mutationUpdateTeam): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...updateTeamInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//CreateHolidayPolicy
export function CreateHolidayPolicy(
  createHolidayInput: any,
  query: string = mutationCreateHolidayPolicy,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...createHolidayInput,
  }
  return post('/', {
    query,
    variables,
  })
}
//* Position
export function createPosition(
  createPositionInput: any,
  query: string = mutationCreatePosition,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...createPositionInput,
  }
  return post('/', {
    query,
    variables,
  })
}
export function updatePosition(
  updatePositionInput: any,
  query: string = mutationUpdatePosition,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...updatePositionInput,
  }
  return post('/', {
    query,
    variables,
  })
}
export function deletePosition(
  deletePositionInput: any,
  query: string = mutationDeletePosition,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...deletePositionInput,
  }
  return post('/', {
    query,
    variables,
  })
}

export function createTimeAttendanceBatch(
  createTimeAttendanceBatchInput: any,
  query: string = mutationCreateTimeAttendanceBatch,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...createTimeAttendanceBatchInput,
  }
  return post('/', {
    query,
    variables,
  })
}

export function createShift(input: any, query: string = mutationCreateShift): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...input,
  }
  return post('/', {
    query,
    variables,
  })
}

export function updateShift(input: any, query: string = mutationUpdateShift): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...input,
  }
  return post('/', {
    query,
    variables,
  })
}

export function putHexColorList(input: any, query: string = mutationPutHexColorList): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...input,
  }
  return post('/', {
    query,
    variables,
  })
}

export function updateDefaultShift(
  input: any,
  query: string = mutationUpdateDefaultShift,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...input,
  }
  return post('/', {
    query,
    variables,
  })
}

export function deleteShift(input: any, query: string = mutationDeleteShift): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...input,
  }
  return post('/', {
    query,
    variables,
  })
}

export function QueryCalendarPolicy(query: string = queryListCalendar): Promise<AxiosResponse<any, any>> {
  const variables = {}
  return post('/', {
    query,
    variables,
  })
}

//GetHoliDay
export function GetHoliday(holidayId: any, query: string = queryGetHoliday): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...holidayId,
  }
  return post('/', {
    query,
    variables,
  })
}

//UpdateHoliday
export function UpdateHolidayOnYear(
  holidayInput: any,
  query: string = mutationUpdateHoliday,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...holidayInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//UpdateHolidayDefault
export function UpdateDefaultHoliday(
  holidayId: any,
  query: string = mutationUpdateDefaultCalendar,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...holidayId,
  }
  return post('/', {
    query,
    variables,
  })
}

//DelHoliday
export function DelHoliday(holidayId: any, query: string = mutationDelHoliday): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...holidayId,
  }
  return post('/', {
    query,
    variables,
  })
}

// export function ListTimeAttenLog(input: any, query: string = querylistAttendanceLog): Promise<AxiosResponse<any, any>> {
//   const variables = {
//     ...input,
//     sortDirection: ModelSortDirection.Desc,
//   }
//   return post('/', {
//     query,
//     variables,
//   })
// }

export function ListPayroll(input: any, query: string = queryListPayroll): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...input,
  }
  return post('/', {
    query,
    variables,
  })
}

//AttendanceReport

// export function QueryAttendanceReport(
//   datevariables: any,
//   query: string = queryListAttendanceReport,
// ): Promise<AxiosResponse<any, any>> {
//   const variables = {
//     ...datevariables,
//   }
//   return post('/', {
//     query,
//     variables,
//   })
// }

export function UpdateAttendanceReport(
  reportVariables: any,
  query: string = mutationAttendanceReport,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...reportVariables,
  }
  return post('/', {
    query,
    variables,
  })
}

//ListPayroll
export function QueryListPayroll(
  payrollvariables: any,
  query: string = queryListPayroll,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...payrollvariables,
  }
  return post('/', {
    query,
    variables,
  })
}

//GetAttendance
export function QueryGetAttendance(
  reportVariables: any,
  query: string = queryGetAttendance,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...reportVariables,
  }
  return post('/', {
    query,
    variables,
  })
}

export function createPayroll(input: any, query = mutationCreatePayroll): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...input,
  }
  return post('/', {
    query,
    variables,
  })
}
//Create Leave Document
export function CreateLeaveDocument(
  leaveInput: any,
  query: string = mutationCreateLeaveDocument,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...leaveInput,
  }
  return post('/', {
    query,
    variables,
  })
}

export function getPayrollConfig(query = queryPayrollConfig): Promise<AxiosResponse<any, any>> {
  const variables = {}
  return post('/', {
    query,
    variables,
  })
}

export function putEmployeePayroll(input: any, query = mutationPutEmployeePayroll): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...input,
  }
  return post('/', {
    query,
    variables,
  })
}

//Create Overtime Document
export function CreateOvertimeDocument(
  overtimeInput: any,
  query: string = mutationCreateOvertimeDocument,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...overtimeInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//Reset Attendance
export function ResetAttendanceReport(
  resetInput: any,
  query: string = mutationResetAttendance,
): Promise<AxiosResponse<any, any>> {
  const variables = {
    ...resetInput,
  }
  return post('/', {
    query,
    variables,
  })
}

//Del RequestOvertime
export function DelRequestOvertime(
  delovertimeInput: any,
  query: string = mutationDelRequestOverTime,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...delovertimeInput }
  return post('/', {
    query,
    variables,
  })
}

export function DelRequestLeave(
  deleteleaveInput: any,
  query: string = mutationDeleteLeave,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...deleteleaveInput }
  return post('/', {
    query,
    variables,
  })
}

export function ApproveDocument(
  approveInput: any,
  query: string = mutationApproveDocument,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...approveInput }
  return post('/', {
    query,
    variables,
  })
}

export function ApproveDocumentBatch(
  approveInput: any,
  query: string = mutationApproveBatch,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...approveInput }
  return post('/', {
    query,
    variables,
  })
}

export function QueryEmployeeList(filter: any, query: string = ListEmployee): Promise<AxiosResponse<any, any>> {
  const variables = { ...filter }
  return post('/', {
    query,
    variables,
  })
}

export function Login(userInput: any, query: string = mutationLogin): Promise<AxiosResponse<any, any>> {
  const variables = { ...userInput }
  return postNoAuth('/', {
    query,
    variables,
  })
}

//CreateEmployeeProfile

export function CreateEmployeeProfile(
  userInput: any,
  query: string = mutationCreateEmployeeProfile,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...userInput }
  return post('/', {
    query,
    variables,
  })
}

//GetEmployee
export function GetEmployeeProfile(
  employeeId: any,
  query: string = querygetEmployee,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...employeeId }
  return post('/', {
    query,
    variables,
  })
}

//UpdateEmployeeProfile
export function UpdateEmployeeProfile(
  uservariables: any,
  query: string = mutationupdateEmployeeProfile,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...uservariables }
  return post('/', {
    query,
    variables,
  })
}

//ClosePayroll
export function ClosePayroll(
  closeVaraibles: any,
  query: string = mutationClosePayroll,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...closeVaraibles }
  return post('/', {
    query,
    variables,
  })
}

//PayrollSlip
export function GetPayrollSlip(
  employeeVariable: any,
  query: string = mutationPayrollSlip,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...employeeVariable }
  return post('/', {
    query,
    variables,
  })
}

//LeaveDocument

export function ListLeaveDocument(
  employeeVariable: any,
  query: string = queryLeaveDocument,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...employeeVariable }

  return post('/', {
    query,
    variables,
  })
}

export function ListOvertimeDocument(
  OvertimeVariable: any,
  query: string = queryOvertimeDocument,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...OvertimeVariable }

  return post('/', {
    query,
    variables,
  })
}

export function GetOvertimeDocument(
  OvertimeVariable: any,
  query: string = querygetovertimeDocument,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...OvertimeVariable }

  return post('/', {
    query,
    variables,
  })
}

export function UpdateOvertimeDocument(
  OvertimeVariable: any,
  query: string = mutationUpdateOvertime,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...OvertimeVariable }
  return post('/', {
    query,
    variables,
  })
}

export function DeleteOvertimeDocument(
  OvertimeVariable: any,
  query: string = mutationDeleteOvertime,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...OvertimeVariable }
  return post('/', {
    query,
    variables,
  })
}

//LeaveDocument
export function GetLeaveDocument(Input: any, query: string = queryGetLeaveDocument): Promise<AxiosResponse<any, any>> {
  const variables = { ...Input }
  return post('/', {
    query,
    variables,
  })
}

export function UpdateLeaveDocument(
  Input: any,
  query: string = mutationUpdateLeaveDocument,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...Input }
  return post('/', {
    query,
    variables,
  })
}

export function DeleteLeaveDocument(
  Input: any,
  query: string = mutationDeleteLeaveDocument,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...Input }
  return post('/', {
    query,
    variables,
  })
}

export const gqlResponseType = async <T,>(response: Promise<AxiosResponse<any>>, typename: string): Promise<T> => {
  const res = await response

  if (res.data.errors) throw new Error(JSON.stringify(res.data.errors))

  const data = res.data.data[typename]

  return data
}

export function ListOtherIncomeOrExpense(
  Input: any,
  query: string = queryotherIncome,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...Input }
  return post('/', {
    query,
    variables,
  })
}

export function ListLeaveConfig(
  variablesInput: any,
  query: string = queryListLeaveConfig,
): Promise<AxiosResponse<any, any>> {
  const variables = { ...variablesInput }
  return post('/', {
    query,
    variables,
  })
}
