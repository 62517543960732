import { gql, useMutation, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { mutationCreateEmployeeProfile, mutationupdateEmployeeProfile } from 'src/adapter/xhr/mutation'
import { GET_ACTIVE_EMPLOYEE } from 'src/adapter/xhr/query'
import useHandleResponseAPI from '../useHandleResponseAPI'

function useEmployee(variables?: any) {
  const { data, refetch, loading, error } = useQuery(GET_ACTIVE_EMPLOYEE, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createEmployee, optionCreate] = useMutation(gql(mutationCreateEmployeeProfile))

  const [updateEmployee, optionUpdate] = useMutation(gql(mutationupdateEmployeeProfile))
  const isLoading = useMemo(() => {
    return loading || optionCreate.loading || optionUpdate.loading
  }, [loading, optionUpdate.loading, optionCreate.loading])

  const errMsg = useMemo(() => {
    if (error) return JSON.stringify(error)
    else if (optionCreate.error) return JSON.stringify(optionCreate.error)
    else if (optionUpdate.error) return JSON.stringify(optionUpdate.error)
    else return ''
  }, [error, optionUpdate.error, optionCreate.error])

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg,
  })
  return {
    data: data?.listEmployee,
    refetch,
    createEmployee,
    updateEmployee,
  }
}

export default useEmployee
