import styled from '@emotion/styled'
import { Grid, Stack, Box, Paper } from '@mui/material'
import { Formik, FormikProps, Form } from 'formik'
import React, { useEffect } from 'react'
import {
  InputNumberUnitComponent,
  CustomizedSwitches,
  ButtonComponent,
  ContentContainer,
  InputSelectComponent,
  PopperContainer,
  FastFieldProvider,
} from '../../component'

import MinusIcon from '../../image/minus-grey.svg'
import CheckIcon from '../../image/check-circle.svg'
import { createDiligent, getDiligent, useQueryListLeaveList } from '../../adapter/api'
import { MONTH_THAI } from '../../utils/enums'
import { Diligent, EDiligentType, EStatus } from '../../utils/generated'
import { deepClone } from '../../utils/common'
import Swal from 'sweetalert'
import QuestionIcon from '../../image/question.svg'

type IValue = Diligent

const DiligentPolicyPage = () => {
  const formikRef = React.useRef<FormikProps<Diligent> | null>(null)

  const [prevDiligent, setPrevDiligent] = React.useState<Diligent>()
  const initialValues = {}

  const onSubmit = async (values: IValue) => {
    try {
      await createDiligent(values)
      Swal({ title: 'Saved', icon: 'success' })
    } catch (error) {
      Swal({ title: 'Error', icon: 'error' })
    }
  }

  const { data: leaveConfigs } = useQueryListLeaveList({})

  const fethcDiligent = async () => {
    const res = await getDiligent()
    setPrevDiligent(deepClone(res || {}))
    formikRef.current?.setValues(deepClone(res || {}))
  }

  useEffect(() => {
    fethcDiligent()
  }, [])

  const monthList = MONTH_THAI.map((m, index) => ({
    label: m,
    value: index + 1,
  }))

  const handleChangeDiligentType = (type: EDiligentType) => {
    if (!formikRef.current) return
    const { values, setFieldValue } = formikRef.current
    return (checked: boolean) => {
      if (checked && (values.diligentType === EDiligentType.None || values.diligentType !== type)) {
        return setFieldValue('diligentType', type)
      }

      if (!checked && values.diligentType === type) {
        return setFieldValue('diligentType', EDiligentType.None)
      }
    }
  }

  const handleReset = () => {
    formikRef.current?.setValues(deepClone(prevDiligent || {}))
  }

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} innerRef={formikRef}>
        {({ values }) => (
          <FastFieldProvider>
            <Form>
              <ContentContainer rowGap="2rem">
                <Grid container columns={2} columnSpacing="5rem">
                  <Grid item xs={1}>
                    <InputNumberUnitComponent _name="diligentPerMonth" _label="เบี้ยขยันต่อเดือน" _unit="บาท" />
                  </Grid>
                </Grid>
                <Grid container columns={2} columnSpacing="5rem">
                  <Grid item xs={1}>
                    <Stack sx={{ height: '100%' }}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" mb="1rem">
                        <Stack direction="row" alignItems="center" columnGap="0.5rem">
                          <Box component="p">เบี้ยขยันสะสมขั้นบันได</Box>
                          <SwitchPopper text="เบี้ยขยันขั้นบันได คือ กรณีพนักงานทำตามเงื่อนไขติดต่อกัน" />
                        </Stack>
                        <CustomizedSwitches
                          _name="diligentType"
                          _checked={values.diligentType === EDiligentType.Compound}
                          onChange={handleChangeDiligentType(EDiligentType.Compound)}
                        />
                      </Stack>
                      <Stack
                        rowGap="1rem"
                        sx={{ backgroundColor: 'white', p: '1rem', borderRadius: '5px', flexGrow: 1 }}
                      >
                        <InputNumberUnitComponent _name="incrementPerRound" _label="เพิ่มขึ้นรอบละ" _unit="บาท" />
                        <InputNumberUnitComponent
                          _name="maxIncrementRound"
                          _label="จำนวนรอบสูงสุดที่สะสม *"
                          _unit="รอบ"
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb="1rem">
                      <Stack direction="row" alignItems="center" columnGap="0.5rem">
                        <Box component="p">เบี้ยขยันพิเศษติดต่อกัน</Box>
                        <SwitchPopper text="เเงินพิเศษคือ พนักงานไม่ขาด ไม่ลา ไม่สายต่อเนื่องในเดือนมกราคม - สิงหาคม รับเงินพิเศษเพิ่ม 1,000 บาท แล้วกำหนดเดือนจ่ายคือรอบกันยายน" />
                      </Stack>
                      <CustomizedSwitches
                        _name="diligentType"
                        _checked={values.diligentType === EDiligentType.Consecutive}
                        onChange={handleChangeDiligentType(EDiligentType.Consecutive)}
                      />
                    </Stack>
                    <Stack rowGap="1rem" sx={{ backgroundColor: 'white', p: '1rem', borderRadius: '5px' }}>
                      <InputNumberUnitComponent _name="extraMoney" _label="เงินพิเศษ" _unit="บาท" />
                      <Grid container columns={2} columnSpacing="1rem">
                        <Grid item xs={1}>
                          <InputSelectComponent _name="monthStart" _menuList={monthList} _label="เดือนเริ่มต้น" />
                        </Grid>
                        <Grid item xs={1}>
                          <InputSelectComponent _name="monthEnd" _menuList={monthList} _label="เดือนสิ้นสุด" />
                        </Grid>
                      </Grid>
                      <InputSelectComponent _name="monthPayment" _menuList={monthList} _label="เดือนที่จ่าย" />
                    </Stack>
                  </Grid>
                </Grid>
                <Stack rowGap="1rem">
                  <Grid container columns={5} px="1rem">
                    <Grid item xs={3}>
                      <Stack justifyContent="center" pl="4rem">
                        <Box>เงื่อนไข</Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={1}>
                      <Stack justifyContent="center" alignItems="center">
                        <Box>ได้เบี้ยขยัน</Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={1}>
                      <Stack justifyContent="center" alignItems="center">
                        <Box>ไม่ได้เบี้ยขยัน</Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack>
                    {leaveConfigs.map((leaveConfig) => (
                      <Row key={leaveConfig.id}>
                        <Grid container columns={5} alignItems="center">
                          <Grid item xs={3} pl="4rem">
                            {leaveConfig.name}
                          </Grid>
                          {leaveConfig.isLeaveDiligent ? (
                            <>
                              <Grid item xs={1}>
                                <Stack justifyContent="center" alignItems="center">
                                  <Icon src={MinusIcon} />
                                </Stack>
                              </Grid>
                              <Grid item xs={1}>
                                <Stack justifyContent="center" alignItems="center">
                                  <Icon src={CheckIcon} />
                                </Stack>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={1}>
                                <Stack justifyContent="center" alignItems="center">
                                  <Icon src={CheckIcon} />
                                </Stack>
                              </Grid>
                              <Grid item xs={1}>
                                <Stack justifyContent="center" alignItems="center">
                                  <Icon src={MinusIcon} />
                                </Stack>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Row>
                    ))}
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" alignItems="center" columnGap="1rem">
                    <ButtonComponent
                      _colorBG="white"
                      _colorText="red"
                      _text="ยกเลิก"
                      _type="button"
                      _variant="text"
                      _sx={{ fontSize: '16px', width: 'fit-content' }}
                      _functionOnClick={handleReset}
                    />
                    <ButtonComponent
                      _colorBG="blue"
                      _colorText="white"
                      _text="บันทึก"
                      _type="submit"
                      _variant="text"
                      _sx={{ fontSize: '16px', width: 'fit-content' }}
                    />
                  </Stack>
                  <Stack direction="row" alignItems="center" columnGap="1rem">
                    <Box component="p">เปิดใช้งาน</Box>
                    <CustomizedSwitches.Formik
                      _name="status"
                      activeValue={EStatus.Active}
                      inactiveValue={EStatus.Inactive}
                    />
                  </Stack>
                </Stack>
              </ContentContainer>
            </Form>
          </FastFieldProvider>
        )}
      </Formik>
    </>
  )
}

interface SwitchPopperProps {
  text: string
}

const SwitchPopper: React.FC<SwitchPopperProps> = ({ text }) => {
  return (
    <PopperContainer button={<QuetionIcon src={QuestionIcon} />} placement="right">
      <Paper sx={{ width: '12rem', ml: '0.5rem', p: '0.5rem' }}>{text}</Paper>
    </PopperContainer>
  )
}

const QuetionIcon = styled.img`
  width: 1.25rem;
  margin-bottom: -4px;
`

const Icon = styled.img`
  width: 2rem;
`
const Row = styled.div`
  :nth-of-type(even) {
    background: white;
  }
  :nth-of-type(odd) {
    background: #f5f4f6;
  }

  padding: 0.5rem 1rem;
`

export default DiligentPolicyPage
