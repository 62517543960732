import React from 'react'
import PdfSVG from '../../image/pdfDoc.svg'
import ExcelSVG from '../../image/excelDoc.svg'
import basicSVG from '../../image/basicDoc.svg'
import detailedSVG from '../../image/detailedDoc.svg'

import ButtonComponent from '../Button'
import {
  AutocompleteComponent,
  InputDateComponentCustom,
  InputDateComponentCustome,
  InputRadioComponent,
  InputSelectComponentCustom,
} from '../InputsFormik'
import styles from './index.module.scss'
import { EDocumentType, EEmployeeType } from 'src/utils/generated'
import { downloadFile } from 'src/utils/common'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'src/state/reducers'
import Swal from 'sweetalert2'

interface ReportProps {
  headerLabel: string
  value: any
  documentType?: EDocumentType
  mutationData?: any
  loading?: boolean
  setShowfile?: any
  defaultData?: any
  setFileData?: any
  setTypeFile?: any
}

const ReportFilter: React.FC<ReportProps> = (props: ReportProps): JSX.Element => {
  const getEmployeeReducer = useSelector((state: ICombineReducers) => state.employeeReducer)

  const TranferNull = (data: any) => {
    if (data.length > 0) {
      return data
    } else {
      return null
    }
  }

  const VariablesData = () => {
    if (props.documentType === EDocumentType.TimeAttendanceReportDoc) {
      return {
        input: {
          documentType: props.documentType,
          startedAt: props.value.values.startedAt,
          endedAt: props.value.values.endedAt,
          employeeID: TranferNull(props.value.values.employeeID),
          departmentID: TranferNull(props.value.values.departmentID),
          employeeType: TranferNull(props.value.values.employeeType),
        },
      }
    } else if (props.documentType === EDocumentType.EmployeeAllReportDoc) {
      return {
        input: {
          documentType: props.documentType,
          employeeID: TranferNull(props.value.values.employeeID),
          departmentID: TranferNull(props.value.values.departmentID),
        },
      }
    } else if (props.documentType === EDocumentType.EmployeeLukjangReportDoc) {
      return {
        input: {
          documentType: props.documentType,
          startedAt: null,
          endedAt: null,
          employeeID: TranferNull(props.value.values.employeeID),
          departmentID: TranferNull(props.value.values.departmentID),
        },
      }
    } else {
      return {
        input: {
          documentType: props.documentType,
          startedAt: props.value.values.startedAt,
          endedAt: props.value.values.endedAt,
          employeeID: TranferNull(props.value.values.employeeID),
          departmentID: TranferNull(props.value.values.departmentID),
        },
      }
    }
  }

  const MutationData = VariablesData()

  const onDownload = () => {
    if (EDocumentType.TimeAttendanceLeaveLateDocPerEmp && props.value.values?.employeeID.length === 0) {
      return Swal.fire({
        title: 'เลือกพนักงานที่ต้องการ',
        icon: 'error',
      })
    }
    if (props.defaultData) {
      if (props.value.values.documentType === 'PDF') {
        downloadFile(props.defaultData.downloadPDFDocument, props.defaultData.downloadPDFDocument)
      } else {
        downloadFile(props.defaultData.downloadEXCELDocument, props.defaultData.downloadEXCELDocument)
      }
    } else {
      props
        .mutationData({
          variables: MutationData,
        })
        .then((res: any) => {
          if (props.value.values.documentType === 'PDF') {
            downloadFile(res.data.downloadPDFDocument, res.data.downloadPDFDocument)
          } else {
            downloadFile(res.data.downloadEXCELDocument, res.data.downloadEXCELDocument)
          }
        })
    }
  }

  const onShowDocument = () => {
    if (EDocumentType.TimeAttendanceLeaveLateDocPerEmp && props.value.values?.employeeID.length === 0) {
      return Swal.fire({
        title: 'เลือกพนักงานที่ต้องการ',
        icon: 'error',
      })
    }
    props
      .mutationData({
        variables: MutationData,
      })
      .then((res: any) => {
        if (props.value.values.documentType === 'PDF') {
          props.setFileData(res.data.downloadPDFDocument)
          props.setTypeFile('PDF')
        } else {
          props.setFileData(res.data.downloadEXCELDocument)
          props.setTypeFile('EXCEL')
        }
        props.setShowfile(true)
      })
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapperCard}>
        <p className={styles.textHead}>{props.headerLabel}</p>
        {props.documentType === EDocumentType.EmployeeAllReportDoc ? (
          <>
            <section className={styles.groupForm}>
              <p className={styles.text}>ปี</p>
              <div className={styles.inputBox}>
                <div className={styles.dateInput}>
                  <InputDateComponentCustome _name="year" _label="ปี" _type="month" />
                </div>
              </div>
            </section>
            <section className={styles.groupForm}>
              <p className={styles.text}>สาขา</p>
              <AutocompleteComponent _name="branch" _label="สาขา" _option={[]} _disabled={true} />
            </section>
            <section className={styles.groupForm}>
              <p className={styles.text}>รูปแบบเอกสาร</p>
              <div className={styles.box}>
                <div className={styles.groupImg}>
                  <img src={PdfSVG} alt="PdfSVG" />
                  <img src={ExcelSVG} alt="ExcelSVG" />
                </div>
                <InputRadioComponent
                  _row
                  _name="documentType"
                  _menuList={[
                    { label: 'PDF', value: 'PDF' },
                    { label: 'Excel', value: 'EXCEL' },
                  ]}
                />
              </div>
            </section>
          </>
        ) : (
          <>
            {props.documentType === EDocumentType.EmployeeLukjangReportDoc ? (
              ''
            ) : (
              <section className={styles.groupForm}>
                <p className={styles.text}>ช่วงวันที่</p>
                <div className={styles.inputBox}>
                  <div className={styles.dateInput}>
                    <InputDateComponentCustom
                      _name="startedAt"
                      _label="วันที่เริ่ม"
                      _value={props.value.values.startedAt}
                    />
                  </div>
                  <div className={styles.dateInput}>
                    <InputDateComponentCustom
                      _name="endedAt"
                      _label="วันที่สิ้นสุด"
                      _value={props.value.values.endedAt}
                    />
                  </div>
                </div>
              </section>
            )}
            <section className={styles.groupForm}>
              {/* <p className={styles.text}>แผนก</p> */}
              {/* <AutocompleteComponent _name="departmentID" _label="แผนก" _option={ListDepartment} _limitTags={2} /> */}
              {/* <InputCheckboxComponentCustom
                _name="checkDepartment"
                _label="เลือกแผนกทั้งหมด"
                _value={props.value.values.checkDepartment}
              /> */}
            </section>
            <section className={styles.groupForm}>
              <p className={styles.text}>พนักงาน</p>
              <InputSelectComponentCustom
                _name={'employeeID'}
                _label={'เลือกพนักงาน'}
                _menuList={getEmployeeReducer.itemsListOption}
                // _helperText={props.value.errors.employeeID}
              />
              {/* <AutocompleteComponent
                _name="employeeID"
                _label="แผนก"
                _option={getEmployeeReducer.itemsListOption}
                _limitTags={2}
              /> */}
              {/* <InputCheckboxComponentCustom
                _name="employeeall"
                _label="เลือกพนักงานทั้งหมด"
                _value={props.value.values.employeeall}
              /> */}
            </section>
            {/* <section className={styles.groupForm}>
              <p className={styles.text}>สาขา</p>
              <AutocompleteComponent _name="branch" _label="สาขา" _option={[]} _disabled={true} />
            </section> */}
            {props.documentType === EDocumentType.PayrollSalaryPermonth ? (
              <section className={styles.groupForm}>
                <p className={styles.text}>รูปแบบเอกสาร</p>
                <div className={styles.box}>
                  <div className={styles.groupImg}>
                    <img src={basicSVG} alt="basicSVG" />
                    <img src={detailedSVG} alt="detailedSVG" />
                  </div>
                  <InputRadioComponent
                    _row
                    _name="documentType"
                    _menuList={[
                      { label: 'อย่างง่าย', value: 'PDF' },
                      { label: 'อย่างละเอียด', value: 'EXCEL' },
                    ]}
                    _disabled
                  />
                </div>
              </section>
            ) : (
              <section className={styles.groupForm}>
                <p className={styles.text}>รูปแบบเอกสาร</p>
                <div className={styles.box}>
                  <div className={styles.groupImg}>
                    <img src={PdfSVG} alt="PdfSVG" />
                    <img src={ExcelSVG} alt="ExcelSVG" />
                  </div>
                  <InputRadioComponent
                    _row
                    _name="documentType"
                    _disabled
                    _menuList={[
                      { label: 'PDF', value: 'PDF' },
                      { label: 'Excel', value: 'EXCEL' },
                    ]}
                  />
                </div>
              </section>
            )}
          </>
        )}

        {props.documentType === EDocumentType.TimeAttendanceReportDoc && (
          <>
            <section className={styles.groupForm}>
              <p className={styles.text}>สถานที่ทำงาน</p>
              <InputSelectComponentCustom _name="location" _label="สถานที่ทำงาน" _menuList={[]} _disabled />
            </section>
            <section className={styles.groupForm}>
              <p className={styles.text}>ประเภทพนักงาน</p>
              <AutocompleteComponent _name="employeeType" _label="ประเภทพนักงาน" _option={EMPLOYEE_TYPES_LIST} />
            </section>
          </>
        )}
      </div>

      <section className={styles.buttonForm}>
        <ButtonComponent
          _type={'button'}
          _colorBG={'white'}
          _colorText={'blue'}
          _variant={'outlined'}
          _text={'แสดงข้อมูล'}
          _isIcon="view"
          _functionOnClick={onShowDocument}
        />
        <ButtonComponent
          _type={'button'}
          _colorBG={'blue'}
          _colorText={'white'}
          _variant={'contained'}
          _text={'ดาวน์โหลด'}
          _isIcon="download"
          _functionOnClick={onDownload}
        />
        {props.loading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          ''
        )}
      </section>
    </div>
  )
}
export default ReportFilter

export const EMPLOYEE_TYPES_LIST = [
  {
    value: EEmployeeType.FulltimeMonth,
    title: 'พนักงานรายเดือน',
  },
  {
    value: EEmployeeType.FulltimeDay,
    title: 'พนักงานรายวัน',
  },

  {
    value: EEmployeeType.Parttime,
    title: 'พนักงานพาร์ทไทม์',
  },
  {
    value: EEmployeeType.Outsource,
    title: 'พนักงานรายจ้างเหมา',
  },
]
