import moment from 'moment'
import { isBeforeShift } from 'src/utils/common'
import * as Yup from 'yup'

const isSameOrBefore = (startTime: any, endTime: any) => {
  if (!startTime && !endTime) {
    return true
  }
  return moment(startTime, 'HH:mm').isSameOrBefore(moment(endTime, 'HH:mm'))
}
export const isSame = (startTime: any, endTime: any) => {
  return !moment(startTime, 'HH:mm').isSame(moment(endTime, 'HH:mm'))
}

const yupTimeAttendanceReport = Yup.object().shape({
  report: Yup.object().shape({
    scanIn: Yup.string()
      .test('start_time_test', 'เวลาสแกนเออกต้องมากกว่าเวลาแสกนเข้า', function (value) {
        const { scanOut } = this.parent
        return isSameOrBefore(value, scanOut)
      })
      .test('isSameTest', 'เวลาสแกนเข้าและแสกนออกเท่ากัน', function (value) {
        const { scanOut } = this.parent
        return isSame(value, scanOut)
      }),
  }),
  // documentOvertime: Yup.object().shape({
  //   overtimeType: Yup.string().required('เลือกประเภทการทำงานล่วงเวลา'),
  //   startedAt: Yup.string().required('กรอกเวลาเริ่มต้น'),
  //   endedAt: Yup.string().required('กรอกเวลาสิ้นสุด'),
  // }),
  documentLeave: Yup.object().shape({
    leaveType: Yup.string().required('เลือกประเภทการทำงานล่วงเวลา'),
    leaveForm: Yup.string().required('เลือกรูปแบบการลา'),
    startedAt: Yup.string().required('กรอกเวลาเริ่มต้น'),
    endedAt: Yup.string().required('กรอกเวลาสิ้นสุด'),
  }),
  //   overtimeList: Yup.array().of(
  //     Yup.object().shape({
  //       startedAt: Yup.string()
  //         .required('กรอกเวลาเริ่มต้น')
  //         .test(
  //           'start_time_test',
  //           `ไม่อยู่ในช่วงเวลา Shift (${values.attendance.shiftStartTime} - ${values.attendance.shiftEndTime}) `,
  //           function (value) {
  //             return isBeforeShift(value, values.attendance.shiftEndTime)
  //           },
  //         ),
  //     }),
  //   ),
})

export default yupTimeAttendanceReport
