import React, { useMemo, useState } from 'react'
import styles from './index.module.scss'
import { Formik } from 'formik'
// import * as yup from 'yup'
import { ButtonComponent, ProgessBarComponent } from '../../component'
import payIcon from '../../image/payIcon.svg'
import { TabPayroll } from './tab'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import SettingIcon from '../../image/setting.svg'
import HistoryIcon from '../../image/historyEdit.svg'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ModalHistory } from './modalHistory'
import {
  convertDateString,
  convertToPeriodList,
  getPeriodStartEndDate,
  PeriodElement,
} from '../../utils/periodCalCulate'
import { TextField, FormControl, InputLabel, MenuItem, Select, Popover, CircularProgress } from '@mui/material'
// import { months } from '../../utils/common'
import { LinearProgress } from '@mui/material'
import { gql, useQuery, useMutation } from '@apollo/client'
// import { PayrollConfig, PayrollQuery } from '../../utils/generated'
// import usePaginationQuery from 'src/hooks/usePaginationQuery'
import {
  Employee,
  EmployeePayroll,
  PayrollConfig,
  PayrollQuery,
  PayrollRoot,
  TimeAttendanceReportResponse,
} from '../../utils/generated'
import usePaginationQuery, { IUsePaginationQueryOutput } from 'src/hooks/usePaginationQuery'
// import { activeEmployeeFilter, GET_ACTIVE_EMPLOYEE, GET_LISTPAYROLL } from 'src/adapter/xhr/query'
import { useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { downloadFile } from 'src/utils/common'

const periodNumberToText: any = {
  1: 'แรก',
  2: 'สอง',
  3: 'สาม',
  4: 'สี่',
}

const Payroll: React.FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const yearMonth = searchParams.get('yearMonth')
  // The selected yearMonth
  const [date, setDate] = useState<Date>(yearMonth ? new Date(yearMonth) : new Date())
  // The selected period
  const [selectedPeriod, setSelectedPeriod] = useState('ALL')
  // const [periodMap, setPeriodMap] = useState<PeriodElementMap>({})
  const [modalhistory, setModalHistory] = useState(false)
  // const [nextToken, setNextToken] = useState<any>(null)

  // const { loading, error, data, refetch } = useQuery<QueryListPayrollResponse>(queryListPayroll, {
  //   variables: {
  //     yearMonth: date.toLocaleString('sv-SE', {
  //       year: 'numeric',
  //       month: '2-digit',
  //     }),
  //     period: selectedPeriod,
  //     nextToken: nextToken,
  //   },
  //   // https://github.com/apollographql/react-apollo/issues/321
  //   notifyOnNetworkStatusChange: true, // for loading to be changed on refetch
  // })

  // Query 3 Entete: (1) Root (2) Time Attendance (3) Payroll
  // (1) Root Query: Get the Root for that yearMonth
  const { loading, error, data, refetch } = useQuery<QueryListPayrollRootResponse>(queryListPayrollRoot, {
    variables: {
      yearMonth: date.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
      }),
      period: selectedPeriod,
    },
    // https://github.com/apollographql/react-apollo/issues/321
    notifyOnNetworkStatusChange: true, // for loading to be changed on refetch
  })
  const payrollRoot: (PayrollRoot & Record<string, any>) | null = useMemo(() => {
    if (data && data.listPayrollRoot && Object.keys(data.listPayrollRoot).length > 0) {
      return data.listPayrollRoot.reduce<PayrollRoot>((prev, curr) => {
        const parsedData = JSON.parse(curr) as PayrollRoot
        // Since payrollRoot will return as a list, SUM IT ALL
        // if it's a string/map/list, replace. If it's a number, add
        for (const [key, value] of Object.entries(parsedData)) {
          if (typeof value === 'string') prev[key as keyof PayrollRoot] = value
          else if (typeof value === 'number')
            prev[key as keyof PayrollRoot] = prev[key as keyof PayrollRoot]
              ? prev[key as keyof PayrollRoot] + value
              : value
          // For Cases such as map/list
          else prev[key as keyof PayrollRoot] = value
        }
        return prev
      }, {} as any)
    }
    return null
  }, [date, data])
  // (2) TimeAttendance Query
  const payrollTimeAttendanceQuery = usePaginationQuery<TimeAttendanceReportResponse>({
    query: queryListPayrollTimeAttendance,
    convertRefetch: (s) => s.listPayrollTimeAttendance,
    additionalVariables: {
      yearMonth: date.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
      }),
    },
    initialPageSize: 20,
  })
  // (3) Payroll Query
  const payrollEmployeeQuery = usePaginationQuery<EmployeePayroll>({
    query: queryListPayrollEmployee,
    convertRefetch: (s) => s.listPayrollEmployee,
    additionalVariables: {
      yearMonth: date.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
      }),
    },
    initialPageSize: 30,
  })
  // {
  //   currentTableData: currentTableDataEP,
  //   currentPage: currentPageEP,
  //   setCurrentPage: setCurrentPageEP,
  //   pageSize: pageSizeEP,
  //   setPageSize: setPageSizeEP,
  //   windowSize: windowSizeEP,
  //   maxHasNextToken: masHasNextTokenEP,
  //   currentMaxPageIndex: currentMaxPageIndexEP,
  //   loading: loadingEP,
  //   clearCacheRefetch: clearCacheRefetchEP,
  // }

  // Get Payroll Config
  // Multiple useQuery: https://github.com/trojanowski/react-apollo-hooks/issues/120
  const { loading: loadingPC, error: errorPC, data: dataPC } = useQuery<QueryPayrollConfigResponse>(queryPayrollConfig)

  // useMutation on Create
  const [createPayroll, { loading: loadingCP, error: errorCP }] = useMutation(mutationCreatePayroll)

  const periodMap: PeriodElementMap = useMemo(() => {
    if (dataPC && !loadingPC && !errorPC) {
      const companyPayrollConfig = dataPC.getPayrollConfig
      const periodStart = companyPayrollConfig.periodStart

      const [periodStartDate, periodEndDate] = getPeriodStartEndDate(
        date.getFullYear(),
        date.getMonth() + 1,
        periodStart,
      )

      const periodList = [
        {
          period: 'ALL',
          periodStart: convertDateString(periodStartDate),
          periodEnd: convertDateString(periodEndDate),
        },
        {
          period: '1.1',
          periodStart: convertDateString(periodStartDate),
          periodEnd: convertDateString(periodEndDate),
        },
        ...convertToPeriodList(companyPayrollConfig.periodCount, periodStartDate, periodEndDate, companyPayrollConfig),
      ]
      console.log(periodList, 'convertToPeriodList')

      return periodList.reduce<PeriodElementMap>((map, ele) => {
        map[ele.period] = ele
        return map
      }, {})
    } else {
      return {}
    }
  }, [dataPC, date])

  const createPayrollAndRefetch = () => {
    const yearMonth = date.toLocaleString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
    })
    createPayroll({ variables: { yearMonth } }).then((res) => {
      console.log(res, 'ress createPayroll')

      refetch({
        variables: {
          yearMonth,
          period: selectedPeriod,
        },
      })
    })
  }

  // const [closePayroll, setClosePayroll] = useState<any>()

  console.log('periodMap', periodMap)
  // console.log('selectedPeriod', selectedPeriod)
  console.log('loading', loading)
  console.log('loadingPC', loadingPC)
  console.log('loadingCP', loadingCP)
  console.log('data', data)
  // console.log('dataPC', dataPC)
  console.log('payrollRoot', payrollRoot)

  let payrollContent
  // If it is loading
  if (loading || loadingPC || loadingCP || Object.keys(periodMap).length < 1) payrollContent = <ProgessBarComponent />
  // If there is any error or no data
  else if (error || !data || errorPC || !dataPC || errorCP)
    payrollContent = (
      <>
        Error OR no data! {error?.message} {errorPC?.message} {errorCP?.message}
      </>
    )
  // If payrollRoot is null (No payroll data in that yearMonth)
  else if (payrollRoot === null || dataPC.getPayrollConfig === null)
    payrollContent = <PayrollNoData date={date} createPayrollAndRefetch={createPayrollAndRefetch} />
  else
    payrollContent = (
      <PayrollFullMenu
        date={date}
        payrollRoot={payrollRoot}
        periodMap={periodMap}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        modalhistory={modalhistory}
        setModalHistory={setModalHistory}
        createPayrollAndRefetch={createPayrollAndRefetch}
        // Data Fetching for Tab TimeAttendance and EmployeePayroll
        payrollEmployeeQuery={payrollEmployeeQuery}
        payrollTimeAttendanceQuery={payrollTimeAttendanceQuery}
      />
    )

  return (
    <>
      <PayrollLayout date={date} setDate={setDate}>
        {payrollContent}
      </PayrollLayout>
      <ModalHistory modalhistory={modalhistory} setModalHistory={setModalHistory} />
    </>
  )
}

const PayrollFullMenu: React.FC<PayrollFullMenuProps> = ({
  payrollRoot,
  date,
  periodMap,
  selectedPeriod,
  setSelectedPeriod,
  modalhistory,
  setModalHistory,
  createPayrollAndRefetch,
  payrollEmployeeQuery,
  payrollTimeAttendanceQuery,
}: PayrollFullMenuProps) => {
  const [isShowMoney, setIsShowMoney] = useState(true)

  // Loading Payroll Detailed need to put in Employee!!
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const [employeeIDPopover, setEmployeeIDPopover] = useState<string>('')
  const [loadPayrollDetailed, { loading: loadingPayrollDetailed }] = useMutation(MUTATION_dowloadPayroll)
  const onDownloadPayrollDetailed = () => {
    if (!employeeIDPopover) {
      return Swal.fire({
        title: 'กรุณากรอกรหัสพนักงาน',
        text: 'ที่ต้องการดาวน์โหลดรายงาน',
      })
    }
    const variablesReport = {
      input: {
        documentType: 'PAYROLL_SALARY_DETAILED',
        startedAt: periodMap['ALL'].periodStart,
        endedAt: periodMap['ALL'].periodEnd,
        employeeID: [employeeIDPopover],
        departmentID: null,
        yearMonth: date.toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
        }),
      },
    }
    console.log(variablesReport, 'variablesReport')
    loadPayrollDetailed({ variables: variablesReport }).then((res) => {
      const downloadURL = res.data.downloadEXCELDocument
      downloadFile(downloadURL, downloadURL.split('/').pop())
      setAnchorEl(null)
    })
  }
  return (
    <>
      {/* Top 3 Card Selection */}
      <div className={styles.cardList}>
        {/* Left Card showing the Employee Count  & Maybe Card Success*/}
        <div className={styles.cardLeft}>
          <section className={styles.card}>
            <div className={styles.header}>
              <div className={styles.TextLeft}>
                <p className={styles.countEmp}>{payrollRoot.employeeCount || 0}</p>
                <p className={styles.text}>พนักงานทั้งหมด</p>
              </div>
              <div className={styles.TextRigth}>
                <span className={styles.text}>พนักงานเข้าใหม่ {payrollRoot.employeeHireCount || 0} คน</span>
                <span className={styles.text}>พนักงานลาออก {payrollRoot.employeeRetireCount || 0} คน</span>
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.contentText}>
              <span className={styles.text}>พนักงานรายเดือน</span>
              <span className={styles.text}> {payrollRoot.FULLTIME_MONTH || 0} คน</span>
              <span className={styles.text}>พนักงานรายวัน</span>
              <span className={styles.text}> {payrollRoot.FULLTIME_DAY || 0} คน</span>
              <span className={styles.text}>พนักงานพาร์ทไทม์</span>
              <span className={styles.text}> {payrollRoot.PARTTIME || 0} คน</span>
              <span className={styles.text}>พนักงานจ้างเหมา</span>
              <span className={styles.text}> {payrollRoot.OUTSOURCE || 0} คน</span>
            </div>
            <p className={styles.textfooter}>
              ณ เดือน
              {date.toLocaleString('th-TH', {
                month: 'long',
                year: 'numeric',
              })}
            </p>
          </section>

          {/* Umbrella ปิดงวดสำเร็จ */}
          {payrollRoot.status === 'PAID' && (
            <section className={styles.cardSuccess}>
              <div className={styles.area}>
                <p className={styles.textHead}>ปิดงวดสำเร็จ</p>
                <p className={styles.textSub}>
                  จำนวนเงินทั้งหมดที่จ่าย
                  {payrollRoot.netIncome
                    ?.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  บาท
                </p>
                <p className={styles.textSub}>จ่ายวันที่ 19 พฤษภาคม 2565</p>
              </div>
            </section>
          )}
        </div>
        {/* Right Card showing selecting the period */}
        <section className={styles.cardRight}>
          <div className={styles.card}>
            <div className={styles.header}>
              <div className={styles.dropdownPeriod}>
                <FormControl fullWidth size="small">
                  <Select
                    sx={{ borderRadius: 3, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
                    value={selectedPeriod}
                    onChange={(e) => {
                      setSelectedPeriod(e.target.value)
                    }}
                  >
                    {Object.keys(periodMap)
                      .map((period) => {
                        return {
                          label:
                            period === 'ALL'
                              ? 'รวมทั้งหมดทุกงวดที่มี'
                              : `งวด${
                                  period === '1.1'
                                    ? 'เต็ม'
                                    : periodNumberToText[period.split('.')[1]] +
                                      'เดือน' +
                                      date.toLocaleString('th-TH', {
                                        month: 'long',
                                      })
                                }`,
                          value: period,
                        }
                      })
                      .map((data, index) => (
                        <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
                          {data.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <img src={SettingIcon} alt="" />
            </div>
            <div className={styles.detaildate}>
              <div className={styles.date}>
                <p className={styles.dateText}>
                  {new Date(periodMap[selectedPeriod].periodStart).toLocaleString('th-TH', {
                    day: 'numeric',
                  })}
                </p>
                <p>
                  {new Date(periodMap[selectedPeriod].periodStart).toLocaleString('th-TH', {
                    month: 'long',
                  })}
                </p>
              </div>
              -
              <div className={styles.date}>
                <p className={styles.dateText}>
                  {new Date(periodMap[selectedPeriod].periodEnd).toLocaleString('th-TH', {
                    day: 'numeric',
                  })}
                </p>
                <p>
                  {new Date(periodMap[selectedPeriod].periodEnd).toLocaleString('th-TH', {
                    month: 'long',
                  })}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Bottom Section Showing Buttons*/}
      <div className={styles.bottom}>
        {/* History Button */}
        <section className={styles.history}>
          <img src={HistoryIcon} alt="" />
          <p className={styles.clickhere} onClick={() => setModalHistory(!modalhistory)}>
            ประวัติการแก้ไขข้อมูลคลิกที่นี่
          </p>
        </section>
        {/* 3 Button Row */}
        <section className={styles.buttonFooter}>
          <div className={styles.button}>
            <ButtonComponent
              _colorBG="red"
              _colorText="white"
              _text="สร้างรายการ"
              _variant="text"
              _type="button"
              _isIcon="add"
              _functionOnClick={createPayrollAndRefetch}
            />
          </div>

          <div className={styles.button}>
            <ButtonComponent
              _colorBG="white"
              _colorText="blue"
              _text="รีเซ็ตข้อมูลตั้งต้น"
              _variant="text"
              _type="button"
              _isIcon="reset"
              _functionOnClick={() => console.log('reset initial Data Click !!')}
            />
          </div>
          <div className={styles.button}>
            <ButtonComponent
              _colorBG="blue"
              _colorText="white"
              _text={isShowMoney ? 'ปิดข้อมูลเงินเดือน' : 'เปิดข้อมูลเงินเดือน'}
              _variant="text"
              _type="button"
              _isIcon="payroll"
              _functionOnClick={() => {
                console.log('close Income Click !!')
                setIsShowMoney(!isShowMoney)
              }}
            />
          </div>
          <div className={styles.button}>
            <ButtonComponent
              _colorBG="darkblue"
              _colorText="white"
              _text="Detailed"
              _variant="text"
              _type="button"
              _isIcon="file"
              _functionOnClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
            />
          </div>
        </section>
        {loadingPayrollDetailed && (
          <div className={styles.loadingCircle}>
            <CircularProgress />
          </div>
        )}
      </div>
      {/* Popover for downloading detailed data */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        // disableScrollLock={true}
      >
        <div className={styles.popoverWrapper}>
          <TextField
            required
            label="Employee ID"
            variant="filled"
            size="small"
            onChange={(e) => {
              setEmployeeIDPopover(e.target.value)
            }}
          />
          <button className={styles.downloadButton} onClick={onDownloadPayrollDetailed}>
            Download
          </button>
        </div>
      </Popover>

      {/* Bottom Tab Bar: (1) WorkList (2) OtherIncomeExpense (3) Summary Salary (4) Close Period */}
      <section className={styles.tabContainer}>
        <TabPayroll
          // payrollList={payrollRoot}
          period={selectedPeriod}
          isShowMoney={isShowMoney}
          date={date.toISOString().slice(0, 7)}
          // (1) WorkList
          // (2) OtherIncomeExpense (3) Summary Salary
          payrollEmployeeQuery={payrollEmployeeQuery}
          payrollTimeAttendanceQuery={payrollTimeAttendanceQuery}
          payrollRoot={payrollRoot}
        />
      </section>
    </>
  )
}

const PayrollNoData: React.FC<{ date: Date; createPayrollAndRefetch: () => void }> = ({
  date,
  createPayrollAndRefetch,
}: {
  date: Date
  createPayrollAndRefetch: () => void
}) => {
  return (
    <section className={styles.createList}>
      <img src={payIcon} alt="" />
      <section className={styles.groupText}>
        <p className={styles.text}>สร้างรายการคำนวณเงินเดือน</p>
        <p className={styles.text}>
          {date.toLocaleString('th-TH', {
            month: 'long',
            year: 'numeric',
          })}
        </p>
      </section>
      <div className={styles.line}></div>
      <section className={styles.btCrtList}>
        <ButtonComponent
          _colorBG="blue"
          _colorText="white"
          _text="สร้างรายการ"
          _variant="text"
          _type="button"
          _isIcon="add"
          _functionOnClick={createPayrollAndRefetch}
        />
      </section>
    </section>
  )
}

const PayrollLayout: React.FC<PayrollLayoutProps> = (props: PayrollLayoutProps) => {
  return (
    <div className={styles.container}>
      {/* Top Header */}
      <TopHeader date={props.date} setDate={props.setDate} />
      {/* Actual Content */}
      <div className={styles.content}>{props.children}</div>
    </div>
  )
}

const TopHeader: React.FC<TopHeaderProps> = (props: TopHeaderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <div className={styles.navtop}>
      <h1 className={styles.title}>คำนวณเงินเดือน</h1>
      <section className={styles.calendarMonth}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label="เลือกเดือน"
            openTo="month"
            views={['year', 'month']}
            value={props.date}
            onChange={(date) => {
              if (date) {
                props.setDate(date)
                setSearchParams({
                  yearMonth: date.toLocaleString('sv-SE', {
                    year: 'numeric',
                    month: '2-digit',
                  }),
                })
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </section>
    </div>
  )
}

export default Payroll

interface PayrollFullMenuProps {
  payrollRoot: PayrollRoot & Record<string, any>
  date: Date
  periodMap: PeriodElementMap
  selectedPeriod: string
  setSelectedPeriod: React.Dispatch<React.SetStateAction<string>>
  modalhistory: boolean
  setModalHistory: React.Dispatch<React.SetStateAction<boolean>>
  createPayrollAndRefetch: () => void

  // Data from usePaginationQuery
  payrollTimeAttendanceQuery: IUsePaginationQueryOutput<TimeAttendanceReportResponse>
  payrollEmployeeQuery: IUsePaginationQueryOutput<EmployeePayroll>
  // payrollTimeAttendanceQuery: IUsePaginationQueryOutput<TimeAttendanceReportResponse>
}

interface PayrollLayoutProps {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  children?: React.ReactNode
}

// A PeriodElement Map maps period --> periodStart-periodEnd
interface PeriodElementMap {
  [period: string]: PeriodElement
}

interface TopHeaderProps {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
}

// interface QueryListPayrollResponse {
//   listPayroll: PayrollQuery
// }

interface QueryPayrollConfigResponse {
  getPayrollConfig: PayrollConfig
}

const mutationCreatePayroll = gql`
  mutation CreatePayroll($yearMonth: String!) {
    createPayroll(yearMonth: $yearMonth)
  }
`

interface QueryListPayrollRootResponse {
  listPayrollRoot: string[]
}

// 4 Queries
// 1. Payroll Config
// 2. List PayrollRoot
// 3. List PayrollEmployee
// 4. List PayrollTimeAttendance

const queryPayrollConfig = gql`
  query GetPayrollConfig {
    getPayrollConfig {
      periodStart
      periodCount
      period1Day
      period2Day
      period3Day
      workingDayCount
      workingDayCountNewEmployee
      resetLeaveDay
      workingHourDivisor
      roundType
    }
  }
`

const queryListPayrollRoot = gql`
  query ListPayrollRoot($yearMonth: String!, $period: String) {
    listPayrollRoot(yearMonth: $yearMonth, period: $period)
  }
`

const queryListPayrollEmployee = gql`
  query ListPayrollEmployee($yearMonth: String!, $period: String, $nextToken: String, $limit: Int) {
    listPayrollEmployee(yearMonth: $yearMonth, period: $period, nextToken: $nextToken, limit: $limit) {
      items {
        employeeID
        workingMinute
        leaveMinute
        leaveDeductMinute
        otTypeWorkMinute
        otTypeOTMinute
        income
        otTypeWorkIncome
        otTypeOTIncome
        otherIncomeList {
          incomeOrExpenseID
          name
          incomeOrExpense
          incomeExpenseType
          variableOrFixed
          income40Type
          amount
        }
        welfareList {
          welfareID
          name
          income40Type
          amount
        }
        workDiligentIncome
        inEarlyIncome
        breakEarlyIncome
        outLateIncome
        netPositiveIncome
        otherExpenseList {
          incomeOrExpenseID
          name
          incomeOrExpense
          incomeExpenseType
          variableOrFixed
          income40Type
          amount
        }
        inLateExpense
        breakLateExpense
        outEarlyExpense
        netNegativeIncome
        netIncome
        daysInPeriod
        paySlipURL
        employeePeriod
        paymentID
        employee {
          id
          fullName
          middleName
          lastName
          department {
            departmentName
          }
        }
      }
      nextToken
    }
  }
`

const queryListPayrollTimeAttendance = gql`
  query ListPayrollTimeAttendance($yearMonth: String!, $period: String, $nextToken: String, $limit: Int) {
    listPayrollTimeAttendance(yearMonth: $yearMonth, period: $period, nextToken: $nextToken, limit: $limit) {
      items {
        items {
          companyID
          date
          employeeID
          createdAt
          updatedAt
          Type
          dayType
          isLink
          remark
          shiftName
          shiftStartTime
          shiftEndTime
          shiftVersion
          shiftMinute
          breakTimeList {
            startTime
            endTime
            startedAt
            endedAt
          }
          scanIn
          scanOut
          inDiffMinute
          breakDiffMinute
          outDiffMinute
          workingTimeList {
            startedAt
            endedAt
            workingMinute
          }
          workingMinute
          overtimeList {
            startedAt
            endedAt
            overtimeMinute
            overtimeType
            locationType
            status
            overtimeRequestID
            isCustomOvertimeMultiply
            overtimeMultiply
          }
          overtimeMinute
          overtimeApproveList {
            startedAt
            endedAt
            overtimeMinute
            overtimeType
            locationType
            status
            overtimeRequestID
            isCustomOvertimeMultiply
            overtimeMultiply
          }
          overtimeApproveMinute
          leaveList {
            startedAt
            endedAt
            leaveMinute
            leaveType
            leaveForm
            leaveDocumentAttachment
            leaveRequestID
            status
            leaveConfig {
              id
              name
            }
          }
          leaveMinute
          leaveDeductList {
            startedAt
            endedAt
            leaveMinute
            leaveType
            leaveForm
            leaveDocumentAttachment
            leaveRequestID
            status
            leaveConfig {
              id
              name
            }
          }
          leaveDeductMinute
        }
        employeeID
        employee {
          id
          fullName
          middleName
          lastName
          department {
            departmentName
          }
        }
      }
      nextToken
    }
  }
`

const MUTATION_dowloadPayroll = gql`
  mutation _5_Payroll($input: DownloadOptional!) {
    downloadEXCELDocument(input: $input)
  }
`
