import { Stack, useTheme, StackProps } from '@mui/material'
import React from 'react'

interface Props extends StackProps {
  children: React.ReactNode
  padding?: string | number
  minHeight?: string | number
}
const ContentContainer: React.FC<Props> = ({ padding, minHeight, children, ...stackProps }) => {
  const theme = useTheme()
  return (
    <>
      <Stack
        sx={{
          backgroundColor: theme.colors.grayBg,
          padding: padding ?? '2rem 4rem',
          minHeight: minHeight || '100vh',
        }}
        {...stackProps}
      >
        {children}
      </Stack>
    </>
  )
}

export default ContentContainer
