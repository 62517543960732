import { Formik } from 'formik'
import React, { useState } from 'react'
import { ButtonComponent, InputTextComponentCustom } from '../../component'
import styles from './index.module.scss'
import ModalView from './modal'
// import { LabTabs } from './tab'
const ForgotPassword: React.FC = (): JSX.Element => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.container}>
      <div className={styles.navtop}>
        <h1 className={styles.title}>Change Password</h1>
      </div>
      <div className={styles.card_one}>
        <Formik
          initialValues={{
            empid: '',
            username: '',
            password: '',
            send: false,
          }}
          // validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2))
              actions.setSubmitting(false)
            }, 1000)
          }}
        >
          {(props: any) => (
            <form onSubmit={props.handleSubmit} className={styles.GroupArea}>
              <div className={styles.GroupCard}>
                <p className={styles.title}>เปลี่ยนรหัสผ่าน</p>
                <p>กรุณาอย่าเปิดเผยรหัสผ่านให้ผู้อื่นรู้ เพื่อความปลอดภัยของบัญชีผู้ใช้เอง </p>
              </div>
              <section className={styles.Group}>
                <div className={styles.GroupInput}>
                  <section className={styles.GridGroup}>
                    <p>รหัสผ่านปัจจุบัน</p>
                    <InputTextComponentCustom _name="currenpassword" />
                  </section>
                  <p className={styles.textLink} onClick={() => setOpen(true)}>
                    ลืมรหัสผ่าน ?
                  </p>
                  <section className={styles.GridGroup}>
                    <p>รหัสผ่านใหม่</p>
                    <InputTextComponentCustom _name="currenpassword" />
                  </section>
                  <section className={styles.GridGroup}>
                    <p>ยืนยันรหัสผ่าน</p>
                    <InputTextComponentCustom _name="currenpassword" />
                  </section>
                </div>
              </section>

              <div className={styles.button}>
                <ButtonComponent
                  _colorBG="blue"
                  _colorText="white"
                  _text="ยืนยัน"
                  _variant="contained"
                  _type="button"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
      <ModalView open={open} setOpen={setOpen} />
    </div>
  )
}
export default ForgotPassword
