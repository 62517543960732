import React, { FC, useCallback, useEffect, useState } from 'react'
import { classes, handleUploadFile } from '../../utils/common'
import styles from './index.module.scss'
import FileIcon from '../../image/uploadfile.svg'
import FileUpload from '../../image/upload.svg'
import DeleteIcon from '../../image/delete.svg'
import Swal from 'sweetalert2'
import { useFormikContext } from 'formik'
interface Props {
  name: any
  id?: any
  flodername: string
  valueShow: any
  label?: string
  disabled?: boolean
}

const UploadFileComponent: FC<Props> = (props: Props) => {
  const { values, setFieldValue } = useFormikContext<any>()

  const GetFileName = (filename: any) => {
    const lenFile = filename.split('/')
    return filename.split('/')[lenFile.length - 1]
  }

  return (
    <div className={props.disabled ? styles.UploadFiledisabled : styles.UploadFile}>
      <input
        id={`${props.name}`}
        type="file"
        // accept=".pdf .jpeg .png"
        accept="image/jpeg,image/gif,image/png,application/pdf"
        onChange={(e) => {
          console.log(e, e)
          Promise.all(handleUploadFile(e.target.files, `${props.flodername}/${props.id}/file/`)).then((data: any) => {
            setFieldValue(`${props.name}`, data[0])
          })
        }}
        style={{ display: 'none' }}
      />

      <p className="margin-Bottom15px">
        <label className={styles.labelfile} htmlFor={`${props.name}`}>
          {/* <span className={styles.primaryuploadspan}>
            <img className={styles.filelogo} src={FileIconWhite} alt="filelogo" />
          </span> */}
          <span className={styles.secondaryuploadspan}>
            {props.valueShow ? (
              <label className={styles.labelshowfile} htmlFor={`${props.name}`}>
                <p className={styles.fileName}>{GetFileName(props.valueShow)}</p>
                <img className={styles.filelogo} src={FileIcon} alt="filelogo" />
              </label>
            ) : (
              <label className={styles.labelshowfile} htmlFor={`${props.name}`}>
                <p className={styles.fileName}>{GetFileName(props.label)}</p>
                <img className={styles.filelogo} src={FileUpload} alt="filelogo" />
              </label>
            )}
          </span>
        </label>
      </p>
      {props.valueShow ? (
        <img
          src={DeleteIcon}
          className={styles.Icon}
          alt=""
          onClick={() => {
            Swal.fire({
              title: 'ลบไฟล์เอกสาร',
              icon: 'error',
              showCancelButton: true,
              cancelButtonText: 'ยกเลิก',
              confirmButtonColor: '#FF6161',
              confirmButtonText: 'ตกลง',
              reverseButtons: true,
            }).then((del) => {
              if (del.isConfirmed) {
                setFieldValue(`${props.name}`, '')
              }
            })
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default UploadFileComponent
