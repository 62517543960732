import React, { Fragment, useMemo, useState } from 'react'
// import { makeStyles } from '@mui/material'

import styles from './index.module.scss'
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
  CircularProgress,
  SxProps,
  Box,
  Avatar,
  makeStyles,
  useFormControl,
  useTheme,
  FormGroup,
} from '@mui/material'
import { VisibilityOff, Visibility, Search } from '@mui/icons-material'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CloseIcon from '@mui/icons-material/Close'
import { FastField, FastFieldProps, Field, FieldProps, getIn, useField, useFormikContext } from 'formik'
import { handleDecimalsOnValue } from 'src/utils/common'
import _ from 'lodash'
// import { resolvePath } from 'react-router-dom'
//Image
// import CloseIcon from '../../image/close.svg'

interface PropsInputText {
  _name: string
  _label?: string
  _helperText?: string
  _descriptionText?: string
  _unit?: string
  _disabled?: boolean
  _value?: any
  _onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  fastField?: boolean
  _type?: string
}

export const InputTextAreaComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, meta }: FastFieldProps) => (
        <TextField
          InputLabelProps={{
            style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
          }}
          InputProps={{
            style: {
              fontFamily: 'KanitRegular',
              color: 'gray',
              borderRadius: 7,
              backgroundColor: 'white',
            },
          }}
          rows={4}
          multiline
          fullWidth
          size="small"
          id={props._name}
          label={props._label}
          name={props._name}
          value={meta.value ?? ''}
          onChange={field.onChange}
          onBlur={field.onBlur}
          helperText={meta.touched && meta.error && props._helperText}
          error={meta.touched && Boolean(meta.error)}
        />
      )}
    </FieldWrapper>
  )
}

export const InputTextComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, meta, form }: FastFieldProps) => (
        <>
          <TextField
            InputLabelProps={{
              shrink: true,
              style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
            }}
            InputProps={{
              style: {
                color: 'gray',
                borderRadius: 10,
                backgroundColor: 'white',
                fontFamily: 'KanitRegular',
              },
            }}
            fullWidth
            size="small"
            placeholder={props._label}
            id={props._name}
            label={props._label}
            name={props._name}
            value={(meta.value || meta.initialValue) ?? ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            helperText={meta.touched && meta.error && props._helperText}
            error={meta.touched && Boolean(meta.error)}
            disabled={props._disabled}
          />
        </>
      )}
    </FieldWrapper>
  )
}
export const InputTextComponentNoFormik: React.FC<PropsInputTextNoFormik> = (
  props: PropsInputTextNoFormik,
): JSX.Element => {
  const [touched, setTouched] = useState(true)
  const errorText = useMemo(() => {
    const validate = (v: string) => {
      if (props._validator && props._validator.test(v)) return ''
      else return props._errorMsg!
    }
    return validate(props._value)
  }, [props._value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) setTouched(true)
    props._setValue(e.target.value)
  }

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
      }}
      InputProps={{
        style: {
          color: 'gray',
          borderRadius: 10,
          backgroundColor: 'white',
          fontFamily: 'KanitRegular',
        },
      }}
      fullWidth
      size="small"
      placeholder={props._label}
      id={props._name}
      sx={{ borderRadius: 7 }}
      label={props._label}
      name={props._name}
      value={props._value}
      onChange={handleChange}
      helperText={touched && errorText}
      error={touched && Boolean(errorText)}
    />
  )
}
interface PropsInputTextNoFormik {
  _name: string
  _label: string
  _value: string
  _setValue: (v: string) => void
  _validator?: RegExp
  _errorMsg?: string
  _disabled?: boolean
  _sx?: any
}

interface PropsInputTextCustom {
  _name: string
  _label?: string
  _helperText?: string
  _descriptionText?: string
  _value?: any
  _sx?: any
  _disabled?: any
  _styles?: any
}
export const InputTextComponentCustom: React.FC<PropsInputTextCustom> = (props: PropsInputTextCustom): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: 'SFThonburiRegular', backgroundColor: 'white' },
      }}
      InputProps={{
        style: {
          color: 'gray',
          borderRadius: 7,
          backgroundColor: 'white',
          fontFamily: 'SFThonburiRegular',
        },
      }}
      // sx={{ borderRadius: 7 }}
      disabled={props._disabled}
      fullWidth
      size="small"
      placeholder={props._label}
      id={props._name}
      label={props._label}
      name={props._name}
      value={getIn(values, props._name)}
      onChange={handleChange}
      helperText={<span>{getIn(touched, props._name) && getIn(errors, props._name) && props._helperText}</span>}
      error={getIn(touched, props._name) && Boolean(getIn(errors, props._name))}
    />
  )
}

export const InputTextIconComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, meta }: FastFieldProps) => (
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white' }}
            error={meta.touched && Boolean(meta.error)}
          >
            {props._label}
          </InputLabel>
          <OutlinedInput
            sx={{ borderRadius: 3, backgroundColor: 'white', fontFamily: 'KanitRegular' }}
            id={props._name}
            label={props._label}
            name={props._name}
            type="text"
            value={meta.value}
            onChange={(e) => {
              field.onChange(e)
              props._onChange?.(e)
            }}
            disabled={props._disabled}
            error={meta.touched && Boolean(meta.error)}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
          />
          <p className={styles.errorMsg}>{meta.touched && meta.error && props._helperText}</p>
        </FormControl>
      )}
    </FieldWrapper>
  )
}

export const InputTextUnitComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel
        shrink
        htmlFor="outlined-adornment-text"
        sx={{ backgroundColor: 'white', padding: '0 5px', fontFamily: 'KanitRegular' }}
        error={touched[props._name] && Boolean(errors[props._name])}
      >
        {props._label}
      </InputLabel>
      <OutlinedInput
        placeholder={props._label}
        sx={{ borderRadius: 3, backgroundColor: 'white', fontFamily: 'KanitRegular' }}
        id={props._name}
        label={props._label}
        name={props._name}
        type="text"
        value={values[props._name]}
        onChange={handleChange}
        disabled={props._disabled}
        error={touched[props._name] && Boolean(errors[props._name])}
        endAdornment={
          <InputAdornment position="end">
            <span>{props._unit}</span>
          </InputAdornment>
        }
      />
      {!touched[props._name] && !Boolean(errors[props._name]) ? (
        <p className={styles.msg}>{props._descriptionText}</p>
      ) : (
        <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
      )}
    </FormControl>
  )
}

type SelectMenuProps = { label: any; value: any }

export const InputNumberUnitComponent: React.FC<
  PropsInputText & { selectUnit?: (menuList: SelectMenuProps[]) => React.ReactNode; selectMenuList?: SelectMenuProps[] }
> = (props): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, meta }: FastFieldProps) => (
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel
            shrink
            htmlFor="outlined-adornment-text"
            sx={{ backgroundColor: 'white', padding: '0 5px', fontFamily: 'SFThonburiRegular' }}
            error={meta.touched && Boolean(meta.error)}
          >
            {props._label}
          </InputLabel>
          <OutlinedInput
            placeholder={props._label}
            sx={{
              borderRadius: 1,
              backgroundColor: 'white',
              fontFamily: 'SFThonburiRegular',
              pr: props.selectUnit ? 0 : '1rem',
            }}
            id={props._name}
            label={props._label}
            name={props._name}
            type="number"
            value={meta.value ?? ''}
            onChange={field.onChange}
            disabled={props._disabled}
            error={meta.touched && Boolean(meta.error)}
            endAdornment={
              <InputAdornment position="end">
                {props.selectUnit ? props.selectUnit(props.selectMenuList || []) : <span>{props._unit}</span>}
              </InputAdornment>
            }
          />
          {!meta.touched && !Boolean(meta.error) ? (
            <p className={styles.msg}>{props._descriptionText}</p>
          ) : (
            <p className={styles.errorMsg}>{meta.touched && meta.error && props._helperText}</p>
          )}
        </FormControl>
      )}
    </FieldWrapper>
  )
}
export const InputPasswordComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white' }}
        error={touched[props._name] && Boolean(errors[props._name])}
      >
        {props._label}
      </InputLabel>
      <OutlinedInput
        sx={{ borderRadius: 3, backgroundColor: 'white' }}
        id={props._name}
        label={props._label}
        name={props._name}
        type={showPassword ? 'text' : 'password'}
        value={values[props._name]}
        onChange={handleChange}
        error={touched[props._name] && Boolean(errors[props._name])}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
    </FormControl>
  )
}

export const InputNumberComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, meta }: FastFieldProps) => (
        <TextField
          InputLabelProps={{
            shrink: true,
            style: { fontFamily: 'KanitRegular' },
          }}
          InputProps={{
            style: {
              fontFamily: 'KanitRegular',
              color: 'gray',
              borderRadius: 3,
            },
          }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          onKeyPress={(event: any) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
            if (event.target.value === '0' && event.key === '0') {
              event.preventDefault()
            }
          }}
          placeholder={props._label}
          fullWidth
          size="small"
          type="number"
          id={field.name}
          label={props._label}
          name={field.name}
          value={meta.value ?? ''}
          onChange={field.onChange}
          helperText={meta.touched && meta.error && props._helperText}
          error={meta.touched && Boolean(meta.error)}
        />
      )}
    </FieldWrapper>
  )
}

export const InputNumberComponentCustom: React.FC<PropsInputTextCustom> = (
  props: PropsInputTextCustom,
): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: 'KanitRegular' },
      }}
      InputProps={{
        style: {
          fontFamily: 'KanitRegular',
          color: 'gray',
          borderRadius: 4,
          backgroundColor: '#FFFFFF',
          // height: '30px',
        },
      }}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      onKeyDown={(event: any) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') event.preventDefault()
      }}
      onKeyPress={(event: any) => {
        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
          event.preventDefault()
        }
        if (event.target.value === '0' && event.key === '0') {
          event.preventDefault()
        }
      }}
      placeholder={props._label}
      fullWidth
      size="small"
      type="number"
      sx={props._sx}
      id={props._name}
      label={props._label}
      name={props._name}
      value={props._value}
      disabled={props._disabled}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
    />
  )
}

export const InputNumberComponentCustomTran: React.FC<PropsInputTextCustom> = (
  props: PropsInputTextCustom,
): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      variant="standard"
      InputLabelProps={{
        shrink: true,
        style: { fontFamily: 'SFThonburiRegular', border: 'none' },
      }}
      InputProps={{
        style: {
          fontFamily: 'SFThonburiRegular',
          color: 'black',
          borderRadius: 7,
          border: 'none',
          fontSize: 18,
          fontWeight: 'bold',
        },
      }}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      onKeyDown={(event: any) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') event.preventDefault()
      }}
      onKeyPress={(event: any) => {
        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
          event.preventDefault()
        }
        if (event.target.value === '0' && event.key === '0') {
          event.preventDefault()
        }
      }}
      placeholder={props._label}
      fullWidth
      size="small"
      type="number"
      sx={props._sx}
      id={props._name}
      label={props._label}
      name={props._name}
      value={props._value}
      disabled={props._disabled}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
    />
  )
}

export const InputDateComponent: React.FC<PropsInputText & { maskValue?: React.ReactNode }> = (props): JSX.Element => {
  const [{ value, onChange }, { touched, error }] = useField(props._name)

  return (
    <Box sx={{ position: 'relative', isolation: 'isolate' }}>
      {props.maskValue && (
        <Box
          sx={{
            zIndex: 10,
            position: 'absolute',
            top: '50%',
            left: '0.75rem',
            transform: 'translate(0, -50%)',
            height: 'calc(100% - 0.75rem)',
            width: 'calc(100% - 4rem)',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {props.maskValue}
        </Box>
      )}
      <TextField
        InputProps={{
          style: {
            fontFamily: 'SFThonburiRegular',
            color: 'gray',
            borderRadius: 4,
            backgroundColor: 'white',
          },
        }}
        fullWidth
        size="small"
        type="date"
        disabled={props._disabled}
        id={props._name}
        label={<span>{props._label}</span>}
        name={props._name}
        value={value}
        onChange={onChange}
        helperText={<p>{touched && error && props._helperText}</p>}
        error={touched && Boolean(error)}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: 16, fontFamily: 'SFThonburiRegular' },
        }}
      />
    </Box>
  )
}

export const InputDateComponentNoFormik: React.FC<PropsInputDateNoFormik> = (props: PropsInputDateNoFormik) => {
  const [touched, setTouched] = useState(true)

  const errorText = useMemo(() => {
    const validate = (v: string) => {
      console.log(v, 'v')
      if (props._validator && props._validator.test(v)) return ''
      else return props._errorMsg!
    }
    return validate(props._value)
  }, [props._value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) setTouched(true)
    props._setValue(e.target.value)
  }

  return (
    <TextField
      InputProps={{
        style: {
          fontFamily: 'KanitRegular',
          color: 'gray',
          borderRadius: 10,
        },
      }}
      fullWidth
      size="small"
      type="date"
      id={props._name}
      label={<span>{props._label}</span>}
      name={props._name}
      value={props._value}
      onChange={handleChange}
      helperText={<p>{touched && errorText}</p>}
      error={touched && Boolean(errorText)}
      InputLabelProps={{
        shrink: true,
        style: { fontSize: 16, fontFamily: 'KanitRegular' },
      }}
    />
  )
}

interface PropsInputDateNoFormik {
  _name: string
  _label: string
  _value: string
  _setValue: (v: string) => void
  _validator: RegExp
  _errorMsg: string
  _disabled?: boolean
}

export const InputDateComponentCustom: React.FC<PropsInputText & { maskValue?: React.ReactNode }> = (
  props,
): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <Box sx={{ position: 'relative', isolation: 'isolate' }}>
      {props.maskValue && (
        <Box
          sx={{
            zIndex: 10,
            position: 'absolute',
            top: '50%',
            left: '0.75rem',
            transform: 'translate(0, -50%)',
            height: 'calc(100% - 0.75rem)',
            width: 'calc(100% - 4rem)',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {props.maskValue}
        </Box>
      )}
      <TextField
        InputProps={{
          style: {
            fontFamily: 'SFThonburiRegular',
            color: 'gray',
            borderRadius: 4,
            backgroundColor: 'white',
          },
        }}
        fullWidth
        size="small"
        // type={props._type ? props._type : 'date'}
        type={props._type ? props._type : 'date'}
        disabled={props._disabled}
        id={props._name}
        label={<span>{props._label}</span>}
        name={props._name}
        value={props._value}
        onChange={props._onChange || handleChange}
        helperText={touched[props._name] && errors[props._name] && props._helperText}
        error={touched[props._name] && Boolean(errors[props._name])}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: 16, fontFamily: 'SFThonburiRegular' },
        }}
      />
    </Box>
  )
}

interface PropsInputDateCustom {
  _name: string
  _label?: string
  _helperText?: string
  _descriptionText?: string
  _value?: any
  _disabled?: boolean
  _onChange?: any
  date?: any
  _type?: 'date' | 'month' | 'year'
}
export const InputDateComponentCustome: React.FC<PropsInputDateCustom> = (props: PropsInputDateCustom): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      InputProps={{
        style: {
          fontFamily: 'SFThonburiRegular',
          color: 'gray',
          borderRadius: 4,
        },
      }}
      fullWidth
      size="small"
      type={props._type ? props._type : 'date'}
      id={props._name}
      label={<span>{props._label}</span>}
      name={props._name}
      value={props._value}
      disabled={props._disabled}
      onChange={handleChange}
      defaultValue={props.date}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
      InputLabelProps={{
        shrink: true,
        style: { fontSize: 16, fontFamily: 'SFThonburiRegular' },
      }}
    />
  )
}

export const InputDateTimeComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      fullWidth
      size="small"
      type="datetime-local"
      id={props._name}
      label={<span>{props._label}</span>}
      name={props._name}
      value={values[props._name]}
      onChange={handleChange}
      helperText={touched[props._name] && errors[props._name] && props._helperText}
      error={touched[props._name] && Boolean(errors[props._name])}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        style: {
          borderRadius: 7,
          fontFamily: 'KanitRegular',
        },
      }}
    />
  )
}
export const InputTimeComponent: React.FC<PropsInputText & { fastField?: boolean }> = (props): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, meta }: FastFieldProps) => (
        <TextField
          fullWidth
          size="small"
          type="time"
          id={props._name}
          label={<span>{props._label}</span>}
          name={props._name}
          value={meta.value || meta.initialValue || ''}
          onChange={field.onChange}
          helperText={meta.touched && meta.error && props._helperText}
          error={meta.touched && Boolean(meta.error)}
          InputLabelProps={{
            shrink: true,
            style: {
              fontFamily: 'KanitRegular',
              color: 'gray',
              borderRadius: 7,
              backgroundColor: 'white',
            },
          }}
          InputProps={{
            style: {
              borderRadius: 7,
              backgroundColor: 'white',
            },
          }}
        />
      )}
    </FieldWrapper>
  )
}

interface PropsInputTimeCustom {
  _name: string
  _label: string
  _helperText?: string
  _value?: any
  _disabled?: boolean
  _default?: any
}
export const InputTimeComponentCustom: React.FC<PropsInputTimeCustom> = (props: PropsInputTimeCustom): JSX.Element => {
  const { touched, errors, handleChange } = useFormikContext<any>()

  return (
    <TextField
      fullWidth
      size="small"
      disabled={props._disabled}
      type="time"
      id={props._name}
      defaultValue={props._default}
      label={<span>{props._label}</span>}
      name={props._name}
      value={props._value}
      onChange={handleChange}
      helperText={getIn(touched, props._name) && getIn(errors, props._name) && props._helperText}
      error={getIn(touched, props._name) && Boolean(getIn(errors, props._name))}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        style: {
          borderRadius: 4,
          fontFamily: 'SFThonburiRegular',
        },
      }}
    />
  )
}

interface PropsInputSelectCustom {
  _name: string
  _label: string
  _helperText?: string
  _value?: any
  _menuList?: any
  _text?: any
  _placeholder?: string
  _disabled?: boolean
  _bg?: string
}
export const InputSelectComponentCustom: React.FC<PropsInputSelectCustom> = (
  props: PropsInputSelectCustom,
): JSX.Element => {
  const { touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl fullWidth size="small">
      <InputLabel
        shrink
        id={props._name}
        sx={{ fontFamily: 'SFThonburiRegular', backgroundColor: 'white', padding: '0px 5px' }}
      >
        {props._label}
      </InputLabel>
      <Select
        // defaultValue={props._name}
        sx={{ borderRadius: 1, fontFamily: 'SFThonburiRegular', backgroundColor: 'white' }}
        labelId={props._name}
        id={props._name}
        placeholder={props._placeholder}
        value={props._value}
        label={props._label}
        onChange={handleChange}
        name={props._name}
        error={getIn(touched, props._name) && Boolean(getIn(errors, props._name))}
        disabled={props._disabled}
      >
        {props._menuList?.map((data: any, index: number) => (
          <MenuItem key={index} value={data.value} sx={{ fontFamily: 'SFThonburiRegular' }}>
            {data.label}
          </MenuItem>
        ))}
      </Select>

      <p className={styles.errorMsg}>
        {getIn(touched, props._name) && getIn(errors, props._name) && getIn(errors, props._name)}
      </p>
    </FormControl>
  )
}

export const InputSelectComponentAddress: React.FC<PropsInputSelectCustom> = (
  props: PropsInputSelectCustom,
): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl fullWidth size="small">
      <InputLabel
        shrink
        id={props._name}
        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white', padding: '0px 5px' }}
      >
        {props._label}
      </InputLabel>
      <Select
        defaultValue={props._name}
        sx={{ borderRadius: 3, fontFamily: 'KanitRegular', backgroundColor: 'white' }}
        labelId={props._name}
        id={props._name}
        placeholder={props._placeholder}
        value={props._value}
        label={props._label}
        onChange={handleChange}
        name={props._name}
        error={touched[props._name] && Boolean(errors[props._name])}
        disabled={props._disabled}
      >
        {props._menuList.map((data: any, index: number) => (
          <MenuItem key={index} value={data.province} sx={{ fontFamily: 'KanitRegular' }}>
            {data.province}
          </MenuItem>
        ))}
      </Select>

      {/* <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p> */}
      <p className={styles.errorMsg}>
        {getIn(touched, props._name) && getIn(errors, props._name) && props._helperText}
      </p>
    </FormControl>
  )
}

interface PropsInputSelect {
  _name: string
  _label?: string
  _helperText?: string
  _menuList: any[]
  _style?: any
  fastField?: boolean
}

/**
 * _menuList: [ { label,  value } ]
 */
export const InputSelectComponent: React.FC<PropsInputSelect> = (props: PropsInputSelect): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, meta }: FastFieldProps) => (
        <FormControl fullWidth size="small" style={props._style}>
          <InputLabel
            shrink
            id={props._name}
            sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white', padding: '0px 5px' }}
          >
            {props._label}
          </InputLabel>
          <Select
            sx={{ borderRadius: '7px', fontFamily: 'KanitRegular', backgroundColor: 'white' }}
            labelId={props._name}
            id={props._name}
            value={(meta.value || meta.initialValue) ?? ''}
            label={props._label}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={props._name}
            error={meta.touched && Boolean(meta.error)}
            MenuProps={{ PaperProps: { sx: { maxHeight: 'min(20rem, 60vh)' } } }}
          >
            {props._menuList.map((data: any, index: number) => (
              <MenuItem key={index} value={data.value} sx={{ fontFamily: 'KanitRegular' }}>
                {data.label}
              </MenuItem>
            ))}
          </Select>

          <p className={styles.errorMsg}>{meta.touched && meta.error && props._helperText}</p>
        </FormControl>
      )}
    </FieldWrapper>
  )
}

export const InputSwitchComponent: React.FC<PropsInputText> = (props: PropsInputText): JSX.Element => {
  const { values, handleChange } = useFormikContext<any>()

  return (
    <FormControlLabel
      control={
        <Switch
          id={props._name}
          name={props._name}
          value={values[props._name]}
          onChange={handleChange}
          defaultChecked={values[props._name]}
        />
      }
      label={props._label}
    />
  )
}

interface PropsInputCheckbox {
  _label?: any
  _name: string
  _style?: any
  _labelStyle?: any
  fastField?: boolean
}

export const InputCheckboxComponent: React.FC<PropsInputCheckbox> = (props: PropsInputCheckbox): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, meta }: FastFieldProps) => (
        <FormControlLabel
          style={props._style}
          control={
            <Checkbox
              id={props._name}
              name={props._name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              checked={meta.value}
              // defaultChecked={getIn(values, props._name)}
              sx={{
                color: '#5357BB',
                fontFamily: 'KanitRegular',

                '&.Mui-checked': {
                  color: '#5357BB',
                },
              }}
            />
          }
          label={<span style={props._labelStyle}>{props._label}</span>}
        />
      )}
    </FieldWrapper>
  )
}

export const InputCheckboxComponentToggle: React.FC<PropsInputCheckbox> = (props): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, form }: FastFieldProps) => (
        <InputCheckboxComponentCustom
          _label={props._label}
          _onChange={(_, checked) => form.setFieldValue(field.name, checked)}
          _value={field.value}
        />
      )}
    </FieldWrapper>
  )
}

interface PropsInputCheckboxCustom {
  _label?: any
  _name?: string
  _style?: any
  _value?: any
  _onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const InputCheckboxComponentCustom: React.FC<PropsInputCheckboxCustom> = (
  props: PropsInputCheckboxCustom,
): JSX.Element => {
  const { values, handleChange } = useFormikContext<any>()

  return (
    <FormControlLabel
      style={props._style}
      control={
        <Checkbox
          id={props._name}
          name={props._name}
          onChange={props._onChange || handleChange}
          // value={true}
          value={props._value ?? false}
          checked={props._value ?? false}
          // defaultChecked={props._value}
          sx={{
            color: '#5357BB',

            '&.Mui-checked': {
              color: '#5357BB',
            },
          }}
        />
      }
      label={<span>{props._label}</span>}
    />
  )
}

interface PropsInputRadio {
  _name: string
  _menuList: any[]
  _helperText?: string
  _row?: boolean
  _disabled?: boolean
  _sx?: SxProps
  fastField?: boolean
  size?: 'small' | 'medium'
}
interface PropsInputCheckbox {
  _label?: any
  _name: string
  _style?: any
}
/**
 * _menuList = [ { value: "", label: "" } ]
 */
export const InputRadioComponent: React.FC<PropsInputRadio> = (props: PropsInputRadio): JSX.Element => {
  const context = React.useContext(FastFieldContext)

  const FieldWrapper = React.useMemo(() => {
    if ((props.fastField === true || props.fastField === undefined) && context.enable) return FastField
    return Field
  }, [context.enable])

  return (
    <FieldWrapper name={props._name}>
      {({ field, meta }: FastFieldProps) => (
        <FormControl component="fieldset">
          <RadioGroup
            row={props._row}
            aria-label={props._name}
            id={props._name}
            name={props._name}
            // value={values[props._name]}
            value={(meta.value || meta.initialValue) ?? ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            style={{ display: 'flex' }}
          >
            {props._menuList.map((data: any, index: number) => (
              <FormControlLabel
                key={index}
                value={data.value}
                control={
                  <Radio
                    sx={{
                      color: '#5357BB',
                      '&.Mui-checked': {
                        color: '#5357BB',
                      },
                      ...props._sx,
                    }}
                    disabled={props._disabled}
                    size={props.size}
                  />
                }
                label={<span>{data.label}</span>}
              />
            ))}
          </RadioGroup>
          {/* <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p> */}
          <p className={styles.errorMsg}>{meta.touched && meta.error && props._helperText}</p>
        </FormControl>
      )}
    </FieldWrapper>
  )
}

interface PropsInputend {
  _name: string
  _label: string
  _helperText?: string
  _labelend: string
}

export const InputendComponent: React.FC<PropsInputend> = (props: PropsInputend): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{ fontFamily: 'KanitRegular', backgroundColor: 'white' }}
        error={touched[props._name] && Boolean(errors[props._name])}
      >
        {props._label}
      </InputLabel>
      <OutlinedInput
        sx={{ borderRadius: 3, backgroundColor: 'white' }}
        id={props._name}
        label={props._label}
        name={props._name}
        value={values[props._name]}
        onChange={handleChange}
        error={touched[props._name] && Boolean(errors[props._name])}
        endAdornment={<InputAdornment position="end">{props._labelend}</InputAdornment>}
      />
      <p className={styles.errorMsg}>{touched[props._name] && errors[props._name] && props._helperText}</p>
    </FormControl>
  )
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
interface PropsAutocomplete {
  _name: string
  _label?: string
  _option: AutoCompleteOption[]
  _placeholder?: string
  _style?: React.CSSProperties
  _disabled?: boolean
  _limitTags?: number
}

interface AutoCompleteOption {
  title: string
  value: string
}

//todo > Custom
/**
 * _option = [ { title: '', value: ''  } ]
 *
 */
export const AutocompleteComponent: React.FC<PropsAutocomplete> = (props: PropsAutocomplete): JSX.Element => {
  // const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<any>()
  const [field, , { setValue }] = useField<string[]>(props._name)

  const optionMap = React.useMemo<Record<string, AutoCompleteOption>>(() => {
    return props._option.reduce<Record<string, AutoCompleteOption>>((acc, curr) => {
      if (!acc[curr.value]) acc[curr.value] = curr
      return acc
    }, {})
  }, [field.value, props._option.length])

  return (
    <Autocomplete
      multiple
      id={props._name}
      options={props._option}
      disabled={props._disabled}
      limitTags={props._limitTags}
      value={field.value?.map((e) => optionMap[e]) || []}
      style={{ ...props._style, width: '100%', borderRadius: '7px' }}
      renderTags={(values, getTagProps) =>
        values?.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            variant="outlined"
            label={value?.title || <CircularProgress />}
            key={index}
          />
        ))
      }
      onChange={(event, value, reason, details) => {
        // console.log('details select', details)
        // console.log('option select', details?.option.title)
        // console.log('option select', details?.option.value)
        // console.log('reason', reason, 'details', details)
        if (reason === 'selectOption') {
          // Add
          const selectValue = details?.option.value as string
          setValue([...(field.value || []), selectValue], false)
        } else if (reason === 'removeOption') {
          // Remove
          const filtered = field.value.filter((e) => e !== details?.option.value)
          setValue(filtered, false)
        } else if (reason === 'clear') {
          // Clear
          setValue([], false)
        }
      }}
      disableCloseOnSelect
      getOptionLabel={(_option: AutoCompleteOption) => _option.value}
      renderOption={(props, _option: AutoCompleteOption, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            sx={{
              color: '#5357BB',
              '&.Mui-checked': {
                color: '#5357BB',
              },
            }}
            checked={selected}
          />
          {/* <Avatar /> */}
          {_option.title}
        </li>
      )}
      renderInput={({ InputLabelProps, InputProps, ...params }) => (
        <TextField
          InputLabelProps={{
            shrink: true,
            style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
            ...InputLabelProps,
          }}
          InputProps={{ style: { height: '100%', padding: 0 }, ...InputProps }}
          {...params}
          label={props._label && <span>{props._label}</span>}
          placeholder={props._placeholder}
        />
      )}
    />
  )
}

interface PropsAutocompleteCustom {
  _name: string
  _label: string
  _option: any[]
  _placeholder?: string
  _style?: any
  _value?: any
  _multiple?: boolean
}
export const AutocompleteComponentCustom: React.FC<PropsAutocompleteCustom> = (
  props: PropsAutocompleteCustom,
): JSX.Element => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<any>()
  return (
    <Autocomplete
      multiple={props._multiple ? props._multiple : true}
      id={props._name}
      options={props._option}
      value={props._value}
      style={{ ...props._style, width: 500, borderRadius: 3 }}
      onChange={(event, value, reason, details) => {
        // console.log('details select', details)
        // console.log('option select', details?.option.title)
        // console.log('option select', details?.option.value)
        if (reason === 'selectOption') {
          // Add
          console.log(props._value, 'props._value')
          setFieldValue(props._name, [...props._value, details?.option], false)
        } else if (reason === 'removeOption') {
          // Remove
          const editArr = props._value.filter((value: any, index: any) => {
            return details?.option.title !== value.title
          })
          setFieldValue(props._name, editArr, false)
        } else if (reason === 'clear') {
          // Clear
          setFieldValue(props._name, [], false)
        }
      }}
      disableCloseOnSelect
      getOptionLabel={(_option) => _option.title}
      renderOption={(props, _option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            sx={{
              color: '#5357BB',
              '&.Mui-checked': {
                color: '#5357BB',
              },
            }}
            checked={selected}
          />
          {_option.title}
        </li>
      )}
      renderInput={({ InputLabelProps, InputProps, ...params }) => (
        <TextField
          InputLabelProps={{
            shrink: true,
            style: { fontFamily: 'KanitRegular', backgroundColor: 'white' },
            ...InputLabelProps,
          }}
          InputProps={{ style: { height: '100%', padding: 0 }, ...InputProps }}
          {...params}
          label={<span>{props._label}</span>}
          placeholder={props._placeholder}
        />
      )}
    />
  )
}

interface PropsAutoIconcomplete {
  _name: string
  _label: string
  _option: any[]
  _placeholder: string
  _isIcon?: boolean
}
export const AutocompleteIconComponent: React.FC<PropsAutoIconcomplete> = (
  props: PropsAutoIconcomplete,
): JSX.Element => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<any>()

  return (
    <Autocomplete
      multiple
      value={values[props._name]}
      onChange={(event, value, reason, details) => {
        console.log()
        if (reason === 'selectOption') {
          // Add
          setFieldValue(props._name, [...values[props._name], details?.option], false)
        } else if (reason === 'removeOption') {
          // Remove
          const editArr = values[props._name].filter((value: any, index: any) => {
            return details?.option.title !== value.title
          })
          setFieldValue(props._name, editArr, false)
        } else if (reason === 'clear') {
          // Clear
          setFieldValue(props._name, [], false)
        }
      }}
      limitTags={2}
      id={props._name}
      options={props._option}
      getOptionLabel={(option) => option.title}
      renderTags={(options) => {
        return options.map((option) => (
          <Fragment key={option.title}>
            {option.img}
            <p className={styles.showtext}>{option.title}</p>
          </Fragment>
        ))
      }}
      renderOption={(props, _option) => (
        <li {...props}>
          {_option.img}

          <div className={styles.textWrapper}>
            <p className={styles.title}> {_option.title}</p>
            <p className={styles.subtitle}> {_option.subtitle}</p>

            <button type="button" className={styles.btnClose} onClick={() => console.log('Delete')}>
              <CloseIcon color="secondary" />
            </button>
          </div>
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={props._label} placeholder={props._placeholder} />}
    />
  )
}

const FastFieldContext = React.createContext({
  enable: false,
})
interface FastFieldProviderProps {
  children: React.ReactNode
  enable?: boolean
}
export const FastFieldProvider: React.FC<FastFieldProviderProps> = ({ children, enable = true }) => {
  return <FastFieldContext.Provider value={{ enable }}>{children}</FastFieldContext.Provider>
}

export const FormInputWrapper: React.FC<{ children: React.ReactNode; label: string }> = ({ children, label }) => {
  const theme = useTheme()
  const { focused } = useFormControl() || {}
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          px: '1rem',
          pb: label ? '0.25rem' : '0',
          borderRadius: '10px',
          border: `1px solid ${focused ? theme.colors.primary : 'rgba(0, 0, 0, 0.23)'}`,
          boxShadow: focused ? `0px 0px 0px 2px ${theme.colors.primary}` : '',
          '&:hover': {
            borderColor: focused ? theme.colors.primary : 'black',
          },
          width: '100%',
        }}
        component="fieldset"
      >
        {label && (
          <Box
            sx={{
              fontSize: '12px',
              color: focused ? theme.colors.primary : 'rgba(0, 0, 0, 0.6)',
              backgroundColor: 'white',
              px: '0.25rem',
            }}
            component="legend"
          >
            {label}
          </Box>
        )}

        {children}
      </Box>
    </>
  )
}

interface FormRadioGroupMenu {
  value: any
  label: any
  disabled?: boolean
}

interface FormRadioGroupProps {
  menuList: FormRadioGroupMenu[]
  row?: boolean
  name: string
  label?: string
  disabled?: boolean
}

export const FormRadioGroup: React.FC<FormRadioGroupProps> = (props) => {
  return (
    <FormControl fullWidth>
      <FastField name={props.name}>
        {({ form, meta }: FastFieldProps) => (
          <FormInputWrapper label={props.label || ''}>
            <RadioGroup
              row={props.row}
              value={meta.value || meta.initialValue || ''}
              onChange={(_, value) => form.setFieldValue(props.name, value)}
            >
              {props.menuList.map((menu, index) => (
                <FormControlLabel
                  key={index}
                  value={menu.value}
                  control={
                    <Radio
                      sx={{
                        color: '#5357BB',
                        '&.Mui-checked': {
                          color: '#5357BB',
                        },
                      }}
                      size="small"
                    />
                  }
                  label={menu.label}
                  disabled={props.disabled || menu.disabled}
                />
              ))}
            </RadioGroup>
          </FormInputWrapper>
        )}
      </FastField>
    </FormControl>
  )
}
