import * as React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import styles from './index.module.scss'
const ProgessBarComponent: React.FC = (): JSX.Element => {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.trunc(oldProgress + diff)
      })
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    // <Box sx={{ width: '100%' }}>
    <div className={styles.content}>
      <LinearProgress sx={{ height: 5 }} />
    </div>

    // </Box>
  )
}

export default ProgessBarComponent
