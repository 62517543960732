import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import LogoSVG from './images/logo.svg'
// import ExpandSVG from './images/expand.svg'
import BellSVG from './images/bell.svg'
import ChatSVG from './images/chat.svg'
// import QuestionSVG from './images/question.svg'
import ProfileSVG from './images/profile.svg'
// import HamburgurIcon from './images/hamburgur.svg'
import { Link, useNavigate } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// import { checkAllTokens } from '../../adapter/xhr'
// import useUser from '../../hooks/useUser'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import LinkMaterial from '@mui/material/Link'
import useMyLocation from 'src/hooks/useMyLocation'
import { useSelector } from 'react-redux'
import { ICombineReducers } from 'src/state/reducers'
import { gql, useLazyQuery } from '@apollo/client'
import { querygetEmployee } from 'src/adapter/xhr/query'
import { Avatar } from '@mui/material'
// import Typography from '@mui/material/Typography'

const NavTopBarComponent: React.FC<NavTopBarProps> = ({
  isSideNavOpen,
  setIsSideNavOpen,
}: NavTopBarProps): JSX.Element => {
  const navigate = useNavigate()
  const { adminOrEmployee } = useMyLocation()
  const { decodedAccessToken } = useSelector((state: ICombineReducers) => state.loginReducer)
  const userId = decodedAccessToken.userID
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [profileImg, setProfileImg] = useState<string>('')
  const open = Boolean(anchorEl)

  if (adminOrEmployee === 'EMPLOYEE') {
    const [loadData, { data }] = useLazyQuery(GET_EMPLOYEE)
    useEffect(() => {
      loadData({ variables: userId }).then((res) => {
        console.log(res, 'res')
        setProfileImg(res.data.getEmployee.profileImage)
      })
      console.log(data, 'data')
    }, [])
  }

  return (
    <section className={styles.wrapper}>
      <div className={styles.leftSide}>
        {/* Hamburger Button */}
        <button
          className={styles.sideNavToggle}
          aria-controls="side-navigation"
          aria-expanded={isSideNavOpen}
          onClick={() => setIsSideNavOpen(true)}
        >
          <span className="sr-only">Menu</span>
        </button>
        {/* <img alt="ExpandSVG" src={HamburgurIcon} width={24} height={24} onClick={() => console.log('clickk')} /> */}
        <Link to={`/${adminOrEmployee.toLowerCase()}`}>
          <img className={styles.logo} alt="LogoSVG" src={LogoSVG} width={109} height={24} />
        </Link>
      </div>
      {/* Middle Section: Breadcrumbs for Navigation */}
      <NavBreadcrumbs />
      {/* Right side of Top Bar */}
      <div className={styles.rightSide}>
        {/* <p>Trial expires in 14 Day</p> */}
        <button className={styles.chatButton}>
          <img alt="ChatSVG" src={ChatSVG} width={24} height={24} />
        </button>
        <button className={styles.notiButton}>
          <img alt="BellSVG" src={BellSVG} width={24} height={24} />
        </button>
        {/* <img alt="QuestionSVG" src={QuestionSVG} width={30} height={30} /> */}
        {/* <p>{user.username}</p> */}
        <button className={styles.profileButton} onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Avatar alt="profile" src={profileImg} />
          {/* <img onClick={handleClick} alt="ProfileSVG" src={ProfileSVG} width={32} height={32} /> */}
          <p>{decodedAccessToken.username}</p>
        </button>

        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          disableScrollLock={true}
        >
          {options.map((data) => (
            <MenuItem
              key={data.menu}
              onClick={() => {
                setAnchorEl(null)
                navigate(data.path)

                if (data.menu === 'Sign Out') {
                  localStorage.clear()
                }
              }}
            >
              {data.menu}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </section>
  )
}
export default NavTopBarComponent

interface NavTopBarProps {
  isSideNavOpen: boolean
  setIsSideNavOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const NavBreadcrumbs: React.FC = () => {
  const { adminOrEmployee, module, topic, subtopic } = useMyLocation()
  const getBreadcrumbList = (stringList: (string | undefined)[]): IBreadcrumb[] => {
    const out: IBreadcrumb[] = []
    stringList.forEach((s, i, a) => {
      if (s)
        out.push({
          text: capitalizeFirst(s),
          href: a
            .slice(0, i + 1)
            .join('/')
            .toLowerCase(),
          isLast: false,
        })
    })
    out[out.length - 1].isLast = true
    return out
  }
  const breadcrumbList = getBreadcrumbList([adminOrEmployee, module, topic, subtopic])

  return (
    <Breadcrumbs>
      {breadcrumbList.map(({ text, href, isLast }, index) =>
        isLast ? (
          <p key={index} style={{ color: '#000' }}>
            {text}
          </p>
        ) : (
          <LinkMaterial key={index} underline="hover" color="inherit" href={href}>
            {text}
          </LinkMaterial>
        ),
      )}
    </Breadcrumbs>
  )
}

interface IBreadcrumb {
  text: string
  href: string
  isLast: boolean
}

const options = [
  { menu: 'Profile', path: './profilepage' },
  { menu: 'Edit Profile', path: '../profilesetting/Edit' },
  { menu: 'Account Setting', path: '#' },
  { menu: 'User History', path: '#' },
  { menu: 'Sign Out', path: '/' },
]

function capitalizeFirst(s: string) {
  return s[0].toUpperCase() + s.substring(1)
}

const GET_EMPLOYEE = gql`
  query Query($getEmployeeId: ID) {
    getEmployee(id: $getEmployeeId) {
      id
      positionID
      departmentID
      status
      fullName
      lastName
      profileImage
    }
  }
`
