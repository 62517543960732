import React from 'react'
import { EDayType } from 'src/utils/generated'
import styles from './index.module.scss'

interface Props {
  dayType: string
  date: any
}

const ChipDateType: React.FC<Props> = ({ dayType, date }: Props): JSX.Element => {
  const ENUM_DAY_TYPE: any = {
    WORKDAY: 'วันทำงาน',
    WEEKEND: 'วันหยุดสุดสัปดาห์',
    HOLIDAY: 'วันหยุดประจำปี',
  }

  const renderColorDayType = (dayType: string) => {
    switch (dayType) {
      case EDayType.Workday:
        return styles.pill
      case EDayType.Weekend:
        return styles.pillWeek
      case EDayType.Holiday:
        return styles.pillHoliday
      default:
        break
    }
  }

  return (
    <div>
      <div className={styles.GroupDate}>
        <div className={renderColorDayType(dayType)}>
          <p className={styles.textDay}>{ENUM_DAY_TYPE[`${dayType}`]}</p>
        </div>
        <p>
          {new Date(`${date.slice('', 10)}`).toLocaleDateString('th-TH', {
            weekday: 'long',
          })}
        </p>
      </div>
      <p> {date.split('', 10)}</p>{' '}
    </div>
  )
}

export default ChipDateType
