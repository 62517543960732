import { getIn, useFormikContext } from 'formik'
import React from 'react'

interface IToggleContext {
  onChange: (value: any) => void
  value: any
  disabled: boolean
}

function createToggleContext() {
  return React.createContext<IToggleContext | null>(null)
}

const Context = createToggleContext()

interface ToggleGroupProps {
  children: React.ReactNode
  value?: any
  onChange?: (value: any) => void
  disabled?: boolean
}

const ToggleGroupComponent: React.FC<ToggleGroupProps> = (props) => {
  const [value, setValue] = React.useState<any>(props.value)

  const handleChange = (value: any) => {
    setValue(value)
    props.onChange && props.onChange(value)
  }
  return (
    <Context.Provider value={{ onChange: handleChange, value: value, disabled: !!props.disabled }}>
      {props.children}
    </Context.Provider>
  )
}
interface ToggleGroupFormikProps extends ToggleGroupProps {
  name: string
}
const ToggleGroupFormik: React.FC<ToggleGroupFormikProps> = (props) => {
  const formik = useFormikContext()
  const [value, setValue] = React.useState<any>(() => getIn(formik.values, props.name) || props.value)

  const handleChange = (value: any) => {
    setValue(value)
    formik.setFieldValue(props.name, value)
    props.onChange && props.onChange(value)
  }
  return (
    <Context.Provider value={{ onChange: handleChange, value: value, disabled: !!props.disabled }}>
      {props.children}
    </Context.Provider>
  )
}

const ToggleGroup = Object.assign(ToggleGroupComponent, {
  Formik: ToggleGroupFormik,
})

interface ToggleItemProps {
  children: React.ReactNode | FunctionChildren
  value: any
  disabled?: boolean
}

type FunctionChildren = (props: { value: any; isActive: boolean; disabled: boolean }) => React.ReactNode

export const ToggleItem: React.FC<ToggleItemProps> = (props) => {
  const context = React.useContext(Context)

  if (!context) throw new Error('ToggleItem should inside ToggleGroup')

  const isDisabled = React.useMemo(() => {
    return props.disabled || context.disabled
  }, [props.disabled, context.disabled])

  const handleChange = () => {
    if (context.value === props.value || isDisabled) return
    context.onChange(props.value)
  }

  const renderChildren = (): React.ReactNode => {
    switch (typeof props.children) {
      case 'function': {
        return (props.children as FunctionChildren)({
          value: context.value,
          isActive: props.value === context.value,
          disabled: isDisabled,
        })
      }
      default: {
        return props.children
      }
    }
  }

  return <div onClick={handleChange}>{renderChildren()}</div>
}
export default ToggleGroup
