import { CreateWelfareConfig, DeleteWelfareConfig, UpdateWelfareConfig, WelfareConfig } from '../../utils/generated'
import { gqlResponseType, post } from '../xhr'
import { mutationCreateWelfareConfig, mutationDeleteWelfareConfig, mutationUpdateWelfareConfig } from '../xhr/mutation'
import { queryListWelfareConfig } from '../xhr/query'

// ----------------------------------------------- Query -----------------------------------------------

export function getListWelfareConfig() {
  return gqlResponseType<{ items: WelfareConfig[] }>(
    post('/', {
      query: queryListWelfareConfig,
    }),
    'listWelfareConfig',
  )
}

// ----------------------------------------------- Mutation -----------------------------------------------

export function createWelfareConfig(input: CreateWelfareConfig) {
  return gqlResponseType<WelfareConfig>(
    post('/', {
      query: mutationCreateWelfareConfig,
      variables: { input },
    }),
    'createWelfareConfig',
  )
}

export function updateWelfareConfig(input: UpdateWelfareConfig) {
  return gqlResponseType<WelfareConfig>(
    post('/', {
      query: mutationUpdateWelfareConfig,
      variables: { input },
    }),
    'updateWelfareConfig',
  )
}

export function deleteWelfareConfig(input: DeleteWelfareConfig) {
  return gqlResponseType<WelfareConfig>(
    post('/', {
      query: mutationDeleteWelfareConfig,
      variables: { input },
    }),
    'deleteWelfareConfig',
  )
}
