//lib
import React from 'react'
import { useFormikContext } from 'formik'

//Include Project
import styles from './index.module.scss'

//Images
import { EOverTime, EStatus } from 'src/pages/AttendanceReport/EType'

const OvertimeAttendaceReportView: React.FC = (): JSX.Element => {
  const { values } = useFormikContext<any>()

  return (
    <div className={styles.container}>
      <div className={styles.wrapperInput}>
        <div className={styles.gropHead}>
          <p className={styles.TextHead}>การทำงานล่วงเวลา</p>
        </div>
        {values?.report.overtimeList?.map((itemOvertime: any, IndexOvertime: any) => (
          <section className={styles.cardDocument} key={IndexOvertime}>
            <div className={styles.box}>
              <div className={styles.headButton}>
                <section className={returnstyles(itemOvertime.status)}>
                  <p>{EStatus[`${itemOvertime.status}`]}</p>
                </section>
              </div>
              <section className={styles.inputBox}>
                <p className={styles.descriptionText}>ประเภทโอที</p>
                <p>{EOverTime[`${itemOvertime.overtimeType}`]}</p>
              </section>
              <section className={styles.inputBox}>
                <p className={styles.descriptionText}>ช่วงเวลา</p>
                <p>
                  {itemOvertime.startedAt} - {itemOvertime.endedAt}
                </p>
              </section>
            </div>
          </section>
        ))}
      </div>
    </div>
  )
}

const returnstyles = (status: any) => {
  if (status === 'APPROVE') {
    return `${styles.pillApprove}`
  } else if (status === 'PENDING') {
    return `${styles.pillPending}`
  } else if (status === 'DRAFT') {
    return `${styles.pillDraft}`
  } else if (status === 'REJECT') {
    return `${styles.pillReject}`
  } else {
    return `${styles.pillSuggest}`
  }
}

export default OvertimeAttendaceReportView
