import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { AutocompleteIconComponent, ButtonComponent, InputTextAreaComponent, InputTextComponent } from '../../component'
import Profile from '../../image/avatar.svg'
import search from '../../image/search.svg'
import styles from './index.module.scss'

const UserManageTeamNewEdite: React.FC = (): JSX.Element => {
  const member = [
    { img: <img src={Profile} />, title: 'Thanyarat', subtitle: 'Frontend' },
    { img: <img src={Profile} />, title: 'Beam', subtitle: 'UX/UI' },
    { img: <img src={Profile} />, title: 'Admin', subtitle: 'Mobile' },
  ]
  const { id } = useParams<any>()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  useEffect(() => {
    if (id) {
      setMode('update')
    } else {
      setMode('create')
    }
  }, [])
  const navigate = useNavigate()
  const validationSchema = yup.object({
    teamname: yup.string().required('ใส่ชื่อทีม'),
    lead: yup.string().required('เลือกหัวหน้าทีม'),
  })
  return (
    <div className={styles.container}>
      {/* <div className={styles.navtop}>
            <h1 className={styles.title}>User Management</h1>
          </div> */}

      <div className={styles.cardSearch}>
        <Formik
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2))
              actions.setSubmitting(false)
            }, 1000)
          }}
        >
          {(props: any) => (
            <form className={styles.wrapperForm} onSubmit={props.handleSubmit}>
              <div className={styles.InputArea}>
                <div className={styles.inputBox}>
                  <TextField
                    label="ค้นหาทีม"
                    id="outlined-start-adornment"
                    sx={{ m: 1, width: '35ch' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={search} alt="" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className={styles.btSearch}>
                {' '}
                <ButtonComponent
                  _variant="contained"
                  _text="ค้นหา"
                  _colorText="white"
                  _colorBG="blue"
                  _type="button"
                  _functionOnClick={() => navigate('../menuemployee')}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className={styles.cardTable}>
        <div className={styles.cardform}>
          <Formik
            initialValues={{
              teamname: '',
              detail: '',
              member: [],
              lead: [],
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2))
                actions.setSubmitting(false)
              }, 1000)
            }}
          >
            {(props: any) => (
              <form onSubmit={props.handleSubmit} className={styles.formgroup}>
                {/* {console.log('user add', props.values)} */}
                <div className={styles.BoxInput}>
                  <InputTextComponent _name="teamname" _label="ชื่อทีม" _helperText={props.errors.teamname} />
                  <AutocompleteIconComponent
                    _label="หัวหน้าทีม"
                    _option={member}
                    _name="lead"
                    _placeholder="กรอกรหัสพนักงาน"
                  />
                  <AutocompleteIconComponent
                    _label="พนักงาน"
                    _option={member}
                    _name="member"
                    _placeholder="กรอกรหัสพนักงาน"
                  />
                  <InputTextAreaComponent _name="detail" _label="รายละเอียด" />
                </div>

                <div className={styles.formButton}>
                  <div className={styles.btCancle}>
                    <ButtonComponent
                      _colorBG="white"
                      _colorText="red"
                      _text="ยกเลิก"
                      _variant="outlined"
                      _type="button"
                    />
                  </div>
                  <div className={styles.btCancle}>
                    <ButtonComponent
                      _colorBG="black"
                      _colorText="white"
                      _text="ย้อนกลับ"
                      _variant="contained"
                      _type="button"
                      _functionOnClick={() => navigate('../usermanage/team')}
                    />
                  </div>
                  {mode === 'create' && (
                    <div className={styles.btAdd}>
                      <ButtonComponent
                        _colorBG="blue"
                        _colorText="white"
                        _text="เพิ่ม"
                        _variant="contained"
                        _type="submit"
                      />
                    </div>
                  )}
                  {mode === 'update' && (
                    <div className={styles.btAdd}>
                      <ButtonComponent
                        _colorBG="blue"
                        _colorText="white"
                        _text="แก้ไข"
                        _variant="contained"
                        _type="submit"
                      />
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default UserManageTeamNewEdite
