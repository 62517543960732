import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CircularProgress, Fade, TextField, Tooltip } from '@mui/material'
import styles from './index.module.scss'
import NoteIcon from '../../../image/note.svg'
import quesIcon from '../../../image/question.svg'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Popper from '@mui/material/Popper'
import closeIcon from '../../../image/close.svg'
import NoteDataIcon from '../../../image/notedata.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { QueryGetAttendance, QueryListPayroll } from '../../../adapter/xhr'
import { useFormikContext } from 'formik'
import { combineList, minuteToHour } from '../../../utils/common'
import { ELeave, toTime } from '../../AttendanceReport/EType'
import { Employee, TimeAttendanceReport, TimeAttendanceReportResponse } from 'src/utils/generated'
import { IUsePaginationQueryOutput } from 'src/hooks/usePaginationQuery'
import alertGrayIcon from '../../../image/alertGray.svg'
import alertGreenIcon from '../../../image/alertGreen.svg'
import alertWarningIcon from '../../../image/alertWarnning.svg'
import alertRejectIcon from '../../../image/alertRed.svg'
import { TooltipArrow } from 'src/component'
import TimeLog from '../../../image/TimeLog.svg'

//TableReportPayroll   console.log(props.report.timeAttendanceReportList, 'props.ReportPayroll')

interface TableAttendancePayrollProps {
  employeeGroupList: TimeAttendanceReportResponse[]
  searchVal: string
  filter: any
}

export const TableAttendancePayroll: React.FC<TableAttendancePayrollProps> = (
  props: TableAttendancePayrollProps,
): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [modalIndex, setModalIndex] = React.useState<any>()
  const [modalRowIndex, setModalRowIndex] = React.useState<any>()
  const [remarkIndex, setRemarkIndex] = React.useState<number>(0)

  const handleClick = (index: number, RowIndex: number) => {
    setModalIndex(index)
    setModalRowIndex(RowIndex)
  }

  const handleClickPopper = (event: any, index: number) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
    setRemarkIndex(index)
  }

  const handleClose = (index: number) => {
    setAnchorEl(null)
    setRemarkIndex(index - 1)
  }
  const EDayType: any = {
    WORKDAY: 'วันทำงาน',
    WEEKEND: 'วันหยุดสุดสัปดาห์',
    HOLIDAY: 'วันหยุดประจำปี',
  }

  //Colors Rows

  const statusStyle = (Type: any) => {
    switch (Type) {
      case 'ATTENDANCE':
        return '#FFFFFF'
        break
      case 'OVERTIME':
        return '#F8E8BD'
        break
      case 'LEAVE':
        return '#FFC5C8'
        break
      case 'WEEKEND':
        return '#DFDFDE'
        break
      case 'HOLIDAY':
        return '#E9C4E9'
        break
      case 'INCOMPLETE':
        return '#F5F4F6'
        break
      default:
        return '#FFFFFF'
    }
  }

  const Border = (Type: any) => {
    switch (Type) {
      case 'INCOMPLETE':
        return '#F32424'
        break
      default:
        return 'none'
    }
  }

  // console.log(props, 'props.report')

  const Header = [
    'วันที่',
    'กะการทำงาน',
    'เวลาทำงานจริง',
    'ชั่วโมงการทำงานจริง',
    'การทำงานล่วงเวลา',
    'ลาหักเงิน',
    'ลาไม่หักเงิน',
    '',
  ]

  const filterEmployee = (employeeGroup: TimeAttendanceReportResponse) => {
    if (props.searchVal === '') {
      return employeeGroup
    } else if (
      employeeGroup.employee?.department?.departmentName
        .toLocaleLowerCase()
        .includes(props.searchVal.toLocaleLowerCase()) ||
      employeeGroup.employee?.fullName?.toLocaleLowerCase().includes(props.searchVal.toLocaleLowerCase()) ||
      employeeGroup.employee?.lastName?.toLocaleLowerCase().includes(props.searchVal.toLocaleLowerCase()) ||
      employeeGroup.employeeID?.toLocaleLowerCase().includes(props.searchVal.toLocaleLowerCase())
    ) {
      return employeeGroup
    }
  }
  const filterAttendancePayroll = (attendPayroll: TimeAttendanceReport) => {
    if (props.filter.length == 0) {
      return attendPayroll
    } else if (
      (attendPayroll.shiftName && props.filter.includes('SHIFT')) ||
      (attendPayroll.overtimeMinute! > 0 && props.filter.includes('OVERTIME')) ||
      (attendPayroll.leaveDeductMinute! > 0 && props.filter.includes('LEAVE_DEDUCT')) ||
      (attendPayroll.leaveMinute! > 0 && props.filter.includes('LEAVE'))
    ) {
      return attendPayroll
    }
  }

  //Ic
  const statusDocument = (data: any) => {
    const _status: any = []
    const _data = data?.map((item: any) => {
      _status.push(item.status)
    })

    if (_status.includes(null)) {
      return alertGrayIcon
    } else if (_status.includes('APPROVE')) {
      return alertGreenIcon
    } else if (_status.includes('PENDING')) {
      return alertWarningIcon
    }
  }
  const [employeeId, setEmployeeId] = React.useState<any>()

  const [expanded, setExpanded] = React.useState<string | false>(false)
  const handleChange = (id: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    // Make the expanded by this one (or close it)
    setExpanded(isExpanded ? id : false)
  }

  const questionTooltip = () => {
    return (
      <div className={styles.textShift}>
        <img src={quesIcon} alt="" />
      </div>
    )
  }
  const TooltipTimeLog = () => {
    return (
      <div className={styles.IconTime}>
        <img src={TimeLog} alt="TimeLog" />
      </div>
    )
  }

  return (
    <>
      {/* The Table Header */}
      <Table
        aria-label="simple table"
        sx={{
          position: 'sticky',
          top: 0,
          padding: 50,
          maxHeight: '56.5px',
          // bottom: '0px',
          // border: '1px solid red',
          zIndex: 99,
          // ['z-index']: 999,
          ['background-color']: 'white',
        }}
      >
        {/* Setting Column Widths */}
        {/* https://stackoverflow.com/questions/51216285/material-ui-v1-set-table-column-widths */}
        {/* https://github.com/mui/material-ui/issues/1911 */}
        <colgroup>
          {/* Date */}
          <col style={{ width: '15%' }} />
          {/* Shift */}
          <col style={{ width: '10%' }} />
          {/* ScanIn/ScanOut */}
          <col style={{ width: '20%' }} />
          {/* Working Minute */}
          <col style={{ width: '10%' }} />
          {/* OT Minute */}
          <col style={{ width: '10%' }} />
          {/* Leave Deduct Minute */}
          <col style={{ width: '10%' }} />
          {/* Leave Minute */}
          <col style={{ width: '10%' }} />
          {/* Misc */}
          <col style={{ width: '10%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            {Header.map((item, index) => (
              <TableCell
                align="center"
                key={index}
                sx={{
                  borderLeft: '1px solid #F2F2F2',
                  borderRight: '1px solid #F2F2F2',
                  borderTop: '1px solid #F2F2F2',

                  position: 'sticky',
                  top: 0,
                  // backgroundColor: '#CAD6EA',
                  // border: 'none',
                }}
              >
                <p>{item}</p>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      {props.employeeGroupList.filter(filterEmployee).map((empGroup, empGroupIndex) => (
        <Accordion
          key={empGroupIndex}
          expanded={expanded === empGroup.employee?.id}
          onChange={handleChange(empGroup.employee?.id)}
          onClick={() => setEmployeeId(empGroup.employee?.id)}
          sx={{
            // This is here so that the expanded version will have blue background.
            backgroundColor: '#CAD6EA',
            borderRadius: '7px 7px 0px 0px',
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              position: 'sticky',
              top: '57px',
              backgroundColor: '#CAD6EA99',
              backdropFilter: 'blur(1px)',
              borderRadius: '7px 7px 0px 0px',
            }}
          >
            <section className={styles.headEmployee}>
              <div>
                <span className={styles.bold}>{empGroup.employee?.id}</span>
              </div>
              <div className={styles.name}>
                {empGroup.employee?.fullName} {empGroup.employee?.lastName}{' '}
                {empGroup.employee?.nickName ? `(${empGroup.employee?.nickName})` : ''}
              </div>
              <div className={styles.department} style={returnStyle(empGroup.employee?.department?.departmentName)}>
                {empGroup.employee?.department?.departmentName}
              </div>
            </section>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.tableContainer}>
              <Table aria-label="simple table">
                <colgroup>
                  {/* Date */}
                  <col style={{ width: '15%' }} />
                  {/* Shift */}
                  <col style={{ width: '10%' }} />
                  {/* ScanIn/ScanOut */}
                  <col style={{ width: '20%' }} />
                  {/* Working Minute */}
                  <col style={{ width: '10%' }} />
                  {/* OT Minute */}
                  <col style={{ width: '10%' }} />
                  {/* Leave Deduct Minute */}
                  <col style={{ width: '10%' }} />
                  {/* Leave Minute */}
                  <col style={{ width: '10%' }} />
                  {/* Misc */}
                  <col style={{ width: '10%' }} />
                </colgroup>
                <TableBody>
                  {empGroup.items &&
                    empGroup.items?.filter(filterAttendancePayroll)?.map((itemRow, rowIndex) => (
                      <TableRow sx={{ backgroundColor: statusStyle(itemRow.Type) }} key={rowIndex}>
                        <TableCell align="left">
                          {/* {console.log(itemRow, 'ItemRow')} */}

                          <div className={styles.GroupDate}>
                            <p>
                              {new Date(itemRow.date).toLocaleDateString('th-TH', {
                                weekday: 'long',
                              })}
                            </p>

                            <div
                              className={
                                itemRow.dayType === 'WORKDAY'
                                  ? styles.pill
                                  : itemRow.dayType === 'WEEKEND'
                                  ? styles.pillWeek
                                  : itemRow.dayType === 'HOLIDAY'
                                  ? styles.pillHoliday
                                  : ''
                              }
                            >
                              <p className={styles.textDay}>{EDayType[`${itemRow.dayType}`]}</p>
                            </div>
                          </div>

                          <p> {itemRow.date.split('', 10)}</p>
                        </TableCell>
                        <TableCell align="center">
                          {itemRow.shiftName ? (
                            <div className={styles.textShift}>
                              <p> {itemRow.shiftName}</p>
                              <TooltipArrow
                                label={`${itemRow.shiftStartTime} - ${itemRow.shiftEndTime}`}
                                child={questionTooltip()}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <div>
                            <div className={styles.group}>
                              {itemRow.scanIn ? (
                                <div className={itemRow.inDiffMinute! > 0 ? styles.textScanLate : styles.textScan}>
                                  <div className={styles.groupText}>
                                    <p>{toTime(itemRow.scanIn)}</p>
                                    {itemRow.inDiffMinute === 0 ? (
                                      ''
                                    ) : (
                                      <div className={styles.groupPill}>
                                        <section className={styles.pillDiffEarly}>
                                          {itemRow.inDiffMinute! > 0 ? <p>มาสาย</p> : <p>มาก่อน</p>}
                                        </section>
                                        {itemRow.inDiffMinute! > 0 ? (
                                          <p className={styles.textIn}>+{itemRow.inDiffMinute}</p>
                                        ) : (
                                          <p className={styles.textIn}>{itemRow.inDiffMinute}</p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <TooltipArrow
                                    label={`เวลาจริงจากเครื่องสแกนนิ้ว ${itemRow.shiftStartTime} -
                                              ${itemRow.shiftEndTime}`}
                                    child={TooltipTimeLog()}
                                  />
                                </div>
                              ) : (
                                ''
                              )}

                              {itemRow.scanOut ? (
                                <div className={itemRow.outDiffMinute! < 0 ? styles.textScanLate : styles.textScanOut}>
                                  <div className={styles.groupText}>
                                    <p>{toTime(itemRow.scanOut)}</p>
                                    {itemRow.outDiffMinute === 0 ? (
                                      ''
                                    ) : (
                                      <div className={styles.groupPill}>
                                        <section className={styles.pillDiffEarly}>
                                          {itemRow.outDiffMinute! < 0 ? <p>ออกก่อน</p> : <p>ออกหลัง</p>}
                                        </section>
                                        {itemRow.outDiffMinute! > 0 ? (
                                          <p className={styles.textIn}>+{itemRow.outDiffMinute}</p>
                                        ) : (
                                          <p className={styles.textIn}>{itemRow.outDiffMinute}</p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <TooltipArrow
                                    label={`เวลาจริงจากเครื่องสแกนนิ้ว ${itemRow.shiftStartTime} -
                                              ${itemRow.shiftEndTime}`}
                                    child={TooltipTimeLog()}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </TableCell>
                        {/* <TableCell align="center">
                          <div>
                            {itemRow.scanIn ? (
                              <div className={styles.group}>
                                <div className={itemRow.inDiffMinute! < 0 ? styles.textScan : styles.textScanLate}>
                                  <p>{toTime(itemRow.scanIn)}</p>
                                  {itemRow.inDiffMinute === 0 ? (
                                    ''
                                  ) : (
                                    <div className={styles.groupPill}>
                                      <section className={styles.pillDiffEarly}>
                                        {itemRow.inDiffMinute! < 0 ? <p>มาก่อน</p> : <p>มาสาย</p>}
                                      </section>
                                      <p className={styles.textIn}>{itemRow.inDiffMinute}</p>
                                    </div>
                                  )}
                                </div>
                                <div className={itemRow.outDiffMinute! < 0 ? styles.textScanLate : styles.textScanOut}>
                                  <p>{toTime(itemRow.scanOut)}</p>

                                  {itemRow.overtimeList!.length > 0 ? (
                                    ''
                                  ) : (
                                    <>
                                      {itemRow.outDiffMinute == 0 ? (
                                        ''
                                      ) : (
                                        <div className={styles.groupPill}>
                                          <section className={styles.pillDiffEarly}>
                                            {itemRow.outDiffMinute! < 0 ? <p>ออกก่อน</p> : <p>ออกหลัง</p>}
                                          </section>
                                          <p className={styles.textIn}>{itemRow.outDiffMinute}</p>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </TableCell> */}
                        <TableCell align="center">
                          <h4 style={{ fontWeight: 'bold' }}>{minuteToHour(itemRow.workingMinute!)} </h4>
                        </TableCell>
                        <TableCell align="center">
                          <h4 style={{ fontWeight: 'bold' }}>
                            {minuteToHour(itemRow.overtimeMinute! + itemRow.overtimeApproveMinute!)}
                          </h4>
                          {/* {console.log(itemRow?.overtimeApproveList.concat(itemRow?.overtimeList), 'OVERTIME LIST')} */}
                          <img
                            src={statusDocument(itemRow.overtimeList!)}
                            alt=""
                            style={{ cursor: 'pointer' }}
                            onClick={(event: any) => {
                              // handleClickPopper(event, emp.id)
                              // setDatePopper(itemRow.date)
                              // setEmployeePopper(emp.id)
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <h4 style={{ fontWeight: 'bold' }}>{minuteToHour(itemRow.leaveDeductMinute!)}</h4>
                          <div className={styles.group}>
                            {/* {console.log(itemRow.leaveDeductList, 'LEAVELIST')} */}
                            {itemRow.leaveDeductList?.slice(0, 2).map((itemLeave, IndexLeave) => (
                              <div className={styles.groupPill} key={IndexLeave}>
                                <section className={styles.pillDiffEarlyLeave}>
                                  {itemLeave.leaveConfig!.name ? <p>{itemLeave.leaveConfig!.name}</p> : <p>ขาด</p>}
                                </section>
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <h4 style={{ fontWeight: 'bold' }}>{minuteToHour(itemRow.leaveMinute!)}</h4>
                          <div className={styles.group}>
                            {itemRow.leaveList?.slice(0, 2).map((itemLeave, IndexLeave) => (
                              <div className={styles.groupPill} key={IndexLeave}>
                                <section className={styles.pillDiffEarlyLeave}>
                                  {itemLeave.leaveConfig!.name ? <p>{itemLeave.leaveConfig!.name}</p> : <p>ขาด</p>}
                                </section>
                              </div>
                            ))}
                          </div>
                        </TableCell>

                        <TableCell align="center">
                          <div className={styles.action}>
                            {itemRow.remark === null || itemRow.remark === '' ? (
                              <img src={NoteIcon} alt="" onClick={(e: any) => handleClickPopper(e, empGroupIndex)} />
                            ) : (
                              <img
                                src={NoteDataIcon}
                                alt=""
                                onClick={(e: any) => handleClickPopper(e, empGroupIndex)}
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <Popper open={open} anchorEl={anchorEl} transition placement="left">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={styles.area}>
              <div className={styles.modal}>
                <div className={styles.modalGroup}>
                  <div className={styles.header}>
                    <p className={styles.text}>หมายเหตุ</p>
                    <img src={closeIcon} alt="" className={styles.close} onClick={() => handleClose(remarkIndex)} />
                  </div>
                  <TextField
                    id="outlined-textarea"
                    name="remark"
                    type="text"
                    multiline
                    // value={remark}
                    className={styles.textFieldBG}
                  />
                </div>
              </div>
              <div className={styles.pointer}></div>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  )
}

const buttonColorList = [
  // { color: 'white', backgroundColor: 'black' },
  // { color: 'white', backgroundColor: 'darkblue' },
  { color: 'black', backgroundColor: 'white' },
  // { color: 'black', backgroundColor: 'yellow' },
  // { color: 'black', backgroundColor: 'orange' },
  // { color: 'black', backgroundColor: 'pink' },
  // { color: 'white', backgroundColor: 'green' },
  // { color: 'white', backgroundColor: 'maroon' },
  // { color: 'white', backgroundColor: 'olive' },
  // { color: 'black', backgroundColor: 'lime' },
  // { color: 'white', backgroundColor: 'fuchsia' },
  // { color: 'white', backgroundColor: 'Purple' },
  // { color: 'white', backgroundColor: 'navy' },
  // { color: 'white', backgroundColor: 'teal' },
  // { color: 'white', backgroundColor: 'charcoal' },
  // { color: 'white', backgroundColor: 'jade' },
  // { color: 'white', backgroundColor: 'bronze' },
]

function returnStyle(s: string | undefined) {
  if (!s) return {}
  // console.log('string ', s, getHashFromString(s), getHashFromString(s) % buttonColorList.length)
  return buttonColorList[getHashFromString(s) % buttonColorList.length]
}

function getHashFromString(s: string) {
  let hash = 0
  for (let i = 0; i < s.length; i++) {
    hash += s.charCodeAt(i)
  }
  return hash
}
