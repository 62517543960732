import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IInitialStateEmployee {
  itemsList: []
  itemsListOption: any[]
  refetch: () => void
}
const initialState: IInitialStateEmployee = {
  itemsList: [],
  itemsListOption: [],
  refetch: () => null,
}

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployee: (state, action: PayloadAction<any>) => {
      state.itemsList = action.payload.items
      state.itemsListOption = action.payload.items?.map((data: any) => ({
        value: data.id,
        label: `${data.fullName}  ${data.lastName}`,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setEmployee } = employeeSlice.actions

export default employeeSlice.reducer
